/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Billing20201105ConsumptionPoolsSummaryFromJSON(json) {
    return Billing20201105ConsumptionPoolsSummaryFromJSONTyped(json, false);
}
export function Billing20201105ConsumptionPoolsSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'initialAmount': !exists(json, 'initial_amount') ? undefined : json['initial_amount'],
        'estimatedRemainingBalance': !exists(json, 'estimated_remaining_balance') ? undefined : json['estimated_remaining_balance'],
        'activeFrom': !exists(json, 'active_from') ? undefined : (new Date(json['active_from'])),
        'activeUntil': !exists(json, 'active_until') ? undefined : (new Date(json['active_until'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}
export function Billing20201105ConsumptionPoolsSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'initial_amount': value.initialAmount,
        'estimated_remaining_balance': value.estimatedRemainingBalance,
        'active_from': value.activeFrom === undefined ? undefined : (value.activeFrom.toISOString()),
        'active_until': value.activeUntil === undefined ? undefined : (value.activeUntil.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
