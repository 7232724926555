import Component from '@glimmer/component';

interface SubmitButtonSignature {
  Args: {
    disabled: unknown;
    text: unknown;
  };
  Element: HTMLSpanElement;
}

/**
 *
 * Just a regular old submit button, nothing fancy.
 *
 *
 * ```
 * <FormInputs::SubmitButton
 *  @disabled={{disabled}}
 *  @text='Submit this form !!!'
 * />
 * ```
 *
 * @class FormInputsSubmitButton
 *
 */

export default class FormInputsSubmitButtonComponent extends Component<SubmitButtonSignature> {
  /**
   * @argument disabled
   * @type {Boolean}
   */
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormInputs::SubmitButton': typeof FormInputsSubmitButtonComponent;
    'form-inputs/submit-button': typeof FormInputsSubmitButtonComponent;
  }
}
