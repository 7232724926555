import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';

import type { IamEnrichedRole } from '../types/iam-enriched-role.ts';
import parseRoleId from '../utils/parse-role-id.ts';

export default function iamEnrichRolesWithServiceAndPersona(
  roles: Array<HashicorpCloudResourcemanagerRole>,
): Array<IamEnrichedRole> {
  if (!roles) {
    return [];
  }
  return roles.map((role) => {
    const { service, persona } = parseRoleId(role.id);
    return {
      ...role,
      service,
      persona,
    };
  });
}
