/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GhAppMetadataAccountTypeFromJSON, GhAppMetadataAccountTypeToJSON, } from './';
export function Secrets20230613InstallationFromJSON(json) {
    return Secrets20230613InstallationFromJSONTyped(json, false);
}
export function Secrets20230613InstallationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'appSlug': !exists(json, 'app_slug') ? undefined : json['app_slug'],
        'accountLogin': !exists(json, 'account_login') ? undefined : json['account_login'],
        'accountType': !exists(json, 'account_type') ? undefined : GhAppMetadataAccountTypeFromJSON(json['account_type']),
    };
}
export function Secrets20230613InstallationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'app_slug': value.appSlug,
        'account_login': value.accountLogin,
        'account_type': GhAppMetadataAccountTypeToJSON(value.accountType),
    };
}
