/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CloudlocationLocationFromJSON, CloudlocationLocationToJSON, LogService20210330CloudwatchLogsProviderFromJSON, LogService20210330CloudwatchLogsProviderToJSON, LogService20210330DatadogProviderFromJSON, LogService20210330DatadogProviderToJSON, LogService20210330SplunkCloudProviderFromJSON, LogService20210330SplunkCloudProviderToJSON, } from './';
export function LogService20210330CreateStreamingDestinationRequestFromJSON(json) {
    return LogService20210330CreateStreamingDestinationRequestFromJSONTyped(json, false);
}
export function LogService20210330CreateStreamingDestinationRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : CloudlocationLocationFromJSON(json['location']),
        'destinationName': !exists(json, 'destination_name') ? undefined : json['destination_name'],
        'sourceChannel': !exists(json, 'source_channel') ? undefined : json['source_channel'],
        'datadogProvider': !exists(json, 'datadog_provider') ? undefined : LogService20210330DatadogProviderFromJSON(json['datadog_provider']),
        'cloudwatchlogsProvider': !exists(json, 'cloudwatchlogs_provider') ? undefined : LogService20210330CloudwatchLogsProviderFromJSON(json['cloudwatchlogs_provider']),
        'splunkCloudProvider': !exists(json, 'splunk_cloud_provider') ? undefined : LogService20210330SplunkCloudProviderFromJSON(json['splunk_cloud_provider']),
    };
}
export function LogService20210330CreateStreamingDestinationRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': CloudlocationLocationToJSON(value.location),
        'destination_name': value.destinationName,
        'source_channel': value.sourceChannel,
        'datadog_provider': LogService20210330DatadogProviderToJSON(value.datadogProvider),
        'cloudwatchlogs_provider': LogService20210330CloudwatchLogsProviderToJSON(value.cloudwatchlogsProvider),
        'splunk_cloud_provider': LogService20210330SplunkCloudProviderToJSON(value.splunkCloudProvider),
    };
}
