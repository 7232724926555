/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125InputClusterFromJSON, HashicorpCloudVault20201125InputClusterToJSON, } from './';
export function HashicorpCloudVault20201125CreateRequestFromJSON(json) {
    return HashicorpCloudVault20201125CreateRequestFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125CreateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cluster': !exists(json, 'cluster') ? undefined : HashicorpCloudVault20201125InputClusterFromJSON(json['cluster']),
    };
}
export function HashicorpCloudVault20201125CreateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cluster': HashicorpCloudVault20201125InputClusterToJSON(value.cluster),
    };
}
