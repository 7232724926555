import Component from '@glimmer/component';

/**
 *
 * `CostEstimatorDifference` renders the difference between two estimate prices.
 *
 *
 * ```
 * <CostEstimatorDifference
 *   @currentEstimatePrice={{@currentEstimatePrice}}
 *   @newEstimatePrice={{@newEstimatePrice}}
 * />
 * ```
 *
 * @class CostEstimatorDifference
 *
 */

export default class CostEstimatorDifferenceComponent extends Component {
  get difference() {
    let { currentEstimatePrice, newEstimatePrice } = this.args;
    let difference = newEstimatePrice - currentEstimatePrice;
    let isIncrease = difference > 0;
    return {
      isIncrease,
      value: isIncrease ? `+${difference}` : difference,
    };
  }
}
