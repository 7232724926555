import templateOnlyComponent from '@ember/component/template-only';

interface CoreUserAgentDisplaySignature {
  Args: {
    userAgent: unknown;
  };
}

const CoreUserAgentDisplayComponent =
  templateOnlyComponent<CoreUserAgentDisplaySignature>();

export default CoreUserAgentDisplayComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::UserAgent::Display': typeof CoreUserAgentDisplayComponent;
    'core/user-agent/display': typeof CoreUserAgentDisplayComponent;
  }
}
