/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202ModelsVersionFromJSON, HashicorpCloudPacker20221202ModelsVersionToJSON, } from './';
export function HashicorpCloudPacker20221202ModelsChannelFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsChannelFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsChannelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'bucketName': !exists(json, 'bucket_name') ? undefined : json['bucket_name'],
        'authorId': !exists(json, 'author_id') ? undefined : json['author_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'version': !exists(json, 'version') ? undefined : HashicorpCloudPacker20221202ModelsVersionFromJSON(json['version']),
        'managed': !exists(json, 'managed') ? undefined : json['managed'],
        'restricted': !exists(json, 'restricted') ? undefined : json['restricted'],
    };
}
export function HashicorpCloudPacker20221202ModelsChannelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'bucket_name': value.bucketName,
        'author_id': value.authorId,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'version': HashicorpCloudPacker20221202ModelsVersionToJSON(value.version),
        'managed': value.managed,
        'restricted': value.restricted,
    };
}
