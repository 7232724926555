import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { typeOf } from '@ember/utils';

import type TimeService from '../services/time';

interface TimeSignature {
  Args: {
    date: Date | string | undefined;
    display: string;
    hasTooltip?: boolean;
  };
  Element: HTMLElement;
}

const dateIsValid = (date?: Date | string): date is Date =>
  date instanceof Date && !isNaN(+date);

/**
 *
 * `Time` renders a semantic time element based on a JS Date and the
 * Structure Design System patterns for date formatting.
 *
 *
 * @example
 * // returns 'Sep 5, 2018 (30 minutes ago)'
 * <Time @date={{this.date}} @display='friendly-relative' />
 * @example
 * // returns 'Sep 5, 2018, 4:07:32 pm'
 * <Time @date={{this.date}} @display='friendly-local' />
 * @example
 * // returns 'Sep 5, 2018'
 * <Time @date={{this.date}} @display='friendly-only' />
 * @example
 * // returns 'about 2 hours ago'
 * <Time @date={{this.date}} @display='relative' />
 * @example
 * // returns '2018-09-05T23:15:17345Z'
 * <Time @date={{this.date}} @display='utc' />
 *
 *
 * @class Time
 */

/**
 * @argument date
 * @type {Date}
 * @description A JavaScript native Date to display.
 */

/**
 * @argument display
 * @type {string}
 * @enum {('friendly-relative'|'friendly-local'|'friendly-only'|'relative'|'utc')}
 * @description The selected pattern to display the date. This is optional and
 *    forces the date to forced into a pattern. If left blank, an algorithm
 *    will display the best option.
 * @see {@link https://docs.google.com/document/d/1I91wdyCa8iO91TlVmKd2n1NI1aK7bRm5YM2BF8FaQfY/edit|Structure date and time}
 */

/**
 * @argument hasTooltip
 * @type {boolean}
 * @description Controls whether or not the component includes tooltip with the
 *    full date. Under normal circumstances, the time component should show a
 *    tooltip, so please only use this option in exceptional circumstances, such as
 *    when the time component is used inside another interactive element such as a
 *    button or form control.
 * @default true
 */

export default class TimeComponent extends Component<TimeSignature> {
  @service declare readonly time: TimeService;

  get date() {
    const { date } = this.args;

    // Sometimes an ISO date string might be passed in instead of a JS Date.
    if (typeOf(date) === 'string') {
      return new Date(date as string);
    }

    return date;
  }

  get isValid() {
    return dateIsValid(this.date);
  }

  get hasTooltip() {
    return this.args.hasTooltip ?? true;
  }

  @action
  register() {
    const date = this.date;

    if (dateIsValid(date)) {
      this.time.register(date);
    }
  }

  @action
  unregister() {
    const date = this.date;

    this.time.unregister(date);
  }

  get isoUtcString() {
    const date = this.date;

    if (dateIsValid(date)) return this.time.toIsoUtcString(date);
    return '';
  }

  get display() {
    const date = this.date;
    const { display } = this.args;
    if (dateIsValid(date)) {
      const nextDiff = this.time.getTimeDifference(this.time.now, date);
      return this.time.format(nextDiff, display);
    }
    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Time: typeof TimeComponent;
    time: typeof TimeComponent;
  }
}
