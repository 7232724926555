/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVagrant20220930VersionSummaryFromJSON(json) {
    return HashicorpCloudVagrant20220930VersionSummaryFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930VersionSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'providersCount': !exists(json, 'providers_count') ? undefined : json['providers_count'],
        'providerNames': !exists(json, 'provider_names') ? undefined : json['provider_names'],
    };
}
export function HashicorpCloudVagrant20220930VersionSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'providers_count': value.providersCount,
        'provider_names': value.providerNames,
    };
}
