/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128ServiceAccountImpersonationRequestFromJSON, Secrets20231128ServiceAccountImpersonationRequestToJSON, } from './';
export function SecretServiceUpdateGcpDynamicSecretBodyFromJSON(json) {
    return SecretServiceUpdateGcpDynamicSecretBodyFromJSONTyped(json, false);
}
export function SecretServiceUpdateGcpDynamicSecretBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'defaultTtl': !exists(json, 'default_ttl') ? undefined : json['default_ttl'],
        'serviceAccountImpersonation': !exists(json, 'service_account_impersonation') ? undefined : Secrets20231128ServiceAccountImpersonationRequestFromJSON(json['service_account_impersonation']),
    };
}
export function SecretServiceUpdateGcpDynamicSecretBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'default_ttl': value.defaultTtl,
        'service_account_impersonation': Secrets20231128ServiceAccountImpersonationRequestToJSON(value.serviceAccountImpersonation),
    };
}
