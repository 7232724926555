/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125CORSConfigFromJSON, HashicorpCloudVault20201125CORSConfigToJSON, HashicorpCloudVault20201125CidrRangeFromJSON, HashicorpCloudVault20201125CidrRangeToJSON, HashicorpCloudVault20201125HttpProxyOptionFromJSON, HashicorpCloudVault20201125HttpProxyOptionToJSON, } from './';
export function HashicorpCloudVault20201125NetworkConfigFromJSON(json) {
    return HashicorpCloudVault20201125NetworkConfigFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125NetworkConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'networkId': !exists(json, 'network_id') ? undefined : json['network_id'],
        'publicIpsEnabled': !exists(json, 'public_ips_enabled') ? undefined : json['public_ips_enabled'],
        'corsConfig': !exists(json, 'cors_config') ? undefined : HashicorpCloudVault20201125CORSConfigFromJSON(json['cors_config']),
        'httpProxyOption': !exists(json, 'http_proxy_option') ? undefined : HashicorpCloudVault20201125HttpProxyOptionFromJSON(json['http_proxy_option']),
        'ipAllowlist': !exists(json, 'ip_allowlist') ? undefined : (json['ip_allowlist'].map(HashicorpCloudVault20201125CidrRangeFromJSON)),
    };
}
export function HashicorpCloudVault20201125NetworkConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'network_id': value.networkId,
        'public_ips_enabled': value.publicIpsEnabled,
        'cors_config': HashicorpCloudVault20201125CORSConfigToJSON(value.corsConfig),
        'http_proxy_option': HashicorpCloudVault20201125HttpProxyOptionToJSON(value.httpProxyOption),
        'ip_allowlist': value.ipAllowlist === undefined ? undefined : (value.ipAllowlist.map(HashicorpCloudVault20201125CidrRangeToJSON)),
    };
}
