/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Billing20201105EstimatedPackageItemPriceFromJSON(json) {
    return Billing20201105EstimatedPackageItemPriceFromJSONTyped(json, false);
}
export function Billing20201105EstimatedPackageItemPriceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hourlyPrice': !exists(json, 'hourly_price') ? undefined : json['hourly_price'],
        'monthlyPrice': !exists(json, 'monthly_price') ? undefined : json['monthly_price'],
        'itemKey': !exists(json, 'item_key') ? undefined : json['item_key'],
    };
}
export function Billing20201105EstimatedPackageItemPriceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hourly_price': value.hourlyPrice,
        'monthly_price': value.monthlyPrice,
        'item_key': value.itemKey,
    };
}
