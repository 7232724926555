/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IDP Service
 * API for managing authentication sessions and SCIM settings.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudIdpCreateScimTokenRequestFromJSON(json) {
    return HashicorpCloudIdpCreateScimTokenRequestFromJSONTyped(json, false);
}
export function HashicorpCloudIdpCreateScimTokenRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'displayName': !exists(json, 'display_name') ? undefined : json['display_name'],
    };
}
export function HashicorpCloudIdpCreateScimTokenRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'display_name': value.displayName,
    };
}
