/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125PluginRegistrationStatusFromJSON, HashicorpCloudVault20201125PluginRegistrationStatusToJSON, } from './';
export function HashicorpCloudVault20201125PluginRegistrationStatusResponseFromJSON(json) {
    return HashicorpCloudVault20201125PluginRegistrationStatusResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125PluginRegistrationStatusResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'plugins': !exists(json, 'plugins') ? undefined : (json['plugins'].map(HashicorpCloudVault20201125PluginRegistrationStatusFromJSON)),
    };
}
export function HashicorpCloudVault20201125PluginRegistrationStatusResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'plugins': value.plugins === undefined ? undefined : (value.plugins.map(HashicorpCloudVault20201125PluginRegistrationStatusToJSON)),
    };
}
