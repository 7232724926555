/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125NewRelicRegionFromJSON, HashicorpCloudVault20201125NewRelicRegionToJSON, } from './';
export function HashicorpCloudVault20201125NewRelicFromJSON(json) {
    return HashicorpCloudVault20201125NewRelicFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125NewRelicFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountId': !exists(json, 'account_id') ? undefined : json['account_id'],
        'licenseKey': !exists(json, 'license_key') ? undefined : json['license_key'],
        'region': !exists(json, 'region') ? undefined : HashicorpCloudVault20201125NewRelicRegionFromJSON(json['region']),
    };
}
export function HashicorpCloudVault20201125NewRelicToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'account_id': value.accountId,
        'license_key': value.licenseKey,
        'region': HashicorpCloudVault20201125NewRelicRegionToJSON(value.region),
    };
}
