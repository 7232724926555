/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudConsul20210204GetClientConfigResponseFromJSON(json) {
    return HashicorpCloudConsul20210204GetClientConfigResponseFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204GetClientConfigResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'consulConfigFile': !exists(json, 'consul_config_file') ? undefined : json['consul_config_file'],
        'caFile': !exists(json, 'ca_file') ? undefined : json['ca_file'],
        'fileBundleZip': !exists(json, 'file_bundle_zip') ? undefined : json['file_bundle_zip'],
    };
}
export function HashicorpCloudConsul20210204GetClientConfigResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'consul_config_file': value.consulConfigFile,
        'ca_file': value.caFile,
        'file_bundle_zip': value.fileBundleZip,
    };
}
