import templateOnlyComponent from '@ember/component/template-only';

interface TimeInnerSignature {
  Args: {
    date: unknown;
    display: unknown;
    isoUtcString: unknown;
    register: unknown;
    unregister: unknown;
  };
  Element: HTMLTimeElement;
}

const TimeInnerComponent = templateOnlyComponent<TimeInnerSignature>();

export default TimeInnerComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Time::Inner': typeof TimeInnerComponent;
    'time/inner': typeof TimeInnerComponent;
  }
}
