import disabledRoles from '../utils/disabled-roles.ts';

import type { IPermission } from '../types/permissions.ts';

export default function iamIsPermissionDisabled(
  permission: IPermission,
): boolean {
  if (!permission || !permission.roleIds) {
    return false;
  }

  return permission.roleIds.some((roleId) => {
    return disabledRoles.includes(roleId);
  });
}
