/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Operations
 * API for managing HCP operations.
 *
 * The version of the OpenAPI document: 2020-05-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import {
  HashicorpCloudCommonPaginationResponseFromJSON,
  HashicorpCloudCommonPaginationResponseToJSON,
  HashicorpCloudOperationOperationFromJSON,
  HashicorpCloudOperationOperationToJSON,
} from './';
export function HashicorpCloudOperationListResponseFromJSON(json) {
  return HashicorpCloudOperationListResponseFromJSONTyped(json, false);
}
export function HashicorpCloudOperationListResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    operations: !exists(json, 'operations')
      ? undefined
      : json['operations'].map(HashicorpCloudOperationOperationFromJSON),
    pagination: !exists(json, 'pagination')
      ? undefined
      : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
  };
}
export function HashicorpCloudOperationListResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    operations:
      value.operations === undefined
        ? undefined
        : value.operations.map(HashicorpCloudOperationOperationToJSON),
    pagination: HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
  };
}
