/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeekFromJSON, HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeekToJSON, } from './';
export function HashicorpCloudBoundary20211221MaintenanceWindowFromJSON(json) {
    return HashicorpCloudBoundary20211221MaintenanceWindowFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221MaintenanceWindowFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dayOfWeek': !exists(json, 'day_of_week') ? undefined : HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeekFromJSON(json['day_of_week']),
        'start': !exists(json, 'start') ? undefined : json['start'],
        'end': !exists(json, 'end') ? undefined : json['end'],
    };
}
export function HashicorpCloudBoundary20211221MaintenanceWindowToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'day_of_week': HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeekToJSON(value.dayOfWeek),
        'start': value.start,
        'end': value.end,
    };
}
