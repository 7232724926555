import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  PEERINGS_ALERTS,
  PEERING_ACTIVE_STATUS,
} from '../../../utils/peerings';
import { TGW_ATTACHMENTS_ALERTS } from '../../../utils/tgw-attachments';
import { CONNECTIONS_INFO } from '../../../utils/hvn-routes';
import { action } from '@ember/object';

/**
 *
 * `NetworksConnectionInstructionsBanner` renders the instructions banner and no routes banner for a Network connection. This component can be used with `<NetworksConnectionInstructionsAccordion />`.
 *
 *
 * ```
 * <Networks::ConnectionInstructionsBanner
 *   @connection={{@model.peering}}
 *   @routes={{@model.routes}}
 *   @id='instructions'
 * />
 * ```
 *
 * @class NetworksConnectionInstructionsBanner
 *
 */

export default class NetworksConnectionInstructionsBannerComponent extends Component {
  @tracked connection = this.args.connection;
  @tracked routes = this.args.routes;
  @tracked id = this.args.id;

  connectionsInfo = CONNECTIONS_INFO;
  peeringActiveStatus = PEERING_ACTIVE_STATUS;

  @action
  goToAndShowInstructionsById(elementId) {
    document.getElementById(elementId).open = true;
    document.getElementById(elementId).scrollIntoView(true);
  }

  get providerText() {
    if (this.provider === 'aws') {
      return 'AWS';
    }

    if (this.provider === 'azure') {
      return 'Azure';
    }
    return '';
  }

  get noRoutesBanner() {
    let connectionCategory = '';
    let connectionType = '';
    let createHvnRoute = '';

    if (
      this.connection?.target?.awsTarget ||
      this.connection?.target?.azureTarget
    ) {
      connectionCategory = 'connection';
      connectionType = 'peering';
      createHvnRoute = this.connectionsInfo.peering.createRoute;
    }
    if (this.connection?.providerData) {
      connectionCategory = 'attachment';
      connectionType = 'transit gateway';
      createHvnRoute = this.connectionsInfo.tgwAttachment.createRoute;
    }

    return {
      connectionCategory,
      connectionType,
      createHvnRoute,
    };
  }

  get isHvnHvnPeering() {
    return Boolean(this.connection?.target?.hvnTarget?.hvn);
  }

  get provider() {
    return this.connection?.hvn?.location?.region?.provider;
  }

  get alerts() {
    if (
      this.connection?.target?.awsTarget ||
      this.connection?.target?.azureTarget
    ) {
      return PEERINGS_ALERTS;
    }
    if (this.connection?.providerData) {
      return TGW_ATTACHMENTS_ALERTS;
    }
    return '';
  }

  /**
   * `connection` is an object for a connection type that comes from the
   * cloud-network API.  It can either be a Peering or TGW Attachment.
   * @argument connection
   * @type {object}
   */

  /**
   * `routes` is an array of objects for hvn routes in a connection that comes
   * from the cloud-network API.  It can either be a Peering or TGW Attachment.
   * @argument routes
   * @type {array}
   */

  /**
   * `id` is the id that corresponds to the
   * `<Network::ConnectionInstructionsAccordion />` on the Connection detail
   * page.
   * @argument id
   * @type {string}
   */
}
