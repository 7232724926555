/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GetPricingModelTransitionsResponseTransitionFromJSON, GetPricingModelTransitionsResponseTransitionToJSON, } from './';
export function Billing20201105GetPricingModelTransitionsResponseFromJSON(json) {
    return Billing20201105GetPricingModelTransitionsResponseFromJSONTyped(json, false);
}
export function Billing20201105GetPricingModelTransitionsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'transitions': !exists(json, 'transitions') ? undefined : (json['transitions'].map(GetPricingModelTransitionsResponseTransitionFromJSON)),
    };
}
export function Billing20201105GetPricingModelTransitionsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'transitions': value.transitions === undefined ? undefined : (value.transitions.map(GetPricingModelTransitionsResponseTransitionToJSON)),
    };
}
