/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Operations
 * API for managing HCP operations.
 *
 * The version of the OpenAPI document: 2020-05-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export const BASE_PATH = 'http://localhost/operation/2020-05-05'.replace(/\/+$/, '');
const isBlob = value => typeof Blob !== 'undefined' && value instanceof Blob;
/**
 * This is the base class for all generated API classes.
 */
export class BaseAPI {
  constructor(configuration = new Configuration()) {
    this.configuration = configuration;
    this.fetchApi = async (url, init) => {
      let fetchParams = { url, init };
      for (const middleware of this.middleware) {
        if (middleware.pre) {
          fetchParams =
            (await middleware.pre({
              fetch: this.fetchApi,
              ...fetchParams,
            })) || fetchParams;
        }
      }
      let response = await this.configuration.fetchApi(fetchParams.url, fetchParams.init);
      for (const middleware of this.middleware) {
        if (middleware.post) {
          response =
            (await middleware.post({
              fetch: this.fetchApi,
              url,
              init,
              response: response.clone(),
            })) || response;
        }
      }
      return response;
    };
    this.middleware = configuration.middleware;
  }
  withMiddleware(...middlewares) {
    const next = this.clone();
    next.middleware = next.middleware.concat(...middlewares);
    return next;
  }
  withPreMiddleware(...preMiddlewares) {
    const middlewares = preMiddlewares.map(pre => ({ pre }));
    return this.withMiddleware(...middlewares);
  }
  withPostMiddleware(...postMiddlewares) {
    const middlewares = postMiddlewares.map(post => ({ post }));
    return this.withMiddleware(...middlewares);
  }
  async request(context) {
    const { url, init } = this.createFetchParams(context);
    const response = await this.fetchApi(url, init);
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    throw response;
  }
  createFetchParams(context) {
    let url = this.configuration.basePath + context.path;
    if (context.query !== undefined && Object.keys(context.query).length !== 0) {
      // only add the querystring to the URL if there are query parameters.
      // this is done to avoid urls ending with a "?" character which buggy webservers
      // do not handle correctly sometimes.
      url += '?' + this.configuration.queryParamsStringify(context.query);
    }
    const body =
      (typeof FormData !== 'undefined' && context.body instanceof FormData) ||
      context.body instanceof URLSearchParams ||
      isBlob(context.body)
        ? context.body
        : JSON.stringify(context.body);
    const headers = Object.assign({}, this.configuration.headers, context.headers);
    const init = {
      method: context.method,
      headers: headers,
      body,
      credentials: this.configuration.credentials,
    };
    return { url, init };
  }
  /**
   * Create a shallow clone of `this` by constructing a new instance
   * and then shallow cloning data members.
   */
  clone() {
    const constructor = this.constructor;
    const next = new constructor(this.configuration);
    next.middleware = this.middleware.slice();
    return next;
  }
}
export class RequiredError extends Error {
  constructor(field, msg) {
    super(msg);
    this.field = field;
    this.name = 'RequiredError';
  }
}
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};
export class Configuration {
  constructor(configuration = {}) {
    this.configuration = configuration;
  }
  get basePath() {
    return this.configuration.basePath != null ? this.configuration.basePath : BASE_PATH;
  }
  get fetchApi() {
    return this.configuration.fetchApi || window.fetch.bind(window);
  }
  get middleware() {
    return this.configuration.middleware || [];
  }
  get queryParamsStringify() {
    return this.configuration.queryParamsStringify || querystring;
  }
  get username() {
    return this.configuration.username;
  }
  get password() {
    return this.configuration.password;
  }
  get apiKey() {
    const apiKey = this.configuration.apiKey;
    if (apiKey) {
      return typeof apiKey === 'function' ? apiKey : () => apiKey;
    }
    return undefined;
  }
  get accessToken() {
    const accessToken = this.configuration.accessToken;
    if (accessToken) {
      return typeof accessToken === 'function' ? accessToken : () => accessToken;
    }
    return undefined;
  }
  get headers() {
    return this.configuration.headers;
  }
  get credentials() {
    return this.configuration.credentials;
  }
}
export function exists(json, key) {
  const value = json[key];
  return value !== null && value !== undefined;
}
export function querystring(params, prefix = '') {
  return Object.keys(params)
    .map(key => {
      const fullKey = prefix + (prefix.length ? `[${key}]` : key);
      const value = params[key];
      if (value instanceof Array) {
        const multiValue = value
          .map(singleValue => encodeURIComponent(String(singleValue)))
          .join(`&${encodeURIComponent(fullKey)}=`);
        return `${encodeURIComponent(fullKey)}=${multiValue}`;
      }
      if (value instanceof Object) {
        return querystring(value, fullKey);
      }
      return `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`;
    })
    .filter(part => part.length > 0)
    .join('&');
}
export function mapValues(data, fn) {
  return Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: fn(data[key]) }), {});
}
export function canConsumeForm(consumes) {
  for (const consume of consumes) {
    if ('multipart/form-data' === consume.contentType) {
      return true;
    }
  }
  return false;
}
export class JSONApiResponse {
  constructor(raw, transformer = jsonValue => jsonValue) {
    this.raw = raw;
    this.transformer = transformer;
  }
  async value() {
    return this.transformer(await this.raw.json());
  }
}
export class VoidApiResponse {
  constructor(raw) {
    this.raw = raw;
  }
  async value() {
    return undefined;
  }
}
export class BlobApiResponse {
  constructor(raw) {
    this.raw = raw;
  }
  async value() {
    return await this.raw.blob();
  }
}
export class TextApiResponse {
  constructor(raw) {
    this.raw = raw;
  }
  async value() {
    return await this.raw.text();
  }
}
