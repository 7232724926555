import { assert } from '@ember/debug';
import { BILLING_LOW_BALANCE } from '../constants/status';

export default class BillingCredits {
  #credits;

  constructor(value = 0) {
    this.#credits = parseFloat(value);
    assert(
      `value: ${this.#credits} must be valid number`,
      typeof this.#credits === 'number' && !Number.isNaN(this.#credits),
    );
  }

  get isLow() {
    return this.#credits <= BILLING_LOW_BALANCE && !this.isZero;
  }

  get isZero() {
    return this.#credits === 0;
  }

  valueOf() {
    return this.#credits;
  }
}
