/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp TFC Synchronization Service
 * API for managing TFC syncrhonization notifications and events.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudTfcSynchronizationGetNotificationResponseFromJSON, } from '../models';
/**
 *
 */
export class NotificationsServiceApi extends runtime.BaseAPI {
    /**
     * AcknowledgeNotification acknowledges a notification by the current user_principal_id.
     */
    async notificationsServiceAcknowledgeNotificationRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/tfc-synchronization/2024-05-03/notifications`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * AcknowledgeNotification acknowledges a notification by the current user_principal_id.
     */
    async notificationsServiceAcknowledgeNotification() {
        const response = await this.notificationsServiceAcknowledgeNotificationRaw();
        return await response.value();
    }
    /**
     * GetNotification returns a notification by a user_principal_id.option
     */
    async notificationsServiceGetNotificationRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/tfc-synchronization/2024-05-03/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudTfcSynchronizationGetNotificationResponseFromJSON(jsonValue));
    }
    /**
     * GetNotification returns a notification by a user_principal_id.option
     */
    async notificationsServiceGetNotification() {
        const response = await this.notificationsServiceGetNotificationRaw();
        return await response.value();
    }
}
