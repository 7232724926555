/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudConsul20210204VersionFromJSON, HashicorpCloudConsul20210204VersionToJSON, } from './';
export function HashicorpCloudConsul20210204ListUpgradeVersionsResponseFromJSON(json) {
    return HashicorpCloudConsul20210204ListUpgradeVersionsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204ListUpgradeVersionsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'versions': !exists(json, 'versions') ? undefined : (json['versions'].map(HashicorpCloudConsul20210204VersionFromJSON)),
    };
}
export function HashicorpCloudConsul20210204ListUpgradeVersionsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'versions': value.versions === undefined ? undefined : (value.versions.map(HashicorpCloudConsul20210204VersionToJSON)),
    };
}
