/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215ACLInfoFromJSON, HashicorpCloudGlobalNetworkManager20220215ACLInfoToJSON, HashicorpCloudGlobalNetworkManager20220215AutoPilotInfoFromJSON, HashicorpCloudGlobalNetworkManager20220215AutoPilotInfoToJSON, HashicorpCloudGlobalNetworkManager20220215RaftInfoFromJSON, HashicorpCloudGlobalNetworkManager20220215RaftInfoToJSON, HashicorpCloudGlobalNetworkManager20220215ServerTLSMetadataFromJSON, HashicorpCloudGlobalNetworkManager20220215ServerTLSMetadataToJSON, HashicorpCloudGlobalNetworkManager20220215TLSInfoFromJSON, HashicorpCloudGlobalNetworkManager20220215TLSInfoToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ServerStateFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ServerStateFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ServerStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'lanAddress': !exists(json, 'lan_address') ? undefined : json['lan_address'],
        'gossipPort': !exists(json, 'gossip_port') ? undefined : json['gossip_port'],
        'rpcPort': !exists(json, 'rpc_port') ? undefined : json['rpc_port'],
        'tls': !exists(json, 'tls') ? undefined : HashicorpCloudGlobalNetworkManager20220215TLSInfoFromJSON(json['tls']),
        'raft': !exists(json, 'raft') ? undefined : HashicorpCloudGlobalNetworkManager20220215RaftInfoFromJSON(json['raft']),
        'autopilot': !exists(json, 'autopilot') ? undefined : HashicorpCloudGlobalNetworkManager20220215AutoPilotInfoFromJSON(json['autopilot']),
        'scadaStatus': !exists(json, 'scada_status') ? undefined : json['scada_status'],
        'datacenter': !exists(json, 'datacenter') ? undefined : json['datacenter'],
        'acl': !exists(json, 'acl') ? undefined : HashicorpCloudGlobalNetworkManager20220215ACLInfoFromJSON(json['acl']),
        'serverTls': !exists(json, 'server_tls') ? undefined : HashicorpCloudGlobalNetworkManager20220215ServerTLSMetadataFromJSON(json['server_tls']),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ServerStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'id': value.id,
        'version': value.version,
        'lan_address': value.lanAddress,
        'gossip_port': value.gossipPort,
        'rpc_port': value.rpcPort,
        'tls': HashicorpCloudGlobalNetworkManager20220215TLSInfoToJSON(value.tls),
        'raft': HashicorpCloudGlobalNetworkManager20220215RaftInfoToJSON(value.raft),
        'autopilot': HashicorpCloudGlobalNetworkManager20220215AutoPilotInfoToJSON(value.autopilot),
        'scada_status': value.scadaStatus,
        'datacenter': value.datacenter,
        'acl': HashicorpCloudGlobalNetworkManager20220215ACLInfoToJSON(value.acl),
        'server_tls': HashicorpCloudGlobalNetworkManager20220215ServerTLSMetadataToJSON(value.serverTls),
    };
}
