/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128MongoDBAtlasIntegrationFromJSON, Secrets20231128MongoDBAtlasIntegrationToJSON, } from './';
export function Secrets20231128GetMongoDBAtlasIntegrationResponseFromJSON(json) {
    return Secrets20231128GetMongoDBAtlasIntegrationResponseFromJSONTyped(json, false);
}
export function Secrets20231128GetMongoDBAtlasIntegrationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'integration': !exists(json, 'integration') ? undefined : Secrets20231128MongoDBAtlasIntegrationFromJSON(json['integration']),
    };
}
export function Secrets20231128GetMongoDBAtlasIntegrationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'integration': Secrets20231128MongoDBAtlasIntegrationToJSON(value.integration),
    };
}
