/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * update_cause defines possible reasons for the update to the contract.
 * @export
 * @enum {string}
 */
export var Billing20201105ContractUpdateUpdateCause;
(function (Billing20201105ContractUpdateUpdateCause) {
    Billing20201105ContractUpdateUpdateCause["ADDON"] = "ADDON";
    Billing20201105ContractUpdateUpdateCause["EARLYRENEWAL"] = "EARLY_RENEWAL";
})(Billing20201105ContractUpdateUpdateCause || (Billing20201105ContractUpdateUpdateCause = {}));
export function Billing20201105ContractUpdateUpdateCauseFromJSON(json) {
    return Billing20201105ContractUpdateUpdateCauseFromJSONTyped(json, false);
}
export function Billing20201105ContractUpdateUpdateCauseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Billing20201105ContractUpdateUpdateCauseToJSON(value) {
    return value;
}
