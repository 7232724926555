/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudPacker20221202ModelsCreateRegistryBodyToJSON, HashicorpCloudPacker20221202ModelsCreateRegistryResponseFromJSON, HashicorpCloudPacker20221202ModelsDeleteRegistryResponseFromJSON, HashicorpCloudPacker20221202ModelsGetRegistryResponseFromJSON, HashicorpCloudPacker20221202ModelsUpdateRegistryBodyToJSON, HashicorpCloudPacker20221202ModelsUpdateRegistryResponseFromJSON, } from '../models';
/**
 *
 */
export class PackerRegistryServiceApi extends runtime.BaseAPI {
    /**
     * Creates the HCP Packer registry and starts billing for it.
     */
    async packerRegistryServiceCreateRegistryRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerRegistryServiceCreateRegistry.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerRegistryServiceCreateRegistry.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling packerRegistryServiceCreateRegistry.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/registry/v2`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudPacker20221202ModelsCreateRegistryBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsCreateRegistryResponseFromJSON(jsonValue));
    }
    /**
     * Creates the HCP Packer registry and starts billing for it.
     */
    async packerRegistryServiceCreateRegistry(requestParameters) {
        const response = await this.packerRegistryServiceCreateRegistryRaw(requestParameters);
        return await response.value();
    }
    /**
     * Deletes the HCP Packer registry and any associated resources from the database.
     */
    async packerRegistryServiceDeleteRegistryRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerRegistryServiceDeleteRegistry.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerRegistryServiceDeleteRegistry.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/registry/v2`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsDeleteRegistryResponseFromJSON(jsonValue));
    }
    /**
     * Deletes the HCP Packer registry and any associated resources from the database.
     */
    async packerRegistryServiceDeleteRegistry(requestParameters) {
        const response = await this.packerRegistryServiceDeleteRegistryRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieves the HCP Packer registry.
     */
    async packerRegistryServiceGetRegistryRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerRegistryServiceGetRegistry.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerRegistryServiceGetRegistry.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/registry/v2`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsGetRegistryResponseFromJSON(jsonValue));
    }
    /**
     * Retrieves the HCP Packer registry.
     */
    async packerRegistryServiceGetRegistry(requestParameters) {
        const response = await this.packerRegistryServiceGetRegistryRaw(requestParameters);
        return await response.value();
    }
    /**
     * Updates the feature tier _OR_ activates/deactivates the HCP Packer registry. Deactivating a registry will stop any additional billing for the resource.
     */
    async packerRegistryServiceUpdateRegistryRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerRegistryServiceUpdateRegistry.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerRegistryServiceUpdateRegistry.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling packerRegistryServiceUpdateRegistry.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/registry/v2`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudPacker20221202ModelsUpdateRegistryBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsUpdateRegistryResponseFromJSON(jsonValue));
    }
    /**
     * Updates the feature tier _OR_ activates/deactivates the HCP Packer registry. Deactivating a registry will stop any additional billing for the resource.
     */
    async packerRegistryServiceUpdateRegistry(requestParameters) {
        const response = await this.packerRegistryServiceUpdateRegistryRaw(requestParameters);
        return await response.value();
    }
}
