/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionCfgRefFromJSON, HashicorpCloudWaypointActionCfgRefToJSON, HashicorpCloudWaypointRefApplicationTemplateFromJSON, HashicorpCloudWaypointRefApplicationTemplateToJSON, HashicorpCloudWaypointTFOutputValueFromJSON, HashicorpCloudWaypointTFOutputValueToJSON, HashicorpCloudWaypointTagFromJSON, HashicorpCloudWaypointTagToJSON, } from './';
export function HashicorpCloudWaypointApplicationFromJSON(json) {
    return HashicorpCloudWaypointApplicationFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointApplicationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'readmeMarkdown': !exists(json, 'readme_markdown') ? undefined : json['readme_markdown'],
        'readme': !exists(json, 'readme') ? undefined : json['readme'],
        'templateName': !exists(json, 'template_name') ? undefined : json['template_name'],
        'applicationTemplate': !exists(json, 'application_template') ? undefined : HashicorpCloudWaypointRefApplicationTemplateFromJSON(json['application_template']),
        'tags': !exists(json, 'tags') ? undefined : (json['tags'].map(HashicorpCloudWaypointTagFromJSON)),
        'actionCfgRefs': !exists(json, 'action_cfg_refs') ? undefined : (json['action_cfg_refs'].map(HashicorpCloudWaypointActionCfgRefFromJSON)),
        'outputValues': !exists(json, 'output_values') ? undefined : (json['output_values'].map(HashicorpCloudWaypointTFOutputValueFromJSON)),
        'tfcWorkspaceId': !exists(json, 'tfc_workspace_id') ? undefined : json['tfc_workspace_id'],
    };
}
export function HashicorpCloudWaypointApplicationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'readme_markdown': value.readmeMarkdown,
        'readme': value.readme,
        'template_name': value.templateName,
        'application_template': HashicorpCloudWaypointRefApplicationTemplateToJSON(value.applicationTemplate),
        'tags': value.tags === undefined ? undefined : (value.tags.map(HashicorpCloudWaypointTagToJSON)),
        'action_cfg_refs': value.actionCfgRefs === undefined ? undefined : (value.actionCfgRefs.map(HashicorpCloudWaypointActionCfgRefToJSON)),
        'output_values': value.outputValues === undefined ? undefined : (value.outputValues.map(HashicorpCloudWaypointTFOutputValueToJSON)),
        'tfc_workspace_id': value.tfcWorkspaceId,
    };
}
