/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * IdentityType contains the identity types for users.
 *
 *  - UNSET: UNSET is the default value, should not be used.
 *  - SOCIAL_GITHUB: SOCIAL_GITHUB is the GitHub Social login provider.
 *  - EMAIL_PASSWORD: EMAIL_PASSWORD is the auth0 email/password database provider.
 *  - SAMLP: SAMLP is the auth0 SAML provider.
 *  - HASHICORP_SSO: HASHICORP_SSO is the HashiCorp SSO provider.
 * @export
 * @enum {string}
 */
export var HashicorpCloudIamUserPrincipalIdentityType;
(function (HashicorpCloudIamUserPrincipalIdentityType) {
    HashicorpCloudIamUserPrincipalIdentityType["UNSET"] = "UNSET";
    HashicorpCloudIamUserPrincipalIdentityType["SOCIALGITHUB"] = "SOCIAL_GITHUB";
    HashicorpCloudIamUserPrincipalIdentityType["EMAILPASSWORD"] = "EMAIL_PASSWORD";
    HashicorpCloudIamUserPrincipalIdentityType["SAMLP"] = "SAMLP";
    HashicorpCloudIamUserPrincipalIdentityType["HASHICORPSSO"] = "HASHICORP_SSO";
})(HashicorpCloudIamUserPrincipalIdentityType || (HashicorpCloudIamUserPrincipalIdentityType = {}));
export function HashicorpCloudIamUserPrincipalIdentityTypeFromJSON(json) {
    return HashicorpCloudIamUserPrincipalIdentityTypeFromJSONTyped(json, false);
}
export function HashicorpCloudIamUserPrincipalIdentityTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudIamUserPrincipalIdentityTypeToJSON(value) {
    return value;
}
