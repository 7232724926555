/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202RegistryConfigTierFromJSON, HashicorpCloudPacker20221202RegistryConfigTierToJSON, } from './';
export function HashicorpCloudPacker20221202CreateRegistryBodyFromJSON(json) {
    return HashicorpCloudPacker20221202CreateRegistryBodyFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202CreateRegistryBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'featureTier': !exists(json, 'feature_tier') ? undefined : HashicorpCloudPacker20221202RegistryConfigTierFromJSON(json['feature_tier']),
    };
}
export function HashicorpCloudPacker20221202CreateRegistryBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'feature_tier': HashicorpCloudPacker20221202RegistryConfigTierToJSON(value.featureTier),
    };
}
