/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudResourcemanagerPolicyBindingFromJSON, HashicorpCloudResourcemanagerPolicyBindingToJSON, } from './';
export function HashicorpCloudResourcemanagerPolicyFromJSON(json) {
    return HashicorpCloudResourcemanagerPolicyFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerPolicyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bindings': !exists(json, 'bindings') ? undefined : (json['bindings'].map(HashicorpCloudResourcemanagerPolicyBindingFromJSON)),
        'etag': !exists(json, 'etag') ? undefined : json['etag'],
    };
}
export function HashicorpCloudResourcemanagerPolicyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bindings': value.bindings === undefined ? undefined : (value.bindings.map(HashicorpCloudResourcemanagerPolicyBindingToJSON)),
        'etag': value.etag,
    };
}
