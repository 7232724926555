/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128TierFromJSON, Secrets20231128TierToJSON, } from './';
export function SecretServiceSetTierBodyFromJSON(json) {
    return SecretServiceSetTierBodyFromJSONTyped(json, false);
}
export function SecretServiceSetTierBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'tier': !exists(json, 'tier') ? undefined : Secrets20231128TierFromJSON(json['tier']),
    };
}
export function SecretServiceSetTierBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'project_id': value.projectId,
        'tier': Secrets20231128TierToJSON(value.tier),
    };
}
