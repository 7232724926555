/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * State is an enumeration of possible Version states.
 * @export
 * @enum {string}
 */
export var HashicorpCloudVagrant20220930VersionState;
(function (HashicorpCloudVagrant20220930VersionState) {
    HashicorpCloudVagrant20220930VersionState["UNRELEASED"] = "UNRELEASED";
    HashicorpCloudVagrant20220930VersionState["ACTIVE"] = "ACTIVE";
    HashicorpCloudVagrant20220930VersionState["REVOKED"] = "REVOKED";
})(HashicorpCloudVagrant20220930VersionState || (HashicorpCloudVagrant20220930VersionState = {}));
export function HashicorpCloudVagrant20220930VersionStateFromJSON(json) {
    return HashicorpCloudVagrant20220930VersionStateFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930VersionStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVagrant20220930VersionStateToJSON(value) {
    return value;
}
