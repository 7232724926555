/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105UnitFromJSON, Billing20201105UnitToJSON, PackageOptionDependencyFromJSON, PackageOptionDependencyToJSON, } from './';
export function Billing20201105PackageOptionFromJSON(json) {
    return Billing20201105PackageOptionFromJSONTyped(json, false);
}
export function Billing20201105PackageOptionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'unit': !exists(json, 'unit') ? undefined : Billing20201105UnitFromJSON(json['unit']),
        'unitPrice': !exists(json, 'unit_price') ? undefined : json['unit_price'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'dependencies': !exists(json, 'dependencies') ? undefined : (json['dependencies'].map(PackageOptionDependencyFromJSON)),
        'unitDisplayKey': !exists(json, 'unit_display_key') ? undefined : json['unit_display_key'],
    };
}
export function Billing20201105PackageOptionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'unit': Billing20201105UnitToJSON(value.unit),
        'unit_price': value.unitPrice,
        'key': value.key,
        'dependencies': value.dependencies === undefined ? undefined : (value.dependencies.map(PackageOptionDependencyToJSON)),
        'unit_display_key': value.unitDisplayKey,
    };
}
