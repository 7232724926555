/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128CapabilityFromJSON, Secrets20231128CapabilityToJSON, Secrets20231128ConfluentStaticCredentialsRequestFromJSON, Secrets20231128ConfluentStaticCredentialsRequestToJSON, } from './';
export function SecretServiceUpdateConfluentIntegrationBodyFromJSON(json) {
    return SecretServiceUpdateConfluentIntegrationBodyFromJSONTyped(json, false);
}
export function SecretServiceUpdateConfluentIntegrationBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'capabilities': !exists(json, 'capabilities') ? undefined : (json['capabilities'].map(Secrets20231128CapabilityFromJSON)),
        'rotateOnUpdate': !exists(json, 'rotate_on_update') ? undefined : json['rotate_on_update'],
        'staticCredentialDetails': !exists(json, 'static_credential_details') ? undefined : Secrets20231128ConfluentStaticCredentialsRequestFromJSON(json['static_credential_details']),
    };
}
export function SecretServiceUpdateConfluentIntegrationBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'capabilities': value.capabilities === undefined ? undefined : (value.capabilities.map(Secrets20231128CapabilityToJSON)),
        'rotate_on_update': value.rotateOnUpdate,
        'static_credential_details': Secrets20231128ConfluentStaticCredentialsRequestToJSON(value.staticCredentialDetails),
    };
}
