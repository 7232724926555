import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';

const defaultBlacklistedRoles: Array<HashicorpCloudResourcemanagerRole['id']> =
  ['roles/some-role*'];

export function filterBlacklistedRoles(
  roles: Array<HashicorpCloudResourcemanagerRole> = [],
  blacklistedRoles: Array<
    HashicorpCloudResourcemanagerRole['id']
  > = defaultBlacklistedRoles,
): Array<HashicorpCloudResourcemanagerRole> {
  return roles.filter((role) => {
    return !blacklistedRoles.some((blacklistedRole) => {
      if (blacklistedRole?.endsWith('*')) {
        // If the blacklistedRole ends with '*', check for a prefix match.
        // Remove the '*' before comparison.
        const prefix = blacklistedRole.slice(0, -1);
        return role?.id?.startsWith(prefix);
      } else {
        // Exact match check.
        return role.id === blacklistedRole;
      }
    });
  });
}

export default filterBlacklistedRoles;
