// this file is used for ember-intl by consumer app/engine
import { DATE_DISPLAY_FORMATS } from './services/time.js';

export default {
  time: {},
  date: {
    ...DATE_DISPLAY_FORMATS,
    month: {
      month: 'long',
    },
  },
  number: {
    EUR: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    USD: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    USD_UNIT_PRICE: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    },
  },
};
