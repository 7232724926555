import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { typeOf } from '@ember/utils';

import type { IntlService } from 'ember-intl';

import {
  COPY_SUCCESS_ICON,
  COPY_ACTION_ICON,
} from '../utils/consts/copy-card.js';

interface CopyCardSignature {
  Args: {
    buttonDescription?: string;
    clipboardHref?: string;
    clipboardText?: string;
    container?: string;
    copyText?: string;
    isMultiline?: boolean;
    onSuccess?: () => void;
    showCopied?: boolean;
    title?: string;
  };
  Element: HTMLElement;
}

/**
 *
 * `CopyCard` is a box with text, a label, and a CopyButton.
 *
 * ```
 * <CopyCard
 *   @title={{'Admin Token'}}
 *   @clipboardText={{this.adminToken}}
 *   @showCopied={{true|false}}
 *   @onSuccess={{this.onSuccess}}
 * />
 * ```
 *
 * @class CopyCard
 *
 */

/**
 * The title/label for the text to copy.
 * @argument title
 * @type {?string}
 */

/**
 * The content to be copied. If `@clipboardHref` is also provided,
 * `@clipboardHref` takes precedence.
 * @argument clipboardText
 * @type {?string}
 */

/**
 * The content to be copied. If  `@clipboardText` and `@clipboardHref` are
 * specified , `@clipboardHref` takes precedence.
 * @argument clipboardHref
 * @type {?string}
 */

/**
 * Whether to display the post copy text or not.
 * @argument showCopied
 * @type {?boolean}
 */

/**
 * Whether or not the text is a multiline string. If so, the content will be
 * wrapped in a <pre> element instead of <code>.
 * @argument isMultiline
 * @type {?boolean}
 */

/**
 * Function to be executed upon successful copy
 * @argument onSuccess
 * @type {?function}
 */

/**
 * If `<CopyCard />` is in a modal, specify the container class/selector.
 * @argument container
 * @type {?string}
 */

/**
 * Override default description of the copy button for accessibility.
 * @argument buttonDescription
 * @type {?string}
 */

export default class CopyCardComponent extends Component<CopyCardSignature> {
  @service declare readonly intl: IntlService;

  @tracked copyText: string = '';

  get copied() {
    const { copyText } = this.args;
    return copyText ?? this.copyText;
  }

  get icon() {
    const copied = this.copied;
    return copied ? COPY_SUCCESS_ICON : COPY_ACTION_ICON;
  }

  get isMultiline() {
    return this.args.isMultiline ?? false;
  }

  get showCopied() {
    return this.args.showCopied ?? true;
  }

  @task
  *onCopySuccess() {
    const { onSuccess } = this.args;

    this.copyText = this.intl.t('components.copy-card.copied');

    if (onSuccess && typeOf(this.args.onSuccess) === 'function') {
      yield this.args.onSuccess?.();
    }

    yield timeout(1000);

    this.copyText = '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CopyCard: typeof CopyCardComponent;
    'copy-card': typeof CopyCardComponent;
  }
}
