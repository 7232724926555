/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Operations
 * API for managing HCP operations.
 *
 * The version of the OpenAPI document: 2020-05-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudOperationOperationFromJSON, HashicorpCloudOperationOperationToJSON } from './';
export function HashicorpCloudOperationWaitResponseFromJSON(json) {
  return HashicorpCloudOperationWaitResponseFromJSONTyped(json, false);
}
export function HashicorpCloudOperationWaitResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    operation: !exists(json, 'operation')
      ? undefined
      : HashicorpCloudOperationOperationFromJSON(json['operation']),
  };
}
export function HashicorpCloudOperationWaitResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    operation: HashicorpCloudOperationOperationToJSON(value.operation),
  };
}
