/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330EventTypeFromJSON, LogService20210330EventTypeToJSON, } from './';
export function LogService20210330ServiceLogEventFromJSON(json) {
    return LogService20210330ServiceLogEventFromJSONTyped(json, false);
}
export function LogService20210330ServiceLogEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'logName': !exists(json, 'log_name') ? undefined : json['log_name'],
        'eventType': !exists(json, 'event_type') ? undefined : LogService20210330EventTypeFromJSON(json['event_type']),
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'insertId': !exists(json, 'insert_id') ? undefined : json['insert_id'],
        'textPayload': !exists(json, 'text_payload') ? undefined : json['text_payload'],
        'jsonPayload': !exists(json, 'json_payload') ? undefined : json['json_payload'],
    };
}
export function LogService20210330ServiceLogEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'log_name': value.logName,
        'event_type': LogService20210330EventTypeToJSON(value.eventType),
        'metadata': value.metadata,
        'insert_id': value.insertId,
        'text_payload': value.textPayload,
        'json_payload': value.jsonPayload,
    };
}
