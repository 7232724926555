/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125AuditConfigFromJSON, HashicorpCloudVault20201125AuditConfigToJSON, HashicorpCloudVault20201125CapacityConfigFromJSON, HashicorpCloudVault20201125CapacityConfigToJSON, HashicorpCloudVault20201125DisasterRecoveryNetworkConfigFromJSON, HashicorpCloudVault20201125DisasterRecoveryNetworkConfigToJSON, HashicorpCloudVault20201125MajorVersionUpgradeConfigFromJSON, HashicorpCloudVault20201125MajorVersionUpgradeConfigToJSON, HashicorpCloudVault20201125NetworkConfigFromJSON, HashicorpCloudVault20201125NetworkConfigToJSON, HashicorpCloudVault20201125ObservabilityConfigFromJSON, HashicorpCloudVault20201125ObservabilityConfigToJSON, HashicorpCloudVault20201125SnapshotConfigFromJSON, HashicorpCloudVault20201125SnapshotConfigToJSON, HashicorpCloudVault20201125TierFromJSON, HashicorpCloudVault20201125TierToJSON, HashicorpCloudVault20201125VaultAccessFromJSON, HashicorpCloudVault20201125VaultAccessToJSON, HashicorpCloudVault20201125VaultConfigFromJSON, HashicorpCloudVault20201125VaultConfigToJSON, } from './';
export function HashicorpCloudVault20201125ClusterConfigFromJSON(json) {
    return HashicorpCloudVault20201125ClusterConfigFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125ClusterConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'vaultConfig': !exists(json, 'vault_config') ? undefined : HashicorpCloudVault20201125VaultConfigFromJSON(json['vault_config']),
        'networkConfig': !exists(json, 'network_config') ? undefined : HashicorpCloudVault20201125NetworkConfigFromJSON(json['network_config']),
        'maintenanceConfig': !exists(json, 'maintenance_config') ? undefined : json['maintenance_config'],
        'snapshotConfig': !exists(json, 'snapshot_config') ? undefined : HashicorpCloudVault20201125SnapshotConfigFromJSON(json['snapshot_config']),
        'capacityConfig': !exists(json, 'capacity_config') ? undefined : HashicorpCloudVault20201125CapacityConfigFromJSON(json['capacity_config']),
        'vaultAccess': !exists(json, 'vault_access') ? undefined : HashicorpCloudVault20201125VaultAccessFromJSON(json['vault_access']),
        'auditConfig': !exists(json, 'audit_config') ? undefined : HashicorpCloudVault20201125AuditConfigFromJSON(json['audit_config']),
        'tier': !exists(json, 'tier') ? undefined : HashicorpCloudVault20201125TierFromJSON(json['tier']),
        'metricsConfig': !exists(json, 'metrics_config') ? undefined : HashicorpCloudVault20201125ObservabilityConfigFromJSON(json['metrics_config']),
        'auditLogExportConfig': !exists(json, 'audit_log_export_config') ? undefined : HashicorpCloudVault20201125ObservabilityConfigFromJSON(json['audit_log_export_config']),
        'majorVersionUpgradeConfig': !exists(json, 'major_version_upgrade_config') ? undefined : HashicorpCloudVault20201125MajorVersionUpgradeConfigFromJSON(json['major_version_upgrade_config']),
        'disasterRecoveryNetworkConfig': !exists(json, 'disaster_recovery_network_config') ? undefined : HashicorpCloudVault20201125DisasterRecoveryNetworkConfigFromJSON(json['disaster_recovery_network_config']),
    };
}
export function HashicorpCloudVault20201125ClusterConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'vault_config': HashicorpCloudVault20201125VaultConfigToJSON(value.vaultConfig),
        'network_config': HashicorpCloudVault20201125NetworkConfigToJSON(value.networkConfig),
        'maintenance_config': value.maintenanceConfig,
        'snapshot_config': HashicorpCloudVault20201125SnapshotConfigToJSON(value.snapshotConfig),
        'capacity_config': HashicorpCloudVault20201125CapacityConfigToJSON(value.capacityConfig),
        'vault_access': HashicorpCloudVault20201125VaultAccessToJSON(value.vaultAccess),
        'audit_config': HashicorpCloudVault20201125AuditConfigToJSON(value.auditConfig),
        'tier': HashicorpCloudVault20201125TierToJSON(value.tier),
        'metrics_config': HashicorpCloudVault20201125ObservabilityConfigToJSON(value.metricsConfig),
        'audit_log_export_config': HashicorpCloudVault20201125ObservabilityConfigToJSON(value.auditLogExportConfig),
        'major_version_upgrade_config': HashicorpCloudVault20201125MajorVersionUpgradeConfigToJSON(value.majorVersionUpgradeConfig),
        'disaster_recovery_network_config': HashicorpCloudVault20201125DisasterRecoveryNetworkConfigToJSON(value.disasterRecoveryNetworkConfig),
    };
}
