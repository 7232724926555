/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - AVAILABLE: AVAILABLE represents a version that is generally available
 * but no longer the preferred/recommended version.
 *  - RECOMMENDED: RECOMMENDED represents a version that is generally available
 * and recommended by HashiCorp.
 *  - PREVIEW: PREVIEW represents a version that is not generally available.
 * @export
 * @enum {string}
 */
export var HashicorpCloudConsul20210204VersionStatus;
(function (HashicorpCloudConsul20210204VersionStatus) {
    HashicorpCloudConsul20210204VersionStatus["AVAILABLE"] = "AVAILABLE";
    HashicorpCloudConsul20210204VersionStatus["RECOMMENDED"] = "RECOMMENDED";
    HashicorpCloudConsul20210204VersionStatus["PREVIEW"] = "PREVIEW";
})(HashicorpCloudConsul20210204VersionStatus || (HashicorpCloudConsul20210204VersionStatus = {}));
export function HashicorpCloudConsul20210204VersionStatusFromJSON(json) {
    return HashicorpCloudConsul20210204VersionStatusFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204VersionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudConsul20210204VersionStatusToJSON(value) {
    return value;
}
