/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105ConsumptionPoolStatusFromJSON, Billing20201105ConsumptionPoolStatusToJSON, Billing20201105SupportLevelFromJSON, Billing20201105SupportLevelToJSON, Cloudbilling20201105DiscountFromJSON, Cloudbilling20201105DiscountToJSON, } from './';
export function Billing20201105ConsumptionPoolFromJSON(json) {
    return Billing20201105ConsumptionPoolFromJSONTyped(json, false);
}
export function Billing20201105ConsumptionPoolFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'billingAccountId': !exists(json, 'billing_account_id') ? undefined : json['billing_account_id'],
        'activeFrom': !exists(json, 'active_from') ? undefined : (new Date(json['active_from'])),
        'activeUntil': !exists(json, 'active_until') ? undefined : (new Date(json['active_until'])),
        'initialAmount': !exists(json, 'initial_amount') ? undefined : json['initial_amount'],
        'recognizedBalance': !exists(json, 'recognized_balance') ? undefined : json['recognized_balance'],
        'runningTotal': !exists(json, 'running_total') ? undefined : json['running_total'],
        'supportLevel': !exists(json, 'support_level') ? undefined : json['support_level'],
        'discounts': !exists(json, 'discounts') ? undefined : (json['discounts'].map(Cloudbilling20201105DiscountFromJSON)),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : Billing20201105ConsumptionPoolStatusFromJSON(json['status']),
        'supportLevels': !exists(json, 'support_levels') ? undefined : (json['support_levels'].map(Billing20201105SupportLevelFromJSON)),
        'rollInPercentage': !exists(json, 'roll_in_percentage') ? undefined : json['roll_in_percentage'],
    };
}
export function Billing20201105ConsumptionPoolToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'billing_account_id': value.billingAccountId,
        'active_from': value.activeFrom === undefined ? undefined : (value.activeFrom.toISOString()),
        'active_until': value.activeUntil === undefined ? undefined : (value.activeUntil.toISOString()),
        'initial_amount': value.initialAmount,
        'recognized_balance': value.recognizedBalance,
        'running_total': value.runningTotal,
        'support_level': value.supportLevel,
        'discounts': value.discounts === undefined ? undefined : (value.discounts.map(Cloudbilling20201105DiscountToJSON)),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'id': value.id,
        'status': Billing20201105ConsumptionPoolStatusToJSON(value.status),
        'support_levels': value.supportLevels === undefined ? undefined : (value.supportLevels.map(Billing20201105SupportLevelToJSON)),
        'roll_in_percentage': value.rollInPercentage,
    };
}
