import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

const groupMemberPrincipalTypes = ['GROUP_MEMBER_PRINCIPAL_TYPE_USER']; //if principal is in the group

// This service tracks principal members or non-members of a group.
// idsToAdd tracks new members a user wants to add to a group.
// idsToRemove tracks IDS of existing members a user wants to remove from a group.
// idsOfCurrentMembers tracks the ids of existing members in a group in idsOfCurrentMembers.
// hasChanged if an existing group membership has changed before a user saves their changes.

export default class groupMembersService extends Service {
  @tracked idsToAdd = [];
  @tracked idsToRemove = [];
  @tracked idsOfCurrentMembers = [];
  @tracked hasChanged = false;

  reset() {
    this.hasChanged = false;
    this.idsToAdd = [];
    this.idsToRemove = [];
    this.idsOfCurrentMembers = [];
    return Promise.resolve();
  }

  addMemberById(principalId) {
    //Only add principals who are not in the group
    if (this.idsOfCurrentMembers.indexOf(principalId) === -1) {
      if (this.idsToAdd.indexOf(principalId) === -1) {
        this.idsToAdd = [...this.idsToAdd, principalId];
      }
    }
    // If principal is a group member and was unchecked then re-checked,
    // remove them from idsToRemove but do not add them to the idsToAdd list
    if (this.idsOfCurrentMembers.indexOf(principalId) > -1) {
      const index = this.idsToRemove.indexOf(principalId);
      if (index > -1) {
        let idsToRemove = this.idsToRemove;
        idsToRemove.splice(index, 1);
        this.idsToRemove = [...idsToRemove];
      }
    }
    this.hasChanged =
      this.idsToAdd.length > 0 || this.idsToRemove.length > 0 ? true : false;
    return Promise.resolve();
  }

  removeMemberById(principalId) {
    // If the principal is not a group member and is in the idsToAdd array
    // remove them from idsToAdd
    if (this.idsOfCurrentMembers.indexOf(principalId) === -1) {
      const index = this.idsToAdd.indexOf(principalId);
      if (index > -1) {
        let idsToAdd = this.idsToAdd;
        idsToAdd.splice(index, 1);
        this.idsToAdd = [...idsToAdd];
      }
    }

    // If the principal is a group member
    // add them to idsToRemove
    if (this.idsOfCurrentMembers.indexOf(principalId) > -1) {
      if (this.idsToRemove.indexOf(principalId) === -1) {
        this.idsToRemove = [...this.idsToRemove, principalId];
      }
    }
    this.hasChanged =
      this.idsToAdd.length > 0 || this.idsToRemove.length > 0 ? true : false;
    return Promise.resolve();
  }
  // get the current members ids
  setCurrentMembersIds(principal) {
    if (
      groupMemberPrincipalTypes.indexOf(principal.principalType) > -1 &&
      this.idsOfCurrentMembers.indexOf(principal.id) === -1
    ) {
      this.idsOfCurrentMembers = [...this.idsOfCurrentMembers, principal.id];
    }
    return Promise.resolve();
  }
}
