/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202IterationFromJSON, HashicorpCloudPacker20221202IterationToJSON, } from './';
export function HashicorpCloudPacker20221202ChannelFromJSON(json) {
    return HashicorpCloudPacker20221202ChannelFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ChannelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'bucketSlug': !exists(json, 'bucket_slug') ? undefined : json['bucket_slug'],
        'authorId': !exists(json, 'author_id') ? undefined : json['author_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'iteration': !exists(json, 'iteration') ? undefined : HashicorpCloudPacker20221202IterationFromJSON(json['iteration']),
        'managed': !exists(json, 'managed') ? undefined : json['managed'],
        'restricted': !exists(json, 'restricted') ? undefined : json['restricted'],
    };
}
export function HashicorpCloudPacker20221202ChannelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'slug': value.slug,
        'bucket_slug': value.bucketSlug,
        'author_id': value.authorId,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'iteration': HashicorpCloudPacker20221202IterationToJSON(value.iteration),
        'managed': value.managed,
        'restricted': value.restricted,
    };
}
