/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Operations
 * API for managing HCP operations.
 *
 * The version of the OpenAPI document: 2020-05-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GoogleProtobufAnyFromJSON, GoogleProtobufAnyToJSON } from './';
export function GoogleRpcStatusFromJSON(json) {
  return GoogleRpcStatusFromJSONTyped(json, false);
}
export function GoogleRpcStatusFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: !exists(json, 'code') ? undefined : json['code'],
    message: !exists(json, 'message') ? undefined : json['message'],
    details: !exists(json, 'details') ? undefined : json['details'].map(GoogleProtobufAnyFromJSON),
  };
}
export function GoogleRpcStatusToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    message: value.message,
    details: value.details === undefined ? undefined : value.details.map(GoogleProtobufAnyToJSON),
  };
}
