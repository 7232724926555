/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import tippy from 'tippy.js';

interface TooltipSignature {
  Args: {
    tooltipOptions: Record<string, unknown>;
  };
  Blocks: {
    anchor: [];
    tooltip: [];
  };
}

export default class TooltipComponent extends Component<TooltipSignature> {
  // Tippy parts
  // @ts-expect-error
  @tracked tooltipContent;
  // @ts-expect-error
  @tracked anchor;
  // @ts-expect-error
  @tracked arrow;

  // Tippy instance
  // @ts-expect-error
  @tracked tooltip;

  @action
  captureContent(tooltipContent: any) {
    this.tooltipContent = tooltipContent;
    this.createTooltip();
  }

  @action
  captureAnchor(anchor: any) {
    this.anchor = anchor;
    this.createTooltip();
  }

  @action
  captureArrow(arrow: any) {
    this.arrow = arrow;
    this.createTooltip();
  }

  createTooltip() {
    // Only create the tooltip once the anchor, arrow, and content have all been
    // captured. This casuses a flaky race condition in tests. The test helper
    // accounts for this and should be refactored eventually.
    if (!this.tooltip && (!this.tooltipContent || !this.anchor || !this.arrow))
      return;

    const tippyOptions = {
      content: this.tooltipContent,
      arrow: this.arrow,
      interactive: true,
      delay: 0,
      duration: 0,
      ...this.args.tooltipOptions, // see https://atomiks.github.io/tippyjs/v6/all-props/ for list of available options
    };

    this.tooltip = tippy(this.anchor, tippyOptions);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Tooltip: typeof TooltipComponent;
    tooltip: typeof TooltipComponent;
  }
}
