/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVault20201125IsVaultPluginRegisteredResponseFromJSON(json) {
    return HashicorpCloudVault20201125IsVaultPluginRegisteredResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125IsVaultPluginRegisteredResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isRegistered': !exists(json, 'is_registered') ? undefined : json['is_registered'],
    };
}
export function HashicorpCloudVault20201125IsVaultPluginRegisteredResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'is_registered': value.isRegistered,
    };
}
