/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function LogService20210330RequestInfoFromJSON(json) {
    return LogService20210330RequestInfoFromJSONTyped(json, false);
}
export function LogService20210330RequestInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'httpVerb': !exists(json, 'http_verb') ? undefined : json['http_verb'],
        'httpPath': !exists(json, 'http_path') ? undefined : json['http_path'],
        'httpUserAgent': !exists(json, 'http_user_agent') ? undefined : json['http_user_agent'],
        'httpClientIp': !exists(json, 'http_client_ip') ? undefined : json['http_client_ip'],
    };
}
export function LogService20210330RequestInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'http_verb': value.httpVerb,
        'http_path': value.httpPath,
        'http_user_agent': value.httpUserAgent,
        'http_client_ip': value.httpClientIp,
    };
}
