/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudConsulTelemetry20230414ValueFromJSON, HashicorpCloudConsulTelemetry20230414ValueToJSON, } from './';
export function HashicorpCloudConsulTelemetry20230414MetricMatrixFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414MetricMatrixFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414MetricMatrixFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'metric': !exists(json, 'metric') ? undefined : json['metric'],
        'values': !exists(json, 'values') ? undefined : (json['values'].map(HashicorpCloudConsulTelemetry20230414ValueFromJSON)),
    };
}
export function HashicorpCloudConsulTelemetry20230414MetricMatrixToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'metric': value.metric,
        'values': value.values === undefined ? undefined : (value.values.map(HashicorpCloudConsulTelemetry20230414ValueToJSON)),
    };
}
