import Service from '@ember/service';
import { assert } from '@ember/debug';

import {
  RESOURCE_TIER_I18N_PREFIX,
  RESOURCE_TIER_SIZE_I18N_PREFIX,
} from '../constants/index.ts';

import type {
  ResourceCatalog,
  ResourceTierData,
  ResourceTierSizeData,
} from '../types/resource-catalog.ts';

const isNotEmpty = <T>(val: T | null | undefined): val is T =>
  val !== null && val !== undefined;

export default class ResourceCatalogService extends Service {
  /**
   * A Object literal which defines the available tiers (billing key as the key) with the tier itself as the value.
   */
  resourceMap = new Map<string, ResourceCatalog>();

  addResource(resource: ResourceCatalog) {
    this.resourceMap.set(resource.id, resource);

    return this.resourceMap;
  }

  /** Gets the resource by id. */
  getResource(resourceType: string): ResourceCatalog | undefined {
    const keys = Array.from(this.resourceMap.keys());
    assert(
      `@resourceType for getResource must be one of the following: ${keys.join(
        ', ',
      )}, receieved: ${resourceType}`,
      keys.includes(resourceType),
    );

    return this.resourceMap.get(resourceType);
  }

  /**
   * Gets the size by resourceType, size key, and tier key. The keys must be explicitly
   * defined on the tier mapping object even if the value is an empty object.
   * The object's keys will be spread to override or extend what gets generated
   * here as the return value.
   */
  getSize(
    resourceType: string,
    tierKey: string,
    sizeKey: string,
    provider: string,
    showCostData = true,
  ): ResourceTierSizeData | null {
    const resource = this.getResource(resourceType);

    if (!resource) {
      return null;
    }

    const { icon } = resource;
    const i18namespace = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${resourceType}.${tierKey}.${sizeKey}`;
    const tier = this.getTier(resourceType, tierKey, provider);

    if (!tier) {
      return null;
    }

    const sizes = tier.sizes;
    const sizeKeys = sizes.map((size) => size.key);
    const size = sizes.find((size) => size.key === sizeKey);

    assert(
      `@sizeKey for getSize must be one of the following for ${resourceType}: (${sizeKeys.join(
        ', ',
      )}, receieved: ${sizeKey}`,
      sizeKeys.includes(sizeKey) !== false,
    );

    if (!size) {
      return null;
    }

    const { features, ...restOfSize } = size;

    return {
      icon,
      id: `${resourceType}::${tierKey}::${sizeKey}`,
      name: `${i18namespace}.name`,
      price: `${i18namespace}.price`,
      resourceType,
      serviceInstances: `${i18namespace}.service-instances`,
      clientCost: `${i18namespace}.client-cost`,
      size: `${i18namespace}.size`,
      tier,
      tierKey,
      features: showCostData
        ? features
        : features.filter((f) => !f.includes('service-instance-price')),
      ...restOfSize,
    };
  }

  /**
   * Gets the tier by resourceType and tier key. The keys must be explicitly
   * defined on the tier mapping object even if the value is an empty object.
   * The object's keys will be spread to override or extend what gets generated
   * here as the return value.
   */
  getTier(
    resourceType: string,
    tierKey: string,
    provider: string,
  ): ResourceTierData | null {
    const resource = this.getResource(resourceType);
    if (!resource) {
      return null;
    }

    const { icon, tiers } = resource;

    if (!provider) {
      assert(
        `@provider for getTier must be one of the following: 'aws', 'azure'`,
      );
    }

    const providerTiers = tiers[provider];
    const i18namespace = `${RESOURCE_TIER_I18N_PREFIX}.${resourceType}.${tierKey}`;
    // @ts-expect-error: adding this to suppress error while upgrading to ember v4, please fix me
    const tierKeys = Object.keys(providerTiers);

    assert(
      `@tierKey for getTier must be one of the following for ${resourceType}: (${tierKeys.join(
        ', ',
      )}, receieved: ${tierKey}`,
      tierKeys.includes(tierKey),
    );

    // @ts-expect-error: adding this to suppress error while upgrading to ember v4, please fix me
    return {
      description: `${i18namespace}.description`,
      icon,
      id: `${resourceType}::${tierKey}`,
      key: tierKey,
      name: `${i18namespace}.name`,
      resourceType,
      // @ts-expect-error: adding this to suppress error while upgrading to ember v4, please fix me
      ...providerTiers[tierKey],
    };
  }

  /**
   * Gets the tier by resourceType and tier value. This is generally for use when
   * it's necessary to map the API's tier value to the tier's key. Unfortunately
   * the API does not use the key when creating or returning cluster data.
   */
  getTierKeyByValue(
    resourceType: string,
    tierValue: string,
    provider: string,
  ): string | undefined {
    const resource = this.getResource(resourceType);
    if (!resource) {
      return;
    }

    const { tiers } = resource;
    const providerTiers = tiers[provider];
    // @ts-expect-error: adding this to suppress error while upgrading to ember v4, please fix me
    const tier = Object.entries(providerTiers)
      .map(([tierKey, tier]) => {
        return { ...tier, key: tierKey };
      })
      .find((tier) => tier.value === tierValue);

    if (!tier) {
      return;
    }

    return tier.key;
  }

  /**
   * Gets the tier by resourceType and tier value. This is generally for use when
   * it's necessary to map the API's tier value to the tier's key. Unfortunately
   * the API does not use the key when creating or returning cluster data.
   */
  getTierSizeKeyByValue(
    resourceType: string,
    tierValue: string,
    sizeValue: string,
    provider: string,
  ): string | undefined {
    const tierKey = this.getTierKeyByValue(resourceType, tierValue, provider);
    if (!tierKey) {
      return;
    }
    const tier = this.getTier(resourceType, tierKey, provider);
    if (!tier) {
      return;
    }
    const size = tier.sizes.find((size) => size.value === sizeValue);

    if (!size) {
      return;
    }

    return size.key;
  }

  /** Reduces resource map data into a list of sizes. */
  getTierSizeScale(provider: string): ResourceTierSizeData[] {
    return Array.from(this.resourceMap.values()).reduce<ResourceTierSizeData[]>(
      (sizes, resource) => {
        return [
          ...sizes,
          // @ts-expect-error: adding this to suppress error while upgrading to ember v4, please fix me
          ...Object.keys(resource.tiers[provider]).reduce<
            ResourceTierSizeData[]
          >((tierSizes, tierKey) => {
            const tier = this.getTier(resource.id, tierKey, provider);
            return tier
              ? [
                  ...tierSizes,
                  ...tier.sizes
                    .map((size) => {
                      return this.getSize(
                        resource.id,
                        tierKey,
                        size.key,
                        provider,
                      );
                    })
                    .filter(isNotEmpty),
                ]
              : tierSizes;
          }, []),
        ];
      },
      [],
    );
  }

  /** Reduces resource map data into a list of tiers. */
  getTierScale(provider: string): ResourceTierData[] {
    // @ts-expect-error: adding this to suppress error while upgrading to ember v4, please fix me
    return Array.from(this.resourceMap.values()).reduce<ResourceTierData[]>(
      // @ts-expect-error: adding this to suppress error while upgrading to ember v4, please fix me
      (tiers, resource) => {
        return [
          ...tiers,
          // @ts-expect-error: adding this to suppress error while upgrading to ember v4, please fix me
          ...Object.keys(resource.tiers[provider]).reduce(
            // @ts-expect-error: adding this to suppress error while upgrading to ember v4, please fix me
            (tierFlavors, tierKey) => {
              return [
                ...tierFlavors,
                this.getTier(resource.id, tierKey, provider),
              ].filter(isNotEmpty);
            },
            [],
          ),
        ];
      },
      [],
    );
  }

  /** Reduces resource map data into a list of tiers. */
  get resourceScale(): ResourceCatalog[] {
    return Array.from(this.resourceMap.values());
  }
}
