/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LocationRegionFromJSON, LocationRegionToJSON, } from './';
export function CloudlocationLocationFromJSON(json) {
    return CloudlocationLocationFromJSONTyped(json, false);
}
export function CloudlocationLocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'region': !exists(json, 'region') ? undefined : LocationRegionFromJSON(json['region']),
    };
}
export function CloudlocationLocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'project_id': value.projectId,
        'region': LocationRegionToJSON(value.region),
    };
}
