/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - MANUAL: This version was revoked manually.
 *  - INHERITED: This version inherited a revocation from an upstream ancestor and was revoked automatically.
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202ModelsRevocationType;
(function (HashicorpCloudPacker20221202ModelsRevocationType) {
    HashicorpCloudPacker20221202ModelsRevocationType["REVOCATIONTYPEUNSET"] = "REVOCATION_TYPE_UNSET";
    HashicorpCloudPacker20221202ModelsRevocationType["MANUAL"] = "MANUAL";
    HashicorpCloudPacker20221202ModelsRevocationType["INHERITED"] = "INHERITED";
})(HashicorpCloudPacker20221202ModelsRevocationType || (HashicorpCloudPacker20221202ModelsRevocationType = {}));
export function HashicorpCloudPacker20221202ModelsRevocationTypeFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsRevocationTypeFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsRevocationTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202ModelsRevocationTypeToJSON(value) {
    return value;
}
