import Component from '@glimmer/component';

interface StepperFormSignature {
  Args: {
    onPrevious: () => void;
    onTabChange: () => void;
    step: number;
    steps: number;
  };
  Blocks: {
    actions: [unknown];
    body: [unknown];
    tabs: [unknown];
  };
  Element: HTMLDivElement;
}

/**
 * `StepperForm` component is used to structure a sequential (or quasi-sequential)
 * form broken down into phases.
 *
 * Each step can be completed independently and marked as so. Additionally each
 * step may be "jumped to" at the discretion of the implementer of the form.
 *
 * @class StepperForm
 */
export default class StepperFormComponent extends Component<StepperFormSignature> {
  /**
   * `@step` The step the stepper is currently on.
   *
   * @argument @step
   * @type {Number}
   */
  /**
   * `@steps` The number of steps the stepper has.
   *
   * @argument @steps
   * @type {Number}
   */
  /**
   * `@onTabChange` Called with the selected step number when a tab is clicked
   *
   * @argument @onTabChange
   * @type {Function}
   * @default noop
   */
  /**
   * `@onPrevious` Called with the current step number when the previous button
   * is clicked
   *
   * @argument @onPrevious
   * @type {Function}
   * @default noop
   */

  // Make sure step is numeric (could be provided like @step=5)
  get step() {
    return this.args.step ?? parseInt(this.args.step, 10);
  }

  get steps() {
    return this.args.steps ?? parseInt(this.args.steps, 10);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    StepperForm: typeof StepperFormComponent;
    'stepper-form': typeof StepperFormComponent;
  }
}
