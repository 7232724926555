/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface OrganizationPickerSignature {
  Args: {
    currentOrg: unknown;
    organizations: unknown;
  };
  Element: HTMLDivElement;
}

/**
 *
 * `OrganizationPicker` renders a dropdown that allows users to swith
 *  Organization contexts in the HCP navigation bar. This component replaces the
 * `OrgPicker` component.
 *
 *  Compared to `OrgPicker`, `OrganizationPicker` uses BasicDropdown. The
 *  dropdown content opens above the trigger, the content is styled using HDS
 *  classes and is set to the default light-theme set by the HDS dropdown
 *  classes. Ideally the dependency on BasicDropdown will be dropped when HDS
 *  allows for dropdowns that have a configurable dropdown that can open upward.
 *
 *
 * ```
 * <OrganizationPicker
 *  @currentOrg={{this.currentOrg}}
 *  @organization={{this.organizations}}
 * />
 * ```
 *
 * @class OrganizationPicker
 *
 */

export default class OrganizationPickerComponent extends Component<OrganizationPickerSignature> {
  get currentOrg() {
    return this.args.currentOrg;
  }

  get organizations() {
    return this.args.organizations;
  }

  @action
  closeDropdown(
    dropdown: { actions: { close: () => void } },
    event: {
      currentTarget: { contains: (arg0: any) => any };
      relatedTarget: any;
    }
  ) {
    // only close if we're not focused on something inside the dropdown and if
    // the component is not destroyed.
    if (
      !event.currentTarget.contains(
        event.relatedTarget || document.activeElement
      ) &&
      (this.isDestroying || this.isDestroyed)
    ) {
      dropdown.actions.close();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OrganizationPicker: typeof OrganizationPickerComponent;
    'organization-picker': typeof OrganizationPickerComponent;
  }
}
