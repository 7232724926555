/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * DestinationStatus indicates whether the destination is successfully streaming logs to the provider or failing to do so.
 *
 *  - DESTINATION_STATUS_STREAMING: DESTINATION_STATUS_STREAMING: the destination is streaming logs to the provider.
 *  - DESTINATION_STATUS_FAILING_USER: DESTINATION_STATUS_FAILING_USER: the destination is failing to stream logs because of a user error (e.g. invalid credentials).
 *  - DESTINATION_STATUS_FAILING_HCP: DESTINATION_STATUS_FAILING_HCP: the destination is failing to stream logs because of a HashiCorp Cloud Platform error.
 *  - DESTINATION_STATUS_FAILING_PROVIDER: DESTINATION_STATUS_FAILING_PROVIDER: the destination is failing to stream logs because of a provider error.
 * @export
 * @enum {string}
 */
export var LogService20210330DestinationStatus;
(function (LogService20210330DestinationStatus) {
    LogService20210330DestinationStatus["UNSPECIFIED"] = "DESTINATION_STATUS_UNSPECIFIED";
    LogService20210330DestinationStatus["STREAMING"] = "DESTINATION_STATUS_STREAMING";
    LogService20210330DestinationStatus["FAILINGUSER"] = "DESTINATION_STATUS_FAILING_USER";
    LogService20210330DestinationStatus["FAILINGHCP"] = "DESTINATION_STATUS_FAILING_HCP";
    LogService20210330DestinationStatus["FAILINGPROVIDER"] = "DESTINATION_STATUS_FAILING_PROVIDER";
})(LogService20210330DestinationStatus || (LogService20210330DestinationStatus = {}));
export function LogService20210330DestinationStatusFromJSON(json) {
    return LogService20210330DestinationStatusFromJSONTyped(json, false);
}
export function LogService20210330DestinationStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LogService20210330DestinationStatusToJSON(value) {
    return value;
}
