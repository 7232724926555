import Component from '@glimmer/component';

interface ChartsPercentageBarSignature {
  Args: {
    getState?: (value: number) => 'ok' | 'warning' | 'danger';
    title?: string;
    value: number;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLDivElement | HTMLElement;
}

// TODO: enable when this is fixed: https://github.com/thgh/rollup-plugin-scss/issues/70
// import './index.scss';

/**
 *
 * `PercentageBar` renders a horizontal bar chart that represents the percentage of something. \
 *  - it includes space for a title, a subtitle with an optional state, and a summary (showing X%)
 *  - it also yields its components (Chart, Summary, and Subtitle) as well as the API (state, value)
 *
 *  * ```
 * <PercentageBar
 *   @title={{"A very interesting metric"}}
 *   @value={{35}}
 * />
 * ```
 *
 * @class PercentageBar
 */

export default class ChartsPercentageBarComponent extends Component<ChartsPercentageBarSignature> {
  /**
   * A title for the card, if omitted the section is not rendered
   * @argument title
   * @type {?string}
   */

  /**
   * the value (0-100 intended as a percentage) that bar will display
   * @argument value
   * @type {number}
   */

  /**
   * getState is an optional function that takes the value passed to the chart and returns one of the "state" enum values
   *  - it's intended to allow usersto customize the ok/warn/danger thresholds away from the defaults
   *
   *  ```
   *    // component.js
   *    ...
   *
   *    getState(value) {
   *      if (value === 90) return 'danger'
   *
   *      return  'ok';
   *    }
   *
   *    ...
   *
   *    // template.hbs
   *    <PercentageBar
   *      @title='A very specific danger'
   *      @value={{this.weirdCPU.level}}
   *      @getState={{this.getState}}
   *    />
   * ```
   * ^ would yield a chart that ONLY shows danger at 90 (for some reason...)
   *
   * @argument getState
   * @type {(value: number) => enum{'ok'|'warning'|'danger'}
   */

  /**
   * default state calculation
   */
  _getState(value: number) {
    if (value >= 100) {
      return 'danger';
    } else if (value >= 75) {
      return 'warning';
    } else {
      return 'ok';
    }
  }

  /**
   * returns the passed in value (as a number) or zero
   * @method PercentageBar#value
   * @return {number}
   */
  get value() {
    return Number(this.args.value) || 0;
  }

  /**
   * uses the value to determine the state of the bar
   * @method PercentageBar#state
   * @return {enum{'ok'|'warning'|'danger'}}
   */
  get state() {
    const { getState } = this.args;
    const { _getState, value } = this;

    const stateFunction = getState ? getState : _getState;

    return stateFunction(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Charts::PercentageBar': typeof ChartsPercentageBarComponent;
    'charts/percentage-bar': typeof ChartsPercentageBarComponent;
  }
}
