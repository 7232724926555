/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLinkFromJSON, HashicorpCloudLocationLinkToJSON, HashicorpCloudResourcemanagerResourceV2FromJSON, HashicorpCloudResourcemanagerResourceV2ToJSON, } from './';
export function HashicorpCloudResourcemanagerResourceFromJSON(json) {
    return HashicorpCloudResourcemanagerResourceFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'link': !exists(json, 'link') ? undefined : HashicorpCloudLocationLinkFromJSON(json['link']),
        'resourceV2': !exists(json, 'resource_v2') ? undefined : HashicorpCloudResourcemanagerResourceV2FromJSON(json['resource_v2']),
        'resourceName': !exists(json, 'resource_name') ? undefined : json['resource_name'],
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'geo': !exists(json, 'geo') ? undefined : json['geo'],
    };
}
export function HashicorpCloudResourcemanagerResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'link': HashicorpCloudLocationLinkToJSON(value.link),
        'resource_v2': HashicorpCloudResourcemanagerResourceV2ToJSON(value.resourceV2),
        'resource_name': value.resourceName,
        'resource_id': value.resourceId,
        'geo': value.geo,
    };
}
