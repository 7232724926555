/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Webhook Service
 * API for delivering and managing webhooks.
 *
 * The version of the OpenAPI document: 2023-05-31
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWebhookWebhookResponseFromJSON(json) {
    return HashicorpCloudWebhookWebhookResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWebhookWebhookResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'statusCode': !exists(json, 'status_code') ? undefined : json['status_code'],
        'headers': !exists(json, 'headers') ? undefined : json['headers'],
        'body': !exists(json, 'body') ? undefined : json['body'],
    };
}
export function HashicorpCloudWebhookWebhookResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status_code': value.statusCode,
        'headers': value.headers,
        'body': value.body,
    };
}
