/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudConsulTelemetry20230414ClusterFromJSON, HashicorpCloudConsulTelemetry20230414ClusterToJSON, HashicorpCloudConsulTelemetry20230414HttpMetricsFromJSON, HashicorpCloudConsulTelemetry20230414HttpMetricsToJSON, HashicorpCloudConsulTelemetry20230414ServiceFromJSON, HashicorpCloudConsulTelemetry20230414ServiceToJSON, HashicorpCloudConsulTelemetry20230414TcpMetricsFromJSON, HashicorpCloudConsulTelemetry20230414TcpMetricsToJSON, } from './';
export function HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeMetadataFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeMetadataFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cluster': !exists(json, 'cluster') ? undefined : HashicorpCloudConsulTelemetry20230414ClusterFromJSON(json['cluster']),
        'service': !exists(json, 'service') ? undefined : HashicorpCloudConsulTelemetry20230414ServiceFromJSON(json['service']),
        'tcpMetrics': !exists(json, 'tcp_metrics') ? undefined : HashicorpCloudConsulTelemetry20230414TcpMetricsFromJSON(json['tcp_metrics']),
        'httpMetrics': !exists(json, 'http_metrics') ? undefined : HashicorpCloudConsulTelemetry20230414HttpMetricsFromJSON(json['http_metrics']),
    };
}
export function HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cluster': HashicorpCloudConsulTelemetry20230414ClusterToJSON(value.cluster),
        'service': HashicorpCloudConsulTelemetry20230414ServiceToJSON(value.service),
        'tcp_metrics': HashicorpCloudConsulTelemetry20230414TcpMetricsToJSON(value.tcpMetrics),
        'http_metrics': HashicorpCloudConsulTelemetry20230414HttpMetricsToJSON(value.httpMetrics),
    };
}
