/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgress;
(function (HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgress) {
    HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgress["GETREPLICATIONSTATUSRESPONSESYNCPROGRESSINVALID"] = "GET_REPLICATION_STATUS_RESPONSE_SYNC_PROGRESS_INVALID";
    HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgress["STREAMING"] = "STREAMING";
    HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgress["INPROGRESS"] = "IN_PROGRESS";
    HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgress["IDLE"] = "IDLE";
})(HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgress || (HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgress = {}));
export function HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgressFromJSON(json) {
    return HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgressFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgressFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgressToJSON(value) {
    return value;
}
