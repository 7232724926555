/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330DestinationSourceFromJSON, LogService20210330DestinationSourceToJSON, LogService20210330StreamingCloudwatchLogsProviderFromJSON, LogService20210330StreamingCloudwatchLogsProviderToJSON, LogService20210330StreamingDatadogProviderFromJSON, LogService20210330StreamingDatadogProviderToJSON, LogService20210330StreamingFilterFromJSON, LogService20210330StreamingFilterToJSON, LogService20210330StreamingSplunkCloudProviderFromJSON, LogService20210330StreamingSplunkCloudProviderToJSON, } from './';
export function LogService20210330StreamingDestinationFromJSON(json) {
    return LogService20210330StreamingDestinationFromJSONTyped(json, false);
}
export function LogService20210330StreamingDestinationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'filter': !exists(json, 'filter') ? undefined : LogService20210330StreamingFilterFromJSON(json['filter']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'lastErrorMessage': !exists(json, 'last_error_message') ? undefined : json['last_error_message'],
        'datadogProvider': !exists(json, 'datadog_provider') ? undefined : LogService20210330StreamingDatadogProviderFromJSON(json['datadog_provider']),
        'cloudwatchLogsProvider': !exists(json, 'cloudwatch_logs_provider') ? undefined : LogService20210330StreamingCloudwatchLogsProviderFromJSON(json['cloudwatch_logs_provider']),
        'splunkCloudProvider': !exists(json, 'splunk_cloud_provider') ? undefined : LogService20210330StreamingSplunkCloudProviderFromJSON(json['splunk_cloud_provider']),
        'source': !exists(json, 'source') ? undefined : LogService20210330DestinationSourceFromJSON(json['source']),
    };
}
export function LogService20210330StreamingDestinationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'id': value.id,
        'name': value.name,
        'filter': LogService20210330StreamingFilterToJSON(value.filter),
        'status': value.status,
        'last_error_message': value.lastErrorMessage,
        'datadog_provider': LogService20210330StreamingDatadogProviderToJSON(value.datadogProvider),
        'cloudwatch_logs_provider': LogService20210330StreamingCloudwatchLogsProviderToJSON(value.cloudwatchLogsProvider),
        'splunk_cloud_provider': LogService20210330StreamingSplunkCloudProviderToJSON(value.splunkCloudProvider),
        'source': LogService20210330DestinationSourceToJSON(value.source),
    };
}
