import BaseAbility from './base-ability';
import {
  WAYPOINT_ADDON_DEFINITIONS,
  WAYPOINT_ADDON_DEFINITIONS_UPDATE,
  WAYPOINT_ADDON_DEFINITIONS_DELETE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = WAYPOINT_ADDON_DEFINITIONS;

  get canManage() {
    return (
      this.permissions.has(WAYPOINT_ADDON_DEFINITIONS_UPDATE) &&
      this.permissions.has(WAYPOINT_ADDON_DEFINITIONS_DELETE)
    );
  }
}
