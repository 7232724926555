export const FLIGHT_ICON_HEXAGON = 'hexagon';
export const FLIGHT_ICON_HEXAGON_FILL = 'hexagon-fill';
export const FLIGHT_ICON_CHECK_HEXAGON = 'check-hexagon';
export const FLIGHT_ICON_CHECK_HEXAGON_FILL = 'check-hexagon-fill';
export const FLIGHT_ICON_X_HEXAGON = 'x-hexagon';
export const FLIGHT_ICON_X_HEXAGON_FILL = 'x-hexagon-fill';
export const FLIGHT_ICON_LOADING = 'loading';

export const FLIGHT_ICON_SIZE_16 = 16;
export const FLIGHT_ICON_SIZE_24 = 24;
