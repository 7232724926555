/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - QRT_SERVERS_HEARTBEAT: The P90 heartbeat from the leader to followers within each time interval.
 *  - QRT_SERVERS_LEADER_ELECTIONS: The count of leadership elections in the prior day.
 *  - QRT_SERVERS_HAS_LEADER: Whether the cluster has a leader.
 *  - QRT_SERVERS_MAX_HEARTBEAT: The p90 heartbeat latency in the last day -- the max latency of a leader contacting a follower.
 *  - QRT_SERVERS_LEADER_WRITE_LOAD_HIGH: The max raft transaction rate over the last day.
 *  - QRT_SERVERS_LEADER_WRITE_LOAD_MEDIAN: The p50 raft transaction rate over the last day.
 *  - QRT_SERVERS_LEADER_WRITE_LOAD_LOW: The minimum raft transaction rate over the last day.
 *  - QRT_SERVERS_LEADER_WRITE_LATENCY_HIGH: The p95 leader commit latency over the prior day.
 *  - QRT_SERVERS_LEADER_WRITE_LATENCY_MEDIAN: The p90 leader commit latency over the prior day.
 *  - QRT_SERVERS_LEADER_WRITE_LATENCY_LOW: The p50 leader commit latency over the prior day.
 *  - QRT_SERVERS_MEMORY_UTILIZATION_HIGH: The maximum server memory utilization over the prior day.
 *  - QRT_SERVERS_MEMORY_UTILIZATION_MEDIAN: The median average server memory utilization over the prior day.
 *  - QRT_SERVERS_MEMORY_UTILIZATION_LOW: The minimum server memory utilization over the prior day.
 *  - QRT_SERVERS_CPU_UTILIZATION_HIGH: The maximum server cpu utilization of any individual cpu over the prior day.
 *  - QRT_SERVERS_CPU_UTILIZATION_MEDIAN: The p50 average cpu utilization of any individual cpu over the prior day.
 *  - QRT_SERVERS_CPU_UTILIZATION_LOW: The minimum server cpu utilization of any individual cpu over the prior day.
 *  - QRT_SERVERS_IO_WAIT_TIME_HIGH: The maximum cpu io wait time of any individual cpu over the prior day.
 *  - QRT_SERVERS_IO_WAIT_TIME_MEDIAN: The p50 average cpu io wait time of any individual cpu over the prior day.
 *  - QRT_SERVERS_IO_WAIT_TIME_LOW: The minimum cpu io wait time of any individual cpu over the prior day.
 *  - QRT_SERVERS_TRANSACTIONS: Raft applies per second per server.
 *  - QRT_SERVERS_LATENCY: The p50 Raft commit time latency.
 *  - QRT_SERVERS_FOLLOWER_REPLICATIONS: The rate of Raft replications from the leader to its followers. This is summed per peer (peer_id).
 *  - QRT_SERVERS_MEMORY_UTILIZATION: The median percentage memory usage for each server each interval.
 *  - QRT_SERVERS_CPU_UTILIZATION: The median percentage cpu usage for each server each interval.
 *  - QRT_SERVERS_IO_WAIT_TIME: The median percentage io wait time for each server each interval.
 *  - QRT_ENVOY_SERVER_PHYSICAL_MEMORY_TOTAL_CAPACITY: Total memory capacity of Envoy servers.
 *  - QRT_ENVOY_SERVER_STATES_LIVE: The count of live Envoy servers.
 *  - QRT_ENVOY_SERVER_STATES_PREINITIALIZING: The count of currently pre-initializing Envoy servers.
 *  - QRT_ENVOY_SERVER_STATES_INITIALIZING: The count of currently initializing Envoy servers.
 *  - QRT_ENVOY_SERVER_STATES_DRAINING: The count of currently draining Envoy servers.
 *  - QRT_ENVOY_PROXY_COUNT_CONNECTED: The count of Envoy servers connected to Consul.
 *  - QRT_ENVOY_PROXY_COUNT_DISCONNECTED: The count of Envoy servers not connected to Consul.
 *  - QRT_ENVOY_REQUEST_RATE_1XX: The ratio of requests in the mesh that return 1XX.
 *  - QRT_ENVOY_REQUEST_RATE_2XX: The ratio of requests in the mesh that return 2XX.
 *  - QRT_ENVOY_REQUEST_RATE_3XX: The ratio of requests in the mesh that return 3XX.
 *  - QRT_ENVOY_REQUEST_RATE_4XX: The ratio of requests in the mesh that return 4XX.
 *  - QRT_ENVOY_REQUEST_RATE_5XX: The ratio of requests in the mesh that return 5XX.
 *  - QRT_ENVOY_REQUEST_SUCCESS_RATE: The success rate of Envoy requests: 2XX / (2XX + 5XX).
 *  - QRT_ENVOY_CONNECTION_RATE: New Envoy connections per second.
 *  - QRT_ENVOY_TOTAL_ACTIVE_CONNECTIONS: The total count of active Envoy connections.
 *  - QRT_SERVERS_LATEST_HEARTBEAT: The p50 latency of a leader contacting its followers.
 * This queries the consul_raft_leader_lastContact metric directly so will (likely) retrieve newer samples
 * than are generated via QRT_QRT_SERVERS_HEARTBEAT's recording rule.
 *  - QRT_COLLECTOR_IS_DEPLOYED: Whether the cluster has a deployed Consul Telemetry Collector. It queries a metric that
 * the collector emits about its own Prometheus receiver: https://opentelemetry.io/docs/collector/management/.
 * We receive these metrics whether or not intentions are configured which is useful for the UI. The UI will show
 * a "here's how to configure your intentions" snippet if we get collector resource metrics but no service metrics.
 * This will return no data if no collector is deployed -- or the collector is deployed but unable to push metrics
 * (eg its SP is bad/stale).
 *  - QRT_SERVERS_FOLLOWER_REPLICATION_MS: The P50 latency of Raft append log operations from the leader to followers. The latency is per follower with peer_id as the label.
 *  - QRT_SERVERS_REQUEST_RATE: The per second rate of requests to each server.
 *  - QRT_SERVERS_REQUEST_P50_MS: The P50 latencies of HTTP requests to the Consul servers.
 *  - QRT_SERVERS_REQUEST_P90_MS: The P90 latencies of HTTP requests to the Consul servers.
 *  - QRT_SERVERS_REQUEST_P95_MS: The P95 latencies of HTTP requests to the Consul servers.
 *  - QRT_SERVERS_REQUEST_SUCCESS_RATE: The rate of successful requests to all Consul servers in the cluster.
 *  - QRT_SERVERS_BLOCKING_QUERIES_TOTAL: The sum of blocking queries across all servers in the cluster.
 *  - QRT_SERVERS_RPC_CONNECTION_RATE: The rate of RPC connections to all the servers in the cluster.
 *  - QRT_SERVERS_GRPC_CONNECTION_RATE: The rate of gRPC connections to all the servers in the cluster.
 *  - QRT_SERVERS_XDS_STREAMS_TOTAL: The count of xDS streams active at a given time.
 *  - QRT_SERVERS_PEERINGS_TOTAL: The count of Consul cluster peerings active at a given time.
 *  - QRT_ENVOY_SERVICE_REQUEST_RATE: The per second rate of requests to a service.
 *  - QRT_ENVOY_SERVICE_REQUEST_TIMEOUT_RATE: The per second rate of requests to a service that time out.
 *  - QRT_ENVOY_SERVICE_HTTP_RESPONSE_1XX_RATE: The percentage rate of 1XX status response codes from the service.
 *  - QRT_ENVOY_SERVICE_HTTP_RESPONSE_2XX_RATE: The percentage rate of 2XX status response codes from the service.
 *  - QRT_ENVOY_SERVICE_HTTP_RESPONSE_3XX_RATE: The percentage rate of 3XX status response codes from the service.
 *  - QRT_ENVOY_SERVICE_HTTP_RESPONSE_4XX_RATE: The percentage rate of 4XX status response codes from the service.
 *  - QRT_ENVOY_SERVICE_HTTP_RESPONSE_5XX_RATE: The percentage rate of 5XX status response codes from the service.
 *  - QRT_ENVOY_SERVICE_REQUEST_P50_MS: The P50 request latency to a service.
 *  - QRT_ENVOY_SERVICE_REQUEST_RX_BYTES_RATE: The per second rate of bytes received by service.
 *  - QRT_ENVOY_SERVICE_REQUEST_TX_BYTES_RATE: The per second rate of bytes transmitted from service.
 *  - QRT_ENVOY_SERVICE_CONNECTIONS_TOTAL_RATE: The per second rate of connections to a service.
 *  - QRT_ENVOY_SERVICE_CONNECTIONS_SUCCESSFUL_RATE: The per second rate of successful connections to a service.
 *  - QRT_ENVOY_SERVICE_CONNECTIONS_FAILED_RATE: The per second rate of failed connections to a service.
 *  - QRT_ENVOY_SERVICE_CONNECTIONS_SUCCESSFUL_PERCENTAGE_RATE: The percentage rate of successful connection requests to a service.
 *  - QRT_ENVOY_TOTAL_HTTP_RESPONSE_1XX_RATE: The percentage rate of 1XX status response codes from all services in the mesh.
 *  - QRT_ENVOY_TOTAL_HTTP_RESPONSE_2XX_RATE: The percentage rate of 2XX status response codes from all services in the mesh.
 *  - QRT_ENVOY_TOTAL_HTTP_RESPONSE_3XX_RATE: The percentage rate of 3XX status response codes from all services in the mesh.
 *  - QRT_ENVOY_TOTAL_HTTP_RESPONSE_4XX_RATE: The percentage rate of 4XX status response codes from all services in the mesh.
 *  - QRT_ENVOY_TOTAL_HTTP_RESPONSE_5XX_RATE: The percentage rate of 5XX status response codes from all services in the mesh.
 *  - QRT_ENVOY_TOTAL_HTTP_RESPONSE_COUNT: The total count of http responses from all services in the mesh.
 *  - QRT_SERVERS_LATEST_HEARTBEAT_P95_MS: The p95 latency of a leader contacting its followers.
 *  - QRT_SERVERS_LEADER_WRITE_P100_RATE: The maximum rate of Raft applies per second over the queried interval. From consul.raft.apply.
 *  - QRT_SERVERS_LEADER_WRITE_P50_RATE: The median rate of Raft applies per second over the queried interval. From consul.raft.apply.
 *  - QRT_SERVERS_LEADER_WRITE_P0_RATE: The minimum rate of Raft applies per second over the queried interval. From consul.raft.apply.
 *  - QRT_SERVERS_LEADER_WRITE_LATENCY_P95_MS: The P95 latency of Raft applies from leader to follower over the queried interval. From consul.raft.commitTime.
 *  - QRT_SERVERS_LEADER_WRITE_LATENCY_P90_MS: The P90 latency of Raft applies from leader to follower over the queried interval. From consul.raft.commitTime.
 *  - QRT_SERVERS_LEADER_WRITE_LATENCY_P50_MS: The P50 latency of Raft applies from leader to follower over the queried interval. From consul.raft.commitTime.
 *  - QRT_SERVERS_MEMORY_UTILIZATION_P100_PERCENTAGE_RATE: The maximum percentage rate of consul server memory usage over the queried interval for any server. From consul.host.memory.used_percent.
 *  - QRT_SERVERS_MEMORY_UTILIZATION_P50_PERCENTAGE_RATE: The median percentage rate of consul server memory usage over the queried interval for any server. From consul.host.memory.used_percent.
 *  - QRT_SERVERS_MEMORY_UTILIZATION_P0_PERCENTAGE_RATE: The minimum percentage rate of consul server memory usage over the queried interval for any server. From consul.host.memory.used_percent.
 *  - QRT_SERVERS_CPU_UTILIZATION_P100_PERCENTAGE_RATE: The maximum percentage rate of consul server CPU usage over the queried interval for any server. From consul.host.cpu.used_percent.
 *  - QRT_SERVERS_CPU_UTILIZATION_P50_PERCENTAGE_RATE: The median percentage rate of consul server CPU usage over the queried interval for any server. From consul.host.cpu.used_percent.
 *  - QRT_SERVERS_CPU_UTILIZATION_P0_PERCENTAGE_RATE: The minimum percentage rate of consul server CPU usage over the queried interval for any server. From consul.host.cpu.used_percent.
 *  - QRT_SERVERS_IO_WAIT_P100_PERCENTAGE_RATE: The maximum percentage of cpu time spent on IO want of any server over the queried interval. From consul.host.cpu.iowait.
 *  - QRT_SERVERS_IO_WAIT_P50_PERCENTAGE_RATE: The median percentage of cpu time spent on IO want of any server over the queried interval. From consul.host.cpu.iowait.
 *  - QRT_SERVERS_IO_WAIT_P0_PERCENTAGE_RATE: The minimum percentage of cpu time spent on IO want of any server over the queried interval. From consul.host.cpu.iowait.
 *  - QRT_SERVERS_LEADER_ELECTIONS_TOTAL: The count of leader elections over the queried interval. From consul.raft.state.leader.
 *  - QRT_ENVOY_SERVICE_REQUEST_AVG_MS: The average request latency to a service.
 *  - QRT_ENVOY_SERVICE_CONNECTIONS_UNHEALTHY_HOSTS_RATE: The total number of failed connections due to unhealthy hosts.
 * @export
 * @enum {string}
 */
export var HashicorpCloudConsulTelemetry20230414QRT;
(function (HashicorpCloudConsulTelemetry20230414QRT) {
    HashicorpCloudConsulTelemetry20230414QRT["UNSPECIFIED"] = "QRT_UNSPECIFIED";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSHEARTBEAT"] = "QRT_SERVERS_HEARTBEAT";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERELECTIONS"] = "QRT_SERVERS_LEADER_ELECTIONS";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSHASLEADER"] = "QRT_SERVERS_HAS_LEADER";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSMAXHEARTBEAT"] = "QRT_SERVERS_MAX_HEARTBEAT";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERWRITELOADHIGH"] = "QRT_SERVERS_LEADER_WRITE_LOAD_HIGH";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERWRITELOADMEDIAN"] = "QRT_SERVERS_LEADER_WRITE_LOAD_MEDIAN";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERWRITELOADLOW"] = "QRT_SERVERS_LEADER_WRITE_LOAD_LOW";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERWRITELATENCYHIGH"] = "QRT_SERVERS_LEADER_WRITE_LATENCY_HIGH";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERWRITELATENCYMEDIAN"] = "QRT_SERVERS_LEADER_WRITE_LATENCY_MEDIAN";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERWRITELATENCYLOW"] = "QRT_SERVERS_LEADER_WRITE_LATENCY_LOW";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSMEMORYUTILIZATIONHIGH"] = "QRT_SERVERS_MEMORY_UTILIZATION_HIGH";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSMEMORYUTILIZATIONMEDIAN"] = "QRT_SERVERS_MEMORY_UTILIZATION_MEDIAN";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSMEMORYUTILIZATIONLOW"] = "QRT_SERVERS_MEMORY_UTILIZATION_LOW";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSCPUUTILIZATIONHIGH"] = "QRT_SERVERS_CPU_UTILIZATION_HIGH";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSCPUUTILIZATIONMEDIAN"] = "QRT_SERVERS_CPU_UTILIZATION_MEDIAN";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSCPUUTILIZATIONLOW"] = "QRT_SERVERS_CPU_UTILIZATION_LOW";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSIOWAITTIMEHIGH"] = "QRT_SERVERS_IO_WAIT_TIME_HIGH";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSIOWAITTIMEMEDIAN"] = "QRT_SERVERS_IO_WAIT_TIME_MEDIAN";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSIOWAITTIMELOW"] = "QRT_SERVERS_IO_WAIT_TIME_LOW";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSTRANSACTIONS"] = "QRT_SERVERS_TRANSACTIONS";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLATENCY"] = "QRT_SERVERS_LATENCY";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSFOLLOWERREPLICATIONS"] = "QRT_SERVERS_FOLLOWER_REPLICATIONS";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSMEMORYUTILIZATION"] = "QRT_SERVERS_MEMORY_UTILIZATION";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSCPUUTILIZATION"] = "QRT_SERVERS_CPU_UTILIZATION";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSIOWAITTIME"] = "QRT_SERVERS_IO_WAIT_TIME";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVERPHYSICALMEMORYTOTALCAPACITY"] = "QRT_ENVOY_SERVER_PHYSICAL_MEMORY_TOTAL_CAPACITY";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVERSTATESLIVE"] = "QRT_ENVOY_SERVER_STATES_LIVE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVERSTATESPREINITIALIZING"] = "QRT_ENVOY_SERVER_STATES_PREINITIALIZING";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVERSTATESINITIALIZING"] = "QRT_ENVOY_SERVER_STATES_INITIALIZING";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVERSTATESDRAINING"] = "QRT_ENVOY_SERVER_STATES_DRAINING";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYPROXYCOUNTCONNECTED"] = "QRT_ENVOY_PROXY_COUNT_CONNECTED";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYPROXYCOUNTDISCONNECTED"] = "QRT_ENVOY_PROXY_COUNT_DISCONNECTED";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYREQUESTRATE1XX"] = "QRT_ENVOY_REQUEST_RATE_1XX";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYREQUESTRATE2XX"] = "QRT_ENVOY_REQUEST_RATE_2XX";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYREQUESTRATE3XX"] = "QRT_ENVOY_REQUEST_RATE_3XX";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYREQUESTRATE4XX"] = "QRT_ENVOY_REQUEST_RATE_4XX";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYREQUESTRATE5XX"] = "QRT_ENVOY_REQUEST_RATE_5XX";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYREQUESTSUCCESSRATE"] = "QRT_ENVOY_REQUEST_SUCCESS_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYCONNECTIONRATE"] = "QRT_ENVOY_CONNECTION_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYTOTALACTIVECONNECTIONS"] = "QRT_ENVOY_TOTAL_ACTIVE_CONNECTIONS";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLATESTHEARTBEAT"] = "QRT_SERVERS_LATEST_HEARTBEAT";
    HashicorpCloudConsulTelemetry20230414QRT["COLLECTORISDEPLOYED"] = "QRT_COLLECTOR_IS_DEPLOYED";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSFOLLOWERREPLICATIONMS"] = "QRT_SERVERS_FOLLOWER_REPLICATION_MS";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSREQUESTRATE"] = "QRT_SERVERS_REQUEST_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSREQUESTP50MS"] = "QRT_SERVERS_REQUEST_P50_MS";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSREQUESTP90MS"] = "QRT_SERVERS_REQUEST_P90_MS";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSREQUESTP95MS"] = "QRT_SERVERS_REQUEST_P95_MS";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSREQUESTSUCCESSRATE"] = "QRT_SERVERS_REQUEST_SUCCESS_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSBLOCKINGQUERIESTOTAL"] = "QRT_SERVERS_BLOCKING_QUERIES_TOTAL";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSRPCCONNECTIONRATE"] = "QRT_SERVERS_RPC_CONNECTION_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSGRPCCONNECTIONRATE"] = "QRT_SERVERS_GRPC_CONNECTION_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSXDSSTREAMSTOTAL"] = "QRT_SERVERS_XDS_STREAMS_TOTAL";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSPEERINGSTOTAL"] = "QRT_SERVERS_PEERINGS_TOTAL";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICEREQUESTRATE"] = "QRT_ENVOY_SERVICE_REQUEST_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICEREQUESTTIMEOUTRATE"] = "QRT_ENVOY_SERVICE_REQUEST_TIMEOUT_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICEHTTPRESPONSE1XXRATE"] = "QRT_ENVOY_SERVICE_HTTP_RESPONSE_1XX_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICEHTTPRESPONSE2XXRATE"] = "QRT_ENVOY_SERVICE_HTTP_RESPONSE_2XX_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICEHTTPRESPONSE3XXRATE"] = "QRT_ENVOY_SERVICE_HTTP_RESPONSE_3XX_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICEHTTPRESPONSE4XXRATE"] = "QRT_ENVOY_SERVICE_HTTP_RESPONSE_4XX_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICEHTTPRESPONSE5XXRATE"] = "QRT_ENVOY_SERVICE_HTTP_RESPONSE_5XX_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICEREQUESTP50MS"] = "QRT_ENVOY_SERVICE_REQUEST_P50_MS";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICEREQUESTRXBYTESRATE"] = "QRT_ENVOY_SERVICE_REQUEST_RX_BYTES_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICEREQUESTTXBYTESRATE"] = "QRT_ENVOY_SERVICE_REQUEST_TX_BYTES_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICECONNECTIONSTOTALRATE"] = "QRT_ENVOY_SERVICE_CONNECTIONS_TOTAL_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICECONNECTIONSSUCCESSFULRATE"] = "QRT_ENVOY_SERVICE_CONNECTIONS_SUCCESSFUL_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICECONNECTIONSFAILEDRATE"] = "QRT_ENVOY_SERVICE_CONNECTIONS_FAILED_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICECONNECTIONSSUCCESSFULPERCENTAGERATE"] = "QRT_ENVOY_SERVICE_CONNECTIONS_SUCCESSFUL_PERCENTAGE_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYTOTALHTTPRESPONSE1XXRATE"] = "QRT_ENVOY_TOTAL_HTTP_RESPONSE_1XX_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYTOTALHTTPRESPONSE2XXRATE"] = "QRT_ENVOY_TOTAL_HTTP_RESPONSE_2XX_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYTOTALHTTPRESPONSE3XXRATE"] = "QRT_ENVOY_TOTAL_HTTP_RESPONSE_3XX_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYTOTALHTTPRESPONSE4XXRATE"] = "QRT_ENVOY_TOTAL_HTTP_RESPONSE_4XX_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYTOTALHTTPRESPONSE5XXRATE"] = "QRT_ENVOY_TOTAL_HTTP_RESPONSE_5XX_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYTOTALHTTPRESPONSECOUNT"] = "QRT_ENVOY_TOTAL_HTTP_RESPONSE_COUNT";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLATESTHEARTBEATP95MS"] = "QRT_SERVERS_LATEST_HEARTBEAT_P95_MS";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERWRITEP100RATE"] = "QRT_SERVERS_LEADER_WRITE_P100_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERWRITEP50RATE"] = "QRT_SERVERS_LEADER_WRITE_P50_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERWRITEP0RATE"] = "QRT_SERVERS_LEADER_WRITE_P0_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERWRITELATENCYP95MS"] = "QRT_SERVERS_LEADER_WRITE_LATENCY_P95_MS";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERWRITELATENCYP90MS"] = "QRT_SERVERS_LEADER_WRITE_LATENCY_P90_MS";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERWRITELATENCYP50MS"] = "QRT_SERVERS_LEADER_WRITE_LATENCY_P50_MS";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSMEMORYUTILIZATIONP100PERCENTAGERATE"] = "QRT_SERVERS_MEMORY_UTILIZATION_P100_PERCENTAGE_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSMEMORYUTILIZATIONP50PERCENTAGERATE"] = "QRT_SERVERS_MEMORY_UTILIZATION_P50_PERCENTAGE_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSMEMORYUTILIZATIONP0PERCENTAGERATE"] = "QRT_SERVERS_MEMORY_UTILIZATION_P0_PERCENTAGE_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSCPUUTILIZATIONP100PERCENTAGERATE"] = "QRT_SERVERS_CPU_UTILIZATION_P100_PERCENTAGE_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSCPUUTILIZATIONP50PERCENTAGERATE"] = "QRT_SERVERS_CPU_UTILIZATION_P50_PERCENTAGE_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSCPUUTILIZATIONP0PERCENTAGERATE"] = "QRT_SERVERS_CPU_UTILIZATION_P0_PERCENTAGE_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSIOWAITP100PERCENTAGERATE"] = "QRT_SERVERS_IO_WAIT_P100_PERCENTAGE_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSIOWAITP50PERCENTAGERATE"] = "QRT_SERVERS_IO_WAIT_P50_PERCENTAGE_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSIOWAITP0PERCENTAGERATE"] = "QRT_SERVERS_IO_WAIT_P0_PERCENTAGE_RATE";
    HashicorpCloudConsulTelemetry20230414QRT["SERVERSLEADERELECTIONSTOTAL"] = "QRT_SERVERS_LEADER_ELECTIONS_TOTAL";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICEREQUESTAVGMS"] = "QRT_ENVOY_SERVICE_REQUEST_AVG_MS";
    HashicorpCloudConsulTelemetry20230414QRT["ENVOYSERVICECONNECTIONSUNHEALTHYHOSTSRATE"] = "QRT_ENVOY_SERVICE_CONNECTIONS_UNHEALTHY_HOSTS_RATE";
})(HashicorpCloudConsulTelemetry20230414QRT || (HashicorpCloudConsulTelemetry20230414QRT = {}));
export function HashicorpCloudConsulTelemetry20230414QRTFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414QRTFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414QRTFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudConsulTelemetry20230414QRTToJSON(value) {
    return value;
}
