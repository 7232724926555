/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var SyncConfigGitlabScope;
(function (SyncConfigGitlabScope) {
    SyncConfigGitlabScope["PROJECT"] = "PROJECT";
    SyncConfigGitlabScope["GROUP"] = "GROUP";
})(SyncConfigGitlabScope || (SyncConfigGitlabScope = {}));
export function SyncConfigGitlabScopeFromJSON(json) {
    return SyncConfigGitlabScopeFromJSONTyped(json, false);
}
export function SyncConfigGitlabScopeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SyncConfigGitlabScopeToJSON(value) {
    return value;
}
