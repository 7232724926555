import {
  BILLING_ACCOUNT_STATUS,
  BILLING_ON_DEMAND_STATUS,
} from '../constants/status';

export default class BillingStatus {
  #status;
  #onDemandStatus;

  constructor(status, onDemandStatus) {
    this.#status = status;
    this.#onDemandStatus = onDemandStatus;
  }

  get isActive() {
    return this.#status === BILLING_ACCOUNT_STATUS.ACTIVE;
  }

  get isOnDemandActive() {
    return this.#onDemandStatus === BILLING_ON_DEMAND_STATUS.ACTIVE;
  }

  get isRemovingCreditCard() {
    // An account will be set to 'trial' in some cases when a credit card is
    // in a long async process of being removed. During this phase, the credit
    // card is still set.
    return this.#onDemandStatus === BILLING_ON_DEMAND_STATUS.DELETING;
  }

  get isDelinquent() {
    return this.#status === BILLING_ACCOUNT_STATUS.DELINQUENT;
  }

  get isTrial() {
    return this.#status === BILLING_ACCOUNT_STATUS.TRIAL;
  }

  get isIncomplete() {
    return this.#status === BILLING_ACCOUNT_STATUS.INCOMPLETE;
  }

  get isOnDemandUnset() {
    return this.#onDemandStatus === BILLING_ON_DEMAND_STATUS.UNSET;
  }

  valueOf() {
    return this.#status;
  }
}
