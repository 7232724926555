/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Contacts Service
 * API for managing Contact information.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudEmailSourceTypeFromJSON, HashicorpCloudEmailSourceTypeToJSON } from './';
export function HashicorpCloudEmailContactFromJSON(json) {
  return HashicorpCloudEmailContactFromJSONTyped(json, false);
}
export function HashicorpCloudEmailContactFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    sourceIdentifier: !exists(json, 'source_identifier') ? undefined : json['source_identifier'],
    sourceType: !exists(json, 'source_type')
      ? undefined
      : HashicorpCloudEmailSourceTypeFromJSON(json['source_type']),
    emailAddress: !exists(json, 'email_address') ? undefined : json['email_address'],
    isActive: !exists(json, 'is_active') ? undefined : json['is_active'],
    verifiedAt: !exists(json, 'verified_at') ? undefined : new Date(json['verified_at']),
    createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
    updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
  };
}
export function HashicorpCloudEmailContactToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    source_identifier: value.sourceIdentifier,
    source_type: HashicorpCloudEmailSourceTypeToJSON(value.sourceType),
    email_address: value.emailAddress,
    is_active: value.isActive,
    verified_at: value.verifiedAt === undefined ? undefined : value.verifiedAt.toISOString(),
    created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
  };
}
