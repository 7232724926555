import { helper } from '@ember/component/helper';
import * as resourceTypes from 'common/utils/cloud-resource-types';

let BUSY_STATES_FOR_TYPE = {
  'hashicorp.network.hvn': ['UNSET', 'CREATING', 'DELETING'],
  'hashicorp.network.route': ['UNSET', 'CREATING', 'DELETING'],
  'hashicorp.network.peering': ['UNSET', 'CREATING', 'DELETING'],
  'hashicorp.network.tgw-attachment': ['UNSET', 'CREATING', 'DELETING'],
  'hashicorp.consul.cluster': [
    'UNSET',
    'QUEUED',
    'PENDING',
    'CREATING',
    'RESTORING',
    'DELETING',
  ],
  [resourceTypes.TYPE_VAULT]: [
    'CREATING',
    'DELETING',
    'LOCKING',
    'PENDING',
    'RESTORING',
    'SEALING',
    'UNLOCKING',
    'UNSEALING',
    'UNSET',
  ],
};

export function busyStatesForResource([type]) {
  return BUSY_STATES_FOR_TYPE[type].slice(0);
}

export default helper(busyStatesForResource);
