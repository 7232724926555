/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var Secrets20231128Tier;
(function (Secrets20231128Tier) {
    Secrets20231128Tier["UNKNOWN"] = "UNKNOWN";
    Secrets20231128Tier["FREE"] = "FREE";
    Secrets20231128Tier["STANDARD"] = "STANDARD";
    Secrets20231128Tier["PLUS"] = "PLUS";
})(Secrets20231128Tier || (Secrets20231128Tier = {}));
export function Secrets20231128TierFromJSON(json) {
    return Secrets20231128TierFromJSONTyped(json, false);
}
export function Secrets20231128TierFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Secrets20231128TierToJSON(value) {
    return value;
}
