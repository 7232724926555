/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - CLUSTER_MARKETING_SKU_UNSPECIFIED: CLUSTER_MARKETING_SKU_UNSPECIFIED is unspecified
 *  - CLUSTER_MARKETING_SKU_STANDARD: CLUSTER_MARKETING_SKU_STANDARD is 'standard' SKU
 *  - CLUSTER_MARKETING_SKU_PLUS: CLUSTER_MARKETING_SKU_PLUS is a 'plus' SKU
 * @export
 * @enum {string}
 */
export var HashicorpCloudBoundary20211221ClusterMarketingSKU;
(function (HashicorpCloudBoundary20211221ClusterMarketingSKU) {
    HashicorpCloudBoundary20211221ClusterMarketingSKU["UNSPECIFIED"] = "CLUSTER_MARKETING_SKU_UNSPECIFIED";
    HashicorpCloudBoundary20211221ClusterMarketingSKU["STANDARD"] = "CLUSTER_MARKETING_SKU_STANDARD";
    HashicorpCloudBoundary20211221ClusterMarketingSKU["PLUS"] = "CLUSTER_MARKETING_SKU_PLUS";
})(HashicorpCloudBoundary20211221ClusterMarketingSKU || (HashicorpCloudBoundary20211221ClusterMarketingSKU = {}));
export function HashicorpCloudBoundary20211221ClusterMarketingSKUFromJSON(json) {
    return HashicorpCloudBoundary20211221ClusterMarketingSKUFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221ClusterMarketingSKUFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudBoundary20211221ClusterMarketingSKUToJSON(value) {
    return value;
}
