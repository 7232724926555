import Component from '@glimmer/component';

interface SlugIdSignature {
  Args: {
    disabled: unknown;
    invalid: unknown;
    onChange: unknown;
    value: unknown;
  };
  Blocks: {
    'slug-id-help-text': [];
    'slug-id-label': [];
  };
  Element: HTMLElement;
}

/**
 *
 * Simple text input, meant to be used to collect a slug ID.
 *
 *
 * ```
 * <FormInputs::SlugId
 *   @invalid={{invalid}}
 *   @disabled={{disabled}}
 *   @value={{value}}
 *   @onChange={{onChange}}
 * />
 * ```
 *
 * @class FormInputsSlugId
 *
 */

export default class FormInputsSlugIdComponent extends Component<SlugIdSignature> {
  /**
   * @argument invalid
   * @type {Boolean}
   */
  /**
   * @argument disabled
   * @type {Boolean}
   */
  /**
   * @argument value
   * @type {String}
   */
  /**
   * @argument onChange - handler function that accepts a browser Event as 0th argument
   * @type {Function}
   */
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormInputs::SlugId': typeof FormInputsSlugIdComponent;
    'form-inputs/slug-id': typeof FormInputsSlugIdComponent;
  }
}
