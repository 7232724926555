/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105AvailablePackageFromJSON, Billing20201105AvailablePackageToJSON, } from './';
export function Billing20201105ListAvailablePackagesResponseFromJSON(json) {
    return Billing20201105ListAvailablePackagesResponseFromJSONTyped(json, false);
}
export function Billing20201105ListAvailablePackagesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'packages': !exists(json, 'packages') ? undefined : (json['packages'].map(Billing20201105AvailablePackageFromJSON)),
    };
}
export function Billing20201105ListAvailablePackagesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'packages': value.packages === undefined ? undefined : (value.packages.map(Billing20201105AvailablePackageToJSON)),
    };
}
