import Component from '@glimmer/component';

const COST_ESTIMATOR_PERIOD_HOUR = 'HOUR';
const COST_ESTIMATOR_PERIOD_MONTH = 'MONTH';
const COST_ESTIMATOR_PERIOD_DEFAULT = COST_ESTIMATOR_PERIOD_HOUR;

const COST_ESTIMATOR_PERIOD_MAPPING = {
  [COST_ESTIMATOR_PERIOD_HOUR]: {
    id: COST_ESTIMATOR_PERIOD_HOUR,
    i18n: 'billing-common.cost-estimator.footer.per-hour',
    priceKey: 'totalHourlyPrice',
  },
  [COST_ESTIMATOR_PERIOD_MONTH]: {
    id: COST_ESTIMATOR_PERIOD_MONTH,
    i18n: 'billing-common.cost-estimator.footer.per-month',
    priceKey: 'totalMonthlyPrice',
  },
};

/**
 *
 * `CostEstimatorEditFooter` renders a cost estimator in the context of scaling
 * a cluster up or down. This renders the price difference based on @period.
 *
 *
 * ```
 * <CostEstimator::EditFooter
 *   @currentEstimate={{@currentEstimate}}
 *   @newEstimate={{@newEstimate}}
 *   @period={{@period}} // [MONTH|HOUR]
 * />
 * ```
 *
 * @class CostEstimatorEditFooter
 *
 */

/**
 * The period for which estimate to show. The options are HOUR or MONTH.
 * @argument period
 * @type {String}
 */

/**
 * The api data from the cost estimation API. This will be used as the left
 * operand when calculating the difference.
 * @argument newEstimate
 * @type {object}
 */

/**
 * The api data from the cost estimation API. This will be used as the right
 * operand when calculating the difference.
 * @argument currentEstimate
 * @type {object}
 */
export default class CostEstimatorEditFooterComponent extends Component {
  get period() {
    let { period = COST_ESTIMATOR_PERIOD_DEFAULT } = this.args;
    return (
      COST_ESTIMATOR_PERIOD_MAPPING[period] ||
      COST_ESTIMATOR_PERIOD_MAPPING[COST_ESTIMATOR_PERIOD_DEFAULT]
    );
  }

  get newEstimatePrice() {
    let { newEstimate } = this.args;
    return newEstimate[this.period.priceKey];
  }

  get currentEstimatePrice() {
    let { currentEstimate } = this.args;
    return currentEstimate[this.period.priceKey];
  }
}
