/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudPacker20221202ModelsUpdateVersionBodyFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsUpdateVersionBodyFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsUpdateVersionBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'complete': !exists(json, 'complete') ? undefined : json['complete'],
        'revocationMessage': !exists(json, 'revocation_message') ? undefined : json['revocation_message'],
        'revokeAt': !exists(json, 'revoke_at') ? undefined : (new Date(json['revoke_at'])),
        'revokeIn': !exists(json, 'revoke_in') ? undefined : json['revoke_in'],
        'restore': !exists(json, 'restore') ? undefined : json['restore'],
        'skipDescendantsRevocation': !exists(json, 'skip_descendants_revocation') ? undefined : json['skip_descendants_revocation'],
        'disableRollbackChannels': !exists(json, 'disable_rollback_channels') ? undefined : json['disable_rollback_channels'],
    };
}
export function HashicorpCloudPacker20221202ModelsUpdateVersionBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'complete': value.complete,
        'revocation_message': value.revocationMessage,
        'revoke_at': value.revokeAt === undefined ? undefined : (value.revokeAt.toISOString()),
        'revoke_in': value.revokeIn,
        'restore': value.restore,
        'skip_descendants_revocation': value.skipDescendantsRevocation,
        'disable_rollback_channels': value.disableRollbackChannels,
    };
}
