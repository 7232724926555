/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Ctgw20230414OrganizationsLocationOrganizationIdProjectsLocationProjectIdClustersClusterIdAgentTelemetryConfigLocationFromJSON, Ctgw20230414OrganizationsLocationOrganizationIdProjectsLocationProjectIdClustersClusterIdAgentTelemetryConfigLocationToJSON, HashicorpCloudConsulTelemetry20230414QRTFromJSON, HashicorpCloudConsulTelemetry20230414QRTToJSON, } from './';
export function QueryRangeBatchRequestFromJSON(json) {
    return QueryRangeBatchRequestFromJSONTyped(json, false);
}
export function QueryRangeBatchRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : Ctgw20230414OrganizationsLocationOrganizationIdProjectsLocationProjectIdClustersClusterIdAgentTelemetryConfigLocationFromJSON(json['location']),
        'query': !exists(json, 'query') ? undefined : (json['query'].map(HashicorpCloudConsulTelemetry20230414QRTFromJSON)),
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'step': !exists(json, 'step') ? undefined : json['step'],
        'serviceName': !exists(json, 'service_name') ? undefined : json['service_name'],
        'serviceNamespace': !exists(json, 'service_namespace') ? undefined : json['service_namespace'],
        'servicePartition': !exists(json, 'service_partition') ? undefined : json['service_partition'],
        'clusterInternalId': !exists(json, 'cluster_internal_id') ? undefined : json['cluster_internal_id'],
    };
}
export function QueryRangeBatchRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': Ctgw20230414OrganizationsLocationOrganizationIdProjectsLocationProjectIdClustersClusterIdAgentTelemetryConfigLocationToJSON(value.location),
        'query': value.query === undefined ? undefined : (value.query.map(HashicorpCloudConsulTelemetry20230414QRTToJSON)),
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'step': value.step,
        'service_name': value.serviceName,
        'service_namespace': value.serviceNamespace,
        'service_partition': value.servicePartition,
        'cluster_internal_id': value.clusterInternalId,
    };
}
