/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202IterationExternalStatusFromJSON, HashicorpCloudPacker20221202IterationExternalStatusToJSON, HashicorpCloudPacker20221202IterationParentsFromJSON, HashicorpCloudPacker20221202IterationParentsToJSON, } from './';
export function HashicorpCloudPacker20221202ChannelAssignmentIterationFromJSON(json) {
    return HashicorpCloudPacker20221202ChannelAssignmentIterationFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ChannelAssignmentIterationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'incrementalVersion': !exists(json, 'incremental_version') ? undefined : json['incremental_version'],
        'fingerprint': !exists(json, 'fingerprint') ? undefined : json['fingerprint'],
        'complete': !exists(json, 'complete') ? undefined : json['complete'],
        'buildStatuses': !exists(json, 'build_statuses') ? undefined : json['build_statuses'],
        'revokeAt': !exists(json, 'revoke_at') ? undefined : (new Date(json['revoke_at'])),
        'status': !exists(json, 'status') ? undefined : HashicorpCloudPacker20221202IterationExternalStatusFromJSON(json['status']),
        'parents': !exists(json, 'parents') ? undefined : HashicorpCloudPacker20221202IterationParentsFromJSON(json['parents']),
    };
}
export function HashicorpCloudPacker20221202ChannelAssignmentIterationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'incremental_version': value.incrementalVersion,
        'fingerprint': value.fingerprint,
        'complete': value.complete,
        'build_statuses': value.buildStatuses,
        'revoke_at': value.revokeAt === undefined ? undefined : (value.revokeAt.toISOString()),
        'status': HashicorpCloudPacker20221202IterationExternalStatusToJSON(value.status),
        'parents': HashicorpCloudPacker20221202IterationParentsToJSON(value.parents),
    };
}
