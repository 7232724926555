/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * PolicyBindingMemberType is the type of the policy binding member.
 * @export
 * @enum {string}
 */
export var HashicorpCloudResourcemanagerPolicyBindingMemberType;
(function (HashicorpCloudResourcemanagerPolicyBindingMemberType) {
    HashicorpCloudResourcemanagerPolicyBindingMemberType["UNSET"] = "UNSET";
    HashicorpCloudResourcemanagerPolicyBindingMemberType["USER"] = "USER";
    HashicorpCloudResourcemanagerPolicyBindingMemberType["GROUP"] = "GROUP";
    HashicorpCloudResourcemanagerPolicyBindingMemberType["SERVICEPRINCIPAL"] = "SERVICE_PRINCIPAL";
})(HashicorpCloudResourcemanagerPolicyBindingMemberType || (HashicorpCloudResourcemanagerPolicyBindingMemberType = {}));
export function HashicorpCloudResourcemanagerPolicyBindingMemberTypeFromJSON(json) {
    return HashicorpCloudResourcemanagerPolicyBindingMemberTypeFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerPolicyBindingMemberTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudResourcemanagerPolicyBindingMemberTypeToJSON(value) {
    return value;
}
