/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105StatementFromJSON, Billing20201105StatementToJSON, } from './';
export function Billing20201105GetStatementResponseFromJSON(json) {
    return Billing20201105GetStatementResponseFromJSONTyped(json, false);
}
export function Billing20201105GetStatementResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'statement': !exists(json, 'statement') ? undefined : Billing20201105StatementFromJSON(json['statement']),
    };
}
export function Billing20201105GetStatementResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'statement': Billing20201105StatementToJSON(value.statement),
    };
}
