/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { Billing20201105CreateBillingAccountResponseFromJSON, Billing20201105CreateSetupIntentResponseFromJSON, Billing20201105GetBillingAccountResponseFromJSON, Billing20201105GetPricingModelTransitionsResponseFromJSON, Billing20201105RemoveOnDemandPaymentMethodResponseFromJSON, Billing20201105UpdateBillingAccountResponseFromJSON, BillingAccountServiceCreateBodyToJSON, BillingAccountServiceUpdateBodyToJSON, } from '../models';
/**
 *
 */
export class BillingAccountServiceApi extends runtime.BaseAPI {
    /**
     * This endpoint is idempotent and it is expected that clients will retry their requests on server-side failure.
     * Create a new Billing Account for the organization.
     */
    async billingAccountServiceCreateRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling billingAccountServiceCreate.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling billingAccountServiceCreate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillingAccountServiceCreateBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105CreateBillingAccountResponseFromJSON(jsonValue));
    }
    /**
     * This endpoint is idempotent and it is expected that clients will retry their requests on server-side failure.
     * Create a new Billing Account for the organization.
     */
    async billingAccountServiceCreate(organizationId, body) {
        const response = await this.billingAccountServiceCreateRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * https://stripe.com/docs/payments/setup-intents
     * CreateSetupIntent creates a Setup Intent, used to collect payment details with Stripe.js (and perform any necessary checks e.g. 3D-Secure).
     */
    async billingAccountServiceCreateSetupIntentRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling billingAccountServiceCreateSetupIntent.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/setup-intents`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105CreateSetupIntentResponseFromJSON(jsonValue));
    }
    /**
     * https://stripe.com/docs/payments/setup-intents
     * CreateSetupIntent creates a Setup Intent, used to collect payment details with Stripe.js (and perform any necessary checks e.g. 3D-Secure).
     */
    async billingAccountServiceCreateSetupIntent(organizationId) {
        const response = await this.billingAccountServiceCreateSetupIntentRaw({ organizationId: organizationId });
        return await response.value();
    }
    /**
     * Get a Billing Account by ID.
     */
    async billingAccountServiceGetRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling billingAccountServiceGet.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling billingAccountServiceGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105GetBillingAccountResponseFromJSON(jsonValue));
    }
    /**
     * Get a Billing Account by ID.
     */
    async billingAccountServiceGet(organizationId, id) {
        const response = await this.billingAccountServiceGetRaw({ organizationId: organizationId, id: id });
        return await response.value();
    }
    /**
     * GetByProject returns the billing account associated with the given project. If no billing account is set for the project, NotFound is returned.
     */
    async billingAccountServiceGetByProjectRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling billingAccountServiceGetByProject.');
        }
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling billingAccountServiceGetByProject.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/projects/{project_id}/account`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105GetBillingAccountResponseFromJSON(jsonValue));
    }
    /**
     * GetByProject returns the billing account associated with the given project. If no billing account is set for the project, NotFound is returned.
     */
    async billingAccountServiceGetByProject(organizationId, projectId) {
        const response = await this.billingAccountServiceGetByProjectRaw({ organizationId: organizationId, projectId: projectId });
        return await response.value();
    }
    /**
     * GetPricingModelTransitions gets the pricing model transitions for the given billing account. If no start is specified, it will default to 1/1/1 00:00:00.000000000 UTC. If no end date is specified, it will default to 12/31/9999 11:59:59.000000000 UTC.
     */
    async billingAccountServiceGetPricingModelTransitionsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling billingAccountServiceGetPricingModelTransitions.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling billingAccountServiceGetPricingModelTransitions.');
        }
        const queryParameters = {};
        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start.toISOString();
        }
        if (requestParameters.end !== undefined) {
            queryParameters['end'] = requestParameters.end.toISOString();
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/pricing-model-transitions`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105GetPricingModelTransitionsResponseFromJSON(jsonValue));
    }
    /**
     * GetPricingModelTransitions gets the pricing model transitions for the given billing account. If no start is specified, it will default to 1/1/1 00:00:00.000000000 UTC. If no end date is specified, it will default to 12/31/9999 11:59:59.000000000 UTC.
     */
    async billingAccountServiceGetPricingModelTransitions(organizationId, billingAccountId, start, end) {
        const response = await this.billingAccountServiceGetPricingModelTransitionsRaw({ organizationId: organizationId, billingAccountId: billingAccountId, start: start, end: end });
        return await response.value();
    }
    /**
     */
    async billingAccountServiceRemoveOnDemandPaymentMethodRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling billingAccountServiceRemoveOnDemandPaymentMethod.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling billingAccountServiceRemoveOnDemandPaymentMethod.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/billing_accounts/{billing_account_id}/on_demand_payment_method`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105RemoveOnDemandPaymentMethodResponseFromJSON(jsonValue));
    }
    /**
     */
    async billingAccountServiceRemoveOnDemandPaymentMethod(organizationId, billingAccountId) {
        const response = await this.billingAccountServiceRemoveOnDemandPaymentMethodRaw({ organizationId: organizationId, billingAccountId: billingAccountId });
        return await response.value();
    }
    /**
     * Update a Billing Account\'s settings.
     */
    async billingAccountServiceUpdateRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling billingAccountServiceUpdate.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling billingAccountServiceUpdate.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling billingAccountServiceUpdate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BillingAccountServiceUpdateBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105UpdateBillingAccountResponseFromJSON(jsonValue));
    }
    /**
     * Update a Billing Account\'s settings.
     */
    async billingAccountServiceUpdate(organizationId, id, body) {
        const response = await this.billingAccountServiceUpdateRaw({ organizationId: organizationId, id: id, body: body });
        return await response.value();
    }
}
