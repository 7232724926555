import BaseAbility from './base-ability';
import {
  WAYPOINT_TEMPLATES,
  WAYPOINT_TEMPLATES_UPDATE,
  WAYPOINT_TEMPLATES_DELETE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = WAYPOINT_TEMPLATES;

  get canManage() {
    return (
      this.permissions.has(WAYPOINT_TEMPLATES_UPDATE) &&
      this.permissions.has(WAYPOINT_TEMPLATES_DELETE)
    );
  }
}
