import templateOnlyComponent from '@ember/component/template-only';

interface StepperStepLabelSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLSpanElement;
}

const StepperStepLabelComponent =
  templateOnlyComponent<StepperStepLabelSignature>();

export default StepperStepLabelComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Stepper::StepLabel': typeof StepperStepLabelComponent;
    'stepper/step-label': typeof StepperStepLabelComponent;
  }
}
