/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ApiHttpBodyFromJSON, BillingToJSON, InlineObjectToJSON, InlineObject1ToJSON, InlineObject10ToJSON, InlineObject11ToJSON, InlineObject12ToJSON, InlineObject13ToJSON, InlineObject2ToJSON, InlineObject3ToJSON, InlineObject4ToJSON, InlineObject5ToJSON, InlineObject6ToJSON, InlineObject7ToJSON, InlineObject8ToJSON, InlineObject9ToJSON, Secrets20230613ConnectGitHubInstallationResponseFromJSON, Secrets20230613CreateAppKVSecretResponseFromJSON, Secrets20230613CreateAppResponseFromJSON, Secrets20230613CreateSyncIntegrationResponseFromJSON, Secrets20230613GetAppResponseFromJSON, Secrets20230613GetAppSecretResponseFromJSON, Secrets20230613GetAppSecretVersionResponseFromJSON, Secrets20230613GetGitHubEnvironmentsResponseFromJSON, Secrets20230613GetGitHubInstallLinksResponseFromJSON, Secrets20230613GetGitHubRepositoriesResponseFromJSON, Secrets20230613GetSyncInstallationResponseFromJSON, Secrets20230613GetSyncIntegrationResponseFromJSON, Secrets20230613GetUsageResponseFromJSON, Secrets20230613GetVercelInstallationLinkResponseFromJSON, Secrets20230613ListAppSecretVersionsResponseFromJSON, Secrets20230613ListAppSecretsResponseFromJSON, Secrets20230613ListAppsResponseFromJSON, Secrets20230613ListGitHubInstallationsResponseFromJSON, Secrets20230613ListOpenAppSecretVersionsResponseFromJSON, Secrets20230613ListSyncInstallationsResponseFromJSON, Secrets20230613ListSyncIntegrationsResponseFromJSON, Secrets20230613OpenAppSecretResponseFromJSON, Secrets20230613OpenAppSecretVersionResponseFromJSON, Secrets20230613OpenAppSecretsResponseFromJSON, Secrets20230613UpdateAppResponseFromJSON, Secrets20230613UpdateSyncInstallationResponseFromJSON, } from '../models';
/**
 *
 */
export class SecretServiceApi extends runtime.BaseAPI {
    /**
     */
    async completeGitHubInstallRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }
        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }
        if (requestParameters.installationId !== undefined) {
            queryParameters['installation_id'] = requestParameters.installationId;
        }
        if (requestParameters.setupAction !== undefined) {
            queryParameters['setup_action'] = requestParameters.setupAction;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/sync/github/callback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ApiHttpBodyFromJSON(jsonValue));
    }
    /**
     */
    async completeGitHubInstall(code, state, installationId, setupAction) {
        const response = await this.completeGitHubInstallRaw({ code: code, state: state, installationId: installationId, setupAction: setupAction });
        return await response.value();
    }
    /**
     */
    async completeVercelInstallationRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }
        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }
        if (requestParameters.configurationId !== undefined) {
            queryParameters['configuration_id'] = requestParameters.configurationId;
        }
        if (requestParameters.teamId !== undefined) {
            queryParameters['team_id'] = requestParameters.teamId;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/sync/vercel/callback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ApiHttpBodyFromJSON(jsonValue));
    }
    /**
     */
    async completeVercelInstallation(code, state, configurationId, teamId) {
        const response = await this.completeVercelInstallationRaw({ code: code, state: state, configurationId: configurationId, teamId: teamId });
        return await response.value();
    }
    /**
     */
    async connectGitHubInstallationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling connectGitHubInstallation.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling connectGitHubInstallation.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling connectGitHubInstallation.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/github/installations`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject9ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613ConnectGitHubInstallationResponseFromJSON(jsonValue));
    }
    /**
     */
    async connectGitHubInstallation(locationOrganizationId, locationProjectId, body) {
        const response = await this.connectGitHubInstallationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     */
    async createAppRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling createApp.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createApp.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createApp.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613CreateAppResponseFromJSON(jsonValue));
    }
    /**
     */
    async createApp(locationOrganizationId, locationProjectId, body) {
        const response = await this.createAppRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     */
    async createAppKVSecretRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling createAppKVSecret.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createAppKVSecret.');
        }
        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName', 'Required parameter requestParameters.appName was null or undefined when calling createAppKVSecret.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createAppKVSecret.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{app_name}/kv`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"app_name"}}`, encodeURIComponent(String(requestParameters.appName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject1ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613CreateAppKVSecretResponseFromJSON(jsonValue));
    }
    /**
     */
    async createAppKVSecret(locationOrganizationId, locationProjectId, appName, body) {
        const response = await this.createAppKVSecretRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, appName: appName, body: body });
        return await response.value();
    }
    /**
     */
    async createAwsSmSyncIntegrationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling createAwsSmSyncIntegration.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createAwsSmSyncIntegration.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createAwsSmSyncIntegration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/aws-sm`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject3ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613CreateSyncIntegrationResponseFromJSON(jsonValue));
    }
    /**
     */
    async createAwsSmSyncIntegration(locationOrganizationId, locationProjectId, body) {
        const response = await this.createAwsSmSyncIntegrationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     */
    async createAzureKvSyncIntegrationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling createAzureKvSyncIntegration.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createAzureKvSyncIntegration.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createAzureKvSyncIntegration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/azure-kv`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject4ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613CreateSyncIntegrationResponseFromJSON(jsonValue));
    }
    /**
     */
    async createAzureKvSyncIntegration(locationOrganizationId, locationProjectId, body) {
        const response = await this.createAzureKvSyncIntegrationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     */
    async createGcpSmSyncIntegrationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling createGcpSmSyncIntegration.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createGcpSmSyncIntegration.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createGcpSmSyncIntegration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/gcp-sm`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject6ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613CreateSyncIntegrationResponseFromJSON(jsonValue));
    }
    /**
     */
    async createGcpSmSyncIntegration(locationOrganizationId, locationProjectId, body) {
        const response = await this.createGcpSmSyncIntegrationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     */
    async createGhOrgSyncIntegrationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling createGhOrgSyncIntegration.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createGhOrgSyncIntegration.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createGhOrgSyncIntegration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/gh-org`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject7ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613CreateSyncIntegrationResponseFromJSON(jsonValue));
    }
    /**
     */
    async createGhOrgSyncIntegration(locationOrganizationId, locationProjectId, body) {
        const response = await this.createGhOrgSyncIntegrationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     */
    async createGhRepoSyncIntegrationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling createGhRepoSyncIntegration.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createGhRepoSyncIntegration.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createGhRepoSyncIntegration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/gh-repo`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject8ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613CreateSyncIntegrationResponseFromJSON(jsonValue));
    }
    /**
     */
    async createGhRepoSyncIntegration(locationOrganizationId, locationProjectId, body) {
        const response = await this.createGhRepoSyncIntegrationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     */
    async createHcpTerraformSyncInstallationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling createHcpTerraformSyncInstallation.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createHcpTerraformSyncInstallation.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createHcpTerraformSyncInstallation.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/hcp-terraform/installations`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject11ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613GetSyncInstallationResponseFromJSON(jsonValue));
    }
    /**
     */
    async createHcpTerraformSyncInstallation(locationOrganizationId, locationProjectId, body) {
        const response = await this.createHcpTerraformSyncInstallationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     */
    async createHcpTerraformSyncIntegrationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling createHcpTerraformSyncIntegration.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createHcpTerraformSyncIntegration.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createHcpTerraformSyncIntegration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/hcp-terraform`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject10ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613CreateSyncIntegrationResponseFromJSON(jsonValue));
    }
    /**
     */
    async createHcpTerraformSyncIntegration(locationOrganizationId, locationProjectId, body) {
        const response = await this.createHcpTerraformSyncIntegrationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     */
    async createVercelProjectSyncIntegrationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling createVercelProjectSyncIntegration.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createVercelProjectSyncIntegration.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createVercelProjectSyncIntegration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/vercel-project`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject13ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613CreateSyncIntegrationResponseFromJSON(jsonValue));
    }
    /**
     */
    async createVercelProjectSyncIntegration(locationOrganizationId, locationProjectId, body) {
        const response = await this.createVercelProjectSyncIntegrationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     */
    async deleteAppRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling deleteApp.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling deleteApp.');
        }
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name', 'Required parameter requestParameters.name was null or undefined when calling deleteApp.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async deleteApp(locationOrganizationId, locationProjectId, name, locationRegionProvider, locationRegionRegion) {
        const response = await this.deleteAppRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, name: name, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async deleteAppSecretRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling deleteAppSecret.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling deleteAppSecret.');
        }
        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName', 'Required parameter requestParameters.appName was null or undefined when calling deleteAppSecret.');
        }
        if (requestParameters.secretName === null || requestParameters.secretName === undefined) {
            throw new runtime.RequiredError('secretName', 'Required parameter requestParameters.secretName was null or undefined when calling deleteAppSecret.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{app_name}/secrets/{secret_name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"app_name"}}`, encodeURIComponent(String(requestParameters.appName))).replace(`{${"secret_name"}}`, encodeURIComponent(String(requestParameters.secretName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async deleteAppSecret(locationOrganizationId, locationProjectId, appName, secretName, locationRegionProvider, locationRegionRegion) {
        const response = await this.deleteAppSecretRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, appName: appName, secretName: secretName, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async deleteAppSecretVersionRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling deleteAppSecretVersion.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling deleteAppSecretVersion.');
        }
        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName', 'Required parameter requestParameters.appName was null or undefined when calling deleteAppSecretVersion.');
        }
        if (requestParameters.secretName === null || requestParameters.secretName === undefined) {
            throw new runtime.RequiredError('secretName', 'Required parameter requestParameters.secretName was null or undefined when calling deleteAppSecretVersion.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling deleteAppSecretVersion.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{app_name}/secrets/{secret_name}/versions/{version}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"app_name"}}`, encodeURIComponent(String(requestParameters.appName))).replace(`{${"secret_name"}}`, encodeURIComponent(String(requestParameters.secretName))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async deleteAppSecretVersion(locationOrganizationId, locationProjectId, appName, secretName, version, locationRegionProvider, locationRegionRegion) {
        const response = await this.deleteAppSecretVersionRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, appName: appName, secretName: secretName, version: version, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async deleteSyncInstallationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling deleteSyncInstallation.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling deleteSyncInstallation.');
        }
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name', 'Required parameter requestParameters.name was null or undefined when calling deleteSyncInstallation.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/installations/{name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async deleteSyncInstallation(locationOrganizationId, locationProjectId, name, locationRegionProvider, locationRegionRegion) {
        const response = await this.deleteSyncInstallationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, name: name, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async deleteSyncIntegrationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling deleteSyncIntegration.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling deleteSyncIntegration.');
        }
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name', 'Required parameter requestParameters.name was null or undefined when calling deleteSyncIntegration.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.cascadeDelete !== undefined) {
            queryParameters['cascade_delete'] = requestParameters.cascadeDelete;
        }
        if (requestParameters.purgeSyncedSecrets !== undefined) {
            queryParameters['purge_synced_secrets'] = requestParameters.purgeSyncedSecrets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/integrations/{name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async deleteSyncIntegration(locationOrganizationId, locationProjectId, name, locationRegionProvider, locationRegionRegion, cascadeDelete, purgeSyncedSecrets) {
        const response = await this.deleteSyncIntegrationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, name: name, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, cascadeDelete: cascadeDelete, purgeSyncedSecrets: purgeSyncedSecrets });
        return await response.value();
    }
    /**
     */
    async forceSyncRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling forceSync.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling forceSync.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling forceSync.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/force`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject5ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async forceSync(locationOrganizationId, locationProjectId, body) {
        const response = await this.forceSyncRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     */
    async getAppRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getApp.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getApp.');
        }
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name', 'Required parameter requestParameters.name was null or undefined when calling getApp.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613GetAppResponseFromJSON(jsonValue));
    }
    /**
     */
    async getApp(locationOrganizationId, locationProjectId, name, locationRegionProvider, locationRegionRegion) {
        const response = await this.getAppRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, name: name, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async getAppSecretRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getAppSecret.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getAppSecret.');
        }
        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName', 'Required parameter requestParameters.appName was null or undefined when calling getAppSecret.');
        }
        if (requestParameters.secretName === null || requestParameters.secretName === undefined) {
            throw new runtime.RequiredError('secretName', 'Required parameter requestParameters.secretName was null or undefined when calling getAppSecret.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{app_name}/secrets/{secret_name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"app_name"}}`, encodeURIComponent(String(requestParameters.appName))).replace(`{${"secret_name"}}`, encodeURIComponent(String(requestParameters.secretName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613GetAppSecretResponseFromJSON(jsonValue));
    }
    /**
     */
    async getAppSecret(locationOrganizationId, locationProjectId, appName, secretName, locationRegionProvider, locationRegionRegion) {
        const response = await this.getAppSecretRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, appName: appName, secretName: secretName, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async getAppSecretVersionRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getAppSecretVersion.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getAppSecretVersion.');
        }
        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName', 'Required parameter requestParameters.appName was null or undefined when calling getAppSecretVersion.');
        }
        if (requestParameters.secretName === null || requestParameters.secretName === undefined) {
            throw new runtime.RequiredError('secretName', 'Required parameter requestParameters.secretName was null or undefined when calling getAppSecretVersion.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling getAppSecretVersion.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{app_name}/secrets/{secret_name}/versions/{version}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"app_name"}}`, encodeURIComponent(String(requestParameters.appName))).replace(`{${"secret_name"}}`, encodeURIComponent(String(requestParameters.secretName))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613GetAppSecretVersionResponseFromJSON(jsonValue));
    }
    /**
     */
    async getAppSecretVersion(locationOrganizationId, locationProjectId, appName, secretName, version, locationRegionProvider, locationRegionRegion) {
        const response = await this.getAppSecretVersionRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, appName: appName, secretName: secretName, version: version, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async getGitHubEnvironmentsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getGitHubEnvironments.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getGitHubEnvironments.');
        }
        if (requestParameters.repository === null || requestParameters.repository === undefined) {
            throw new runtime.RequiredError('repository', 'Required parameter requestParameters.repository was null or undefined when calling getGitHubEnvironments.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.installationName !== undefined) {
            queryParameters['installation_name'] = requestParameters.installationName;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/github/repositories/{repository}/environments`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"repository"}}`, encodeURIComponent(String(requestParameters.repository))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613GetGitHubEnvironmentsResponseFromJSON(jsonValue));
    }
    /**
     */
    async getGitHubEnvironments(locationOrganizationId, locationProjectId, repository, locationRegionProvider, locationRegionRegion, installationName) {
        const response = await this.getGitHubEnvironmentsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, repository: repository, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, installationName: installationName });
        return await response.value();
    }
    /**
     */
    async getGitHubInstallLinksRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getGitHubInstallLinks.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getGitHubInstallLinks.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/github/links`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613GetGitHubInstallLinksResponseFromJSON(jsonValue));
    }
    /**
     */
    async getGitHubInstallLinks(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion) {
        const response = await this.getGitHubInstallLinksRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async getGitHubRepositoriesRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getGitHubRepositories.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getGitHubRepositories.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.installationName !== undefined) {
            queryParameters['installation_name'] = requestParameters.installationName;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/github/repositories`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613GetGitHubRepositoriesResponseFromJSON(jsonValue));
    }
    /**
     */
    async getGitHubRepositories(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion, installationName) {
        const response = await this.getGitHubRepositoriesRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, installationName: installationName });
        return await response.value();
    }
    /**
     */
    async getSyncInstallationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getSyncInstallation.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getSyncInstallation.');
        }
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name', 'Required parameter requestParameters.name was null or undefined when calling getSyncInstallation.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/installations/{name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613GetSyncInstallationResponseFromJSON(jsonValue));
    }
    /**
     */
    async getSyncInstallation(locationOrganizationId, locationProjectId, name, locationRegionProvider, locationRegionRegion) {
        const response = await this.getSyncInstallationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, name: name, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async getSyncIntegrationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getSyncIntegration.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getSyncIntegration.');
        }
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name', 'Required parameter requestParameters.name was null or undefined when calling getSyncIntegration.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/integrations/{name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613GetSyncIntegrationResponseFromJSON(jsonValue));
    }
    /**
     */
    async getSyncIntegration(locationOrganizationId, locationProjectId, name, locationRegionProvider, locationRegionRegion) {
        const response = await this.getSyncIntegrationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, name: name, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async getUsageRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getUsage.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getUsage.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/usage`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613GetUsageResponseFromJSON(jsonValue));
    }
    /**
     */
    async getUsage(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion) {
        const response = await this.getUsageRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async getUsage2Raw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getUsage2.');
        }
        const queryParameters = {};
        if (requestParameters.locationProjectId !== undefined) {
            queryParameters['location.project_id'] = requestParameters.locationProjectId;
        }
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/usage`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613GetUsageResponseFromJSON(jsonValue));
    }
    /**
     */
    async getUsage2(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion) {
        const response = await this.getUsage2Raw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async getVercelInstallationLinkRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getVercelInstallationLink.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getVercelInstallationLink.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/vercel/link`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613GetVercelInstallationLinkResponseFromJSON(jsonValue));
    }
    /**
     */
    async getVercelInstallationLink(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion) {
        const response = await this.getVercelInstallationLinkRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async listAppSecretVersionsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling listAppSecretVersions.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling listAppSecretVersions.');
        }
        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName', 'Required parameter requestParameters.appName was null or undefined when calling listAppSecretVersions.');
        }
        if (requestParameters.secretName === null || requestParameters.secretName === undefined) {
            throw new runtime.RequiredError('secretName', 'Required parameter requestParameters.secretName was null or undefined when calling listAppSecretVersions.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{app_name}/secrets/{secret_name}/versions`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"app_name"}}`, encodeURIComponent(String(requestParameters.appName))).replace(`{${"secret_name"}}`, encodeURIComponent(String(requestParameters.secretName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613ListAppSecretVersionsResponseFromJSON(jsonValue));
    }
    /**
     */
    async listAppSecretVersions(locationOrganizationId, locationProjectId, appName, secretName, locationRegionProvider, locationRegionRegion) {
        const response = await this.listAppSecretVersionsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, appName: appName, secretName: secretName, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async listAppSecretsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling listAppSecrets.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling listAppSecrets.');
        }
        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName', 'Required parameter requestParameters.appName was null or undefined when calling listAppSecrets.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{app_name}/secrets`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"app_name"}}`, encodeURIComponent(String(requestParameters.appName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613ListAppSecretsResponseFromJSON(jsonValue));
    }
    /**
     */
    async listAppSecrets(locationOrganizationId, locationProjectId, appName, locationRegionProvider, locationRegionRegion) {
        const response = await this.listAppSecretsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, appName: appName, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async listAppsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling listApps.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling listApps.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613ListAppsResponseFromJSON(jsonValue));
    }
    /**
     */
    async listApps(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion) {
        const response = await this.listAppsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async listGitHubInstallationsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling listGitHubInstallations.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling listGitHubInstallations.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/github/installations`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613ListGitHubInstallationsResponseFromJSON(jsonValue));
    }
    /**
     */
    async listGitHubInstallations(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion, state) {
        const response = await this.listGitHubInstallationsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, state: state });
        return await response.value();
    }
    /**
     */
    async listOpenAppSecretVersionsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling listOpenAppSecretVersions.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling listOpenAppSecretVersions.');
        }
        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName', 'Required parameter requestParameters.appName was null or undefined when calling listOpenAppSecretVersions.');
        }
        if (requestParameters.secretName === null || requestParameters.secretName === undefined) {
            throw new runtime.RequiredError('secretName', 'Required parameter requestParameters.secretName was null or undefined when calling listOpenAppSecretVersions.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{app_name}/open/{secret_name}/versions`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"app_name"}}`, encodeURIComponent(String(requestParameters.appName))).replace(`{${"secret_name"}}`, encodeURIComponent(String(requestParameters.secretName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613ListOpenAppSecretVersionsResponseFromJSON(jsonValue));
    }
    /**
     */
    async listOpenAppSecretVersions(locationOrganizationId, locationProjectId, appName, secretName, locationRegionProvider, locationRegionRegion) {
        const response = await this.listOpenAppSecretVersionsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, appName: appName, secretName: secretName, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async listSyncInstallationsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling listSyncInstallations.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling listSyncInstallations.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/installations`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613ListSyncInstallationsResponseFromJSON(jsonValue));
    }
    /**
     */
    async listSyncInstallations(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion, type) {
        const response = await this.listSyncInstallationsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, type: type });
        return await response.value();
    }
    /**
     */
    async listSyncIntegrationsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling listSyncIntegrations.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling listSyncIntegrations.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/integrations`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613ListSyncIntegrationsResponseFromJSON(jsonValue));
    }
    /**
     */
    async listSyncIntegrations(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion) {
        const response = await this.listSyncIntegrationsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async openAppSecretRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling openAppSecret.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling openAppSecret.');
        }
        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName', 'Required parameter requestParameters.appName was null or undefined when calling openAppSecret.');
        }
        if (requestParameters.secretName === null || requestParameters.secretName === undefined) {
            throw new runtime.RequiredError('secretName', 'Required parameter requestParameters.secretName was null or undefined when calling openAppSecret.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{app_name}/open/{secret_name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"app_name"}}`, encodeURIComponent(String(requestParameters.appName))).replace(`{${"secret_name"}}`, encodeURIComponent(String(requestParameters.secretName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613OpenAppSecretResponseFromJSON(jsonValue));
    }
    /**
     */
    async openAppSecret(locationOrganizationId, locationProjectId, appName, secretName, locationRegionProvider, locationRegionRegion) {
        const response = await this.openAppSecretRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, appName: appName, secretName: secretName, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async openAppSecretVersionRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling openAppSecretVersion.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling openAppSecretVersion.');
        }
        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName', 'Required parameter requestParameters.appName was null or undefined when calling openAppSecretVersion.');
        }
        if (requestParameters.secretName === null || requestParameters.secretName === undefined) {
            throw new runtime.RequiredError('secretName', 'Required parameter requestParameters.secretName was null or undefined when calling openAppSecretVersion.');
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version', 'Required parameter requestParameters.version was null or undefined when calling openAppSecretVersion.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{app_name}/open/{secret_name}/versions/{version}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"app_name"}}`, encodeURIComponent(String(requestParameters.appName))).replace(`{${"secret_name"}}`, encodeURIComponent(String(requestParameters.secretName))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613OpenAppSecretVersionResponseFromJSON(jsonValue));
    }
    /**
     */
    async openAppSecretVersion(locationOrganizationId, locationProjectId, appName, secretName, version, locationRegionProvider, locationRegionRegion) {
        const response = await this.openAppSecretVersionRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, appName: appName, secretName: secretName, version: version, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async openAppSecretsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling openAppSecrets.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling openAppSecrets.');
        }
        if (requestParameters.appName === null || requestParameters.appName === undefined) {
            throw new runtime.RequiredError('appName', 'Required parameter requestParameters.appName was null or undefined when calling openAppSecrets.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{app_name}/open`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"app_name"}}`, encodeURIComponent(String(requestParameters.appName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613OpenAppSecretsResponseFromJSON(jsonValue));
    }
    /**
     */
    async openAppSecrets(locationOrganizationId, locationProjectId, appName, locationRegionProvider, locationRegionRegion) {
        const response = await this.openAppSecretsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, appName: appName, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async setTierRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling setTier.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling setTier.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/billing/tier`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BillingToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async setTier(locationOrganizationId, body) {
        const response = await this.setTierRaw({ locationOrganizationId: locationOrganizationId, body: body });
        return await response.value();
    }
    /**
     */
    async updateAppRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling updateApp.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling updateApp.');
        }
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name', 'Required parameter requestParameters.name was null or undefined when calling updateApp.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling updateApp.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/apps/{name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject2ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613UpdateAppResponseFromJSON(jsonValue));
    }
    /**
     */
    async updateApp(locationOrganizationId, locationProjectId, name, body) {
        const response = await this.updateAppRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, name: name, body: body });
        return await response.value();
    }
    /**
     */
    async updateSyncInstallationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling updateSyncInstallation.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling updateSyncInstallation.');
        }
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name', 'Required parameter requestParameters.name was null or undefined when calling updateSyncInstallation.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling updateSyncInstallation.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/secrets/2023-06-13/organizations/{location.organization_id}/projects/{location.project_id}/sync/installations/{name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject12ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Secrets20230613UpdateSyncInstallationResponseFromJSON(jsonValue));
    }
    /**
     */
    async updateSyncInstallation(locationOrganizationId, locationProjectId, name, body) {
        const response = await this.updateSyncInstallationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, name: name, body: body });
        return await response.value();
    }
}
