import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';
import type { IamParsedRole } from '../types/iam-parsed-role.ts';

export const BASIC_ROLE_KEY_UI_ONLY = 'basic';
export const ROLE_ID_TO_SERVICE_OVERRIDE: {
  [roleId: Exclude<HashicorpCloudResourcemanagerRole['id'], undefined>]: string;
} = {
  'roles/resource-manager.browser': BASIC_ROLE_KEY_UI_ONLY,
};

export function parseRoleId(
  roleId: HashicorpCloudResourcemanagerRole['id'] = '',
): IamParsedRole {
  const [, serviceAndPersona = ''] = roleId.split('/');
  const [service, persona] = serviceAndPersona.split('.');
  const serviceOverride = ROLE_ID_TO_SERVICE_OVERRIDE[roleId];

  let serviceValue: string = BASIC_ROLE_KEY_UI_ONLY;

  if (persona && service) {
    serviceValue = service;
  }

  if (serviceOverride) {
    serviceValue = serviceOverride;
  }

  return {
    roleId,
    service: serviceValue,
    persona,
  };
}

export default parseRoleId;
