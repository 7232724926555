import Component from '@glimmer/component';

interface ZeroConnectionsBannerSignature {
  Args: {
    model: unknown;
    network: unknown;
    route: unknown;
    useLinkToExternal: unknown;
  };
}

export default class ZeroConnectionsBannerComponent extends Component<ZeroConnectionsBannerSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ZeroConnectionsBanner: typeof ZeroConnectionsBannerComponent;
    'zero-connections-banner': typeof ZeroConnectionsBannerComponent;
  }
}
