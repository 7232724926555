/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Status represents the status of a given consumption pool.
 *
 *  - ACTIVE: UPCOMING = 2;
 *     ROLLOVER = 3;
 *     NEGATIVE = 4;
 *     EXPIRED = 5;
 * @export
 * @enum {string}
 */
export var Billing20201105ConsumptionPoolStatus;
(function (Billing20201105ConsumptionPoolStatus) {
    Billing20201105ConsumptionPoolStatus["UNSET"] = "UNSET";
    Billing20201105ConsumptionPoolStatus["ACTIVE"] = "ACTIVE";
})(Billing20201105ConsumptionPoolStatus || (Billing20201105ConsumptionPoolStatus = {}));
export function Billing20201105ConsumptionPoolStatusFromJSON(json) {
    return Billing20201105ConsumptionPoolStatusFromJSONTyped(json, false);
}
export function Billing20201105ConsumptionPoolStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Billing20201105ConsumptionPoolStatusToJSON(value) {
    return value;
}
