import Component from '@glimmer/component';

interface VersionSelectSignature {
  Args: {
    disabled: unknown;
    onChange: unknown;
    selectedVersion: unknown;
    versions: unknown;
  };
}

/**
 *
 * Simple select box input, formats & localizes version statuse in options, example: "v1.2.3 (Recommended)".
 *
 *
 * ```
 * <FormsInputs::VersionSelect
 *   @versions={{versions}}
 *   @selectedVersion={{selectedVersion}}
 *   @onChange={{onChange}}
 *   @disabled={{disabled}}
 * />
 * ```
 *
 * @class FormInputsVersionSelect
 *
 */

export default class FormInputsVersionSelectComponent extends Component<VersionSelectSignature> {
  /**
   * @argument versions - the list of verstions from API data
   * @type {Array.<Object>}
   */
  /**
   * @argument selectedVersion - the selected version number string (example: "v.1.2.3")
   * @type {String}
   */
  /**
   * @argument onChange - handler function that accepts a browser Event as 0th argument
   * @type {Function}
   */
  /**
   * @argument disabled
   * @type {Boolean}
   */
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormInputs::VersionSelect': typeof FormInputsVersionSelectComponent;
    'form-inputs/version-select': typeof FormInputsVersionSelectComponent;
  }
}
