/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - TOKEN_INVALID_REASON_UNSPECIFIED: Token's invalid reason is unspecified because token is not invalid
 *  - TOKEN_INVALID_REASON_DOES_NOT_EXIST: Token is invalid because it does not exist. Expired tokens are deleted
 * and therefore do not exist
 *  - TOKEN_INVALID_REASON_NO_POLICIES: Token is invalid because no policies are applied to it
 *  - TOKEN_INVALID_REASON_MULTIPLE_POLICIES: Token is invalid because it has more than one policy applied to it
 *  - TOKEN_INVALID_REASON_INCORRECT_POLICY: Token is invalid because it does not have the expected policy applied to it
 *  - TOKEN_INVALID_REASON_ACLS_NOT_BOOTSTRAPPED: Token is invalid because the ACL system has not been bootstrapped
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215TokenInvalidReason;
(function (HashicorpCloudGlobalNetworkManager20220215TokenInvalidReason) {
    HashicorpCloudGlobalNetworkManager20220215TokenInvalidReason["UNSPECIFIED"] = "TOKEN_INVALID_REASON_UNSPECIFIED";
    HashicorpCloudGlobalNetworkManager20220215TokenInvalidReason["DOESNOTEXIST"] = "TOKEN_INVALID_REASON_DOES_NOT_EXIST";
    HashicorpCloudGlobalNetworkManager20220215TokenInvalidReason["NOPOLICIES"] = "TOKEN_INVALID_REASON_NO_POLICIES";
    HashicorpCloudGlobalNetworkManager20220215TokenInvalidReason["MULTIPLEPOLICIES"] = "TOKEN_INVALID_REASON_MULTIPLE_POLICIES";
    HashicorpCloudGlobalNetworkManager20220215TokenInvalidReason["INCORRECTPOLICY"] = "TOKEN_INVALID_REASON_INCORRECT_POLICY";
    HashicorpCloudGlobalNetworkManager20220215TokenInvalidReason["ACLSNOTBOOTSTRAPPED"] = "TOKEN_INVALID_REASON_ACLS_NOT_BOOTSTRAPPED";
})(HashicorpCloudGlobalNetworkManager20220215TokenInvalidReason || (HashicorpCloudGlobalNetworkManager20220215TokenInvalidReason = {}));
export function HashicorpCloudGlobalNetworkManager20220215TokenInvalidReasonFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215TokenInvalidReasonFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215TokenInvalidReasonFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215TokenInvalidReasonToJSON(value) {
    return value;
}
