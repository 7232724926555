/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125NewRelicRegion;
(function (HashicorpCloudVault20201125NewRelicRegion) {
    HashicorpCloudVault20201125NewRelicRegion["NEWRELICREGIONINVALID"] = "NEW_RELIC_REGION_INVALID";
    HashicorpCloudVault20201125NewRelicRegion["US"] = "US";
    HashicorpCloudVault20201125NewRelicRegion["EU"] = "EU";
})(HashicorpCloudVault20201125NewRelicRegion || (HashicorpCloudVault20201125NewRelicRegion = {}));
export function HashicorpCloudVault20201125NewRelicRegionFromJSON(json) {
    return HashicorpCloudVault20201125NewRelicRegionFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125NewRelicRegionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125NewRelicRegionToJSON(value) {
    return value;
}
