/* eslint-disable @typescript-eslint/no-unused-vars */
import type { IPermission, PermissionsMap } from '../types/permissions.ts';

export default function iamPermissionsServiceCount<
  K,
  V extends { service: IPermission['service'] },
>(
  permissionsMap: PermissionsMap = new Map(),
  { service }: { service: IPermission['service'] },
): number {
  if (!(permissionsMap instanceof Map)) {
    return 0;
  }

  return Array.from(permissionsMap.values()).filter(
    (permission) => permission.service === service,
  ).length;
}
