/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20231128AppSyncFromJSON(json) {
    return Secrets20231128AppSyncFromJSONTyped(json, false);
}
export function Secrets20231128AppSyncFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'syncName': !exists(json, 'sync_name') ? undefined : json['sync_name'],
        'aggregatedStatus': !exists(json, 'aggregated_status') ? undefined : json['aggregated_status'],
        'aggregatedErrorCode': !exists(json, 'aggregated_error_code') ? undefined : json['aggregated_error_code'],
        'lastSyncedAt': !exists(json, 'last_synced_at') ? undefined : (new Date(json['last_synced_at'])),
        'numSecrets': !exists(json, 'num_secrets') ? undefined : json['num_secrets'],
    };
}
export function Secrets20231128AppSyncToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sync_name': value.syncName,
        'aggregated_status': value.aggregatedStatus,
        'aggregated_error_code': value.aggregatedErrorCode,
        'last_synced_at': value.lastSyncedAt === undefined ? undefined : (value.lastSyncedAt.toISOString()),
        'num_secrets': value.numSecrets,
    };
}
