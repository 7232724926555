import Component from '@glimmer/component';

interface ActionCardSignature {
  // Args: {};
  Blocks: {
    button: [];
    description: [];
    header: [];
    icon: [];
  };
}

/**
 *
 * `ActionCard` is a skeletal component for higher-order layouts. It's built
 * with the `Flex` component to stack a title and/or a label, center them
 * vertically, and allow for a space to render a button. This is powered by
 * any of the following combinations of the named blocks ":icon," ":header,"
 * ":description," and ":button".
 *
 * ```
 * <ActionCard>
 *   <:header>header</:header>
 *   <:description>description</:description>
 *   <:button>
 *     <button type='button'>Action</button>
 *   </:button>
 * </ActionCard>
 * ```
 *
 * @class ActionCard
 *
 */

export default class ActionCardComponent extends Component<ActionCardSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ActionCard: typeof ActionCardComponent;
    'action-card': typeof ActionCardComponent;
  }
}
