import Component from '@glimmer/component';
/**
 *
 * `NetworksCardsConnectionDetailsComponent` renders the provider radio cards for AWS and Azure
 *
 *
 * ```
 * <Networks::Cards::ConnectionDetails
 *   @connection={{connection}}
 * />
 * ```
 *
 * @class NetworksCardsConnectionDetailsComponent
 *
 */
export default class NetworksCardsConnectionDetailsComponent extends Component {
  get provider() {
    let {
      connection: {
        hvn: {
          location: {
            region: { provider },
          },
        },
      },
    } = this.args;
    return provider;
  }
}
