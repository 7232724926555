/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125DisasterRecoveryNetworkConfigFromJSON, HashicorpCloudVault20201125DisasterRecoveryNetworkConfigToJSON, HashicorpCloudVault20201125InputNetworkConfigFromJSON, HashicorpCloudVault20201125InputNetworkConfigToJSON, HashicorpCloudVault20201125ObservabilityConfigFromJSON, HashicorpCloudVault20201125ObservabilityConfigToJSON, HashicorpCloudVault20201125TierFromJSON, HashicorpCloudVault20201125TierToJSON, HashicorpCloudVault20201125VaultConfigFromJSON, HashicorpCloudVault20201125VaultConfigToJSON, } from './';
export function HashicorpCloudVault20201125InputClusterConfigFromJSON(json) {
    return HashicorpCloudVault20201125InputClusterConfigFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125InputClusterConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'networkConfig': !exists(json, 'network_config') ? undefined : HashicorpCloudVault20201125InputNetworkConfigFromJSON(json['network_config']),
        'vaultConfig': !exists(json, 'vault_config') ? undefined : HashicorpCloudVault20201125VaultConfigFromJSON(json['vault_config']),
        'tier': !exists(json, 'tier') ? undefined : HashicorpCloudVault20201125TierFromJSON(json['tier']),
        'metricsConfig': !exists(json, 'metrics_config') ? undefined : HashicorpCloudVault20201125ObservabilityConfigFromJSON(json['metrics_config']),
        'auditLogExportConfig': !exists(json, 'audit_log_export_config') ? undefined : HashicorpCloudVault20201125ObservabilityConfigFromJSON(json['audit_log_export_config']),
        'disasterRecoveryNetworkConfig': !exists(json, 'disaster_recovery_network_config') ? undefined : HashicorpCloudVault20201125DisasterRecoveryNetworkConfigFromJSON(json['disaster_recovery_network_config']),
    };
}
export function HashicorpCloudVault20201125InputClusterConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'network_config': HashicorpCloudVault20201125InputNetworkConfigToJSON(value.networkConfig),
        'vault_config': HashicorpCloudVault20201125VaultConfigToJSON(value.vaultConfig),
        'tier': HashicorpCloudVault20201125TierToJSON(value.tier),
        'metrics_config': HashicorpCloudVault20201125ObservabilityConfigToJSON(value.metricsConfig),
        'audit_log_export_config': HashicorpCloudVault20201125ObservabilityConfigToJSON(value.auditLogExportConfig),
        'disaster_recovery_network_config': HashicorpCloudVault20201125DisasterRecoveryNetworkConfigToJSON(value.disasterRecoveryNetworkConfig),
    };
}
