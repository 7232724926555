/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128MongoDBRoleFromJSON, Secrets20231128MongoDBRoleToJSON, Secrets20231128MongoDBScopeFromJSON, Secrets20231128MongoDBScopeToJSON, } from './';
export function Secrets20231128MongoDBAtlasSecretDetailsFromJSON(json) {
    return Secrets20231128MongoDBAtlasSecretDetailsFromJSONTyped(json, false);
}
export function Secrets20231128MongoDBAtlasSecretDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mongodbGroupId': !exists(json, 'mongodb_group_id') ? undefined : json['mongodb_group_id'],
        'mongodbRoles': !exists(json, 'mongodb_roles') ? undefined : (json['mongodb_roles'].map(Secrets20231128MongoDBRoleFromJSON)),
        'mongodbScopes': !exists(json, 'mongodb_scopes') ? undefined : (json['mongodb_scopes'].map(Secrets20231128MongoDBScopeFromJSON)),
    };
}
export function Secrets20231128MongoDBAtlasSecretDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mongodb_group_id': value.mongodbGroupId,
        'mongodb_roles': value.mongodbRoles === undefined ? undefined : (value.mongodbRoles.map(Secrets20231128MongoDBRoleToJSON)),
        'mongodb_scopes': value.mongodbScopes === undefined ? undefined : (value.mongodbScopes.map(Secrets20231128MongoDBScopeToJSON)),
    };
}
