import Component from '@glimmer/component';
import { DEBUG } from '@glimmer/env';
import { assert } from '@ember/debug';

interface HvnSelectGroupSignature {
  Args: {
    groupName: unknown;
    groupNetworkOptions: unknown;
    ids: unknown;
  };
}

/**
 * Renderless logical component which triggers parent network options to group Network objects with matching IDs under a named option group.
 *
 * **NOTE:** _This component is NOT meant to be used independently, rather yielded back from a parent `<HvnSelect>` component._
 *
 * Example usage:
 *
 * ```
 * <FormInputs::HvnSelect
 *  @networks={{networks}}
 *  @selectedNetworkId={{selectedNetworkId}}
 *  @onChange={{onChange}}
 *  as |Hvns|
 * >
 *   <Hvns.Group
 *     @name="Network used by Primary Consul cluster"
 *     @ids={{array 'primary-consul-clusters-network'}}
 *   />
 *
 *
 *   <Hvns.Group
 *     @name="Other available networks"
 *     @ids={{array 'available-network-1' 'available-network-2'}}
 *   />
 * </FormInputs::HvnSelect>
 * ```
 *
 * @see {FormInputsHvnSelect}
 * @class FormInputsHvnSelectGroup
 *
 */

export default class FormInputsHvnSelectGroupComponent extends Component<HvnSelectGroupSignature> {
  /**
   * @argument groupNetworkOptions
   * @type {Function(Array.<String>)}
   */
  /**
   * @argument ids
   * @type {Array.<String>}
   */

  constructor(owner: unknown, args: HvnSelectGroupSignature['Args']) {
    super(owner, args);

    const { groupNetworkOptions, groupName, ids } = this.args;

    if (DEBUG) {
      assert(
        '<HvnSelect.Group> Argument @groupNetworkOptions is expected to be a Function (passed down from parent <HvnSelect> component)',
        typeof groupNetworkOptions === 'function'
      );

      assert(
        '<HvnSelect.Group> Argument @groupName is expected to be a non-empty String',
        typeof groupName === 'string' && groupName.length
      );

      assert(
        '<HvnSelect.Group> Argument @ids is expected to be an Array of String Network IDs',
        Array.isArray(ids)
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormInputs::HvnSelect::Group': typeof FormInputsHvnSelectGroupComponent;
    'form-inputs/hvn-select/group': typeof FormInputsHvnSelectGroupComponent;
  }
}
