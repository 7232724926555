import Component from '@glimmer/component';

interface NetworkCardSignature {
  Args: {
    network: unknown;
    route: unknown;
    useLinkToExternal: unknown;
  };
  Element: HTMLDivElement;
}

/**
 *
 * `NetworkCard` renders a card with info related to a resource's HVN and then links to the HVN detail page.
 *
 * ## Example usage
 *
 * ```
 * <NetworkCard
 *   @clusterProductName='Vault'
 *   @network={{@model.network}}
 *   @route='some.route'
 *   @useLinkToExternal={{true}}
 * />
 * ```
 *
 * @class NetworkCard
 *
 */

export default class NetworkCardComponent extends Component<NetworkCardSignature> {
  /**
   *  The name of the HashiCorp product for the cluster.
   * @argument clusterProductName
   * @type {string}
   */
  /**
   * The full model representation of a HashiCorp Virtual Network
   * @argument network
   * @type {object}
   */
  /**
   *  The name of the route to use to construct the LinkTo or LinkToExternal.
   * @argument route
   * @type {string}
   */
  /**
   *  The name of the peering list route to use to construct the LinkTo or LinkToExternal.
   * @argument peeringRoute
   * @type {string}
   *
   */
  /**
   *  If the component should show a warning about not having peerings.
   * @argument showPeeringWarning
   * @type {boolean}
   *
   */
  /**
   *  If true, the component will render a link using ember-engines' LinkToExternal compoent instead of the default LinkTo.
   * @argument useLinkToExternal
   * @type {boolean}
   */
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    NetworkCard: typeof NetworkCardComponent;
    'network-card': typeof NetworkCardComponent;
  }
}
