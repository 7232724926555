import Component from '@glimmer/component';

interface SplitListLeftHeaderSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

/**
 *
 * `SplitListLeftHeader` is a slot for the consumer to provide optional header content at the top of the
 * SplitList left column (above list items and pagination controls).
 *
 * This slot is optional, the consumer does not have to render them if left header content
 * is not applicable to their use case.
 *
 * ```
 * <SplitList
 *   @identifyListItem={{this.identifyListItem}}
 *   @initialSelectedItem={{this.currentData.[1]}}
 *   @listData={{this.currentData}}
 *   @onSelectItem={{this.onSelectItem}} as |SL|
 * >
 *   <SL.LeftHeaderContent>
 *      Left column title
 *   </SL.LeftHeaderContent>
 * </SplitList>
 * ```
 *
 * @class SplitListLeftHeader
 *
 */

export default class SplitListLeftHeaderComponent extends Component<SplitListLeftHeaderSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SplitList::LeftHeader': typeof SplitListLeftHeaderComponent;
    'split-list/left-header': typeof SplitListLeftHeaderComponent;
  }
}
