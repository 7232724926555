/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudConsul20210204ClusterToJSON, HashicorpCloudConsul20210204CreateCustomerMasterACLTokenRequestToJSON, HashicorpCloudConsul20210204CreateCustomerMasterACLTokenResponseFromJSON, HashicorpCloudConsul20210204CreateRequestToJSON, HashicorpCloudConsul20210204CreateResponseFromJSON, HashicorpCloudConsul20210204CreateSnapshotRequestToJSON, HashicorpCloudConsul20210204CreateSnapshotResponseFromJSON, HashicorpCloudConsul20210204DeleteResponseFromJSON, HashicorpCloudConsul20210204DeleteSnapshotResponseFromJSON, HashicorpCloudConsul20210204GetClientConfigResponseFromJSON, HashicorpCloudConsul20210204GetClusterTelemetryResponseFromJSON, HashicorpCloudConsul20210204GetResponseFromJSON, HashicorpCloudConsul20210204GetSnapshotResponseFromJSON, HashicorpCloudConsul20210204ListResponseFromJSON, HashicorpCloudConsul20210204ListSnapshotsResponseFromJSON, HashicorpCloudConsul20210204ListUpgradeVersionsResponseFromJSON, HashicorpCloudConsul20210204ListVersionsResponseFromJSON, HashicorpCloudConsul20210204RestoreSnapshotRequestToJSON, HashicorpCloudConsul20210204RestoreSnapshotResponseFromJSON, HashicorpCloudConsul20210204SnapshotToJSON, HashicorpCloudConsul20210204UpdateResponseFromJSON, HashicorpCloudConsul20210204UpdateSnapshotResponseFromJSON, } from '../models';
/**
 *
 */
export class ConsulServiceApi extends runtime.BaseAPI {
    /**
     * Delete deletes the specified HCP Consul cluster.
     */
    async _deleteRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling _delete.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling _delete.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling _delete.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204DeleteResponseFromJSON(jsonValue));
    }
    /**
     * Delete deletes the specified HCP Consul cluster.
     */
    async _delete(locationOrganizationId, locationProjectId, id, locationRegionProvider, locationRegionRegion) {
        const response = await this._deleteRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, id: id, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * Create creates a new HCP Consul cluster.
     */
    async createRaw(requestParameters) {
        if (requestParameters.clusterLocationOrganizationId === null || requestParameters.clusterLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('clusterLocationOrganizationId', 'Required parameter requestParameters.clusterLocationOrganizationId was null or undefined when calling create.');
        }
        if (requestParameters.clusterLocationProjectId === null || requestParameters.clusterLocationProjectId === undefined) {
            throw new runtime.RequiredError('clusterLocationProjectId', 'Required parameter requestParameters.clusterLocationProjectId was null or undefined when calling create.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling create.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{cluster.location.organization_id}/projects/{cluster.location.project_id}/clusters`.replace(`{${"cluster.location.organization_id"}}`, encodeURIComponent(String(requestParameters.clusterLocationOrganizationId))).replace(`{${"cluster.location.project_id"}}`, encodeURIComponent(String(requestParameters.clusterLocationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudConsul20210204CreateRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204CreateResponseFromJSON(jsonValue));
    }
    /**
     * Create creates a new HCP Consul cluster.
     */
    async create(clusterLocationOrganizationId, clusterLocationProjectId, body) {
        const response = await this.createRaw({ clusterLocationOrganizationId: clusterLocationOrganizationId, clusterLocationProjectId: clusterLocationProjectId, body: body });
        return await response.value();
    }
    /**
     * CreateCustomerMasterACLToken creates a new master ACL token for the customer on the specified cluster. External clients will invoke this endpoint to re-generate new master tokens.
     */
    async createCustomerMasterACLTokenRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling createCustomerMasterACLToken.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createCustomerMasterACLToken.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling createCustomerMasterACLToken.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createCustomerMasterACLToken.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{id}/master-acl-tokens`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudConsul20210204CreateCustomerMasterACLTokenRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204CreateCustomerMasterACLTokenResponseFromJSON(jsonValue));
    }
    /**
     * CreateCustomerMasterACLToken creates a new master ACL token for the customer on the specified cluster. External clients will invoke this endpoint to re-generate new master tokens.
     */
    async createCustomerMasterACLToken(locationOrganizationId, locationProjectId, id, body) {
        const response = await this.createCustomerMasterACLTokenRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, id: id, body: body });
        return await response.value();
    }
    /**
     * CreateSnapshot takes a new snapshot asynchronously.
     */
    async createSnapshotRaw(requestParameters) {
        if (requestParameters.resourceLocationOrganizationId === null || requestParameters.resourceLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('resourceLocationOrganizationId', 'Required parameter requestParameters.resourceLocationOrganizationId was null or undefined when calling createSnapshot.');
        }
        if (requestParameters.resourceLocationProjectId === null || requestParameters.resourceLocationProjectId === undefined) {
            throw new runtime.RequiredError('resourceLocationProjectId', 'Required parameter requestParameters.resourceLocationProjectId was null or undefined when calling createSnapshot.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createSnapshot.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{resource.location.organization_id}/projects/{resource.location.project_id}/snapshots`.replace(`{${"resource.location.organization_id"}}`, encodeURIComponent(String(requestParameters.resourceLocationOrganizationId))).replace(`{${"resource.location.project_id"}}`, encodeURIComponent(String(requestParameters.resourceLocationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudConsul20210204CreateSnapshotRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204CreateSnapshotResponseFromJSON(jsonValue));
    }
    /**
     * CreateSnapshot takes a new snapshot asynchronously.
     */
    async createSnapshot(resourceLocationOrganizationId, resourceLocationProjectId, body) {
        const response = await this.createSnapshotRaw({ resourceLocationOrganizationId: resourceLocationOrganizationId, resourceLocationProjectId: resourceLocationProjectId, body: body });
        return await response.value();
    }
    /**
     * DeleteSnapshot removes the snapshot from storage asynchronously.
     */
    async deleteSnapshotRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling deleteSnapshot.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling deleteSnapshot.');
        }
        if (requestParameters.snapshotId === null || requestParameters.snapshotId === undefined) {
            throw new runtime.RequiredError('snapshotId', 'Required parameter requestParameters.snapshotId was null or undefined when calling deleteSnapshot.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{location.organization_id}/projects/{location.project_id}/snapshots/{snapshot_id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"snapshot_id"}}`, encodeURIComponent(String(requestParameters.snapshotId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204DeleteSnapshotResponseFromJSON(jsonValue));
    }
    /**
     * DeleteSnapshot removes the snapshot from storage asynchronously.
     */
    async deleteSnapshot(locationOrganizationId, locationProjectId, snapshotId, locationRegionProvider, locationRegionRegion) {
        const response = await this.deleteSnapshotRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, snapshotId: snapshotId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * Get reads a single HCP Consul cluster.
     */
    async getRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling get.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling get.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling get.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204GetResponseFromJSON(jsonValue));
    }
    /**
     * Get reads a single HCP Consul cluster.
     */
    async get(locationOrganizationId, locationProjectId, id, locationRegionProvider, locationRegionRegion) {
        const response = await this.getRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, id: id, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * GetClientConfigFiles returns the config files for a Consul agent running in client mode. The files are base64-encoded.
     */
    async getClientConfigRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getClientConfig.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getClientConfig.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getClientConfig.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.bundle !== undefined) {
            queryParameters['bundle'] = requestParameters.bundle;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{id}/client-config`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204GetClientConfigResponseFromJSON(jsonValue));
    }
    /**
     * GetClientConfigFiles returns the config files for a Consul agent running in client mode. The files are base64-encoded.
     */
    async getClientConfig(locationOrganizationId, locationProjectId, id, locationRegionProvider, locationRegionRegion, bundle) {
        const response = await this.getClientConfigRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, id: id, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, bundle: bundle });
        return await response.value();
    }
    /**
     * GetClusterTelemetry retrieves a snapshot of a subset of consul cluster telemetry
     */
    async getClusterTelemetryRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getClusterTelemetry.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getClusterTelemetry.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling getClusterTelemetry.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/telemetry`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204GetClusterTelemetryResponseFromJSON(jsonValue));
    }
    /**
     * GetClusterTelemetry retrieves a snapshot of a subset of consul cluster telemetry
     */
    async getClusterTelemetry(locationOrganizationId, locationProjectId, clusterId, locationRegionProvider, locationRegionRegion) {
        const response = await this.getClusterTelemetryRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * GetSnapshot retrieves an existing snapshot.
     */
    async getSnapshotRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getSnapshot.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getSnapshot.');
        }
        if (requestParameters.snapshotId === null || requestParameters.snapshotId === undefined) {
            throw new runtime.RequiredError('snapshotId', 'Required parameter requestParameters.snapshotId was null or undefined when calling getSnapshot.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{location.organization_id}/projects/{location.project_id}/snapshots/{snapshot_id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"snapshot_id"}}`, encodeURIComponent(String(requestParameters.snapshotId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204GetSnapshotResponseFromJSON(jsonValue));
    }
    /**
     * GetSnapshot retrieves an existing snapshot.
     */
    async getSnapshot(locationOrganizationId, locationProjectId, snapshotId, locationRegionProvider, locationRegionRegion) {
        const response = await this.getSnapshotRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, snapshotId: snapshotId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * List lists all HCP Consul clusters.
     */
    async listRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling list.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling list.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{location.organization_id}/projects/{location.project_id}/clusters`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204ListResponseFromJSON(jsonValue));
    }
    /**
     * List lists all HCP Consul clusters.
     */
    async list(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.listRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListSnapshots lists snapshots.
     */
    async listSnapshotsRaw(requestParameters) {
        if (requestParameters.resourceLocationOrganizationId === null || requestParameters.resourceLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('resourceLocationOrganizationId', 'Required parameter requestParameters.resourceLocationOrganizationId was null or undefined when calling listSnapshots.');
        }
        if (requestParameters.resourceLocationProjectId === null || requestParameters.resourceLocationProjectId === undefined) {
            throw new runtime.RequiredError('resourceLocationProjectId', 'Required parameter requestParameters.resourceLocationProjectId was null or undefined when calling listSnapshots.');
        }
        const queryParameters = {};
        if (requestParameters.resourceType !== undefined) {
            queryParameters['resource.type'] = requestParameters.resourceType;
        }
        if (requestParameters.resourceId !== undefined) {
            queryParameters['resource.id'] = requestParameters.resourceId;
        }
        if (requestParameters.resourceUuid !== undefined) {
            queryParameters['resource.uuid'] = requestParameters.resourceUuid;
        }
        if (requestParameters.resourceLocationRegionProvider !== undefined) {
            queryParameters['resource.location.region.provider'] = requestParameters.resourceLocationRegionProvider;
        }
        if (requestParameters.resourceLocationRegionRegion !== undefined) {
            queryParameters['resource.location.region.region'] = requestParameters.resourceLocationRegionRegion;
        }
        if (requestParameters.resourceDescription !== undefined) {
            queryParameters['resource.description'] = requestParameters.resourceDescription;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{resource.location.organization_id}/projects/{resource.location.project_id}/snapshots`.replace(`{${"resource.location.organization_id"}}`, encodeURIComponent(String(requestParameters.resourceLocationOrganizationId))).replace(`{${"resource.location.project_id"}}`, encodeURIComponent(String(requestParameters.resourceLocationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204ListSnapshotsResponseFromJSON(jsonValue));
    }
    /**
     * ListSnapshots lists snapshots.
     */
    async listSnapshots(resourceLocationOrganizationId, resourceLocationProjectId, resourceType, resourceId, resourceUuid, resourceLocationRegionProvider, resourceLocationRegionRegion, resourceDescription, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.listSnapshotsRaw({ resourceLocationOrganizationId: resourceLocationOrganizationId, resourceLocationProjectId: resourceLocationProjectId, resourceType: resourceType, resourceId: resourceId, resourceUuid: resourceUuid, resourceLocationRegionProvider: resourceLocationRegionProvider, resourceLocationRegionRegion: resourceLocationRegionRegion, resourceDescription: resourceDescription, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListUpgradeVersions returns the versions available for this Consul cluster to upgrade to.
     */
    async listUpgradeVersionsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling listUpgradeVersions.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling listUpgradeVersions.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling listUpgradeVersions.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{id}/upgrade-versions`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204ListUpgradeVersionsResponseFromJSON(jsonValue));
    }
    /**
     * ListUpgradeVersions returns the versions available for this Consul cluster to upgrade to.
     */
    async listUpgradeVersions(locationOrganizationId, locationProjectId, id, locationRegionProvider, locationRegionRegion) {
        const response = await this.listUpgradeVersionsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, id: id, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * ListVersions returns the versions available for this org and project.
     */
    async listVersionsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling listVersions.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling listVersions.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.platformType !== undefined) {
            queryParameters['platform_type'] = requestParameters.platformType;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{location.organization_id}/projects/{location.project_id}/versions`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204ListVersionsResponseFromJSON(jsonValue));
    }
    /**
     * ListVersions returns the versions available for this org and project.
     */
    async listVersions(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion, platformType) {
        const response = await this.listVersionsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, platformType: platformType });
        return await response.value();
    }
    /**
     * ListVersions returns the versions available for this org and project.
     */
    async listVersions2Raw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.locationOrganizationId !== undefined) {
            queryParameters['location.organization_id'] = requestParameters.locationOrganizationId;
        }
        if (requestParameters.locationProjectId !== undefined) {
            queryParameters['location.project_id'] = requestParameters.locationProjectId;
        }
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.platformType !== undefined) {
            queryParameters['platform_type'] = requestParameters.platformType;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/versions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204ListVersionsResponseFromJSON(jsonValue));
    }
    /**
     * ListVersions returns the versions available for this org and project.
     */
    async listVersions2(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion, platformType) {
        const response = await this.listVersions2Raw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, platformType: platformType });
        return await response.value();
    }
    /**
     * RestoreSnapshot restores a snapshot on a given cluster asynchronously.
     */
    async restoreSnapshotRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling restoreSnapshot.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling restoreSnapshot.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling restoreSnapshot.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling restoreSnapshot.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/restore`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudConsul20210204RestoreSnapshotRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204RestoreSnapshotResponseFromJSON(jsonValue));
    }
    /**
     * RestoreSnapshot restores a snapshot on a given cluster asynchronously.
     */
    async restoreSnapshot(locationOrganizationId, locationProjectId, clusterId, body) {
        const response = await this.restoreSnapshotRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, clusterId: clusterId, body: body });
        return await response.value();
    }
    /**
     * Update updates the properties of an existing HCP Consul cluster.
     */
    async updateRaw(requestParameters) {
        if (requestParameters.clusterLocationOrganizationId === null || requestParameters.clusterLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('clusterLocationOrganizationId', 'Required parameter requestParameters.clusterLocationOrganizationId was null or undefined when calling update.');
        }
        if (requestParameters.clusterLocationProjectId === null || requestParameters.clusterLocationProjectId === undefined) {
            throw new runtime.RequiredError('clusterLocationProjectId', 'Required parameter requestParameters.clusterLocationProjectId was null or undefined when calling update.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling update.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling update.');
        }
        const queryParameters = {};
        if (requestParameters.updateMaskPaths) {
            queryParameters['update_mask.paths'] = requestParameters.updateMaskPaths;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{cluster.location.organization_id}/projects/{cluster.location.project_id}/clusters/{cluster.id}`.replace(`{${"cluster.location.organization_id"}}`, encodeURIComponent(String(requestParameters.clusterLocationOrganizationId))).replace(`{${"cluster.location.project_id"}}`, encodeURIComponent(String(requestParameters.clusterLocationProjectId))).replace(`{${"cluster.id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudConsul20210204ClusterToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204UpdateResponseFromJSON(jsonValue));
    }
    /**
     * Update updates the properties of an existing HCP Consul cluster.
     */
    async update(clusterLocationOrganizationId, clusterLocationProjectId, clusterId, body, updateMaskPaths) {
        const response = await this.updateRaw({ clusterLocationOrganizationId: clusterLocationOrganizationId, clusterLocationProjectId: clusterLocationProjectId, clusterId: clusterId, body: body, updateMaskPaths: updateMaskPaths });
        return await response.value();
    }
    /**
     * Right now only the name can be changed.
     * UpdateSnapshot updates an existing snapshot.
     */
    async updateSnapshotRaw(requestParameters) {
        if (requestParameters.snapshotLocationOrganizationId === null || requestParameters.snapshotLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('snapshotLocationOrganizationId', 'Required parameter requestParameters.snapshotLocationOrganizationId was null or undefined when calling updateSnapshot.');
        }
        if (requestParameters.snapshotLocationProjectId === null || requestParameters.snapshotLocationProjectId === undefined) {
            throw new runtime.RequiredError('snapshotLocationProjectId', 'Required parameter requestParameters.snapshotLocationProjectId was null or undefined when calling updateSnapshot.');
        }
        if (requestParameters.snapshotId === null || requestParameters.snapshotId === undefined) {
            throw new runtime.RequiredError('snapshotId', 'Required parameter requestParameters.snapshotId was null or undefined when calling updateSnapshot.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling updateSnapshot.');
        }
        const queryParameters = {};
        if (requestParameters.maskPaths) {
            queryParameters['mask.paths'] = requestParameters.maskPaths;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/consul/2021-02-04/organizations/{snapshot.location.organization_id}/projects/{snapshot.location.project_id}/snapshots/{snapshot.id}`.replace(`{${"snapshot.location.organization_id"}}`, encodeURIComponent(String(requestParameters.snapshotLocationOrganizationId))).replace(`{${"snapshot.location.project_id"}}`, encodeURIComponent(String(requestParameters.snapshotLocationProjectId))).replace(`{${"snapshot.id"}}`, encodeURIComponent(String(requestParameters.snapshotId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudConsul20210204SnapshotToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsul20210204UpdateSnapshotResponseFromJSON(jsonValue));
    }
    /**
     * Right now only the name can be changed.
     * UpdateSnapshot updates an existing snapshot.
     */
    async updateSnapshot(snapshotLocationOrganizationId, snapshotLocationProjectId, snapshotId, body, maskPaths) {
        const response = await this.updateSnapshotRaw({ snapshotLocationOrganizationId: snapshotLocationOrganizationId, snapshotLocationProjectId: snapshotLocationProjectId, snapshotId: snapshotId, body: body, maskPaths: maskPaths });
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var ListVersionsPlatformTypeEnum;
(function (ListVersionsPlatformTypeEnum) {
    ListVersionsPlatformTypeEnum["NOTSET"] = "NOTSET";
    ListVersionsPlatformTypeEnum["HCP"] = "HCP";
    ListVersionsPlatformTypeEnum["HCS"] = "HCS";
})(ListVersionsPlatformTypeEnum || (ListVersionsPlatformTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ListVersions2PlatformTypeEnum;
(function (ListVersions2PlatformTypeEnum) {
    ListVersions2PlatformTypeEnum["NOTSET"] = "NOTSET";
    ListVersions2PlatformTypeEnum["HCP"] = "HCP";
    ListVersions2PlatformTypeEnum["HCS"] = "HCS";
})(ListVersions2PlatformTypeEnum || (ListVersions2PlatformTypeEnum = {}));
