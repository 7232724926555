import BaseAbility from './base-ability';
import {
  WAYPOINT_TFCCONFIG,
  WAYPOINT_TFCCONFIG_CREATE,
  WAYPOINT_TFCCONFIG_UPDATE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = WAYPOINT_TFCCONFIG;

  get canManage() {
    return (
      this.permissions.has(WAYPOINT_TFCCONFIG_CREATE) &&
      this.permissions.has(WAYPOINT_TFCCONFIG_UPDATE)
    );
  }
}
