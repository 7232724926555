import Component from '@glimmer/component';

interface InfoLabelSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

export default class InfoLabelComponent extends Component<InfoLabelSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    InfoLabel: typeof InfoLabelComponent;
    'info-label': typeof InfoLabelComponent;
  }
}
