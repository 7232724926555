/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudIamCountMembersForGroupsResponseMembersCountFromJSON(json) {
    return HashicorpCloudIamCountMembersForGroupsResponseMembersCountFromJSONTyped(json, false);
}
export function HashicorpCloudIamCountMembersForGroupsResponseMembersCountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'resourceName': !exists(json, 'resource_name') ? undefined : json['resource_name'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}
export function HashicorpCloudIamCountMembersForGroupsResponseMembersCountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'resource_name': value.resourceName,
        'count': value.count,
    };
}
