import BaseAbility from './base-ability';
import { inject as service } from '@ember/service';
import { assert } from '@ember/debug';

import { TYPE_NETWORK } from 'common/utils/cloud-resource-types';
import {
  PREFIX_NETWORK_HVNS,
  ACTION_CREATE,
  ACTION_DELETE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  @service abilities;
  @service quota;

  prefix = PREFIX_NETWORK_HVNS;

  /**
   * Determine if create is available based on permissions, an array of networks,
   * and billing status.
   *
   * Requires a list of HVNs to be passed as the model.
   *
   * @return {boolean}
   */
  get canCreate() {
    assert(
      'canCreate network must contain a list of networks',
      Array.isArray(this.model),
    );
    return (
      this.abilities.can('bill billing-account') &&
      this.permissions.has(this.generate(ACTION_CREATE)) &&
      this.model.length < this.quota.for(TYPE_NETWORK)
    );
  }

  /**
   * Determine if user has sufficient permissions to create an HVN
   *
   * Does not require a model.
   *
   * @return {boolean}
   */
  get canCreatePermissions() {
    return this.permissions.has(this.generate(ACTION_CREATE));
  }

  /**
   * Determine if network limit is reached.
   *
   * Requires a list of HVNs to be passed as the model.
   *
   * @return {boolean}
   */
  get canCreateQuotaReached() {
    assert(
      'canCreateQuotaReached network must contain a list of networks',
      Array.isArray(this.model),
    );
    return this.model && this.model.length >= this.quota.for(TYPE_NETWORK);
  }

  /**
   * The ability checks that the network has no dependencies.
   *
   * @param {Array} model - The HVN model to be deleted.
   * @returns {Boolean}
   */
  get canDelete() {
    assert('canDelete network must contain a network', this.model);
    const { dependencies = [] } = this.model;
    return (
      this.permissions.has(this.generate(ACTION_DELETE)) && !dependencies.length
    );
  }

  /**
   * The ability checks that they have enough permissions to open the modal.
   *
   * @returns {Boolean}
   */
  get canOpenDeleteModal() {
    return this.permissions.has(this.generate(ACTION_DELETE));
  }
}
