/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudConsulTelemetry20230414IntentionsEntryFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414IntentionsEntryFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414IntentionsEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'clusterLocation': !exists(json, 'cluster_location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['cluster_location']),
        'clusterName': !exists(json, 'cluster_name') ? undefined : json['cluster_name'],
        'intentionId': !exists(json, 'intention_id') ? undefined : json['intention_id'],
        'intentionPrecedence': !exists(json, 'intention_precedence') ? undefined : json['intention_precedence'],
    };
}
export function HashicorpCloudConsulTelemetry20230414IntentionsEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cluster_location': HashicorpCloudLocationLocationToJSON(value.clusterLocation),
        'cluster_name': value.clusterName,
        'intention_id': value.intentionId,
        'intention_precedence': value.intentionPrecedence,
    };
}
