import Component from '@glimmer/component';
import type { ComponentLike } from '@glint/template';

import type { CoreCardSummaryKeySignature } from './key.ts';
import type { CoreCardSummaryValueSignature } from './value.ts';

interface CoreCardSummaryPairSignature {
  Args: {
    keyIcon: unknown;
  };
  Blocks: {
    default: [
      {
        Key: ComponentLike<{
          Args: CoreCardSummaryKeySignature['Args'];
          Blocks: CoreCardSummaryKeySignature['Blocks'];
          Element: CoreCardSummaryKeySignature['Element'];
        }>;
        Value: ComponentLike<{
          Args: CoreCardSummaryValueSignature['Args'];
          Blocks: CoreCardSummaryValueSignature['Blocks'];
          Element: CoreCardSummaryValueSignature['Element'];
        }>;
      },
    ];
  };
}

/**
 *
 * `CoreCardSummaryValueComponent` provides a container to display both the key/value pair.
 *
 *
 * ```
 * <Core::Card::Summary::Pair @keyIcon="help" as |P|>
 *   <P.Key><P.Key>
 *   <P.Value><P.Value>
 * </Core::Card::Summary::Pair>
 * ```
 *
 * @class CoreCardSummaryValueComponent
 *
 */

export default class CoreCardSummaryPairComponent extends Component<CoreCardSummaryPairSignature> {
  /**
   * Get the class names to apply to the component.
   * @method CoreCardSummaryValueComponent#classNames
   * @return {string} The "class" attribute to apply to the component.
   */
  get classNames() {
    const classes = [];

    if (this.args.keyIcon) {
      classes.push(`core-card-summary--with-key-icon`);
    }

    return classes.join(' ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::Card::Summary::Pair': typeof CoreCardSummaryPairComponent;
    'core/card/summary/pair': typeof CoreCardSummaryPairComponent;
  }
}
