export const CONNECTION_METHODS = {
  awsQuickLink: {
    key: 'AWS_QUICK_LINK',
    icon: 'aws-color',
  },
  awsCloudShell: {
    key: 'AWS_CLOUD_SHELL',
    icon: 'aws-color',
  },
  webConsole: {
    key: 'AWS_WEB_CONSOLE',
    icon: 'hashicorp',
  },
};

export const ALL_CONNECTION_METHODS = Object.values(CONNECTION_METHODS);
