/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Mode represents the role type for the Performance Replicated cluster.
 *
 *  - CLUSTER_PERFORMANCE_REPLICATION_INFO_MODE_INVALID: CLUSTER_PERFORMANCE_REPLICATION_INFO_MODE_INVALID is a sentinel zero value so that an uninitialized value can be
 * detected.
 *  - PRIMARY: PRIMARY mode type.
 *  - SECONDARY: SECONDARY mode type.
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125ClusterPerformanceReplicationInfoMode;
(function (HashicorpCloudVault20201125ClusterPerformanceReplicationInfoMode) {
    HashicorpCloudVault20201125ClusterPerformanceReplicationInfoMode["CLUSTERPERFORMANCEREPLICATIONINFOMODEINVALID"] = "CLUSTER_PERFORMANCE_REPLICATION_INFO_MODE_INVALID";
    HashicorpCloudVault20201125ClusterPerformanceReplicationInfoMode["PRIMARY"] = "PRIMARY";
    HashicorpCloudVault20201125ClusterPerformanceReplicationInfoMode["SECONDARY"] = "SECONDARY";
})(HashicorpCloudVault20201125ClusterPerformanceReplicationInfoMode || (HashicorpCloudVault20201125ClusterPerformanceReplicationInfoMode = {}));
export function HashicorpCloudVault20201125ClusterPerformanceReplicationInfoModeFromJSON(json) {
    return HashicorpCloudVault20201125ClusterPerformanceReplicationInfoModeFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125ClusterPerformanceReplicationInfoModeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125ClusterPerformanceReplicationInfoModeToJSON(value) {
    return value;
}
