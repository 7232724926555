import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';

import { DropdownListItem } from '../../../utils/filter-bar/dropdown-manager.ts';
import {
  DateRange,
  MAX_ISO_DATE,
  MIN_ISO_DATE,
} from '../../../utils/filter-bar/date-range.ts';

import type { HdsDropdownElement } from '../../../utils/filter-bar/index.ts';

interface Args {
  dd: HdsDropdownElement;
  listItems: DropdownListItem[];
  onSubmit: (
    value: string[],
    dropdown: HTMLElement,
    updateCustomOptions: object,
  ) => void;
  dateRange: DateRange;
  trackInteraction: () => void;
}

/**
 * The DateRangeDropdown component is a dropdown body for display within an Hds
 * Dropdown component specifically meant for handling date/time range selections. The component can
 * be used on its own or within a FilterBar component instance.
 *
 * Usage:
 *  <FilterBar::DateRangeDropdown
 *     @name={{this.dropdownName}}
 *     @dd={{dd}}
 *     @listItems={{this.listItems}}
 *     @onSubmit={{this.onSubmit}}
 *     @dateRange={{this.dateRange}}
 *     @trackInteraction={{this.trackInteraction}}
 *   />
 *
 * The DateRangeComponent requires as an argument an instance of a DateRange
 * util class, which manages state and configuration, formats dates and times,
 * and handles interactions with the various sub-components of the
 * DateRangeDropdown component.
 *
 * On submit, the DateRangeDropdown passes the selected date range back to the
 * handler as a string containing unix timestamps separated by a colon (:)
 *
 * An optional tracking callback can be provided to instrument the dropdown.
 *
 * @class DateRangeDropdown
 */

export default class DateRangeDropdownComponent extends Component<Args> {
  @tracked showTimePickers = false;
  /**
   * This generates the hour values and labels for the hour select box
   * in the custom date picker
   */
  hourSelectorList = new Array(24).fill('').map((_value, index) => {
    const hour = String(index).padStart(2, '0');
    return {
      label: `vault-common.components.filter-bar.date-range-dropdown.hours.${hour}`,
      value: `${hour}:00`,
    };
  });

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args.dateRange.startTime || this.args.dateRange.endTime) {
      this.showTimePickers = true;
    }
  }

  get timePickerToggleText() {
    return this.showTimePickers ? 'Date only' : 'Enter time';
  }

  get showDateRangePicker() {
    return (
      this.args.dateRange.pendingChanges.isCustom ||
      this.args.dateRange.isCustom
    );
  }

  get endDateValue() {
    return this.args.dateRange.lookbackOnly
      ? DateTime.now().toFormat('yyyy-MM-dd')
      : this.args.dateRange['endDate'];
  }

  get endTimeValue() {
    return this.args.dateRange.lookbackOnly
      ? DateTime.now().plus({ hour: 1 }).startOf('hour').toFormat('hh:mm')
      : this.args.dateRange['endTime'];
  }

  get anyOptionSelected() {
    return !this.args.dateRange.pendingChanges.dateRangeKey;
  }

  get earliestSelectableDate() {
    return Number.isInteger(this.args.dateRange.maxLookbackInDays)
      ? DateTime.now()
          .minus({ days: this.args.dateRange.maxLookbackInDays })
          .toISODate()
      : MIN_ISO_DATE;
  }

  get latestSelectableDate() {
    return Number.isInteger(this.args.dateRange.maxLookaheadInDays)
      ? DateTime.now()
          .plus({ days: this.args.dateRange.maxLookaheadInDays })
          .toISODate()
      : MAX_ISO_DATE;
  }

  get isDateRangeInvalid() {
    const { lookbackOnly, hasPendingChanges, pendingChanges } =
      this.args.dateRange;
    if (lookbackOnly) {
      return false;
    }
    const { startDate, endDate, startTime, endTime } = pendingChanges;
    if (hasPendingChanges && startDate && endDate) {
      return `${endDate}T${endTime}` < `${startDate}T${startTime}`;
    }
    return false;
  }

  get submitDisabled() {
    const {
      startDate,
      endDate,
      lookbackOnly,
      hasPendingChanges,
      pendingChanges: {
        startDate: newStartDate,
        endDate: newEndDate,
        isCustom,
      },
    } = this.args.dateRange;

    if (this.isDateRangeInvalid) {
      return true;
    }

    if (hasPendingChanges) {
      if (isCustom) {
        return !(
          (startDate || newStartDate) &&
          (endDate || newEndDate || lookbackOnly)
        );
      }

      return false;
    }
    return true;
  }

  @action
  resetPendingChanges() {
    this.args.dateRange.resetPendingChanges();
  }

  @action
  selectDateRangeOption(evt: Event) {
    const { value } = evt.target as HTMLInputElement;
    const dateRangeKey = value === 'default' ? '' : value;
    const isCustom = dateRangeKey === 'custom';
    this.args.dateRange.stagePendingChange('isCustom', isCustom);
    this.args.dateRange.stagePendingChange('dateRangeKey', dateRangeKey);
  }

  @action
  toggleTimePickers() {
    this.showTimePickers = !this.showTimePickers;
  }

  @action
  submitDateRange() {
    const dateRangeKey = this.args.dateRange.pendingChanges.dateRangeKey || '';
    this.args.onSubmit([dateRangeKey], this.args.dd, this.args.dateRange);
    this.args.trackInteraction?.();
  }

  @action
  setCustomDateOrTime(type: string, evt: Event) {
    const { value } = evt.target as HTMLInputElement;
    this.args.dateRange.setCustomDateOrTime(type, value);
  }

  @action
  cancel(dropdown: { close: () => void }) {
    dropdown.close();
  }

  @action
  onKeyup(e: KeyboardEvent) {
    if (e.key === 'Enter' && !this.submitDisabled) {
      this.submitDateRange();
    }
  }

  @action
  onDatePickerFocusOut(e: MouseEvent) {
    /**
     * There is a bug in Firefox that closes
     * the dropdown everytime the browser datepicker
     * opens. It is triggering some focusout logic in
     * the disclosure component that is not compatible
     * with the datepicker. The design system team is
     * aware and we should be able to remove this after
     * this ticket is finished:
     * https://hashicorp.atlassian.net/browse/HDS-1540
     *
     * Insights ticket to remove when the above is resolved
     * https://hashicorp.atlassian.net/browse/VAULT-13549
     *
     * This traps the focusout event when it originates
     * from the date fields to work around the above bug.
     */
    e.stopPropagation();
  }
}
