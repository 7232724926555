/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105StatementSummaryFromJSON, Billing20201105StatementSummaryToJSON, } from './';
export function Billing20201105ListStatementsResponseFromJSON(json) {
    return Billing20201105ListStatementsResponseFromJSONTyped(json, false);
}
export function Billing20201105ListStatementsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'statementSummaries': !exists(json, 'statement_summaries') ? undefined : (json['statement_summaries'].map(Billing20201105StatementSummaryFromJSON)),
    };
}
export function Billing20201105ListStatementsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'statement_summaries': value.statementSummaries === undefined ? undefined : (value.statementSummaries.map(Billing20201105StatementSummaryToJSON)),
    };
}
