/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105StripeInvoiceFromJSON, Billing20201105StripeInvoiceToJSON, } from './';
export function Billing20201105GetStripeInvoiceResponseFromJSON(json) {
    return Billing20201105GetStripeInvoiceResponseFromJSONTyped(json, false);
}
export function Billing20201105GetStripeInvoiceResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stripeInvoice': !exists(json, 'stripe_invoice') ? undefined : Billing20201105StripeInvoiceFromJSON(json['stripe_invoice']),
    };
}
export function Billing20201105GetStripeInvoiceResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stripe_invoice': Billing20201105StripeInvoiceToJSON(value.stripeInvoice),
    };
}
