/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenAWS4RequestFromJSON(json) {
    return HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenAWS4RequestFromJSONTyped(json, false);
}
export function HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenAWS4RequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'url': !exists(json, 'url') ? undefined : json['url'],
        'method': !exists(json, 'method') ? undefined : json['method'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'headers': !exists(json, 'headers') ? undefined : json['headers'],
    };
}
export function HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenAWS4RequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'url': value.url,
        'method': value.method,
        'body': value.body,
        'headers': value.headers,
    };
}
