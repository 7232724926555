/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudResourcemanagerPolicyFromJSON, HashicorpCloudResourcemanagerPolicyToJSON, } from './';
export function HashicorpCloudResourcemanagerResourceGetIamPolicyResponseFromJSON(json) {
    return HashicorpCloudResourcemanagerResourceGetIamPolicyResponseFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerResourceGetIamPolicyResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'policy': !exists(json, 'policy') ? undefined : HashicorpCloudResourcemanagerPolicyFromJSON(json['policy']),
    };
}
export function HashicorpCloudResourcemanagerResourceGetIamPolicyResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'policy': HashicorpCloudResourcemanagerPolicyToJSON(value.policy),
    };
}
