import { action } from '@ember/object';
import { tracked, TrackedSet } from 'tracked-built-ins';
import { assert } from '@ember/debug';

import { DropdownListItemType } from '../../utils/filter-bar/dropdown-manager-types.ts';
import { DateRange } from '../../utils/filter-bar/date-range.ts';

import type {
  Noop,
  DropdownType,
  DropdownConfigOptions,
  DropdownSelection,
  DropdownManagerOptions,
} from '../../utils/filter-bar/dropdown-manager-types.ts';
import type { FiltersTracking } from '../../utils/vault-insights/dropdown-tracking.ts';
import type { HdsDropdownElement } from '../../utils/filter-bar/index.ts';

const noop: Noop = () => {
  return;
};

export function getTrackingParams(
  dropdownSelections: DropdownSelection[],
  actualValues = [] as string[],
): FiltersTracking | null {
  const filters: FiltersTracking = {};
  dropdownSelections.forEach((ds) => {
    const key = Object.keys(ds)[0] ?? '';
    const value = ds[key] ?? [''];
    if (actualValues.includes(key)) {
      filters[key] = value;
    } else {
      filters[key] = value.length;
    }
  });

  return filters;
}

/**
 * Represents an individual item within a dropdown
 */
export class DropdownListItem {
  @tracked isSelected = false;
  @tracked optionValue: string;
  optionLabel: string;
  optionType: DropdownListItemType;
  helperText?: string | undefined; // RAWTEXT_SEARCH only
  groupId?: string | undefined; // For future MULTISELECT use

  constructor(
    optionType: DropdownListItemType,
    optionLabel: string,
    optionValue: string,
    helperText?: string,
    groupId?: string,
  ) {
    this.optionType = optionType;
    this.optionLabel = optionLabel;
    this.optionValue = optionValue;
    this.helperText = helperText;
    this.groupId = groupId;
  }

  select() {
    this.isSelected = true;
  }

  unselect() {
    if (this.optionType === 'STRING_SEARCH') {
      this.optionValue = '';
    }
    this.isSelected = false;
  }

  /**
   * For Text Search filter dropdowns, the optionValue is set by the end user rather than the developer,
   * so we require a method to update that user-specified value in addition to selcting it.
   * Additionally, since the implementation of the Text Search filter allows the end-user to select
   * previously searched-for items, we must provide a way to concatenate the current text value with
   * any historical values selected and set that as the currently selected optionValue.
   * @param userSpecifiedString string
   */
  updateUserSpecifiedValue(userSpecifiedString: string) {
    const currentSelectedValues = this.optionValue
      .split(',')
      .filter((option) => option !== '');
    const newSelectedValues = [...currentSelectedValues, userSpecifiedString];
    this.optionValue = [...new Set(newSelectedValues)].join(',');
  }
}

/**
 * Represents an individual dropdown and contains one or more DropdownListItem instances.
 * In general, should not be called directly, but via DropdownManager.
 */
export class DropdownConfig {
  dropdownType: DropdownType;
  id: string;
  label: string;
  listItems: DropdownListItem[];
  selectionHistory: TrackedSet<string>; // Currently used by RAWTEXT_SEARCH only
  dateRange?: DateRange; // DATE_RANGE only
  options?: DropdownConfigOptions;

  constructor(
    dropdownType: DropdownType,
    id: string,
    label: string,
    listItems: Array<DropdownListItem>,
    options?: DropdownConfigOptions,
  ) {
    this.dropdownType = dropdownType;
    this.label = label;
    this.id = id;
    this.listItems = listItems;
    this.selectionHistory = new TrackedSet();

    if (this.dropdownType === 'DATE_RANGE') {
      this.dateRange = new DateRange(options);
    } else {
      this.options = options;
    }
    this.validate();
  }

  get keyedSelectedValues() {
    return { [this.id]: this.selectedValues };
  }

  get selectedValues() {
    return this.listItems.reduce((result, option) => {
      if (option.isSelected) {
        return [...result, option.optionValue];
      }
      return result;
    }, [] as string[]);
  }

  get displayName() {
    let displayString = this.label;
    if (this.dropdownType === 'DATE_RANGE' && this.dateRange?.isCustom) {
      return `${displayString}: ${this.dateRange.customDateRangeString}`;
    }

    const selectedValuesCount = this.selectedValues.length;
    const showAdditionalCount = selectedValuesCount > 1;

    if (selectedValuesCount) {
      const firstSelectedValue = this.selectedValues[0] ?? '';
      const firstSelectedListItem =
        this.getListItemByOptionValue(firstSelectedValue);
      const selectedItemLabel =
        this.dropdownType === 'RAWTEXT_SEARCH'
          ? this.getSearchTextDisplayString()
          : firstSelectedListItem?.optionLabel;
      displayString = displayString + `: ${selectedItemLabel}`;
    }

    if (showAdditionalCount) {
      displayString = displayString + ` +${selectedValuesCount - 1}`;
    }

    return displayString;
  }

  /**
   * Returns a tuple containing the first search text item and the number of other selected text values
   */
  get parsedSearchTextDisplayInfo(): [string, number] {
    if (!this.selectedValues.length) {
      return ['', 0];
    }
    const [firstSearchEntry, ...remainingValues] = (
      this.selectedValues[0] ?? ''
    ).split(',');
    return [firstSearchEntry ?? '', remainingValues.length];
  }

  get searchTextTooltip() {
    const [searchText] = this.parsedSearchTextDisplayInfo;
    return searchText.length > 18 ? searchText : '';
  }

  getSearchTextDisplayString() {
    const [firstSelection, remainingLength] = this.parsedSearchTextDisplayInfo;
    let searchText = firstSelection;
    if (searchText.length > 18) {
      searchText = `${searchText.substring(0, 18)}…`;
    }
    return remainingLength ? `${searchText} +${remainingLength}` : searchText;
  }

  @action
  onSelect(selectedOptionValue: string) {
    // Text search option values are end-user defined by their nature,
    // which requires updating of the option value prior to selection
    // See the comment on the DropdownListItem method 'updateUserSpecifiedValue' for more info
    if (this.dropdownType === 'RAWTEXT_SEARCH') {
      const [searchListItem] = this.listItems;
      searchListItem?.updateUserSpecifiedValue(selectedOptionValue);
      if (selectedOptionValue) {
        this.selectionHistory.add(selectedOptionValue);
      }
      return searchListItem?.select();
    }

    const selectedOption = this.listItems.find(
      (listItem) => listItem.optionValue === selectedOptionValue,
    );

    return selectedOption?.select();
  }

  @action
  onUnselect(selectedOptionValue: string) {
    const selectedOption = this.listItems.find(
      (listItem) => listItem.optionValue === selectedOptionValue,
    );
    return selectedOption?.unselect();
  }

  @action
  resetDropdown() {
    this.listItems.forEach((item) => {
      item.unselect();
    });

    if (this.dropdownType === 'DATE_RANGE') {
      this.dateRange?.resetState();
    }
  }

  validate() {
    assert(
      'You must provide at least one DropdownListItem instance',
      this.listItems.length,
    );
  }

  private getListItemByOptionValue(optionValue: string) {
    return this.listItems.find(
      (listItem) => listItem.optionValue === optionValue,
    );
  }
}

/**
 * DropdownManager contains one or more DropdownConfig instances and is the interface through which developers
 * should interact with individual dropdowns and list items within dropdowns.
 */
export class DropdownManager {
  #dropdowns;
  callbackFn;
  trackAnalytics;
  options;

  constructor(
    dropdownCollection: DropdownConfig[],
    callbackFn?: (dropdownSelection: DropdownSelection) => void,
    trackAnalytics?: (dropdownSelections?: DropdownSelection[]) => void,
    options?: DropdownManagerOptions,
  ) {
    this.#dropdowns = dropdownCollection;
    this.callbackFn = callbackFn || noop;
    this.trackAnalytics = trackAnalytics || noop;
    this.options = options;
  }

  get dropdowns() {
    return this.options?.sortFiltersOnSelect
      ? this.#dropdowns.sort((a: DropdownConfig, b: DropdownConfig) => {
          if (a.selectedValues.length === b.selectedValues.length) return 0;
          return a.selectedValues.length > b.selectedValues.length ? -1 : 1;
        })
      : this.#dropdowns;
  }

  get currentDropdownSelections(): DropdownSelection[] {
    return this.dropdowns.map((dropdown) => dropdown.keyedSelectedValues);
  }

  get hasActiveSelections() {
    const activeSelections = this.currentDropdownSelections.filter(
      (selection) => {
        const dropdownSelection = Object.values(selection)[0];
        return dropdownSelection?.length ?? false;
      },
    );

    return Boolean(activeSelections.length);
  }

  // Looks up a dropdown, finds the list item with the given value, and returns its label
  getKey(dropdownId: string, value: string) {
    const dropdown = this.getDropdownById(dropdownId);
    const listItem = dropdown.listItems.find(
      (item) => item.optionValue === value,
    );
    return listItem?.optionLabel ? listItem?.optionLabel : value;
  }

  @action
  trackInteraction() {
    this.trackAnalytics(this.currentDropdownSelections);
  }

  @action
  submitDropdownSelection(
    dropdownId: string,
    updateValues: string[],
    dropdown?: HdsDropdownElement,
  ) {
    const dropdownToUpdate = this.getDropdownById(dropdownId);
    dropdownToUpdate.resetDropdown();
    updateValues.forEach((updateValue) =>
      dropdownToUpdate.onSelect(updateValue),
    );

    // Special callback handling for date ranges due to the compound nature of date range values
    if (dropdownToUpdate.dropdownType === 'DATE_RANGE') {
      dropdownToUpdate.dateRange?.applyPendingChanges();
      const dateRangeResult = [dropdownToUpdate.dateRange?.dateRangeString];
      dropdownToUpdate.dateRange?.setCustomDateRangeDisplayString();
      this.callbackFn({
        [dropdownId]: dateRangeResult,
      } as DropdownSelection);

      dropdown?.close();
      return;
    }

    this.callbackFn(dropdownToUpdate.keyedSelectedValues);
    dropdown?.close();
  }

  @action
  resetDropdownSelection(dropdownId: string) {
    const dropdownToReset = this.getDropdownById(dropdownId);
    dropdownToReset.resetDropdown();
    this.callbackFn(dropdownToReset.keyedSelectedValues);
  }

  @action
  resetAllDropdowns() {
    this.dropdowns.forEach((dropdownConfig) => {
      this.resetDropdownSelection(dropdownConfig.id);
    });
    this.trackAnalytics(/* empty for reset */);
  }

  getDropdownById(dropdownId: string) {
    const dropdown = this.dropdowns.find(
      (dropdown) => dropdown.id === dropdownId,
    );
    if (!dropdown)
      throw new Error(`Dropdown with id ${dropdownId} does not exist`);
    return dropdown;
  }
}

/**
 * Convenience export
 * @param dropdowns Array of dropdown configs
 * @param callback Callback function which handles the updated filter values
 * @param trackCallback Callback function which tracks user interactions
 * @param dropdownManagerOptions
 * @returns DropdownManager instance
 */
export default function (
  dropdowns = [],
  callback = () => {},
  trackCallback = () => {},
  dropdownManagerOptions?: DropdownManagerOptions,
) {
  interface ListItem {
    optionType: DropdownListItemType;
    optionLabel: string;
    optionValue: string;
    helperText?: string;
    groupId?: string;
  }

  const dropdownInstances = dropdowns.map((dropdown) => {
    const {
      id,
      label,
      dropdownType,
      listItems,
      options,
    }: {
      id: string;
      label: string;
      dropdownType: DropdownType;
      listItems: ListItem[];
      options: DropdownConfigOptions;
    } = dropdown;

    const listItemInstances = listItems.map((listItem: ListItem) => {
      const { optionType, optionLabel, optionValue, helperText, groupId } =
        listItem;
      return new DropdownListItem(
        optionType,
        optionLabel,
        optionValue,
        helperText,
        groupId,
      );
    });

    return new DropdownConfig(
      dropdownType,
      id,
      label,
      listItemInstances,
      options,
    );
  });
  return new DropdownManager(
    dropdownInstances,
    callback,
    trackCallback,
    dropdownManagerOptions,
  );
}
