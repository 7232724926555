/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20231128IntegrationUsageFromJSON(json) {
    return Secrets20231128IntegrationUsageFromJSONTyped(json, false);
}
export function Secrets20231128IntegrationUsageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'appsCount': !exists(json, 'apps_count') ? undefined : json['apps_count'],
        'secretsCount': !exists(json, 'secrets_count') ? undefined : json['secrets_count'],
        'secretsCountByApp': !exists(json, 'secrets_count_by_app') ? undefined : json['secrets_count_by_app'],
    };
}
export function Secrets20231128IntegrationUsageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'apps_count': value.appsCount,
        'secrets_count': value.secretsCount,
        'secrets_count_by_app': value.secretsCountByApp,
    };
}
