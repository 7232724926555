/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const MATCH_STRING_DEFAULT = 'DELETE';

interface ModalConfirmSignature {
  Args: {
    confirmHelp?: unknown;
    confirmLabel?: unknown;
    matchString: unknown;
    onAllowedChange: unknown;
  };
}

/**
 *
 * `ModalConfirm` is a component that handles the typing of confirm text for modals.
 *
 *
 * ```
 * <ModalConfirm
 *   @confirmLabel={{t 'components.modals.restore.confirm-label'}}
 *   @confirmHelp={{t 'components.modals.restore.confirm-help'}}
 *   @matchString={{t 'components.modals.restore.match-string'}}
 *   @onAllowedChange={{fn this.foo}}
 * />
 * ```
 *
 * @class ModalConfirm
 *
 */

/**
 * The string the confirm text should match. If no argument is passed in, this defaults to 'DELETE'.
 *
 * @argument matchString
 * @type {string}
 */

/**
 * The form label string for the confirm component.
 *
 * @argument confirmLabel
 * @type {string}
 */

/**
 * The form help string for the confirm component.
 *
 * @argument confirmHelp
 * @type {string}
 */
export default class ModalConfirmComponent extends Component<ModalConfirmSignature> {
  @tracked confirmString = '';

  change =
    typeof this.args?.onAllowedChange === 'function'
      ? this.args.onAllowedChange
      : () => {};

  get matchString() {
    return this.args.matchString || MATCH_STRING_DEFAULT;
  }

  get confirmed() {
    const { matchString } = this;
    return this.confirmString === matchString;
  }

  @action
  setConfirm(evt: any) {
    this.confirmString = evt.target?.value;
    this.change(this.confirmed);
  }

  // this is called on will-destroy so that closing the modal and re-opening
  // does not perist a previous match
  @action
  resetConfirm() {
    this.change(false);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ModalConfirm: typeof ModalConfirmComponent;
    'modal-confirm': typeof ModalConfirmComponent;
  }
}
