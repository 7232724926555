/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVagrant20220930ArchitectureFromJSON, HashicorpCloudVagrant20220930ProviderSummaryFromJSON, HashicorpCloudVagrant20220930ProviderSummaryToJSON, } from './';
export function HashicorpCloudVagrant20220930ProviderFromJSON(json) {
    return HashicorpCloudVagrant20220930ProviderFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930ProviderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'architectures': !exists(json, 'architectures') ? undefined : (json['architectures'].map(HashicorpCloudVagrant20220930ArchitectureFromJSON)),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'summary': !exists(json, 'summary') ? undefined : HashicorpCloudVagrant20220930ProviderSummaryFromJSON(json['summary']),
    };
}
export function HashicorpCloudVagrant20220930ProviderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'summary': HashicorpCloudVagrant20220930ProviderSummaryToJSON(value.summary),
    };
}
