/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * IncludeLevels represents hierarchical levels below an organization under
 * which service principals can be crated.
 *
 *  - UNSET: UNSET is the default value.
 * UNSET will only include service principals at organization level.
 *  - ALL: ALL will include all service principals on all hirarchical levels
 * under the given organization.
 *  - PROJECTS: PROJECTS includes service principals at project level
 * in the list response.
 * @export
 * @enum {string}
 */
export var HashicorpCloudIamListOrganizationServicePrincipalsRequestIncludeLevels;
(function (HashicorpCloudIamListOrganizationServicePrincipalsRequestIncludeLevels) {
    HashicorpCloudIamListOrganizationServicePrincipalsRequestIncludeLevels["UNSET"] = "UNSET";
    HashicorpCloudIamListOrganizationServicePrincipalsRequestIncludeLevels["ALL"] = "ALL";
    HashicorpCloudIamListOrganizationServicePrincipalsRequestIncludeLevels["PROJECTS"] = "PROJECTS";
})(HashicorpCloudIamListOrganizationServicePrincipalsRequestIncludeLevels || (HashicorpCloudIamListOrganizationServicePrincipalsRequestIncludeLevels = {}));
export function HashicorpCloudIamListOrganizationServicePrincipalsRequestIncludeLevelsFromJSON(json) {
    return HashicorpCloudIamListOrganizationServicePrincipalsRequestIncludeLevelsFromJSONTyped(json, false);
}
export function HashicorpCloudIamListOrganizationServicePrincipalsRequestIncludeLevelsFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudIamListOrganizationServicePrincipalsRequestIncludeLevelsToJSON(value) {
    return value;
}
