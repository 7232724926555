/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20230613HcpTerraformUpdateInstallationRequestFromJSON, Secrets20230613HcpTerraformUpdateInstallationRequestToJSON, Secrets20230613OrganizationsLocationOrganizationIdProjectsLocationProjectIdAppsLocationFromJSON, Secrets20230613OrganizationsLocationOrganizationIdProjectsLocationProjectIdAppsLocationToJSON, } from './';
export function InlineObject12FromJSON(json) {
    return InlineObject12FromJSONTyped(json, false);
}
export function InlineObject12FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : Secrets20230613OrganizationsLocationOrganizationIdProjectsLocationProjectIdAppsLocationFromJSON(json['location']),
        'hcpTerraformUpdateInstallationRequest': !exists(json, 'hcp_terraform_update_installation_request') ? undefined : Secrets20230613HcpTerraformUpdateInstallationRequestFromJSON(json['hcp_terraform_update_installation_request']),
    };
}
export function InlineObject12ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': Secrets20230613OrganizationsLocationOrganizationIdProjectsLocationProjectIdAppsLocationToJSON(value.location),
        'hcp_terraform_update_installation_request': Secrets20230613HcpTerraformUpdateInstallationRequestToJSON(value.hcpTerraformUpdateInstallationRequest),
    };
}
