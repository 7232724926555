/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudCommonPaginationResponseFromJSON(json) {
    return HashicorpCloudCommonPaginationResponseFromJSONTyped(json, false);
}
export function HashicorpCloudCommonPaginationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'previousPageToken': !exists(json, 'previous_page_token') ? undefined : json['previous_page_token'],
    };
}
export function HashicorpCloudCommonPaginationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'next_page_token': value.nextPageToken,
        'previous_page_token': value.previousPageToken,
    };
}
