/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudConsul20210204CapacityConfigFromJSON, HashicorpCloudConsul20210204CapacityConfigToJSON, HashicorpCloudConsul20210204ClusterConfigTierFromJSON, HashicorpCloudConsul20210204ClusterConfigTierToJSON, HashicorpCloudConsul20210204ConsulConfigFromJSON, HashicorpCloudConsul20210204ConsulConfigToJSON, HashicorpCloudConsul20210204NetworkConfigFromJSON, HashicorpCloudConsul20210204NetworkConfigToJSON, HashicorpCloudLocationLinkFromJSON, HashicorpCloudLocationLinkToJSON, } from './';
export function HashicorpCloudConsul20210204ClusterConfigFromJSON(json) {
    return HashicorpCloudConsul20210204ClusterConfigFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204ClusterConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'consulConfig': !exists(json, 'consul_config') ? undefined : HashicorpCloudConsul20210204ConsulConfigFromJSON(json['consul_config']),
        'networkConfig': !exists(json, 'network_config') ? undefined : HashicorpCloudConsul20210204NetworkConfigFromJSON(json['network_config']),
        'maintenanceConfig': !exists(json, 'maintenance_config') ? undefined : json['maintenance_config'],
        'snapshotConfig': !exists(json, 'snapshot_config') ? undefined : json['snapshot_config'],
        'capacityConfig': !exists(json, 'capacity_config') ? undefined : HashicorpCloudConsul20210204CapacityConfigFromJSON(json['capacity_config']),
        'tier': !exists(json, 'tier') ? undefined : HashicorpCloudConsul20210204ClusterConfigTierFromJSON(json['tier']),
        'primary': !exists(json, 'primary') ? undefined : HashicorpCloudLocationLinkFromJSON(json['primary']),
        'autoHvnToHvnPeering': !exists(json, 'auto_hvn_to_hvn_peering') ? undefined : json['auto_hvn_to_hvn_peering'],
    };
}
export function HashicorpCloudConsul20210204ClusterConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'consul_config': HashicorpCloudConsul20210204ConsulConfigToJSON(value.consulConfig),
        'network_config': HashicorpCloudConsul20210204NetworkConfigToJSON(value.networkConfig),
        'maintenance_config': value.maintenanceConfig,
        'snapshot_config': value.snapshotConfig,
        'capacity_config': HashicorpCloudConsul20210204CapacityConfigToJSON(value.capacityConfig),
        'tier': HashicorpCloudConsul20210204ClusterConfigTierToJSON(value.tier),
        'primary': HashicorpCloudLocationLinkToJSON(value.primary),
        'auto_hvn_to_hvn_peering': value.autoHvnToHvnPeering,
    };
}
