/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202RegistryBillingDeprovisionReason;
(function (HashicorpCloudPacker20221202RegistryBillingDeprovisionReason) {
    HashicorpCloudPacker20221202RegistryBillingDeprovisionReason["DELINQUENTBILLINGACCOUNT"] = "DELINQUENT_BILLING_ACCOUNT";
    HashicorpCloudPacker20221202RegistryBillingDeprovisionReason["USERREQUEST"] = "USER_REQUEST";
    HashicorpCloudPacker20221202RegistryBillingDeprovisionReason["HASHIADMINREQUEST"] = "HASHI_ADMIN_REQUEST";
})(HashicorpCloudPacker20221202RegistryBillingDeprovisionReason || (HashicorpCloudPacker20221202RegistryBillingDeprovisionReason = {}));
export function HashicorpCloudPacker20221202RegistryBillingDeprovisionReasonFromJSON(json) {
    return HashicorpCloudPacker20221202RegistryBillingDeprovisionReasonFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202RegistryBillingDeprovisionReasonFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202RegistryBillingDeprovisionReasonToJSON(value) {
    return value;
}
