import Component from '@glimmer/component';

interface HvnSelectHvnGroupSignature {
  Args: {
    group: unknown;
  };
  Blocks: {
    default: [];
  };
}

export default class FormInputsHvnSelectHvnGroupComponent extends Component<HvnSelectHvnGroupSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormInputs::HvnSelect::HvnGroup': typeof FormInputsHvnSelectHvnGroupComponent;
    'form-inputs/hvn-select/hvn-group': typeof FormInputsHvnSelectHvnGroupComponent;
  }
}
