/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var Secrets20230613HcpTerraformCategory;
(function (Secrets20230613HcpTerraformCategory) {
    Secrets20230613HcpTerraformCategory["TERRAFORM"] = "TERRAFORM";
    Secrets20230613HcpTerraformCategory["ENV"] = "ENV";
})(Secrets20230613HcpTerraformCategory || (Secrets20230613HcpTerraformCategory = {}));
export function Secrets20230613HcpTerraformCategoryFromJSON(json) {
    return Secrets20230613HcpTerraformCategoryFromJSONTyped(json, false);
}
export function Secrets20230613HcpTerraformCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Secrets20230613HcpTerraformCategoryToJSON(value) {
    return value;
}
