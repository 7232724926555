import type { HashicorpCloudResourcemanagerPolicyBindingMember } from '@clients/cloud-resource-manager';
import type { PolicyManagerLocation } from '../types/policy-manager-location.ts';
import type { IamRoleLocation } from '../types/iam-role-location.ts';

export default function iamFindRoleLocationsFromPolicyManagerLocations(
  policyManagerLocations: Array<PolicyManagerLocation> = [],
  {
    memberIds,
  }: {
    memberIds: Array<
      HashicorpCloudResourcemanagerPolicyBindingMember['memberId']
    >;
  },
): Array<IamRoleLocation> {
  if (
    !policyManagerLocations.length ||
    !Array.isArray(policyManagerLocations) ||
    !memberIds ||
    !Array.isArray(memberIds)
  ) {
    return [];
  }

  return policyManagerLocations.reduce(
    (
      roleLocations: IamRoleLocation[],
      policyManagerLocation: PolicyManagerLocation,
    ): Array<IamRoleLocation> => {
      const foundIamRoleLocations = memberIds.reduce(
        (
          locations: Array<IamRoleLocation>,
          memberId,
        ): Array<IamRoleLocation> => {
          const { memberType, roleIds } =
            policyManagerLocation.policyManager.getRolesByMemberId(memberId) ??
            {};
          const member = {
            memberId,
            memberType,
          };
          return [
            ...locations,
            ...(roleIds ? roleIds : []).map((roleId) => ({
              member,
              link: policyManagerLocation.link,
              roleId,
            })),
          ] as Array<IamRoleLocation>;
        },
        [],
      );

      return [
        ...roleLocations,
        ...foundIamRoleLocations,
      ] as Array<IamRoleLocation>;
    },
    [],
  );
}
