/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudInternalLocationLocationFromJSON, HashicorpCloudInternalLocationLocationToJSON, HashicorpCloudVault20201125AuditLogStateFromJSON, HashicorpCloudVault20201125AuditLogStateToJSON, } from './';
export function HashicorpCloudVault20201125AuditLogFromJSON(json) {
    return HashicorpCloudVault20201125AuditLogFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125AuditLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'intervalStart': !exists(json, 'interval_start') ? undefined : (new Date(json['interval_start'])),
        'intervalEnd': !exists(json, 'interval_end') ? undefined : (new Date(json['interval_end'])),
        'downloadUrl': !exists(json, 'download_url') ? undefined : json['download_url'],
        'state': !exists(json, 'state') ? undefined : HashicorpCloudVault20201125AuditLogStateFromJSON(json['state']),
        'finishedAt': !exists(json, 'finished_at') ? undefined : (new Date(json['finished_at'])),
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'location': !exists(json, 'location') ? undefined : HashicorpCloudInternalLocationLocationFromJSON(json['location']),
    };
}
export function HashicorpCloudVault20201125AuditLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'cluster_id': value.clusterId,
        'interval_start': value.intervalStart === undefined ? undefined : (value.intervalStart.toISOString()),
        'interval_end': value.intervalEnd === undefined ? undefined : (value.intervalEnd.toISOString()),
        'download_url': value.downloadUrl,
        'state': HashicorpCloudVault20201125AuditLogStateToJSON(value.state),
        'finished_at': value.finishedAt === undefined ? undefined : (value.finishedAt.toISOString()),
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'location': HashicorpCloudInternalLocationLocationToJSON(value.location),
    };
}
