/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Operations
 * API for managing HCP operations.
 *
 * The version of the OpenAPI document: 2020-05-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * State is one of the states that an Operation can be in.
 *
 * The states are purposely coarse grained to make it easy to understand
 * the operation state machine: pending => running => done. Or pending =>
 * queued => running => done. No other state transitions are possible.
 * Success/failure can be determined based on the result oneof.
 * @export
 * @enum {string}
 */
export var HashicorpCloudOperationOperationState;
(function(HashicorpCloudOperationOperationState) {
  HashicorpCloudOperationOperationState['PENDING'] = 'PENDING';
  HashicorpCloudOperationOperationState['RUNNING'] = 'RUNNING';
  HashicorpCloudOperationOperationState['DONE'] = 'DONE';
  HashicorpCloudOperationOperationState['QUEUED'] = 'QUEUED';
})(HashicorpCloudOperationOperationState || (HashicorpCloudOperationOperationState = {}));
export function HashicorpCloudOperationOperationStateFromJSON(json) {
  return HashicorpCloudOperationOperationStateFromJSONTyped(json, false);
}
export function HashicorpCloudOperationOperationStateFromJSONTyped(json, ignoreDiscriminator) {
  return json;
}
export function HashicorpCloudOperationOperationStateToJSON(value) {
  return value;
}
