/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202BucketFromJSON, HashicorpCloudPacker20221202BucketToJSON, } from './';
export function HashicorpCloudPacker20221202CreateBucketResponseFromJSON(json) {
    return HashicorpCloudPacker20221202CreateBucketResponseFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202CreateBucketResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bucket': !exists(json, 'bucket') ? undefined : HashicorpCloudPacker20221202BucketFromJSON(json['bucket']),
    };
}
export function HashicorpCloudPacker20221202CreateBucketResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bucket': HashicorpCloudPacker20221202BucketToJSON(value.bucket),
    };
}
