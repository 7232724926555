/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeMetadataFromJSON, HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeMetadataToJSON, } from './';
export function HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'metadata': !exists(json, 'metadata') ? undefined : HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeMetadataFromJSON(json['metadata']),
    };
}
export function HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'metadata': HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeMetadataToJSON(value.metadata),
    };
}
