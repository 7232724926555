/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215CertificateMetadataFromJSON, HashicorpCloudGlobalNetworkManager20220215CertificateMetadataToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215TLSInfoFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215TLSInfoFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215TLSInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'certExpiry': !exists(json, 'cert_expiry') ? undefined : (new Date(json['cert_expiry'])),
        'certName': !exists(json, 'cert_name') ? undefined : json['cert_name'],
        'certSerial': !exists(json, 'cert_serial') ? undefined : json['cert_serial'],
        'verifyIncoming': !exists(json, 'verify_incoming') ? undefined : json['verify_incoming'],
        'verifyOutgoing': !exists(json, 'verify_outgoing') ? undefined : json['verify_outgoing'],
        'verifyServerHostname': !exists(json, 'verify_server_hostname') ? undefined : json['verify_server_hostname'],
        'certIssuer': !exists(json, 'cert_issuer') ? undefined : json['cert_issuer'],
        'certificateAuthorities': !exists(json, 'certificate_authorities') ? undefined : (json['certificate_authorities'].map(HashicorpCloudGlobalNetworkManager20220215CertificateMetadataFromJSON)),
        'tlsAutorotated': !exists(json, 'tls_autorotated') ? undefined : json['tls_autorotated'],
    };
}
export function HashicorpCloudGlobalNetworkManager20220215TLSInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'enabled': value.enabled,
        'cert_expiry': value.certExpiry === undefined ? undefined : (value.certExpiry.toISOString()),
        'cert_name': value.certName,
        'cert_serial': value.certSerial,
        'verify_incoming': value.verifyIncoming,
        'verify_outgoing': value.verifyOutgoing,
        'verify_server_hostname': value.verifyServerHostname,
        'cert_issuer': value.certIssuer,
        'certificate_authorities': value.certificateAuthorities === undefined ? undefined : (value.certificateAuthorities.map(HashicorpCloudGlobalNetworkManager20220215CertificateMetadataToJSON)),
        'tls_autorotated': value.tlsAutorotated,
    };
}
