/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Billing20201105RemoveOnDemandPaymentMethodResponseFromJSON(json) {
    return Billing20201105RemoveOnDemandPaymentMethodResponseFromJSONTyped(json, false);
}
export function Billing20201105RemoveOnDemandPaymentMethodResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ok': !exists(json, 'ok') ? undefined : json['ok'],
    };
}
export function Billing20201105RemoveOnDemandPaymentMethodResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ok': value.ok,
    };
}
