/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - VERSION_UNSET: UNSET is a sentinel zero value so that an uninitialized value can be
 * detected.
 *  - VERSION_RUNNING: Running means the `packer build` is currently running in the version.
 *  - VERSION_CANCELLED: Cancelled means the `packer build` was cancelled by a user.
 *  - VERSION_FAILED: Failed means the `packer build` failed and therefore artifact creation failed.
 *  - VERSION_REVOKED: Revoked means the version has been revoked.
 *  - VERSION_REVOCATION_SCHEDULED: Revocation scheduled means the version has been scheduled to be revoked.
 *  - VERSION_ACTIVE: Active means the version is valid and ready to be used.
 *  - VERSION_INCOMPLETE: Incomplete means the version has one or more builds that didn't run and the status is UNSET.
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202ModelsVersionStatus;
(function (HashicorpCloudPacker20221202ModelsVersionStatus) {
    HashicorpCloudPacker20221202ModelsVersionStatus["UNSET"] = "VERSION_UNSET";
    HashicorpCloudPacker20221202ModelsVersionStatus["RUNNING"] = "VERSION_RUNNING";
    HashicorpCloudPacker20221202ModelsVersionStatus["CANCELLED"] = "VERSION_CANCELLED";
    HashicorpCloudPacker20221202ModelsVersionStatus["FAILED"] = "VERSION_FAILED";
    HashicorpCloudPacker20221202ModelsVersionStatus["REVOKED"] = "VERSION_REVOKED";
    HashicorpCloudPacker20221202ModelsVersionStatus["REVOCATIONSCHEDULED"] = "VERSION_REVOCATION_SCHEDULED";
    HashicorpCloudPacker20221202ModelsVersionStatus["ACTIVE"] = "VERSION_ACTIVE";
    HashicorpCloudPacker20221202ModelsVersionStatus["INCOMPLETE"] = "VERSION_INCOMPLETE";
})(HashicorpCloudPacker20221202ModelsVersionStatus || (HashicorpCloudPacker20221202ModelsVersionStatus = {}));
export function HashicorpCloudPacker20221202ModelsVersionStatusFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsVersionStatusFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsVersionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202ModelsVersionStatusToJSON(value) {
    return value;
}
