/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202ModelsRegistryConfigTier;
(function (HashicorpCloudPacker20221202ModelsRegistryConfigTier) {
    HashicorpCloudPacker20221202ModelsRegistryConfigTier["UNSET"] = "UNSET";
    HashicorpCloudPacker20221202ModelsRegistryConfigTier["STANDARD"] = "STANDARD";
    HashicorpCloudPacker20221202ModelsRegistryConfigTier["PLUS"] = "PLUS";
})(HashicorpCloudPacker20221202ModelsRegistryConfigTier || (HashicorpCloudPacker20221202ModelsRegistryConfigTier = {}));
export function HashicorpCloudPacker20221202ModelsRegistryConfigTierFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsRegistryConfigTierFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsRegistryConfigTierFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202ModelsRegistryConfigTierToJSON(value) {
    return value;
}
