/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudNetwork20200907NetworkFromJSON, HashicorpCloudNetwork20200907NetworkToJSON, } from './';
export function HashicorpCloudNetwork20200907CreateRequestFromJSON(json) {
    return HashicorpCloudNetwork20200907CreateRequestFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907CreateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'network': !exists(json, 'network') ? undefined : HashicorpCloudNetwork20200907NetworkFromJSON(json['network']),
    };
}
export function HashicorpCloudNetwork20200907CreateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'network': HashicorpCloudNetwork20200907NetworkToJSON(value.network),
    };
}
