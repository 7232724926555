/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20230613SyncIntegrationFromJSON, Secrets20230613SyncIntegrationToJSON, } from './';
export function Secrets20230613CreateSyncIntegrationResponseFromJSON(json) {
    return Secrets20230613CreateSyncIntegrationResponseFromJSONTyped(json, false);
}
export function Secrets20230613CreateSyncIntegrationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'syncIntegration': !exists(json, 'sync_integration') ? undefined : Secrets20230613SyncIntegrationFromJSON(json['sync_integration']),
    };
}
export function Secrets20230613CreateSyncIntegrationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sync_integration': Secrets20230613SyncIntegrationToJSON(value.syncIntegration),
    };
}
