/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202BuildMetadataFromJSON, HashicorpCloudPacker20221202BuildMetadataToJSON, HashicorpCloudPacker20221202BuildStatusFromJSON, HashicorpCloudPacker20221202BuildStatusToJSON, HashicorpCloudPacker20221202ImageFromJSON, HashicorpCloudPacker20221202ImageToJSON, } from './';
export function HashicorpCloudPacker20221202BuildFromJSON(json) {
    return HashicorpCloudPacker20221202BuildFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202BuildFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'iterationId': !exists(json, 'iteration_id') ? undefined : json['iteration_id'],
        'componentType': !exists(json, 'component_type') ? undefined : json['component_type'],
        'packerRunUuid': !exists(json, 'packer_run_uuid') ? undefined : json['packer_run_uuid'],
        'images': !exists(json, 'images') ? undefined : (json['images'].map(HashicorpCloudPacker20221202ImageFromJSON)),
        'cloudProvider': !exists(json, 'cloud_provider') ? undefined : json['cloud_provider'],
        'status': !exists(json, 'status') ? undefined : HashicorpCloudPacker20221202BuildStatusFromJSON(json['status']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'sourceImageId': !exists(json, 'source_image_id') ? undefined : json['source_image_id'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'sourceBuildUlid': !exists(json, 'source_build_ulid') ? undefined : json['source_build_ulid'],
        'metadata': !exists(json, 'metadata') ? undefined : HashicorpCloudPacker20221202BuildMetadataFromJSON(json['metadata']),
    };
}
export function HashicorpCloudPacker20221202BuildToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'iteration_id': value.iterationId,
        'component_type': value.componentType,
        'packer_run_uuid': value.packerRunUuid,
        'images': value.images === undefined ? undefined : (value.images.map(HashicorpCloudPacker20221202ImageToJSON)),
        'cloud_provider': value.cloudProvider,
        'status': HashicorpCloudPacker20221202BuildStatusToJSON(value.status),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'source_image_id': value.sourceImageId,
        'labels': value.labels,
        'source_build_ulid': value.sourceBuildUlid,
        'metadata': HashicorpCloudPacker20221202BuildMetadataToJSON(value.metadata),
    };
}
