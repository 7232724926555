/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, HashicorpCloudPacker20221202BuildFromJSON, HashicorpCloudPacker20221202BuildToJSON, } from './';
export function HashicorpCloudPacker20221202ListBuildsResponseFromJSON(json) {
    return HashicorpCloudPacker20221202ListBuildsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ListBuildsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
        'builds': !exists(json, 'builds') ? undefined : (json['builds'].map(HashicorpCloudPacker20221202BuildFromJSON)),
    };
}
export function HashicorpCloudPacker20221202ListBuildsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
        'builds': value.builds === undefined ? undefined : (value.builds.map(HashicorpCloudPacker20221202BuildToJSON)),
    };
}
