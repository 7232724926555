/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudInternalLocationLinkFromJSON, HashicorpCloudInternalLocationLinkToJSON, HashicorpCloudInternalLocationLocationFromJSON, HashicorpCloudInternalLocationLocationToJSON, HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterFromJSON, HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterToJSON, HashicorpCloudVault20201125InputClusterConfigFromJSON, HashicorpCloudVault20201125InputClusterConfigToJSON, HashicorpCloudVault20201125InputClusterTemplateInputFromJSON, HashicorpCloudVault20201125InputClusterTemplateInputToJSON, } from './';
export function HashicorpCloudVault20201125InputClusterFromJSON(json) {
    return HashicorpCloudVault20201125InputClusterFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125InputClusterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudInternalLocationLocationFromJSON(json['location']),
        'config': !exists(json, 'config') ? undefined : HashicorpCloudVault20201125InputClusterConfigFromJSON(json['config']),
        'performanceReplicationPrimaryCluster': !exists(json, 'performance_replication_primary_cluster') ? undefined : HashicorpCloudInternalLocationLinkFromJSON(json['performance_replication_primary_cluster']),
        'performanceReplicationPathsFilter': !exists(json, 'performance_replication_paths_filter') ? undefined : HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterFromJSON(json['performance_replication_paths_filter']),
        'templateInput': !exists(json, 'template_input') ? undefined : HashicorpCloudVault20201125InputClusterTemplateInputFromJSON(json['template_input']),
    };
}
export function HashicorpCloudVault20201125InputClusterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'location': HashicorpCloudInternalLocationLocationToJSON(value.location),
        'config': HashicorpCloudVault20201125InputClusterConfigToJSON(value.config),
        'performance_replication_primary_cluster': HashicorpCloudInternalLocationLinkToJSON(value.performanceReplicationPrimaryCluster),
        'performance_replication_paths_filter': HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterToJSON(value.performanceReplicationPathsFilter),
        'template_input': HashicorpCloudVault20201125InputClusterTemplateInputToJSON(value.templateInput),
    };
}
