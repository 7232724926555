/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApplicationRefWithoutIDFromJSON, ApplicationRefWithoutIDToJSON, HashicorpCloudWaypointActionCfgRefFromJSON, HashicorpCloudWaypointActionCfgRefToJSON, } from './';
export function HashicorpCloudWaypointWaypointServiceUpdateApplicationBodyFromJSON(json) {
    return HashicorpCloudWaypointWaypointServiceUpdateApplicationBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointWaypointServiceUpdateApplicationBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'application': !exists(json, 'application') ? undefined : ApplicationRefWithoutIDFromJSON(json['application']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'readmeMarkdown': !exists(json, 'readme_markdown') ? undefined : json['readme_markdown'],
        'readme': !exists(json, 'readme') ? undefined : json['readme'],
        'actionCfgRefs': !exists(json, 'action_cfg_refs') ? undefined : (json['action_cfg_refs'].map(HashicorpCloudWaypointActionCfgRefFromJSON)),
        'updateMask': !exists(json, 'update_mask') ? undefined : json['update_mask'],
    };
}
export function HashicorpCloudWaypointWaypointServiceUpdateApplicationBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': value.namespace,
        'application': ApplicationRefWithoutIDToJSON(value.application),
        'name': value.name,
        'readme_markdown': value.readmeMarkdown,
        'readme': value.readme,
        'action_cfg_refs': value.actionCfgRefs === undefined ? undefined : (value.actionCfgRefs.map(HashicorpCloudWaypointActionCfgRefToJSON)),
        'update_mask': value.updateMask,
    };
}
