/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { Billing20201105GetContractResponseFromJSON, Billing20201105ListContractsResponseFromJSON, } from '../models';
/**
 *
 */
export class ContractServiceApi extends runtime.BaseAPI {
    /**
     * GetContract returns a contract by its public ID.
     */
    async contractServiceGetContractRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling contractServiceGetContract.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling contractServiceGetContract.');
        }
        if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
            throw new runtime.RequiredError('contractId', 'Required parameter requestParameters.contractId was null or undefined when calling contractServiceGetContract.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/contracts/{contract_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))).replace(`{${"contract_id"}}`, encodeURIComponent(String(requestParameters.contractId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105GetContractResponseFromJSON(jsonValue));
    }
    /**
     * GetContract returns a contract by its public ID.
     */
    async contractServiceGetContract(organizationId, billingAccountId, contractId) {
        const response = await this.contractServiceGetContractRaw({ organizationId: organizationId, billingAccountId: billingAccountId, contractId: contractId });
        return await response.value();
    }
    /**
     * ListContracts returns a list of a billing account\'s contracts, optionally filtered.
     */
    async contractServiceListContractsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling contractServiceListContracts.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling contractServiceListContracts.');
        }
        const queryParameters = {};
        if (requestParameters.byStatus) {
            queryParameters['by_status'] = requestParameters.byStatus;
        }
        if (requestParameters.byActiveFrom !== undefined) {
            queryParameters['by_active_from'] = requestParameters.byActiveFrom.toISOString();
        }
        if (requestParameters.byActiveUntil !== undefined) {
            queryParameters['by_active_until'] = requestParameters.byActiveUntil.toISOString();
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/contracts`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105ListContractsResponseFromJSON(jsonValue));
    }
    /**
     * ListContracts returns a list of a billing account\'s contracts, optionally filtered.
     */
    async contractServiceListContracts(organizationId, billingAccountId, byStatus, byActiveFrom, byActiveUntil) {
        const response = await this.contractServiceListContractsRaw({ organizationId: organizationId, billingAccountId: billingAccountId, byStatus: byStatus, byActiveFrom: byActiveFrom, byActiveUntil: byActiveUntil });
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var ContractServiceListContractsByStatusEnum;
(function (ContractServiceListContractsByStatusEnum) {
    ContractServiceListContractsByStatusEnum["UNSET"] = "UNSET";
    ContractServiceListContractsByStatusEnum["ACTIVE"] = "ACTIVE";
    ContractServiceListContractsByStatusEnum["UPCOMING"] = "UPCOMING";
    ContractServiceListContractsByStatusEnum["EXPIRED"] = "EXPIRED";
})(ContractServiceListContractsByStatusEnum || (ContractServiceListContractsByStatusEnum = {}));
