/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125HTTPBasicAuthFromJSON, HashicorpCloudVault20201125HTTPBasicAuthToJSON, HashicorpCloudVault20201125HTTPBearerAuthFromJSON, HashicorpCloudVault20201125HTTPBearerAuthToJSON, HashicorpCloudVault20201125HTTPEncodingCodecFromJSON, HashicorpCloudVault20201125HTTPEncodingCodecToJSON, } from './';
export function HashicorpCloudVault20201125HTTPFromJSON(json) {
    return HashicorpCloudVault20201125HTTPFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125HTTPFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'uri': !exists(json, 'uri') ? undefined : json['uri'],
        'method': !exists(json, 'method') ? undefined : json['method'],
        'basic': !exists(json, 'basic') ? undefined : HashicorpCloudVault20201125HTTPBasicAuthFromJSON(json['basic']),
        'bearer': !exists(json, 'bearer') ? undefined : HashicorpCloudVault20201125HTTPBearerAuthFromJSON(json['bearer']),
        'headers': !exists(json, 'headers') ? undefined : json['headers'],
        'payloadPrefix': !exists(json, 'payload_prefix') ? undefined : json['payload_prefix'],
        'payloadSuffix': !exists(json, 'payload_suffix') ? undefined : json['payload_suffix'],
        'compression': !exists(json, 'compression') ? undefined : json['compression'],
        'codec': !exists(json, 'codec') ? undefined : HashicorpCloudVault20201125HTTPEncodingCodecFromJSON(json['codec']),
    };
}
export function HashicorpCloudVault20201125HTTPToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'uri': value.uri,
        'method': value.method,
        'basic': HashicorpCloudVault20201125HTTPBasicAuthToJSON(value.basic),
        'bearer': HashicorpCloudVault20201125HTTPBearerAuthToJSON(value.bearer),
        'headers': value.headers,
        'payload_prefix': value.payloadPrefix,
        'payload_suffix': value.payloadSuffix,
        'compression': value.compression,
        'codec': HashicorpCloudVault20201125HTTPEncodingCodecToJSON(value.codec),
    };
}
