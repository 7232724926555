/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105ConsumptionPoolFromJSON, Billing20201105ConsumptionPoolToJSON, Billing20201105ConsumptionPoolUpdateInfoFromJSON, Billing20201105ConsumptionPoolUpdateInfoToJSON, } from './';
export function Billing20201105GetConsumptionPoolResponseFromJSON(json) {
    return Billing20201105GetConsumptionPoolResponseFromJSONTyped(json, false);
}
export function Billing20201105GetConsumptionPoolResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'consumptionPool': !exists(json, 'consumption_pool') ? undefined : Billing20201105ConsumptionPoolFromJSON(json['consumption_pool']),
        'consumptionPoolUpdates': !exists(json, 'consumption_pool_updates') ? undefined : (json['consumption_pool_updates'].map(Billing20201105ConsumptionPoolUpdateInfoFromJSON)),
    };
}
export function Billing20201105GetConsumptionPoolResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'consumption_pool': Billing20201105ConsumptionPoolToJSON(value.consumptionPool),
        'consumption_pool_updates': value.consumptionPoolUpdates === undefined ? undefined : (value.consumptionPoolUpdates.map(Billing20201105ConsumptionPoolUpdateInfoToJSON)),
    };
}
