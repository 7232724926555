/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UserPrincipalIdentityTypeFromJSON, UserPrincipalIdentityTypeToJSON, } from './';
export function CloudiamUserPrincipalFromJSON(json) {
    return CloudiamUserPrincipalFromJSONTyped(json, false);
}
export function CloudiamUserPrincipalFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'identityType': !exists(json, 'identity_type') ? undefined : UserPrincipalIdentityTypeFromJSON(json['identity_type']),
        'identityTypes': !exists(json, 'identity_types') ? undefined : (json['identity_types'].map(UserPrincipalIdentityTypeFromJSON)),
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'scimSynchronized': !exists(json, 'scim_synchronized') ? undefined : json['scim_synchronized'],
    };
}
export function CloudiamUserPrincipalToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'email': value.email,
        'full_name': value.fullName,
        'identity_type': UserPrincipalIdentityTypeToJSON(value.identityType),
        'identity_types': value.identityTypes === undefined ? undefined : (value.identityTypes.map(UserPrincipalIdentityTypeToJSON)),
        'subject': value.subject,
        'scim_synchronized': value.scimSynchronized,
    };
}
