/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { Secrets20230613OpenSecretVersionFromJSON, Secrets20230613OpenSecretVersionToJSON, Secrets20230613PrincipalFromJSON, Secrets20230613PrincipalToJSON, Secrets20230613SyncStatusFromJSON, Secrets20230613SyncStatusToJSON, } from './';
export function Secrets20230613OpenSecretFromJSON(json) {
    return Secrets20230613OpenSecretFromJSONTyped(json, false);
}
export function Secrets20230613OpenSecretFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'version': !exists(json, 'version') ? undefined : Secrets20230613OpenSecretVersionFromJSON(json['version']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'latestVersion': !exists(json, 'latest_version') ? undefined : json['latest_version'],
        'createdBy': !exists(json, 'created_by') ? undefined : Secrets20230613PrincipalFromJSON(json['created_by']),
        'syncStatus': !exists(json, 'sync_status') ? undefined : (mapValues(json['sync_status'], Secrets20230613SyncStatusFromJSON)),
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
    };
}
export function Secrets20230613OpenSecretToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'version': Secrets20230613OpenSecretVersionToJSON(value.version),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'latest_version': value.latestVersion,
        'created_by': Secrets20230613PrincipalToJSON(value.createdBy),
        'sync_status': value.syncStatus === undefined ? undefined : (mapValues(value.syncStatus, Secrets20230613SyncStatusToJSON)),
        'created_by_id': value.createdById,
    };
}
