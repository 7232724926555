/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudResourcemanagerResourceV2FromJSON, HashicorpCloudResourcemanagerResourceV2ToJSON, } from './';
export function HashicorpCloudResourcemanagerResourceListAccessibleResourcesResponseFromJSON(json) {
    return HashicorpCloudResourcemanagerResourceListAccessibleResourcesResponseFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerResourceListAccessibleResourcesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'resources': !exists(json, 'resources') ? undefined : (json['resources'].map(HashicorpCloudResourcemanagerResourceV2FromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudResourcemanagerResourceListAccessibleResourcesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'resources': value.resources === undefined ? undefined : (value.resources.map(HashicorpCloudResourcemanagerResourceV2ToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
