/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, HashicorpCloudPacker20221202BucketChildrenFromJSON, HashicorpCloudPacker20221202BucketChildrenToJSON, HashicorpCloudPacker20221202BucketLatestIterationFromJSON, HashicorpCloudPacker20221202BucketLatestIterationToJSON, HashicorpCloudPacker20221202BucketParentsFromJSON, HashicorpCloudPacker20221202BucketParentsToJSON, } from './';
export function HashicorpCloudPacker20221202BucketFromJSON(json) {
    return HashicorpCloudPacker20221202BucketFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202BucketFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'latestIteration': !exists(json, 'latest_iteration') ? undefined : HashicorpCloudPacker20221202BucketLatestIterationFromJSON(json['latest_iteration']),
        'latestVersion': !exists(json, 'latest_version') ? undefined : json['latest_version'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'platforms': !exists(json, 'platforms') ? undefined : json['platforms'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'iterationCount': !exists(json, 'iteration_count') ? undefined : json['iteration_count'],
        'parents': !exists(json, 'parents') ? undefined : HashicorpCloudPacker20221202BucketParentsFromJSON(json['parents']),
        'children': !exists(json, 'children') ? undefined : HashicorpCloudPacker20221202BucketChildrenFromJSON(json['children']),
    };
}
export function HashicorpCloudPacker20221202BucketToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'slug': value.slug,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'latest_iteration': HashicorpCloudPacker20221202BucketLatestIterationToJSON(value.latestIteration),
        'latest_version': value.latestVersion,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'platforms': value.platforms,
        'description': value.description,
        'labels': value.labels,
        'iteration_count': value.iterationCount,
        'parents': HashicorpCloudPacker20221202BucketParentsToJSON(value.parents),
        'children': HashicorpCloudPacker20221202BucketChildrenToJSON(value.children),
    };
}
