import { TYPE_VAULT } from 'common/utils/cloud-resource-types';
import {
  RESOURCE_TIER_I18N_PREFIX,
  RESOURCE_TIER_SIZE_I18N_PREFIX,
  RESOURCE_TIER_SIZE_XS,
  RESOURCE_TIER_SIZE_SM,
  RESOURCE_TIER_SIZE_MD,
  RESOURCE_TIER_SIZE_LG,
} from '../../constants/index.ts';

import type {
  ResourceCatalog,
  ResourceTier,
} from '../../types/resource-catalog.ts';

// tier info
// Dev Tier
export const VAULT_TIER_DEV = 'hashicorp.vault.development';
export const VAULT_TIER_DEV_VALUE = 'DEV';
export const VAULT_DEV_FEATURES_PREFIX = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_DEV}.features`;
export const VAULT_DEV_HIGHLIGHTS = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_DEV}.highlights`;
export const VAULT_DEV_FEATURES = [
  `${VAULT_DEV_FEATURES_PREFIX}.vault-enterprise`,
  `${VAULT_DEV_FEATURES_PREFIX}.single-cluster-only`,
  `${VAULT_DEV_FEATURES_PREFIX}.monitoring`,
  `${VAULT_DEV_FEATURES_PREFIX}.num-included-client`,
];

// Starter Tier
export const VAULT_TIER_STARTER = 'hashicorp.vault.starter';
export const VAULT_TIER_STARTER_VALUE = 'STARTER';
export const VAULT_STARTER_FEATURES_PREFIX = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_STARTER}.features`;
export const VAULT_STARTER_HIGHLIGHTS = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_STARTER}.highlights`;
export const VAULT_STARTER_FEATURES = [
  `${VAULT_STARTER_FEATURES_PREFIX}.all-dev-features`,
  `${VAULT_STARTER_FEATURES_PREFIX}.three-node-cluster`,
  `${VAULT_STARTER_FEATURES_PREFIX}.backups-and-restore`,
  `${VAULT_STARTER_FEATURES_PREFIX}.audit-logs`,
  `${VAULT_STARTER_FEATURES_PREFIX}.num-included-client`,
];

// Standard Tier
export const VAULT_TIER_STANDARD = 'hashicorp.vault.standard';
export const VAULT_TIER_STANDARD_VALUE = 'STANDARD';
export const VAULT_STANDARD_FEATURES_PREFIX = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_STANDARD}.features`;
export const VAULT_STANDARD_HIGHLIGHTS = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_STANDARD}.highlights`;
export const VAULT_STANDARD_FEATURES = [
  `${VAULT_STANDARD_FEATURES_PREFIX}.all-dev-features`,
  `${VAULT_STANDARD_FEATURES_PREFIX}.enterprise-use-cases`,
  `${VAULT_STANDARD_FEATURES_PREFIX}.silver-support`,
  `${VAULT_STANDARD_FEATURES_PREFIX}.unlimited-clients`,
];

// Plus Tier
export const VAULT_TIER_PLUS = 'hashicorp.vault.plus';
export const VAULT_TIER_PLUS_VALUE = 'PLUS';
export const VAULT_PLUS_FEATURES_PREFIX = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_PLUS}.features`;
export const VAULT_PLUS_HIGHLIGHTS = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_PLUS}.highlights`;
export const VAULT_PLUS_FEATURES = [
  `${VAULT_PLUS_FEATURES_PREFIX}.all-standard-features`,
  `${VAULT_PLUS_FEATURES_PREFIX}.cross-region-replication`,
  `${VAULT_PLUS_FEATURES_PREFIX}.silver-support`,
  `${VAULT_PLUS_FEATURES_PREFIX}.unlimited-clients`,
];

// size info
export const VAULT_DEV_XS_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_DEV}.extra-small`;

export const VAULT_STARTER_SMALL_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_STARTER}.small`;

export const VAULT_STANDARD_SMALL_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_STANDARD}.small`;
export const VAULT_STANDARD_MEDIUM_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_STANDARD}.medium`;
export const VAULT_STANDARD_LARGE_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_STANDARD}.large`;

export const VAULT_PLUS_SMALL_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_PLUS}.small`;
export const VAULT_PLUS_MEDIUM_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_PLUS}.medium`;
export const VAULT_PLUS_LARGE_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_VAULT}.${VAULT_TIER_PLUS}.large`;

export const VAULT_DEV_XS_HIGHLIGHTS = `${VAULT_DEV_XS_PREFIX}.highlights`;
export const VAULT_DEV_XS_FEATURES = [
  `${VAULT_DEV_XS_PREFIX}.features.clients`,
  `${VAULT_DEV_XS_PREFIX}.features.limits`,
];

export const VAULT_STANDARD_SMALL_HIGHLIGHTS = `${VAULT_STANDARD_SMALL_PREFIX}.highlights`;
export const VAULT_STANDARD_SMALL_FEATURES = [
  `${VAULT_STANDARD_SMALL_PREFIX}.features.storage`,
  `${VAULT_STANDARD_SMALL_PREFIX}.features.snapshots`,
  `${VAULT_STANDARD_SMALL_PREFIX}.features.limits`,
];

export const VAULT_STANDARD_MEDIUM_HIGHLIGHTS = `${VAULT_STANDARD_MEDIUM_PREFIX}.highlights`;
export const VAULT_STANDARD_MEDIUM_FEATURES = [
  `${VAULT_STANDARD_MEDIUM_PREFIX}.features.storage`,
  `${VAULT_STANDARD_MEDIUM_PREFIX}.features.snapshots`,
];

export const VAULT_STANDARD_LARGE_HIGHLIGHTS = `${VAULT_STANDARD_LARGE_PREFIX}.highlights`;
export const VAULT_STANDARD_LARGE_FEATURES = [
  `${VAULT_STANDARD_LARGE_PREFIX}.features.storage`,
  `${VAULT_STANDARD_LARGE_PREFIX}.features.snapshots`,
];

export const VAULT_STARTER_SMALL_HIGHLIGHTS = `${VAULT_STARTER_SMALL_PREFIX}.highlights`;
export const VAULT_STARTER_SMALL_FEATURES = [
  `${VAULT_STARTER_SMALL_PREFIX}.features.storage`,
  `${VAULT_STARTER_SMALL_PREFIX}.features.snapshots`,
  `${VAULT_STARTER_SMALL_PREFIX}.features.clients`,
  `${VAULT_STARTER_SMALL_PREFIX}.features.limits`,
];

// PLUS SKU
export const VAULT_PLUS_SMALL_HIGHLIGHTS = `${VAULT_PLUS_SMALL_PREFIX}.highlights`;
export const VAULT_PLUS_SMALL_FEATURES = [
  `${VAULT_PLUS_SMALL_PREFIX}.features.storage`,
  `${VAULT_PLUS_SMALL_PREFIX}.features.snapshots`,
  `${VAULT_PLUS_SMALL_PREFIX}.features.clients`,
];
export const VAULT_PLUS_MEDIUM_HIGHLIGHTS = `${VAULT_PLUS_MEDIUM_PREFIX}.highlights`;
export const VAULT_PLUS_MEDIUM_FEATURES = [
  `${VAULT_PLUS_MEDIUM_PREFIX}.features.storage`,
  `${VAULT_PLUS_MEDIUM_PREFIX}.features.snapshots`,
  `${VAULT_PLUS_MEDIUM_PREFIX}.features.clients`,
];
export const VAULT_PLUS_LARGE_HIGHLIGHTS = `${VAULT_PLUS_LARGE_PREFIX}.highlights`;
export const VAULT_PLUS_LARGE_FEATURES = [
  `${VAULT_PLUS_LARGE_PREFIX}.features.storage`,
  `${VAULT_PLUS_LARGE_PREFIX}.features.snapshots`,
  `${VAULT_PLUS_LARGE_PREFIX}.features.clients`,
];

export const VAULT_RESOURCE_TIER_SIZE_XS_VALUE = 'EXTRA-SMALL';
export const VAULT_RESOURCE_TIER_SIZE_SM_VALUE = 'SMALL';
export const VAULT_RESOURCE_TIER_SIZE_MD_VALUE = 'MEDIUM';
export const VAULT_RESOURCE_TIER_SIZE_LG_VALUE = 'LARGE';

/**
 * This defines the mapping for available billing tiers for this resource. The
 *     data here enriches an API response with i18n strings which can be used
 *     for basic labels to dynamic pricing and with additional values used for
 *     rendering components in the front-end. More documentation can be found
 *     in `resource-catalog/services/resource-catalog`;
 */
export const RESOURCE_VAULT_TIER_MAPPING: Record<
  string,
  Record<string, ResourceTier>
> = {
  aws: {
    [VAULT_TIER_DEV]: {
      features: VAULT_DEV_FEATURES,
      highlights: VAULT_DEV_HIGHLIGHTS,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_XS,
          value: VAULT_RESOURCE_TIER_SIZE_XS_VALUE,
          features: VAULT_DEV_XS_FEATURES,
          highlights: VAULT_DEV_XS_HIGHLIGHTS,
        },
      ],
      value: VAULT_TIER_DEV_VALUE,
    },
    [VAULT_TIER_STARTER]: {
      features: VAULT_STARTER_FEATURES,
      highlights: VAULT_STARTER_HIGHLIGHTS,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_SM,
          value: VAULT_RESOURCE_TIER_SIZE_SM_VALUE,
          features: VAULT_STARTER_SMALL_FEATURES,
          highlights: VAULT_STARTER_SMALL_HIGHLIGHTS,
        },
      ],
      value: VAULT_TIER_STARTER_VALUE,
    },
    [VAULT_TIER_STANDARD]: {
      features: VAULT_STANDARD_FEATURES,
      highlights: VAULT_STANDARD_HIGHLIGHTS,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_SM,
          value: VAULT_RESOURCE_TIER_SIZE_SM_VALUE,
          features: VAULT_STANDARD_SMALL_FEATURES,
          highlights: VAULT_STANDARD_SMALL_HIGHLIGHTS,
        },
        {
          key: RESOURCE_TIER_SIZE_MD,
          value: VAULT_RESOURCE_TIER_SIZE_MD_VALUE,
          features: VAULT_STANDARD_MEDIUM_FEATURES,
          highlights: VAULT_STANDARD_MEDIUM_HIGHLIGHTS,
        },
        {
          key: RESOURCE_TIER_SIZE_LG,
          value: VAULT_RESOURCE_TIER_SIZE_LG_VALUE,
          features: VAULT_STANDARD_LARGE_FEATURES,
          highlights: VAULT_STANDARD_LARGE_HIGHLIGHTS,
        },
      ],
      value: VAULT_TIER_STANDARD_VALUE,
    },
    [VAULT_TIER_PLUS]: {
      features: VAULT_PLUS_FEATURES,
      highlights: VAULT_PLUS_HIGHLIGHTS,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_SM,
          value: VAULT_RESOURCE_TIER_SIZE_SM_VALUE,
          features: VAULT_PLUS_SMALL_FEATURES,
          highlights: VAULT_PLUS_SMALL_HIGHLIGHTS,
        },
        {
          key: RESOURCE_TIER_SIZE_MD,
          value: VAULT_RESOURCE_TIER_SIZE_MD_VALUE,
          features: VAULT_PLUS_MEDIUM_FEATURES,
          highlights: VAULT_PLUS_MEDIUM_HIGHLIGHTS,
        },
        {
          key: RESOURCE_TIER_SIZE_LG,
          value: VAULT_RESOURCE_TIER_SIZE_LG_VALUE,
          features: VAULT_PLUS_LARGE_FEATURES,
          highlights: VAULT_PLUS_LARGE_HIGHLIGHTS,
        },
      ],
      value: VAULT_TIER_PLUS_VALUE,
    },
  },
  azure: {
    [VAULT_TIER_DEV]: {
      features: VAULT_DEV_FEATURES,
      highlights: VAULT_DEV_HIGHLIGHTS,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_XS,
          value: VAULT_RESOURCE_TIER_SIZE_XS_VALUE,
          features: VAULT_DEV_XS_FEATURES,
          highlights: VAULT_DEV_XS_HIGHLIGHTS,
        },
      ],
      value: VAULT_TIER_DEV_VALUE,
    },
    [VAULT_TIER_STARTER]: {
      features: VAULT_STARTER_FEATURES,
      highlights: VAULT_STARTER_HIGHLIGHTS,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_SM,
          value: VAULT_RESOURCE_TIER_SIZE_SM_VALUE,
          features: VAULT_STARTER_SMALL_FEATURES,
          highlights: VAULT_STARTER_SMALL_HIGHLIGHTS,
        },
      ],
      value: VAULT_TIER_STARTER_VALUE,
    },
    [VAULT_TIER_STANDARD]: {
      features: VAULT_STANDARD_FEATURES,
      highlights: VAULT_STANDARD_HIGHLIGHTS,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_SM,
          value: VAULT_RESOURCE_TIER_SIZE_SM_VALUE,
          features: VAULT_STANDARD_SMALL_FEATURES,
          highlights: VAULT_STANDARD_SMALL_HIGHLIGHTS,
        },
        {
          key: RESOURCE_TIER_SIZE_MD,
          value: VAULT_RESOURCE_TIER_SIZE_MD_VALUE,
          features: VAULT_STANDARD_MEDIUM_FEATURES,
          highlights: VAULT_STANDARD_MEDIUM_HIGHLIGHTS,
        },
        {
          key: RESOURCE_TIER_SIZE_LG,
          value: VAULT_RESOURCE_TIER_SIZE_LG_VALUE,
          features: VAULT_STANDARD_LARGE_FEATURES,
          highlights: VAULT_STANDARD_LARGE_HIGHLIGHTS,
        },
      ],
      value: VAULT_TIER_STANDARD_VALUE,
    },
    [VAULT_TIER_PLUS]: {
      features: VAULT_PLUS_FEATURES,
      highlights: VAULT_PLUS_HIGHLIGHTS,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_SM,
          value: VAULT_RESOURCE_TIER_SIZE_SM_VALUE,
          features: VAULT_PLUS_SMALL_FEATURES,
          highlights: VAULT_PLUS_SMALL_HIGHLIGHTS,
        },
        {
          key: RESOURCE_TIER_SIZE_MD,
          value: VAULT_RESOURCE_TIER_SIZE_MD_VALUE,
          features: VAULT_PLUS_MEDIUM_FEATURES,
          highlights: VAULT_PLUS_MEDIUM_HIGHLIGHTS,
        },
        {
          key: RESOURCE_TIER_SIZE_LG,
          value: VAULT_RESOURCE_TIER_SIZE_LG_VALUE,
          features: VAULT_PLUS_LARGE_FEATURES,
          highlights: VAULT_PLUS_LARGE_HIGHLIGHTS,
        },
      ],
      value: VAULT_TIER_PLUS_VALUE,
    },
  },
};

export const VAULT_RESOURCE: ResourceCatalog = {
  icon: 'vault',
  id: TYPE_VAULT,
  tiers: RESOURCE_VAULT_TIER_MAPPING,
};
