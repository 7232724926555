/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudPacker20221202BucketAncestryParentChannelIterationFromJSON(json) {
    return HashicorpCloudPacker20221202BucketAncestryParentChannelIterationFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202BucketAncestryParentChannelIterationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'incrementalVersion': !exists(json, 'incremental_version') ? undefined : json['incremental_version'],
        'fingerprint': !exists(json, 'fingerprint') ? undefined : json['fingerprint'],
    };
}
export function HashicorpCloudPacker20221202BucketAncestryParentChannelIterationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'incremental_version': value.incrementalVersion,
        'fingerprint': value.fingerprint,
    };
}
