/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudIamCreateOrganizationServicePrincipalKeyResponseFromJSON, HashicorpCloudIamCreateOrganizationServicePrincipalResponseFromJSON, HashicorpCloudIamCreateProjectServicePrincipalKeyResponseFromJSON, HashicorpCloudIamCreateProjectServicePrincipalResponseFromJSON, HashicorpCloudIamCreateServicePrincipalKeyResponseFromJSON, HashicorpCloudIamCreateServicePrincipalResponseFromJSON, HashicorpCloudIamCreateWorkloadIdentityProviderResponseFromJSON, HashicorpCloudIamDeleteWorkloadIdentityProviderResponseFromJSON, HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenToJSON, HashicorpCloudIamExchangeWorkloadIdentityTokenResponseFromJSON, HashicorpCloudIamGetOrganizationServicePrincipalResponseFromJSON, HashicorpCloudIamGetProjectServicePrincipalResponseFromJSON, HashicorpCloudIamGetServicePrincipalResponseFromJSON, HashicorpCloudIamGetWorkloadIdentityProviderResponseFromJSON, HashicorpCloudIamListOrganizationServicePrincipalsResponseFromJSON, HashicorpCloudIamListProjectServicePrincipalsResponseFromJSON, HashicorpCloudIamListServicePrincipalsResponseFromJSON, HashicorpCloudIamListWorkloadIdentityProviderResponseFromJSON, HashicorpCloudIamUpdateWorkloadIdentityProviderResponseFromJSON, InlineObjectToJSON, InlineObject1ToJSON, InlineObject10ToJSON, InlineObject11ToJSON, InlineObject2ToJSON, InlineObject8ToJSON, InlineObject9ToJSON, ProviderIsTheWorkloadIdentityProviderToUpdateToJSON, } from '../models';
/**
 *
 */
export class ServicePrincipalsServiceApi extends runtime.BaseAPI {
    /**
     * CreateOrganizationServicePrincipal creates a new service principal on organization level.
     */
    async servicePrincipalsServiceCreateOrganizationServicePrincipalRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling servicePrincipalsServiceCreateOrganizationServicePrincipal.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling servicePrincipalsServiceCreateOrganizationServicePrincipal.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/service-principals`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject11ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCreateOrganizationServicePrincipalResponseFromJSON(jsonValue));
    }
    /**
     * CreateOrganizationServicePrincipal creates a new service principal on organization level.
     */
    async servicePrincipalsServiceCreateOrganizationServicePrincipal(organizationId, body) {
        const response = await this.servicePrincipalsServiceCreateOrganizationServicePrincipalRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * CreateOrganizationServicePrincipalKey creates a new service principal key for a service principal on organization level.
     */
    async servicePrincipalsServiceCreateOrganizationServicePrincipalKeyRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling servicePrincipalsServiceCreateOrganizationServicePrincipalKey.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling servicePrincipalsServiceCreateOrganizationServicePrincipalKey.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/service-principal-keys`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject10ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCreateOrganizationServicePrincipalKeyResponseFromJSON(jsonValue));
    }
    /**
     * CreateOrganizationServicePrincipalKey creates a new service principal key for a service principal on organization level.
     */
    async servicePrincipalsServiceCreateOrganizationServicePrincipalKey(organizationId, body) {
        const response = await this.servicePrincipalsServiceCreateOrganizationServicePrincipalKeyRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * CreateProjectServicePrincipal creates a new service principal on project level.
     */
    async servicePrincipalsServiceCreateProjectServicePrincipalRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling servicePrincipalsServiceCreateProjectServicePrincipal.');
        }
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling servicePrincipalsServiceCreateProjectServicePrincipal.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling servicePrincipalsServiceCreateProjectServicePrincipal.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/projects/{project_id}/service-principals`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject9ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCreateProjectServicePrincipalResponseFromJSON(jsonValue));
    }
    /**
     * CreateProjectServicePrincipal creates a new service principal on project level.
     */
    async servicePrincipalsServiceCreateProjectServicePrincipal(organizationId, projectId, body) {
        const response = await this.servicePrincipalsServiceCreateProjectServicePrincipalRaw({ organizationId: organizationId, projectId: projectId, body: body });
        return await response.value();
    }
    /**
     * CreateProjectServicePrincipalKey creates a new service principal key for a service principal on project level.
     */
    async servicePrincipalsServiceCreateProjectServicePrincipalKeyRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling servicePrincipalsServiceCreateProjectServicePrincipalKey.');
        }
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling servicePrincipalsServiceCreateProjectServicePrincipalKey.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling servicePrincipalsServiceCreateProjectServicePrincipalKey.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/projects/{project_id}/service-principal-keys`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject8ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCreateProjectServicePrincipalKeyResponseFromJSON(jsonValue));
    }
    /**
     * CreateProjectServicePrincipalKey creates a new service principal key for a service principal on project level.
     */
    async servicePrincipalsServiceCreateProjectServicePrincipalKey(organizationId, projectId, body) {
        const response = await this.servicePrincipalsServiceCreateProjectServicePrincipalKeyRaw({ organizationId: organizationId, projectId: projectId, body: body });
        return await response.value();
    }
    /**
     * CreateServicePrincipal creates a new service principal.
     */
    async servicePrincipalsServiceCreateServicePrincipalRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling servicePrincipalsServiceCreateServicePrincipal.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling servicePrincipalsServiceCreateServicePrincipal.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/iam/{parent_resource_name}/service-principals`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject1ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCreateServicePrincipalResponseFromJSON(jsonValue));
    }
    /**
     * CreateServicePrincipal creates a new service principal.
     */
    async servicePrincipalsServiceCreateServicePrincipal(parentResourceName, body) {
        const response = await this.servicePrincipalsServiceCreateServicePrincipalRaw({ parentResourceName: parentResourceName, body: body });
        return await response.value();
    }
    /**
     * CreateServicePrincipal creates a new service principal.
     */
    async servicePrincipalsServiceCreateServicePrincipal2Raw(requestParameters) {
        if (requestParameters.parentResourceName1 === null || requestParameters.parentResourceName1 === undefined) {
            throw new runtime.RequiredError('parentResourceName1', 'Required parameter requestParameters.parentResourceName1 was null or undefined when calling servicePrincipalsServiceCreateServicePrincipal2.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling servicePrincipalsServiceCreateServicePrincipal2.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/iam/{parent_resource_name_1}/service-principals`.replace(`{${"parent_resource_name_1"}}`, encodeURIComponent(String(requestParameters.parentResourceName1))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCreateServicePrincipalResponseFromJSON(jsonValue));
    }
    /**
     * CreateServicePrincipal creates a new service principal.
     */
    async servicePrincipalsServiceCreateServicePrincipal2(parentResourceName1, body) {
        const response = await this.servicePrincipalsServiceCreateServicePrincipal2Raw({ parentResourceName1: parentResourceName1, body: body });
        return await response.value();
    }
    /**
     * CreateServicePrincipalKey creates a new service principal key for a service principal.
     */
    async servicePrincipalsServiceCreateServicePrincipalKeyRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling servicePrincipalsServiceCreateServicePrincipalKey.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling servicePrincipalsServiceCreateServicePrincipalKey.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{parent_resource_name}/keys`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCreateServicePrincipalKeyResponseFromJSON(jsonValue));
    }
    /**
     * CreateServicePrincipalKey creates a new service principal key for a service principal.
     */
    async servicePrincipalsServiceCreateServicePrincipalKey(parentResourceName, body) {
        const response = await this.servicePrincipalsServiceCreateServicePrincipalKeyRaw({ parentResourceName: parentResourceName, body: body });
        return await response.value();
    }
    /**
     * CreateServicePrincipalKey creates a new service principal key for a service principal.
     */
    async servicePrincipalsServiceCreateServicePrincipalKey2Raw(requestParameters) {
        if (requestParameters.parentResourceName1 === null || requestParameters.parentResourceName1 === undefined) {
            throw new runtime.RequiredError('parentResourceName1', 'Required parameter requestParameters.parentResourceName1 was null or undefined when calling servicePrincipalsServiceCreateServicePrincipalKey2.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling servicePrincipalsServiceCreateServicePrincipalKey2.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{parent_resource_name_1}/keys`.replace(`{${"parent_resource_name_1"}}`, encodeURIComponent(String(requestParameters.parentResourceName1))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCreateServicePrincipalKeyResponseFromJSON(jsonValue));
    }
    /**
     * CreateServicePrincipalKey creates a new service principal key for a service principal.
     */
    async servicePrincipalsServiceCreateServicePrincipalKey2(parentResourceName1, body) {
        const response = await this.servicePrincipalsServiceCreateServicePrincipalKey2Raw({ parentResourceName1: parentResourceName1, body: body });
        return await response.value();
    }
    /**
     * CreateWorkloadIdentityProvider creates a workload identity provider nested under a service principal.
     */
    async servicePrincipalsServiceCreateWorkloadIdentityProviderRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling servicePrincipalsServiceCreateWorkloadIdentityProvider.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling servicePrincipalsServiceCreateWorkloadIdentityProvider.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{parent_resource_name}/workload-identity-providers`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject2ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCreateWorkloadIdentityProviderResponseFromJSON(jsonValue));
    }
    /**
     * CreateWorkloadIdentityProvider creates a workload identity provider nested under a service principal.
     */
    async servicePrincipalsServiceCreateWorkloadIdentityProvider(parentResourceName, body) {
        const response = await this.servicePrincipalsServiceCreateWorkloadIdentityProviderRaw({ parentResourceName: parentResourceName, body: body });
        return await response.value();
    }
    /**
     * DeleteOrganizationServicePrincipal deletes a service principal on organization level.
     */
    async servicePrincipalsServiceDeleteOrganizationServicePrincipalRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling servicePrincipalsServiceDeleteOrganizationServicePrincipal.');
        }
        if (requestParameters.principalId === null || requestParameters.principalId === undefined) {
            throw new runtime.RequiredError('principalId', 'Required parameter requestParameters.principalId was null or undefined when calling servicePrincipalsServiceDeleteOrganizationServicePrincipal.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/service-principals/{principal_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"principal_id"}}`, encodeURIComponent(String(requestParameters.principalId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteOrganizationServicePrincipal deletes a service principal on organization level.
     */
    async servicePrincipalsServiceDeleteOrganizationServicePrincipal(organizationId, principalId) {
        const response = await this.servicePrincipalsServiceDeleteOrganizationServicePrincipalRaw({ organizationId: organizationId, principalId: principalId });
        return await response.value();
    }
    /**
     * DeleteOrganizationServicePrincipalKey deletes a service principal key for a service principal on organization level.
     */
    async servicePrincipalsServiceDeleteOrganizationServicePrincipalKeyRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling servicePrincipalsServiceDeleteOrganizationServicePrincipalKey.');
        }
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling servicePrincipalsServiceDeleteOrganizationServicePrincipalKey.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/service-principal-keys/{client_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteOrganizationServicePrincipalKey deletes a service principal key for a service principal on organization level.
     */
    async servicePrincipalsServiceDeleteOrganizationServicePrincipalKey(organizationId, clientId) {
        const response = await this.servicePrincipalsServiceDeleteOrganizationServicePrincipalKeyRaw({ organizationId: organizationId, clientId: clientId });
        return await response.value();
    }
    /**
     * DeleteServicePrincipal deletes a service principal on project level.
     */
    async servicePrincipalsServiceDeleteProjectServicePrincipalRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling servicePrincipalsServiceDeleteProjectServicePrincipal.');
        }
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling servicePrincipalsServiceDeleteProjectServicePrincipal.');
        }
        if (requestParameters.principalId === null || requestParameters.principalId === undefined) {
            throw new runtime.RequiredError('principalId', 'Required parameter requestParameters.principalId was null or undefined when calling servicePrincipalsServiceDeleteProjectServicePrincipal.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/projects/{project_id}/service-principals/{principal_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"principal_id"}}`, encodeURIComponent(String(requestParameters.principalId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteServicePrincipal deletes a service principal on project level.
     */
    async servicePrincipalsServiceDeleteProjectServicePrincipal(organizationId, projectId, principalId) {
        const response = await this.servicePrincipalsServiceDeleteProjectServicePrincipalRaw({ organizationId: organizationId, projectId: projectId, principalId: principalId });
        return await response.value();
    }
    /**
     * DeleteProjectServicePrincipalKey deletes a service principal key for a service principal on project level.
     */
    async servicePrincipalsServiceDeleteProjectServicePrincipalKeyRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling servicePrincipalsServiceDeleteProjectServicePrincipalKey.');
        }
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling servicePrincipalsServiceDeleteProjectServicePrincipalKey.');
        }
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling servicePrincipalsServiceDeleteProjectServicePrincipalKey.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/projects/{project_id}/service-principal-keys/{client_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"client_id"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteProjectServicePrincipalKey deletes a service principal key for a service principal on project level.
     */
    async servicePrincipalsServiceDeleteProjectServicePrincipalKey(organizationId, projectId, clientId) {
        const response = await this.servicePrincipalsServiceDeleteProjectServicePrincipalKeyRaw({ organizationId: organizationId, projectId: projectId, clientId: clientId });
        return await response.value();
    }
    /**
     * DeleteServicePrincipal deletes a service principal.
     */
    async servicePrincipalsServiceDeleteServicePrincipalRaw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling servicePrincipalsServiceDeleteServicePrincipal.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{resource_name}`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteServicePrincipal deletes a service principal.
     */
    async servicePrincipalsServiceDeleteServicePrincipal(resourceName) {
        const response = await this.servicePrincipalsServiceDeleteServicePrincipalRaw({ resourceName: resourceName });
        return await response.value();
    }
    /**
     * DeleteServicePrincipal deletes a service principal.
     */
    async servicePrincipalsServiceDeleteServicePrincipal2Raw(requestParameters) {
        if (requestParameters.resourceName1 === null || requestParameters.resourceName1 === undefined) {
            throw new runtime.RequiredError('resourceName1', 'Required parameter requestParameters.resourceName1 was null or undefined when calling servicePrincipalsServiceDeleteServicePrincipal2.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{resource_name_1}`.replace(`{${"resource_name_1"}}`, encodeURIComponent(String(requestParameters.resourceName1))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteServicePrincipal deletes a service principal.
     */
    async servicePrincipalsServiceDeleteServicePrincipal2(resourceName1) {
        const response = await this.servicePrincipalsServiceDeleteServicePrincipal2Raw({ resourceName1: resourceName1 });
        return await response.value();
    }
    /**
     * DeleteServicePrincipalKey deletes a service principal key.
     */
    async servicePrincipalsServiceDeleteServicePrincipalKeyRaw(requestParameters) {
        if (requestParameters.resourceName2 === null || requestParameters.resourceName2 === undefined) {
            throw new runtime.RequiredError('resourceName2', 'Required parameter requestParameters.resourceName2 was null or undefined when calling servicePrincipalsServiceDeleteServicePrincipalKey.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{resource_name_2}`.replace(`{${"resource_name_2"}}`, encodeURIComponent(String(requestParameters.resourceName2))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteServicePrincipalKey deletes a service principal key.
     */
    async servicePrincipalsServiceDeleteServicePrincipalKey(resourceName2) {
        const response = await this.servicePrincipalsServiceDeleteServicePrincipalKeyRaw({ resourceName2: resourceName2 });
        return await response.value();
    }
    /**
     * DeleteServicePrincipalKey deletes a service principal key.
     */
    async servicePrincipalsServiceDeleteServicePrincipalKey2Raw(requestParameters) {
        if (requestParameters.resourceName3 === null || requestParameters.resourceName3 === undefined) {
            throw new runtime.RequiredError('resourceName3', 'Required parameter requestParameters.resourceName3 was null or undefined when calling servicePrincipalsServiceDeleteServicePrincipalKey2.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{resource_name_3}`.replace(`{${"resource_name_3"}}`, encodeURIComponent(String(requestParameters.resourceName3))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteServicePrincipalKey deletes a service principal key.
     */
    async servicePrincipalsServiceDeleteServicePrincipalKey2(resourceName3) {
        const response = await this.servicePrincipalsServiceDeleteServicePrincipalKey2Raw({ resourceName3: resourceName3 });
        return await response.value();
    }
    /**
     * DeleteWorkloadIdentityProvider deletes a workload identity provider.
     */
    async servicePrincipalsServiceDeleteWorkloadIdentityProviderRaw(requestParameters) {
        if (requestParameters.resourceName4 === null || requestParameters.resourceName4 === undefined) {
            throw new runtime.RequiredError('resourceName4', 'Required parameter requestParameters.resourceName4 was null or undefined when calling servicePrincipalsServiceDeleteWorkloadIdentityProvider.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{resource_name_4}`.replace(`{${"resource_name_4"}}`, encodeURIComponent(String(requestParameters.resourceName4))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamDeleteWorkloadIdentityProviderResponseFromJSON(jsonValue));
    }
    /**
     * DeleteWorkloadIdentityProvider deletes a workload identity provider.
     */
    async servicePrincipalsServiceDeleteWorkloadIdentityProvider(resourceName4) {
        const response = await this.servicePrincipalsServiceDeleteWorkloadIdentityProviderRaw({ resourceName4: resourceName4 });
        return await response.value();
    }
    /**
     * ExchangeWorkloadIdentityToken exchanges an external workload identity token for an access token whose subject is that of the service principal the identity federation is nested under.
     */
    async servicePrincipalsServiceExchangeWorkloadIdentityTokenRaw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling servicePrincipalsServiceExchangeWorkloadIdentityToken.');
        }
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token', 'Required parameter requestParameters.token was null or undefined when calling servicePrincipalsServiceExchangeWorkloadIdentityToken.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{resource_name}/exchange-token`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenToJSON(requestParameters.token),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamExchangeWorkloadIdentityTokenResponseFromJSON(jsonValue));
    }
    /**
     * ExchangeWorkloadIdentityToken exchanges an external workload identity token for an access token whose subject is that of the service principal the identity federation is nested under.
     */
    async servicePrincipalsServiceExchangeWorkloadIdentityToken(resourceName, token) {
        const response = await this.servicePrincipalsServiceExchangeWorkloadIdentityTokenRaw({ resourceName: resourceName, token: token });
        return await response.value();
    }
    /**
     * GetOrganizationServicePrincipal retrieves a service principal on organization level.
     */
    async servicePrincipalsServiceGetOrganizationServicePrincipalRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling servicePrincipalsServiceGetOrganizationServicePrincipal.');
        }
        if (requestParameters.principalId === null || requestParameters.principalId === undefined) {
            throw new runtime.RequiredError('principalId', 'Required parameter requestParameters.principalId was null or undefined when calling servicePrincipalsServiceGetOrganizationServicePrincipal.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/service-principals/{principal_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"principal_id"}}`, encodeURIComponent(String(requestParameters.principalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetOrganizationServicePrincipalResponseFromJSON(jsonValue));
    }
    /**
     * GetOrganizationServicePrincipal retrieves a service principal on organization level.
     */
    async servicePrincipalsServiceGetOrganizationServicePrincipal(organizationId, principalId) {
        const response = await this.servicePrincipalsServiceGetOrganizationServicePrincipalRaw({ organizationId: organizationId, principalId: principalId });
        return await response.value();
    }
    /**
     * GetProjectServicePrincipal retrieves a service principal on project level.
     */
    async servicePrincipalsServiceGetProjectServicePrincipalRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling servicePrincipalsServiceGetProjectServicePrincipal.');
        }
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling servicePrincipalsServiceGetProjectServicePrincipal.');
        }
        if (requestParameters.principalId === null || requestParameters.principalId === undefined) {
            throw new runtime.RequiredError('principalId', 'Required parameter requestParameters.principalId was null or undefined when calling servicePrincipalsServiceGetProjectServicePrincipal.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/projects/{project_id}/service-principals/{principal_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"principal_id"}}`, encodeURIComponent(String(requestParameters.principalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetProjectServicePrincipalResponseFromJSON(jsonValue));
    }
    /**
     * GetProjectServicePrincipal retrieves a service principal on project level.
     */
    async servicePrincipalsServiceGetProjectServicePrincipal(organizationId, projectId, principalId) {
        const response = await this.servicePrincipalsServiceGetProjectServicePrincipalRaw({ organizationId: organizationId, projectId: projectId, principalId: principalId });
        return await response.value();
    }
    /**
     * GetServicePrincipal retrieves a service principal.
     */
    async servicePrincipalsServiceGetServicePrincipalRaw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling servicePrincipalsServiceGetServicePrincipal.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{resource_name}`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetServicePrincipalResponseFromJSON(jsonValue));
    }
    /**
     * GetServicePrincipal retrieves a service principal.
     */
    async servicePrincipalsServiceGetServicePrincipal(resourceName) {
        const response = await this.servicePrincipalsServiceGetServicePrincipalRaw({ resourceName: resourceName });
        return await response.value();
    }
    /**
     * GetServicePrincipal retrieves a service principal.
     */
    async servicePrincipalsServiceGetServicePrincipal2Raw(requestParameters) {
        if (requestParameters.resourceName1 === null || requestParameters.resourceName1 === undefined) {
            throw new runtime.RequiredError('resourceName1', 'Required parameter requestParameters.resourceName1 was null or undefined when calling servicePrincipalsServiceGetServicePrincipal2.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{resource_name_1}`.replace(`{${"resource_name_1"}}`, encodeURIComponent(String(requestParameters.resourceName1))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetServicePrincipalResponseFromJSON(jsonValue));
    }
    /**
     * GetServicePrincipal retrieves a service principal.
     */
    async servicePrincipalsServiceGetServicePrincipal2(resourceName1) {
        const response = await this.servicePrincipalsServiceGetServicePrincipal2Raw({ resourceName1: resourceName1 });
        return await response.value();
    }
    /**
     * GetWorkloadIdentityProvider retrieves a workload identity provider.
     */
    async servicePrincipalsServiceGetWorkloadIdentityProviderRaw(requestParameters) {
        if (requestParameters.resourceName2 === null || requestParameters.resourceName2 === undefined) {
            throw new runtime.RequiredError('resourceName2', 'Required parameter requestParameters.resourceName2 was null or undefined when calling servicePrincipalsServiceGetWorkloadIdentityProvider.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{resource_name_2}`.replace(`{${"resource_name_2"}}`, encodeURIComponent(String(requestParameters.resourceName2))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetWorkloadIdentityProviderResponseFromJSON(jsonValue));
    }
    /**
     * GetWorkloadIdentityProvider retrieves a workload identity provider.
     */
    async servicePrincipalsServiceGetWorkloadIdentityProvider(resourceName2) {
        const response = await this.servicePrincipalsServiceGetWorkloadIdentityProviderRaw({ resourceName2: resourceName2 });
        return await response.value();
    }
    /**
     * ListProjectServicePrincipals lists service principals in an organization.
     */
    async servicePrincipalsServiceListOrganizationServicePrincipalsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling servicePrincipalsServiceListOrganizationServicePrincipals.');
        }
        const queryParameters = {};
        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/service-principals`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamListOrganizationServicePrincipalsResponseFromJSON(jsonValue));
    }
    /**
     * ListProjectServicePrincipals lists service principals in an organization.
     */
    async servicePrincipalsServiceListOrganizationServicePrincipals(organizationId, include, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.servicePrincipalsServiceListOrganizationServicePrincipalsRaw({ organizationId: organizationId, include: include, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListProjectServicePrincipals lists service principals in a project.
     */
    async servicePrincipalsServiceListProjectServicePrincipalsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling servicePrincipalsServiceListProjectServicePrincipals.');
        }
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling servicePrincipalsServiceListProjectServicePrincipals.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/projects/{project_id}/service-principals`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"project_id"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamListProjectServicePrincipalsResponseFromJSON(jsonValue));
    }
    /**
     * ListProjectServicePrincipals lists service principals in a project.
     */
    async servicePrincipalsServiceListProjectServicePrincipals(organizationId, projectId, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.servicePrincipalsServiceListProjectServicePrincipalsRaw({ organizationId: organizationId, projectId: projectId, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListServicePrincipals lists service principals at a given scope.
     */
    async servicePrincipalsServiceListServicePrincipalsRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling servicePrincipalsServiceListServicePrincipals.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/iam/{parent_resource_name}/service-principals`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamListServicePrincipalsResponseFromJSON(jsonValue));
    }
    /**
     * ListServicePrincipals lists service principals at a given scope.
     */
    async servicePrincipalsServiceListServicePrincipals(parentResourceName, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.servicePrincipalsServiceListServicePrincipalsRaw({ parentResourceName: parentResourceName, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListServicePrincipals lists service principals at a given scope.
     */
    async servicePrincipalsServiceListServicePrincipals2Raw(requestParameters) {
        if (requestParameters.parentResourceName1 === null || requestParameters.parentResourceName1 === undefined) {
            throw new runtime.RequiredError('parentResourceName1', 'Required parameter requestParameters.parentResourceName1 was null or undefined when calling servicePrincipalsServiceListServicePrincipals2.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/iam/{parent_resource_name_1}/service-principals`.replace(`{${"parent_resource_name_1"}}`, encodeURIComponent(String(requestParameters.parentResourceName1))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamListServicePrincipalsResponseFromJSON(jsonValue));
    }
    /**
     * ListServicePrincipals lists service principals at a given scope.
     */
    async servicePrincipalsServiceListServicePrincipals2(parentResourceName1, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.servicePrincipalsServiceListServicePrincipals2Raw({ parentResourceName1: parentResourceName1, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListWorkloadIdentityProvider lists workload identity providers under a service principal.
     */
    async servicePrincipalsServiceListWorkloadIdentityProviderRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling servicePrincipalsServiceListWorkloadIdentityProvider.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{parent_resource_name}/workload-identity-providers`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamListWorkloadIdentityProviderResponseFromJSON(jsonValue));
    }
    /**
     * ListWorkloadIdentityProvider lists workload identity providers under a service principal.
     */
    async servicePrincipalsServiceListWorkloadIdentityProvider(parentResourceName, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.servicePrincipalsServiceListWorkloadIdentityProviderRaw({ parentResourceName: parentResourceName, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * UpdateWorkloadIdentityProvider updates a workload identity provider.
     */
    async servicePrincipalsServiceUpdateWorkloadIdentityProviderRaw(requestParameters) {
        if (requestParameters.providerResourceName === null || requestParameters.providerResourceName === undefined) {
            throw new runtime.RequiredError('providerResourceName', 'Required parameter requestParameters.providerResourceName was null or undefined when calling servicePrincipalsServiceUpdateWorkloadIdentityProvider.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling servicePrincipalsServiceUpdateWorkloadIdentityProvider.');
        }
        const queryParameters = {};
        if (requestParameters.updateMask !== undefined) {
            queryParameters['update_mask'] = requestParameters.updateMask;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/{provider.resource_name}`.replace(`{${"provider.resource_name"}}`, encodeURIComponent(String(requestParameters.providerResourceName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProviderIsTheWorkloadIdentityProviderToUpdateToJSON(requestParameters.provider),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamUpdateWorkloadIdentityProviderResponseFromJSON(jsonValue));
    }
    /**
     * UpdateWorkloadIdentityProvider updates a workload identity provider.
     */
    async servicePrincipalsServiceUpdateWorkloadIdentityProvider(providerResourceName, provider, updateMask) {
        const response = await this.servicePrincipalsServiceUpdateWorkloadIdentityProviderRaw({ providerResourceName: providerResourceName, provider: provider, updateMask: updateMask });
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var ServicePrincipalsServiceListOrganizationServicePrincipalsIncludeEnum;
(function (ServicePrincipalsServiceListOrganizationServicePrincipalsIncludeEnum) {
    ServicePrincipalsServiceListOrganizationServicePrincipalsIncludeEnum["UNSET"] = "UNSET";
    ServicePrincipalsServiceListOrganizationServicePrincipalsIncludeEnum["ALL"] = "ALL";
    ServicePrincipalsServiceListOrganizationServicePrincipalsIncludeEnum["PROJECTS"] = "PROJECTS";
})(ServicePrincipalsServiceListOrganizationServicePrincipalsIncludeEnum || (ServicePrincipalsServiceListOrganizationServicePrincipalsIncludeEnum = {}));
