/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105ConsumptionPoolFromJSON, Billing20201105ConsumptionPoolToJSON, Billing20201105ConsumptionPoolsSummaryFromJSON, Billing20201105ConsumptionPoolsSummaryToJSON, } from './';
export function Billing20201105ListConsumptionPoolsResponseFromJSON(json) {
    return Billing20201105ListConsumptionPoolsResponseFromJSONTyped(json, false);
}
export function Billing20201105ListConsumptionPoolsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'consumptionPools': !exists(json, 'consumption_pools') ? undefined : (json['consumption_pools'].map(Billing20201105ConsumptionPoolFromJSON)),
        'summary': !exists(json, 'summary') ? undefined : Billing20201105ConsumptionPoolsSummaryFromJSON(json['summary']),
    };
}
export function Billing20201105ListConsumptionPoolsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'consumption_pools': value.consumptionPools === undefined ? undefined : (value.consumptionPools.map(Billing20201105ConsumptionPoolToJSON)),
        'summary': Billing20201105ConsumptionPoolsSummaryToJSON(value.summary),
    };
}
