import { action } from '@ember/object';
import Component from '@glimmer/component';
import type { TaskForTaskFunction, TaskFunction } from 'ember-concurrency';

interface SplitListLeftPaneListItemSignature<ListItemType, DetailType> {
  Args: {
    item: ListItemType;
    onSelectItem: TaskForTaskFunction<TaskFunction<DetailType, [ListItemType]>>;
    identifyListItem: (listItem: ListItemType) => string;
    selectedItemIdentifier: string | null;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLLIElement;
}

/**
 *
 * `SplitListLeftPaneListItem` allows the consumer to provide their own yielded content for a list item
 * in the left pane of the `SplitList`.
 *
 * The parent SplitList components provide all the necessary args to this component except for `item`, which
 * must be provided by the consumer for each `ListItem`.
 *
 * ```
 * <SplitList as |SL>
 *   <SL.LeftPane as |LP|>
 *     {{#each LP.ListData as |listItem|}}
 *       <LP.ListItem @item={{listItem}}>
 *         Consumer list item content here
 *       </LP.ListItem>
 *     {{/each}}
 *   </SL.LeftPane>
 * </SplitList>
 * ```
 *
 * @class SplitListLeftPaneListItem
 *
 */

export default class SplitListLeftPaneListItemComponent<
  ListItemType,
  DetailType,
> extends Component<
  SplitListLeftPaneListItemSignature<ListItemType, DetailType>
> {
  get itemIdentifier() {
    const { item, identifyListItem } = this.args;

    return typeof identifyListItem === 'function' && identifyListItem?.(item);
  }

  get isSelected() {
    const { selectedItemIdentifier } = this.args;
    return (
      selectedItemIdentifier && this.itemIdentifier === selectedItemIdentifier
    );
  }

  @action
  onKeyUp(e: KeyboardEvent) {
    // List items are selectable via the Enter or Space keys, in addition to click
    if (!this.isSelected && (e.key === 'Enter' || e.key === ' ')) {
      const { item, onSelectItem } = this.args;
      onSelectItem.perform(item);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SplitList::LeftPane::ListItem': typeof SplitListLeftPaneListItemComponent;
    'split-list/left-pane/list-item': typeof SplitListLeftPaneListItemComponent;
  }
}
