/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudNetwork20200907PeeringState;
(function (HashicorpCloudNetwork20200907PeeringState) {
    HashicorpCloudNetwork20200907PeeringState["UNSET"] = "UNSET";
    HashicorpCloudNetwork20200907PeeringState["CREATING"] = "CREATING";
    HashicorpCloudNetwork20200907PeeringState["PENDINGACCEPTANCE"] = "PENDING_ACCEPTANCE";
    HashicorpCloudNetwork20200907PeeringState["ACCEPTED"] = "ACCEPTED";
    HashicorpCloudNetwork20200907PeeringState["ACTIVE"] = "ACTIVE";
    HashicorpCloudNetwork20200907PeeringState["FAILED"] = "FAILED";
    HashicorpCloudNetwork20200907PeeringState["EXPIRED"] = "EXPIRED";
    HashicorpCloudNetwork20200907PeeringState["REJECTED"] = "REJECTED";
    HashicorpCloudNetwork20200907PeeringState["DELETING"] = "DELETING";
})(HashicorpCloudNetwork20200907PeeringState || (HashicorpCloudNetwork20200907PeeringState = {}));
export function HashicorpCloudNetwork20200907PeeringStateFromJSON(json) {
    return HashicorpCloudNetwork20200907PeeringStateFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907PeeringStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudNetwork20200907PeeringStateToJSON(value) {
    return value;
}
