/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Vault Radar
 * API for managing Vault Radar.
 *
 * The version of the OpenAPI document: 2023-05-01
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function VaultRadar20230501ListOrganizationTenantsResponseFromJSON(json) {
    return VaultRadar20230501ListOrganizationTenantsResponseFromJSONTyped(json, false);
}
export function VaultRadar20230501ListOrganizationTenantsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'provisionedTenants': !exists(json, 'provisioned_tenants') ? undefined : json['provisioned_tenants'],
    };
}
export function VaultRadar20230501ListOrganizationTenantsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'provisioned_tenants': value.provisionedTenants,
    };
}
