/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128OpenSecretStaticVersionFromJSON, Secrets20231128OpenSecretStaticVersionToJSON, } from './';
export function Secrets20231128OpenSecretStaticVersionListFromJSON(json) {
    return Secrets20231128OpenSecretStaticVersionListFromJSONTyped(json, false);
}
export function Secrets20231128OpenSecretStaticVersionListFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'versions': !exists(json, 'versions') ? undefined : (json['versions'].map(Secrets20231128OpenSecretStaticVersionFromJSON)),
    };
}
export function Secrets20231128OpenSecretStaticVersionListToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'versions': value.versions === undefined ? undefined : (value.versions.map(Secrets20231128OpenSecretStaticVersionToJSON)),
    };
}
