import Service, { inject as service } from '@ember/service';

import type SegmentService from 'ember-cli-segment/services/segment';
import type CurrentUserService from '../services/current-user.ts';
import type UserContextService from '../services/user-context.ts';

const CONTEXT_STORAGE_KEY = 'hcp.analytics.context';

/**
 * Encapsulates the implementation details of our Segment integration for Cloud UI analytics.
 *
 * @see https://backstage.hashicorp.services/docs/default/component/cloud-ui/analytics/
 *
 */
export default class AnalyticsService extends Service {
  @service declare readonly currentUser: CurrentUserService;
  @service declare readonly segment: SegmentService;
  @service declare readonly userContext: UserContextService;

  set context(context) {
    localStorage.setItem(CONTEXT_STORAGE_KEY, JSON.stringify(context));
  }

  get context() {
    const context = localStorage.getItem(CONTEXT_STORAGE_KEY);
    return context ? JSON.parse(context) : {};
  }

  clearContext() {
    localStorage.removeItem(CONTEXT_STORAGE_KEY);
  }

  identifyUser() {
    const { user } = this.currentUser;
    const { organization } = this.userContext;

    if (user && user.id) {
      const { email, ...rest } = user;
      this.segment.identifyUser(user.id, {
        ...rest,
        requesting_user_id: user?.id,
        organization: organization?.id,
      });
    }
  }

  // NOTE: This is invoked by the application route controller
  //  SEE: https://github.com/josemarluedke/ember-cli-segment/tree/v5.0.0#tracking-page-views
  trackPageView(path: string, routeName: string, referrer: string) {
    const { clientWidth = null, clientHeight = null } =
      document.documentElement || {};
    this.segment.trackPageView(path, {
      referrer,
      routeName,
      clientWidth,
      clientHeight,
    });
  }

  get anonymousId() {
    // in cases where there are ad blockers, window.analytics is defined, but window.analytics.user is not, so we need to try/catch this
    let id;
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      id = (window as any).analytics?.user().anonymousId();
    } catch (e) {
      // do nothing here
    }
    return id;
  }

  // SEE: https://github.com/josemarluedke/ember-cli-segment/tree/v5.0.0#tracking-other-events
  trackEvent(event: string, attrs?: object, segmentOptions?: object) {
    const { organization } = this.userContext;
    let properties = attrs || {};
    if (organization && organization.id) {
      properties = { ...properties, organization: organization.id };
    }
    this.segment.trackEvent(event, properties, segmentOptions);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    analytics: AnalyticsService;
  }
}
