import templateOnlyComponent from '@ember/component/template-only';

interface StepperStepContentSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

const StepperStepContentComponent =
  templateOnlyComponent<StepperStepContentSignature>();

export default StepperStepContentComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Stepper::StepContent': typeof StepperStepContentComponent;
    'stepper/step-content': typeof StepperStepContentComponent;
  }
}
