/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Ctgw20230414OrganizationsLocationOrganizationIdProjectsLocationProjectIdClustersClusterIdAgentTelemetryConfigLocationFromJSON, Ctgw20230414OrganizationsLocationOrganizationIdProjectsLocationProjectIdClustersClusterIdAgentTelemetryConfigLocationToJSON, HashicorpCloudConsulTelemetry20230414LVTFromJSON, HashicorpCloudConsulTelemetry20230414LVTToJSON, } from './';
export function GetLabelValuesRequestFromJSON(json) {
    return GetLabelValuesRequestFromJSONTyped(json, false);
}
export function GetLabelValuesRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : Ctgw20230414OrganizationsLocationOrganizationIdProjectsLocationProjectIdClustersClusterIdAgentTelemetryConfigLocationFromJSON(json['location']),
        'type': !exists(json, 'type') ? undefined : HashicorpCloudConsulTelemetry20230414LVTFromJSON(json['type']),
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'clusterInternalId': !exists(json, 'cluster_internal_id') ? undefined : json['cluster_internal_id'],
    };
}
export function GetLabelValuesRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': Ctgw20230414OrganizationsLocationOrganizationIdProjectsLocationProjectIdClustersClusterIdAgentTelemetryConfigLocationToJSON(value.location),
        'type': HashicorpCloudConsulTelemetry20230414LVTToJSON(value.type),
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'cluster_internal_id': value.clusterInternalId,
    };
}
