/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215Licensing;
(function (HashicorpCloudGlobalNetworkManager20220215Licensing) {
    HashicorpCloudGlobalNetworkManager20220215Licensing["UNSPECIFIED"] = "LICENSING_UNSPECIFIED";
    HashicorpCloudGlobalNetworkManager20220215Licensing["ENTERPRISE"] = "LICENSING_ENTERPRISE";
    HashicorpCloudGlobalNetworkManager20220215Licensing["EXPIREDENTERPRISE"] = "LICENSING_EXPIRED_ENTERPRISE";
    HashicorpCloudGlobalNetworkManager20220215Licensing["OSS"] = "LICENSING_OSS";
    HashicorpCloudGlobalNetworkManager20220215Licensing["OSSFREETRIAL"] = "LICENSING_OSS_FREE_TRIAL";
    HashicorpCloudGlobalNetworkManager20220215Licensing["COMMUNITY"] = "LICENSING_COMMUNITY";
    HashicorpCloudGlobalNetworkManager20220215Licensing["COMMUNITYTRIAL"] = "LICENSING_COMMUNITY_TRIAL";
})(HashicorpCloudGlobalNetworkManager20220215Licensing || (HashicorpCloudGlobalNetworkManager20220215Licensing = {}));
export function HashicorpCloudGlobalNetworkManager20220215LicensingFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215LicensingFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215LicensingFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215LicensingToJSON(value) {
    return value;
}
