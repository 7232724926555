/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function LogService20210330SplunkCloudProviderFromJSON(json) {
    return LogService20210330SplunkCloudProviderFromJSONTyped(json, false);
}
export function LogService20210330SplunkCloudProviderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hecEndpoint': !exists(json, 'hec_endpoint') ? undefined : json['hec_endpoint'],
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}
export function LogService20210330SplunkCloudProviderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hec_endpoint': value.hecEndpoint,
        'token': value.token,
    };
}
