import { labelForRegion } from '../helpers/label-for-region.js';
import { TYPE_CONSUL, TYPE_VAULT } from 'common/utils/cloud-resource-types';
import type { HashicorpCloudNetwork20200907Network as HashicorpCloudNetworkNetwork } from '@clients/cloud-network';

export enum RegionProviders {
  aws = 'aws',
  azure = 'azure',
}

export const DEFAULT_HVN_PROVIDER_REGION = {
  provider: 'aws',
  region: 'us-west-2',
};
export const DEFAULT_SELECTED_PROVIDER_REGION = {
  aws: 'us-west-2',
  azure: 'eastus',
};

export const ProductProviderRegionFeatureFlags: Record<
  string,
  Record<string, string>
> = {
  [RegionProviders.aws]: {
    [TYPE_CONSUL]: 'hcp-consul-aws-available-regions',
    [TYPE_VAULT]: 'hcpv-aws-available-regions',
  },
  [RegionProviders.azure]: {
    [TYPE_CONSUL]: 'hcp-azure-available-regions',
  },
};

/**
 * This determines the available VPC/VNet Regions on the Terraform Quickstart form
 */
export const NetworkProviderPeeringRegionsFeatureFlags: Record<string, string> =
  {
    [RegionProviders.aws]: 'hcp-aws-available-vpc-regions',
    [RegionProviders.azure]: 'hcp-azure-available-vnet-regions',
  };

export const NetworkProviderRegionFeatureFlags: Record<string, string> = {
  [RegionProviders.aws]: 'hcp-aws-available-regions',
  [RegionProviders.azure]: 'hcp-azure-available-regions',
};

/**
 * Some regions have *individual* feature flags that control their availability
 */
export const NetworkProviderSingleRegionFeatureFlags: Record<
  string,
  Record<string, { flag: string; value: string }>
> = {
  [RegionProviders.aws]: {
    sydney: {
      flag: 'hcp-aws-sydney-region-support',
      value: 'ap-southeast-2',
    },
    singapore: {
      flag: 'hcp-aws-singapore-region-support',
      value: 'ap-southeast-1',
    },
  },
};

export const DEFAULT_AWS_REGION = 'us-west-2';

export const DEFAULT_AVAILABLE_REGIONS: Record<string, string[]> = {
  [RegionProviders.aws]: [
    DEFAULT_AWS_REGION,
    'us-east-1',
    'eu-west-1',
    'eu-west-2',
    'eu-central-1',
  ],
  [RegionProviders.azure]: [DEFAULT_SELECTED_PROVIDER_REGION.azure],
};

export const ProductRegionFallbacks: Record<
  string,
  Record<string, string[]>
> = {
  [RegionProviders.aws]: {
    [TYPE_CONSUL]: [DEFAULT_AWS_REGION],
    [TYPE_VAULT]: [DEFAULT_AWS_REGION],
  },
  [RegionProviders.azure]: {
    [TYPE_CONSUL]: [DEFAULT_SELECTED_PROVIDER_REGION.azure],
  },
};

export const ConsulPeeringRegionFallbacks: Record<string, string[]> = {
  [RegionProviders.aws]: [DEFAULT_SELECTED_PROVIDER_REGION.aws],
  [RegionProviders.azure]: [DEFAULT_SELECTED_PROVIDER_REGION.azure],
};

/**
 * These should match the translation keys used to display continent names
 * Note: India is not a continent, but it is treated as such because it does not meet
 * latency requirements when peered with other APAC regions
 */
export enum ContinentCodes {
  NorthAmerica = 'us-ca',
  Europe = 'eu',
  AsiaPacific = 'ap',
  Africa = 'af',
  MiddleEast = 'me',
  SouthAmerica = 'sa',
  Other = 'other',
  India = 'in',
}

// Used to group different region continent/area identifiers together
export const continentCodeMap: Record<string, string> = {
  ca: ContinentCodes.NorthAmerica,
  us: ContinentCodes.NorthAmerica,
};

const azureRegionsGrouping: Record<string, ContinentCodes> = {
  westus2: ContinentCodes.NorthAmerica,
  westus3: ContinentCodes.NorthAmerica,
  eastus2: ContinentCodes.NorthAmerica,
  eastus: ContinentCodes.NorthAmerica,
  centralus: ContinentCodes.NorthAmerica,
  southcentralus: ContinentCodes.NorthAmerica,
  canadacentral: ContinentCodes.NorthAmerica,
  westeurope: ContinentCodes.Europe,
  uksouth: ContinentCodes.Europe,
  northeurope: ContinentCodes.Europe,
  francecentral: ContinentCodes.Europe,
  germanywestcentral: ContinentCodes.Europe,
  norwayeast: ContinentCodes.Europe,
  swedencentral: ContinentCodes.Europe,
  australiasoutheast: ContinentCodes.AsiaPacific,
  japaneast: ContinentCodes.AsiaPacific,
  southeastasia: ContinentCodes.AsiaPacific,
  australiaeast: ContinentCodes.AsiaPacific,
  eastasia: ContinentCodes.AsiaPacific,
  centralindia: ContinentCodes.India,
};

// Get the region's continent prefix, example: us-west-2 -> us
const getAwsRegionContinentPrefix = (region: string) => region.split('-')[0];

// Get the region's continent code
export const getContinentCodeForRegion = (
  region: string,
  provider: RegionProviders
) => {
  switch (provider) {
    case RegionProviders.aws: {
      // For AWS, the continent code will be the continent prefix in most cases,
      // unless the region is part of a grouping as defined by `continentCodeMap`
      // Examples:
      // eu-north-1 -> eu
      // us-west-2 -> us-ca
      // ca-central-1 -> us-ca
      const continentPrefix = getAwsRegionContinentPrefix(region);

      return continentPrefix && continentCodeMap[continentPrefix]
        ? continentCodeMap[continentPrefix]
        : continentPrefix;
    }
    case RegionProviders.azure:
      // Azure regions do not have a consistent geo-prefix associated with them
      // so we have a 1:1 mapping of region -> continent code.
      // When additional regions are added, they should be added to this map
      // otherwise they will be displayed under an "Other" geo label
      return azureRegionsGrouping[region] || ContinentCodes.Other;
  }
};

export const groupRegionsByContinent =
  (provider: RegionProviders) =>
  (regions: string[] = []) => {
    const I18N_PREFIX = 'components.region-select';
    // Reduce the list of regions into a Map grouped by continents.
    const options = regions.reduce((continents, region) => {
      // Parse the continent code out of the region name.
      let continentCode = getContinentCodeForRegion(region, provider);

      // India should be labelled as AsiaPacific
      if (continentCode == ContinentCodes.India) {
        continentCode = ContinentCodes.AsiaPacific;
      }

      const isSet = continents.has(continentCode);

      // Register the continent if it hasn't been seen yet.
      if (!isSet) {
        continents.set(continentCode, {
          name: `${I18N_PREFIX}.continent.${continentCode}`,
          regions: new Set(),
        });
      }

      const continent = continents.get(continentCode);

      // Add the region to the continent.
      continent.regions.add({
        id: region,
        name: labelForRegion([region], { provider }),
      });

      return continents;
    }, new Map());

    // Format the Map to a neighborhood friendly array.
    return Array.from(options, ([id, value]) => ({ id, ...value }));
  };

/**
 * Returns a list of eligible backup regions
 */
export const listEligibleBackupRegions = (
  selectedRegion: string,
  provider: RegionProviders,
  regions: string[]
): string[] => {
  if (!selectedRegion || !provider || !regions.length) {
    return [];
  }

  const continentCode = getContinentCodeForRegion(selectedRegion, provider);

  return regions.filter(
    (region) =>
      region !== selectedRegion &&
      getContinentCodeForRegion(region, provider) === continentCode
  );
};

/**
 * Returns a list of eligible backup networks based on region requirements
 * Warning: this does NOT guarantee against CIDR overlaps
 */
export const listEligibleBackupNetworks = (
  selectedHvn: HashicorpCloudNetworkNetwork,
  hvns: HashicorpCloudNetworkNetwork[]
): HashicorpCloudNetworkNetwork[] => {
  const selectedRegion = selectedHvn.location?.region?.region;
  const selectedProvider = selectedHvn.location?.region
    ?.provider as RegionProviders;

  if (!selectedHvn || !selectedRegion || !selectedProvider || !hvns.length) {
    return [];
  }

  const continentCode = getContinentCodeForRegion(
    selectedRegion,
    selectedProvider
  );

  // Wishlist: verify that there are no CIDR collisions via networkHasCidrBlockCollision
  return hvns.filter((network) => {
    const region = network.location?.region?.region || '';
    const provider = selectedHvn.location?.region?.provider as RegionProviders;

    return (
      region != selectedRegion &&
      getContinentCodeForRegion(region, provider) == continentCode
    );
  });
};
