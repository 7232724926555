import Component from '@glimmer/component';

interface LayoutLoadingSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class LayoutLoadingComponent extends Component<LayoutLoadingSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::Loading': typeof LayoutLoadingComponent;
    'layout/loading': typeof LayoutLoadingComponent;
  }
}
