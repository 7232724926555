import NetworkOption from '../models/network-option.js';
import NetworkOptionGroup from '../models/network-option-group.js';

/**
 * Checks whether a candidate data type has the properties of a `{NetworkOptionGroup}`.
 * @param {*} candidate
 * @return {Boolean}
 */
export function isNetworkOptionGroup(candidate) {
  return (
    typeof candidate?.groupName === 'string' &&
    Array.isArray(candidate?.options)
  );
}

/**
 * @param {NetworkOption} networkOptionA
 * @param {NetworkOption} networkOptionB
 * @return {-1,0,1}
 * @callback {Array~sort}
 */
export function compareNetworkOptionByIdAsc(networkOptionA, networkOptionB) {
  if (!networkOptionA?.disabled && networkOptionB?.disabled) return -1;
  if (networkOptionA?.disabled && !networkOptionB?.disabled) return 1;

  if (networkOptionA?.network?.id < networkOptionB?.network?.id) return -1;
  if (networkOptionA?.network?.id > networkOptionB?.network?.id) return 1;
  return 0;
}

/**
 * @param {Array.<String>} ids
 * @return {Function}
 */
export function removeById(ids = []) {
  /**
   * @param {Array.<NetworkOption,NetworkOptionGroup>} options
   * @param {NetworkOption,NetworkOptionGroup} networkOption
   * @return {Array.<NetworkOption,NetworkOptionGroup>}
   * @callback {Array~reduce}
   */
  return function removeById2(options, networkOption) {
    if (isNetworkOptionGroup(networkOption)) {
      let group = networkOption;

      return [
        ...options,
        new NetworkOptionGroup(
          group.groupName,
          group.options.reduce(removeById(ids), []),
          group.disabled
        ),
      ];
    }

    if (ids.includes(networkOption?.network?.id)) {
      return options;
    }

    return [...options, networkOption];
  };
}

/**
 * @param {Array.String} ids
 * @param {Boolean} disabled
 * @return {Function}
 */
export function decorateNetworkOptionWithDisabled(ids = [], disabled = false) {
  /**
   * @param {NetworkOption,NetworkOptionGroup} networkOption
   * @return {NetworkOption,NetworkOptionGroup}
   * @callback {Array~map}
   */
  return function decorateNetworkOptionWithDisabled2(networkOption) {
    if (isNetworkOptionGroup(networkOption)) {
      let group = networkOption;

      return new NetworkOptionGroup(
        group.groupName,
        group.options.map(decorateNetworkOptionWithDisabled(ids, disabled), []),
        group.disabled
      );
    }

    if (ids.includes(networkOption?.network?.id)) {
      return new NetworkOption(networkOption.network, disabled);
    }

    return networkOption;
  };
}

/**
 * @param {Array.String} ids
 * @return {Function}
 */
export function groupNetworkOptionsById(ids = []) {
  /**
   * @param {NetworkOptionGroup} groupa - accumulator, options start empty
   * @param {NetworkOption,NetworkOptionGroup} networkOption
   * @return {NetworkOptionGroup}
   * @callback {Array~reduce}
   */
  return function groupNetworkOptionsById2(networkOptionsGroup, networkOption) {
    if (isNetworkOptionGroup(networkOption)) {
      let group = networkOption;
      return group.options.reduce(
        groupNetworkOptionsById(ids),
        networkOptionsGroup
      );
    }

    if (ids.includes(networkOption?.network?.id)) {
      return new NetworkOptionGroup(
        networkOptionsGroup.groupName,
        [...networkOptionsGroup.options, networkOption],
        networkOptionsGroup.disabled
      );
    }

    return networkOptionsGroup;
  };
}

/**
 * @param {Array.<NetworkOption>} flattenedNetworkOptions - accumulator, starts empty
 * @param {NetworkOption,NetworkOptionGroup} networkOption
 * @return {Array.<NetworkOption>}
 * @callback {Array~reduce}
 */
export function flattenNetworkOptionGroups(
  flattenedNetworkOptions,
  networkOption
) {
  if (isNetworkOptionGroup(networkOption)) {
    let group = networkOption;
    return [
      ...flattenedNetworkOptions,
      ...group.options.reduce(flattenNetworkOptionGroups, []),
    ];
  }

  return [...flattenedNetworkOptions, networkOption];
}
