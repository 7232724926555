import packagesDataSizes from './packages-data-sizes.ts';

import type {
  BillingAvailablePackage,
  BillingPackageOption,
} from '../types/api.ts';

export default function packagesDataSize(
  tier: BillingAvailablePackage | null,
  { sizeKey }: { sizeKey?: string } = {},
): BillingPackageOption | undefined {
  if (!tier || !sizeKey) return;

  const sizes = packagesDataSizes(tier);
  const size = sizes.find((s) => s.key === sizeKey);

  return size;
}
