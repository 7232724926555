/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330DestinationFilterTypeFromJSON, LogService20210330DestinationFilterTypeToJSON, } from './';
export function LogService20210330CreateDestinationFilterRequestFromJSON(json) {
    return LogService20210330CreateDestinationFilterRequestFromJSONTyped(json, false);
}
export function LogService20210330CreateDestinationFilterRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'destinationId': !exists(json, 'destination_id') ? undefined : json['destination_id'],
        'filterType': !exists(json, 'filter_type') ? undefined : LogService20210330DestinationFilterTypeFromJSON(json['filter_type']),
    };
}
export function LogService20210330CreateDestinationFilterRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'destination_id': value.destinationId,
        'filter_type': LogService20210330DestinationFilterTypeToJSON(value.filterType),
    };
}
