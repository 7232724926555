import { assert } from '@ember/debug';

export default class BillingFCPBalance {
  #initialAmount;
  #estimatedRemainingBalance;

  constructor(initialAmount = 0, estimatedRemainingBalance = 0) {
    this.#initialAmount = parseFloat(initialAmount);
    this.#estimatedRemainingBalance = parseFloat(estimatedRemainingBalance);

    assert(
      `value: ${this.#initialAmount} must be valid number`,
      typeof this.#initialAmount === 'number' &&
        !Number.isNaN(this.#initialAmount),
    );
    assert(
      `value: ${this.#estimatedRemainingBalance} must be valid number`,
      typeof this.#estimatedRemainingBalance === 'number' &&
        !Number.isNaN(this.#estimatedRemainingBalance),
    );
  }

  get estimatedRemainingBalance() {
    return this.#estimatedRemainingBalance;
  }

  get isLow() {
    return this.percentageConsumed >= 0.75 && this.percentageConsumed < 1;
  }

  get isZero() {
    return this.estimatedRemainingBalance <= 0;
  }

  get percentageRemaining() {
    return 1 - this.percentageConsumed;
  }

  get percentageConsumed() {
    if (this.estimatedRemainingBalance <= this.#initialAmount) {
      return Math.abs(
        (this.estimatedRemainingBalance - this.#initialAmount) /
          this.#initialAmount,
      );
    }
    return 0;
  }
}
