import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';

export const MEMBER_TYPE_USER = {
  active: true,
  description: 'helpers.rbac-member-types.member-types.user.description',
  label: 'helpers.rbac-member-types.member-types.user.label',
  value: HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
};
export const MEMBER_TYPE_SERVICE_PRINCIPAL = {
  active: true,
  description:
    'helpers.rbac-member-types.member-types.service-principal.description',
  label: 'helpers.rbac-member-types.member-types.service-principal.label',
  value: HashicorpCloudResourcemanagerPolicyBindingMemberType.SERVICEPRINCIPAL,
};
export const MEMBER_TYPE_GROUP = {
  active: false,
  description: 'helpers.rbac-member-types.member-types.group.description',
  label: 'helpers.rbac-member-types.member-types.group.label',
  value: HashicorpCloudResourcemanagerPolicyBindingMemberType.GROUP,
};

export default [
  MEMBER_TYPE_USER,
  MEMBER_TYPE_SERVICE_PRINCIPAL,
  MEMBER_TYPE_GROUP,
];
