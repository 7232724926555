/** ALERTS is used for TGW Attachment banners and accordions
 *  The translations pattern is:
 *  components
 *    --> networks
 *       --> tgw-attachments
 *          --> alerts
 *            --> <PROVIDER>
 *              --> <TGW STATE>
 *                  --> banner or accordion
 *                      --> message or title
 * **/
import SystemNotificationColors from 'common/utils/system-notification-colors';

export const TGW_ATTACHMENTS_ALERTS = {
  aws: {
    EXPIRED: {
      banner: {
        message:
          'components.networks.tgw-attachments.alerts.aws.expired.banner.message',
        title:
          'components.networks.tgw-attachments.alerts.aws.expired.banner.title',
        color: SystemNotificationColors.CRITICAL,
      },
    },
    FAILED: {
      banner: {
        message:
          'components.networks.tgw-attachments.alerts.aws.failed.banner.message',
        title:
          'components.networks.tgw-attachments.alerts.aws.failed.banner.title',
        color: SystemNotificationColors.CRITICAL,
      },
    },
    PENDING_ACCEPTANCE: {
      banner: {
        message:
          'components.networks.tgw-attachments.alerts.aws.pending-acceptance.banner.message',
        title:
          'components.networks.tgw-attachments.alerts.aws.pending-acceptance.banner.title',
        color: SystemNotificationColors.WARNING,
        link: 'components.networks.tgw-attachments.alerts.aws.pending-acceptance.banner.link',
      },
      accordion: {
        title:
          'components.networks.tgw-attachments.alerts.aws.pending-acceptance.accordion.title',
        message:
          'components.networks.tgw-attachments.alerts.aws.pending-acceptance.accordion.message',
      },
    },
    ACCEPTED: {
      banner: {
        message:
          'components.networks.tgw-attachments.alerts.aws.default.banner.message',
        title:
          'components.networks.tgw-attachments.alerts.aws.default.banner.title',
        color: SystemNotificationColors.WARNING,
        link: 'components.networks.tgw-attachments.alerts.aws.default.banner.link',
      },
      accordion: {
        title:
          'components.networks.tgw-attachments.alerts.aws.default.accordion.title',
        message:
          'components.networks.tgw-attachments.alerts.aws.default.accordion.message',
      },
    },
    ACTIVE: {
      banner: {
        message:
          'components.networks.tgw-attachments.alerts.aws.default.banner.message',
        title:
          'components.networks.tgw-attachments.alerts.aws.default.banner.title',
        color: SystemNotificationColors.WARNING,
        link: 'components.networks.tgw-attachments.alerts.aws.default.banner.link',
      },
      accordion: {
        title:
          'components.networks.tgw-attachments.alerts.aws.default.accordion.title',
        message:
          'components.networks.tgw-attachments.alerts.aws.default.accordion.message',
      },
    },
    REJECTED: {
      banner: {
        message:
          'components.networks.tgw-attachments.alerts.aws.rejected.banner.message',
        title:
          'components.networks.tgw-attachments.alerts.aws.rejected.banner.title',
        color: SystemNotificationColors.CRITICAL,
      },
    },
  },
};

// TODO:  need to grab the images
export const AWS_DISCLOSURE_GUIDES_TGW_ATTACHMENTS = {
  accountId: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.create.form.label.aws-account-id-example-instructions',
      imgSrc: '/images/tgw-Instructions-account-ID.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.create.form.label.aws-account-id-example-alt',
    },
  ],
  tgwId: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.create.form.label.aws-transit-gateway-id-example-instructions',
      imgSrc: '/images/tgw-Instructions-tgw-id.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.create.form.label.aws-transit-gateway-id-example-alt',
    },
  ],
  resourceShareArn: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.create.form.label.aws-resource-share-arn-example-instructions',
      imgSrc: '/images/tgw-Instructions-share-arn.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.create.form.label.aws-resource-share-arn-example-alt',
    },
  ],
  cidrBlock: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.create.form.label.route-table-example-instructions',
      imgSrc: '/images/tgw-Instructions-route-table.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.create.form.label.route-table-example-alt',
    },
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.create.form.label.vpc-cidr-block-example-instructions',
      imgSrc: '/images/tgw-Instructions-cidr.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.create.form.label.vpc-cidr-block-example-alt',
    },
  ],
  updatingSecurityGroups: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.detail.instructions.default.updating-groups.web-console.example-instructions',
      imgSrc: '/images/securitygroups-instruction-files.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.detail.instructions.default.updating-groups.web-console.example-alt',
    },
  ],
  updatingRouteTable: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.detail.instructions.default.updating-route-table.web-console.example-instructions',
      imgSrc: '/images/routetable-instruction-files.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.detail.instructions.default.updating-route-table.web-console.example-alt',
    },
  ],
};

export const TGW_ROUTE_NAMES = {
  configurationInstructions:
    'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.detail.configuration-instructions',
  create:
    'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.create',
  list: 'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.list.index',
  detail:
    'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.detail.index',
  createRoute:
    'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.detail.hvn-routes.create',
};
