/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Vault Radar
 * API for managing Vault Radar.
 *
 * The version of the OpenAPI document: 2023-05-01
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LocationRegionFromJSON, LocationRegionToJSON, } from './';
export function Model20230501VaultRadarProjectsLocationProjectIdDataSourceRegistrationsOffboardLocationFromJSON(json) {
    return Model20230501VaultRadarProjectsLocationProjectIdDataSourceRegistrationsOffboardLocationFromJSONTyped(json, false);
}
export function Model20230501VaultRadarProjectsLocationProjectIdDataSourceRegistrationsOffboardLocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'region': !exists(json, 'region') ? undefined : LocationRegionFromJSON(json['region']),
    };
}
export function Model20230501VaultRadarProjectsLocationProjectIdDataSourceRegistrationsOffboardLocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'region': LocationRegionToJSON(value.region),
    };
}
