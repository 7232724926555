/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * SSOType is the type of SSO.
 *
 *  - UNSET: UNSET is the default value.
 * It should never be used at runtime for valid messages.
 *  - SAML: SAML is a SAML connection.
 *  - OIDC: OIDC is a OIDC connection stored in cloud-idp.
 * @export
 * @enum {string}
 */
export var HashicorpCloudIamSSOType;
(function (HashicorpCloudIamSSOType) {
    HashicorpCloudIamSSOType["UNSET"] = "UNSET";
    HashicorpCloudIamSSOType["SAML"] = "SAML";
    HashicorpCloudIamSSOType["OIDC"] = "OIDC";
})(HashicorpCloudIamSSOType || (HashicorpCloudIamSSOType = {}));
export function HashicorpCloudIamSSOTypeFromJSON(json) {
    return HashicorpCloudIamSSOTypeFromJSONTyped(json, false);
}
export function HashicorpCloudIamSSOTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudIamSSOTypeToJSON(value) {
    return value;
}
