/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215AdminPartitionWithEligibilityFromJSON, HashicorpCloudGlobalNetworkManager20220215AdminPartitionWithEligibilityToJSON, HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOptionFromJSON, HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOptionToJSON, HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReasonFromJSON, HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReasonToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ListClustersWithDialerEligibilityResponseClusterWithDialerEligibilityFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ListClustersWithDialerEligibilityResponseClusterWithDialerEligibilityFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ListClustersWithDialerEligibilityResponseClusterWithDialerEligibilityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'partitions': !exists(json, 'partitions') ? undefined : (json['partitions'].map(HashicorpCloudGlobalNetworkManager20220215AdminPartitionWithEligibilityFromJSON)),
        'ineligibilityReasons': !exists(json, 'ineligibility_reasons') ? undefined : (json['ineligibility_reasons'].map(HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReasonFromJSON)),
        'connectivityOptions': !exists(json, 'connectivity_options') ? undefined : (json['connectivity_options'].map(HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOptionFromJSON)),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ListClustersWithDialerEligibilityResponseClusterWithDialerEligibilityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'partitions': value.partitions === undefined ? undefined : (value.partitions.map(HashicorpCloudGlobalNetworkManager20220215AdminPartitionWithEligibilityToJSON)),
        'ineligibility_reasons': value.ineligibilityReasons === undefined ? undefined : (value.ineligibilityReasons.map(HashicorpCloudGlobalNetworkManager20220215PeeringIneligibilityReasonToJSON)),
        'connectivity_options': value.connectivityOptions === undefined ? undefined : (value.connectivityOptions.map(HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOptionToJSON)),
    };
}
