/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var LogService20210330EventType;
(function (LogService20210330EventType) {
    LogService20210330EventType["UNSET"] = "EVENT_TYPE_UNSET";
    LogService20210330EventType["AUDITLOG"] = "EVENT_TYPE_AUDIT_LOG";
    LogService20210330EventType["SERVERLOGSTDOUT"] = "EVENT_TYPE_SERVER_LOG_STDOUT";
    LogService20210330EventType["SERVERLOGSTDERR"] = "EVENT_TYPE_SERVER_LOG_STDERR";
})(LogService20210330EventType || (LogService20210330EventType = {}));
export function LogService20210330EventTypeFromJSON(json) {
    return LogService20210330EventTypeFromJSONTyped(json, false);
}
export function LogService20210330EventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LogService20210330EventTypeToJSON(value) {
    return value;
}
