/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105ContractBillingMethodFromJSON, Billing20201105ContractBillingMethodToJSON, } from './';
export function Billing20201105EntitlementBillingMethodDetailsFromJSON(json) {
    return Billing20201105EntitlementBillingMethodDetailsFromJSONTyped(json, false);
}
export function Billing20201105EntitlementBillingMethodDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'billingMethod': !exists(json, 'billing_method') ? undefined : Billing20201105ContractBillingMethodFromJSON(json['billing_method']),
    };
}
export function Billing20201105EntitlementBillingMethodDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'billing_method': Billing20201105ContractBillingMethodToJSON(value.billingMethod),
    };
}
