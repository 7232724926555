/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - UNDETERMINED: HCP Packer cannot determine whether the child image is out of date.
 * This status occurs when the parent iteration was not assigned to an image channel when Packer built the image,
 * the image channel no longer exists in the image bucket, or HCP Packer is not tracking the parent.
 *  - UP_TO_DATE: The child image is built from the iteration currently assigned to the image channel.
 *  - OUT_OF_DATE: The child image is built from a different iteration than the one currently assigned to the image channel.
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202BucketAncestryStatus;
(function (HashicorpCloudPacker20221202BucketAncestryStatus) {
    HashicorpCloudPacker20221202BucketAncestryStatus["UNDETERMINED"] = "UNDETERMINED";
    HashicorpCloudPacker20221202BucketAncestryStatus["UPTODATE"] = "UP_TO_DATE";
    HashicorpCloudPacker20221202BucketAncestryStatus["OUTOFDATE"] = "OUT_OF_DATE";
})(HashicorpCloudPacker20221202BucketAncestryStatus || (HashicorpCloudPacker20221202BucketAncestryStatus = {}));
export function HashicorpCloudPacker20221202BucketAncestryStatusFromJSON(json) {
    return HashicorpCloudPacker20221202BucketAncestryStatusFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202BucketAncestryStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202BucketAncestryStatusToJSON(value) {
    return value;
}
