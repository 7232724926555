/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudConsulTelemetry20230414ClusterFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414ClusterFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414ClusterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'licensing': !exists(json, 'licensing') ? undefined : json['licensing'],
        'datacenter': !exists(json, 'datacenter') ? undefined : json['datacenter'],
        'trustDomain': !exists(json, 'trust_domain') ? undefined : json['trust_domain'],
        'external': !exists(json, 'external') ? undefined : json['external'],
    };
}
export function HashicorpCloudConsulTelemetry20230414ClusterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'licensing': value.licensing,
        'datacenter': value.datacenter,
        'trust_domain': value.trustDomain,
        'external': value.external,
    };
}
