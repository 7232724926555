/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215ClusterSource;
(function (HashicorpCloudGlobalNetworkManager20220215ClusterSource) {
    HashicorpCloudGlobalNetworkManager20220215ClusterSource["UNSPECIFIED"] = "SOURCE_UNSPECIFIED";
    HashicorpCloudGlobalNetworkManager20220215ClusterSource["KUBERNETES"] = "SOURCE_KUBERNETES";
    HashicorpCloudGlobalNetworkManager20220215ClusterSource["HCP"] = "SOURCE_HCP";
    HashicorpCloudGlobalNetworkManager20220215ClusterSource["VM"] = "SOURCE_VM";
})(HashicorpCloudGlobalNetworkManager20220215ClusterSource || (HashicorpCloudGlobalNetworkManager20220215ClusterSource = {}));
export function HashicorpCloudGlobalNetworkManager20220215ClusterSourceFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ClusterSourceFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterSourceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterSourceToJSON(value) {
    return value;
}
