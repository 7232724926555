import Component from '@glimmer/component';

interface ModalDialogHeaderSignature {
  Args: {
    closeModalDialog?: unknown;
    dismissible?: unknown;
    headerIconType?: unknown;
    id?: string;
    variant?: unknown;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class ModalDialogHeaderComponent extends Component<ModalDialogHeaderSignature> {
  get dismissible() {
    return this.args.dismissible ?? true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ModalDialog::Header': typeof ModalDialogHeaderComponent;
    'modal-dialog/header': typeof ModalDialogHeaderComponent;
  }
}
