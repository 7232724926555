import Component from '@glimmer/component';

interface DateRangeSignature {
  Args: {
    asUTC: unknown;
    condensed: unknown;
    endDate: unknown;
    formatOpts: unknown;
    startDate: unknown;
    withTooltip: unknown;
  };
}

/**
 *
 * `DateRangeComponent` provides a container to display a range of two dates.
 *
 *
 * ```
 * <DateRangeComponent>
 * ```
 *
 * @class DateRangeComponent
 *
 */

/**
 * `startDate` is the starting date to be displayed in the date range
 * @argument startDate
 * @type {boolean}
 */

/**
 * `endDate` is the ending date to be displayed in the date range
 * @argument endDate
 * @type {boolean}
 */

/**
 * `condensed` is a flag to condense the date range is possible if the range is within the same year.
 * For example, the date range for May 31, 2023 - June 31, 2023 would be condensed to May 31 - June 31, 2023
 * @argument condensed
 * @type {boolean}
 */

/**
 * `formatOpts` are the Intl.DateTimeFormat formatting options for the dates
 * see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 * @argument formatOpts
 * @type {string}
 */

/**
 * `asUTC` is a flag to determine if the dates should be displayed as UTC instead of locale
 * @argument asUTC
 * @type {boolean}
 */

/**
 * `withTooltip` is a flag to determine if the tooltip displaying the ISO date string should be displayed.
 * @argument withTooltip
 * @type {boolean}
 */

export default class DateRangeComponent extends Component<DateRangeSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DateRange: typeof DateRangeComponent;
    'date-range': typeof DateRangeComponent;
  }
}
