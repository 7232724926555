/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GhAppMetadataAccountTypeFromJSON, GhAppMetadataAccountTypeToJSON, } from './';
export function Secrets20230613GhAppMetadataFromJSON(json) {
    return Secrets20230613GhAppMetadataFromJSONTyped(json, false);
}
export function Secrets20230613GhAppMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'installationId': !exists(json, 'installation_id') ? undefined : json['installation_id'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'accountType': !exists(json, 'account_type') ? undefined : GhAppMetadataAccountTypeFromJSON(json['account_type']),
    };
}
export function Secrets20230613GhAppMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'installation_id': value.installationId,
        'owner': value.owner,
        'account_type': GhAppMetadataAccountTypeToJSON(value.accountType),
    };
}
