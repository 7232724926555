import { getOwner } from '@ember/owner';
import { assert } from '@ember/debug';

const FORBIDDEN_ERROR_NAME = 'ForbiddenError';
const DEFAULT_ERROR_NAME = FORBIDDEN_ERROR_NAME;

/**
 * @typedef {Object} PermissionOptions
 * @property {number} [status=403] - HTTP status code to return on a permission error.
 * @property {string} [name=DEFAULT_ERROR_NAME] - The name for the error.
 * @property {string} permission - The required permission string.
 */

/**
 * Ensures that the provided permissions are checked and guards against
 * unauthorized actions. Implementation of this function can be used in
 * routes directly by importing this guard and decorating any beforeModel,
 * model, or afterModel hook. If none are present and you still need to guard
 * the route, always use the afterModel hook because it is always gauranteed to
 * fire. Applying this decorator to an empty model may cause undesireable side
 * effects. Additionally, this decorator can be chained back-to-back to check
 * multiple strings and throw specific errors.
 *
 * Example:
 *
 * ```
 * import { PREFIX_IAM_GROUPS, ACTION_UPDATE } from 'authz/utils/permission-types/index';
 *
 * @permissionGuard({
 *   permission: `${PREFIX_IAM_GROUPS}.${ACTION_UPDATE}`,
 * })
 * async model() {
 *   const { organization } = this.modelFor('parent.route');
 *   return { organization };
 * }
 * ```
 *
 * @param {PermissionOptions} options - The options for the permission guard.
 * @returns {MethodDecorator}
 * @throws Will throw an error if the permission check fails.
 */
export function permissionGuard({
  status = 403,
  name = DEFAULT_ERROR_NAME,
  permission,
}) {
  return function (target, _key, descriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args) {
      assert(
        'A permission string must be passed to permissionGuard',
        permission
      );

      const permissions = getOwner(this).lookup('service:permissions');
      const hasPermission = permission ? permissions.has(permission) : false;

      if (!hasPermission) {
        const error = new Error(name);
        error.name = name;
        error.status = status;
        throw error;
      }

      return originalMethod.apply(this, args);
    };

    return descriptor;
  };
}

export default permissionGuard;
