/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { HashicorpCloudConsulTelemetry20230414ServiceTopologyEdgeFromJSON, HashicorpCloudConsulTelemetry20230414ServiceTopologyEdgeToJSON, HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeFromJSON, HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeToJSON, } from './';
export function HashicorpCloudConsulTelemetry20230414ServiceTopologyFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414ServiceTopologyFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414ServiceTopologyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nodes': !exists(json, 'nodes') ? undefined : (mapValues(json['nodes'], HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeFromJSON)),
        'edges': !exists(json, 'edges') ? undefined : (json['edges'].map(HashicorpCloudConsulTelemetry20230414ServiceTopologyEdgeFromJSON)),
    };
}
export function HashicorpCloudConsulTelemetry20230414ServiceTopologyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nodes': value.nodes === undefined ? undefined : (mapValues(value.nodes, HashicorpCloudConsulTelemetry20230414ServiceTopologyNodeToJSON)),
        'edges': value.edges === undefined ? undefined : (value.edges.map(HashicorpCloudConsulTelemetry20230414ServiceTopologyEdgeToJSON)),
    };
}
