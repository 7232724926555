import templateOnlyComponent from '@ember/component/template-only';

interface LayoutFullScreenSignature {
  // Args: {};
  Blocks: {
    default: [unknown];
  };
  Element: HTMLDivElement;
}

const LayoutFullScreenComponent =
  templateOnlyComponent<LayoutFullScreenSignature>();

export default LayoutFullScreenComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::FullScreen': typeof LayoutFullScreenComponent;
    'layout/full-screen': typeof LayoutFullScreenComponent;
  }
}
