import Modifier from 'ember-modifier';
import { assert } from '@ember/debug';

export const SPACING_SCALE = [
  '0',
  '3xs', // 2px
  '2xs', // 4px
  'xs', // 8px
  'sm', // 12px
  'md', // 16px
  'lg', // 24px, Note: No longer +4px, starting here with `lg`
  '2lg', // 32px
  'xl', // 36px
  '2xl', // 48px
  'auto',
];

export interface BoxMarginSignature {
  Args: {
    Positional: typeof SPACING_SCALE | undefined;
  };
}

export type SpacingArray = [string, string, string, string];

export const DEFAULT_SPACING: SpacingArray = ['sm', 'sm', 'sm', 'sm'];

export const buildSpacingArray = (params: string[]): SpacingArray => {
  if (!params || !params[0] || params.length === 0) {
    return DEFAULT_SPACING;
  }
  const spacing = params.length === 1 ? params[0].split(' ') : params;

  switch (spacing.length) {
    case 1: {
      return [spacing[0], spacing[0], spacing[0], spacing[0]] as SpacingArray;
    }
    case 2: {
      return [spacing[0], spacing[1], spacing[0], spacing[1]] as SpacingArray;
    }
    case 3: {
      return [spacing[0], spacing[1], spacing[2], spacing[1]] as SpacingArray;
    }
    case 4: {
      return spacing as SpacingArray;
    }
    default: {
      return DEFAULT_SPACING;
    }
  }
};

/**
 * {{box-margin}} modifier controls the inner margin of an element container and
 *    aligns to the HCP size scale. A single value or an array of values are
 *    accepted values. The semantics are the same as declaring margin in CSS:
 *    top, right, bottom, left.
 * Options: '0', '3xs', '2xs', 'xs', 'sm', 'md', 'lg', '2lg', 'xl', '2xl', 'auto'
 *
 *
 * @example
 * {{box-margin '2xs'}} will render an element with 2xs margin on all sides.
 * {{box-margin '2x xs'}} will render an element with 2x margin on the top and
 *    bottom, and xs margin on the right and left.
 * {{box-margin '0 xs'}} will render an element with zero margin on the top and
 *    bottom, and xs margin on the right and left.
 *
 *
 * @default 'sm'
 * @type {string}
 */
export default class BoxMarginModifier extends Modifier<BoxMarginSignature> {
  modify(element: HTMLElement, params: string[]) {
    const spacing = buildSpacingArray(params);
    for (const size of spacing) {
      assert(
        `margin size for '{{box-margin}}' must be one of the following: ${SPACING_SCALE.join(
          ', '
        )}, received: ${size}`,
        SPACING_SCALE.includes(size)
      );
    }

    const elementClassNames = [
      'box--margin-top',
      'box--margin-right',
      'box--margin-bottom',
      'box--margin-left',
    ].map((prefix, i) => `${prefix}-${spacing[i]}`);

    element.classList.add('box', ...elementClassNames);

    return element;
  }
}
