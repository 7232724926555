import { isTesting } from '@embroider/macros';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { timeout, dropTask } from 'ember-concurrency';

import type { IntlService } from 'ember-intl';

interface ClipboardCopyButtonSignature {
  Args: {
    clipboardText: string;
    container: string;
    onSuccess?: () => void;
    successText?: string;
    timeout?: string | number;
    variant?: 'primary' | 'no-success-text';
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `ClipboardCopyButton` wraps [ember-cli-clipboard's `CopyButton` component]( https://github.com/jkusa/ember-cli-clipboard#angle-bracket-invocation ) and uses `ember-concurrency` to show a success message after copying.
 *
 * The default appearance is the same as a compact "ghost" button from HDS, and it replaces the yielded text with the success message.
 *
 * There is also a "primary" variant that renders the button as a primary button and shows the success message to the right of the button.
 *
 * The "no-success-text" variant renders the clipboard icon, but does not show a success text upon copying.
 *
 *
 * ### Usage
 *
 * #### Minimal usage
 *
 * Just the clipboard icon
 *
 *
 * ```
 * <ClipboardCopyButton
 *   @clipboardText='Some text'
 * />
 * ```
 *
 * A primary button that says 'Copy' with the clipboard icon.
 *
 *
 * ```
 * <ClipboardCopyButton
 *   @clipboardText='Some text'
 *   @variant='primary'
 * />
 * ```
 *
 * #### All of the args
 *
 *
 * ```
 * <ClipboardCopyButton
 *   @clipboardText='Some text'
 *   @container='.some-selector'
 *   @variant='primary'
 *   @timeout='2000'
 *   @successText='Some text'
 *   @onSuccess={{this.onSuccess}}
 * >
 *   Copy some text
 *</ClipboardCopyButton>
 * ```
 *
 * @class ClipboardCopyButton
 *
 */
export default class ClipboardCopyButtonComponent extends Component<ClipboardCopyButtonSignature> {
  /**
   * The text to copy to the clipboard. This is passed through to the ember-cli-clipboard component.
   * @argument clipboardText
   * @required
   * @type {string}
   */

  /**
   * A DOM selector representing the ancestor element in the DOM that events should be delegated to. This is useful when rendering a CopyButton in a modal. This argument is passed through to the ember-cli-clipboard component.
   * @argument container
   * @required
   * @type {string}
   */

  /**
   * The amount of time in milliseconds to wait before removing the success text. If this argument isn't specified, a timeout of 1000 is used.
   * @argument timeout
   * @type {?string|?number}
   */

  /**
   * Changes the variant of the ClipboardCopyButton. The only currently supported variants are `'primary'` and `'no-success-text'`.
   * @argument variant
   * @type {?string}
   */

  /**
   * A message that can be displayed upon successful copy
   * @argument successText
   * @type {?string}
   */

  @service declare readonly intl: IntlService;
  @tracked successText: string = '';

  // Internal state-tracking
  @tracked showSuccess = false;

  get timeout() {
    return this.args.timeout || isTesting() ? 10 : 1000;
  }

  get isPrimary() {
    return this.args.variant === 'primary';
  }

  get isNoSuccessText() {
    return this.args.variant === 'no-success-text';
  }

  @dropTask
  *copied() {
    this.successText =
      this.args.successText ??
      this.intl.t('components.clipboard-copy-button.copied');
    this.showSuccess = true;

    this.args?.onSuccess?.();

    yield timeout(this.timeout);

    this.successText = '';
    this.showSuccess = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ClipboardCopyButton: typeof ClipboardCopyButtonComponent;
    'clipboard-copy-button': typeof ClipboardCopyButtonComponent;
  }
}
