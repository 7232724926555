import type {
  BillingAvailablePackage,
  BillingPackageOption,
} from '../types/api.ts';

export default function packagesDataConsulServiceInstances(
  tier: BillingAvailablePackage | null,
): BillingPackageOption[] {
  if (!tier) return [];

  const { items = [] } = tier;
  const [numServiceInstances = {}] = items.filter(
    (i) => i.key === 'hashicorp.consul.dimension.num_service_instances',
  );
  const { options = [] } = numServiceInstances;

  return options;
}
