import Component from '@glimmer/component';

import {
  ALL_PROVIDERS,
  PROVIDERS_ICONS_MAPPING,
} from '../../../../utils/networks';
/**
 *
 * `NetworksFormInputsProviderCards` renders the provider radio cards for AWS and Azure
 *
 *
 * ```
 * <Networks::FormInputs::ProviderCards
 *   @onChange={{onChange}}
 *   @selectedProvider='aws'
 * />
 * ```
 *
 * @class NetworksFormInputsProviderCards
 *
 */

export default class NetworksFormInputsProviderCardsComponent extends Component {
  get providers() {
    let { providers } = this.args;
    return providers || ALL_PROVIDERS;
  }

  get providerLogo() {
    return PROVIDERS_ICONS_MAPPING;
  }
}
