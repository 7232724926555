import BaseAbility from './base-ability';
import { inject as service } from '@ember/service';
import { assert } from '@ember/debug';
import { TYPE_VAULT } from 'common/utils/cloud-resource-types';
import {
  PREFIX_VAULT_CLUSTERS,
  VAULT_CLUSTERS_CREATE,
  VAULT_CLUSTERS_UPDATE,
  VAULT_CLUSTERS_SCALE,
  VAULT_CLUSTERS_DELETE,
  VAULT_CLUSTERS_LOCK,
  VAULT_CLUSTERS_UNLOCK,
  VAULT_CLUSTERS_REVOKE_ADMIN_TOKENS,
  VAULT_CLUSTERS_CREATE_ADMIN_TOKEN,
  VAULT_PERFORMANCE_REPLICATION_DISABLE,
  VAULT_PERFORMANCE_REPLICATION_ENABLE,
  VAULT_PERFORMANCE_REPLICATION_LIST_SECONDARIES,
  VAULT_VERSION_UPGRADE_CONFIG_UPDATE,
  VAULT_VERSION_UPGRADE_MANUAL_UPGRADE,
} from '../utils/permission-types/index';
export default class extends BaseAbility {
  @service abilities;
  @service quota;
  @service regions;

  // the prefix is used to take advantage of BaseAbility's built in canGet, canUpdate etc getters
  prefix = PREFIX_VAULT_CLUSTERS;

  /**
   * Determine if create is available based on permissions, an array of
   * clusters, a quota, and billing status.
   *
   * @return {boolean}
   */
  get canCreate() {
    assert(
      `
        (can 'create vault') takes an array of clusters as an arg -> (can 'create vault' @model.clusters)
        you passed: ${this.model}
      `,
      Array.isArray(this.model),
    );

    return (
      this.abilities.can('bill billing-account') &&
      this.permissions.has(VAULT_CLUSTERS_CREATE) &&
      this.model?.length < this.quota.for(TYPE_VAULT) &&
      this.quota.for(TYPE_VAULT) !== 0
    );
  }

  get canEdit() {
    // this permission is intended for gating the edit configuration buttons/route access
    // since users with ONLY update permissions can still update their config,
    // and users with scaling permissions can update their config AND scale their cluster
    return this.canUpdate || this.canScale;
  }

  get canUpdate() {
    return this.permissions.has(VAULT_CLUSTERS_UPDATE);
  }

  get canScale() {
    return this.permissions.has(VAULT_CLUSTERS_SCALE);
  }

  get canDelete() {
    return this.permissions.has(VAULT_CLUSTERS_DELETE);
  }

  get canLock() {
    return this.permissions.has(VAULT_CLUSTERS_LOCK);
  }

  get canUnlock() {
    return this.permissions.has(VAULT_CLUSTERS_UNLOCK);
  }

  get canRevokeAdminTokens() {
    return this.permissions.has(VAULT_CLUSTERS_REVOKE_ADMIN_TOKENS);
  }

  get canCreateQuotaReached() {
    assert(
      `
        (can 'create quota reached vault') takes an array of clusters as an arg -> (can '...' @model.clusters)
        you passed: ${this.model}
      `,
      Array.isArray(this.model),
    );

    // This checks if the vault cluster length is equal to the quota limit
    return (
      this.permissions.has(VAULT_CLUSTERS_CREATE) &&
      this.model.length === this.quota.for(TYPE_VAULT) &&
      this.quota.for(TYPE_VAULT) !== 0
    );
  }

  get canQuickStart() {
    assert(
      `
        (can 'quick start vault') takes an array of clusters as an arg -> (can '...' @model.clusters)
        you passed: ${this.model}
      `,
      Array.isArray(this.model),
    );

    return (
      this.permissions.has(VAULT_CLUSTERS_CREATE) &&
      this.model.length < this.quota.for(TYPE_VAULT) &&
      this.quota.for(TYPE_VAULT) !== 0
    );
  }

  get canCreateAdminToken() {
    return this.permissions.has(VAULT_CLUSTERS_CREATE_ADMIN_TOKEN);
  }

  // to disable you must be able to:
  // - delete clusters
  // - disable replication (not visible action to the user)
  get canDisableReplication() {
    return (
      this.permissions.has(VAULT_PERFORMANCE_REPLICATION_DISABLE) &&
      this.permissions.has(VAULT_CLUSTERS_DELETE)
    );
  }

  // to enable you must be able to:
  // - create new clusters
  // - enable replication (not visible action to the user)
  get canEnableReplication() {
    return (
      this.permissions.has(VAULT_PERFORMANCE_REPLICATION_ENABLE) &&
      this.permissions.has(VAULT_CLUSTERS_CREATE)
    );
  }

  get canListReplicationSecondaries() {
    return this.permissions.has(VAULT_PERFORMANCE_REPLICATION_LIST_SECONDARIES);
  }

  get canEditUpgradeConfig() {
    return this.permissions.has(VAULT_VERSION_UPGRADE_CONFIG_UPDATE);
  }

  get canTriggerVersionUpdates() {
    return this.permissions.has(VAULT_VERSION_UPGRADE_MANUAL_UPGRADE);
  }
}
