/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { LogService20210330CreateArchiveRequestToJSON, LogService20210330CreateArchiveResponseFromJSON, LogService20210330CreateStreamingDestinationFilterRequestToJSON, LogService20210330CreateStreamingDestinationRequestToJSON, LogService20210330CreateStreamingDestinationResponseFromJSON, LogService20210330DeleteStreamingDestinationResponseFromJSON, LogService20210330GetArchiveResponseFromJSON, LogService20210330GetRequestToJSON, LogService20210330GetResponseFromJSON, LogService20210330GetStreamingDestinationResponseFromJSON, LogService20210330ListArchivesResponseFromJSON, LogService20210330ListStreamingDestinationsForResourceRequestToJSON, LogService20210330ListStreamingDestinationsForResourceResponseFromJSON, LogService20210330ListStreamingDestinationsResponseFromJSON, LogService20210330SearchRequestToJSON, LogService20210330SearchResponseFromJSON, LogService20210330StoreRequestToJSON, LogService20210330TestStreamingDestinationRequestToJSON, LogService20210330TestStreamingDestinationResponseFromJSON, LogService20210330UpdateStreamingDestinationRequestToJSON, } from '../models';
/**
 *
 */
export class LogServiceApi extends runtime.BaseAPI {
    /**
     * Create an archive request.
     */
    async logServiceCreateArchiveRaw(requestParameters) {
        if (requestParameters.resourceLocationOrganizationId === null || requestParameters.resourceLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('resourceLocationOrganizationId', 'Required parameter requestParameters.resourceLocationOrganizationId was null or undefined when calling logServiceCreateArchive.');
        }
        if (requestParameters.resourceLocationProjectId === null || requestParameters.resourceLocationProjectId === undefined) {
            throw new runtime.RequiredError('resourceLocationProjectId', 'Required parameter requestParameters.resourceLocationProjectId was null or undefined when calling logServiceCreateArchive.');
        }
        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId', 'Required parameter requestParameters.resourceId was null or undefined when calling logServiceCreateArchive.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling logServiceCreateArchive.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{resource.location.organization_id}/projects/{resource.location.project_id}/resources/{resource.id}/archives`.replace(`{${"resource.location.organization_id"}}`, encodeURIComponent(String(requestParameters.resourceLocationOrganizationId))).replace(`{${"resource.location.project_id"}}`, encodeURIComponent(String(requestParameters.resourceLocationProjectId))).replace(`{${"resource.id"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330CreateArchiveRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330CreateArchiveResponseFromJSON(jsonValue));
    }
    /**
     * Create an archive request.
     */
    async logServiceCreateArchive(resourceLocationOrganizationId, resourceLocationProjectId, resourceId, body) {
        const response = await this.logServiceCreateArchiveRaw({ resourceLocationOrganizationId: resourceLocationOrganizationId, resourceLocationProjectId: resourceLocationProjectId, resourceId: resourceId, body: body });
        return await response.value();
    }
    /**
     * Create a streaming destination for a given resource.
     */
    async logServiceCreateStreamingDestinationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling logServiceCreateStreamingDestination.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling logServiceCreateStreamingDestination.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling logServiceCreateStreamingDestination.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{location.organization_id}/projects/{location.project_id}/resources/destinations`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330CreateStreamingDestinationRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330CreateStreamingDestinationResponseFromJSON(jsonValue));
    }
    /**
     * Create a streaming destination for a given resource.
     */
    async logServiceCreateStreamingDestination(locationOrganizationId, locationProjectId, body) {
        const response = await this.logServiceCreateStreamingDestinationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     * Create a streaming destination for a given resource.
     */
    async logServiceCreateStreamingDestination2Raw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling logServiceCreateStreamingDestination2.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling logServiceCreateStreamingDestination2.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{location.organization_id}/resources/destinations`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330CreateStreamingDestinationRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330CreateStreamingDestinationResponseFromJSON(jsonValue));
    }
    /**
     * Create a streaming destination for a given resource.
     */
    async logServiceCreateStreamingDestination2(locationOrganizationId, body) {
        const response = await this.logServiceCreateStreamingDestination2Raw({ locationOrganizationId: locationOrganizationId, body: body });
        return await response.value();
    }
    /**
     * Create a streaming destination filter for a given resource.
     */
    async logServiceCreateStreamingDestinationFilterRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling logServiceCreateStreamingDestinationFilter.');
        }
        if (requestParameters.destinationId === null || requestParameters.destinationId === undefined) {
            throw new runtime.RequiredError('destinationId', 'Required parameter requestParameters.destinationId was null or undefined when calling logServiceCreateStreamingDestinationFilter.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling logServiceCreateStreamingDestinationFilter.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{organization_id}/resources/destinations/{destination_id}/filter`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"destination_id"}}`, encodeURIComponent(String(requestParameters.destinationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330CreateStreamingDestinationFilterRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Create a streaming destination filter for a given resource.
     */
    async logServiceCreateStreamingDestinationFilter(organizationId, destinationId, body) {
        const response = await this.logServiceCreateStreamingDestinationFilterRaw({ organizationId: organizationId, destinationId: destinationId, body: body });
        return await response.value();
    }
    /**
     * Delete a streaming destination by its project and id.
     */
    async logServiceDeleteStreamingDestinationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling logServiceDeleteStreamingDestination.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling logServiceDeleteStreamingDestination.');
        }
        if (requestParameters.destinationId === null || requestParameters.destinationId === undefined) {
            throw new runtime.RequiredError('destinationId', 'Required parameter requestParameters.destinationId was null or undefined when calling logServiceDeleteStreamingDestination.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.sourceChannel !== undefined) {
            queryParameters['source_channel'] = requestParameters.sourceChannel;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{location.organization_id}/projects/{location.project_id}/resources/destinations/{destination_id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"destination_id"}}`, encodeURIComponent(String(requestParameters.destinationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330DeleteStreamingDestinationResponseFromJSON(jsonValue));
    }
    /**
     * Delete a streaming destination by its project and id.
     */
    async logServiceDeleteStreamingDestination(locationOrganizationId, locationProjectId, destinationId, locationRegionProvider, locationRegionRegion, sourceChannel) {
        const response = await this.logServiceDeleteStreamingDestinationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, destinationId: destinationId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, sourceChannel: sourceChannel });
        return await response.value();
    }
    /**
     * Get log entries from one or more log streams.
     */
    async logServiceGetRaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling logServiceGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/entries/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330GetRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330GetResponseFromJSON(jsonValue));
    }
    /**
     * Get log entries from one or more log streams.
     */
    async logServiceGet(body) {
        const response = await this.logServiceGetRaw({ body: body });
        return await response.value();
    }
    /**
     * List archives for a given resource.
     */
    async logServiceGetArchiveRaw(requestParameters) {
        if (requestParameters.resourceLocationOrganizationId === null || requestParameters.resourceLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('resourceLocationOrganizationId', 'Required parameter requestParameters.resourceLocationOrganizationId was null or undefined when calling logServiceGetArchive.');
        }
        if (requestParameters.resourceLocationProjectId === null || requestParameters.resourceLocationProjectId === undefined) {
            throw new runtime.RequiredError('resourceLocationProjectId', 'Required parameter requestParameters.resourceLocationProjectId was null or undefined when calling logServiceGetArchive.');
        }
        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId', 'Required parameter requestParameters.resourceId was null or undefined when calling logServiceGetArchive.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling logServiceGetArchive.');
        }
        const queryParameters = {};
        if (requestParameters.resourceType !== undefined) {
            queryParameters['resource.type'] = requestParameters.resourceType;
        }
        if (requestParameters.resourceUuid !== undefined) {
            queryParameters['resource.uuid'] = requestParameters.resourceUuid;
        }
        if (requestParameters.resourceLocationRegionProvider !== undefined) {
            queryParameters['resource.location.region.provider'] = requestParameters.resourceLocationRegionProvider;
        }
        if (requestParameters.resourceLocationRegionRegion !== undefined) {
            queryParameters['resource.location.region.region'] = requestParameters.resourceLocationRegionRegion;
        }
        if (requestParameters.resourceDescription !== undefined) {
            queryParameters['resource.description'] = requestParameters.resourceDescription;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{resource.location.organization_id}/projects/{resource.location.project_id}/resources/{resource.id}/archives/{id}`.replace(`{${"resource.location.organization_id"}}`, encodeURIComponent(String(requestParameters.resourceLocationOrganizationId))).replace(`{${"resource.location.project_id"}}`, encodeURIComponent(String(requestParameters.resourceLocationProjectId))).replace(`{${"resource.id"}}`, encodeURIComponent(String(requestParameters.resourceId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330GetArchiveResponseFromJSON(jsonValue));
    }
    /**
     * List archives for a given resource.
     */
    async logServiceGetArchive(resourceLocationOrganizationId, resourceLocationProjectId, resourceId, id, resourceType, resourceUuid, resourceLocationRegionProvider, resourceLocationRegionRegion, resourceDescription) {
        const response = await this.logServiceGetArchiveRaw({ resourceLocationOrganizationId: resourceLocationOrganizationId, resourceLocationProjectId: resourceLocationProjectId, resourceId: resourceId, id: id, resourceType: resourceType, resourceUuid: resourceUuid, resourceLocationRegionProvider: resourceLocationRegionProvider, resourceLocationRegionRegion: resourceLocationRegionRegion, resourceDescription: resourceDescription });
        return await response.value();
    }
    /**
     * Get a streaming destination by its project and id.
     */
    async logServiceGetStreamingDestinationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling logServiceGetStreamingDestination.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling logServiceGetStreamingDestination.');
        }
        if (requestParameters.destinationId === null || requestParameters.destinationId === undefined) {
            throw new runtime.RequiredError('destinationId', 'Required parameter requestParameters.destinationId was null or undefined when calling logServiceGetStreamingDestination.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{location.organization_id}/projects/{location.project_id}/resources/destinations/{destination_id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"destination_id"}}`, encodeURIComponent(String(requestParameters.destinationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330GetStreamingDestinationResponseFromJSON(jsonValue));
    }
    /**
     * Get a streaming destination by its project and id.
     */
    async logServiceGetStreamingDestination(locationOrganizationId, locationProjectId, destinationId, locationRegionProvider, locationRegionRegion) {
        const response = await this.logServiceGetStreamingDestinationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, destinationId: destinationId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * Get a streaming destination by its project and id.
     */
    async logServiceGetStreamingDestination2Raw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling logServiceGetStreamingDestination2.');
        }
        if (requestParameters.destinationId === null || requestParameters.destinationId === undefined) {
            throw new runtime.RequiredError('destinationId', 'Required parameter requestParameters.destinationId was null or undefined when calling logServiceGetStreamingDestination2.');
        }
        const queryParameters = {};
        if (requestParameters.locationProjectId !== undefined) {
            queryParameters['location.project_id'] = requestParameters.locationProjectId;
        }
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{location.organization_id}/resources/destinations/{destination_id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"destination_id"}}`, encodeURIComponent(String(requestParameters.destinationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330GetStreamingDestinationResponseFromJSON(jsonValue));
    }
    /**
     * Get a streaming destination by its project and id.
     */
    async logServiceGetStreamingDestination2(locationOrganizationId, destinationId, locationProjectId, locationRegionProvider, locationRegionRegion) {
        const response = await this.logServiceGetStreamingDestination2Raw({ locationOrganizationId: locationOrganizationId, destinationId: destinationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * List archives for a given resource.
     */
    async logServiceListArchivesRaw(requestParameters) {
        if (requestParameters.resourceLocationOrganizationId === null || requestParameters.resourceLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('resourceLocationOrganizationId', 'Required parameter requestParameters.resourceLocationOrganizationId was null or undefined when calling logServiceListArchives.');
        }
        if (requestParameters.resourceLocationProjectId === null || requestParameters.resourceLocationProjectId === undefined) {
            throw new runtime.RequiredError('resourceLocationProjectId', 'Required parameter requestParameters.resourceLocationProjectId was null or undefined when calling logServiceListArchives.');
        }
        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId', 'Required parameter requestParameters.resourceId was null or undefined when calling logServiceListArchives.');
        }
        const queryParameters = {};
        if (requestParameters.resourceType !== undefined) {
            queryParameters['resource.type'] = requestParameters.resourceType;
        }
        if (requestParameters.resourceUuid !== undefined) {
            queryParameters['resource.uuid'] = requestParameters.resourceUuid;
        }
        if (requestParameters.resourceLocationRegionProvider !== undefined) {
            queryParameters['resource.location.region.provider'] = requestParameters.resourceLocationRegionProvider;
        }
        if (requestParameters.resourceLocationRegionRegion !== undefined) {
            queryParameters['resource.location.region.region'] = requestParameters.resourceLocationRegionRegion;
        }
        if (requestParameters.resourceDescription !== undefined) {
            queryParameters['resource.description'] = requestParameters.resourceDescription;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{resource.location.organization_id}/projects/{resource.location.project_id}/resources/{resource.id}/archives`.replace(`{${"resource.location.organization_id"}}`, encodeURIComponent(String(requestParameters.resourceLocationOrganizationId))).replace(`{${"resource.location.project_id"}}`, encodeURIComponent(String(requestParameters.resourceLocationProjectId))).replace(`{${"resource.id"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330ListArchivesResponseFromJSON(jsonValue));
    }
    /**
     * List archives for a given resource.
     */
    async logServiceListArchives(resourceLocationOrganizationId, resourceLocationProjectId, resourceId, resourceType, resourceUuid, resourceLocationRegionProvider, resourceLocationRegionRegion, resourceDescription) {
        const response = await this.logServiceListArchivesRaw({ resourceLocationOrganizationId: resourceLocationOrganizationId, resourceLocationProjectId: resourceLocationProjectId, resourceId: resourceId, resourceType: resourceType, resourceUuid: resourceUuid, resourceLocationRegionProvider: resourceLocationRegionProvider, resourceLocationRegionRegion: resourceLocationRegionRegion, resourceDescription: resourceDescription });
        return await response.value();
    }
    /**
     * List streaming destinations for a given location.
     */
    async logServiceListStreamingDestinationsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling logServiceListStreamingDestinations.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling logServiceListStreamingDestinations.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{location.organization_id}/projects/{location.project_id}/resources/destinations`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330ListStreamingDestinationsResponseFromJSON(jsonValue));
    }
    /**
     * List streaming destinations for a given location.
     */
    async logServiceListStreamingDestinations(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion) {
        const response = await this.logServiceListStreamingDestinationsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * List streaming destinations for a given location.
     */
    async logServiceListStreamingDestinations2Raw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling logServiceListStreamingDestinations2.');
        }
        const queryParameters = {};
        if (requestParameters.locationProjectId !== undefined) {
            queryParameters['location.project_id'] = requestParameters.locationProjectId;
        }
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{location.organization_id}/resources/destinations`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330ListStreamingDestinationsResponseFromJSON(jsonValue));
    }
    /**
     * List streaming destinations for a given location.
     */
    async logServiceListStreamingDestinations2(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion) {
        const response = await this.logServiceListStreamingDestinations2Raw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async logServiceListStreamingDestinationsForResourceRaw(requestParameters) {
        if (requestParameters.resourceLocationOrganizationId === null || requestParameters.resourceLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('resourceLocationOrganizationId', 'Required parameter requestParameters.resourceLocationOrganizationId was null or undefined when calling logServiceListStreamingDestinationsForResource.');
        }
        if (requestParameters.resourceLocationProjectId === null || requestParameters.resourceLocationProjectId === undefined) {
            throw new runtime.RequiredError('resourceLocationProjectId', 'Required parameter requestParameters.resourceLocationProjectId was null or undefined when calling logServiceListStreamingDestinationsForResource.');
        }
        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId', 'Required parameter requestParameters.resourceId was null or undefined when calling logServiceListStreamingDestinationsForResource.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling logServiceListStreamingDestinationsForResource.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{resource.location.organization_id}/projects/{resource.location.project_id}/resources/{resource.id}/destinations`.replace(`{${"resource.location.organization_id"}}`, encodeURIComponent(String(requestParameters.resourceLocationOrganizationId))).replace(`{${"resource.location.project_id"}}`, encodeURIComponent(String(requestParameters.resourceLocationProjectId))).replace(`{${"resource.id"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330ListStreamingDestinationsForResourceRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330ListStreamingDestinationsForResourceResponseFromJSON(jsonValue));
    }
    /**
     */
    async logServiceListStreamingDestinationsForResource(resourceLocationOrganizationId, resourceLocationProjectId, resourceId, body) {
        const response = await this.logServiceListStreamingDestinationsForResourceRaw({ resourceLocationOrganizationId: resourceLocationOrganizationId, resourceLocationProjectId: resourceLocationProjectId, resourceId: resourceId, body: body });
        return await response.value();
    }
    /**
     * Search for log entries in a public tenant.
     */
    async logServiceSearchRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling logServiceSearch.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling logServiceSearch.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2022-06-06/organizations/{organization_id}/entries/preview/search`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330SearchRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330SearchResponseFromJSON(jsonValue));
    }
    /**
     * Search for log entries in a public tenant.
     */
    async logServiceSearch(organizationId, body) {
        const response = await this.logServiceSearchRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * Store for log entries in a public tenant.
     */
    async logServiceStoreRaw(requestParameters) {
        if (requestParameters.streamOrganizationId === null || requestParameters.streamOrganizationId === undefined) {
            throw new runtime.RequiredError('streamOrganizationId', 'Required parameter requestParameters.streamOrganizationId was null or undefined when calling logServiceStore.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling logServiceStore.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{stream.organization_id}/entries/store`.replace(`{${"stream.organization_id"}}`, encodeURIComponent(String(requestParameters.streamOrganizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330StoreRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Store for log entries in a public tenant.
     */
    async logServiceStore(streamOrganizationId, body) {
        const response = await this.logServiceStoreRaw({ streamOrganizationId: streamOrganizationId, body: body });
        return await response.value();
    }
    /**
     * Test a streaming destination config record by sending a test record to the provided configuration
     */
    async logServiceTestStreamingDestinationRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling logServiceTestStreamingDestination.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling logServiceTestStreamingDestination.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling logServiceTestStreamingDestination.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{location.organization_id}/projects/{location.project_id}/resources/destinations/test`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330TestStreamingDestinationRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330TestStreamingDestinationResponseFromJSON(jsonValue));
    }
    /**
     * Test a streaming destination config record by sending a test record to the provided configuration
     */
    async logServiceTestStreamingDestination(locationOrganizationId, locationProjectId, body) {
        const response = await this.logServiceTestStreamingDestinationRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     * Update a streaming destination by its id.
     */
    async logServiceUpdateStreamingDestinationRaw(requestParameters) {
        if (requestParameters.destinationResourceLocationOrganizationId === null || requestParameters.destinationResourceLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('destinationResourceLocationOrganizationId', 'Required parameter requestParameters.destinationResourceLocationOrganizationId was null or undefined when calling logServiceUpdateStreamingDestination.');
        }
        if (requestParameters.destinationResourceLocationProjectId === null || requestParameters.destinationResourceLocationProjectId === undefined) {
            throw new runtime.RequiredError('destinationResourceLocationProjectId', 'Required parameter requestParameters.destinationResourceLocationProjectId was null or undefined when calling logServiceUpdateStreamingDestination.');
        }
        if (requestParameters.destinationResourceId === null || requestParameters.destinationResourceId === undefined) {
            throw new runtime.RequiredError('destinationResourceId', 'Required parameter requestParameters.destinationResourceId was null or undefined when calling logServiceUpdateStreamingDestination.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling logServiceUpdateStreamingDestination.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{destination.resource.location.organization_id}/projects/{destination.resource.location.project_id}/resources/destinations/{destination.resource.id}`.replace(`{${"destination.resource.location.organization_id"}}`, encodeURIComponent(String(requestParameters.destinationResourceLocationOrganizationId))).replace(`{${"destination.resource.location.project_id"}}`, encodeURIComponent(String(requestParameters.destinationResourceLocationProjectId))).replace(`{${"destination.resource.id"}}`, encodeURIComponent(String(requestParameters.destinationResourceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330UpdateStreamingDestinationRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Update a streaming destination by its id.
     */
    async logServiceUpdateStreamingDestination(destinationResourceLocationOrganizationId, destinationResourceLocationProjectId, destinationResourceId, body) {
        const response = await this.logServiceUpdateStreamingDestinationRaw({ destinationResourceLocationOrganizationId: destinationResourceLocationOrganizationId, destinationResourceLocationProjectId: destinationResourceLocationProjectId, destinationResourceId: destinationResourceId, body: body });
        return await response.value();
    }
}
