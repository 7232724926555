/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Webhook Service
 * API for delivering and managing webhooks.
 *
 * The version of the OpenAPI document: 2023-05-31
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWebhookWebhookConfigFromJSON(json) {
    return HashicorpCloudWebhookWebhookConfigFromJSONTyped(json, false);
}
export function HashicorpCloudWebhookWebhookConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hmacKey': !exists(json, 'hmac_key') ? undefined : json['hmac_key'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}
export function HashicorpCloudWebhookWebhookConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hmac_key': value.hmacKey,
        'url': value.url,
    };
}
