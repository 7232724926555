import { helper } from '@ember/component/helper';
import { assert } from '@ember/debug';

import * as resourceTypes from 'common/utils/cloud-resource-types';

export const RESOURCE_TYPE_MAPPING = {
  network: resourceTypes.TYPE_NETWORK,
  'network-route': resourceTypes.TYPE_NETWORK_ROUTE,
  'network-peering': resourceTypes.TYPE_NETWORK_PEERING,
  'network-tgw-attachment':
    resourceTypes.TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
  vault: resourceTypes.TYPE_VAULT,
  'vault-snapshot': resourceTypes.TYPE_VAULT_SNAPSHOT,
  'vault-secrets': resourceTypes.TYPE_VAULT_SECRETS_PROJECT,
  consul: resourceTypes.TYPE_CONSUL,
  'consul-snapshot': resourceTypes.TYPE_CONSUL_SNAPSHOT,
  'service-principal': resourceTypes.TYPE_SERVICE_PRINCIPAL,
  'service-principal-key': resourceTypes.TYPE_SERVICE_PRINCIPAL_KEY,
  organization: resourceTypes.TYPE_ORGANIZATION,
  project: resourceTypes.TYPE_PROJECT,
  tfc: resourceTypes.TYPE_TERRAFORM_ORGANIZATION,
  tfcb: resourceTypes.TYPE_TERRAFORM,
  boundary: resourceTypes.TYPE_BOUNDARY,
  packer: resourceTypes.TYPE_PACKER,
  'packer-version': resourceTypes.TYPE_PACKER_VERSION,
};

export const RESOURCE_TYPE_MAPPING_SCALE = Object.keys(RESOURCE_TYPE_MAPPING);

export function resourceTypeFor([type]) {
  const resourceType = RESOURCE_TYPE_MAPPING[type];

  assert(
    `\`type\` must be one of the following: ${Object.keys(
      RESOURCE_TYPE_MAPPING
    ).join(', ')}`,
    RESOURCE_TYPE_MAPPING_SCALE.includes(type)
  );

  return resourceType && typeof resourceType === 'string'
    ? resourceType.slice(0)
    : resourceType;
}

export default helper(resourceTypeFor);
