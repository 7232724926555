/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudNetwork20200907PeeringFromJSON, HashicorpCloudNetwork20200907PeeringToJSON, } from './';
export function HashicorpCloudNetwork20200907CreatePeeringRequestFromJSON(json) {
    return HashicorpCloudNetwork20200907CreatePeeringRequestFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907CreatePeeringRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'peering': !exists(json, 'peering') ? undefined : HashicorpCloudNetwork20200907PeeringFromJSON(json['peering']),
    };
}
export function HashicorpCloudNetwork20200907CreatePeeringRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'peering': HashicorpCloudNetwork20200907PeeringToJSON(value.peering),
    };
}
