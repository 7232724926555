import type { IPolicyLocationLink } from '../types/policy-location-link.ts';

export interface Location {
  link: IPolicyLocationLink;
  [key: string]: string | number | IPolicyLocationLink;
}

export interface Options {
  type?: string;
}

export default function iamGetLocationByLinkType(
  locations: Array<Location>,
  options?: Options,
): Location | undefined {
  if (!locations || !Array.isArray(locations) || locations.length === 0) {
    return undefined;
  }

  const { type } = options ?? {};

  if (type) {
    return locations.find(({ link }) => link.type === type);
  }

  return locations[locations.length - 1];
}
