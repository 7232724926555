/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Webhook Service
 * API for delivering and managing webhooks.
 *
 * The version of the OpenAPI document: 2023-05-31
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWebhookWebhookRequestFromJSON, HashicorpCloudWebhookWebhookRequestToJSON, HashicorpCloudWebhookWebhookResponseFromJSON, HashicorpCloudWebhookWebhookResponseToJSON, } from './';
export function HashicorpCloudWebhookWebhookDeliveryFromJSON(json) {
    return HashicorpCloudWebhookWebhookDeliveryFromJSONTyped(json, false);
}
export function HashicorpCloudWebhookWebhookDeliveryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'webhookResourceId': !exists(json, 'webhook_resource_id') ? undefined : json['webhook_resource_id'],
        'request': !exists(json, 'request') ? undefined : HashicorpCloudWebhookWebhookRequestFromJSON(json['request']),
        'response': !exists(json, 'response') ? undefined : HashicorpCloudWebhookWebhookResponseFromJSON(json['response']),
        'retries': !exists(json, 'retries') ? undefined : json['retries'],
    };
}
export function HashicorpCloudWebhookWebhookDeliveryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'webhook_resource_id': value.webhookResourceId,
        'request': HashicorpCloudWebhookWebhookRequestToJSON(value.request),
        'response': HashicorpCloudWebhookWebhookResponseToJSON(value.response),
        'retries': value.retries,
    };
}
