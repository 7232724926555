import templateOnlyComponent from '@ember/component/template-only';

interface ModalDialogFooterCancelSignature {
  Args: {
    closeModalDialog?: unknown;
    text?: unknown;
  };
  Element: HTMLElement;
}

const ModalDialogFooterCancelComponent =
  templateOnlyComponent<ModalDialogFooterCancelSignature>();

export default ModalDialogFooterCancelComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ModalDialog::Footer::Cancel': typeof ModalDialogFooterCancelComponent;
    'modal-dialog/footer/cancel': typeof ModalDialogFooterCancelComponent;
  }
}
