/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionConfigFromJSON, HashicorpCloudWaypointActionConfigToJSON, HashicorpCloudWaypointActionRunFromJSON, HashicorpCloudWaypointActionRunToJSON, HashicorpCloudWaypointGetActionConfigResponseTotalRunsFromJSON, HashicorpCloudWaypointGetActionConfigResponseTotalRunsToJSON, } from './';
export function HashicorpCloudWaypointGetActionConfigResponseFromJSON(json) {
    return HashicorpCloudWaypointGetActionConfigResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointGetActionConfigResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'actionConfig': !exists(json, 'action_config') ? undefined : HashicorpCloudWaypointActionConfigFromJSON(json['action_config']),
        'totalRuns': !exists(json, 'total_runs') ? undefined : HashicorpCloudWaypointGetActionConfigResponseTotalRunsFromJSON(json['total_runs']),
        'latestRun': !exists(json, 'latest_run') ? undefined : HashicorpCloudWaypointActionRunFromJSON(json['latest_run']),
    };
}
export function HashicorpCloudWaypointGetActionConfigResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'action_config': HashicorpCloudWaypointActionConfigToJSON(value.actionConfig),
        'total_runs': HashicorpCloudWaypointGetActionConfigResponseTotalRunsToJSON(value.totalRuns),
        'latest_run': HashicorpCloudWaypointActionRunToJSON(value.latestRun),
    };
}
