/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudBoundary20211221UpdateRequestFromJSON(json) {
    return HashicorpCloudBoundary20211221UpdateRequestFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221UpdateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
    };
}
export function HashicorpCloudBoundary20211221UpdateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'cluster_id': value.clusterId,
    };
}
