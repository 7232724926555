/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointVariableScopeFromJSON, HashicorpCloudWaypointVariableScopeToJSON, HashicorpCloudWaypointVariableTypeFromJSON, HashicorpCloudWaypointVariableTypeToJSON, } from './';
export function HashicorpCloudWaypointVariableFromJSON(json) {
    return HashicorpCloudWaypointVariableFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointVariableFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'defaultValue': !exists(json, 'default_value') ? undefined : json['default_value'],
        'sensitive': !exists(json, 'sensitive') ? undefined : json['sensitive'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : HashicorpCloudWaypointVariableTypeFromJSON(json['type']),
        'scope': !exists(json, 'scope') ? undefined : HashicorpCloudWaypointVariableScopeFromJSON(json['scope']),
        'overridable': !exists(json, 'overridable') ? undefined : json['overridable'],
    };
}
export function HashicorpCloudWaypointVariableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'key': value.key,
        'value': value.value,
        'default_value': value.defaultValue,
        'sensitive': value.sensitive,
        'description': value.description,
        'type': HashicorpCloudWaypointVariableTypeToJSON(value.type),
        'scope': HashicorpCloudWaypointVariableScopeToJSON(value.scope),
        'overridable': value.overridable,
    };
}
