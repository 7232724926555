/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Operations
 * API for managing HCP operations.
 *
 * The version of the OpenAPI document: 2020-05-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import {
  HashicorpCloudOperationGetResponseFromJSON,
  HashicorpCloudOperationListResponseFromJSON,
  HashicorpCloudOperationWaitResponseFromJSON,
} from '../models';
/**
 *
 */
export class OperationServiceApi extends runtime.BaseAPI {
  /**
   * The Location\'s organization and project IDs are included in the path in order to support RBAC checks. We perform RBAC checks early in the request lifecycle, before loading the resource targeted by the request.
   * Get returns the latest state of a specific operation.
   */
  async getRaw(requestParameters) {
    if (
      requestParameters.locationOrganizationId === null ||
      requestParameters.locationOrganizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'locationOrganizationId',
        'Required parameter requestParameters.locationOrganizationId was null or undefined when calling get.'
      );
    }
    if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
      throw new runtime.RequiredError(
        'locationProjectId',
        'Required parameter requestParameters.locationProjectId was null or undefined when calling get.'
      );
    }
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling get.'
      );
    }
    const queryParameters = {};
    if (requestParameters.locationRegionProvider !== undefined) {
      queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
    }
    if (requestParameters.locationRegionRegion !== undefined) {
      queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
    }
    const headerParameters = {};
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = this.configuration.apiKey('Authorization'); // bearer authentication
    }
    const response = await this.request({
      path: `/organizations/{location.organization_id}/projects/{location.project_id}/operations/{id}`
        .replace(
          `{${'location.organization_id'}}`,
          encodeURIComponent(String(requestParameters.locationOrganizationId))
        )
        .replace(
          `{${'location.project_id'}}`,
          encodeURIComponent(String(requestParameters.locationProjectId))
        )
        .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });
    return new runtime.JSONApiResponse(response, jsonValue =>
      HashicorpCloudOperationGetResponseFromJSON(jsonValue)
    );
  }
  /**
   * The Location\'s organization and project IDs are included in the path in order to support RBAC checks. We perform RBAC checks early in the request lifecycle, before loading the resource targeted by the request.
   * Get returns the latest state of a specific operation.
   */
  async get(locationOrganizationId, locationProjectId, id, locationRegionProvider, locationRegionRegion) {
    const response = await this.getRaw({
      locationOrganizationId: locationOrganizationId,
      locationProjectId: locationProjectId,
      id: id,
      locationRegionProvider: locationRegionProvider,
      locationRegionRegion: locationRegionRegion,
    });
    return await response.value();
  }
  /**
   * List selects a list of operations that match some filters and then returns them in the response. The supported filters are the Location\'s organization_id and project_id. The project_id supports the special value \"-\" to allow requesting operations that match any project within the organization.
   */
  async listRaw(requestParameters) {
    if (
      requestParameters.locationOrganizationId === null ||
      requestParameters.locationOrganizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'locationOrganizationId',
        'Required parameter requestParameters.locationOrganizationId was null or undefined when calling list.'
      );
    }
    if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
      throw new runtime.RequiredError(
        'locationProjectId',
        'Required parameter requestParameters.locationProjectId was null or undefined when calling list.'
      );
    }
    const queryParameters = {};
    if (requestParameters.locationRegionProvider !== undefined) {
      queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
    }
    if (requestParameters.locationRegionRegion !== undefined) {
      queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
    }
    if (requestParameters.paginationPageSize !== undefined) {
      queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
    }
    if (requestParameters.paginationNextPageToken !== undefined) {
      queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
    }
    if (requestParameters.paginationPreviousPageToken !== undefined) {
      queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
    }
    if (requestParameters.linkedResourceType !== undefined) {
      queryParameters['linked_resource.type'] = requestParameters.linkedResourceType;
    }
    if (requestParameters.linkedResourceId !== undefined) {
      queryParameters['linked_resource.id'] = requestParameters.linkedResourceId;
    }
    if (requestParameters.linkedResourceUuid !== undefined) {
      queryParameters['linked_resource.uuid'] = requestParameters.linkedResourceUuid;
    }
    if (requestParameters.linkedResourceLocationRegionProvider !== undefined) {
      queryParameters['linked_resource.location.region.provider'] =
        requestParameters.linkedResourceLocationRegionProvider;
    }
    if (requestParameters.linkedResourceLocationRegionRegion !== undefined) {
      queryParameters['linked_resource.location.region.region'] =
        requestParameters.linkedResourceLocationRegionRegion;
    }
    if (requestParameters.linkedResourceDescription !== undefined) {
      queryParameters['linked_resource.description'] = requestParameters.linkedResourceDescription;
    }
    const headerParameters = {};
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = this.configuration.apiKey('Authorization'); // bearer authentication
    }
    const response = await this.request({
      path: `/organizations/{location.organization_id}/projects/{location.project_id}/operations`
        .replace(
          `{${'location.organization_id'}}`,
          encodeURIComponent(String(requestParameters.locationOrganizationId))
        )
        .replace(
          `{${'location.project_id'}}`,
          encodeURIComponent(String(requestParameters.locationProjectId))
        ),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });
    return new runtime.JSONApiResponse(response, jsonValue =>
      HashicorpCloudOperationListResponseFromJSON(jsonValue)
    );
  }
  /**
   * List selects a list of operations that match some filters and then returns them in the response. The supported filters are the Location\'s organization_id and project_id. The project_id supports the special value \"-\" to allow requesting operations that match any project within the organization.
   */
  async list(
    locationOrganizationId,
    locationProjectId,
    locationRegionProvider,
    locationRegionRegion,
    paginationPageSize,
    paginationNextPageToken,
    paginationPreviousPageToken,
    linkedResourceType,
    linkedResourceId,
    linkedResourceUuid,
    linkedResourceLocationRegionProvider,
    linkedResourceLocationRegionRegion,
    linkedResourceDescription
  ) {
    const response = await this.listRaw({
      locationOrganizationId: locationOrganizationId,
      locationProjectId: locationProjectId,
      locationRegionProvider: locationRegionProvider,
      locationRegionRegion: locationRegionRegion,
      paginationPageSize: paginationPageSize,
      paginationNextPageToken: paginationNextPageToken,
      paginationPreviousPageToken: paginationPreviousPageToken,
      linkedResourceType: linkedResourceType,
      linkedResourceId: linkedResourceId,
      linkedResourceUuid: linkedResourceUuid,
      linkedResourceLocationRegionProvider: linkedResourceLocationRegionProvider,
      linkedResourceLocationRegionRegion: linkedResourceLocationRegionRegion,
      linkedResourceDescription: linkedResourceDescription,
    });
    return await response.value();
  }
  /**
   * Note that Wait may return at any time. In most cases, the timeout will be respected but it is not guaranteed. Therefore, clients should always check the state of the returned operation. A return from Wait does not guarantee the operation is DONE.  The Location\'s organization and project IDs are included in the path in order to support RBAC checks. We perform RBAC checks early in the request lifecycle, before loading the resource targeted by the request.
   * Wait blocks until an operation is DONE or a timeout is reached, whichever occurs first. It then returns the latest state of the operation that it had at the time of the timeout.
   */
  async waitRaw(requestParameters) {
    if (
      requestParameters.locationOrganizationId === null ||
      requestParameters.locationOrganizationId === undefined
    ) {
      throw new runtime.RequiredError(
        'locationOrganizationId',
        'Required parameter requestParameters.locationOrganizationId was null or undefined when calling wait.'
      );
    }
    if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
      throw new runtime.RequiredError(
        'locationProjectId',
        'Required parameter requestParameters.locationProjectId was null or undefined when calling wait.'
      );
    }
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling wait.'
      );
    }
    const queryParameters = {};
    if (requestParameters.timeout !== undefined) {
      queryParameters['timeout'] = requestParameters.timeout;
    }
    if (requestParameters.locationRegionProvider !== undefined) {
      queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
    }
    if (requestParameters.locationRegionRegion !== undefined) {
      queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
    }
    const headerParameters = {};
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = this.configuration.apiKey('Authorization'); // bearer authentication
    }
    const response = await this.request({
      path: `/organizations/{location.organization_id}/projects/{location.project_id}/operations/{id}/wait`
        .replace(
          `{${'location.organization_id'}}`,
          encodeURIComponent(String(requestParameters.locationOrganizationId))
        )
        .replace(
          `{${'location.project_id'}}`,
          encodeURIComponent(String(requestParameters.locationProjectId))
        )
        .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });
    return new runtime.JSONApiResponse(response, jsonValue =>
      HashicorpCloudOperationWaitResponseFromJSON(jsonValue)
    );
  }
  /**
   * Note that Wait may return at any time. In most cases, the timeout will be respected but it is not guaranteed. Therefore, clients should always check the state of the returned operation. A return from Wait does not guarantee the operation is DONE.  The Location\'s organization and project IDs are included in the path in order to support RBAC checks. We perform RBAC checks early in the request lifecycle, before loading the resource targeted by the request.
   * Wait blocks until an operation is DONE or a timeout is reached, whichever occurs first. It then returns the latest state of the operation that it had at the time of the timeout.
   */
  async wait(
    locationOrganizationId,
    locationProjectId,
    id,
    timeout,
    locationRegionProvider,
    locationRegionRegion
  ) {
    const response = await this.waitRaw({
      locationOrganizationId: locationOrganizationId,
      locationProjectId: locationProjectId,
      id: id,
      timeout: timeout,
      locationRegionProvider: locationRegionProvider,
      locationRegionRegion: locationRegionRegion,
    });
    return await response.value();
  }
}
