// Utils
import {
  CHANGE_SUMMARY_TYPE_ADDED,
  CHANGE_SUMMARY_TYPE_NO_CHANGE,
  CHANGE_SUMMARY_TYPE_REMOVED,
} from '../constants/change-summary.ts';

// Types
import type {
  HashicorpCloudResourcemanagerPolicyBinding,
  HashicorpCloudResourcemanagerPolicyBindingMember,
} from '@clients/cloud-resource-manager';

import type {
  PolicyChange,
  PolicyBindingMap,
  BindingsToMapFunction,
  IamPolicySummaryChangesFunction,
} from '../types/iam-policy-summary-changes.ts';

const BINDING_DELIMITER = '::';

const bindingsToMap: BindingsToMapFunction = (
  bindings: HashicorpCloudResourcemanagerPolicyBinding[],
): PolicyBindingMap => {
  return bindings.reduce<PolicyBindingMap>((acc, binding) => {
    const memberStrings = binding.members
      ? binding.members.map(
          (member) =>
            `${member.memberType}${BINDING_DELIMITER}${member.memberId}`,
        )
      : [];

    acc.set(binding.roleId, memberStrings);
    return acc;
  }, new Map());
};

const recordChanges = (
  oldMap: PolicyBindingMap,
  newMap: PolicyBindingMap,
  type: PolicyChange['type'],
  memberIds:
    | Array<HashicorpCloudResourcemanagerPolicyBindingMember['memberId']>
    | undefined,
): PolicyChange[] => {
  const changes: PolicyChange[] = [];

  oldMap.forEach((members, roleId) => {
    const newMembers = newMap.get(roleId) || [];
    members.forEach((member) => {
      const [memberType, memberId] = member.split(BINDING_DELIMITER);
      if (
        !newMembers.includes(member) &&
        (!memberIds || memberIds.includes(memberId))
      ) {
        changes.push({
          type,
          roleId,
          memberId:
            memberId as HashicorpCloudResourcemanagerPolicyBindingMember['memberId'],
          memberType:
            memberType as HashicorpCloudResourcemanagerPolicyBindingMember['memberType'],
        });
      }
    });
  });

  return changes;
};

const findUnchangedMembers = (
  oldMap: PolicyBindingMap,
  newMap: PolicyBindingMap,
  memberIds:
    | Array<HashicorpCloudResourcemanagerPolicyBindingMember['memberId']>
    | undefined,
): PolicyChange[] => {
  const unchanged: PolicyChange[] = [];

  oldMap.forEach((members, roleId) => {
    const newMembers = newMap.get(roleId) || [];
    members.forEach((member) => {
      const [memberType, memberId] = member.split(BINDING_DELIMITER);
      if (
        newMembers.includes(member) &&
        (!memberIds || memberIds.includes(memberId))
      ) {
        unchanged.push({
          type: CHANGE_SUMMARY_TYPE_NO_CHANGE,
          roleId,
          memberId:
            memberId as HashicorpCloudResourcemanagerPolicyBindingMember['memberId'],
          memberType:
            memberType as HashicorpCloudResourcemanagerPolicyBindingMember['memberType'],
        });
      }
    });
  });

  return unchanged;
};

const iamPolicySummaryChanges: IamPolicySummaryChangesFunction = function (
  oldPolicy,
  newPolicy,
  options,
) {
  const changes: PolicyChange[] = [];

  if (!oldPolicy?.bindings || !newPolicy?.bindings) {
    return { changes };
  }

  const oldBindingsMap = bindingsToMap(oldPolicy.bindings);
  const newBindingsMap = bindingsToMap(newPolicy.bindings);
  const memberIds = options?.memberIds;

  const removedChanges = recordChanges(
    oldBindingsMap,
    newBindingsMap,
    CHANGE_SUMMARY_TYPE_REMOVED,
    memberIds,
  );

  const addedChanges = recordChanges(
    newBindingsMap,
    oldBindingsMap,
    CHANGE_SUMMARY_TYPE_ADDED,
    memberIds,
  );

  const noChanges = findUnchangedMembers(
    oldBindingsMap,
    newBindingsMap,
    memberIds,
  );

  if (removedChanges.length || addedChanges.length) {
    changes.push(...removedChanges);

    changes.push(...addedChanges);

    changes.push(...noChanges);
  }

  return { changes };
};

export default iamPolicySummaryChanges;
