/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125GetUtilizationResponseValueFromJSON, HashicorpCloudVault20201125GetUtilizationResponseValueToJSON, } from './';
export function HashicorpCloudVault20201125GetUtilizationResponseFromJSON(json) {
    return HashicorpCloudVault20201125GetUtilizationResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125GetUtilizationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'clients': !exists(json, 'clients') ? undefined : HashicorpCloudVault20201125GetUtilizationResponseValueFromJSON(json['clients']),
        'nonEntityTokens': !exists(json, 'non_entity_tokens') ? undefined : HashicorpCloudVault20201125GetUtilizationResponseValueFromJSON(json['non_entity_tokens']),
        'distinctEntities': !exists(json, 'distinct_entities') ? undefined : HashicorpCloudVault20201125GetUtilizationResponseValueFromJSON(json['distinct_entities']),
        'diskUsageBytes': !exists(json, 'disk_usage_bytes') ? undefined : HashicorpCloudVault20201125GetUtilizationResponseValueFromJSON(json['disk_usage_bytes']),
    };
}
export function HashicorpCloudVault20201125GetUtilizationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'clients': HashicorpCloudVault20201125GetUtilizationResponseValueToJSON(value.clients),
        'non_entity_tokens': HashicorpCloudVault20201125GetUtilizationResponseValueToJSON(value.nonEntityTokens),
        'distinct_entities': HashicorpCloudVault20201125GetUtilizationResponseValueToJSON(value.distinctEntities),
        'disk_usage_bytes': HashicorpCloudVault20201125GetUtilizationResponseValueToJSON(value.diskUsageBytes),
    };
}
