/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Vault Radar
 * API for managing Vault Radar.
 *
 * The version of the OpenAPI document: 2023-05-01
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { InlineObject6ToJSON, InlineObject7ToJSON, VaultRadar20230501ListOrganizationTenantsResponseFromJSON, VaultRadar20230501TenantCreateResponseFromJSON, VaultRadar20230501TenantGetResponseFromJSON, } from '../models';
/**
 *
 */
export class TenantServiceApi extends runtime.BaseAPI {
    /**
     */
    async createTenantRaw(requestParameters) {
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createTenant.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createTenant.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2023-05-01/vault-radar/projects/{location.project_id}/tenants`.replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject6ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => VaultRadar20230501TenantCreateResponseFromJSON(jsonValue));
    }
    /**
     */
    async createTenant(locationProjectId, body) {
        const response = await this.createTenantRaw({ locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
    /**
     */
    async getTenantRaw(requestParameters) {
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getTenant.');
        }
        const queryParameters = {};
        if (requestParameters.locationOrganizationId !== undefined) {
            queryParameters['location.organization_id'] = requestParameters.locationOrganizationId;
        }
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2023-05-01/vault-radar/projects/{location.project_id}/tenants`.replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => VaultRadar20230501TenantGetResponseFromJSON(jsonValue));
    }
    /**
     */
    async getTenant(locationProjectId, locationOrganizationId, locationRegionProvider, locationRegionRegion) {
        const response = await this.getTenantRaw({ locationProjectId: locationProjectId, locationOrganizationId: locationOrganizationId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async listOrganizationTenantsRaw(requestParameters) {
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling listOrganizationTenants.');
        }
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling listOrganizationTenants.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2023-05-01/vault-radar/projects/{location.project_id}/tenants/organizations/{location.organization_id}`.replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => VaultRadar20230501ListOrganizationTenantsResponseFromJSON(jsonValue));
    }
    /**
     */
    async listOrganizationTenants(locationProjectId, locationOrganizationId, locationRegionProvider, locationRegionRegion) {
        const response = await this.listOrganizationTenantsRaw({ locationProjectId: locationProjectId, locationOrganizationId: locationOrganizationId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     */
    async provisionTenantRaw(requestParameters) {
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling provisionTenant.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling provisionTenant.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2023-05-01/vault-radar/projects/{location.project_id}/tenants/provision`.replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject7ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async provisionTenant(locationProjectId, body) {
        const response = await this.provisionTenantRaw({ locationProjectId: locationProjectId, body: body });
        return await response.value();
    }
}
