import templateOnlyComponent from '@ember/component/template-only';

interface ModalDialogBodySignature {
  Args: {
    padding?: unknown;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const ModalDialogBodyComponent =
  templateOnlyComponent<ModalDialogBodySignature>();

export default ModalDialogBodyComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ModalDialog::Body': typeof ModalDialogBodyComponent;
    'modal-dialog/body': typeof ModalDialogBodyComponent;
  }
}
