/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudGlobalNetworkManager20220215RaftInfoFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215RaftInfoFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215RaftInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isLeader': !exists(json, 'is_leader') ? undefined : json['is_leader'],
        'knownLeader': !exists(json, 'known_leader') ? undefined : json['known_leader'],
        'appliedIndex': !exists(json, 'applied_index') ? undefined : json['applied_index'],
        'timeSinceLastContact': !exists(json, 'time_since_last_contact') ? undefined : json['time_since_last_contact'],
    };
}
export function HashicorpCloudGlobalNetworkManager20220215RaftInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'is_leader': value.isLeader,
        'known_leader': value.knownLeader,
        'applied_index': value.appliedIndex,
        'time_since_last_contact': value.timeSinceLastContact,
    };
}
