/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125ClusterNotificationTopic;
(function (HashicorpCloudVault20201125ClusterNotificationTopic) {
    HashicorpCloudVault20201125ClusterNotificationTopic["CLUSTERNOTIFICATIONTOPICINVALID"] = "CLUSTER_NOTIFICATION_TOPIC_INVALID";
    HashicorpCloudVault20201125ClusterNotificationTopic["NEWMAJORVERSIONAVAILABLE"] = "NEW_MAJOR_VERSION_AVAILABLE";
    HashicorpCloudVault20201125ClusterNotificationTopic["MAJORVERSIONUPGRADEINPROGRESS"] = "MAJOR_VERSION_UPGRADE_IN_PROGRESS";
    HashicorpCloudVault20201125ClusterNotificationTopic["MAJORVERSIONUPGRADECOMPLETED"] = "MAJOR_VERSION_UPGRADE_COMPLETED";
    HashicorpCloudVault20201125ClusterNotificationTopic["MAJORVERSIONUPGRADEFAILED"] = "MAJOR_VERSION_UPGRADE_FAILED";
    HashicorpCloudVault20201125ClusterNotificationTopic["MAJORVERSIONUPGRADEMANUALREMINDER"] = "MAJOR_VERSION_UPGRADE_MANUAL_REMINDER";
})(HashicorpCloudVault20201125ClusterNotificationTopic || (HashicorpCloudVault20201125ClusterNotificationTopic = {}));
export function HashicorpCloudVault20201125ClusterNotificationTopicFromJSON(json) {
    return HashicorpCloudVault20201125ClusterNotificationTopicFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125ClusterNotificationTopicFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125ClusterNotificationTopicToJSON(value) {
    return value;
}
