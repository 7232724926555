import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { DateTime } from 'luxon';
import { resourceIsBusy } from '../helpers/resource-is-busy.js';

export default class FailureForResource extends Helper {
  @service operation;

  /**
   *
   * FailureForResource looks for failed operations
   * from the operations service using the passed resource. If the
   * resource's `state` is `FAILED` or busy, and a failed operation from the
   * last 24 hours with a Link to the resource is found, the operation's
   * error message will be returned.
   *
   * Example usage in a Handlebars template:
   * ```
   *  {{#let
        (failure-for-resource cluster (resource-type-for 'consul'))
        as |failedClusterError|
      }}
        <Hds::Alert @type='inline' @color='critical' data-test-operation-failure as |A|>
          <A.Description>
            {{failedOperationError.message}}
          </A.Description>
        </Hds::Alert>
      {{/let}}
   * ```
   *
   */

  /**
   * The `resource` is the object that will be used to find a related operation
   * failure. It needs to have `state` and `id` attributes.
   * @argument resource
   * @type {object}
   */

  /**
   * The resource type so we can check if the resource's state is
   * FAILED or busy.
   * @example 'hashicorp.network.hvn'
   * @argument resourceType
   * @type {string}
   */
  compute([resource, resourceType]) {
    if (!this.operation.operations) {
      return null;
    }

    let hasFailedOrBusyState =
      resource.state === 'FAILED' ||
      resourceIsBusy([resource], { type: resourceType });

    // do not show errors unless the resource is in a failed or busy state
    if (!hasFailedOrBusyState) {
      return null;
    }

    let relatedOperations = this.operation.operations.reduce(
      (related, op) => {
        // operations should always have an associated link
        // but in the extremely rare case when this is not true
        // this prevents the UI from experiencing page breakage
        if (!op.link || !op.createdAt || !op.updatedAt) {
          return related;
        }

        // it's not related if the id is not the same
        // (which can happen because ids are user-input)
        // it's not related if the operation is older than the resource
        if (op.link.id !== resource.id || op.updatedAt < resource.createdAt) {
          return related;
        }

        if (op.state === 'RUNNING') {
          related.running.push(op);
        }

        // only show errors from the last 24 hours
        let yesterday = DateTime.utc().minus({ hours: 24 });

        // if the operation has an error and we've not already marked a related
        // this makes it so we'll only show the first errored operation
        // we also do not want to show old errors
        if (op.error && !related.errored && op.updatedAt > yesterday) {
          related.errored = op;
        }
        return related;
      },
      {
        running: [],
        errored: null,
      }
    );

    // if there are running related operation, don't show any errors,
    // otherwise return the error from the errored operation
    return relatedOperations.running?.length
      ? null
      : relatedOperations.errored?.error;
  }
}
