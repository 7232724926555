/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Country is a two-letter country code as defined by the ISO 3166-1 standard.
 *
 * https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 *
 *  - AF: Afghanistan
 *  - AL: Albania
 *  - AQ: Antarctica
 *  - DZ: Algeria
 *  - AS: American Samoa
 *  - AD: Andorra
 *  - AO: Angola
 *  - AG: Antigua and Barbuda
 *  - AZ: Azerbaijan
 *  - AR: Argentina
 *  - AU: Australia
 *  - AT: Austria
 *  - BS: Bahamas (the)
 *  - BH: Bahrain
 *  - BD: Bangladesh
 *  - AM: Armenia
 *  - BB: Barbados
 *  - BE: Belgium
 *  - BM: Bermuda
 *  - BT: Bhutan
 *  - BO: Bolivia (Plurinational State of)
 *  - BA: Bosnia and Herzegovina
 *  - BW: Botswana
 *  - BV: Bouvet Island
 *  - BR: Brazil
 *  - BZ: Belize
 *  - IO: British Indian Ocean Territory (the)
 *  - SB: Solomon Islands
 *  - VG: Virgin Islands (British)
 *  - BN: Brunei Darussalam
 *  - BG: Bulgaria
 *  - MM: Myanmar
 *  - BI: Burundi
 *  - BY: Belarus
 *  - KH: Cambodia
 *  - CM: Cameroon
 *  - CA: Canada
 *  - CV: Cabo Verde
 *  - KY: Cayman Islands (the)
 *  - CF: Central African Republic (the)
 *  - LK: Sri Lanka
 *  - TD: Chad
 *  - CL: Chile
 *  - CN: China
 *  - TW: Taiwan (Province of China)
 *  - CX: Christmas Island
 *  - CC: Cocos (Keeling) Islands (the)
 *  - CO: Colombia
 *  - KM: Comoros (the)
 *  - YT: Mayotte
 *  - CG: Congo (the)
 *  - CD: Congo (the Democratic Republic of the)
 *  - CK: Cook Islands (the)
 *  - CR: Costa Rica
 *  - HR: Croatia
 *  - CU: Cuba
 *  - CY: Cyprus
 *  - CZ: Czech Republic (the)
 *  - BJ: Benin
 *  - DK: Denmark
 *  - DM: Dominica
 *  - DO: Dominican Republic (the)
 *  - EC: Ecuador
 *  - SV: El Salvador
 *  - GQ: Equatorial Guinea
 *  - ET: Ethiopia
 *  - ER: Eritrea
 *  - EE: Estonia
 *  - FO: Faroe Islands (the)
 *  - FK: Falkland Islands (the) [Malvinas]
 *  - GS: South Georgia and the South Sandwich Islands
 *  - FJ: Fiji
 *  - FI: Finland
 *  - AX: Åland Islands
 *  - FR: France
 *  - GF: French Guiana
 *  - PF: French Polynesia
 *  - TF: French Southern Territories (the)
 *  - DJ: Djibouti
 *  - GA: Gabon
 *  - GE: Georgia
 *  - GM: Gambia (the)
 *  - PS: Palestine, State of
 *  - DE: Germany
 *  - GH: Ghana
 *  - GI: Gibraltar
 *  - KI: Kiribati
 *  - GR: Greece
 *  - GL: Greenland
 *  - GD: Grenada
 *  - GP: Guadeloupe
 *  - GU: Guam
 *  - GT: Guatemala
 *  - GN: Guinea
 *  - GY: Guyana
 *  - HT: Haiti
 *  - HM: Heard Island and McDonald Islands
 *  - VA: Holy See (the)
 *  - HN: Honduras
 *  - HK: Hong Kong
 *  - HU: Hungary
 *  - IS: Iceland
 *  - IN: India
 *  - ID: Indonesia
 *  - IR: Iran (Islamic Republic of)
 *  - IQ: Iraq
 *  - IE: Ireland
 *  - IL: Israel
 *  - IT: Italy
 *  - CI: Côte d'Ivoire
 *  - JM: Jamaica
 *  - JP: Japan
 *  - KZ: Kazakhstan
 *  - JO: Jordan
 *  - KE: Kenya
 *  - KP: Korea (the Democratic People's Republic of)
 *  - KR: Korea (the Republic of)
 *  - KW: Kuwait
 *  - KG: Kyrgyzstan
 *  - LA: Lao People's Democratic Republic (the)
 *  - LB: Lebanon
 *  - LS: Lesotho
 *  - LV: Latvia
 *  - LR: Liberia
 *  - LY: Libya
 *  - LI: Liechtenstein
 *  - LT: Lithuania
 *  - LU: Luxembourg
 *  - MO: Macao
 *  - MG: Madagascar
 *  - MW: Malawi
 *  - MY: Malaysia
 *  - MV: Maldives
 *  - ML: Mali
 *  - MT: Malta
 *  - MQ: Martinique
 *  - MR: Mauritania
 *  - MU: Mauritius
 *  - MX: Mexico
 *  - MC: Monaco
 *  - MN: Mongolia
 *  - MD: Moldova (the Republic of)
 *  - ME: Montenegro
 *  - MS: Montserrat
 *  - MA: Morocco
 *  - MZ: Mozambique
 *  - OM: Oman
 *  - NA: Namibia
 *  - NR: Nauru
 *  - NP: Nepal
 *  - NL: Netherlands (the)
 *  - CW: Curaçao
 *  - AW: Aruba
 *  - SX: Sint Maarten (Dutch part)
 *  - BQ: Bonaire, Sint Eustatius and Saba
 *  - NC: New Caledonia
 *  - VU: Vanuatu
 *  - NZ: New Zealand
 *  - NI: Nicaragua
 *  - NE: Niger (the)
 *  - NG: Nigeria
 *  - NU: Niue
 *  - NF: Norfolk Island
 *  - NO: Norway
 *  - MP: Northern Mariana Islands (the)
 *  - UM: United States Minor Outlying Islands (the)
 *  - FM: Micronesia (Federated States of)
 *  - MH: Marshall Islands (the)
 *  - PW: Palau
 *  - PK: Pakistan
 *  - PA: Panama
 *  - PG: Papua New Guinea
 *  - PY: Paraguay
 *  - PE: Peru
 *  - PH: Philippines (the)
 *  - PN: Pitcairn
 *  - PL: Poland
 *  - PT: Portugal
 *  - GW: Guinea-Bissau
 *  - TL: Timor-Leste
 *  - PR: Puerto Rico
 *  - QA: Qatar
 *  - RE: Réunion
 *  - RO: Romania
 *  - RU: Russian Federation (the)
 *  - RW: Rwanda
 *  - BL: Saint Barthélemy
 *  - SH: Saint Helena, Ascension and Tristan da Cunha
 *  - KN: Saint Kitts and Nevis
 *  - AI: Anguilla
 *  - LC: Saint Lucia
 *  - MF: Saint Martin (French part)
 *  - PM: Saint Pierre and Miquelon
 *  - VC: Saint Vincent and the Grenadines
 *  - SM: San Marino
 *  - ST: Sao Tome and Principe
 *  - SA: Saudi Arabia
 *  - SN: Senegal
 *  - RS: Serbia
 *  - SC: Seychelles
 *  - SL: Sierra Leone
 *  - SG: Singapore
 *  - SK: Slovakia
 *  - VN: Viet Nam
 *  - SI: Slovenia
 *  - SO: Somalia
 *  - ZA: South Africa
 *  - ZW: Zimbabwe
 *  - ES: Spain
 *  - SS: South Sudan
 *  - SD: Sudan (the)
 *  - EH: Western Sahara*
 *  - SR: Suriname
 *  - SJ: Svalbard and Jan Mayen
 *  - SZ: Swaziland
 *  - SE: Sweden
 *  - CH: Switzerland
 *  - SY: Syrian Arab Republic
 *  - TJ: Tajikistan
 *  - TH: Thailand
 *  - TG: Togo
 *  - TK: Tokelau
 *  - TO: Tonga
 *  - TT: Trinidad and Tobago
 *  - AE: United Arab Emirates (the)
 *  - TN: Tunisia
 *  - TR: Turkey
 *  - TM: Turkmenistan
 *  - TC: Turks and Caicos Islands (the)
 *  - TV: Tuvalu
 *  - UG: Uganda
 *  - UA: Ukraine
 *  - MK: Macedonia (the former Yugoslav Republic of)
 *  - EG: Egypt
 *  - GB: United Kingdom of Great Britain and Northern Ireland (the)
 *  - GG: Guernsey
 *  - JE: Jersey
 *  - IM: Isle of Man
 *  - TZ: Tanzania, United Republic of
 *  - US: United States of America (the)
 *  - VI: Virgin Islands (U.S.)
 *  - BF: Burkina Faso
 *  - UY: Uruguay
 *  - UZ: Uzbekistan
 *  - VE: Venezuela (Bolivarian Republic of)
 *  - WF: Wallis and Futuna
 *  - WS: Samoa
 *  - YE: Yemen
 *  - ZM: Zambia
 * @export
 * @enum {string}
 */
export var Billing20201105Country;
(function (Billing20201105Country) {
    Billing20201105Country["UNSET"] = "UNSET";
    Billing20201105Country["AF"] = "AF";
    Billing20201105Country["AL"] = "AL";
    Billing20201105Country["AQ"] = "AQ";
    Billing20201105Country["DZ"] = "DZ";
    Billing20201105Country["AS"] = "AS";
    Billing20201105Country["AD"] = "AD";
    Billing20201105Country["AO"] = "AO";
    Billing20201105Country["AG"] = "AG";
    Billing20201105Country["AZ"] = "AZ";
    Billing20201105Country["AR"] = "AR";
    Billing20201105Country["AU"] = "AU";
    Billing20201105Country["AT"] = "AT";
    Billing20201105Country["BS"] = "BS";
    Billing20201105Country["BH"] = "BH";
    Billing20201105Country["BD"] = "BD";
    Billing20201105Country["AM"] = "AM";
    Billing20201105Country["BB"] = "BB";
    Billing20201105Country["BE"] = "BE";
    Billing20201105Country["BM"] = "BM";
    Billing20201105Country["BT"] = "BT";
    Billing20201105Country["BO"] = "BO";
    Billing20201105Country["BA"] = "BA";
    Billing20201105Country["BW"] = "BW";
    Billing20201105Country["BV"] = "BV";
    Billing20201105Country["BR"] = "BR";
    Billing20201105Country["BZ"] = "BZ";
    Billing20201105Country["IO"] = "IO";
    Billing20201105Country["SB"] = "SB";
    Billing20201105Country["VG"] = "VG";
    Billing20201105Country["BN"] = "BN";
    Billing20201105Country["BG"] = "BG";
    Billing20201105Country["MM"] = "MM";
    Billing20201105Country["BI"] = "BI";
    Billing20201105Country["BY"] = "BY";
    Billing20201105Country["KH"] = "KH";
    Billing20201105Country["CM"] = "CM";
    Billing20201105Country["CA"] = "CA";
    Billing20201105Country["CV"] = "CV";
    Billing20201105Country["KY"] = "KY";
    Billing20201105Country["CF"] = "CF";
    Billing20201105Country["LK"] = "LK";
    Billing20201105Country["TD"] = "TD";
    Billing20201105Country["CL"] = "CL";
    Billing20201105Country["CN"] = "CN";
    Billing20201105Country["TW"] = "TW";
    Billing20201105Country["CX"] = "CX";
    Billing20201105Country["CC"] = "CC";
    Billing20201105Country["CO"] = "CO";
    Billing20201105Country["KM"] = "KM";
    Billing20201105Country["YT"] = "YT";
    Billing20201105Country["CG"] = "CG";
    Billing20201105Country["CD"] = "CD";
    Billing20201105Country["CK"] = "CK";
    Billing20201105Country["CR"] = "CR";
    Billing20201105Country["HR"] = "HR";
    Billing20201105Country["CU"] = "CU";
    Billing20201105Country["CY"] = "CY";
    Billing20201105Country["CZ"] = "CZ";
    Billing20201105Country["BJ"] = "BJ";
    Billing20201105Country["DK"] = "DK";
    Billing20201105Country["DM"] = "DM";
    Billing20201105Country["DO"] = "DO";
    Billing20201105Country["EC"] = "EC";
    Billing20201105Country["SV"] = "SV";
    Billing20201105Country["GQ"] = "GQ";
    Billing20201105Country["ET"] = "ET";
    Billing20201105Country["ER"] = "ER";
    Billing20201105Country["EE"] = "EE";
    Billing20201105Country["FO"] = "FO";
    Billing20201105Country["FK"] = "FK";
    Billing20201105Country["GS"] = "GS";
    Billing20201105Country["FJ"] = "FJ";
    Billing20201105Country["FI"] = "FI";
    Billing20201105Country["AX"] = "AX";
    Billing20201105Country["FR"] = "FR";
    Billing20201105Country["GF"] = "GF";
    Billing20201105Country["PF"] = "PF";
    Billing20201105Country["TF"] = "TF";
    Billing20201105Country["DJ"] = "DJ";
    Billing20201105Country["GA"] = "GA";
    Billing20201105Country["GE"] = "GE";
    Billing20201105Country["GM"] = "GM";
    Billing20201105Country["PS"] = "PS";
    Billing20201105Country["DE"] = "DE";
    Billing20201105Country["GH"] = "GH";
    Billing20201105Country["GI"] = "GI";
    Billing20201105Country["KI"] = "KI";
    Billing20201105Country["GR"] = "GR";
    Billing20201105Country["GL"] = "GL";
    Billing20201105Country["GD"] = "GD";
    Billing20201105Country["GP"] = "GP";
    Billing20201105Country["GU"] = "GU";
    Billing20201105Country["GT"] = "GT";
    Billing20201105Country["GN"] = "GN";
    Billing20201105Country["GY"] = "GY";
    Billing20201105Country["HT"] = "HT";
    Billing20201105Country["HM"] = "HM";
    Billing20201105Country["VA"] = "VA";
    Billing20201105Country["HN"] = "HN";
    Billing20201105Country["HK"] = "HK";
    Billing20201105Country["HU"] = "HU";
    Billing20201105Country["IS"] = "IS";
    Billing20201105Country["IN"] = "IN";
    Billing20201105Country["ID"] = "ID";
    Billing20201105Country["IR"] = "IR";
    Billing20201105Country["IQ"] = "IQ";
    Billing20201105Country["IE"] = "IE";
    Billing20201105Country["IL"] = "IL";
    Billing20201105Country["IT"] = "IT";
    Billing20201105Country["CI"] = "CI";
    Billing20201105Country["JM"] = "JM";
    Billing20201105Country["JP"] = "JP";
    Billing20201105Country["KZ"] = "KZ";
    Billing20201105Country["JO"] = "JO";
    Billing20201105Country["KE"] = "KE";
    Billing20201105Country["KP"] = "KP";
    Billing20201105Country["KR"] = "KR";
    Billing20201105Country["KW"] = "KW";
    Billing20201105Country["KG"] = "KG";
    Billing20201105Country["LA"] = "LA";
    Billing20201105Country["LB"] = "LB";
    Billing20201105Country["LS"] = "LS";
    Billing20201105Country["LV"] = "LV";
    Billing20201105Country["LR"] = "LR";
    Billing20201105Country["LY"] = "LY";
    Billing20201105Country["LI"] = "LI";
    Billing20201105Country["LT"] = "LT";
    Billing20201105Country["LU"] = "LU";
    Billing20201105Country["MO"] = "MO";
    Billing20201105Country["MG"] = "MG";
    Billing20201105Country["MW"] = "MW";
    Billing20201105Country["MY"] = "MY";
    Billing20201105Country["MV"] = "MV";
    Billing20201105Country["ML"] = "ML";
    Billing20201105Country["MT"] = "MT";
    Billing20201105Country["MQ"] = "MQ";
    Billing20201105Country["MR"] = "MR";
    Billing20201105Country["MU"] = "MU";
    Billing20201105Country["MX"] = "MX";
    Billing20201105Country["MC"] = "MC";
    Billing20201105Country["MN"] = "MN";
    Billing20201105Country["MD"] = "MD";
    Billing20201105Country["ME"] = "ME";
    Billing20201105Country["MS"] = "MS";
    Billing20201105Country["MA"] = "MA";
    Billing20201105Country["MZ"] = "MZ";
    Billing20201105Country["OM"] = "OM";
    Billing20201105Country["NA"] = "NA";
    Billing20201105Country["NR"] = "NR";
    Billing20201105Country["NP"] = "NP";
    Billing20201105Country["NL"] = "NL";
    Billing20201105Country["CW"] = "CW";
    Billing20201105Country["AW"] = "AW";
    Billing20201105Country["SX"] = "SX";
    Billing20201105Country["BQ"] = "BQ";
    Billing20201105Country["NC"] = "NC";
    Billing20201105Country["VU"] = "VU";
    Billing20201105Country["NZ"] = "NZ";
    Billing20201105Country["NI"] = "NI";
    Billing20201105Country["NE"] = "NE";
    Billing20201105Country["NG"] = "NG";
    Billing20201105Country["NU"] = "NU";
    Billing20201105Country["NF"] = "NF";
    Billing20201105Country["NO"] = "NO";
    Billing20201105Country["MP"] = "MP";
    Billing20201105Country["UM"] = "UM";
    Billing20201105Country["FM"] = "FM";
    Billing20201105Country["MH"] = "MH";
    Billing20201105Country["PW"] = "PW";
    Billing20201105Country["PK"] = "PK";
    Billing20201105Country["PA"] = "PA";
    Billing20201105Country["PG"] = "PG";
    Billing20201105Country["PY"] = "PY";
    Billing20201105Country["PE"] = "PE";
    Billing20201105Country["PH"] = "PH";
    Billing20201105Country["PN"] = "PN";
    Billing20201105Country["PL"] = "PL";
    Billing20201105Country["PT"] = "PT";
    Billing20201105Country["GW"] = "GW";
    Billing20201105Country["TL"] = "TL";
    Billing20201105Country["PR"] = "PR";
    Billing20201105Country["QA"] = "QA";
    Billing20201105Country["RE"] = "RE";
    Billing20201105Country["RO"] = "RO";
    Billing20201105Country["RU"] = "RU";
    Billing20201105Country["RW"] = "RW";
    Billing20201105Country["BL"] = "BL";
    Billing20201105Country["SH"] = "SH";
    Billing20201105Country["KN"] = "KN";
    Billing20201105Country["AI"] = "AI";
    Billing20201105Country["LC"] = "LC";
    Billing20201105Country["MF"] = "MF";
    Billing20201105Country["PM"] = "PM";
    Billing20201105Country["VC"] = "VC";
    Billing20201105Country["SM"] = "SM";
    Billing20201105Country["ST"] = "ST";
    Billing20201105Country["SA"] = "SA";
    Billing20201105Country["SN"] = "SN";
    Billing20201105Country["RS"] = "RS";
    Billing20201105Country["SC"] = "SC";
    Billing20201105Country["SL"] = "SL";
    Billing20201105Country["SG"] = "SG";
    Billing20201105Country["SK"] = "SK";
    Billing20201105Country["VN"] = "VN";
    Billing20201105Country["SI"] = "SI";
    Billing20201105Country["SO"] = "SO";
    Billing20201105Country["ZA"] = "ZA";
    Billing20201105Country["ZW"] = "ZW";
    Billing20201105Country["ES"] = "ES";
    Billing20201105Country["SS"] = "SS";
    Billing20201105Country["SD"] = "SD";
    Billing20201105Country["EH"] = "EH";
    Billing20201105Country["SR"] = "SR";
    Billing20201105Country["SJ"] = "SJ";
    Billing20201105Country["SZ"] = "SZ";
    Billing20201105Country["SE"] = "SE";
    Billing20201105Country["CH"] = "CH";
    Billing20201105Country["SY"] = "SY";
    Billing20201105Country["TJ"] = "TJ";
    Billing20201105Country["TH"] = "TH";
    Billing20201105Country["TG"] = "TG";
    Billing20201105Country["TK"] = "TK";
    Billing20201105Country["TO"] = "TO";
    Billing20201105Country["TT"] = "TT";
    Billing20201105Country["AE"] = "AE";
    Billing20201105Country["TN"] = "TN";
    Billing20201105Country["TR"] = "TR";
    Billing20201105Country["TM"] = "TM";
    Billing20201105Country["TC"] = "TC";
    Billing20201105Country["TV"] = "TV";
    Billing20201105Country["UG"] = "UG";
    Billing20201105Country["UA"] = "UA";
    Billing20201105Country["MK"] = "MK";
    Billing20201105Country["EG"] = "EG";
    Billing20201105Country["GB"] = "GB";
    Billing20201105Country["GG"] = "GG";
    Billing20201105Country["JE"] = "JE";
    Billing20201105Country["IM"] = "IM";
    Billing20201105Country["TZ"] = "TZ";
    Billing20201105Country["US"] = "US";
    Billing20201105Country["VI"] = "VI";
    Billing20201105Country["BF"] = "BF";
    Billing20201105Country["UY"] = "UY";
    Billing20201105Country["UZ"] = "UZ";
    Billing20201105Country["VE"] = "VE";
    Billing20201105Country["WF"] = "WF";
    Billing20201105Country["WS"] = "WS";
    Billing20201105Country["YE"] = "YE";
    Billing20201105Country["ZM"] = "ZM";
})(Billing20201105Country || (Billing20201105Country = {}));
export function Billing20201105CountryFromJSON(json) {
    return Billing20201105CountryFromJSONTyped(json, false);
}
export function Billing20201105CountryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Billing20201105CountryToJSON(value) {
    return value;
}
