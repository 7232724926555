import Component from '@glimmer/component';

interface CoreDropdownGoToTerraformSignature {
  // Args: {};
  Element: HTMLElement;
}

export default class CoreDropdownGoToTerraformComponent extends Component<CoreDropdownGoToTerraformSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::Dropdown::GoToTerraform': typeof CoreDropdownGoToTerraformComponent;
    'core/dropdown/go-to-terraform': typeof CoreDropdownGoToTerraformComponent;
  }
}
