/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var Billing20201105ConsumptionPoolUpdateInfoUpdateCause;
(function (Billing20201105ConsumptionPoolUpdateInfoUpdateCause) {
    Billing20201105ConsumptionPoolUpdateInfoUpdateCause["ADDON"] = "ADDON";
    Billing20201105ConsumptionPoolUpdateInfoUpdateCause["EARLYRENEWAL"] = "EARLY_RENEWAL";
})(Billing20201105ConsumptionPoolUpdateInfoUpdateCause || (Billing20201105ConsumptionPoolUpdateInfoUpdateCause = {}));
export function Billing20201105ConsumptionPoolUpdateInfoUpdateCauseFromJSON(json) {
    return Billing20201105ConsumptionPoolUpdateInfoUpdateCauseFromJSONTyped(json, false);
}
export function Billing20201105ConsumptionPoolUpdateInfoUpdateCauseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Billing20201105ConsumptionPoolUpdateInfoUpdateCauseToJSON(value) {
    return value;
}
