/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128RotatingSecretStatusFromJSON, Secrets20231128RotatingSecretStatusToJSON, } from './';
export function Secrets20231128RotatingSecretStateFromJSON(json) {
    return Secrets20231128RotatingSecretStateFromJSONTyped(json, false);
}
export function Secrets20231128RotatingSecretStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': !exists(json, 'status') ? undefined : Secrets20231128RotatingSecretStatusFromJSON(json['status']),
        'statusPrevious': !exists(json, 'status_previous') ? undefined : Secrets20231128RotatingSecretStatusFromJSON(json['status_previous']),
        'latestVersion': !exists(json, 'latest_version') ? undefined : json['latest_version'],
        'rotationTimePrevious': !exists(json, 'rotation_time_previous') ? undefined : (new Date(json['rotation_time_previous'])),
        'rotationTimeNext': !exists(json, 'rotation_time_next') ? undefined : (new Date(json['rotation_time_next'])),
        'errorMessage': !exists(json, 'error_message') ? undefined : json['error_message'],
    };
}
export function Secrets20231128RotatingSecretStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': Secrets20231128RotatingSecretStatusToJSON(value.status),
        'status_previous': Secrets20231128RotatingSecretStatusToJSON(value.statusPrevious),
        'latest_version': value.latestVersion,
        'rotation_time_previous': value.rotationTimePrevious === undefined ? undefined : (value.rotationTimePrevious.toISOString()),
        'rotation_time_next': value.rotationTimeNext === undefined ? undefined : (value.rotationTimeNext.toISOString()),
        'error_message': value.errorMessage,
    };
}
