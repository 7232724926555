export enum AlignItem {
  Center = 'center',
  Start = 'start',
  End = 'end',
}

export enum Display {
  Inline = 'inline',
}

export enum JustifyContent {
  Center = 'center',
  Start = 'start',
  End = 'end',
  SpaceBetween = 'space-between',
  SpaceAround = 'space-around',
  Stretch = 'stretch',
}

export enum FlexBasis {
  Content = 'content',
  FitContent = 'fit-content',
  Inherit = 'inherit',
  Initial = 'initial',
  MaxContent = 'max-content',
  MinContent = 'min-content',
  Revert = 'revert',
  RevertLayer = 'revert-layer',
  Unset = 'unset',
  ZeroPercent = 'zero-percent',
}

export enum FlexDirection {
  Column = 'column',
  Row = 'row',
}

export enum FlexGap {
  Xxs = '2xs',
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Llg = '2lg',
  Xl = 'xl',
  Xxl = '2xl',
}

export enum FlexGrow {
  Zero = 0,
  One = 1,
  Two = 2,
}

export enum FlexShrink {
  Zero = 0,
  One = 1,
  Two = 2,
}
