/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * BoxState is an enumeration of possible Box states.
 * @export
 * @enum {string}
 */
export var HashicorpCloudVagrant20220930BoxState;
(function (HashicorpCloudVagrant20220930BoxState) {
    HashicorpCloudVagrant20220930BoxState["INACTIVEORUNSPECIFIED"] = "INACTIVE_OR_UNSPECIFIED";
    HashicorpCloudVagrant20220930BoxState["NOVERSIONS"] = "NO_VERSIONS";
    HashicorpCloudVagrant20220930BoxState["NORELEASEDVERSIONS"] = "NO_RELEASED_VERSIONS";
    HashicorpCloudVagrant20220930BoxState["NOPROVIDERS"] = "NO_PROVIDERS";
    HashicorpCloudVagrant20220930BoxState["AVAILABLE"] = "AVAILABLE";
})(HashicorpCloudVagrant20220930BoxState || (HashicorpCloudVagrant20220930BoxState = {}));
export function HashicorpCloudVagrant20220930BoxStateFromJSON(json) {
    return HashicorpCloudVagrant20220930BoxStateFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930BoxStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVagrant20220930BoxStateToJSON(value) {
    return value;
}
