import iamFindRoleLocationsFromPolicyManagerLocations from './iam-find-role-locations-from-policy-manager-locations.ts';

import type { HashicorpCloudIamPrincipal } from '@clients/cloud-iam';
import type { HashicorpCloudResourcemanagerPolicyBindingMember } from '@clients/cloud-resource-manager';
import type { PolicyManagerLocation } from '../types/policy-manager-location.ts';
import type { IamRoleLocation } from '../types/iam-role-location.ts';
import type { ScopeLocationLink } from '../types/scope-location-link.ts';

export default function iamFindAllRelatedRoleLocations(
  currentScopeLink: ScopeLocationLink,
  groupIds: HashicorpCloudIamPrincipal['groupIds'],
  memberIds: Array<
    HashicorpCloudResourcemanagerPolicyBindingMember['memberId']
  >,
  policyManagerLocations: Array<PolicyManagerLocation> | undefined,
): {
  selfRoleLocations: Array<IamRoleLocation>;
  inheritedRoleLocations: Array<IamRoleLocation>;
  groupRoleLocations: Array<IamRoleLocation>;
} {
  const selfPolicyManagerLocationIndex = (
    policyManagerLocations ?? []
  ).findIndex((location) => location.link.type === currentScopeLink?.type);

  const selfPolicyManagerLocation = policyManagerLocations
    ? policyManagerLocations[selfPolicyManagerLocationIndex]
    : undefined;

  const inheritedPolicyManagerLocations = (policyManagerLocations ?? []).slice(
    0,
    selfPolicyManagerLocationIndex,
  );

  const selfRoleLocations = selfPolicyManagerLocation
    ? iamFindRoleLocationsFromPolicyManagerLocations(
        [selfPolicyManagerLocation],
        { memberIds },
      )
    : [];

  const inheritedRoleLocations = iamFindRoleLocationsFromPolicyManagerLocations(
    inheritedPolicyManagerLocations,
    { memberIds },
  );

  const groupRoleLocations = iamFindRoleLocationsFromPolicyManagerLocations(
    policyManagerLocations,
    { memberIds: groupIds ?? [] },
  );

  return {
    selfRoleLocations,
    inheritedRoleLocations,
    groupRoleLocations,
  };
}
