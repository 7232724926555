const crudActions = ['create', 'read', 'update', 'delete'];
const crudlActions = [...crudActions, 'list'];
const rlActions = ['read', 'list'];

type AuthzF<T extends string> = {
  [key in T]: boolean;
};

/**
 * An object of objects of booleans. All fields on an Authz represent types/services.
 * All fields on an Authz[key] represent actions for the type/service.
 * e.g., myAuthz.feature.create will be true or false based on the application of an
 * AuthzBuilder.
 */
export type Authz<T extends string> = {
  [key in T]: AuthzF<string>;
};

/**
 * A mostly internal class for represnting all actions for a type/feature of a service.
 * It is used to generate and iterate fully qualified permission strings.
 */
export class AuthzType {
  service;
  name;
  actions: Set<string>;

  /**
   * @param service - The first part of the permission string {service}.{name}.{action}
   * @param name - The second part of the permission string {service}.{name}.{action}
   * @param actions - All actions for this AuthzType (only the action name itself).
   */
  constructor(service: string, name: string, actions: string[]) {
    this.service = service;
    this.name = name;
    this.actions = new Set(actions);
  }

  /**
   * @returns All actions for this AuthzType, fully qualified.
   */
  list(): string[] {
    return Array.from(this.actions).map(
      (a) => `${this.service}.${this.name}.${a}`,
    );
  }

  /**
   * @param action - The name of the action to expand (e.g., "create").
   * @returns The fully qualified permission name (e.g., "my-service.feature.create").
   */
  get(action: string): string {
    if (!this.actions.has(action))
      throw new Error(
        `Action "${action}" does not exist on type "${this.service}.${this.name}"`,
      );

    return `${this.service}.${this.name}.${action}`;
  }

  /**
   * @param actions - A list of actions that will be true
   *   for the returned AuthzF (e.g., ['create', 'list']).
   * @returns An object of boolean values representing which
   *   permissions are true or false given the provided actions.
   */
  apply(actions: string[]): AuthzF<string> {
    return Object.freeze(
      Array.from(this.actions).reduce(
        (hash: AuthzF<string>, action: string) => {
          hash[action] = actions.includes(this.get(action));
          return hash;
        },
        {} as AuthzF<string>,
      ),
    );
  }
}

export default class AuthzBuilder<T extends string> {
  service;
  #types: { [key in T]: AuthzType };

  /**
   * @returns a typed map of Authz for all features/types in the constructed in enum.
   */
  get types() {
    return this.#types;
  }

  /**
   * @param service - The first part of the permission string {service}.{name}.{action}
   * @param typesEnum - An Enum of all features/types for the service.
   */
  constructor(service: string, typesEnum: { [key: string]: T }) {
    this.service = service;
    this.#types = Object.values(typesEnum).reduce(
      (hash: { [key in T]: AuthzType }, t) => {
        const key = t as T;
        hash[key] = new AuthzType(this.service, key, []);
        return hash;
      },
      {} as { [key in T]: AuthzType },
    );
  }

  /**
   * @param name - The feature/type to add actions to.
   * @param actions - A list of actions to add in addition to create, read, update, delete.
   */
  addCRUDActions(name: T, actions: string[] = []): void {
    this.addActions(name, [...crudActions, ...actions]);
  }

  /**
   * @param name - The feature/type to add actions to.
   * @param actions - A list of actions to add in addition to create, read, update, delete, list.
   */
  addCRUDLActions(name: T, actions: string[] = []): void {
    this.addActions(name, [...crudlActions, ...actions]);
  }

  /**
   * @param name - The feature/type to add actions to.
   * @param actions - A list of actions to add in addition to create, read, list.
   */
  addRLActions(name: T, actions: string[] = []): void {
    this.addActions(name, [...rlActions, ...actions]);
  }

  /**
   * @param name - The feature/type to add actions to.
   * @param actions - A list of actions to add. This recreates the AuthzType and is called
   *   by all add action methods.
   */
  addActions(name: T, actions: string[]): void {
    this.#types[name] = new AuthzType(this.service, name, actions);
  }

  /**
   * @param name - The feature/type to add actions to.
   * @param action - The action to qualify
   * @returns A fully qualified action name (e.g., "create" becomes "my-service.feature.create")
   */
  getAction(name: T, action: string): string {
    return this.#types[name].get(action);
  }

  /**
   * @alias getAction
   */
  get(name: T, action: string): string {
    return this.getAction(name, action);
  }

  /**
   * @returns All actions in this builder, fully qualified.
   */
  list(): string[] {
    return Object.values<AuthzType>(this.#types)
      .map((a) => a.list())
      .flat();
  }

  /**
   * @param allPerms - A set of permissions that should true in the returned Authz.
   * @returns An Authz that represents all actions in this AuthzBuilder with true values
   *   for all actions provided in allPerms.
   */
  apply(allPerms: string[]): Authz<T> {
    const ourPerms = allPerms.filter((s) => s.startsWith(this.service));
    const entries = Object.entries(this.#types) as [T, AuthzType][];
    return entries.reduce((hash: Authz<T>, [t, v]: [T, AuthzType]) => {
      hash[t] = v.apply(
        ourPerms.filter((s) => s.startsWith(`${v.service}.${v.name}`)),
      );
      return hash;
    }, {} as Authz<T>);
  }
}

interface Line {
  type: string;
  action: string;
  value: boolean;
}

/**
 * A helper function meant to be used with console.table for rich debugging.
 *
 * console.table(print(myAuthz))
 *
 * @param authz - The Authz to print
 * @returns A list of objects containing tye type, action, and value of all actions
 *   contained in the Authz.
 */
export function print(authz: Authz<string>): Line[] {
  const lines: Line[] = [];

  const entries = Object.entries(authz) as [string, AuthzF<string>][];
  entries.forEach(([t, actions]: [string, AuthzF<string>]) => {
    const actionsEntries = Object.entries(actions) as [string, boolean][];
    actionsEntries.forEach(([action, v]: [string, boolean]) => {
      lines.push({
        type: t,
        action,
        value: v,
      });
    });
  });

  return lines;
}

/**
 * A helper function meant to be used to modify existing permissions when mocking API responses.
 *
 * const currentPerms = new Set(list('my-service'), authz);
 * currentPerms.add('my-service.feature.activate');
 * authz = authzBuilder.apply(Array.from(currentPerms));
 *
 * @param service - The first part of the permission string {service}.{name}.{action}
 * @param authz - The Authz object that represents allowed permissions to list.
 * @returns The set of all fully qualified permissions allowed by authz.
 */
export function list(service: string, authz: Authz<string>): string[] {
  const lines: string[] = [];

  const entries = Object.entries(authz) as [string, AuthzF<string>][];
  entries.forEach(([t, actions]: [string, AuthzF<string>]) => {
    const actionsEntries = Object.entries(actions) as [string, boolean][];
    actionsEntries.forEach(([action, v]: [string, boolean]) => {
      if (v) lines.push(`${service}.${t}.${action}`);
    });
  });

  return lines;
}
