import BaseAbility from './base-ability';
import {
  PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS,
  ACTION_CREATE,
  ACTION_UPDATE,
  ACTION_DELETE,
  ACTION_GET,
  ACTION_LIST,
} from '../utils/permission-types/index';

/**
 * #### Workload Identity Providers Permissions
 * These permissions can be found here:
 * https://github.com/hashicorp/cloud-iam/blob/main/permissions/permissions.go#L159-L193
 */
export default class extends BaseAbility {
  prefix = PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS;

  get canCreate() {
    return this.projectAllowedPermissions.filter((permission) => {
      permission ===
        `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_CREATE}`;
    });
  }

  get canUpdate() {
    return this.projectAllowedPermissions.filter((permission) => {
      permission ===
        `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_UPDATE}`;
    });
  }

  get canDelete() {
    return this.projectAllowedPermissions.filter((permission) => {
      permission ===
        `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_DELETE}`;
    });
  }

  get canGet() {
    return this.projectAllowedPermissions.filter((permission) => {
      permission === `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_GET}`;
    });
  }

  get canList() {
    return this.projectAllowedPermissions.filter((permission) => {
      permission === `${PREFIX_IAM_WORKLOAD_IDENTITY_PROVIDERS}.${ACTION_LIST}`;
    });
  }
}
