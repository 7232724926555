/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKindFromJSON, HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKindToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ServiceSummaryEntryFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ServiceSummaryEntryFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ServiceSummaryEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cluster': !exists(json, 'cluster') ? undefined : json['cluster'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'partition': !exists(json, 'partition') ? undefined : json['partition'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'passing': !exists(json, 'passing') ? undefined : json['passing'],
        'warning': !exists(json, 'warning') ? undefined : json['warning'],
        'critical': !exists(json, 'critical') ? undefined : json['critical'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'kind': !exists(json, 'kind') ? undefined : HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKindFromJSON(json['kind']),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ServiceSummaryEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cluster': value.cluster,
        'namespace': value.namespace,
        'partition': value.partition,
        'total': value.total,
        'passing': value.passing,
        'warning': value.warning,
        'critical': value.critical,
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'kind': HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKindToJSON(value.kind),
    };
}
