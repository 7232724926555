/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105ContractUpdateUpdateCauseFromJSON, Billing20201105ContractUpdateUpdateCauseToJSON, } from './';
export function Billing20201105ContractUpdateFromJSON(json) {
    return Billing20201105ContractUpdateFromJSONTyped(json, false);
}
export function Billing20201105ContractUpdateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'updateDate': !exists(json, 'update_date') ? undefined : (new Date(json['update_date'])),
        'updatedField': !exists(json, 'updated_field') ? undefined : json['updated_field'],
        'updatedProduct': !exists(json, 'updated_product') ? undefined : json['updated_product'],
        'prevState': !exists(json, 'prev_state') ? undefined : json['prev_state'],
        'newState': !exists(json, 'new_state') ? undefined : json['new_state'],
        'updateCause': !exists(json, 'update_cause') ? undefined : Billing20201105ContractUpdateUpdateCauseFromJSON(json['update_cause']),
        'updateEffectiveAt': !exists(json, 'update_effective_at') ? undefined : (new Date(json['update_effective_at'])),
    };
}
export function Billing20201105ContractUpdateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'update_date': value.updateDate === undefined ? undefined : (value.updateDate.toISOString()),
        'updated_field': value.updatedField,
        'updated_product': value.updatedProduct,
        'prev_state': value.prevState,
        'new_state': value.newState,
        'update_cause': Billing20201105ContractUpdateUpdateCauseToJSON(value.updateCause),
        'update_effective_at': value.updateEffectiveAt === undefined ? undefined : (value.updateEffectiveAt.toISOString()),
    };
}
