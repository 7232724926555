import type { BillingAvailablePackage } from '../types/api.ts';

export default function packagesDataVaultClients(
  tier: BillingAvailablePackage | null,
) {
  if (!tier) return [];

  const { items = [] } = tier;
  const [numClients = {}] = items.filter(
    (i) => i.key === 'hashicorp.vault.dimension.num_clients',
  );
  const { options = [] } = numClients;

  return options;
}
