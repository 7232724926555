export const RESOURCE_TIER_I18N_PREFIX = 'helpers.option-for-resource-tier';
export const RESOURCE_TIER_SIZE_I18N_PREFIX =
  'helpers.option-for-resource-tier-size';

// Resource tier sizes
export const RESOURCE_TIER_SIZE_XS = `extra-small`;
export const RESOURCE_TIER_SIZE_SM = `small`;
export const RESOURCE_TIER_SIZE_MD = `medium`;
export const RESOURCE_TIER_SIZE_LG = `large`;

export const SIZE_MAPPINGS: Record<string, string> = {
  undefined: RESOURCE_TIER_SIZE_XS,
  EXTRA_SMALL: RESOURCE_TIER_SIZE_XS,
  SMALL: RESOURCE_TIER_SIZE_SM,
  MEDIUM: RESOURCE_TIER_SIZE_MD,
  LARGE: RESOURCE_TIER_SIZE_LG,
};
