/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointRunActionRequestVariableOverrideFromJSON(json) {
    return HashicorpCloudWaypointRunActionRequestVariableOverrideFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointRunActionRequestVariableOverrideFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'sensitive': !exists(json, 'sensitive') ? undefined : json['sensitive'],
        'save': !exists(json, 'save') ? undefined : json['save'],
    };
}
export function HashicorpCloudWaypointRunActionRequestVariableOverrideToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
        'value': value.value,
        'sensitive': value.sensitive,
        'save': value.save,
    };
}
