/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var LogService20210330Status;
(function (LogService20210330Status) {
    LogService20210330Status["UNSET"] = "STATUS_UNSET";
    LogService20210330Status["PROCESSING"] = "STATUS_PROCESSING";
    LogService20210330Status["COMPLETED"] = "STATUS_COMPLETED";
    LogService20210330Status["FAILED"] = "STATUS_FAILED";
})(LogService20210330Status || (LogService20210330Status = {}));
export function LogService20210330StatusFromJSON(json) {
    return LogService20210330StatusFromJSONTyped(json, false);
}
export function LogService20210330StatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LogService20210330StatusToJSON(value) {
    return value;
}
