/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ActivationServiceActivateBodyToJSON, Billing20201105GetActivationDetailsResponseFromJSON, } from '../models';
/**
 *
 */
export class ActivationServiceApi extends runtime.BaseAPI {
    /**
     * Activate is called by the front end to redeem the given activation code.
     */
    async activationServiceActivateRaw(requestParameters) {
        if (requestParameters.activationCode === null || requestParameters.activationCode === undefined) {
            throw new runtime.RequiredError('activationCode', 'Required parameter requestParameters.activationCode was null or undefined when calling activationServiceActivate.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling activationServiceActivate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/activations/{activation_code}`.replace(`{${"activation_code"}}`, encodeURIComponent(String(requestParameters.activationCode))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivationServiceActivateBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Activate is called by the front end to redeem the given activation code.
     */
    async activationServiceActivate(activationCode, body) {
        const response = await this.activationServiceActivateRaw({ activationCode: activationCode, body: body });
        return await response.value();
    }
    /**
     * GetActivationDetails returns contract activation details associated with an activation code.
     */
    async activationServiceGetActivationDetailsRaw(requestParameters) {
        if (requestParameters.activationCode === null || requestParameters.activationCode === undefined) {
            throw new runtime.RequiredError('activationCode', 'Required parameter requestParameters.activationCode was null or undefined when calling activationServiceGetActivationDetails.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/activations/{activation_code}`.replace(`{${"activation_code"}}`, encodeURIComponent(String(requestParameters.activationCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105GetActivationDetailsResponseFromJSON(jsonValue));
    }
    /**
     * GetActivationDetails returns contract activation details associated with an activation code.
     */
    async activationServiceGetActivationDetails(activationCode) {
        const response = await this.activationServiceGetActivationDetailsRaw({ activationCode: activationCode });
        return await response.value();
    }
}
