/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * PrincipalView allows retrieving a partial view of a Principal.
 *
 *  - PRINCIPAL_VIEW_UNSPECIFIED: The default / unset value.
 *  - PRINCIPAL_VIEW_BASIC: Include basic metadata about the principal, but not the principal details.
 * This will return the Id and Type only.
 *  - PRINCIPAL_VIEW_FULL: Include everything.
 * @export
 * @enum {string}
 */
export var HashicorpCloudIamPrincipalView;
(function (HashicorpCloudIamPrincipalView) {
    HashicorpCloudIamPrincipalView["UNSPECIFIED"] = "PRINCIPAL_VIEW_UNSPECIFIED";
    HashicorpCloudIamPrincipalView["BASIC"] = "PRINCIPAL_VIEW_BASIC";
    HashicorpCloudIamPrincipalView["FULL"] = "PRINCIPAL_VIEW_FULL";
})(HashicorpCloudIamPrincipalView || (HashicorpCloudIamPrincipalView = {}));
export function HashicorpCloudIamPrincipalViewFromJSON(json) {
    return HashicorpCloudIamPrincipalViewFromJSONTyped(json, false);
}
export function HashicorpCloudIamPrincipalViewFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudIamPrincipalViewToJSON(value) {
    return value;
}
