import Component from '@glimmer/component';

export interface CoreCardSummaryValueSignature {
  Args: {
    contextualClass?: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `CoreCardSummaryValueComponent` provides a container to display a value in a key/value pair.
 *
 *
 * ```
 * <Core::Card::Summary::Value>
 *   Some Value
 * </Core::Card::Summary::Value>
 * ```
 *
 * @class CoreCardSummaryValueComponent
 *
 */

export default class CoreCardSummaryValueComponent extends Component<CoreCardSummaryValueSignature> {
  /**
   * Get the class names to apply to the component.
   * @method CoreCardSummaryValueComponent#classNames
   * @return {string} The "class" attribute to apply to the component.
   */
  get classNames() {
    const { contextualClass } = this.args;

    const classes = [
      'core-card-summary-value',
      'hds-typography-body-100',
      'hds-font-weight-regular',
      'hds-foreground-primary',
    ];

    if (contextualClass) {
      classes.push(contextualClass);
    }

    return classes.join(' ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::Card::Summary::Value': typeof CoreCardSummaryValueComponent;
    'core/card/summary/value': typeof CoreCardSummaryValueComponent;
  }
}
