/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * state is one of the states the TGW Attachment could be in.
 *
 * Output only.
 * @export
 * @enum {string}
 */
export var HashicorpCloudNetwork20200907TGWAttachmentState;
(function (HashicorpCloudNetwork20200907TGWAttachmentState) {
    HashicorpCloudNetwork20200907TGWAttachmentState["UNSET"] = "UNSET";
    HashicorpCloudNetwork20200907TGWAttachmentState["CREATING"] = "CREATING";
    HashicorpCloudNetwork20200907TGWAttachmentState["PENDINGACCEPTANCE"] = "PENDING_ACCEPTANCE";
    HashicorpCloudNetwork20200907TGWAttachmentState["ACCEPTED"] = "ACCEPTED";
    HashicorpCloudNetwork20200907TGWAttachmentState["ACTIVE"] = "ACTIVE";
    HashicorpCloudNetwork20200907TGWAttachmentState["FAILED"] = "FAILED";
    HashicorpCloudNetwork20200907TGWAttachmentState["REJECTED"] = "REJECTED";
    HashicorpCloudNetwork20200907TGWAttachmentState["DELETING"] = "DELETING";
    HashicorpCloudNetwork20200907TGWAttachmentState["EXPIRED"] = "EXPIRED";
})(HashicorpCloudNetwork20200907TGWAttachmentState || (HashicorpCloudNetwork20200907TGWAttachmentState = {}));
export function HashicorpCloudNetwork20200907TGWAttachmentStateFromJSON(json) {
    return HashicorpCloudNetwork20200907TGWAttachmentStateFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907TGWAttachmentStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudNetwork20200907TGWAttachmentStateToJSON(value) {
    return value;
}
