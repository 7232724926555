import Modifier from 'ember-modifier';
import { assert } from '@ember/debug';
import { buildSpacingArray } from './box-margin.ts';

export const PADDING_SIZE_SCALE = [
  '0',
  '3xs', // 2px
  '2xs', // 4px
  'xs', // 8px
  'sm', // 12px
  'md', // 16px
  'lg', // 24px, Note: No longer +4px, starting here with `lg`
  '2lg', // 32px
  'xl', // 36px
  '2xl', // 48px
  'auto',
];

export interface BoxPaddingSignature {
  Args: {
    Positional: typeof PADDING_SIZE_SCALE | undefined;
  };
}

/**
 * {{box-padding}} modifier controls the inner padding of an element container and
 *    aligns to the HCP size scale. A single value or an array of values are
 *    accepted values. The semantics are the same as declaring padding in CSS:
 *    top, right, bottom, left.
 * Options: '0', '3xs', '2xs', 'xs', 'sm', 'md', 'lg', '2lg', 'xl', '2xl', 'auto'
 *
 *
 * @example
 * {{box-padding '2xs'}} will render an element with 2xs padding on all sides.
 * {{box-padding '2x xs'}} will render an element with 2x padding on the top and
 *    bottom, and xs padding on the right and left.
 * {{box-padding '0 xs'}} will render an element with zero padding on the top and
 *    bottom, and xs padding on the right and left.
 *
 *
 * @default 'sm'
 * @type {string}
 */
export default class BoxPaddingModifier extends Modifier<BoxPaddingSignature> {
  modify(element: HTMLElement, params: string[]) {
    const spacing = buildSpacingArray(params);

    for (const size of spacing) {
      assert(
        `padding size for '{{box-padding}}' must be one of the following: ${PADDING_SIZE_SCALE.join(
          ', '
        )}, received: ${size}`,
        PADDING_SIZE_SCALE.includes(size)
      );
    }
    const elementClassNames = [
      'box--padding-top',
      'box--padding-right',
      'box--padding-bottom',
      'box--padding-left',
    ].map((prefix, i) => `${prefix}-${spacing[i]}`);

    element.classList.add('box', ...elementClassNames);

    return element;
  }
}
