/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * authorization are the authorization results of intentions between services.
 * Either traffic is allowed, denied, or undefined.
 *
 *  - AUTHORIZATION_ALLOW_INTENTIONS: traffic is allowed because of an intention
 *  - AUTHORIZATION_DENY_INTENTIONS: traffic is denied because of an intention
 *  - AUTHORIZATION_ALLOW_DEFAULT: traffic is allowed because of a default acl policy
 *  - AUTHORIZATION_DENY_DEFAULT: traffic is denied because of a default acl policy
 *  - AUTHORIZATION_UNDEFINED_APP_AWARE: traffic is either allowed or denied based on paths in the request. We cannot tell, from the
 * intentions/default_policy alone, whether calls will be allowed or denied. This is the case if
 * HTTP is set on intention permissions: https://developer.hashicorp.com/consul/docs/connect/config-entries/service-intentions#sources-permissions-http
 * @export
 * @enum {string}
 */
export var HashicorpCloudConsulTelemetry20230414IntentionsAuthorization;
(function (HashicorpCloudConsulTelemetry20230414IntentionsAuthorization) {
    HashicorpCloudConsulTelemetry20230414IntentionsAuthorization["UNSPECIFIED"] = "AUTHORIZATION_UNSPECIFIED";
    HashicorpCloudConsulTelemetry20230414IntentionsAuthorization["ALLOWINTENTIONS"] = "AUTHORIZATION_ALLOW_INTENTIONS";
    HashicorpCloudConsulTelemetry20230414IntentionsAuthorization["DENYINTENTIONS"] = "AUTHORIZATION_DENY_INTENTIONS";
    HashicorpCloudConsulTelemetry20230414IntentionsAuthorization["ALLOWDEFAULT"] = "AUTHORIZATION_ALLOW_DEFAULT";
    HashicorpCloudConsulTelemetry20230414IntentionsAuthorization["DENYDEFAULT"] = "AUTHORIZATION_DENY_DEFAULT";
    HashicorpCloudConsulTelemetry20230414IntentionsAuthorization["UNDEFINEDAPPAWARE"] = "AUTHORIZATION_UNDEFINED_APP_AWARE";
})(HashicorpCloudConsulTelemetry20230414IntentionsAuthorization || (HashicorpCloudConsulTelemetry20230414IntentionsAuthorization = {}));
export function HashicorpCloudConsulTelemetry20230414IntentionsAuthorizationFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414IntentionsAuthorizationFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414IntentionsAuthorizationFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudConsulTelemetry20230414IntentionsAuthorizationToJSON(value) {
    return value;
}
