/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudOperationOperationFromJSON, HashicorpCloudOperationOperationToJSON, } from './';
export function HashicorpCloudVault20201125UpdatePublicIpsResponseFromJSON(json) {
    return HashicorpCloudVault20201125UpdatePublicIpsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125UpdatePublicIpsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'operation': !exists(json, 'operation') ? undefined : HashicorpCloudOperationOperationFromJSON(json['operation']),
    };
}
export function HashicorpCloudVault20201125UpdatePublicIpsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'operation': HashicorpCloudOperationOperationToJSON(value.operation),
    };
}
