/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20230613GhOrgConnectionDetailsRequestFromJSON(json) {
    return Secrets20230613GhOrgConnectionDetailsRequestFromJSONTyped(json, false);
}
export function Secrets20230613GhOrgConnectionDetailsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'token': !exists(json, 'token') ? undefined : json['token'],
        'org': !exists(json, 'org') ? undefined : json['org'],
        'visibility': !exists(json, 'visibility') ? undefined : json['visibility'],
        'selectedRepositoryNames': !exists(json, 'selected_repository_names') ? undefined : json['selected_repository_names'],
    };
}
export function Secrets20230613GhOrgConnectionDetailsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token': value.token,
        'org': value.org,
        'visibility': value.visibility,
        'selected_repository_names': value.selectedRepositoryNames,
    };
}
