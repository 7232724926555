/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudResourcemanagerPolicyBindingMemberFromJSON, HashicorpCloudResourcemanagerPolicyBindingMemberToJSON, } from './';
export function HashicorpCloudResourcemanagerPolicyBindingFromJSON(json) {
    return HashicorpCloudResourcemanagerPolicyBindingFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerPolicyBindingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'roleId': !exists(json, 'role_id') ? undefined : json['role_id'],
        'members': !exists(json, 'members') ? undefined : (json['members'].map(HashicorpCloudResourcemanagerPolicyBindingMemberFromJSON)),
    };
}
export function HashicorpCloudResourcemanagerPolicyBindingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'role_id': value.roleId,
        'members': value.members === undefined ? undefined : (value.members.map(HashicorpCloudResourcemanagerPolicyBindingMemberToJSON)),
    };
}
