/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeek;
(function (HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeek) {
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeek["DAYOFWEEKINVALID"] = "DAY_OF_WEEK_INVALID";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeek["MONDAY"] = "MONDAY";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeek["TUESDAY"] = "TUESDAY";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeek["WEDNESDAY"] = "WEDNESDAY";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeek["THURSDAY"] = "THURSDAY";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeek["FRIDAY"] = "FRIDAY";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeek["SATURDAY"] = "SATURDAY";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeek["SUNDAY"] = "SUNDAY";
})(HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeek || (HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeek = {}));
export function HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeekFromJSON(json) {
    return HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeekFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeekFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeekToJSON(value) {
    return value;
}
