/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVagrant20220930BoxDataFromJSON, HashicorpCloudVagrant20220930BoxDataToJSON, } from './';
export function HashicorpCloudVagrant20220930ArchitectureFromJSON(json) {
    return HashicorpCloudVagrant20220930ArchitectureFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930ArchitectureFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'architectureType': !exists(json, 'architecture_type') ? undefined : json['architecture_type'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'boxData': !exists(json, 'box_data') ? undefined : HashicorpCloudVagrant20220930BoxDataFromJSON(json['box_data']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}
export function HashicorpCloudVagrant20220930ArchitectureToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'architecture_type': value.architectureType,
        'default': value._default,
        'box_data': HashicorpCloudVagrant20220930BoxDataToJSON(value.boxData),
    };
}
