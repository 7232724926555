/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - TYPE_UNSPECIFIED: unspecified type to filter on. If this error appears, default to self-managed clusters
 *  - TYPE_ALL: all cluster types
 *  - TYPE_SELF: self-managed clusters
 *  - TYPE_HCP: HCP-managed clusters
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215ListClustersRequestType;
(function (HashicorpCloudGlobalNetworkManager20220215ListClustersRequestType) {
    HashicorpCloudGlobalNetworkManager20220215ListClustersRequestType["UNSPECIFIED"] = "TYPE_UNSPECIFIED";
    HashicorpCloudGlobalNetworkManager20220215ListClustersRequestType["ALL"] = "TYPE_ALL";
    HashicorpCloudGlobalNetworkManager20220215ListClustersRequestType["SELF"] = "TYPE_SELF";
    HashicorpCloudGlobalNetworkManager20220215ListClustersRequestType["HCP"] = "TYPE_HCP";
})(HashicorpCloudGlobalNetworkManager20220215ListClustersRequestType || (HashicorpCloudGlobalNetworkManager20220215ListClustersRequestType = {}));
export function HashicorpCloudGlobalNetworkManager20220215ListClustersRequestTypeFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ListClustersRequestTypeFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ListClustersRequestTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215ListClustersRequestTypeToJSON(value) {
    return value;
}
