/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudPacker20221202ModelsCreateChannelBodyFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsCreateChannelBodyFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsCreateChannelBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'versionFingerprint': !exists(json, 'version_fingerprint') ? undefined : json['version_fingerprint'],
        'restricted': !exists(json, 'restricted') ? undefined : json['restricted'],
    };
}
export function HashicorpCloudPacker20221202ModelsCreateChannelBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'version_fingerprint': value.versionFingerprint,
        'restricted': value.restricted,
    };
}
