import templateOnlyComponent from '@ember/component/template-only';

interface ChartsPercentageBarSummarySignature {
  Args: {
    value: unknown;
  };
}

const ChartsPercentageBarSummaryComponent =
  templateOnlyComponent<ChartsPercentageBarSummarySignature>();

export default ChartsPercentageBarSummaryComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Charts::PercentageBar::Summary': typeof ChartsPercentageBarSummaryComponent;
    'charts/percentage-bar/summary': typeof ChartsPercentageBarSummaryComponent;
  }
}
