/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudPacker20221202ModelsCreateBuildBodyToJSON, HashicorpCloudPacker20221202ModelsCreateBuildResponseFromJSON, HashicorpCloudPacker20221202ModelsGetBuildResponseFromJSON, HashicorpCloudPacker20221202ModelsListBuildsResponseFromJSON, HashicorpCloudPacker20221202ModelsUpdateBuildBodyToJSON, HashicorpCloudPacker20221202ModelsUpdateBuildResponseFromJSON, } from '../models';
/**
 *
 */
export class PackerBuildServiceApi extends runtime.BaseAPI {
    /**
     * Creates a build in the provided version. It is called once for each \"build source\" in a Packer build run. The request will error if the build for a given platform already exists.
     */
    async packerBuildServiceCreateBuildRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerBuildServiceCreateBuild.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerBuildServiceCreateBuild.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerBuildServiceCreateBuild.');
        }
        if (requestParameters.fingerprint === null || requestParameters.fingerprint === undefined) {
            throw new runtime.RequiredError('fingerprint', 'Required parameter requestParameters.fingerprint was null or undefined when calling packerBuildServiceCreateBuild.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling packerBuildServiceCreateBuild.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/versions/{fingerprint}/builds`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))).replace(`{${"fingerprint"}}`, encodeURIComponent(String(requestParameters.fingerprint))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudPacker20221202ModelsCreateBuildBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsCreateBuildResponseFromJSON(jsonValue));
    }
    /**
     * Creates a build in the provided version. It is called once for each \"build source\" in a Packer build run. The request will error if the build for a given platform already exists.
     */
    async packerBuildServiceCreateBuild(requestParameters) {
        const response = await this.packerBuildServiceCreateBuildRaw(requestParameters);
        return await response.value();
    }
    /**
     * Deletes a build in the provided version.
     */
    async packerBuildServiceDeleteBuildRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerBuildServiceDeleteBuild.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerBuildServiceDeleteBuild.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerBuildServiceDeleteBuild.');
        }
        if (requestParameters.fingerprint === null || requestParameters.fingerprint === undefined) {
            throw new runtime.RequiredError('fingerprint', 'Required parameter requestParameters.fingerprint was null or undefined when calling packerBuildServiceDeleteBuild.');
        }
        if (requestParameters.buildId === null || requestParameters.buildId === undefined) {
            throw new runtime.RequiredError('buildId', 'Required parameter requestParameters.buildId was null or undefined when calling packerBuildServiceDeleteBuild.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/versions/{fingerprint}/builds/{build_id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))).replace(`{${"fingerprint"}}`, encodeURIComponent(String(requestParameters.fingerprint))).replace(`{${"build_id"}}`, encodeURIComponent(String(requestParameters.buildId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Deletes a build in the provided version.
     */
    async packerBuildServiceDeleteBuild(requestParameters) {
        const response = await this.packerBuildServiceDeleteBuildRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieves a build with its list of artifacts.
     */
    async packerBuildServiceGetBuildRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerBuildServiceGetBuild.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerBuildServiceGetBuild.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerBuildServiceGetBuild.');
        }
        if (requestParameters.fingerprint === null || requestParameters.fingerprint === undefined) {
            throw new runtime.RequiredError('fingerprint', 'Required parameter requestParameters.fingerprint was null or undefined when calling packerBuildServiceGetBuild.');
        }
        if (requestParameters.buildId === null || requestParameters.buildId === undefined) {
            throw new runtime.RequiredError('buildId', 'Required parameter requestParameters.buildId was null or undefined when calling packerBuildServiceGetBuild.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/versions/{fingerprint}/builds/{build_id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))).replace(`{${"fingerprint"}}`, encodeURIComponent(String(requestParameters.fingerprint))).replace(`{${"build_id"}}`, encodeURIComponent(String(requestParameters.buildId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsGetBuildResponseFromJSON(jsonValue));
    }
    /**
     * Retrieves a build with its list of artifacts.
     */
    async packerBuildServiceGetBuild(requestParameters) {
        const response = await this.packerBuildServiceGetBuildRaw(requestParameters);
        return await response.value();
    }
    /**
     * Lists every existing build and its artifacts for a version.
     */
    async packerBuildServiceListBuildsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerBuildServiceListBuilds.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerBuildServiceListBuilds.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerBuildServiceListBuilds.');
        }
        if (requestParameters.fingerprint === null || requestParameters.fingerprint === undefined) {
            throw new runtime.RequiredError('fingerprint', 'Required parameter requestParameters.fingerprint was null or undefined when calling packerBuildServiceListBuilds.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        if (requestParameters.sortingOrderBy) {
            queryParameters['sorting.order_by'] = requestParameters.sortingOrderBy;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/versions/{fingerprint}/builds`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))).replace(`{${"fingerprint"}}`, encodeURIComponent(String(requestParameters.fingerprint))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsListBuildsResponseFromJSON(jsonValue));
    }
    /**
     * Lists every existing build and its artifacts for a version.
     */
    async packerBuildServiceListBuilds(requestParameters) {
        const response = await this.packerBuildServiceListBuildsRaw(requestParameters);
        return await response.value();
    }
    /**
     * Updates the build. This may be most often used for modifying the status of a currently running build.
     */
    async packerBuildServiceUpdateBuildRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerBuildServiceUpdateBuild.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerBuildServiceUpdateBuild.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerBuildServiceUpdateBuild.');
        }
        if (requestParameters.fingerprint === null || requestParameters.fingerprint === undefined) {
            throw new runtime.RequiredError('fingerprint', 'Required parameter requestParameters.fingerprint was null or undefined when calling packerBuildServiceUpdateBuild.');
        }
        if (requestParameters.buildId === null || requestParameters.buildId === undefined) {
            throw new runtime.RequiredError('buildId', 'Required parameter requestParameters.buildId was null or undefined when calling packerBuildServiceUpdateBuild.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling packerBuildServiceUpdateBuild.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/versions/{fingerprint}/builds/{build_id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))).replace(`{${"fingerprint"}}`, encodeURIComponent(String(requestParameters.fingerprint))).replace(`{${"build_id"}}`, encodeURIComponent(String(requestParameters.buildId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudPacker20221202ModelsUpdateBuildBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsUpdateBuildResponseFromJSON(jsonValue));
    }
    /**
     * Updates the build. This may be most often used for modifying the status of a currently running build.
     */
    async packerBuildServiceUpdateBuild(requestParameters) {
        const response = await this.packerBuildServiceUpdateBuildRaw(requestParameters);
        return await response.value();
    }
}
