import Component from '@glimmer/component';

interface TooltipConditionalHdsSignature {
  Args: {
    offset: unknown;
    placement: unknown;
    tooltipText: unknown;
    useTooltip: unknown;
  };
  Blocks: {
    anchor: [];
  };
}

/**
 *
 * The TooltipConditionalHds component yields the tooltip sub-elements to the
 * Hds::TooltipButton component if the provided property evaluates to true. 
 *
 *
 * ```
 * <TooltipConditionalHds @useTooltip={{true}} @tooltipText={{"hello"}} @offset={{array 50 30}} @placement="bottom">
    <:anchor>
      Anchor
    </:anchor>
 * </TooltipConditionalHds>
 * ```
 *
 * @class TooltipConditionalHds
 */

/**
 * Whether to display the Hds::TooltipButton or not
 *
 * @argument useTooltip
 * @type {boolean}
 */

/**
 * The text to display in the tooltip
 *
 * @argument tooltipText
 * @type {string}
 */

/**
 * The offset of the tooltip in relation to the opener element content
 *
 * @argument offset
 * @type {array}
 */

/**
 * Defines the starting position of the tooltip (options are top (the default), bottom, left, right)
 *
 * @argument placement
 * @type {string}
 */

export default class TooltipConditionalHdsComponent extends Component<TooltipConditionalHdsSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TooltipConditional::Hds': typeof TooltipConditionalHdsComponent;
    'tooltip-conditional/hds': typeof TooltipConditionalHdsComponent;
  }
}
