import Component from '@glimmer/component';

import FlexGridItemComponent from './flex-grid/item.ts';

interface FlexGridSignature {
  Args: {
    inside?: unknown;
    reverse?: unknown;
  };
  Blocks: {
    default: [
      {
        Item: typeof FlexGridItemComponent;
      },
    ];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `FlexGrid` wraps FlexGrid::Item to provide a 12-column grid system.
 *
 *
 * ```
 * <FlexGrid @reverse={{true}}></FlexGrid>
 * ```
 *
 * @class FlexGrid
 *
 */

export default class FlexGridComponent extends Component<FlexGridSignature> {
  /**
   * Reverses the order of the items in this flex grid.
   * @argument reverse
   * @type {boolean}
   */
  /**
   * Removes inner negative margin
   * @argument inside
   * @type {boolean}
   */
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FlexGrid: typeof FlexGridComponent;
    'flex-grid': typeof FlexGridComponent;
  }
}
