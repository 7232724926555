import BaseAbility from './base-ability';
import {
  ACTION_LIST_ROLES,
  PREFIX_RESOURCE_MANAGER_ORGANIZATIONS,
  PREFIX_RESOURCE_MANAGER_RESOURCES,
} from '../utils/permission-types/index';
import { PermissionScope } from '../services/permissions';

export default class extends BaseAbility {
  // This is intentionally ORGANIZATIONS because the list roles page
  // is driven by the resource-manager.organizations.list-roles role.
  prefix = PREFIX_RESOURCE_MANAGER_ORGANIZATIONS;

  /** @return {boolean} */
  get canList() {
    return (
      this.permissions.has(
        this.generate(ACTION_LIST_ROLES),
        PermissionScope.ORGANIZATION,
      ) ||
      this.permissions.has(
        `${PREFIX_RESOURCE_MANAGER_RESOURCES}.${ACTION_LIST_ROLES}`,
      )
    );
  }
}
