import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import {
  COPY_ICON_NAME,
  COPIED_ICON_NAME,
} from '../../utils/consts/toolbar.js';

interface CodeEditorToolbarSignature {
  Args: {
    container: unknown;
    copyText: string;
    onCopy?: () => void;
  };
  Blocks: {
    action: [];
    menu: [];
  };
}

/**
 *
 * `CodeEditorToolbar` is intended to go on top of the FieldEditor and provides a 'Copy code'
 * button as well as other optional consumer-provided actions.
 * Consumer-provided actions are arranged into the following slots:
 * ----------------------------------------------------------------------------------------
 *  :action:                                                       :menu:   |   Copy code
 * ----------------------------------------------------------------------------------------
 *
 * Toolbar usage with no actions:
 * ```
 * <CodeEditor::Toolbar
 *   @copyText={{this.codeToCopy}}
 *   @onCopy={{this.onCopy}}
 * />
 * ```
 *
 *  * Toolbar usage with actions:
 * ```
 * <CodeEditor::Toolbar
 *   @copyText={{this.codeToCopy}}
 *   @onCopy={{this.onCopy}}
 * >
 *   <:action>
 *     <Hds::Link::Inline @href='#'>
 *       Download Terraform
 *     </Hds::Link::Inline>
 *   </:action>
 *
 *   <:menu>
 *    ...
 *   </:menu>
 * </CodeEditor::Toolbar>
 * ```
 *
 * @class CodeEditorToolbar
 *
 */

export default class CodeEditorToolbarComponent extends Component<CodeEditorToolbarSignature> {
  /**
   *
   * `copyText` is what will be copied to clipboard when the user clicks the 'Copy code' button.
   * @argument copyText;
   * @type {String}
   *
   */
  /**
   *
   * `onCopy` is a called when the copyText is successfully copied.
   * @argument onCopy;
   * @type {?Function}
   *
   */

  @tracked copyIconName = COPY_ICON_NAME;

  @task
  *copied() {
    const { onCopy } = this.args;
    const originalIconName = this.copyIconName;

    this.copyIconName = COPIED_ICON_NAME;
    onCopy && onCopy();

    yield timeout(1000);

    this.copyIconName = originalIconName;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CodeEditor::Toolbar': typeof CodeEditorToolbarComponent;
    'code-editor/toolbar': typeof CodeEditorToolbarComponent;
  }
}
