/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var Secrets20230613HcpTerraformType;
(function (Secrets20230613HcpTerraformType) {
    Secrets20230613HcpTerraformType["WORKSPACE"] = "WORKSPACE";
    Secrets20230613HcpTerraformType["VARIABLESET"] = "VARIABLE_SET";
})(Secrets20230613HcpTerraformType || (Secrets20230613HcpTerraformType = {}));
export function Secrets20230613HcpTerraformTypeFromJSON(json) {
    return Secrets20230613HcpTerraformTypeFromJSONTyped(json, false);
}
export function Secrets20230613HcpTerraformTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Secrets20230613HcpTerraformTypeToJSON(value) {
    return value;
}
