/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudResourcemanagerResourceFromJSON, HashicorpCloudResourcemanagerResourceToJSON, } from './';
export function HashicorpCloudResourcemanagerResourceTestIamPermissionsRequestFromJSON(json) {
    return HashicorpCloudResourcemanagerResourceTestIamPermissionsRequestFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerResourceTestIamPermissionsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'resource': !exists(json, 'resource') ? undefined : HashicorpCloudResourcemanagerResourceFromJSON(json['resource']),
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
    };
}
export function HashicorpCloudResourcemanagerResourceTestIamPermissionsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'resource': HashicorpCloudResourcemanagerResourceToJSON(value.resource),
        'permissions': value.permissions,
    };
}
