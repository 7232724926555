/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIamAuthConnectionFromJSON, HashicorpCloudIamAuthConnectionToJSON, } from './';
export function HashicorpCloudIamCreateAuthConnectionResponseFromJSON(json) {
    return HashicorpCloudIamCreateAuthConnectionResponseFromJSONTyped(json, false);
}
export function HashicorpCloudIamCreateAuthConnectionResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'authConnection': !exists(json, 'auth_connection') ? undefined : HashicorpCloudIamAuthConnectionFromJSON(json['auth_connection']),
    };
}
export function HashicorpCloudIamCreateAuthConnectionResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'auth_connection': HashicorpCloudIamAuthConnectionToJSON(value.authConnection),
    };
}
