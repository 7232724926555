/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function StatementResourceLineItemFromJSON(json) {
    return StatementResourceLineItemFromJSONTyped(json, false);
}
export function StatementResourceLineItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': !exists(json, 'description') ? undefined : json['description'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'unitPrice': !exists(json, 'unit_price') ? undefined : json['unit_price'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'unitDisplayKey': !exists(json, 'unit_display_key') ? undefined : json['unit_display_key'],
        'averageQuantity': !exists(json, 'average_quantity') ? undefined : json['average_quantity'],
        'averageUnitDisplayKey': !exists(json, 'average_unit_display_key') ? undefined : json['average_unit_display_key'],
        'startedAt': !exists(json, 'started_at') ? undefined : (new Date(json['started_at'])),
        'endedAt': !exists(json, 'ended_at') ? undefined : (new Date(json['ended_at'])),
    };
}
export function StatementResourceLineItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'amount': value.amount,
        'unit_price': value.unitPrice,
        'quantity': value.quantity,
        'unit_display_key': value.unitDisplayKey,
        'average_quantity': value.averageQuantity,
        'average_unit_display_key': value.averageUnitDisplayKey,
        'started_at': value.startedAt === undefined ? undefined : (value.startedAt.toISOString()),
        'ended_at': value.endedAt === undefined ? undefined : (value.endedAt.toISOString()),
    };
}
