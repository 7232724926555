import templateOnlyComponent from '@ember/component/template-only';

import type { ComponentLike } from '@glint/template';

interface LayoutSidebarSubnavSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
  Blocks: {
    default: [
      {
        BackLink: ComponentLike<{
          Element: HTMLAnchorElement;
          Args: {
            text: string;
            route: string;
            isRouteExternal?: boolean;
            models?: Array<string | number>;
            model?: string | number;
            count?: unknown;
          };
        }>;
        Title: ComponentLike<{
          Element: HTMLDivElement;
          Blocks: {
            default: [];
          };
        }>;
        Link: ComponentLike<{
          Element: HTMLAnchorElement;
          Args: {
            text: string;
            route: string;
            isRouteExternal?: boolean;
            models?: Array<string | number>;
            model?: string | number;
            count?: unknown;
          };
        }>;
      },
    ];
  };
  Element: HTMLElement;
}

const LayoutSidebarSubnavComponent =
  templateOnlyComponent<LayoutSidebarSubnavSignature>();

export default LayoutSidebarSubnavComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::Sidebar::Subnav': typeof LayoutSidebarSubnavComponent;
    'layout/sidebar/subnav': typeof LayoutSidebarSubnavComponent;
  }
}
