// This is a tagged template function https://codeburst.io/javascript-what-are-tag-functions-97682f29521b
// usage in your component index.js:
// let someTemplate = templateString`somestring--${'id'}`;
// let desiredStringOutput = someTemplate({id: 'an-id'});
// return desiredStringOutput;
// desiredStringOutput will result in 'somestring--an-id'

export default function templateString(
  strings: TemplateStringsArray,
  ...keys: string[]
) {
  return function (data: Record<string, string> | undefined) {
    const dict = data || {};
    const result = [strings[0]];

    keys.forEach((key, i) => {
      result.push(dict[key], strings[i + 1]);
    });
    return result.join('');
  };
}
