/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudResourcemanagerOrganizationOrganizationStateFromJSON, HashicorpCloudResourcemanagerOrganizationOrganizationStateToJSON, HashicorpCloudResourcemanagerOrganizationOwnerFromJSON, HashicorpCloudResourcemanagerOrganizationOwnerToJSON, } from './';
export function HashicorpCloudResourcemanagerOrganizationFromJSON(json) {
    return HashicorpCloudResourcemanagerOrganizationFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerOrganizationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'owner': !exists(json, 'owner') ? undefined : HashicorpCloudResourcemanagerOrganizationOwnerFromJSON(json['owner']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'state': !exists(json, 'state') ? undefined : HashicorpCloudResourcemanagerOrganizationOrganizationStateFromJSON(json['state']),
        'tfcSynced': !exists(json, 'tfc_synced') ? undefined : json['tfc_synced'],
    };
}
export function HashicorpCloudResourcemanagerOrganizationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'owner': HashicorpCloudResourcemanagerOrganizationOwnerToJSON(value.owner),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'state': HashicorpCloudResourcemanagerOrganizationOrganizationStateToJSON(value.state),
        'tfc_synced': value.tfcSynced,
    };
}
