/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * OrganizationState is the states the organization can be in.
 * @export
 * @enum {string}
 */
export var HashicorpCloudResourcemanagerOrganizationOrganizationState;
(function (HashicorpCloudResourcemanagerOrganizationOrganizationState) {
    HashicorpCloudResourcemanagerOrganizationOrganizationState["UNKNOWN"] = "UNKNOWN";
    HashicorpCloudResourcemanagerOrganizationOrganizationState["ACTIVE"] = "ACTIVE";
    HashicorpCloudResourcemanagerOrganizationOrganizationState["PENDINGDELETE"] = "PENDING_DELETE";
})(HashicorpCloudResourcemanagerOrganizationOrganizationState || (HashicorpCloudResourcemanagerOrganizationOrganizationState = {}));
export function HashicorpCloudResourcemanagerOrganizationOrganizationStateFromJSON(json) {
    return HashicorpCloudResourcemanagerOrganizationOrganizationStateFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerOrganizationOrganizationStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudResourcemanagerOrganizationOrganizationStateToJSON(value) {
    return value;
}
