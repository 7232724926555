/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Type is a list of all possible types a PackageItem can have.
 *
 *  - UNSET: UNSET is not a valid type and will not be accepted by the service.
 *  - SELECTION: SELECTION means that the value for this item must be supplied by the caller
 * when registering a new resource (unless it's an optional item).
 *  - COST: COST means that the value of this item will always be implicitly set by the billing
 * service, depending on the available options of the package item.
 * Items of this type are never selected by the user, but are a consequence of the
 * `SELECTION` items they chose.
 * The internal process to select a COST item follows these steps: (This applies only for Type COST)
 *
 * 1. If the item options have `dependencies`
 *  1.a. It will assign the option key for the item with the option that matches
 *       the dependencies.
 *       There should never be more than one option with the same dependencies.
 *  1.b. If there's no matching dependency, but the item also has an option
 *       without dependency, that option will be assigned to it.
 * 2. When there's no dependency at all, the existing option for the item will
 *    be assigned for it.
 *    There should never be more than 1 option per item without dependency.
 * @export
 * @enum {string}
 */
export var Billing20201105PackageItemType;
(function (Billing20201105PackageItemType) {
    Billing20201105PackageItemType["UNSET"] = "UNSET";
    Billing20201105PackageItemType["SELECTION"] = "SELECTION";
    Billing20201105PackageItemType["COST"] = "COST";
})(Billing20201105PackageItemType || (Billing20201105PackageItemType = {}));
export function Billing20201105PackageItemTypeFromJSON(json) {
    return Billing20201105PackageItemTypeFromJSONTyped(json, false);
}
export function Billing20201105PackageItemTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Billing20201105PackageItemTypeToJSON(value) {
    return value;
}
