import FlashMessages from 'ember-cli-flash/services/flash-messages';
import { SYSTEM_TYPE } from '../utils/constants.ts';
import type { SystemNotification } from '../utils/constants.ts';

export default class CoreFlashMessages extends FlashMessages {
  systemNotification(flash: SystemNotification, context = {}) {
    this.add({
      type: SYSTEM_TYPE,
      sticky: true,
      message: flash.id,
      ...flash,
      context,
    });
  }

  remove(id: string) {
    const message = this.queue.findBy('id', id);
    if (message) {
      message._teardown();
    }
  }
}
