/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface DisclosureSignature {
  Args: {
    badge?: object;
    label?: string;
    openLabel?: string;
    isInitiallyOpen?: boolean;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `Disclosure` renders a `<details>` element styled with an icon instead of the
 * default indicator. It also can change the summary text based on whether the
 * element is open.
 *
 *
 * ## Example usage
 *
 * ```
 * <Disclosure
 *   @label='See example'
 *   @openLabel='Hide example'
 *   @badge={{hash
        text='Marked Complete'
        color='success'
        icon='check'
        size='small'
      }}
 * >
 *   <p>Here's the example!</p>
 * </Disclosure>
 * ```
 *
 * @class Disclosure
 *
 */

/**
 * `label` is the text to show in the default state
 * @argument label
 * @type {string}
 * @required
 */

/**
 * `openLabel` is the text to show in the open state. When not provided,
 * @label is used instead.
 * @argument openLabel
 * @type {string}
 * @optional
 */

/**
 * `isInitiallyOpen` can be optionally set to `true` if the disclosure should be
 * open on initial render.
 * @argument isInitiallyOpen
 * @type {boolean}
 * @optional
 */

/**
 * `badge` can be optionally set to an object containing args for the Hds::Badge
 * component. It's presence also acts as boolean for its rendering in the <summary>
 * element.
 * @argument badge
 * @type {Object}
 * @optional
 */

export default class DisclosureComponent extends Component<DisclosureSignature> {
  // This property only tracks the `<details>` `open` attribute, allowing us to
  // re-render when that changes.
  @tracked isOpen;

  constructor(owner: unknown, args: DisclosureSignature['Args']) {
    super(owner, args);
    this.isOpen = args.isInitiallyOpen || false;
  }

  @action
  setOpen(ctx: { target: { open: any }; open: any }) {
    // `ctx` is either an event from toggling detail or the details element
    // itself passed in the did-insert render modifier to set the initial state.
    this.isOpen = ctx?.target?.open ?? ctx.open;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Disclosure: typeof DisclosureComponent;
    disclosure: typeof DisclosureComponent;
  }
}
