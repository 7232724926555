/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - UNSPECIFIED: UNSPECIFIED means network connnectivity is left up to the user
 *  - HVN_TO_HVN_PEERING: Triggers an automatic peering of two HVNs for connectivity
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215NetworkConnectivity;
(function (HashicorpCloudGlobalNetworkManager20220215NetworkConnectivity) {
    HashicorpCloudGlobalNetworkManager20220215NetworkConnectivity["UNSPECIFIED"] = "UNSPECIFIED";
    HashicorpCloudGlobalNetworkManager20220215NetworkConnectivity["HVNTOHVNPEERING"] = "HVN_TO_HVN_PEERING";
})(HashicorpCloudGlobalNetworkManager20220215NetworkConnectivity || (HashicorpCloudGlobalNetworkManager20220215NetworkConnectivity = {}));
export function HashicorpCloudGlobalNetworkManager20220215NetworkConnectivityFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215NetworkConnectivityFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215NetworkConnectivityFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215NetworkConnectivityToJSON(value) {
    return value;
}
