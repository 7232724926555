/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CommonPaginationResponseFromJSON, CommonPaginationResponseToJSON, Secrets20231128SecretRotatingVersionListFromJSON, Secrets20231128SecretRotatingVersionListToJSON, Secrets20231128SecretStaticVersionListFromJSON, Secrets20231128SecretStaticVersionListToJSON, } from './';
export function Secrets20231128ListAppSecretVersionsResponseFromJSON(json) {
    return Secrets20231128ListAppSecretVersionsResponseFromJSONTyped(json, false);
}
export function Secrets20231128ListAppSecretVersionsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !exists(json, 'type') ? undefined : json['type'],
        'staticVersions': !exists(json, 'static_versions') ? undefined : Secrets20231128SecretStaticVersionListFromJSON(json['static_versions']),
        'rotatingVersions': !exists(json, 'rotating_versions') ? undefined : Secrets20231128SecretRotatingVersionListFromJSON(json['rotating_versions']),
        'pagination': !exists(json, 'pagination') ? undefined : CommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function Secrets20231128ListAppSecretVersionsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'static_versions': Secrets20231128SecretStaticVersionListToJSON(value.staticVersions),
        'rotating_versions': Secrets20231128SecretRotatingVersionListToJSON(value.rotatingVersions),
        'pagination': CommonPaginationResponseToJSON(value.pagination),
    };
}
