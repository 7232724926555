/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenAWS4RequestFromJSON, HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenAWS4RequestToJSON, } from './';
export function HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenFromJSON(json) {
    return HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenFromJSONTyped(json, false);
}
export function HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'jwtToken': !exists(json, 'jwt_token') ? undefined : json['jwt_token'],
        'awsGetCallerIdToken': !exists(json, 'aws_get_caller_id_token') ? undefined : HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenAWS4RequestFromJSON(json['aws_get_caller_id_token']),
    };
}
export function HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'jwt_token': value.jwtToken,
        'aws_get_caller_id_token': HashicorpCloudIamExchangeWorkloadIdentityTokenRequestTokenAWS4RequestToJSON(value.awsGetCallerIdToken),
    };
}
