/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionConfigRequestFromJSON, HashicorpCloudWaypointActionConfigRequestToJSON, } from './';
export function ActionConfigWithoutIDFromJSON(json) {
    return ActionConfigWithoutIDFromJSONTyped(json, false);
}
export function ActionConfigWithoutIDFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'actionUrl': !exists(json, 'action_url') ? undefined : json['action_url'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'request': !exists(json, 'request') ? undefined : HashicorpCloudWaypointActionConfigRequestFromJSON(json['request']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}
export function ActionConfigWithoutIDToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'action_url': value.actionUrl,
        'name': value.name,
        'request': HashicorpCloudWaypointActionConfigRequestToJSON(value.request),
        'description': value.description,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}
