import templateOnlyComponent from '@ember/component/template-only';

interface LogoSignature {
  // Args: {};
}

const LogoComponent = templateOnlyComponent<LogoSignature>();

export default LogoComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Logo: typeof LogoComponent;
    logo: typeof LogoComponent;
  }
}
