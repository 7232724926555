/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLinkFromJSON, HashicorpCloudLocationLinkToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudConsul20210204RestoreSnapshotRequestFromJSON(json) {
    return HashicorpCloudConsul20210204RestoreSnapshotRequestFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204RestoreSnapshotRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'snapshot': !exists(json, 'snapshot') ? undefined : HashicorpCloudLocationLinkFromJSON(json['snapshot']),
        'takeSnapshot': !exists(json, 'take_snapshot') ? undefined : json['take_snapshot'],
    };
}
export function HashicorpCloudConsul20210204RestoreSnapshotRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cluster_id': value.clusterId,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'snapshot': HashicorpCloudLocationLinkToJSON(value.snapshot),
        'take_snapshot': value.takeSnapshot,
    };
}
