/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105AddressFromJSON, Billing20201105AddressToJSON, Billing20201105TaxSettingsFromJSON, Billing20201105TaxSettingsToJSON, } from './';
export function Billing20201105OnDemandBillingMethodFromJSON(json) {
    return Billing20201105OnDemandBillingMethodFromJSONTyped(json, false);
}
export function Billing20201105OnDemandBillingMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'billingAddress': !exists(json, 'billing_address') ? undefined : Billing20201105AddressFromJSON(json['billing_address']),
        'taxSettings': !exists(json, 'tax_settings') ? undefined : Billing20201105TaxSettingsFromJSON(json['tax_settings']),
        'stripeSetupIntentId': !exists(json, 'stripe_setup_intent_id') ? undefined : json['stripe_setup_intent_id'],
    };
}
export function Billing20201105OnDemandBillingMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'email': value.email,
        'billing_address': Billing20201105AddressToJSON(value.billingAddress),
        'tax_settings': Billing20201105TaxSettingsToJSON(value.taxSettings),
        'stripe_setup_intent_id': value.stripeSetupIntentId,
    };
}
