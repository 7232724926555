/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var TaxSettingsExemptionStatus;
(function (TaxSettingsExemptionStatus) {
    TaxSettingsExemptionStatus["NONE"] = "NONE";
    TaxSettingsExemptionStatus["EXEMPT"] = "EXEMPT";
    TaxSettingsExemptionStatus["REVERSE"] = "REVERSE";
})(TaxSettingsExemptionStatus || (TaxSettingsExemptionStatus = {}));
export function TaxSettingsExemptionStatusFromJSON(json) {
    return TaxSettingsExemptionStatusFromJSONTyped(json, false);
}
export function TaxSettingsExemptionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TaxSettingsExemptionStatusToJSON(value) {
    return value;
}
