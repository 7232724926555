import BaseAbility from './base-ability';
import { inject as service } from '@ember/service';

import { PREFIX_IAM_USERS } from '../utils/permission-types/index';
import {
  ROLE_TYPE_VIEWER,
  ROLE_TYPE_PROJECT_NO_ROLE_UI_ONLY,
} from 'core/helpers/rbac-roles';

export default class extends BaseAbility {
  @service currentUser;

  prefix = PREFIX_IAM_USERS;

  /**
   * Determine if viewing is available based on org policy and project policy.
   *
   * @return {boolean}
   */
  get canGet() {
    const { id: currentUserId } = this.currentUser.user;
    const member = this.projectPolicy?.getMemberById(currentUserId);
    const isOrgViewer = this.orgPolicy ? false : true;
    const isProjectViewer = member?.memberId
      ? member.roleId === ROLE_TYPE_VIEWER.value
      : isOrgViewer;

    // They cannot view if they are both a viewer of the org and a viewer of
    // the project.
    if (isOrgViewer && isProjectViewer) {
      return false;
    }

    return true;
  }

  /**
   * Determine if delete is available based on project policy and the current
   * user.
   *
   * @return {boolean}
   */
  get canDelete() {
    const { id } = this.model;
    const member = this.projectPolicy?.getMemberById(id);

    if (!member?.roleId) {
      return false;
    }

    const isInProject =
      member && member.roleId !== ROLE_TYPE_PROJECT_NO_ROLE_UI_ONLY.value;

    if (!isInProject) {
      return false;
    }

    if (!this.canUpdate) {
      return false;
    }

    return true;
  }

  /**
   * Determine if updates are available based on project policy and the current
   * user.
   *
   * @return {boolean}
   */
  get canUpdate() {
    const { id } = this.model;
    const { id: currentUserId } = this.currentUser.user;
    const canEditOrg = this.orgPolicy?.canMemberEditPolicy(currentUserId);

    // They cannot edit if they don't have any policy access.
    if (!(this.orgPolicy || this.projectPolicy)) {
      return false;
    }

    // They cannot edit themselves unless they have edit permissions from
    // the inherited organization.
    if (currentUserId === id && !canEditOrg) {
      return false;
    }

    const doesCurrentUserHaveEditAccess =
      canEditOrg || this.projectPolicy?.canMemberEditPolicy(currentUserId);

    // They cannot edit if they don't have any edit access.
    if (!doesCurrentUserHaveEditAccess) {
      return false;
    }

    return true;
  }
}
