/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330StreamingCloudwatchLogsProviderFromJSON, LogService20210330StreamingCloudwatchLogsProviderToJSON, LogService20210330StreamingDatadogProviderFromJSON, LogService20210330StreamingDatadogProviderToJSON, LogService20210330StreamingSplunkCloudProviderFromJSON, LogService20210330StreamingSplunkCloudProviderToJSON, ProtobufFieldMaskFromJSON, ProtobufFieldMaskToJSON, } from './';
export function LogService20210330TestDestinationRequestFromJSON(json) {
    return LogService20210330TestDestinationRequestFromJSONTyped(json, false);
}
export function LogService20210330TestDestinationRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mask': !exists(json, 'mask') ? undefined : ProtobufFieldMaskFromJSON(json['mask']),
        'datadogProvider': !exists(json, 'datadog_provider') ? undefined : LogService20210330StreamingDatadogProviderFromJSON(json['datadog_provider']),
        'cloudwatchLogsProvider': !exists(json, 'cloudwatch_logs_provider') ? undefined : LogService20210330StreamingCloudwatchLogsProviderFromJSON(json['cloudwatch_logs_provider']),
        'splunkCloudProvider': !exists(json, 'splunk_cloud_provider') ? undefined : LogService20210330StreamingSplunkCloudProviderFromJSON(json['splunk_cloud_provider']),
    };
}
export function LogService20210330TestDestinationRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'id': value.id,
        'mask': ProtobufFieldMaskToJSON(value.mask),
        'datadog_provider': LogService20210330StreamingDatadogProviderToJSON(value.datadogProvider),
        'cloudwatch_logs_provider': LogService20210330StreamingCloudwatchLogsProviderToJSON(value.cloudwatchLogsProvider),
        'splunk_cloud_provider': LogService20210330StreamingSplunkCloudProviderToJSON(value.splunkCloudProvider),
    };
}
