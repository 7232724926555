/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330LogEntryFromJSON, LogService20210330LogEntryToJSON, } from './';
export function LogService20210330GetResponseFromJSON(json) {
    return LogService20210330GetResponseFromJSONTyped(json, false);
}
export function LogService20210330GetResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'entries': !exists(json, 'entries') ? undefined : (json['entries'].map(LogService20210330LogEntryFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
    };
}
export function LogService20210330GetResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'entries': value.entries === undefined ? undefined : (value.entries.map(LogService20210330LogEntryToJSON)),
        'next_page_token': value.nextPageToken,
    };
}
