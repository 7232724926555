import { variation } from 'ember-launch-darkly';

import BaseAbility from './base-ability';
import {
  PREFIX_LOG_ENTRIES,
  ACTION_GET,
  ACTION_STORE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = PREFIX_LOG_ENTRIES;

  get canGet() {
    return (
      variation('hcp-ui-monitoring-org-and-project-platform-logs') &&
      this.permissions.has(this.generate(ACTION_GET))
    );
  }

  get canStore() {
    return (
      variation('hcp-ui-monitoring-org-and-project-platform-logs') &&
      this.permissions.has(this.generate(ACTION_STORE))
    );
  }
}
