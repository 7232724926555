/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestoneFromJSON, HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestoneToJSON, } from './';
export function HashicorpCloudVault20201125GetCurrentMilestoneResponseFromJSON(json) {
    return HashicorpCloudVault20201125GetCurrentMilestoneResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125GetCurrentMilestoneResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currentMilestone': !exists(json, 'current_milestone') ? undefined : HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestoneFromJSON(json['current_milestone']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}
export function HashicorpCloudVault20201125GetCurrentMilestoneResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'current_milestone': HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestoneToJSON(value.currentMilestone),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
