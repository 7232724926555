import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import {
  SupportedVariants,
  DefaultVariant,
} from '../utils/consts/video-player.js';

// @ts-expect-error
const makeIdFromSrc = (src) => src.replace(/\//g, '');

interface VideoPlayerSignature {
  Args: {
    onVideoPlay?: () => void;
    onVideoReady?: () => void;
    src: string;
    title: string;
    variant?: string;
  };
}

/**
 *
 * `VideoPlayer` displays YouTube video content in an iframe.
 * This component maintains a 16:9 aspect ratio specifically for YouTube. Based on this aspect ratio, the height of the player is determined by the width of the container.
 *
 *
 * ```
 * <VideoPlayer
 *   @src={{youtubeSrc}}
 *   @title='My youtube video'
 *   @variant='youtube'
 *   @onVideoPlay={{doThisWhenVideoPlays}}
 * />
 * ```
 *
 * @class VideoPlayer
 *
 */

export default class VideoPlayerComponent extends Component<VideoPlayerSignature> {
  /**
   *
   * `src` is the embed url of a public YouTube video
   * @argument src
   * @type {string}
   *
   */
  /**
   *
   * `title` describes the content and/or purpose of the video.
   * @argument title
   * @type {string}
   *
   */
  /**
   *
   * `variant` describes the video provider type. Supported variants: youtube. Default variant: youtube.
   * @argument variant
   * @type {?string}
   *
   */
  /**
   *
   * `onVideoPlay` is an optional callback that gets called anytime the video is played.
   * @argument onVideoPlay
   * @type {?Function}
   *
   */
  /**
   *
   * `onVideoReady` is an optional callback that gets called when the video player API is ready.
   * @argument onVideoPlay
   * @type {?Function}
   *
   */

  player = null;

  constructor(owner: unknown, args: VideoPlayerSignature['Args']) {
    super(owner, args);
  }

  get variant() {
    if (!this.args.variant) {
      return DefaultVariant;
    }

    // @ts-expect-error
    return SupportedVariants[this.args.variant]
      ? this.args.variant
      : DefaultVariant;
  }

  get playerId() {
    // Assume that there will never be two videos with the same src on a given page.
    // This allows the src to double as the iframe id, so that the user doesn't have to provide an additional `id` arg
    return makeIdFromSrc(this.args.src);
  }

  get youtubeSrc() {
    // The Youtube player API will only work with an existing iframe if the `enablejsapi` param is added to the url
    return this.args.src.includes('?')
      ? `${this.args.src}&enablejsapi=1`
      : `${this.args.src}?enablejsapi=1`;
  }

  get src() {
    switch (this.variant) {
      case SupportedVariants.youtube:
        return this.youtubeSrc;
      default:
        return this.args.src;
    }
  }

  @task
  *createPlayer() {
    yield this.loadYoutubeAPI();
    // @ts-expect-error
    this.player = new window.YT.Player(this.playerId, {
      events: {
        onReady: this.onPlayerReady.bind(this),
        onStateChange: this.onPlayerStateChange.bind(this),
      },
    });
  }

  loadYoutubeAPI() {
    return new Promise((resolve) => {
      // @ts-expect-error
      const onReady = window.onYouTubeIframeAPIReady;

      // @ts-expect-error
      window.onYouTubeIframeAPIReady = () => {
        if (onReady) {
          onReady();
        }

        // @ts-expect-error
        resolve(window.YT);
      };

      // @ts-expect-error
      if (window.YT && window.YT.loaded) {
        // @ts-expect-error
        resolve(window.YT);
      } else {
        // Append youtube iframe API script to the document if not already loaded
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        document.head.appendChild(script);
      }
    });
  }

  onPlayerReady() {
    if (this.args.onVideoReady) {
      this.args.onVideoReady();
    }
  }

  // @ts-expect-error
  onPlayerStateChange(event) {
    // @ts-expect-error
    if (this.args.onVideoPlay && event.data === window.YT.PlayerState.PLAYING) {
      this.args.onVideoPlay();
    }
  }

  willDestroy() {
    // @ts-expect-error
    // eslint-disable-next-line prefer-rest-params
    super.willDestroy(...arguments);
    // @ts-expect-error
    this.player && this.player.destroy();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    VideoPlayer: typeof VideoPlayerComponent;
    'video-player': typeof VideoPlayerComponent;
  }
}
