/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * StatementState represents the current state of this statement.
 *
 *  - STATEMENT_STATE_UNSET: STATEMENT_STATE_UNSET means the current state of the statement is not set or not specified.
 *  - STATEMENT_STATE_RUNNING: STATEMENT_STATE_RUNNING is for the latest current billing period statement.
 * Only one running statement per billing period per billing account at a time.
 *  - STATEMENT_STATE_PAID: STATEMENT_STATE_PAID for FCP is when a statement is recognized.
 * Paid for PAYG is when stripe invoice is paid.
 *  - STATEMENT_STATE_SUBMITTED: STATEMENT_STATE_SUBMITTED means the statement was sent for collection and can no longer
 * be changed. It's yet to be paid.
 *  - STATEMENT_STATE_WONTPAY: STATEMENT_STATE_WONTPAY means that attempts to collect money for the payment were exhausted.
 * This also means the account could enter the delinquency flow because of this.
 *  - STATEMENT_STATE_VOID: STATEMENT_STATE_VOID means that this statement must be ignored as it is not valid anymore.
 * @export
 * @enum {string}
 */
export var Billing20201105StatementState;
(function (Billing20201105StatementState) {
    Billing20201105StatementState["UNSET"] = "STATEMENT_STATE_UNSET";
    Billing20201105StatementState["RUNNING"] = "STATEMENT_STATE_RUNNING";
    Billing20201105StatementState["PAID"] = "STATEMENT_STATE_PAID";
    Billing20201105StatementState["SUBMITTED"] = "STATEMENT_STATE_SUBMITTED";
    Billing20201105StatementState["WONTPAY"] = "STATEMENT_STATE_WONTPAY";
    Billing20201105StatementState["VOID"] = "STATEMENT_STATE_VOID";
})(Billing20201105StatementState || (Billing20201105StatementState = {}));
export function Billing20201105StatementStateFromJSON(json) {
    return Billing20201105StatementStateFromJSONTyped(json, false);
}
export function Billing20201105StatementStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Billing20201105StatementStateToJSON(value) {
    return value;
}
