import templateOnlyComponent from '@ember/component/template-only';

interface ToolbarActionsSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

const ToolbarActionsComponent =
  templateOnlyComponent<ToolbarActionsSignature>();

export default ToolbarActionsComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Toolbar::Actions': typeof ToolbarActionsComponent;
    'toolbar/actions': typeof ToolbarActionsComponent;
  }
}
