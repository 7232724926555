import { HashicorpCloudIamPrincipalType } from '@clients/cloud-iam';

import iamFindResourceNamePart from './iam-find-resource-name-part.ts';
import { ParseResourceName } from 'core/utils/resource-name';

import type { HashicorpCloudResourcemanagerProject } from '@clients/cloud-resource-manager';
import type { HashicorpCloudIamPrincipal } from '@clients/cloud-iam';
import type { ParsedResourceName } from '../types/resource-name.ts';

export interface Options {
  project?: HashicorpCloudResourcemanagerProject;
}

export default function iamIsPrincipalCrossProject(
  principal: HashicorpCloudIamPrincipal,
  options: Options,
): boolean {
  if (!principal) return false;
  if (!options?.project) return false;

  let resourceName;

  switch (principal.type) {
    case HashicorpCloudIamPrincipalType.SERVICE:
      resourceName = principal.service?.resourceName;
      break;
    case HashicorpCloudIamPrincipalType.USER:
      return false;
    case HashicorpCloudIamPrincipalType.GROUP:
      return false;
  }

  if (!resourceName) {
    return false;
  }

  const parsedResourceName = ParseResourceName(
    resourceName,
  ) as ParsedResourceName;

  const projectPart = iamFindResourceNamePart(parsedResourceName, {
    part: 'project',
  });

  if (!projectPart) {
    return false;
  }

  return options.project?.id !== projectPart.id;
}
