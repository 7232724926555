import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';

interface ChartsStackedBarSignature {
  Args: {
    segments: {
      value: number;
      color: string | ((segment: { value: number }) => string);
      text?: string | ((segment: { value: number }) => string);
    }[];
    max?: number;
    subtitle?: string;
    title?: string;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLDivElement | HTMLElement;
}

// TODO: enable when this is fixed: https://github.com/thgh/rollup-plugin-scss/issues/70
// import './index.scss';

export default class ChartsStackedBarComponent extends Component<ChartsStackedBarSignature> {
  uuid = guidFor(this);

  /**
   * A title for the chart, if omitted the section is not rendered
   * @argument title
   * @type {?string}
   */

  /**
   * A subtitle for the chart, if omitted the section is not rendered
   * Rendered as Helptext
   * @argument subtitle
   * @type {?string}
   */

  /**
   * the value (0-100 intended as a percentage) that bar will display
   * For more information see
   *
   * ```
   * type ColorFunction = (segment: { value: number }) => string;
   * type TextFunction = (segment: { value: number }) => string;
   *
   * interface Segment {
   *   value: number,
   *   color: string | ColorFunction, // can be any svg supported color string (rgb, hex, etc)
   *   text?: string | TextFunction,
   * }
   * ```
   * @argument segments
   * @type {Segment[]}
   */

  /**
   * Int representing the max value shown on the chart
   * @argument max
   * @type {?number}
   */

  /**
   * Guard around segments
   * @method StackedBar#segments
   * @return {Segment[]}
   */
  get segments() {
    return this.args.segments.filter(({ value }) => value > 0) || [];
  }

  /**
   * Guard around max, handles the case where there is no max
   * @method StackedBar#max
   * @return {number}
   */
  get max() {
    return this.args.max || this.total;
  }

  /**
   * Calculates the total of all values in the chart
   * @method StackedBar#total
   * @return {number}
   */
  get total() {
    return this.segments.reduce((total, { value }) => {
      total += value;
      return total;
    }, 0);
  }
  /**
   * Single calculation for whether the total is over the max
   * @method StackedBar#isOverMax
   * @return {boolean}
   */
  get isOverMax() {
    return this.total > this.max;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Charts::StackedBar': typeof ChartsStackedBarComponent;
    'charts/stacked-bar': typeof ChartsStackedBarComponent;
  }
}
