/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CloudiamPrincipalFromJSON, CloudiamPrincipalToJSON, LogService20210330ServicePrincipalDelegationInfoFromJSON, LogService20210330ServicePrincipalDelegationInfoToJSON, } from './';
export function LogService20210330AuthenticationInfoFromJSON(json) {
    return LogService20210330AuthenticationInfoFromJSONTyped(json, false);
}
export function LogService20210330AuthenticationInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'principal': !exists(json, 'principal') ? undefined : CloudiamPrincipalFromJSON(json['principal']),
        'servicePrincipalDelegationChain': !exists(json, 'service_principal_delegation_chain') ? undefined : (json['service_principal_delegation_chain'].map(LogService20210330ServicePrincipalDelegationInfoFromJSON)),
    };
}
export function LogService20210330AuthenticationInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'principal': CloudiamPrincipalToJSON(value.principal),
        'service_principal_delegation_chain': value.servicePrincipalDelegationChain === undefined ? undefined : (value.servicePrincipalDelegationChain.map(LogService20210330ServicePrincipalDelegationInfoToJSON)),
    };
}
