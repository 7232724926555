import Component from '@glimmer/component';

interface CoreProgressBarSignature {
  Args: {
    color: 'action' | 'critical' | 'warning' | 'success';
    max?: number | string;
    value?: number;
  };
  Element: HTMLDivElement;
}

export default class CoreProgressBarComponent extends Component<CoreProgressBarSignature> {
  /**
   * Changes the color of the progress bar.
   * @argument color
   * @type {("action"|"critical"|"warning"|"success")}
   */

  /**
   * Changes the max attribute for progress bar.
   * @argument max
   * @type {?number}
   */

  /**
   * Changes the value attribute for progress bar.
   * @argument value
   * @type {?number}
   */

  get colorClass() {
    const { color = 'action' } = this.args;

    switch (color) {
      case 'action':
      case 'critical':
      case 'warning':
      case 'success':
        return `core-progress-bar-meter--${color}`;
      default:
        return `core-progress-bar-meter--action`;
    }
  }

  get max() {
    const { max } = this.args;

    if (isNaN(parseFloat(max as string)) || (max as number) < 0) {
      return 100;
    }

    return parseFloat(max as string);
  }

  get value() {
    const { value = 0 } = this.args;

    if (isNaN(value)) {
      return 0;
    }

    return value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::ProgressBar': typeof CoreProgressBarComponent;
    'core/progress-bar': typeof CoreProgressBarComponent;
  }
}
