/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Contacts Service
 * API for managing Contact information.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudEmailContactFromJSON, HashicorpCloudEmailContactToJSON } from './';
export function HashicorpCloudEmailListContactsResponseFromJSON(json) {
  return HashicorpCloudEmailListContactsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudEmailListContactsResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    contacts: !exists(json, 'contacts')
      ? undefined
      : json['contacts'].map(HashicorpCloudEmailContactFromJSON),
  };
}
export function HashicorpCloudEmailListContactsResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    contacts: value.contacts === undefined ? undefined : value.contacts.map(HashicorpCloudEmailContactToJSON),
  };
}
