/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudConsulTelemetry20230414ValueFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414ValueFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414ValueFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'time': !exists(json, 'time') ? undefined : (new Date(json['time'])),
        'sampleValue': !exists(json, 'sample_value') ? undefined : json['sample_value'],
    };
}
export function HashicorpCloudConsulTelemetry20230414ValueToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'time': value.time === undefined ? undefined : (value.time.toISOString()),
        'sample_value': value.sampleValue,
    };
}
