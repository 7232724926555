/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330DestinationSourceFromJSON, LogService20210330DestinationSourceToJSON, LogService20210330StreamingFilterFromJSON, LogService20210330StreamingFilterToJSON, } from './';
export function LogService20210330StreamingListDestinationFromJSON(json) {
    return LogService20210330StreamingListDestinationFromJSONTyped(json, false);
}
export function LogService20210330StreamingListDestinationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'filter': !exists(json, 'filter') ? undefined : LogService20210330StreamingFilterFromJSON(json['filter']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'lastErrorMessage': !exists(json, 'last_error_message') ? undefined : json['last_error_message'],
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
        'source': !exists(json, 'source') ? undefined : LogService20210330DestinationSourceFromJSON(json['source']),
        'providerType': !exists(json, 'provider_type') ? undefined : json['provider_type'],
    };
}
export function LogService20210330StreamingListDestinationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'id': value.id,
        'name': value.name,
        'filter': LogService20210330StreamingFilterToJSON(value.filter),
        'status': value.status,
        'last_error_message': value.lastErrorMessage,
        'endpoint': value.endpoint,
        'source': LogService20210330DestinationSourceToJSON(value.source),
        'provider_type': value.providerType,
    };
}
