/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudResourcemanagerRoleFromJSON(json) {
    return HashicorpCloudResourcemanagerRoleFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerRoleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
        'lowestApplicableResourceTypes': !exists(json, 'lowest_applicable_resource_types') ? undefined : json['lowest_applicable_resource_types'],
    };
}
export function HashicorpCloudResourcemanagerRoleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'version': value.version,
        'title': value.title,
        'description': value.description,
        'permissions': value.permissions,
        'lowest_applicable_resource_types': value.lowestApplicableResourceTypes,
    };
}
