/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudIamGetPreconfigurationDataResponseFromJSON, HashicorpCloudIamGetSSOConfigurationResponseFromJSON, HashicorpCloudIamGetSSOTypeResponseFromJSON, HashicorpCloudIamListSSOConfigurationsResponseFromJSON, HashicorpCloudIamVerifyDomainOwnershipResponseFromJSON, InlineObject12ToJSON, InlineObject13ToJSON, InlineObject14ToJSON, InlineObject15ToJSON, } from '../models';
/**
 *
 */
export class SSOManagementServiceApi extends runtime.BaseAPI {
    /**
     * CreateSSOConfiguration creates a new SSO configuration for an organization.
     */
    async sSOManagementServiceCreateSSOConfigurationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling sSOManagementServiceCreateSSOConfiguration.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling sSOManagementServiceCreateSSOConfiguration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/sso-configurations`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject12ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * CreateSSOConfiguration creates a new SSO configuration for an organization.
     */
    async sSOManagementServiceCreateSSOConfiguration(organizationId, body) {
        const response = await this.sSOManagementServiceCreateSSOConfigurationRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * DeleteSSOConfiguration deletes an existing SSO configuration.
     */
    async sSOManagementServiceDeleteSSOConfigurationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling sSOManagementServiceDeleteSSOConfiguration.');
        }
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling sSOManagementServiceDeleteSSOConfiguration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/sso-configurations/{type}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteSSOConfiguration deletes an existing SSO configuration.
     */
    async sSOManagementServiceDeleteSSOConfiguration(organizationId, type) {
        const response = await this.sSOManagementServiceDeleteSSOConfigurationRaw({ organizationId: organizationId, type: type });
        return await response.value();
    }
    /**
     * GetPreconfigurationData returns data needed prior to an SSO configuration being created.
     */
    async sSOManagementServiceGetPreconfigurationDataRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling sSOManagementServiceGetPreconfigurationData.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/sso-preconfiguration-data`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetPreconfigurationDataResponseFromJSON(jsonValue));
    }
    /**
     * GetPreconfigurationData returns data needed prior to an SSO configuration being created.
     */
    async sSOManagementServiceGetPreconfigurationData(organizationId) {
        const response = await this.sSOManagementServiceGetPreconfigurationDataRaw({ organizationId: organizationId });
        return await response.value();
    }
    /**
     * GetSSOConfiguration retrieves a specific SSO configuration for an organization.
     */
    async sSOManagementServiceGetSSOConfigurationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling sSOManagementServiceGetSSOConfiguration.');
        }
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling sSOManagementServiceGetSSOConfiguration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/sso-configurations/{type}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetSSOConfigurationResponseFromJSON(jsonValue));
    }
    /**
     * GetSSOConfiguration retrieves a specific SSO configuration for an organization.
     */
    async sSOManagementServiceGetSSOConfiguration(organizationId, type) {
        const response = await this.sSOManagementServiceGetSSOConfigurationRaw({ organizationId: organizationId, type: type });
        return await response.value();
    }
    /**
     * GetSSOType returns the type of SSO that is configured for an organization.
     */
    async sSOManagementServiceGetSSOTypeRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling sSOManagementServiceGetSSOType.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/sso-type`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetSSOTypeResponseFromJSON(jsonValue));
    }
    /**
     * GetSSOType returns the type of SSO that is configured for an organization.
     */
    async sSOManagementServiceGetSSOType(organizationId) {
        const response = await this.sSOManagementServiceGetSSOTypeRaw({ organizationId: organizationId });
        return await response.value();
    }
    /**
     * ListSSOConfigurations retrieves the SSO configurations for an organization.
     */
    async sSOManagementServiceListSSOConfigurationsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling sSOManagementServiceListSSOConfigurations.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/sso-configurations`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamListSSOConfigurationsResponseFromJSON(jsonValue));
    }
    /**
     * ListSSOConfigurations retrieves the SSO configurations for an organization.
     */
    async sSOManagementServiceListSSOConfigurations(organizationId) {
        const response = await this.sSOManagementServiceListSSOConfigurationsRaw({ organizationId: organizationId });
        return await response.value();
    }
    /**
     * UpdateSSOConfiguration updates an existing SSO configuration.
     */
    async sSOManagementServiceUpdateSSOConfigurationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling sSOManagementServiceUpdateSSOConfiguration.');
        }
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling sSOManagementServiceUpdateSSOConfiguration.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling sSOManagementServiceUpdateSSOConfiguration.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/sso-configurations/{type}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject13ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * UpdateSSOConfiguration updates an existing SSO configuration.
     */
    async sSOManagementServiceUpdateSSOConfiguration(organizationId, type, body) {
        const response = await this.sSOManagementServiceUpdateSSOConfigurationRaw({ organizationId: organizationId, type: type, body: body });
        return await response.value();
    }
    /**
     * It is intended to be used to provide early feedback to the user; we check ownership \"for real\" in CreateSSOConfiguration and UpdateSSOConfiguration.
     * VerifyDomainOwnership checks whether the organization has proven their ownership/control of the given domain by adding the required TXT record.
     */
    async sSOManagementServiceVerifyDomainOwnershipRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling sSOManagementServiceVerifyDomainOwnership.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling sSOManagementServiceVerifyDomainOwnership.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/verify-domain-ownership`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject14ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamVerifyDomainOwnershipResponseFromJSON(jsonValue));
    }
    /**
     * It is intended to be used to provide early feedback to the user; we check ownership \"for real\" in CreateSSOConfiguration and UpdateSSOConfiguration.
     * VerifyDomainOwnership checks whether the organization has proven their ownership/control of the given domain by adding the required TXT record.
     */
    async sSOManagementServiceVerifyDomainOwnership(organizationId, body) {
        const response = await this.sSOManagementServiceVerifyDomainOwnershipRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * This endpoint differs from the one above because it is intended to be used for HashiCorp SSO domain ownership verification and not Auth0 SAML SSO. It is intended to be used to provide early feedback to the user; we check domain ownership in cloud-idp.
     * VerifySSODomainOwnership checks whether the organization has proven their ownership/control of the given domain by adding the required TXT record.
     */
    async sSOManagementServiceVerifySSODomainOwnershipRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling sSOManagementServiceVerifySSODomainOwnership.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling sSOManagementServiceVerifySSODomainOwnership.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/verify-sso-domain-ownership`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject15ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamVerifyDomainOwnershipResponseFromJSON(jsonValue));
    }
    /**
     * This endpoint differs from the one above because it is intended to be used for HashiCorp SSO domain ownership verification and not Auth0 SAML SSO. It is intended to be used to provide early feedback to the user; we check domain ownership in cloud-idp.
     * VerifySSODomainOwnership checks whether the organization has proven their ownership/control of the given domain by adding the required TXT record.
     */
    async sSOManagementServiceVerifySSODomainOwnership(organizationId, body) {
        const response = await this.sSOManagementServiceVerifySSODomainOwnershipRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var SSOManagementServiceDeleteSSOConfigurationTypeEnum;
(function (SSOManagementServiceDeleteSSOConfigurationTypeEnum) {
    SSOManagementServiceDeleteSSOConfigurationTypeEnum["UNSET"] = "UNSET";
    SSOManagementServiceDeleteSSOConfigurationTypeEnum["SAML"] = "SAML";
    SSOManagementServiceDeleteSSOConfigurationTypeEnum["OIDC"] = "OIDC";
})(SSOManagementServiceDeleteSSOConfigurationTypeEnum || (SSOManagementServiceDeleteSSOConfigurationTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SSOManagementServiceGetSSOConfigurationTypeEnum;
(function (SSOManagementServiceGetSSOConfigurationTypeEnum) {
    SSOManagementServiceGetSSOConfigurationTypeEnum["UNSET"] = "UNSET";
    SSOManagementServiceGetSSOConfigurationTypeEnum["SAML"] = "SAML";
    SSOManagementServiceGetSSOConfigurationTypeEnum["OIDC"] = "OIDC";
})(SSOManagementServiceGetSSOConfigurationTypeEnum || (SSOManagementServiceGetSSOConfigurationTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SSOManagementServiceUpdateSSOConfigurationTypeEnum;
(function (SSOManagementServiceUpdateSSOConfigurationTypeEnum) {
    SSOManagementServiceUpdateSSOConfigurationTypeEnum["UNSET"] = "UNSET";
    SSOManagementServiceUpdateSSOConfigurationTypeEnum["SAML"] = "SAML";
    SSOManagementServiceUpdateSSOConfigurationTypeEnum["OIDC"] = "OIDC";
})(SSOManagementServiceUpdateSSOConfigurationTypeEnum || (SSOManagementServiceUpdateSSOConfigurationTypeEnum = {}));
