import Component from '@glimmer/component';
import { DEBUG } from '@glimmer/env';
import { assert } from '@ember/debug';

interface HvnSelectRemoveSignature {
  Args: {
    ids: string[];
    removeNetworkOptions: () => void;
  };
}

/**
 * Renderless logical component which triggers parent to remove Network objects with matching IDs from it's network options data model.
 *
 * **NOTE:** _This component is NOT meant to be used independently, rather yielded back from a parent `<HvnSelect>` component._
 *
 * Example usage:
 *
 * ```
 * <FormInputs::HvnSelect
 *  @networks={{networks}}
 *  @selectedNetworkId={{selectedNetworkId}}
 *  @onChange={{onChange}}
 *  as |Hvns|
 * >
 *   <Hvns.Remove @ids={{array 'dont-show-this-network'}} />
 * </FormInputs::HvnSelect
 * ```
 *
 *
 * @see {FormInputsHvnSelect}
 * @class FormInputsHvnSelectRemove
 *
 */

export default class FormInputsHvnSelectRemoveComponent extends Component<HvnSelectRemoveSignature> {
  /**
   * @argument removeNetworkOptions
   * @type {Function(Array.<String>)}
   */
  /**
   * @argument ids
   * @type {Array.<String>}
   */

  constructor(owner: unknown, args: HvnSelectRemoveSignature['Args']) {
    super(owner, args);

    const { removeNetworkOptions, ids } = this.args;

    if (DEBUG) {
      assert(
        '<HvnSelect.Remove> Argument @removeNetworkOptions is expected to be a Function (passed down from parent <HvnSelect> component)',
        typeof removeNetworkOptions === 'function'
      );

      assert(
        '<HvnSelect.Remove> Argument @ids is expected to be an Array of String Network IDs',
        Array.isArray(ids)
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormInputs::HvnSelect::Remove': typeof FormInputsHvnSelectRemoveComponent;
    'form-inputs/hvn-select/remove': typeof FormInputsHvnSelectRemoveComponent;
  }
}
