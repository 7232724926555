/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Status represents the status of a given contract based on its active from and until dates.
 * @export
 * @enum {string}
 */
export var Billing20201105ContractStatus;
(function (Billing20201105ContractStatus) {
    Billing20201105ContractStatus["UNSET"] = "UNSET";
    Billing20201105ContractStatus["ACTIVE"] = "ACTIVE";
    Billing20201105ContractStatus["UPCOMING"] = "UPCOMING";
    Billing20201105ContractStatus["EXPIRED"] = "EXPIRED";
})(Billing20201105ContractStatus || (Billing20201105ContractStatus = {}));
export function Billing20201105ContractStatusFromJSON(json) {
    return Billing20201105ContractStatusFromJSONTyped(json, false);
}
export function Billing20201105ContractStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Billing20201105ContractStatusToJSON(value) {
    return value;
}
