/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Operations
 * API for managing HCP operations.
 *
 * The version of the OpenAPI document: 2020-05-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudLocationRegionFromJSON(json) {
  return HashicorpCloudLocationRegionFromJSONTyped(json, false);
}
export function HashicorpCloudLocationRegionFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    provider: !exists(json, 'provider') ? undefined : json['provider'],
    region: !exists(json, 'region') ? undefined : json['region'],
  };
}
export function HashicorpCloudLocationRegionToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    provider: value.provider,
    region: value.region,
  };
}
