/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128GatewayStatusFromJSON, Secrets20231128GatewayStatusToJSON, } from './';
export function Secrets20231128GatewayFromJSON(json) {
    return Secrets20231128GatewayFromJSONTyped(json, false);
}
export function Secrets20231128GatewayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'os': !exists(json, 'os') ? undefined : json['os'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'status': !exists(json, 'status') ? undefined : Secrets20231128GatewayStatusFromJSON(json['status']),
        'statusMessage': !exists(json, 'status_message') ? undefined : json['status_message'],
        'hostname': !exists(json, 'hostname') ? undefined : json['hostname'],
    };
}
export function Secrets20231128GatewayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'os': value.os,
        'version': value.version,
        'metadata': value.metadata,
        'status': Secrets20231128GatewayStatusToJSON(value.status),
        'status_message': value.statusMessage,
        'hostname': value.hostname,
    };
}
