/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125CloudWatchFromJSON, HashicorpCloudVault20201125CloudWatchToJSON, HashicorpCloudVault20201125DatadogFromJSON, HashicorpCloudVault20201125DatadogToJSON, HashicorpCloudVault20201125ElasticsearchFromJSON, HashicorpCloudVault20201125ElasticsearchToJSON, HashicorpCloudVault20201125GrafanaFromJSON, HashicorpCloudVault20201125GrafanaToJSON, HashicorpCloudVault20201125HTTPFromJSON, HashicorpCloudVault20201125HTTPToJSON, HashicorpCloudVault20201125NewRelicFromJSON, HashicorpCloudVault20201125NewRelicToJSON, HashicorpCloudVault20201125SplunkFromJSON, HashicorpCloudVault20201125SplunkToJSON, } from './';
export function HashicorpCloudVault20201125ObservabilityConfigFromJSON(json) {
    return HashicorpCloudVault20201125ObservabilityConfigFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125ObservabilityConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'grafana': !exists(json, 'grafana') ? undefined : HashicorpCloudVault20201125GrafanaFromJSON(json['grafana']),
        'splunk': !exists(json, 'splunk') ? undefined : HashicorpCloudVault20201125SplunkFromJSON(json['splunk']),
        'datadog': !exists(json, 'datadog') ? undefined : HashicorpCloudVault20201125DatadogFromJSON(json['datadog']),
        'cloudwatch': !exists(json, 'cloudwatch') ? undefined : HashicorpCloudVault20201125CloudWatchFromJSON(json['cloudwatch']),
        'elasticsearch': !exists(json, 'elasticsearch') ? undefined : HashicorpCloudVault20201125ElasticsearchFromJSON(json['elasticsearch']),
        'newrelic': !exists(json, 'newrelic') ? undefined : HashicorpCloudVault20201125NewRelicFromJSON(json['newrelic']),
        'http': !exists(json, 'http') ? undefined : HashicorpCloudVault20201125HTTPFromJSON(json['http']),
    };
}
export function HashicorpCloudVault20201125ObservabilityConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'grafana': HashicorpCloudVault20201125GrafanaToJSON(value.grafana),
        'splunk': HashicorpCloudVault20201125SplunkToJSON(value.splunk),
        'datadog': HashicorpCloudVault20201125DatadogToJSON(value.datadog),
        'cloudwatch': HashicorpCloudVault20201125CloudWatchToJSON(value.cloudwatch),
        'elasticsearch': HashicorpCloudVault20201125ElasticsearchToJSON(value.elasticsearch),
        'newrelic': HashicorpCloudVault20201125NewRelicToJSON(value.newrelic),
        'http': HashicorpCloudVault20201125HTTPToJSON(value.http),
    };
}
