import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import type BreadcrumbsService from '@bagaar/ember-breadcrumbs/services/breadcrumbs';

interface BreadcrumbsItemSignature {
  Args: {
    icon?: string;
    model?: unknown;
    models?: unknown;
    query?: unknown;
    route: string;
    text: string;
  };
  Blocks: {
    extra: [];
  };
  Element: HTMLElement;
}

/**
 * `Breadcrumbs::Item` renders an individual breadcrumb which gets portal'd to
 * the `Breadcrumbs::Container`. Each `Item` passes through `route` and `text`
 * to an underlying HDS component. A built-in `LinkTo` will be rendered
 * in the context it needs to link to and will be able to infer the implicit
 * models of each route that are required. This means that only `route` and
 * `text` should be necessary arguments. We don't render the HDS component as
 * a child here because we want to control the api of that component in a single
 * place.
 *
 * ```
 * <Breadcrumbs::Item
 *  @route='my.route.name'
 *  @text={{t 'my.translation.string'}}
 * />
 * ```
 *
 * @class BreadcrumbsItemComponent
 *
 */

/**
 * `route` is the dot-notation path of the route for the breadcrumb. This
 * argument is equivalent to the `route` arg in `Hds::Breadcrumb::Item`.
 *
 * @argument route;
 * @type {String}
 * @see https://design-system-components-hashicorp.vercel.app/components/breadcrumb#how-to-use
 *
 */

/**
 * `text` is the rendered string that is displayed for the breadcrumb. This
 * argument is equivalent to the `text` arg in `Hds::Breadcrumb::Item`.
 *
 * @argument text;
 * @type {String}
 * @see https://design-system-components-hashicorp.vercel.app/components/breadcrumb#how-to-use
 *
 */
export default class BreadcrumbsItemComponent extends Component<BreadcrumbsItemSignature> {
  @service('breadcrumbs')
  declare readonly breadcrumbsService: BreadcrumbsService;

  // @ts-expect-error
  @tracked element = null;

  @action
  registerSelf(element: HTMLElement) {
    // @ts-expect-error
    this.element = element;
  }

  get current() {
    const crumbs = this.breadcrumbsService?.containers[0]?.element.children;
    const lastCrumb = crumbs?.[crumbs.length - 1];
    return lastCrumb?.isEqualNode(this.element);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Breadcrumbs::Item': typeof BreadcrumbsItemComponent;
    'breadcrumbs/item': typeof BreadcrumbsItemComponent;
  }
}
