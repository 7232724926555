import Component from '@glimmer/component';

interface ChartsPercentageBarChartSignature {
  Args: {
    state: 'ok' | 'warning' | 'danger';
    value: number;
  };
  Element: SVGElement;
}

export default class ChartsPercentageBarChartComponent extends Component<ChartsPercentageBarChartSignature> {
  // height of the SVG element aka the "chart" area
  height = 12;

  // Arguments
  /**
   * Current value the chart is representing
   * @argument value
   * @type {number}
   *
   */

  /**
   * used to determine the color of the filledBar (done through css class)
   * @argument state
   * @type {enum{'ok'|'warning'|'danger'}}
   *
   */

  /**
   * Given a value and the size of the chart area, calculates the appropriate width of the filled bar
   * @method PercentageBar::Chart#barWidth
   * @return {number}
   */
  get barWidth() {
    const { value } = this.args;

    //if no % value then return the height to make a circle
    if (value <= 0 || !value) return this.height;

    return value.toString() + '%';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Charts::PercentageBar::Chart': typeof ChartsPercentageBarChartComponent;
    'charts/percentage-bar/chart': typeof ChartsPercentageBarChartComponent;
  }
}
