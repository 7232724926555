import Component from '@glimmer/component';

interface CoreCardSummaryHeaderSignature {
  // Args: {};
  Blocks: {
    default: [unknown];
  };
}

/**
 *
 * `CoreCardSummaryKeyComponent` provides a container to display a key in a key/value pair.
 *
 *
 * ```
 * <Core::Card::Summary::Key>
 *   Some Key
 * </Core::Card::Summary::Key>
 * ```
 *
 * @class CoreCardSummaryKeyComponent
 *
 */

export default class CoreCardSummaryHeaderComponent extends Component<CoreCardSummaryHeaderSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::Card::Summary::Header': typeof CoreCardSummaryHeaderComponent;
    'core/card/summary/header': typeof CoreCardSummaryHeaderComponent;
  }
}
