import { tracked } from 'tracked-built-ins';

import type {
  PermissionConstructorParams,
  IPermission,
} from '../types/permissions.ts';

export default class Permission implements IPermission {
  @tracked service;
  @tracked roleIds;

  constructor({ service, roleIds }: PermissionConstructorParams) {
    this.service = service;
    this.roleIds = roleIds;
  }
}
