/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudGlobalNetworkManager20220215ServiceInstanceFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ServiceInstanceFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ServiceInstanceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'clusterResourceName': !exists(json, 'cluster_resource_name') ? undefined : json['cluster_resource_name'],
        'clusterResourceId': !exists(json, 'cluster_resource_id') ? undefined : json['cluster_resource_id'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'partition': !exists(json, 'partition') ? undefined : json['partition'],
        'node': !exists(json, 'node') ? undefined : json['node'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'connectedWithProxy': !exists(json, 'connected_with_proxy') ? undefined : json['connected_with_proxy'],
        'externalSource': !exists(json, 'external_source') ? undefined : json['external_source'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'serviceChecksCritical': !exists(json, 'service_checks_critical') ? undefined : json['service_checks_critical'],
        'serviceChecksWarning': !exists(json, 'service_checks_warning') ? undefined : json['service_checks_warning'],
        'serviceChecksPassing': !exists(json, 'service_checks_passing') ? undefined : json['service_checks_passing'],
        'proxyChecksCritical': !exists(json, 'proxy_checks_critical') ? undefined : json['proxy_checks_critical'],
        'proxyChecksWarning': !exists(json, 'proxy_checks_warning') ? undefined : json['proxy_checks_warning'],
        'proxyChecksPassing': !exists(json, 'proxy_checks_passing') ? undefined : json['proxy_checks_passing'],
        'nodeChecksCritical': !exists(json, 'node_checks_critical') ? undefined : json['node_checks_critical'],
        'nodeChecksWarning': !exists(json, 'node_checks_warning') ? undefined : json['node_checks_warning'],
        'nodeChecksPassing': !exists(json, 'node_checks_passing') ? undefined : json['node_checks_passing'],
        'kind': !exists(json, 'kind') ? undefined : json['kind'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'meta': !exists(json, 'meta') ? undefined : json['meta'],
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ServiceInstanceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'id': value.id,
        'cluster_id': value.clusterId,
        'cluster_resource_name': value.clusterResourceName,
        'cluster_resource_id': value.clusterResourceId,
        'namespace': value.namespace,
        'partition': value.partition,
        'node': value.node,
        'address': value.address,
        'port': value.port,
        'connected_with_proxy': value.connectedWithProxy,
        'external_source': value.externalSource,
        'status': value.status,
        'service_checks_critical': value.serviceChecksCritical,
        'service_checks_warning': value.serviceChecksWarning,
        'service_checks_passing': value.serviceChecksPassing,
        'proxy_checks_critical': value.proxyChecksCritical,
        'proxy_checks_warning': value.proxyChecksWarning,
        'proxy_checks_passing': value.proxyChecksPassing,
        'node_checks_critical': value.nodeChecksCritical,
        'node_checks_warning': value.nodeChecksWarning,
        'node_checks_passing': value.nodeChecksPassing,
        'kind': value.kind,
        'tags': value.tags,
        'meta': value.meta,
    };
}
