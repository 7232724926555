/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ForceSyncRequestOPERATIONFromJSON, ForceSyncRequestOPERATIONToJSON, Secrets20230613OrganizationsLocationOrganizationIdProjectsLocationProjectIdAppsLocationFromJSON, Secrets20230613OrganizationsLocationOrganizationIdProjectsLocationProjectIdAppsLocationToJSON, } from './';
export function InlineObject5FromJSON(json) {
    return InlineObject5FromJSONTyped(json, false);
}
export function InlineObject5FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : Secrets20230613OrganizationsLocationOrganizationIdProjectsLocationProjectIdAppsLocationFromJSON(json['location']),
        'appName': !exists(json, 'app_name') ? undefined : json['app_name'],
        'syncIntegrationName': !exists(json, 'sync_integration_name') ? undefined : json['sync_integration_name'],
        'operation': !exists(json, 'operation') ? undefined : ForceSyncRequestOPERATIONFromJSON(json['operation']),
    };
}
export function InlineObject5ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': Secrets20230613OrganizationsLocationOrganizationIdProjectsLocationProjectIdAppsLocationToJSON(value.location),
        'app_name': value.appName,
        'sync_integration_name': value.syncIntegrationName,
        'operation': ForceSyncRequestOPERATIONToJSON(value.operation),
    };
}
