/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CardDetailsBrandFromJSON, CardDetailsBrandToJSON, } from './';
export function Billing20201105CardDetailsFromJSON(json) {
    return Billing20201105CardDetailsFromJSONTyped(json, false);
}
export function Billing20201105CardDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'brand': !exists(json, 'brand') ? undefined : CardDetailsBrandFromJSON(json['brand']),
        'last4': !exists(json, 'last4') ? undefined : json['last4'],
        'expMonth': !exists(json, 'exp_month') ? undefined : json['exp_month'],
        'expYear': !exists(json, 'exp_year') ? undefined : json['exp_year'],
    };
}
export function Billing20201105CardDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'brand': CardDetailsBrandToJSON(value.brand),
        'last4': value.last4,
        'exp_month': value.expMonth,
        'exp_year': value.expYear,
    };
}
