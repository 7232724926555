// Roles can be found in `cloud-iam` swagger definition for hashicorp.cloud.iam.NewOrganizationInvitation
// https://github.com/hashicorp/cloud-iam/commit/606cc8c2ca49bdef9987538fe6527b40dd1124a5

import {
  TYPE_ORGANIZATION,
  TYPE_PROJECT,
} from 'common/utils/cloud-resource-types';

export const ROLE_KEY_ADMIN = 'roles/admin';
export const ROLE_KEY_CONTRIBUTOR = 'roles/contributor';
export const ROLE_KEY_NO_ROLE_UI_ONLY = 'roles/no-role';
export const ROLE_KEY_OWNER = 'roles/owner';
export const ROLE_KEY_BROWSER = 'roles/resource-manager.browser';
export const ROLE_KEY_VIEWER = 'roles/viewer';
export const ROLE_KEY_SECRETS_READER = 'roles/secrets.app-secret-reader';
export const ROLE_KEY_SECRETS_MANAGER = 'roles/secrets.app-manager';
export const ROLE_KEY_INTEGRATION_READER = 'roles/secrets.integration-reader';
export const ROLE_KEY_INTEGRATION_MANAGER = 'roles/secrets.integration-manager';

// Project roles
export const ROLE_TYPE_PROJECT_NO_ROLE_UI_ONLY = {
  active: false,
  description: 'helpers.rbac-roles.roles.project-no-role.description',
  label: 'helpers.rbac-roles.roles.project-no-role.label',
  summary: [
    {
      icon: 'info',
      color: 'var(--token-color-foreground-highlight)',
      message: 'helpers.rbac-roles.summaries.inherits-organization-role',
    },
  ],
  type: TYPE_PROJECT,
  value: ROLE_KEY_NO_ROLE_UI_ONLY,
};

export const ROLE_TYPE_PROJECT_ADMIN = {
  active: true,
  description: 'helpers.rbac-roles.roles.project-admin.description',
  label: 'helpers.rbac-roles.roles.project-admin.label',
  summary: [
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message:
        'helpers.rbac-roles.summaries.has-access-all-resources-in-project',
    },
  ],
  type: TYPE_PROJECT,
  value: ROLE_KEY_ADMIN,
};

export const ROLE_TYPE_PROJECT_CONTRIBUTOR = {
  active: true,
  description: 'helpers.rbac-roles.roles.project-contributor.description',
  label: 'helpers.rbac-roles.roles.project-contributor.label',
  summary: [
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message: 'helpers.rbac-roles.summaries.can-create-and-manage-in-project',
    },
    {
      icon: 'minus-circle',
      color: 'var(--token-color-foreground-critical)',
      message: 'helpers.rbac-roles.summaries.cannot-edit-users-in-project',
    },
  ],
  type: TYPE_PROJECT,
  value: ROLE_KEY_CONTRIBUTOR,
};

export const ROLE_TYPE_PROJECT_BROWSER = {
  active: true,
  description: 'helpers.rbac-roles.roles.project-browser.description',
  label: 'helpers.rbac-roles.roles.project-browser.label',
  summary: [
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message:
        'helpers.rbac-roles.summaries.can-only-view-resources-in-project',
    },
    {
      icon: 'minus-circle',
      color: 'var(--token-color-foreground-critical)',
      message: 'helpers.rbac-roles.summaries.cannot-edit-resources-in-project',
    },
    {
      icon: 'minus-circle',
      color: 'var(--token-color-foreground-critical)',
      message: 'helpers.rbac-roles.summaries.cannot-edit-users-in-project',
    },
  ],
  type: TYPE_PROJECT,
  value: ROLE_KEY_BROWSER,
};

export const ROLE_TYPE_PROJECT_VIEWER = {
  active: true,
  description: 'helpers.rbac-roles.roles.project-viewer.description',
  label: 'helpers.rbac-roles.roles.project-viewer.label',
  summary: [
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message:
        'helpers.rbac-roles.summaries.can-only-view-resources-in-project',
    },
    {
      icon: 'minus-circle',
      color: 'var(--token-color-foreground-critical)',
      message: 'helpers.rbac-roles.summaries.cannot-edit-resources-in-project',
    },
    {
      icon: 'minus-circle',
      color: 'var(--token-color-foreground-critical)',
      message: 'helpers.rbac-roles.summaries.cannot-edit-users-in-project',
    },
  ],
  type: TYPE_PROJECT,
  value: ROLE_KEY_VIEWER,
};

// Organization roles
export const ROLE_TYPE_ADMIN = {
  active: true,
  description: 'helpers.rbac-roles.roles.admin.description',
  label: 'helpers.rbac-roles.roles.admin.label',
  summary: [
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message: 'helpers.rbac-roles.summaries.has-access-all-resources',
    },
  ],
  type: TYPE_ORGANIZATION,
  value: ROLE_KEY_ADMIN,
};

export const ROLE_TYPE_CONTRIBUTOR = {
  active: true,
  description: 'helpers.rbac-roles.roles.contributor.description',
  label: 'helpers.rbac-roles.roles.contributor.label',
  summary: [
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message: 'helpers.rbac-roles.summaries.can-create-and-manage',
    },
    {
      icon: 'minus-circle',
      color: 'var(--token-color-foreground-critical)',
      message: 'helpers.rbac-roles.summaries.cannot-invite-or-grant',
    },
  ],
  type: TYPE_ORGANIZATION,
  value: ROLE_KEY_CONTRIBUTOR,
};

export const ROLE_TYPE_OWNER = {
  active: false,
  description: 'helpers.rbac-roles.roles.owner.description',
  label: 'helpers.rbac-roles.roles.owner.label',
  summary: [
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message: 'helpers.rbac-roles.summaries.full-permissions',
    },
  ],
  type: TYPE_ORGANIZATION,
  value: ROLE_KEY_OWNER,
};

export const ROLE_TYPE_BROWSER = {
  active: true,
  description: 'helpers.rbac-roles.roles.browser.description',
  label: 'helpers.rbac-roles.roles.browser.label',
  summary: [
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message: 'helpers.rbac-roles.summaries.can-only-view',
    },
    {
      icon: 'minus-circle',
      color: 'var(--token-color-foreground-critical)',
      message: 'helpers.rbac-roles.summaries.cannot-edit',
    },
    {
      icon: 'minus-circle',
      color: 'var(--token-color-foreground-critical)',
      message: 'helpers.rbac-roles.summaries.cannot-invite-or-grant',
    },
  ],
  type: TYPE_ORGANIZATION,
  value: ROLE_KEY_BROWSER,
};

export const ROLE_TYPE_VIEWER = {
  active: true,
  description: 'helpers.rbac-roles.roles.viewer.description',
  label: 'helpers.rbac-roles.roles.viewer.label',
  summary: [
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message: 'helpers.rbac-roles.summaries.can-only-view',
    },
    {
      icon: 'minus-circle',
      color: 'var(--token-color-foreground-critical)',
      message: 'helpers.rbac-roles.summaries.cannot-edit',
    },
    {
      icon: 'minus-circle',
      color: 'var(--token-color-foreground-critical)',
      message: 'helpers.rbac-roles.summaries.cannot-invite-or-grant',
    },
  ],
  type: TYPE_ORGANIZATION,
  value: ROLE_KEY_VIEWER,
};

// Secrets roles
export const ROLE_TYPE_SECRETS_READER = {
  active: true,
  description: 'helpers.rbac-roles.roles.secrets.roles.reader.description',
  label: 'helpers.rbac-roles.roles.secrets.roles.reader.label',
  summary: [
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message: 'helpers.rbac-roles.roles.secrets.summaries.read-secrets',
    },
    {
      icon: 'minus-circle',
      color: 'var(--token-color-foreground-critical)',
      message:
        'helpers.rbac-roles.roles.secrets.summaries.cannot-create-edit-delete-secrets',
    },
    {
      icon: 'minus-circle',
      color: 'var(--token-color-foreground-critical)',
      message:
        'helpers.rbac-roles.roles.secrets.summaries.cannot-create-edit-delete-apps',
    },
  ],
  type: TYPE_PROJECT,
  value: ROLE_KEY_SECRETS_READER,
};

export const ROLE_TYPE_SECRETS_MANAGER = {
  active: true,
  description: 'helpers.rbac-roles.roles.secrets.roles.manager.description',
  label: 'helpers.rbac-roles.roles.secrets.roles.manager.label',
  summary: [
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message: 'helpers.rbac-roles.roles.secrets.summaries.read-secrets',
    },
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message:
        'helpers.rbac-roles.roles.secrets.summaries.create-edit-delete-secrets',
    },
    {
      icon: 'check-circle',
      color: 'var(--token-color-foreground-success)',
      message:
        'helpers.rbac-roles.roles.secrets.summaries.create-edit-delete-apps',
    },
  ],
  type: TYPE_PROJECT,
  value: ROLE_KEY_SECRETS_MANAGER,
};

export default [
  ROLE_TYPE_ADMIN,
  ROLE_TYPE_BROWSER,
  ROLE_TYPE_CONTRIBUTOR,
  ROLE_TYPE_OWNER,
  ROLE_TYPE_VIEWER,
  ROLE_TYPE_PROJECT_ADMIN,
  ROLE_TYPE_PROJECT_BROWSER,
  ROLE_TYPE_PROJECT_CONTRIBUTOR,
  ROLE_TYPE_PROJECT_NO_ROLE_UI_ONLY,
  ROLE_TYPE_PROJECT_VIEWER,
  ROLE_TYPE_SECRETS_READER,
  ROLE_TYPE_SECRETS_MANAGER,
];
