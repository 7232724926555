/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudNetwork20200907AWSNetworkDataFromJSON, HashicorpCloudNetwork20200907AWSNetworkDataToJSON, } from './';
export function HashicorpCloudNetwork20200907NetworkProviderNetworkDataFromJSON(json) {
    return HashicorpCloudNetwork20200907NetworkProviderNetworkDataFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907NetworkProviderNetworkDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'awsNetworkData': !exists(json, 'aws_network_data') ? undefined : HashicorpCloudNetwork20200907AWSNetworkDataFromJSON(json['aws_network_data']),
        'azureNetworkData': !exists(json, 'azure_network_data') ? undefined : json['azure_network_data'],
    };
}
export function HashicorpCloudNetwork20200907NetworkProviderNetworkDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aws_network_data': HashicorpCloudNetwork20200907AWSNetworkDataToJSON(value.awsNetworkData),
        'azure_network_data': value.azureNetworkData,
    };
}
