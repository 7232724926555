import Component from '@glimmer/component';

interface DefinitionListValueSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `DefinitionValue` provides a container to display a value in a key/value pair.
 *
 *
 * ```
 * <Definition::Value>
 *   Some Value
 * </Definition::Value>
 * ```
 *
 * @class DefinitionValue
 *
 */

export default class DefinitionListValueComponent extends Component<DefinitionListValueSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DefinitionList::Value': typeof DefinitionListValueComponent;
    'definition-list/value': typeof DefinitionListValueComponent;
  }
}
