/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128PostgresParamsFromJSON, Secrets20231128PostgresParamsToJSON, } from './';
export function SecretServiceUpdatePostgresRotatingSecretBodyFromJSON(json) {
    return SecretServiceUpdatePostgresRotatingSecretBodyFromJSONTyped(json, false);
}
export function SecretServiceUpdatePostgresRotatingSecretBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rotationPolicyName': !exists(json, 'rotation_policy_name') ? undefined : json['rotation_policy_name'],
        'postgresParams': !exists(json, 'postgres_params') ? undefined : Secrets20231128PostgresParamsFromJSON(json['postgres_params']),
        'rotateOnUpdate': !exists(json, 'rotate_on_update') ? undefined : json['rotate_on_update'],
    };
}
export function SecretServiceUpdatePostgresRotatingSecretBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rotation_policy_name': value.rotationPolicyName,
        'postgres_params': Secrets20231128PostgresParamsToJSON(value.postgresParams),
        'rotate_on_update': value.rotateOnUpdate,
    };
}
