import Component from '@glimmer/component';

interface HvnSelectHvnOptionSignature {
  Args: {
    option: unknown;
    select: unknown;
  };
}

export default class FormInputsHvnSelectHvnOptionComponent extends Component<HvnSelectHvnOptionSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormInputs::HvnSelect::HvnOption': typeof FormInputsHvnSelectHvnOptionComponent;
    'form-inputs/hvn-select/hvn-option': typeof FormInputsHvnSelectHvnOptionComponent;
  }
}
