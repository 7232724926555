import BaseAbility from './base-ability';
import { PREFIX_IAM_AUTH_CONNECTION } from '../utils/permission-types/index';

export default class AuthConnectionAbility extends BaseAbility {
  prefix = PREFIX_IAM_AUTH_CONNECTION;

  // only users that are org owners or admins should be able to do this
  get canCRUD() {
    return this.canCreate && this.canUpdate && this.canDelete && this.canGet;
  }
}
