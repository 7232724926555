/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Type represents the type of snapshots.
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125SnapshotType;
(function (HashicorpCloudVault20201125SnapshotType) {
    HashicorpCloudVault20201125SnapshotType["SNAPSHOTTYPEINVALID"] = "SNAPSHOT_TYPE_INVALID";
    HashicorpCloudVault20201125SnapshotType["AUTOMATIC"] = "AUTOMATIC";
    HashicorpCloudVault20201125SnapshotType["SCHEDULED"] = "SCHEDULED";
    HashicorpCloudVault20201125SnapshotType["MANUAL"] = "MANUAL";
    HashicorpCloudVault20201125SnapshotType["BEFOREUPGRADE"] = "BEFORE_UPGRADE";
})(HashicorpCloudVault20201125SnapshotType || (HashicorpCloudVault20201125SnapshotType = {}));
export function HashicorpCloudVault20201125SnapshotTypeFromJSON(json) {
    return HashicorpCloudVault20201125SnapshotTypeFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125SnapshotTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125SnapshotTypeToJSON(value) {
    return value;
}
