import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';

import NO_ROLE_ROLE from '../utils/no-role-role.ts';

export default function iamFindRoleFromRoles(
  roles: Array<HashicorpCloudResourcemanagerRole> = [],
  { roleId }: { roleId: HashicorpCloudResourcemanagerRole['id'] },
): HashicorpCloudResourcemanagerRole | undefined {
  return [...roles, NO_ROLE_ROLE].find((r) => r.id === roleId);
}
