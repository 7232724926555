/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20230613AwsSmConnectionDetailsRequestFromJSON(json) {
    return Secrets20230613AwsSmConnectionDetailsRequestFromJSONTyped(json, false);
}
export function Secrets20230613AwsSmConnectionDetailsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accessKeyId': !exists(json, 'access_key_id') ? undefined : json['access_key_id'],
        'secretAccessKey': !exists(json, 'secret_access_key') ? undefined : json['secret_access_key'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'roleArn': !exists(json, 'role_arn') ? undefined : json['role_arn'],
    };
}
export function Secrets20230613AwsSmConnectionDetailsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'access_key_id': value.accessKeyId,
        'secret_access_key': value.secretAccessKey,
        'region': value.region,
        'role_arn': value.roleArn,
    };
}
