/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Contacts Service
 * API for managing Contact information.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudEmailVerifyContactRequestFromJSON(json) {
  return HashicorpCloudEmailVerifyContactRequestFromJSONTyped(json, false);
}
export function HashicorpCloudEmailVerifyContactRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    verificationToken: !exists(json, 'verification_token') ? undefined : json['verification_token'],
  };
}
export function HashicorpCloudEmailVerifyContactRequestToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    verification_token: value.verificationToken,
  };
}
