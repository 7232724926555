import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import {
  TYPE_CONSUL,
  TYPE_VAULT,
  TYPE_PACKER,
  TYPE_PACKER_VERSION,
  TYPE_TERRAFORM,
  TYPE_NETWORK,
  TYPE_NETWORK_ROUTE,
  TYPE_NETWORK_PEERING,
  TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
} from 'common/utils/cloud-resource-types';
import type { IntlService } from 'ember-intl';

const I18N_PREFIX = 'helpers.resource-type-display-name';

export const CLOUD_RESOURCE_TYPE_ACCEPT_LIST = [
  TYPE_CONSUL,
  TYPE_VAULT,
  TYPE_PACKER,
  TYPE_PACKER_VERSION,
  TYPE_TERRAFORM,
  TYPE_NETWORK,
  TYPE_NETWORK_ROUTE,
  TYPE_NETWORK_PEERING,
  TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
];

export const CLOUD_RESOURCE_TYPE_I18N_MAPPING: Record<string, string> =
  CLOUD_RESOURCE_TYPE_ACCEPT_LIST.reduce(
    (mappings, type) => ({ ...mappings, [type]: `${I18N_PREFIX}.${type}` }),
    {}
  );

export default class ResourceTypeDisplayName extends Helper {
  @service declare readonly intl: IntlService;

  /**
   * @param {array} [args] - 0th element of args must be a non-empty string
   * @returns {string} - i18n translation of resource type friendly name
   */
  compute([cloudResourceType = '']) {
    const translationKey = CLOUD_RESOURCE_TYPE_I18N_MAPPING[cloudResourceType];
    if (translationKey) {
      return this.intl.t(translationKey);
    }

    return cloudResourceType;
  }
}
