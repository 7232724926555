/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudResourcemanagerRoleFromJSON, HashicorpCloudResourcemanagerRoleToJSON, } from './';
export function HashicorpCloudResourcemanagerOrganizationGetRoleResponseFromJSON(json) {
    return HashicorpCloudResourcemanagerOrganizationGetRoleResponseFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerOrganizationGetRoleResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'role': !exists(json, 'role') ? undefined : HashicorpCloudResourcemanagerRoleFromJSON(json['role']),
    };
}
export function HashicorpCloudResourcemanagerOrganizationGetRoleResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'role': HashicorpCloudResourcemanagerRoleToJSON(value.role),
    };
}
