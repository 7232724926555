/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVault20201125GetClientCountsResponseFromJSON(json) {
    return HashicorpCloudVault20201125GetClientCountsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125GetClientCountsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'clients': !exists(json, 'clients') ? undefined : json['clients'],
        'distinctEntities': !exists(json, 'distinct_entities') ? undefined : json['distinct_entities'],
        'nonEntityTokens': !exists(json, 'non_entity_tokens') ? undefined : json['non_entity_tokens'],
    };
}
export function HashicorpCloudVault20201125GetClientCountsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'clients': value.clients,
        'distinct_entities': value.distinctEntities,
        'non_entity_tokens': value.nonEntityTokens,
    };
}
