import templateOnlyComponent from '@ember/component/template-only';

interface LayoutPageHeaderActionsSignature {
  Args: {
    lg: unknown;
    md: unknown;
    sm: unknown;
    xs: unknown;
  };
  Blocks: {
    default: [];
  };
}

const LayoutPageHeaderActionsComponent =
  templateOnlyComponent<LayoutPageHeaderActionsSignature>();

export default LayoutPageHeaderActionsComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::PageHeader::Actions': typeof LayoutPageHeaderActionsComponent;
    'layout/page-header/actions': typeof LayoutPageHeaderActionsComponent;
  }
}
