/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudInternalLocationLinkFromJSON, HashicorpCloudInternalLocationLinkToJSON, HashicorpCloudVault20201125ClusterPerformanceReplicationInfoModeFromJSON, HashicorpCloudVault20201125ClusterPerformanceReplicationInfoModeToJSON, HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterFromJSON, HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterToJSON, } from './';
export function HashicorpCloudVault20201125ClusterPerformanceReplicationInfoFromJSON(json) {
    return HashicorpCloudVault20201125ClusterPerformanceReplicationInfoFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125ClusterPerformanceReplicationInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mode': !exists(json, 'mode') ? undefined : HashicorpCloudVault20201125ClusterPerformanceReplicationInfoModeFromJSON(json['mode']),
        'primaryClusterLink': !exists(json, 'primary_cluster_link') ? undefined : HashicorpCloudInternalLocationLinkFromJSON(json['primary_cluster_link']),
        'pathsFilter': !exists(json, 'paths_filter') ? undefined : HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterFromJSON(json['paths_filter']),
    };
}
export function HashicorpCloudVault20201125ClusterPerformanceReplicationInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mode': HashicorpCloudVault20201125ClusterPerformanceReplicationInfoModeToJSON(value.mode),
        'primary_cluster_link': HashicorpCloudInternalLocationLinkToJSON(value.primaryClusterLink),
        'paths_filter': HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterToJSON(value.pathsFilter),
    };
}
