/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIamPrincipalFromJSON, HashicorpCloudIamPrincipalToJSON, } from './';
export function HashicorpCloudIamGetCallerIdentityResponseFromJSON(json) {
    return HashicorpCloudIamGetCallerIdentityResponseFromJSONTyped(json, false);
}
export function HashicorpCloudIamGetCallerIdentityResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'principal': !exists(json, 'principal') ? undefined : HashicorpCloudIamPrincipalFromJSON(json['principal']),
    };
}
export function HashicorpCloudIamGetCallerIdentityResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'principal': HashicorpCloudIamPrincipalToJSON(value.principal),
    };
}
