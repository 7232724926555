import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { DEBUG } from '@glimmer/env';
import * as Sentry from '@sentry/ember';

import type ResourceCatalogService from '../services/resource-catalog.ts';
import type { ResourceTierData } from '../types/resource-catalog.ts';

export default class OptionForResourceTier extends Helper {
  @service declare readonly resourceCatalog: ResourceCatalogService;

  compute(
    [tierKey = '']: [string | undefined],
    { resourceType, provider }: { resourceType?: string; provider: string },
  ): ResourceTierData | null {
    // This checks if a tier exists, if not, it won't try to display it on the page.
    if (!tierKey || !resourceType || !provider) {
      return null;
    }
    try {
      return this.resourceCatalog.getTier(resourceType, tierKey, provider);
    } catch (e) {
      if (DEBUG) {
        console.log(e); // eslint-disable-line
      }
      Sentry.captureException(e);
      return null;
    }
  }
}
