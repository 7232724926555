/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * state is one of the states the TGW Attachment could be in.
 * @export
 * @enum {string}
 */
export var HashicorpCloudNetwork20200907HVNRouteState;
(function (HashicorpCloudNetwork20200907HVNRouteState) {
    HashicorpCloudNetwork20200907HVNRouteState["UNSET"] = "UNSET";
    HashicorpCloudNetwork20200907HVNRouteState["PENDING"] = "PENDING";
    HashicorpCloudNetwork20200907HVNRouteState["CREATING"] = "CREATING";
    HashicorpCloudNetwork20200907HVNRouteState["ACTIVE"] = "ACTIVE";
    HashicorpCloudNetwork20200907HVNRouteState["FAILED"] = "FAILED";
    HashicorpCloudNetwork20200907HVNRouteState["DELETING"] = "DELETING";
})(HashicorpCloudNetwork20200907HVNRouteState || (HashicorpCloudNetwork20200907HVNRouteState = {}));
export function HashicorpCloudNetwork20200907HVNRouteStateFromJSON(json) {
    return HashicorpCloudNetwork20200907HVNRouteStateFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907HVNRouteStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudNetwork20200907HVNRouteStateToJSON(value) {
    return value;
}
