/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudNetwork20200907AzureHopType;
(function (HashicorpCloudNetwork20200907AzureHopType) {
    HashicorpCloudNetwork20200907AzureHopType["APPLIANCE"] = "VIRTUAL_APPLIANCE";
    HashicorpCloudNetwork20200907AzureHopType["NETWORKGATEWAY"] = "VIRTUAL_NETWORK_GATEWAY";
})(HashicorpCloudNetwork20200907AzureHopType || (HashicorpCloudNetwork20200907AzureHopType = {}));
export function HashicorpCloudNetwork20200907AzureHopTypeFromJSON(json) {
    return HashicorpCloudNetwork20200907AzureHopTypeFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907AzureHopTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudNetwork20200907AzureHopTypeToJSON(value) {
    return value;
}
