/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125PluginType;
(function (HashicorpCloudVault20201125PluginType) {
    HashicorpCloudVault20201125PluginType["PLUGINTYPEINVALID"] = "PLUGIN_TYPE_INVALID";
    HashicorpCloudVault20201125PluginType["SECRET"] = "SECRET";
    HashicorpCloudVault20201125PluginType["AUTH"] = "AUTH";
    HashicorpCloudVault20201125PluginType["DATABASE"] = "DATABASE";
})(HashicorpCloudVault20201125PluginType || (HashicorpCloudVault20201125PluginType = {}));
export function HashicorpCloudVault20201125PluginTypeFromJSON(json) {
    return HashicorpCloudVault20201125PluginTypeFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125PluginTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125PluginTypeToJSON(value) {
    return value;
}
