import Component from '@glimmer/component';

interface OperationFailureForResourceSignature {
  Args: {
    resource: unknown;
    resourceType: unknown;
  };
}

/**
 *
 * Error messages related to failed operations from 'FAILED' or
 * busy clusters will be rendered in an alert component.
 *
 * ```
 * <Operation::FailureForResource
 *   @resource={{@model.cluster}}
 *   @resourceType={{resource-type-for 'network'}}
 * />
 * ```
 *
 * @class OperationFailureForResource
 *
 */

export default class OperationFailureForResourceComponent extends Component<OperationFailureForResourceSignature> {
  /**
   * The `resource` is the object that will be used to find a related operation
   * failure. It needs to have `state` and `id` attributes.
   * @argument resource
   * @type {object}
   */
  /**
   * The resource resource type so we can check if the resource's state is
   * FAILED or busy.
   * @example 'hashicorp.network.hvn'
   * @argument resourceType
   * @type {string}
   */
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operation::FailureForResource': typeof OperationFailureForResourceComponent;
    'operation/failure-for-resource': typeof OperationFailureForResourceComponent;
  }
}
