/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudNetwork20200907AWSPeeringTargetFromJSON(json) {
    return HashicorpCloudNetwork20200907AWSPeeringTargetFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907AWSPeeringTargetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'vpcId': !exists(json, 'vpc_id') ? undefined : json['vpc_id'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'accountId': !exists(json, 'account_id') ? undefined : json['account_id'],
        'cidr': !exists(json, 'cidr') ? undefined : json['cidr'],
    };
}
export function HashicorpCloudNetwork20200907AWSPeeringTargetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'vpc_id': value.vpcId,
        'region': value.region,
        'account_id': value.accountId,
        'cidr': value.cidr,
    };
}
