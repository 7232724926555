/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105PackageItemTypeFromJSON, Billing20201105PackageItemTypeToJSON, Billing20201105PackageOptionFromJSON, Billing20201105PackageOptionToJSON, } from './';
export function Billing20201105PackageItemFromJSON(json) {
    return Billing20201105PackageItemFromJSONTyped(json, false);
}
export function Billing20201105PackageItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !exists(json, 'key') ? undefined : json['key'],
        'optional': !exists(json, 'optional') ? undefined : json['optional'],
        'options': !exists(json, 'options') ? undefined : (json['options'].map(Billing20201105PackageOptionFromJSON)),
        'type': !exists(json, 'type') ? undefined : Billing20201105PackageItemTypeFromJSON(json['type']),
    };
}
export function Billing20201105PackageItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
        'optional': value.optional,
        'options': value.options === undefined ? undefined : (value.options.map(Billing20201105PackageOptionToJSON)),
        'type': Billing20201105PackageItemTypeToJSON(value.type),
    };
}
