import Service from '@ember/service';
import { TYPE_CONSUL, TYPE_VAULT } from 'common/utils/cloud-resource-types';
import * as Sentry from '@sentry/ember';
import {
  ProductProviderRegionFeatureFlags,
  NetworkProviderSingleRegionFeatureFlags,
  ProductRegionFallbacks,
  NetworkProviderRegionFeatureFlags,
  DEFAULT_AVAILABLE_REGIONS,
  NetworkProviderPeeringRegionsFeatureFlags,
  ConsulPeeringRegionFallbacks,
} from '../utils/regions.ts';
import { variation } from 'ember-launch-darkly';
import type { HashicorpCloudNetwork20200907Network } from '@clients/cloud-network';

/**
 *
 * `RegionsService` provides utilities for retrieving information about supported regions.
 * This service uses both a hardcoded list of available regions, as well as feature flags
 * wherever available, to provide lists of supported regions by product and overall
 * supported regions for networking.
 *
 * At this time, this service has no connection to the cloud-region-service.
 *
 *
 * @class RegionsService
 *
 */

export default class RegionsService extends Service {
  /** Returns a list of regions for a provider (aws, azure) supported by networking at the HCP level.*/
  platformProviderRegions(provider: string) {
    const regionsFeatureFlag = NetworkProviderRegionFeatureFlags[provider];
    const fallback = DEFAULT_AVAILABLE_REGIONS[provider] || [];

    if (!regionsFeatureFlag) {
      const invalidProviderError = new Error(
        `No region information is available for provider ${provider} on HCP. Valid providers are: ${Object.keys(
          NetworkProviderRegionFeatureFlags
        ).join(', ')}.`
      );
      Sentry.captureException(invalidProviderError);
      return fallback;
    }

    const launchDarklyRegions = variation<string[]>(regionsFeatureFlag);

    if (!launchDarklyRegions) {
      const noRegionsError = new Error(
        `LaunchDarkly flag ${regionsFeatureFlag} was not available at runtime.`
      );
      Sentry.captureException(noRegionsError);
    }

    return launchDarklyRegions || fallback;
  }

  /** Returns a list of supported provider regions for a given product. */
  productProviderRegions(
    /** the product to fetch supported regions for (hashicorp.consul.cluster, hashicorp.vault.cluster) */
    product: string,
    /** the provider to fetch supported regions for (aws) */
    provider: string,
    /** optional list of strings of regions to fall back to if product regions cannot be retrieved */
    fallbackRegions?: string[]
  ) {
    const fallback =
      fallbackRegions || ProductRegionFallbacks[provider]?.[product] || [];

    // An object with all product feature flags for the given provider
    const providerFeatureFlags = ProductProviderRegionFeatureFlags[provider];

    if (!providerFeatureFlags) {
      const invalidProviderError = new Error(
        `No region information is available for provider ${provider}. Valid providers are: ${Object.keys(
          ProductProviderRegionFeatureFlags
        ).join(', ')}.`
      );
      Sentry.captureException(invalidProviderError);
      return fallback;
    }

    // The specific feature flag that returns list of regions for this product and provider
    const productFeatureFlag = providerFeatureFlags[product];

    if (!productFeatureFlag) {
      const invalidProductError = new Error(
        `No region information is available for product ${product} on provider ${provider}. Valid products for this provider are: ${Object.keys(
          providerFeatureFlags
        ).join(', ')}.`
      );
      Sentry.captureException(invalidProductError);
      return fallback;
    }

    const launchDarklyRegions = variation<string[]>(productFeatureFlag);

    if (!launchDarklyRegions) {
      const noRegionsError = new Error(
        `LaunchDarkly flag ${productFeatureFlag} was not available at runtime.`
      );
      Sentry.captureException(noRegionsError);
    }

    return launchDarklyRegions || fallback;
  }

  /**
   * Returns a map with supported regions for each provider for a given product.
   * Ex: productRegions('hashicorp.consul.cluster', ['aws', 'azure'])
   * {
   *   aws: ['us-west-2'],
   *   azure: ['westus2']
   * }
   */
  productRegions(
    /** the product to fetch supported regions for (hashicorp.consul.cluster, hashicorp.vault.cluster) */
    product: string,
    /** a list of providers to fetch supported regions for (aws) */
    providers: string[]
  ) {
    const regions: Record<string, string[]> = {};

    if (!product || !providers) {
      return regions;
    }

    for (const provider of providers) {
      regions[provider] = this.productProviderRegions(product, provider);
    }
    return regions;
  }

  /** Returns a list of regions for a provider supported by Consul. */
  consulProviderRegions(provider: string) {
    const fallback = ProductRegionFallbacks[provider]?.[TYPE_CONSUL] || [];
    return this.productProviderRegions(TYPE_CONSUL, provider, fallback);
  }

  /** Returns a list of available VPC/VNet regions for a given provider */
  consulProviderPeeringRegions(provider: string) {
    const featureFlag = NetworkProviderPeeringRegionsFeatureFlags[provider];

    let peeringRegions;

    if (featureFlag) {
      peeringRegions = variation<string[]>(featureFlag);
    }

    return peeringRegions || ConsulPeeringRegionFallbacks[provider];
  }

  /** Returns a list of regions for a provider supported by Vault. */
  vaultProviderRegions(provider: string) {
    const fallback = ProductRegionFallbacks[provider]?.[TYPE_VAULT] || [];
    return this.productProviderRegions(TYPE_VAULT, provider, fallback);
  }

  /** Returns a list of regions for a provider supported by networking. */
  networkProviderRegions(provider: string) {
    // Wishlist item: replace logic in this function and instead fetch list from an API or feature flag

    const defaultRegions = this.platformProviderRegions(provider);

    // Add product regions in case there are networks available in products that
    // have not yet been included in hardcoded regions list
    const consulRegions = this.consulProviderRegions(provider);
    const vaultRegions = this.vaultProviderRegions(provider);

    const regions = new Set([
      ...defaultRegions,
      ...consulRegions,
      ...vaultRegions,
    ]);

    // Add feature-flagged network regions
    const featureFlaggedRegions =
      NetworkProviderSingleRegionFeatureFlags[provider];
    if (featureFlaggedRegions) {
      for (const { flag, value } of Object.values(featureFlaggedRegions)) {
        if (variation(flag)) {
          regions.add(value);
        } else {
          // Remove regions if the feature flag indicates it's not available but
          // it has been included in the hardcoded regions list or a product region list.
          regions.delete(value);
        }
      }
    }

    return Array.from(regions);
  }

  /** Returns a list of strings indicating products that support the provided region. */
  productsForRegion(locationRegion?: { provider: string; region: string }) {
    const products: string[] = [];

    if (!locationRegion) {
      return products;
    }

    const { region, provider } = locationRegion;

    if (this.consulProviderRegions(provider).includes(region)) {
      products.push(TYPE_CONSUL);
    }
    if (this.vaultProviderRegions(provider).includes(region)) {
      products.push(TYPE_VAULT);
    }
    return products;
  }

  /** Returns a list of networks that are supported by the provided resource type. */
  networksSupportedByResourceAndProvider(
    /** a list of networks to filter */
    networks: HashicorpCloudNetwork20200907Network[] | undefined,
    /** the resource type to fetch supported regions for (hashicorp.consul.cluster, hashicorp.vault.cluster) */
    resourceType: string,
    /** the network provider to fetch supported regions for (aws) */
    provider: string
  ) {
    let supportedRegions: string[] = [];

    if (!networks) {
      return [];
    }

    switch (resourceType) {
      case TYPE_CONSUL:
        supportedRegions = this.consulProviderRegions(provider);
        break;
      case TYPE_VAULT:
        supportedRegions = this.vaultProviderRegions(provider);
        break;
      default:
        supportedRegions = this.productProviderRegions(resourceType, provider);
    }

    return networks.filter(
      (network) =>
        network?.location?.region?.region &&
        supportedRegions.includes(network.location.region.region)
    );
  }

  /**
   * Returns a list of networks that are supported by the provided resource type.
   * Provided a list of candidate networks, only the networks which are:
   * a) On a supported provider, and
   * b) In a region on that provider supported by the product
   * will be returned in the resulting list.
   */
  networksSupportedByResourceAndProviders(
    /** a list of networks to filter */
    networks: HashicorpCloudNetwork20200907Network[] | undefined,
    /** the resource type to fetch supported regions for (hashicorp.consul.cluster, hashicorp.vault.cluster) */
    resourceType: string,
    /** a list of providers supported by the product (aws, azure) */
    providers: string[] = []
  ) {
    const supportedNetworks = [];
    for (const provider of providers) {
      supportedNetworks.push(
        ...this.networksSupportedByResourceAndProvider(
          networks,
          resourceType,
          provider
        )
      );
    }
    return supportedNetworks;
  }

  /**
   * Returns the provider that the given region is associated with, given a list of candidate providers and resource type.
   * Example: Use this method to determine which provider `us-west-2` is associated with, given you know that your resource type
   * is hashicorp.consul.cluster and the supported providers are `[aws, azure]`.
   */
  providerForRegionAndResource(
    /** the resource type to fetch supported regions for (hashicorp.consul.cluster, hashicorp.vault.cluster) */
    resourceType: string,
    /** a list of providers supported by the product (aws, azure) */
    providers: string[] = [],
    /** a region in a provider */
    region: string
  ) {
    if (!region || !resourceType) return null;
    for (const provider of providers) {
      const providerRegions = this.productProviderRegions(
        TYPE_CONSUL,
        provider
      );
      if (providerRegions.includes(region)) {
        return provider;
      }
    }
    return null;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    regions: RegionsService;
  }
}
