export const AZURE_DISCLOSURE_GUIDES_PEERING = {
  tenantId: [
    {
      helpText:
        'components.page.hvn-peerings.create.form.azure.label.tenant-id-example-instructions',
      imgSrc: '/images/azure_tenant_id.png',
      imgAlt:
        'components.page.hvn-peerings.create.form.azure.label.tenant-id-example-alt',
    },
  ],
  subscriptionId: [
    {
      helpText:
        'components.page.hvn-peerings.create.form.azure.label.subscription-id-example-instructions',
      imgSrc: '/images/azure_subscription_id.png',
      imgAlt:
        'components.page.hvn-peerings.create.form.azure.label.subscription-id-example-alt',
    },
  ],
  resourceGroupName: [
    {
      helpText:
        'components.page.hvn-peerings.create.form.azure.label.resource-group-name-example-instructions',
      imgSrc: '/images/azure_resource_group_name.png',
      imgAlt:
        'components.page.hvn-peerings.create.form.azure.label.resource-group-name-example-alt',
    },
  ],
  vnetName: [
    {
      helpText:
        'components.page.hvn-peerings.create.form.azure.label.vnet-name-example-instructions',
      imgSrc: '/images/azure_vnet_name.png',
      imgAlt:
        'components.page.hvn-peerings.create.form.azure.label.vnet-name-example-alt',
    },
  ],
  updatingSecurityGroups: [
    {
      helpText:
        'components.page.hvn-peerings.detail.instructions.azure.active.web-console.updating-security-groups.help-text',
      imgSrc: '/images/azure_updating_security_groups.png',
      imgAlt:
        'components.page.hvn-peerings.detail.instructions.azure.active.web-console.updating-security-groups.example-alt',
    },
  ],
  cloudShell: [
    {
      helpText:
        'components.page.hvn-peerings.detail.instructions.azure.pending-acceptance.cloud-shell.help-text',
      imgSrc: '/images/azure_cloud_shell.png',
      imgAlt:
        'components.page.hvn-peerings.detail.instructions.azure.pending-acceptance.cloud-shell.example-alt',
    },
  ],
};
