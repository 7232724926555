import BaseAbility from './base-ability';
import {
  PREFIX_RESOURCE_MANAGER_RESOURCES,
  ACTION_LIST_ROLES,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = PREFIX_RESOURCE_MANAGER_RESOURCES;

  get canListRoles() {
    return this.permissions.has(
      `${PREFIX_RESOURCE_MANAGER_RESOURCES}.${ACTION_LIST_ROLES}`,
    );
  }
}
