/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20230613PrincipalFromJSON, Secrets20230613PrincipalToJSON, } from './';
export function Secrets20230613SecretVersionFromJSON(json) {
    return Secrets20230613SecretVersionFromJSONTyped(json, false);
}
export function Secrets20230613SecretVersionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'version': !exists(json, 'version') ? undefined : json['version'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : Secrets20230613PrincipalFromJSON(json['created_by']),
    };
}
export function Secrets20230613SecretVersionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'version': value.version,
        'type': value.type,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'created_by': Secrets20230613PrincipalToJSON(value.createdBy),
    };
}
