export default function iamKebabCaseToSentenceCase(anyString: string = '') {
  return anyString
    .split('-')
    .map((word, index) =>
      index === 0
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word.toLowerCase(),
    )
    .join(' ');
}
