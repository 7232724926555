import templateOnlyComponent from '@ember/component/template-only';

interface LayoutFullScreenAsideSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const LayoutFullScreenAsideComponent =
  templateOnlyComponent<LayoutFullScreenAsideSignature>();

export default LayoutFullScreenAsideComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::FullScreen::Aside': typeof LayoutFullScreenAsideComponent;
    'layout/full-screen/aside': typeof LayoutFullScreenAsideComponent;
  }
}
