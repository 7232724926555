import Component from '@glimmer/component';
import { DEFAULT_ICON_TYPE } from '../utils/consts/error.js';

interface ErrorSignature {
  Args: {
    icon: unknown;
  };
  Blocks: {
    content: [];
    footer: [];
    subtitle: [];
    title: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `Error` displays relevant error messaging.
 *
 *
 * ```
 * <Error @icon="x-square-fill">
 *   <:title>Not Found</:title>
 *   <:subtitle>Error 404</:subtitle>
 *   <:content>Some content message</:content>
 *   <:footer>
 *    <LinkTo @route="cloud">
 *      <FlightIcon @name='chevron-left' @size='16' />
 *      Go back
 *    </LinkTo>
 *  </:footer>
 * </Error>
 * ```
 *
 * @class Error
 *
 */

/**
 * The icon name to render via a FlightIcon component.
 * @argument icon
 * @type {string}
 */

export default class ErrorComponent extends Component<ErrorSignature> {
  get icon() {
    const { icon } = this.args;
    return icon || DEFAULT_ICON_TYPE;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Error: typeof ErrorComponent;
    error: typeof ErrorComponent;
  }
}
