/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatus;
(function (HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatus) {
    HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatus["LEADERSTATUSCLUSTERSTATEINVALID"] = "LEADER_STATUS_CLUSTER_STATE_INVALID";
    HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatus["LEADER"] = "LEADER";
    HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatus["FOLLOWER"] = "FOLLOWER";
    HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatus["ACTIVE"] = "ACTIVE";
    HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatus["STANDBY"] = "STANDBY";
    HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatus["PERFSTANDBY"] = "PERF_STANDBY";
})(HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatus || (HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatus = {}));
export function HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatusFromJSON(json) {
    return HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatusFromJSONTyped(json, false);
}
export function HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatusToJSON(value) {
    return value;
}
