/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudGlobalNetworkManager20220215AutoPilotInfoFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215AutoPilotInfoFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215AutoPilotInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'healthy': !exists(json, 'healthy') ? undefined : json['healthy'],
        'failureTolerance': !exists(json, 'failure_tolerance') ? undefined : json['failure_tolerance'],
        'minQuorum': !exists(json, 'min_quorum') ? undefined : json['min_quorum'],
        'numServers': !exists(json, 'num_servers') ? undefined : json['num_servers'],
        'numVoters': !exists(json, 'num_voters') ? undefined : json['num_voters'],
    };
}
export function HashicorpCloudGlobalNetworkManager20220215AutoPilotInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'healthy': value.healthy,
        'failure_tolerance': value.failureTolerance,
        'min_quorum': value.minQuorum,
        'num_servers': value.numServers,
        'num_voters': value.numVoters,
    };
}
