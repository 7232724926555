import BaseAbility from './base-ability';
import { inject as service } from '@ember/service';
import * as Sentry from '@sentry/ember';
import { TYPE_CONSUL_GLOBAL_NETWORK_MANAGER } from 'common/utils/cloud-resource-types';
import {
  PREFIX_CONSUL_GNM_CLUSTERS,
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_GET_API_INFORMATION,
  ACTION_GET_SECRETS,
  ACTION_UPDATE,
} from '../utils/permission-types/index';
import { variation } from 'ember-launch-darkly';

export default class extends BaseAbility {
  @service abilities;

  @service quota;
  @service userContext;

  prefix = PREFIX_CONSUL_GNM_CLUSTERS;

  /** @return {number} */
  calculateTrialQuota() {
    let fallback =
      this.quota.constructor.DEFAULT_LIMITS[TYPE_CONSUL_GLOBAL_NETWORK_MANAGER];

    let trialQuota = variation('hcp-billing-trial-cluster-quota');

    if (!trialQuota) {
      let missingTrialQuotaError = new Error(
        `Could not retrieve the Consul global network manager trial cluster limit from expected 'hcp-billing-trial-cluster-quota' feature flag.`,
      );
      Sentry.captureException(missingTrialQuotaError);

      return fallback;
    }

    return trialQuota;
  }

  /** @return {number} */
  calculateQuota() {
    let useTrialQuota =
      this.userContext.billing?.status?.isTrial &&
      variation('hcp-billing-trial-quota-opt-out') !== 'OPT_OUT';

    return useTrialQuota
      ? this.calculateTrialQuota()
      : this.quota.for(TYPE_CONSUL_GLOBAL_NETWORK_MANAGER);
  }

  /**
   * Determine if create is available based on permissions, an array of
   * clusters and a quota.
   *
   * Requires a list of GNM clusters to be passed as the model.
   * @return {boolean}
   */
  get canCreate() {
    return (
      this.permissions.has(this.generate(ACTION_CREATE)) &&
      this.model.length < this.calculateQuota() &&
      this.quota.for(TYPE_CONSUL_GLOBAL_NETWORK_MANAGER) !== 0
    );
  }

  /**
   * Determine if delete is available based on permissions.
   *
   * Requires a single GNM cluster to be passed as the model.
   * @return {boolean}
   */
  get canDelete() {
    return this.permissions.has(this.generate(ACTION_DELETE));
  }

  /**
   * Determine if update is available based on permissions.
   *
   * @return {boolean}
   */
  get canUpdate() {
    return this.permissions.has(this.generate(ACTION_UPDATE));
  }

  /**
   * Determine if cluster quota has been met
   *
   * Requires a list of GNM clusters to be passed as the model.
   * @return {boolean}
   */
  get canCreateQuotaReached() {
    return (
      this.model &&
      this.model.length === this.calculateQuota() &&
      this.calculateQuota() !== 0
    );
  }

  /**
   * Determine if get api information is available based on permissions.
   *
   * Does not require a model.
   * @return {boolean}
   */
  get canGetApiInformation() {
    return this.permissions.has(this.generate(ACTION_GET_API_INFORMATION));
  }

  /**
   * Determine if get secrets is available based on permissions.
   *
   * Does not require a model.
   * @return {boolean}
   */
  get canGetSecrets() {
    return this.permissions.has(this.generate(ACTION_GET_SECRETS));
  }
}
