/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IDP Service
 * API for managing authentication sessions and SCIM settings.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudIdpCreateScimTokenRequestToJSON, HashicorpCloudIdpCreateScimTokenResponseFromJSON, HashicorpCloudIdpIsScimEnabledResponseFromJSON, HashicorpCloudIdpListScimTokensResponseFromJSON, } from '../models';
/**
 *
 */
export class ScimServiceApi extends runtime.BaseAPI {
    /**
     * CreateScimToken creates a SCIM token for the organization.
     */
    async scimServiceCreateScimTokenRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling scimServiceCreateScimToken.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling scimServiceCreateScimToken.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/organizations/{organization_id}/scim/tokens/create`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudIdpCreateScimTokenRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIdpCreateScimTokenResponseFromJSON(jsonValue));
    }
    /**
     * CreateScimToken creates a SCIM token for the organization.
     */
    async scimServiceCreateScimToken(organizationId, body) {
        const response = await this.scimServiceCreateScimTokenRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * DeleteScimToken deletes a SCIM token for the organization.
     */
    async scimServiceDeleteScimTokenRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling scimServiceDeleteScimToken.');
        }
        if (requestParameters.tokenId === null || requestParameters.tokenId === undefined) {
            throw new runtime.RequiredError('tokenId', 'Required parameter requestParameters.tokenId was null or undefined when calling scimServiceDeleteScimToken.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/organizations/{organization_id}/scim/tokens/{token_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"token_id"}}`, encodeURIComponent(String(requestParameters.tokenId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteScimToken deletes a SCIM token for the organization.
     */
    async scimServiceDeleteScimToken(organizationId, tokenId) {
        const response = await this.scimServiceDeleteScimTokenRaw({ organizationId: organizationId, tokenId: tokenId });
        return await response.value();
    }
    /**
     * DisableScim disables SCIM for an organization and removes any SCIM tokens.
     */
    async scimServiceDisableScimRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling scimServiceDisableScim.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/organizations/{organization_id}/scim/disable`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DisableScim disables SCIM for an organization and removes any SCIM tokens.
     */
    async scimServiceDisableScim(organizationId) {
        const response = await this.scimServiceDisableScimRaw({ organizationId: organizationId });
        return await response.value();
    }
    /**
     * EnableScim enables SCIM for an organization.
     */
    async scimServiceEnableScimRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling scimServiceEnableScim.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/organizations/{organization_id}/scim/enable`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * EnableScim enables SCIM for an organization.
     */
    async scimServiceEnableScim(organizationId) {
        const response = await this.scimServiceEnableScimRaw({ organizationId: organizationId });
        return await response.value();
    }
    /**
     * IsScimEnabled returns the SCIM status for an organization.
     */
    async scimServiceIsScimEnabledRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling scimServiceIsScimEnabled.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/organizations/{organization_id}/scim/is_enabled`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIdpIsScimEnabledResponseFromJSON(jsonValue));
    }
    /**
     * IsScimEnabled returns the SCIM status for an organization.
     */
    async scimServiceIsScimEnabled(organizationId) {
        const response = await this.scimServiceIsScimEnabledRaw({ organizationId: organizationId });
        return await response.value();
    }
    /**
     * ListScimTokens retrieves SCIM tokens for the organization.
     */
    async scimServiceListScimTokensRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling scimServiceListScimTokens.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/organizations/{organization_id}/scim/tokens`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIdpListScimTokensResponseFromJSON(jsonValue));
    }
    /**
     * ListScimTokens retrieves SCIM tokens for the organization.
     */
    async scimServiceListScimTokens(organizationId, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.scimServiceListScimTokensRaw({ organizationId: organizationId, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
}
