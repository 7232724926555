import Component from '@glimmer/component';
import { SLIDING_WINDOW_COLUMN_WIDTHS } from '../../utils/consts/sliding-window.ts';

interface LayoutSlidingWindowSignature {
  Args: {
    leftDimension: number;
    rightDimension?: number;
    totalDimension: number;
  };
  Blocks: {
    left: [];
    right: [];
  };
}

/**
 * Constrains widths of adjacent "left" and "right" sets of card components.
 * Like a sliding window, as the left dimension opens, the right dimension closes.
 *
 * NOTE: The UI design this implements has a maximum total of 4 elements horizontally,
 *       don't use this component when (left+right > 4) ... or 💥 !!!
 *
 * ```
 * Left    Right
 *  ↓         ↓
 * ┌──────────┬┐
 * │     4    ││
 * └──────────┴┘
 * ┌───────┬───┐
 * │   3   │ 1 │
 * └───────┴───┘
 * ┌─────┬─────┐
 * │  2  │  2  │
 * └─────┴─────┘
 * ┌───┬───────┐
 * │ 1 │   3   │
 * └───┴───────┘
 * ┌┬──────────┐
 * ││    4     │
 * └┴──────────┘
 * ```
 *
 * @class LayoutSlidingWindowComponent
 *
 */
export default class LayoutSlidingWindowComponent extends Component<LayoutSlidingWindowSignature> {
  // TODO: Document args (mandatory VS optional matter)

  get leftLayout() {
    const n = this.args.leftDimension;

    return SLIDING_WINDOW_COLUMN_WIDTHS[
      n as keyof typeof SLIDING_WINDOW_COLUMN_WIDTHS
    ];
  }

  get rightLayout() {
    const n =
      this.args.rightDimension !== undefined
        ? this.args.rightDimension
        : this.args.totalDimension - this.args.leftDimension;

    return SLIDING_WINDOW_COLUMN_WIDTHS[
      n as keyof typeof SLIDING_WINDOW_COLUMN_WIDTHS
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::SlidingWindow': typeof LayoutSlidingWindowComponent;
    'layout/sliding-window': typeof LayoutSlidingWindowComponent;
  }
}
