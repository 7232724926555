export default class v2IamResource {
  #v2ResourceName = '';

  constructor(v2ResourceName) {
    this.#v2ResourceName = v2ResourceName;
  }

  /** Returns the v2resourceName as a string
   *  For example: "iam/organization/2F4988ada2-cb2c-4ca5-9e44-e129da0e1cb8/group/a-group-name"
   */
  get name() {
    return this.#v2ResourceName;
  }

  /** Returns a string of text that begins with the parent resource name.
   *  For example when the parent_resource_name is "organization", it returns:
   *  "/organization/2F4988ada2-cb2c-4ca5-9e44-e129da0e1cb8/group/a-group-name"
   */
  get organizationName() {
    const organizationCaptureRegexp = /^.*\/(organization\/[^/]+)\/?.*$/g;
    const [_, organizationName = ''] = organizationCaptureRegexp.exec(
      this.#v2ResourceName
    );
    return organizationName;
  }

  /** Returns a string of text that is inbetween the "/organization/" and "/group/" strings.
   *  For example when the v2ResourceName is
   *  "iam/organization/2F4988ada2-cb2c-4ca5-9e44-e129da0e1cb8/group/a-group-name"
   *  it returns "2F4988ada2-cb2c-4ca5-9e44-e129da0e1cb8"
   */
  get organizationId() {
    //find regex to return the string between organization/ and /group
    const organizationCaptureRegexp = /organization\/(.*)\/group\//g;
    const [_, organizationId = ''] = organizationCaptureRegexp.exec(
      this.#v2ResourceName
    );
    return organizationId;
  }

  get wipDisplayName() {
    //find regex to return the string after /workload-identity-provider/
    const wipCaptureRegexp = /\/workload-identity-provider\/(.*)/g;
    const [_, wipDisplayName = ''] = wipCaptureRegexp.exec(
      this.#v2ResourceName
    );
    return wipDisplayName;
  }

  /** Returns the string after the org_id in the v2ResourceName and begins with "group"
   *  For example: "group/a-group-name"
   */
  get groupName() {
    const groupCaptureRegexp = /^.*\/(group\/[^/]+)\/?.*$/g;
    const [_, groupName = ''] = groupCaptureRegexp.exec(this.#v2ResourceName);
    return groupName;
  }

  /** Returns a string that represents the input group name provided when a user has created a group
   *  It is the last part of the resourceName
   *  For example: "a-group-name"
   */
  get groupDisplayName() {
    const groupDisplayNameCaptureRegexp = /\/([^/]+)\/?$/g;
    const [_, displayName = ''] = groupDisplayNameCaptureRegexp.exec(
      this.#v2ResourceName
    );
    return displayName;
  }
}
