/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Contacts Service
 * API for managing Contact information.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import {
  HashicorpCloudEmailCreateContactRequestToJSON,
  HashicorpCloudEmailCreateContactResponseFromJSON,
  HashicorpCloudEmailListContactsResponseFromJSON,
  HashicorpCloudEmailStartNewContactVerificationRequestToJSON,
  HashicorpCloudEmailVerifyContactRequestToJSON,
} from '../models';
/**
 *
 */
export class ContactServiceApi extends runtime.BaseAPI {
  /**
   * CreateContactRequest inserts a contact record and initiates the verification  workflow for the email address on that contact record.  If the source_type of the request is HCP_USER_PRINCIPAL, the request  must carry a valid user principal in the JWT, and the user principal\'s uuid must match the source_identifier in the request.
   */
  async createContactRaw(requestParameters) {
    if (requestParameters.body === null || requestParameters.body === undefined) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling createContact.'
      );
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = this.configuration.apiKey('Authorization'); // bearer authentication
    }
    const response = await this.request({
      path: `/contacts`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: HashicorpCloudEmailCreateContactRequestToJSON(requestParameters.body),
    });
    return new runtime.JSONApiResponse(response, jsonValue =>
      HashicorpCloudEmailCreateContactResponseFromJSON(jsonValue)
    );
  }
  /**
   * CreateContactRequest inserts a contact record and initiates the verification  workflow for the email address on that contact record.  If the source_type of the request is HCP_USER_PRINCIPAL, the request  must carry a valid user principal in the JWT, and the user principal\'s uuid must match the source_identifier in the request.
   */
  async createContact(body) {
    const response = await this.createContactRaw({ body: body });
    return await response.value();
  }
  /**
   * This endpoint requires authentication via the Auth JWT, the request will  be rejected if the current user is not authorized to retrieve the contacts for the entity represented by source_type/source_identifier.  Additional note: source_type and source_identifier parameters are not bound by  the path template but were defined in the proto message, making them query parameters.
   * ListContacts retrieve all contact records for an entity (e.g. an HCP  user principal or Azure subscription) represented by the combination of a  source_type and a source_identifier.
   */
  async listContactsRaw(requestParameters) {
    const queryParameters = {};
    if (requestParameters.sourceIdentifier !== undefined) {
      queryParameters['source_identifier'] = requestParameters.sourceIdentifier;
    }
    if (requestParameters.sourceType !== undefined) {
      queryParameters['source_type'] = requestParameters.sourceType;
    }
    const headerParameters = {};
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = this.configuration.apiKey('Authorization'); // bearer authentication
    }
    const response = await this.request({
      path: `/contacts`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
    });
    return new runtime.JSONApiResponse(response, jsonValue =>
      HashicorpCloudEmailListContactsResponseFromJSON(jsonValue)
    );
  }
  /**
   * This endpoint requires authentication via the Auth JWT, the request will  be rejected if the current user is not authorized to retrieve the contacts for the entity represented by source_type/source_identifier.  Additional note: source_type and source_identifier parameters are not bound by  the path template but were defined in the proto message, making them query parameters.
   * ListContacts retrieve all contact records for an entity (e.g. an HCP  user principal or Azure subscription) represented by the combination of a  source_type and a source_identifier.
   */
  async listContacts(sourceIdentifier, sourceType) {
    const response = await this.listContactsRaw({
      sourceIdentifier: sourceIdentifier,
      sourceType: sourceType,
    });
    return await response.value();
  }
  /**
   * This endpoint requires authentication via the Auth JWT. Requests for a contact record that doesn\'t belong to the current user won\'t be accepted.
   * StartNewContactVerification allows the client to trigger a new contact verification flow. With a new verification token and a new email message.
   */
  async startNewContactVerificationRaw(requestParameters) {
    if (requestParameters.body === null || requestParameters.body === undefined) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling startNewContactVerification.'
      );
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = this.configuration.apiKey('Authorization'); // bearer authentication
    }
    const response = await this.request({
      path: `/contacts/start-new-verification`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: HashicorpCloudEmailStartNewContactVerificationRequestToJSON(requestParameters.body),
    });
    return new runtime.JSONApiResponse(response);
  }
  /**
   * This endpoint requires authentication via the Auth JWT. Requests for a contact record that doesn\'t belong to the current user won\'t be accepted.
   * StartNewContactVerification allows the client to trigger a new contact verification flow. With a new verification token and a new email message.
   */
  async startNewContactVerification(body) {
    const response = await this.startNewContactVerificationRaw({ body: body });
    return await response.value();
  }
  /**
   * This endpoint doesn\'t require authentication, as it only relies on the verification token from the request body.
   * VerifyContact ensures that a verification token exists and is not expired, and then marks the associated contact as verified and deletes any existing verification token data.
   */
  async verifyContactRaw(requestParameters) {
    if (requestParameters.body === null || requestParameters.body === undefined) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling verifyContact.'
      );
    }
    const queryParameters = {};
    const headerParameters = {};
    headerParameters['Content-Type'] = 'application/json';
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] = this.configuration.apiKey('Authorization'); // bearer authentication
    }
    const response = await this.request({
      path: `/verify-contact`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: HashicorpCloudEmailVerifyContactRequestToJSON(requestParameters.body),
    });
    return new runtime.JSONApiResponse(response);
  }
  /**
   * This endpoint doesn\'t require authentication, as it only relies on the verification token from the request body.
   * VerifyContact ensures that a verification token exists and is not expired, and then marks the associated contact as verified and deletes any existing verification token data.
   */
  async verifyContact(body) {
    const response = await this.verifyContactRaw({ body: body });
    return await response.value();
  }
}
/**
 * @export
 * @enum {string}
 */
export var ListContactsSourceTypeEnum;
(function(ListContactsSourceTypeEnum) {
  ListContactsSourceTypeEnum['UNSPECIFIED'] = 'UNSPECIFIED';
  ListContactsSourceTypeEnum['HCPUSERPRINCIPAL'] = 'HCP_USER_PRINCIPAL';
  ListContactsSourceTypeEnum['AZURESUBSCRIPTION'] = 'AZURE_SUBSCRIPTION';
})(ListContactsSourceTypeEnum || (ListContactsSourceTypeEnum = {}));
