/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105BillingAccountFromJSON, Billing20201105BillingAccountToJSON, Billing20201105EntitlementBillingMethodDetailsFromJSON, Billing20201105EntitlementBillingMethodDetailsToJSON, Billing20201105FlexibleConsumptionBillingMethodDetailsFromJSON, Billing20201105FlexibleConsumptionBillingMethodDetailsToJSON, Billing20201105OnDemandBillingMethodDetailsFromJSON, Billing20201105OnDemandBillingMethodDetailsToJSON, } from './';
export function Billing20201105GetBillingAccountResponseFromJSON(json) {
    return Billing20201105GetBillingAccountResponseFromJSONTyped(json, false);
}
export function Billing20201105GetBillingAccountResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'billingAccount': !exists(json, 'billing_account') ? undefined : Billing20201105BillingAccountFromJSON(json['billing_account']),
        'onDemandBillingMethodDetails': !exists(json, 'on_demand_billing_method_details') ? undefined : Billing20201105OnDemandBillingMethodDetailsFromJSON(json['on_demand_billing_method_details']),
        'flexibleConsumptionBillingMethodDetails': !exists(json, 'flexible_consumption_billing_method_details') ? undefined : Billing20201105FlexibleConsumptionBillingMethodDetailsFromJSON(json['flexible_consumption_billing_method_details']),
        'entitlementBillingMethodDetails': !exists(json, 'entitlement_billing_method_details') ? undefined : Billing20201105EntitlementBillingMethodDetailsFromJSON(json['entitlement_billing_method_details']),
    };
}
export function Billing20201105GetBillingAccountResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'billing_account': Billing20201105BillingAccountToJSON(value.billingAccount),
        'on_demand_billing_method_details': Billing20201105OnDemandBillingMethodDetailsToJSON(value.onDemandBillingMethodDetails),
        'flexible_consumption_billing_method_details': Billing20201105FlexibleConsumptionBillingMethodDetailsToJSON(value.flexibleConsumptionBillingMethodDetails),
        'entitlement_billing_method_details': Billing20201105EntitlementBillingMethodDetailsToJSON(value.entitlementBillingMethodDetails),
    };
}
