/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVaultLink20221107RaftQuorumStatusFromJSON(json) {
    return HashicorpCloudVaultLink20221107RaftQuorumStatusFromJSONTyped(json, false);
}
export function HashicorpCloudVaultLink20221107RaftQuorumStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isHealthy': !exists(json, 'is_healthy') ? undefined : json['is_healthy'],
        'warning': !exists(json, 'warning') ? undefined : json['warning'],
        'quorumNumber': !exists(json, 'quorum_number') ? undefined : json['quorum_number'],
    };
}
export function HashicorpCloudVaultLink20221107RaftQuorumStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'is_healthy': value.isHealthy,
        'warning': value.warning,
        'quorum_number': value.quorumNumber,
    };
}
