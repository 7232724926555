/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudConsul20210204ACLTokenFromJSON(json) {
    return HashicorpCloudConsul20210204ACLTokenFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204ACLTokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accessorId': !exists(json, 'accessor_id') ? undefined : json['accessor_id'],
        'secretId': !exists(json, 'secret_id') ? undefined : json['secret_id'],
    };
}
export function HashicorpCloudConsul20210204ACLTokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accessor_id': value.accessorId,
        'secret_id': value.secretId,
    };
}
