/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionCfgRefFromJSON, HashicorpCloudWaypointActionCfgRefToJSON, HashicorpCloudWaypointInputVariableFromJSON, HashicorpCloudWaypointInputVariableToJSON, HashicorpCloudWaypointRefApplicationTemplateFromJSON, HashicorpCloudWaypointRefApplicationTemplateToJSON, } from './';
export function HashicorpCloudWaypointWaypointServiceCreateApplicationFromTemplateBodyFromJSON(json) {
    return HashicorpCloudWaypointWaypointServiceCreateApplicationFromTemplateBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointWaypointServiceCreateApplicationFromTemplateBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'applicationTemplate': !exists(json, 'application_template') ? undefined : HashicorpCloudWaypointRefApplicationTemplateFromJSON(json['application_template']),
        'actionCfgRefs': !exists(json, 'action_cfg_refs') ? undefined : (json['action_cfg_refs'].map(HashicorpCloudWaypointActionCfgRefFromJSON)),
        'variables': !exists(json, 'variables') ? undefined : (json['variables'].map(HashicorpCloudWaypointInputVariableFromJSON)),
    };
}
export function HashicorpCloudWaypointWaypointServiceCreateApplicationFromTemplateBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': value.namespace,
        'name': value.name,
        'application_template': HashicorpCloudWaypointRefApplicationTemplateToJSON(value.applicationTemplate),
        'action_cfg_refs': value.actionCfgRefs === undefined ? undefined : (value.actionCfgRefs.map(HashicorpCloudWaypointActionCfgRefToJSON)),
        'variables': value.variables === undefined ? undefined : (value.variables.map(HashicorpCloudWaypointInputVariableToJSON)),
    };
}
