import Component from '@glimmer/component';
import { assert } from '@ember/debug';

import {
  PEERING_ROUTE_NAMES,
  TGW_ROUTE_NAMES,
} from '../../../../utils/network-routes';

/**
 *
 * `NetworksCardsConnectionStatus` description here.
 *
 *
 * ```
 * <Networks::Cards::ConnectionStatus
 *   @state={{state}}
 *   @onDismiss={{function}}
 * />
 * ```
 *
 * @class NetworksCardsConnectionStatus
 *
 */

export default class NetworksCardsConnectionStatusComponent extends Component {
  /**
   * Peering/TGW connection state string. supported [ ACTIVE, FAILED, EXPIRED]
   * @argument state
   * @type {string}
   */
  /**
   * callback function to be call on dismissal icon click
   * @argument onDismiss
   * @type {Function}
   */

  buildOption(state) {
    let failed = {
      icon: 'alert-diamond',
      type: 'connection',
      color: 'critical',
      routes: [
        {
          route: PEERING_ROUTE_NAMES.create,
          i18n: 'components.networks.common.connection.peering-connection',
        },
        {
          route: TGW_ROUTE_NAMES.create,
          i18n: 'components.networks.common.connection.transit-gateway-attachment',
        },
      ],
    };
    switch (state) {
      case 'ACTIVE':
        return {
          icon: 'check-circle',
          type: 'cluster',
          color: 'success',
          status: state ? state.toLowerCase() : '',
          routes: [
            {
              route: 'vault.clusters.list',
              i18n: 'components.networks.common.product.vault',
            },
            {
              route: 'consul.clusters.overview',
              i18n: 'components.networks.common.product.consul',
            },
          ],
        };
      case 'FAILED':
        return {
          ...failed,
          status: state.toLowerCase(),
        };
      case 'EXPIRED':
        return {
          ...failed,
          status: state.toLowerCase(),
        };
      default:
        assert('Unsupported state string was passed.', state === undefined);
    }
  }

  get option() {
    return this.buildOption(this.args.state);
  }

  get isSuccess() {
    return this.args.state === 'ACTIVE';
  }
}
