import { helper } from '@ember/component/helper';
import { BILLING_ACCOUNT_STANDINGS } from '../constants/status';

const TRANSLATION_PREFIX = 'billing.common.tooltips.available-balance';

// the status for tooltip helper is a simplified version of the contract-status.js helper to conditionally render the available balance tooltip,
// depending on whether or not the contract we're rendering the tooltip for has a 'negative' available balance or a status of 'active', 'expired', or 'upcoming'
export function statusForTooltip([status, remainingBalance]) {
  if (remainingBalance <= 0) {
    return `${TRANSLATION_PREFIX}.negative`;
  }

  if (status === BILLING_ACCOUNT_STANDINGS.ACTIVE) {
    return `${TRANSLATION_PREFIX}.active`;
  }

  if (status === BILLING_ACCOUNT_STANDINGS.EXPIRED) {
    return `${TRANSLATION_PREFIX}.expired`;
  }

  return `${TRANSLATION_PREFIX}.upcoming`;
}

export default helper(statusForTooltip);
