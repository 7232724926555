/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - VERSION_UP_TO_DATE: VERSION_UP_TO_DATE is used when node is running the latest Vault Version.
 *  - UPGRADE_AVAILABLE: UPGRADE_AVAILABLE is used when node is running the latest minor release of a Vault Version, but there is a new major Vault version available for upgrade.
 *  - UPGRADE_RECOMMENDED: UPGRADE_RECOMMENDED is used when node is running an outdated but still supported version, but there is a new minor or major Vault versions available for upgrade.
 *  - UPGRADE_REQUIRED: UPGRADE_REQUIRED is used when node is running a no longer supported version and there are minor and major versions available for upgrade.
 * @export
 * @enum {string}
 */
export var HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatus;
(function (HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatus) {
    HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatus["LINKEDCLUSTERNODEVERSIONSTATUSINVALID"] = "LINKED_CLUSTER_NODE_VERSION_STATUS_INVALID";
    HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatus["VERSIONUPTODATE"] = "VERSION_UP_TO_DATE";
    HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatus["UPGRADEAVAILABLE"] = "UPGRADE_AVAILABLE";
    HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatus["UPGRADERECOMMENDED"] = "UPGRADE_RECOMMENDED";
    HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatus["UPGRADEREQUIRED"] = "UPGRADE_REQUIRED";
})(HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatus || (HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatus = {}));
export function HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatusFromJSON(json) {
    return HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatusFromJSONTyped(json, false);
}
export function HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatusToJSON(value) {
    return value;
}
