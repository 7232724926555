import {
  RESOURCE_TIER_SIZE_XS,
  RESOURCE_TIER_SIZE_SM,
  RESOURCE_TIER_SIZE_MD,
  RESOURCE_TIER_SIZE_LG,
} from '../constants/index.ts';

import type { BillingPackageOption } from '../types/api.ts';

const BILLING_SIZE_OPTION_KEY_ORDER = [
  RESOURCE_TIER_SIZE_XS,
  RESOURCE_TIER_SIZE_SM,
  RESOURCE_TIER_SIZE_MD,
  RESOURCE_TIER_SIZE_LG,
];

/** Comparator used to order an array of Billing package size options by option key. */
export default function compareSizeByKeyAsc(
  left: BillingPackageOption,
  right: BillingPackageOption,
) {
  const leftKey = left?.key;
  if (!leftKey) return 0;

  const rightKey = right?.key;
  if (!rightKey) return -1;

  return (
    BILLING_SIZE_OPTION_KEY_ORDER.indexOf(leftKey) -
    BILLING_SIZE_OPTION_KEY_ORDER.indexOf(rightKey)
  );
}
