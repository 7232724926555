/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudPacker20221202BucketAncestryFromJSON, HashicorpCloudPacker20221202BucketAncestryToJSON, } from './';
export function HashicorpCloudPacker20221202ListBucketAncestryResponseFromJSON(json) {
    return HashicorpCloudPacker20221202ListBucketAncestryResponseFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ListBucketAncestryResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'relations': !exists(json, 'relations') ? undefined : (json['relations'].map(HashicorpCloudPacker20221202BucketAncestryFromJSON)),
        'totalCount': !exists(json, 'total_count') ? undefined : json['total_count'],
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudPacker20221202ListBucketAncestryResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'relations': value.relations === undefined ? undefined : (value.relations.map(HashicorpCloudPacker20221202BucketAncestryToJSON)),
        'total_count': value.totalCount,
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
