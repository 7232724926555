/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudOperationOperationFromJSON, HashicorpCloudOperationOperationToJSON, } from './';
export function HashicorpCloudResourcemanagerProjectDeleteResponseFromJSON(json) {
    return HashicorpCloudResourcemanagerProjectDeleteResponseFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerProjectDeleteResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'operation': !exists(json, 'operation') ? undefined : HashicorpCloudOperationOperationFromJSON(json['operation']),
    };
}
export function HashicorpCloudResourcemanagerProjectDeleteResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'operation': HashicorpCloudOperationOperationToJSON(value.operation),
    };
}
