// Must match PricingModel enum in public API
// https://github.com/hashicorp/cloud-billing/blob/main/proto-public/hashicorp/cloud/billing_20201105/billing_account_public.proto
export const PRICING_MODEL = Object.freeze({
  PAYG: 'PRICING_MODEL_PAYG',
  FLEX: 'PRICING_MODEL_FLEX',
  ENTITLEMENT: 'PRICING_MODEL_ENTITLEMENT',
  UNSPECIFIED: 'PRICING_MODEL_UNSPECIFIED',
});

// Must match UpdateCause enum in public API
// https://github.com/hashicorp/cloud-billing/blob/main/proto-public/hashicorp/cloud/billing_20201105/contract_service.proto
export const UPDATE_CAUSES = Object.freeze({
  ADDON: 'ADDON',
  EARLY_RENEWAL: 'EARLY_RENEWAL',
});
