/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function InlineObject4FromJSON(json) {
    return InlineObject4FromJSONTyped(json, false);
}
export function InlineObject4FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'issuer': !exists(json, 'issuer') ? undefined : json['issuer'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'clientSecret': !exists(json, 'client_secret') ? undefined : json['client_secret'],
        'emailDomain': !exists(json, 'email_domain') ? undefined : json['email_domain'],
        'defaultRole': !exists(json, 'default_role') ? undefined : json['default_role'],
    };
}
export function InlineObject4ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'issuer': value.issuer,
        'client_id': value.clientId,
        'client_secret': value.clientSecret,
        'email_domain': value.emailDomain,
        'default_role': value.defaultRole,
    };
}
