/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudConsul20210204ClusterConfigFromJSON, HashicorpCloudConsul20210204ClusterConfigToJSON, HashicorpCloudConsul20210204ClusterDNSNamesFromJSON, HashicorpCloudConsul20210204ClusterDNSNamesToJSON, HashicorpCloudConsul20210204ClusterStateFromJSON, HashicorpCloudConsul20210204ClusterStateToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudConsul20210204ClusterFromJSON(json) {
    return HashicorpCloudConsul20210204ClusterFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204ClusterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'state': !exists(json, 'state') ? undefined : HashicorpCloudConsul20210204ClusterStateFromJSON(json['state']),
        'config': !exists(json, 'config') ? undefined : HashicorpCloudConsul20210204ClusterConfigFromJSON(json['config']),
        'dnsNames': !exists(json, 'dns_names') ? undefined : HashicorpCloudConsul20210204ClusterDNSNamesFromJSON(json['dns_names']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'consulVersion': !exists(json, 'consul_version') ? undefined : json['consul_version'],
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
    };
}
export function HashicorpCloudConsul20210204ClusterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'state': HashicorpCloudConsul20210204ClusterStateToJSON(value.state),
        'config': HashicorpCloudConsul20210204ClusterConfigToJSON(value.config),
        'dns_names': HashicorpCloudConsul20210204ClusterDNSNamesToJSON(value.dnsNames),
        'consul_version': value.consulVersion,
        'resource_id': value.resourceId,
    };
}
