import Component from '@glimmer/component';

/**
 *
 * `NetworksConnectionDetails` renders the details table for a Network connection.
 *
 *
 * ```
 * <Networks::ConnectionDetails
 *   @connection={{@model.peering}}
 * />
 *
 * <Networks::ConnectionDetails
 *   @connection={{@model.tgwAttachment}}
 * />
 * ```
 *
 * @class NetworksConnectionDetails
 *
 */

export default class NetworksConnectionDetailsComponent extends Component {
  /**
   * `connection` is an object for a connection type that comes from the cloud-network API.  It can either be a Peering or TGW Attachment
   * @argument connection
   * @type {object}
   */

  get isAwsPeering() {
    return Boolean(this.args.connection?.target?.awsTarget?.accountId);
  }

  get isAzurePeering() {
    return Boolean(this.args.connection?.target?.azureTarget?.tenantId);
  }

  // TODO: copied from connection-instructions, should be shared helper probably
  get isHvnHvnPeering() {
    return Boolean(this.args.connection?.target?.hvnTarget?.hvn);
  }

  get isTgw() {
    return Boolean(this.args.connection?.providerData?.awsData?.tgwId);
  }
}
