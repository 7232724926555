/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudIamOrganizationInvitationFromJSON, HashicorpCloudIamOrganizationInvitationToJSON, } from './';
export function HashicorpCloudIamListOrganizationInvitationsResponseFromJSON(json) {
    return HashicorpCloudIamListOrganizationInvitationsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudIamListOrganizationInvitationsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'invitations': !exists(json, 'invitations') ? undefined : (json['invitations'].map(HashicorpCloudIamOrganizationInvitationFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudIamListOrganizationInvitationsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'invitations': value.invitations === undefined ? undefined : (value.invitations.map(HashicorpCloudIamOrganizationInvitationToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
