/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointAddOnFromJSON, HashicorpCloudWaypointAddOnToJSON, } from './';
export function HashicorpCloudWaypointCreateAddOnResponseFromJSON(json) {
    return HashicorpCloudWaypointCreateAddOnResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointCreateAddOnResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'addOn': !exists(json, 'add_on') ? undefined : HashicorpCloudWaypointAddOnFromJSON(json['add_on']),
    };
}
export function HashicorpCloudWaypointCreateAddOnResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'add_on': HashicorpCloudWaypointAddOnToJSON(value.addOn),
    };
}
