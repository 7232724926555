/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * `NullValue` is a singleton enumeration to represent the null value for the
 * `Value` type union.
 *
 *  The JSON representation for `NullValue` is JSON `null`.
 *
 *  - NULL_VALUE: Null value.
 * @export
 * @enum {string}
 */
export var GoogleProtobufNullValue;
(function (GoogleProtobufNullValue) {
    GoogleProtobufNullValue["NULLVALUE"] = "NULL_VALUE";
})(GoogleProtobufNullValue || (GoogleProtobufNullValue = {}));
export function GoogleProtobufNullValueFromJSON(json) {
    return GoogleProtobufNullValueFromJSONTyped(json, false);
}
export function GoogleProtobufNullValueFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function GoogleProtobufNullValueToJSON(value) {
    return value;
}
