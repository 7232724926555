/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20230613GhRepoConnectionDetailsResponseFromJSON(json) {
    return Secrets20230613GhRepoConnectionDetailsResponseFromJSONTyped(json, false);
}
export function Secrets20230613GhRepoConnectionDetailsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'repository': !exists(json, 'repository') ? undefined : json['repository'],
        'environment': !exists(json, 'environment') ? undefined : json['environment'],
    };
}
export function Secrets20230613GhRepoConnectionDetailsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'owner': value.owner,
        'repository': value.repository,
        'environment': value.environment,
    };
}
