import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { dropTask } from 'ember-concurrency';
import { warn } from '@ember/debug';
import * as Sentry from '@sentry/ember';

import type OperationService from '../../services/operation.ts';
import type EnginesRouterService from 'ember-engines-router-service/services/router';
import type Route from '@ember/routing/route';

/**
 *
 * `OperationRefreshRoute` is a renderless component that encapsulates route
 * refresh behavior. It uses the operation service to determine if the `model`
 * arg has any related operations that have recently changed. If there are
 * recently changed operations, then the `route` arg will be looked up and `refresh`
 * will be called on it, causing any model hooks on the route to re-run and load
 * any updated data.
 *
 * If `routeFallback` was passed and the `refresh` call fails, the component will
 * attempt to transition to the `routeFallback`.
 *
 * This component is intended to be rendered only once per route/url unless you are
 * polling for different resource types. Rendering multiple instances of the component
 * on the same route can cause multiple network requests and race conditions during
 * acceptance testing.
 * ```
 * <Operation::RefreshRoute
 *   @route='consul.detail'
 *   @routeFallback='consul'
 *   @model={{@model.cluster}}
 *   @resourceType={{resource-type-for 'consul'}}
 * />
 * ```
 *
 * @class OperationRefreshRoute
 *
 */

const THRESHOLD = -5000;

interface OperationRefreshRouteSignature {
  Args: {
    route: string;
    routeFallback: string;
    model: Record<string, unknown> | Record<string, unknown>[];
    modelKey: string;
    resourceType: string;
  };
}

export default class OperationRefreshRouteComponent extends Component<OperationRefreshRouteSignature> {
  @service declare readonly operation: OperationService;
  @service declare readonly router: EnginesRouterService;

  /**
   * The `route` is the name of the route that will be refreshed if shouldRefresh is true.
   *
   * @argument route
   * @type {string}
   */

  /**
   * `routeFallback` is the name of a route to transition to if a refresh of `route`
   * fails.
   *
   * @argument routeFallback
   * @type {string}
   */

  /**
   * `model` is the object or list of objects to match against operation links
   *
   * @argument model
   * @type {array|object}
   */

  /**
   * `modelKey` is the name of id key in the model to allow for the event that the key is not
   * `id`
   * @argument modelKey
   * @type {string}
   */

  /*
   * `resourceType` should match the type that will be on the operation.link -
   * it is used to identify what operations are deemed relevant to the model.
   *
   * See the 'resource-type-for' helper for a convenient way to do this.
   *
   * @argument resourceType
   * @type {string}
   */

  /*
   * `refreshRoute` looks up the route class for the named `this.args.route`.
   * If found, the route's class will have `refresh` called on it, triggering
   * all of the route hooks to be invalidated and re-fetched.
   *
   * This effectively will update the data from the route's model hook,
   * updating the UI if any of that data has changed.
   *
   */
  @action
  refreshRoute() {
    if (this.isDestroyed || this.isDestroying) {
      warn(
        `Attempted to call refreshRoute() in <OperationRefreshRouteComponent> post-destruction`,
        {
          id: 'operation-refresh-route-component.refresh-route.called-after-destruction',
        }
      );
      return;
    }

    // Eagerly look up the router so we aren't later looking up the
    // service when the lifetime of the container may have expired.
    const router = this.router;
    const { route, routeFallback } = this.args;
    const container = getOwner(this);

    let fallbackUrl: unknown = null;
    if (routeFallback) {
      fallbackUrl = routeFallback.includes('/')
        ? routeFallback
        : this.router.urlFor(routeFallback);
    }

    try {
      const routeClass = container?.lookup(`route:${route}`) as Route;

      routeClass?.refresh().catch((e) => {
        if (this.isDestroyed || this.isDestroying) {
          warn(
            `Attempted to call refreshRoute() in <OperationRefreshRouteComponent> called in after refresh, post-destruction`,
            {
              id: 'operation-refresh-route-component.refresh-route.called-in-after-refresh-after-destruction',
            }
          );
          return;
        }

        // @ts-expect-error
        if (e.message === 'TransitionAborted') {
          return;
        }

        if (routeFallback) {
          // NOTE: The engine router service doesn't like routing to urls, so here we
          //       check if there's an externalRouter and if so, use it instead
          // @ts-expect-error
          return router.externalRouter
            ? // @ts-expect-error
              router.externalRouter.transitionTo(fallbackUrl)
            : router.transitionTo(fallbackUrl);
        } else {
          warn(
            `Could not call refreshRoute() in <OperationRefreshRouteComponent> on route ${route} ...`,
            {
              id: 'operation-refresh-route-component.refresh-route.failed',
            }
          );
          throw e;
        }
      });
    } catch (refreshRouteException) {
      Sentry.captureException(refreshRouteException);
    }
  }

  get modelIds() {
    return (
      [this.args.model]
        .flatMap((arr) => arr)
        // @ts-expect-error
        .map((m) => m[this.args.modelKey || 'id'])
    );
  }

  // `waitToRefesh` is an ember-concurrency task that calls the operation service
  // to wait for a single operation.
  //
  // only one instance of the task can run at a time, and additional calls to
  // start new instances will be ignored (that's what `dropTask` is doing here)
  @dropTask
  *waitToRefresh(operationId: string) {
    yield this.operation.waitFor(operationId);
    this.refreshRoute();
  }

  // called on `will-destroy` of the template, this method will cancel running
  // tasks, which in turn will cancel any in-flight calls to the `wait` endpoint
  @action
  stopWatchOperation() {
    this.operation.cancelWait();
    // @ts-expect-error
    this.waitToRefresh.cancelAll();
  }

  // watchOperation gets called whenver this.operation.operations changes
  // it will also call `checkForChangedOperations`.
  //
  // if `@model` is a single item, a wait request for the operation will be started
  @action
  watchOperation() {
    const { modelIds } = this;
    const modelId = modelIds[0];

    // even though we plan to wait, if an operation changes state, we'll want
    // to trigger a model re-fetch if an operation changes
    this.checkForChangedOperations();
    // only proceed with the wait for single operations
    if (modelIds.length > 1) {
      return;
    }

    const op = this.operation.operations.find((op) => {
      if (
        op.state === 'DONE' ||
        !op.link ||
        op.link.id !== modelId ||
        op.link.type !== this.args.resourceType ||
        !op.createdAt
      ) {
        return;
      }
      // creation operations create the resource, so we need a bit of leeway
      // -5000 will allow an operation created up to 5 seconds before the resource to be considered a recent operation
      const isRecentOperation =
        // @ts-expect-error
        +op.createdAt - +this.args.model.createdAt > THRESHOLD;

      if (isRecentOperation) {
        return op;
      }
    });

    if (!op) {
      return;
    }

    // @ts-expect-error
    this.waitToRefresh.perform(op.id);
  }

  /*
   *
   * If there are changed operations related to the passed `model` arg, then
   * `refreshRoute` will be called - otherwise the method returns early
   */
  checkForChangedOperations() {
    const { modelIds } = this;
    // this is the first filling of the operations service so we want
    // to skip it causing a refresh
    if (this.operation.firstFetch) {
      return;
    }
    // relatedOperations are any changedOperations from the operations service
    // where the link's id and type matches one of the current models' id and the passed resourceType
    const relatedOperations = this.operation.changedOperations.filter((op) => {
      const isRelated = op.link
        ? this.args.resourceType === op.link.type &&
          modelIds.includes(op.link.id)
        : false;
      // if there's only one model to watch, then DONE operations are being waited for by the waitToRefresh so we can exclude them here
      // @ts-expect-error
      if (modelIds.length === 1 && this.waitToRefresh.isRunning) {
        return op.state !== 'DONE' && isRelated;
      }
      return isRelated;
    });

    if (!relatedOperations.length) {
      return;
    }
    this.refreshRoute();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operation::RefreshRoute': typeof OperationRefreshRouteComponent;
    'operation/refresh-route': typeof OperationRefreshRouteComponent;
  }
}
