export const DEFAULT_VARIANT = '';
export const DEFAULT_VARIANT_MAPPING: {
  [key: string]: { headerIconType: string };
} = {
  create: {
    headerIconType: 'plus-square',
  },
  delete: {
    headerIconType: 'alert-triangle',
  },
  edit: {
    headerIconType: 'edit',
  },
  error: {
    headerIconType: 'x-square-fill',
  },
  restore: {
    headerIconType: 'history',
  },
};
export const VARIANT_SCALE = Object.keys(DEFAULT_VARIANT_MAPPING);
