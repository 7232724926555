/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215ServerTLSMetadataFromJSON, HashicorpCloudGlobalNetworkManager20220215ServerTLSMetadataToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ServerFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ServerFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ServerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'lanAddress': !exists(json, 'lan_address') ? undefined : json['lan_address'],
        'gossipPort': !exists(json, 'gossip_port') ? undefined : json['gossip_port'],
        'rpcPort': !exists(json, 'rpc_port') ? undefined : json['rpc_port'],
        'minQuorum': !exists(json, 'min_quorum') ? undefined : json['min_quorum'],
        'tlsEnabled': !exists(json, 'tls_enabled') ? undefined : json['tls_enabled'],
        'tlsExpiry': !exists(json, 'tls_expiry') ? undefined : (new Date(json['tls_expiry'])),
        'scadaStatus': !exists(json, 'scada_status') ? undefined : json['scada_status'],
        'failureTolerance': !exists(json, 'failure_tolerance') ? undefined : json['failure_tolerance'],
        'serverTls': !exists(json, 'server_tls') ? undefined : HashicorpCloudGlobalNetworkManager20220215ServerTLSMetadataFromJSON(json['server_tls']),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ServerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'id': value.id,
        'version': value.version,
        'lan_address': value.lanAddress,
        'gossip_port': value.gossipPort,
        'rpc_port': value.rpcPort,
        'min_quorum': value.minQuorum,
        'tls_enabled': value.tlsEnabled,
        'tls_expiry': value.tlsExpiry === undefined ? undefined : (value.tlsExpiry.toISOString()),
        'scada_status': value.scadaStatus,
        'failure_tolerance': value.failureTolerance,
        'server_tls': HashicorpCloudGlobalNetworkManager20220215ServerTLSMetadataToJSON(value.serverTls),
    };
}
