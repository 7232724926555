/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIamServicePrincipalKeyFromJSON, HashicorpCloudIamServicePrincipalKeyToJSON, } from './';
export function HashicorpCloudIamCreateProjectServicePrincipalKeyResponseFromJSON(json) {
    return HashicorpCloudIamCreateProjectServicePrincipalKeyResponseFromJSONTyped(json, false);
}
export function HashicorpCloudIamCreateProjectServicePrincipalKeyResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !exists(json, 'key') ? undefined : HashicorpCloudIamServicePrincipalKeyFromJSON(json['key']),
        'clientSecret': !exists(json, 'client_secret') ? undefined : json['client_secret'],
    };
}
export function HashicorpCloudIamCreateProjectServicePrincipalKeyResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': HashicorpCloudIamServicePrincipalKeyToJSON(value.key),
        'client_secret': value.clientSecret,
    };
}
