/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * HttpProxyOption specifies whether a cluster has HTTP Proxy enabled or disabled.
 * We use an enum instead of bool for readability and extensibility.
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125HttpProxyOption;
(function (HashicorpCloudVault20201125HttpProxyOption) {
    HashicorpCloudVault20201125HttpProxyOption["HTTPPROXYOPTIONINVALID"] = "HTTP_PROXY_OPTION_INVALID";
    HashicorpCloudVault20201125HttpProxyOption["ENABLED"] = "ENABLED";
    HashicorpCloudVault20201125HttpProxyOption["DISABLED"] = "DISABLED";
})(HashicorpCloudVault20201125HttpProxyOption || (HashicorpCloudVault20201125HttpProxyOption = {}));
export function HashicorpCloudVault20201125HttpProxyOptionFromJSON(json) {
    return HashicorpCloudVault20201125HttpProxyOptionFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125HttpProxyOptionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125HttpProxyOptionToJSON(value) {
    return value;
}
