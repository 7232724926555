import type { IamEnrichedRole } from '../types/iam-enriched-role.ts';

export default function iamFindServicesFromRoles(
  roles: Array<IamEnrichedRole>,
): Array<string> {
  const services = roles.reduce((acc, role) => {
    if (role.service) {
      acc.add(role.service);
    }
    return acc;
  }, new Set<string>());
  return Array.from(services);
}
