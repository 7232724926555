/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Webhook Service
 * API for delivering and managing webhooks.
 *
 * The version of the OpenAPI document: 2023-05-31
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWebhookWebhookConfigFromJSON, HashicorpCloudWebhookWebhookConfigToJSON, HashicorpCloudWebhookWebhookPayloadFromJSON, HashicorpCloudWebhookWebhookPayloadToJSON, HashicorpCloudWebhookWebhookSubscriptionFromJSON, HashicorpCloudWebhookWebhookSubscriptionToJSON, } from './';
export function HashicorpCloudWebhookCreateWebhookRequestBodyFromJSON(json) {
    return HashicorpCloudWebhookCreateWebhookRequestBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWebhookCreateWebhookRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'config': !exists(json, 'config') ? undefined : HashicorpCloudWebhookWebhookConfigFromJSON(json['config']),
        'subscriptions': !exists(json, 'subscriptions') ? undefined : (json['subscriptions'].map(HashicorpCloudWebhookWebhookSubscriptionFromJSON)),
        'testPayload': !exists(json, 'test_payload') ? undefined : HashicorpCloudWebhookWebhookPayloadFromJSON(json['test_payload']),
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
    };
}
export function HashicorpCloudWebhookCreateWebhookRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'config': HashicorpCloudWebhookWebhookConfigToJSON(value.config),
        'subscriptions': value.subscriptions === undefined ? undefined : (value.subscriptions.map(HashicorpCloudWebhookWebhookSubscriptionToJSON)),
        'test_payload': HashicorpCloudWebhookWebhookPayloadToJSON(value.testPayload),
        'enabled': value.enabled,
    };
}
