import Component from '@glimmer/component';
import { flattenNetworkOptionGroups } from '../../../utils/hvn-select-network-options.js';

interface HvnSelectKeyboardCuesSignature {
  Args: {
    select?: {
      options?: unknown[];
    };
  };
}

export default class FormInputsHvnSelectKeyboardCuesComponent extends Component<HvnSelectKeyboardCuesSignature> {
  get numNetworkOptions() {
    const { options: networkOptions = [] } = this.args?.select || {};

    const flattenedNetworkOptions = networkOptions.reduce(
      flattenNetworkOptionGroups,
      []
    );

    return flattenedNetworkOptions.length;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormInputs::HvnSelect::KeyboardCues': typeof FormInputsHvnSelectKeyboardCuesComponent;
    'form-inputs/hvn-select/keyboard-cues': typeof FormInputsHvnSelectKeyboardCuesComponent;
  }
}
