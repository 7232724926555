import Component from '@glimmer/component';

interface StepperFormTabSignature {
  Args: {
    available: boolean;
    complete: boolean;
    currentStep: number;
    onTabChange: () => void;
    step: number;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLButtonElement;
}

/**
 * `StepperForm::Tab is meant to be used as a contextual component yielded
 * by `StepperForm`. It is the navigation element of the form and the parent
 * `StepperForm` manages as much of the `Tab` state as possible.
 *
 * @class StepperForm::Tab
 */
export default class StepperFormTabComponent extends Component<StepperFormTabSignature> {
  /**
   * `@step` The numeric placement of this step in the stepper form.
   *
   * @argument @step
   * @type {Number}
   */

  /** `@currentStep` The current step the stepper form is on (typically set in
   * StepperForm on the contextual component)
   *
   * @argument @currentStep
   * @type {Number}
   */

  /** `@complete` Whether or not the step in the form has been completed.
   *
   * @argument @complete
   * @type {Boolean}
   * @default false
   */

  /** `@available` Whether or not the step can be visited directly from the tab.
   *
   * @argument @complete
   * @type {Boolean}
   * @default false
   */

  get isActive() {
    return +this.args.step === +this.args.currentStep;
  }

  get icon() {
    if (this.isActive) {
      return this.args.complete ? 'check-hexagon-fill' : 'hexagon-fill';
    }
    return this.args.complete ? 'check-hexagon' : 'hexagon';
  }

  get step() {
    return this.args.step ?? parseInt(this.args.step, 10);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'StepperForm::Tab': typeof StepperFormTabComponent;
    'stepper-form/tab': typeof StepperFormTabComponent;
  }
}
