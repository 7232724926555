/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudGlobalNetworkManager20220215ServiceFromJSON, HashicorpCloudGlobalNetworkManager20220215ServiceToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ListServicesResponseFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ListServicesResponseFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ListServicesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
        'data': !exists(json, 'data') ? undefined : (json['data'].map(HashicorpCloudGlobalNetworkManager20220215ServiceFromJSON)),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ListServicesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
        'data': value.data === undefined ? undefined : (value.data.map(HashicorpCloudGlobalNetworkManager20220215ServiceToJSON)),
    };
}
