import templateOnlyComponent from '@ember/component/template-only';

interface CoreLayoutMainSignature {
  // Args: {};
  Blocks: {
    alert: [];
    body: [];
    generic: [];
    header: [];
  };
  Element: HTMLDivElement;
}

const CoreLayoutMainComponent =
  templateOnlyComponent<CoreLayoutMainSignature>();

export default CoreLayoutMainComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::Layout::Main': typeof CoreLayoutMainComponent;
    'core/layout/main': typeof CoreLayoutMainComponent;
  }
}
