/*****************************************************************************
 *                                                                           *
 *              This file is owned by HCP's Cloud SRE Team                   *
 *              ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~                  *
 *                                                                           *
 *  Updating the component IDs listed for HCP and each Hashi product below   *
 *  will effect the "System Status" indicator link (in the footer).        *
 *                                                                           *
 *  Please note the logic to roll up the listed IDs when making changes:     *
 *  1. When NONE of the listed IDs are found, no status will be shown        *
 *     (an unstyled link will to the statuspage will still be displayed)     *
 *  2. When MIXED statuses are found, always the worst / most severe         *
 *     component status will be displayed                                    *
 *  3. When ALL SAME status are found, that status will be displayed         *
 *                                                                           *
 *  When updating the `baseUrl` for any environment, please remember to:     *
 *  -  Also update the Content Security Policy (CSP) to match                *
 *     (SEE: /hcp/config/content-security-policy.js)                         *
 *  -  Always make sure the `jsonApiUrl` begins with the `baseUrl`           *
 *                                                                           *
 *****************************************************************************/

/**
 * @typedef {HcpStatuspageConfig}
 * @property {String} baseUrl - base URL for the statuspage, also used for Cloud UI's Content Security Policy (CSP)
 * @property {String} jsonApiUrl - full URL for the statuspage's v2 JSON API endpoint (must match `baseUrl`)
 * @property {Array.<String>} hcpComponentIds - HCP component IDs to roll status up from the statuspage's v2 JSON API data
 * @property {Array.<String>} consulComponentIds - Consul component IDs to roll status up from the statuspage's v2 JSON API data
 * @property {Array.<String>} packerComponentIds - Packer component IDs to roll status up from the statuspage's v2 JSON API data
 * @property {Array.<String>} vaultComponentIds - Vault component IDs to roll status up from the statuspage's v2 JSON API data
 */

/**
 * @type {HcpStatuspageConfig}
 * @see https://manage.statuspage.io/pages/pdrzb3d64wsj/components
 */
export const PRODUCTION = {
  baseUrl: 'https://status.hashicorp.com',
  jsonApiUrl: 'https://status.hashicorp.com/api/v2/components.json',
  hcpComponentIds: [
    'mgv1p2j9x444', // HCP Portal
    '0q55nwmxngkc', // HCP API
    'sxffkgfb4fhb', // HCP Consul
    '0mbkqnrzg33w', // HCP Packer
    'mb7xrbx9gjnq', // Vault Dedicated
    '9gzfhnxdsf93', // HCP Boundary
  ],
  consulComponentIds: [
    'sxffkgfb4fhb', // HCP Consul
  ],
  packerComponentIds: [
    '0mbkqnrzg33w', // HCP Packer
  ],
  vaultComponentIds: [
    'mb7xrbx9gjnq', // Vault Dedicated
  ],
};

/**
 * @type {HcpStatuspageConfig}
 * @see https://manage.statuspage.io/pages/qfc0qrz001tz/components
 */
export const NON_PRODUCTION = {
  baseUrl: 'https://int.status.hashicorp.com.hashicorp.services',
  jsonApiUrl:
    'https://int.status.hashicorp.com.hashicorp.services/api/v2/components.json',
  hcpComponentIds: [
    'y0vtt6qz9r5v', // HCP Portal
    'kwc1s2vpyh8m', // HCP API
    '7gh6r81dwf9h', // HCP Consul
    '7rnt7s7b0t2s', // HCP Packer
    '5sw17s8kbt05', // Vault Dedicated
    '9y03yz3kgnc6', // HCP Boundary
  ],
  consulComponentIds: [
    '7gh6r81dwf9h', // HCP Consul
  ],
  packerComponentIds: [
    '7rnt7s7b0t2s', // HCP Packer
  ],
  vaultComponentIds: [
    '5sw17s8kbt05', // Vault Dedicated
  ],
};

export default {
  PRODUCTION,
  NON_PRODUCTION, // NOTE: Used in development (https://portal.hcp.dev), and/or integration (https://portal.hcp.to)
};
