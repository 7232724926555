/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202ModelsRegistryBillingDeprovisionReasonFromJSON, HashicorpCloudPacker20221202ModelsRegistryBillingDeprovisionReasonToJSON, } from './';
export function HashicorpCloudPacker20221202ModelsRegistryBillingDeprovisionFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsRegistryBillingDeprovisionFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsRegistryBillingDeprovisionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'at': !exists(json, 'at') ? undefined : (new Date(json['at'])),
        'reason': !exists(json, 'reason') ? undefined : HashicorpCloudPacker20221202ModelsRegistryBillingDeprovisionReasonFromJSON(json['reason']),
    };
}
export function HashicorpCloudPacker20221202ModelsRegistryBillingDeprovisionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'at': value.at === undefined ? undefined : (value.at.toISOString()),
        'reason': HashicorpCloudPacker20221202ModelsRegistryBillingDeprovisionReasonToJSON(value.reason),
    };
}
