/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20230613AzureKvConnectionDetailsRequestFromJSON(json) {
    return Secrets20230613AzureKvConnectionDetailsRequestFromJSONTyped(json, false);
}
export function Secrets20230613AzureKvConnectionDetailsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'keyVaultUri': !exists(json, 'key_vault_uri') ? undefined : json['key_vault_uri'],
        'tenantId': !exists(json, 'tenant_id') ? undefined : json['tenant_id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'clientSecret': !exists(json, 'client_secret') ? undefined : json['client_secret'],
    };
}
export function Secrets20230613AzureKvConnectionDetailsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key_vault_uri': value.keyVaultUri,
        'tenant_id': value.tenantId,
        'client_id': value.clientId,
        'client_secret': value.clientSecret,
    };
}
