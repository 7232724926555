/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointAgentOperationFromJSON(json) {
    return HashicorpCloudWaypointAgentOperationFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointAgentOperationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'actionRunId': !exists(json, 'action_run_id') ? undefined : json['action_run_id'],
        'group': !exists(json, 'group') ? undefined : json['group'],
    };
}
export function HashicorpCloudWaypointAgentOperationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'body': value.body,
        'action_run_id': value.actionRunId,
        'group': value.group,
    };
}
