/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202RegistryConfigTier;
(function (HashicorpCloudPacker20221202RegistryConfigTier) {
    HashicorpCloudPacker20221202RegistryConfigTier["UNSET"] = "UNSET";
    HashicorpCloudPacker20221202RegistryConfigTier["PRIVATEBETA"] = "PRIVATE_BETA";
    HashicorpCloudPacker20221202RegistryConfigTier["STANDARD"] = "STANDARD";
    HashicorpCloudPacker20221202RegistryConfigTier["PLUS"] = "PLUS";
})(HashicorpCloudPacker20221202RegistryConfigTier || (HashicorpCloudPacker20221202RegistryConfigTier = {}));
export function HashicorpCloudPacker20221202RegistryConfigTierFromJSON(json) {
    return HashicorpCloudPacker20221202RegistryConfigTierFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202RegistryConfigTierFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202RegistryConfigTierToJSON(value) {
    return value;
}
