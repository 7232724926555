/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudResourcemanagerResourceIDResourceType;
(function (HashicorpCloudResourcemanagerResourceIDResourceType) {
    HashicorpCloudResourcemanagerResourceIDResourceType["UNKNOWN"] = "UNKNOWN";
    HashicorpCloudResourcemanagerResourceIDResourceType["ORGANIZATION"] = "ORGANIZATION";
    HashicorpCloudResourcemanagerResourceIDResourceType["PROJECT"] = "PROJECT";
})(HashicorpCloudResourcemanagerResourceIDResourceType || (HashicorpCloudResourcemanagerResourceIDResourceType = {}));
export function HashicorpCloudResourcemanagerResourceIDResourceTypeFromJSON(json) {
    return HashicorpCloudResourcemanagerResourceIDResourceTypeFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerResourceIDResourceTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudResourcemanagerResourceIDResourceTypeToJSON(value) {
    return value;
}
