/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudResourcemanagerResourceIDResourceTypeFromJSON, HashicorpCloudResourcemanagerResourceIDResourceTypeToJSON, } from './';
export function HashicorpCloudResourcemanagerResourceIDFromJSON(json) {
    return HashicorpCloudResourcemanagerResourceIDFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerResourceIDFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !exists(json, 'type') ? undefined : HashicorpCloudResourcemanagerResourceIDResourceTypeFromJSON(json['type']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}
export function HashicorpCloudResourcemanagerResourceIDToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': HashicorpCloudResourcemanagerResourceIDResourceTypeToJSON(value.type),
        'id': value.id,
    };
}
