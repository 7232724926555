import UserAgentParser from 'ua-parser-js';

/**
 * Formats a given user-agent string to be human readable.
 *
 * NOTE: Does not support localization yet ...
 *       We'll need to wrap the values from ua-parser-js if/when we
 *       ever decide to support locales aside from EN-US.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent
 * @see https://github.com/faisalman/ua-parser-js#readme
 */
export default function formatUserAgentString(userAgent) {
  if (!userAgent || typeof userAgent !== 'string') return '-';

  const parsed = new UserAgentParser(userAgent).getResult();
  const { device, browser, os } = parsed;

  const deviceDisplayText = device?.type
    ? device.type.charAt(0).toUpperCase() + device.type.slice(1)
    : '';

  let browserDisplayText = '';
  if (browser?.name) browserDisplayText += browser.name;

  let osDisplayText = '';
  if (os?.name) {
    osDisplayText += os.name;
    if (os?.version) osDisplayText += ` ${os.version}`;
  }

  if (!deviceDisplayText && !browserDisplayText && !osDisplayText) return '-';

  let displayText = '';
  displayText += deviceDisplayText ? `${deviceDisplayText}, ` : '';
  displayText += browserDisplayText ? browserDisplayText : '';
  displayText += osDisplayText ? ` on ${osDisplayText}` : '';

  return displayText;
}
