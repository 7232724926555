/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105BillingAccountStatusFromJSON, Billing20201105BillingAccountStatusToJSON, Billing20201105CountryFromJSON, Billing20201105CountryToJSON, Billing20201105PricingModelFromJSON, Billing20201105PricingModelToJSON, BillingAccountOnDemandStatusFromJSON, BillingAccountOnDemandStatusToJSON, } from './';
export function Billing20201105BillingAccountFromJSON(json) {
    return Billing20201105BillingAccountFromJSONTyped(json, false);
}
export function Billing20201105BillingAccountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'projectIds': !exists(json, 'project_ids') ? undefined : json['project_ids'],
        'status': !exists(json, 'status') ? undefined : Billing20201105BillingAccountStatusFromJSON(json['status']),
        'balance': !exists(json, 'balance') ? undefined : json['balance'],
        'country': !exists(json, 'country') ? undefined : Billing20201105CountryFromJSON(json['country']),
        'onDemandStatus': !exists(json, 'on_demand_status') ? undefined : BillingAccountOnDemandStatusFromJSON(json['on_demand_status']),
        'pricingModel': !exists(json, 'pricing_model') ? undefined : Billing20201105PricingModelFromJSON(json['pricing_model']),
        'estimatedRemainingCredits': !exists(json, 'estimated_remaining_credits') ? undefined : json['estimated_remaining_credits'],
    };
}
export function Billing20201105BillingAccountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'organization_id': value.organizationId,
        'name': value.name,
        'project_ids': value.projectIds,
        'status': Billing20201105BillingAccountStatusToJSON(value.status),
        'balance': value.balance,
        'country': Billing20201105CountryToJSON(value.country),
        'on_demand_status': BillingAccountOnDemandStatusToJSON(value.onDemandStatus),
        'pricing_model': Billing20201105PricingModelToJSON(value.pricingModel),
        'estimated_remaining_credits': value.estimatedRemainingCredits,
    };
}
