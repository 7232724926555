/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { Billing20201105EstimateCostResponseFromJSON, Billing20201105ListAvailablePackagesResponseFromJSON, ProductServiceEstimateCostBodyToJSON, } from '../models';
/**
 *
 */
export class ProductServiceApi extends runtime.BaseAPI {
    /**
     * EstimateCost returns hourly and monthly pricing information for a given package for the given resource type.
     */
    async productServiceEstimateCostRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling productServiceEstimateCost.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling productServiceEstimateCost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/estimateCost`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductServiceEstimateCostBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105EstimateCostResponseFromJSON(jsonValue));
    }
    /**
     * EstimateCost returns hourly and monthly pricing information for a given package for the given resource type.
     */
    async productServiceEstimateCost(organizationId, body) {
        const response = await this.productServiceEstimateCostRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     */
    async productServiceListAvailablePackagesRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.organizationId !== undefined) {
            queryParameters['organization_id'] = requestParameters.organizationId;
        }
        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }
        if (requestParameters.resourceType !== undefined) {
            queryParameters['resource_type'] = requestParameters.resourceType;
        }
        if (requestParameters.regionProvider !== undefined) {
            queryParameters['region.provider'] = requestParameters.regionProvider;
        }
        if (requestParameters.regionRegion !== undefined) {
            queryParameters['region.region'] = requestParameters.regionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/packages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105ListAvailablePackagesResponseFromJSON(jsonValue));
    }
    /**
     */
    async productServiceListAvailablePackages(organizationId, projectId, resourceType, regionProvider, regionRegion) {
        const response = await this.productServiceListAvailablePackagesRaw({ organizationId: organizationId, projectId: projectId, resourceType: resourceType, regionProvider: regionProvider, regionRegion: regionRegion });
        return await response.value();
    }
}
