/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { Secrets20231128CapabilityFromJSON, Secrets20231128CapabilityToJSON, Secrets20231128GcpFederatedWorkloadIdentityResponseFromJSON, Secrets20231128GcpFederatedWorkloadIdentityResponseToJSON, Secrets20231128GcpServiceAccountKeyResponseFromJSON, Secrets20231128GcpServiceAccountKeyResponseToJSON, Secrets20231128IntegrationUsageFromJSON, Secrets20231128IntegrationUsageToJSON, } from './';
export function Secrets20231128GcpIntegrationFromJSON(json) {
    return Secrets20231128GcpIntegrationFromJSONTyped(json, false);
}
export function Secrets20231128GcpIntegrationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'updatedById': !exists(json, 'updated_by_id') ? undefined : json['updated_by_id'],
        'federatedWorkloadIdentity': !exists(json, 'federated_workload_identity') ? undefined : Secrets20231128GcpFederatedWorkloadIdentityResponseFromJSON(json['federated_workload_identity']),
        'serviceAccountKey': !exists(json, 'service_account_key') ? undefined : Secrets20231128GcpServiceAccountKeyResponseFromJSON(json['service_account_key']),
        'usedBy': !exists(json, 'used_by') ? undefined : (mapValues(json['used_by'], Secrets20231128IntegrationUsageFromJSON)),
        'capabilities': !exists(json, 'capabilities') ? undefined : (json['capabilities'].map(Secrets20231128CapabilityFromJSON)),
        'resourceName': !exists(json, 'resource_name') ? undefined : json['resource_name'],
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
    };
}
export function Secrets20231128GcpIntegrationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'created_by_id': value.createdById,
        'updated_by_id': value.updatedById,
        'federated_workload_identity': Secrets20231128GcpFederatedWorkloadIdentityResponseToJSON(value.federatedWorkloadIdentity),
        'service_account_key': Secrets20231128GcpServiceAccountKeyResponseToJSON(value.serviceAccountKey),
        'used_by': value.usedBy === undefined ? undefined : (mapValues(value.usedBy, Secrets20231128IntegrationUsageToJSON)),
        'capabilities': value.capabilities === undefined ? undefined : (value.capabilities.map(Secrets20231128CapabilityToJSON)),
        'resource_name': value.resourceName,
        'resource_id': value.resourceId,
    };
}
