import templateOnlyComponent from '@ember/component/template-only';

interface TestAppFrameSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

const TestAppFrameComponent = templateOnlyComponent<TestAppFrameSignature>();

export default TestAppFrameComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TestAppFrame: typeof TestAppFrameComponent;
    'test-app-frame': typeof TestAppFrameComponent;
  }
}
