import BaseAbility from './base-ability';
import {
  ACTION_DISABLE,
  ACTION_ENABLE,
  ACTION_GET,
  PREFIX_IDP_SCIM,
} from '../utils/permission-types/index';
import { variation } from 'ember-launch-darkly';

export default class extends BaseAbility {
  prefix = PREFIX_IDP_SCIM;

  /** @return {boolean} */
  get canDisable() {
    return (
      variation('hcp-identity-scim-enabled') &&
      this.permissions.has(this.generate(ACTION_DISABLE))
    );
  }

  /** @return {boolean} */
  get canEnable() {
    return (
      variation('hcp-identity-scim-enabled') &&
      this.permissions.has(this.generate(ACTION_ENABLE))
    );
  }

  /** @return {boolean} */
  get canGet() {
    return (
      variation('hcp-identity-scim-enabled') &&
      this.permissions.has(this.generate(ACTION_GET))
    );
  }
}
