// for context about the decision to use these values, see:
// https://hashicorp.slack.com/archives/C03A0N1QK8S/p1673546329082759
import { DEFAULT_PAGE_SIZES as HDS_DEFAULT_PAGE_SIZES } from '@hashicorp/design-system-components/components/hds/pagination/numbered/index';

export const DEFAULT_CURRENT_PAGE: number = 1;
export const DEFAULT_CURRENT_PAGE_SIZE: number =
  HDS_DEFAULT_PAGE_SIZES[0] || 10;
export const DEFAULT_PAGE_SIZES: Array<number> = HDS_DEFAULT_PAGE_SIZES || [
  DEFAULT_CURRENT_PAGE_SIZE,
  30,
  50,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Items = Array<any>;
export type Options = { currentPage?: number; currentPageSize?: number };

export default function iamPaginateArray(
  items: Items,
  {
    currentPage = DEFAULT_CURRENT_PAGE,
    currentPageSize = DEFAULT_CURRENT_PAGE_SIZE,
  }: Options,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Array<any> {
  const start = (currentPage - 1) * currentPageSize;
  const end = start + currentPageSize;
  return items.slice(start, end);
}
