import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import type { IntlService } from 'ember-intl';

interface MaintenanceModeOfflineServiceSignature {
  Args: {
    serviceName: unknown;
  };
  Element: HTMLDivElement;
}

/**
 *
 * `MaintenanceModeOfflineService` renders a zero-state empty message to
 * communicate that a message that is offline. This component is used at the
 * root of a service's ember in the application.hbs.
 * ```
 * <MaintenanceMode::OfflineService
 *   @serviceName={{'Vault'}}
 * />
 * ```
 *
 * @class MaintenanceModeOfflineServiceComponent
 *
 */

export default class MaintenanceModeOfflineServiceComponent extends Component<MaintenanceModeOfflineServiceSignature> {
  @service declare readonly intl: IntlService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MaintenanceMode::OfflineService': typeof MaintenanceModeOfflineServiceComponent;
    'maintenance-mode/offline-service': typeof MaintenanceModeOfflineServiceComponent;
  }
}
