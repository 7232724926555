/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20231128AwsAccessKeysRequestFromJSON(json) {
    return Secrets20231128AwsAccessKeysRequestFromJSONTyped(json, false);
}
export function Secrets20231128AwsAccessKeysRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accessKeyId': !exists(json, 'access_key_id') ? undefined : json['access_key_id'],
        'secretAccessKey': !exists(json, 'secret_access_key') ? undefined : json['secret_access_key'],
    };
}
export function Secrets20231128AwsAccessKeysRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'access_key_id': value.accessKeyId,
        'secret_access_key': value.secretAccessKey,
    };
}
