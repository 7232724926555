import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class FormatUtcDate extends Helper {
  /**
   * Formats a date as UTC in a repeatable fashion
   * @param {string} args - 0th element of args must be a non-empty date as a string.
   * @param {object} additionalArgs.formatOpts - The Intl.DateTimeFormat formatOpts.
   * @param {boolean} additionalArgs.withTime - boolean that determines if time should be included in the return formatted date
   * @returns {Date} - The formatted UTC date
   */

  @service intl;

  compute(
    [date],
    {
      formatOpts = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'UTC',
      },
      withTime = false,
    }
  ) {
    if (withTime) {
      formatOpts = {
        ...formatOpts,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      };
    }
    return this.intl.formatDate(date, formatOpts);
  }
}
