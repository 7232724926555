import templateOnlyComponent from '@ember/component/template-only';

interface ChartsPercentageBarSubtitleSignature {
  Args: {
    state: unknown;
  };
}

const ChartsPercentageBarSubtitleComponent =
  templateOnlyComponent<ChartsPercentageBarSubtitleSignature>();

export default ChartsPercentageBarSubtitleComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Charts::PercentageBar::Subtitle': typeof ChartsPercentageBarSubtitleComponent;
    'charts/percentage-bar/subtitle': typeof ChartsPercentageBarSubtitleComponent;
  }
}
