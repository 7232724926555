/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudConsulTelemetry20230414HttpMetricsFromJSON, HashicorpCloudConsulTelemetry20230414HttpMetricsToJSON, HashicorpCloudConsulTelemetry20230414IntentionsFromJSON, HashicorpCloudConsulTelemetry20230414IntentionsToJSON, HashicorpCloudConsulTelemetry20230414TcpMetricsFromJSON, HashicorpCloudConsulTelemetry20230414TcpMetricsToJSON, } from './';
export function HashicorpCloudConsulTelemetry20230414ServiceTopologyEdgeMetadataFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414ServiceTopologyEdgeMetadataFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414ServiceTopologyEdgeMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tcpMetrics': !exists(json, 'tcp_metrics') ? undefined : HashicorpCloudConsulTelemetry20230414TcpMetricsFromJSON(json['tcp_metrics']),
        'httpMetrics': !exists(json, 'http_metrics') ? undefined : HashicorpCloudConsulTelemetry20230414HttpMetricsFromJSON(json['http_metrics']),
        'intentions': !exists(json, 'intentions') ? undefined : HashicorpCloudConsulTelemetry20230414IntentionsFromJSON(json['intentions']),
    };
}
export function HashicorpCloudConsulTelemetry20230414ServiceTopologyEdgeMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tcp_metrics': HashicorpCloudConsulTelemetry20230414TcpMetricsToJSON(value.tcpMetrics),
        'http_metrics': HashicorpCloudConsulTelemetry20230414HttpMetricsToJSON(value.httpMetrics),
        'intentions': HashicorpCloudConsulTelemetry20230414IntentionsToJSON(value.intentions),
    };
}
