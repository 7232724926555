import BaseAbility from './base-ability';
import {
  WAYPOINT_ACTIONS,
  ACTION_RUN,
  WAYPOINT_ACTIONS_UPDATE,
  WAYPOINT_APPLICATIONS_UPDATE,
  WAYPOINT_TEMPLATES_UPDATE,
  WAYPOINT_ACTIONS_DELETE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = WAYPOINT_ACTIONS;

  get canRun() {
    return this.permissions.has(this.generate(ACTION_RUN));
  }

  get canManage() {
    return (
      this.permissions.has(WAYPOINT_ACTIONS_UPDATE) &&
      this.permissions.has(WAYPOINT_ACTIONS_DELETE)
    );
  }

  get canAssignApplication() {
    return (
      this.permissions.has(WAYPOINT_ACTIONS_UPDATE) &&
      this.permissions.has(WAYPOINT_APPLICATIONS_UPDATE)
    );
  }

  get canAssignTemplate() {
    return (
      this.permissions.has(WAYPOINT_ACTIONS_UPDATE) &&
      this.permissions.has(WAYPOINT_TEMPLATES_UPDATE)
    );
  }
}
