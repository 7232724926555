import BaseAbility from './base-ability';
import {
  PREFIX_VAULT_RADAR,
  VAULT_RADAR_USERS,
  VAULT_RADAR_VIEW,
} from '../utils/permission-types/index';

export default class VaultRadarAbility extends BaseAbility {
  prefix = `${PREFIX_VAULT_RADAR}.${VAULT_RADAR_USERS}`;

  get canView() {
    return this.permissions.has(this.generate(VAULT_RADAR_VIEW));
  }
}
