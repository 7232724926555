/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Webhook Service
 * API for delivering and managing webhooks.
 *
 * The version of the OpenAPI document: 2023-05-31
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWebhookWebhookFromJSON, HashicorpCloudWebhookWebhookToJSON, HashicorpCloudWebhookWebhookPayloadFromJSON, HashicorpCloudWebhookWebhookPayloadToJSON, } from './';
export function HashicorpCloudWebhookUpdateWebhookRequestBodyFromJSON(json) {
    return HashicorpCloudWebhookUpdateWebhookRequestBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWebhookUpdateWebhookRequestBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'webhook': !exists(json, 'webhook') ? undefined : HashicorpCloudWebhookWebhookFromJSON(json['webhook']),
        'updateMask': !exists(json, 'update_mask') ? undefined : json['update_mask'],
        'testPayload': !exists(json, 'test_payload') ? undefined : HashicorpCloudWebhookWebhookPayloadFromJSON(json['test_payload']),
    };
}
export function HashicorpCloudWebhookUpdateWebhookRequestBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'webhook': HashicorpCloudWebhookWebhookToJSON(value.webhook),
        'update_mask': value.updateMask,
        'test_payload': HashicorpCloudWebhookWebhookPayloadToJSON(value.testPayload),
    };
}
