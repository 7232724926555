/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionConfigFlavorGitHubMethodFromJSON, HashicorpCloudWaypointActionConfigFlavorGitHubMethodToJSON, } from './';
export function HashicorpCloudWaypointActionConfigFlavorGitHubFromJSON(json) {
    return HashicorpCloudWaypointActionConfigFlavorGitHubFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointActionConfigFlavorGitHubFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'method': !exists(json, 'method') ? undefined : HashicorpCloudWaypointActionConfigFlavorGitHubMethodFromJSON(json['method']),
        'authToken': !exists(json, 'auth_token') ? undefined : json['auth_token'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'repo': !exists(json, 'repo') ? undefined : json['repo'],
        'ref': !exists(json, 'ref') ? undefined : json['ref'],
        'inputs': !exists(json, 'inputs') ? undefined : json['inputs'],
        'workflowId': !exists(json, 'workflow_id') ? undefined : json['workflow_id'],
        'runId': !exists(json, 'run_id') ? undefined : json['run_id'],
        'enableDebugLog': !exists(json, 'enable_debug_log') ? undefined : json['enable_debug_log'],
    };
}
export function HashicorpCloudWaypointActionConfigFlavorGitHubToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'method': HashicorpCloudWaypointActionConfigFlavorGitHubMethodToJSON(value.method),
        'auth_token': value.authToken,
        'username': value.username,
        'repo': value.repo,
        'ref': value.ref,
        'inputs': value.inputs,
        'workflow_id': value.workflowId,
        'run_id': value.runId,
        'enable_debug_log': value.enableDebugLog,
    };
}
