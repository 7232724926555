/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * This is the status of the request that the action ran.
 *
 *  - NONE: default/zero value; we have no status yet
 *  - UNKNOWN: a status we didn't understand
 *  - SUCCESS: action submitted successfully
 *  - ERROR: it did not work
 * @export
 * @enum {string}
 */
export var HashicorpCloudWaypointActionRunResponseStatus;
(function (HashicorpCloudWaypointActionRunResponseStatus) {
    HashicorpCloudWaypointActionRunResponseStatus["NONE"] = "NONE";
    HashicorpCloudWaypointActionRunResponseStatus["UNKNOWN"] = "UNKNOWN";
    HashicorpCloudWaypointActionRunResponseStatus["SUCCESS"] = "SUCCESS";
    HashicorpCloudWaypointActionRunResponseStatus["ERROR"] = "ERROR";
})(HashicorpCloudWaypointActionRunResponseStatus || (HashicorpCloudWaypointActionRunResponseStatus = {}));
export function HashicorpCloudWaypointActionRunResponseStatusFromJSON(json) {
    return HashicorpCloudWaypointActionRunResponseStatusFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointActionRunResponseStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudWaypointActionRunResponseStatusToJSON(value) {
    return value;
}
