/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, HashicorpCloudPacker20221202ModelsBucketChildrenFromJSON, HashicorpCloudPacker20221202ModelsBucketChildrenToJSON, HashicorpCloudPacker20221202ModelsBucketParentsFromJSON, HashicorpCloudPacker20221202ModelsBucketParentsToJSON, HashicorpCloudPacker20221202ModelsVersionFromJSON, HashicorpCloudPacker20221202ModelsVersionToJSON, } from './';
export function HashicorpCloudPacker20221202ModelsBucketFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsBucketFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsBucketFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'latestVersion': !exists(json, 'latest_version') ? undefined : HashicorpCloudPacker20221202ModelsVersionFromJSON(json['latest_version']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'platforms': !exists(json, 'platforms') ? undefined : json['platforms'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'versionCount': !exists(json, 'version_count') ? undefined : json['version_count'],
        'parents': !exists(json, 'parents') ? undefined : HashicorpCloudPacker20221202ModelsBucketParentsFromJSON(json['parents']),
        'children': !exists(json, 'children') ? undefined : HashicorpCloudPacker20221202ModelsBucketChildrenFromJSON(json['children']),
        'resourceName': !exists(json, 'resource_name') ? undefined : json['resource_name'],
    };
}
export function HashicorpCloudPacker20221202ModelsBucketToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'latest_version': HashicorpCloudPacker20221202ModelsVersionToJSON(value.latestVersion),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'platforms': value.platforms,
        'description': value.description,
        'labels': value.labels,
        'version_count': value.versionCount,
        'parents': HashicorpCloudPacker20221202ModelsBucketParentsToJSON(value.parents),
        'children': HashicorpCloudPacker20221202ModelsBucketChildrenToJSON(value.children),
        'resource_name': value.resourceName,
    };
}
