/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationRegionFromJSON, HashicorpCloudLocationRegionToJSON, } from './';
export function HashicorpCloudLocationLocationFromJSON(json) {
    return HashicorpCloudLocationLocationFromJSONTyped(json, false);
}
export function HashicorpCloudLocationLocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'region': !exists(json, 'region') ? undefined : HashicorpCloudLocationRegionFromJSON(json['region']),
    };
}
export function HashicorpCloudLocationLocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'project_id': value.projectId,
        'region': HashicorpCloudLocationRegionToJSON(value.region),
    };
}
