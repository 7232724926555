/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIamGroupFromJSON, HashicorpCloudIamGroupToJSON, HashicorpCloudIamPrincipalTypeFromJSON, HashicorpCloudIamPrincipalTypeToJSON, HashicorpCloudIamServicePrincipalFromJSON, HashicorpCloudIamServicePrincipalToJSON, HashicorpCloudIamUserPrincipalFromJSON, HashicorpCloudIamUserPrincipalToJSON, } from './';
export function HashicorpCloudIamPrincipalFromJSON(json) {
    return HashicorpCloudIamPrincipalFromJSONTyped(json, false);
}
export function HashicorpCloudIamPrincipalFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : HashicorpCloudIamPrincipalTypeFromJSON(json['type']),
        'user': !exists(json, 'user') ? undefined : HashicorpCloudIamUserPrincipalFromJSON(json['user']),
        'service': !exists(json, 'service') ? undefined : HashicorpCloudIamServicePrincipalFromJSON(json['service']),
        'group': !exists(json, 'group') ? undefined : HashicorpCloudIamGroupFromJSON(json['group']),
        'groupIds': !exists(json, 'group_ids') ? undefined : json['group_ids'],
    };
}
export function HashicorpCloudIamPrincipalToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'type': HashicorpCloudIamPrincipalTypeToJSON(value.type),
        'user': HashicorpCloudIamUserPrincipalToJSON(value.user),
        'service': HashicorpCloudIamServicePrincipalToJSON(value.service),
        'group': HashicorpCloudIamGroupToJSON(value.group),
        'group_ids': value.groupIds,
    };
}
