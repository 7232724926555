/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var Secrets20231128GatewayStatus;
(function (Secrets20231128GatewayStatus) {
    Secrets20231128GatewayStatus["UNSPECIFIED"] = "STATE_UNSPECIFIED";
    Secrets20231128GatewayStatus["PENDING"] = "STATE_PENDING";
    Secrets20231128GatewayStatus["CONNECTED"] = "STATE_CONNECTED";
    Secrets20231128GatewayStatus["DISCONNECTED"] = "STATE_DISCONNECTED";
    Secrets20231128GatewayStatus["UNSUPPORTEDVERSION"] = "STATE_UNSUPPORTED_VERSION";
    Secrets20231128GatewayStatus["DEPRECATEDVERSION"] = "STATE_DEPRECATED_VERSION";
})(Secrets20231128GatewayStatus || (Secrets20231128GatewayStatus = {}));
export function Secrets20231128GatewayStatusFromJSON(json) {
    return Secrets20231128GatewayStatusFromJSONTyped(json, false);
}
export function Secrets20231128GatewayStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Secrets20231128GatewayStatusToJSON(value) {
    return value;
}
