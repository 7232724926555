import Component from '@glimmer/component';
import { capitalize } from '@ember/string';
import type { FieldViolation } from '../../utils/consts/with-errors.ts';

interface WithErrorsFieldErrorSignature {
  Args: {
    id: string;
    field: string;
    fieldViolations: Array<FieldViolation>;
  };
  Blocks: {
    default: [unknown];
  };
}

export default class WithErrorsFieldErrorComponent extends Component<WithErrorsFieldErrorSignature> {
  /**
   * A string that will match the `field` value of an object in an error.details[].field_violation. This will be used to determine if there is an error corresponding to the passed field.
   * @argument field
   * @type {string}
   *
   */

  /**
   * An array extracted from rolling up all error.details[].field_violations from the parent WithErrors component.
   * @argument fieldViolations
   * @type {array}
   *
   */

  /**
   * An ID to use for the HTML id attribute (for a11y) on the error message.
   * @argument id
   * @type {string}
   *
   */

  // an error matching the specified field from `@field`
  get fieldError() {
    const fieldViolation = this.args.fieldViolations?.find((e) => {
      return e.field === this.args.field;
    });

    if (fieldViolation?.description) {
      const lastCharacter =
        fieldViolation.description[fieldViolation.description.length - 1];

      const punctuationList = ['.', '?', '!'];

      if (lastCharacter && !punctuationList.includes(lastCharacter)) {
        fieldViolation.description = fieldViolation.description + '.';
      }

      fieldViolation.description = capitalize(fieldViolation.description);
    }

    return fieldViolation;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WithErrors::FieldError': typeof WithErrorsFieldErrorComponent;
    'with-errors/field-error': typeof WithErrorsFieldErrorComponent;
  }
}
