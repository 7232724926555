import Component from '@glimmer/component';

interface RegionSelectSignature {
  Args: {
    disabled: unknown;
    error: unknown;
    id: unknown;
    onChange: unknown;
    provider: unknown;
    regions: unknown;
    selectedRegion: unknown;
  };
  Blocks: {
    'helper-text': [];
    label: [];
  };
  Element: HTMLElement;
}

/**
 *
 * Simple select box input, formats & localizes AWS region name options.
 *
 *
 * ```
 * <FormInputs::RegionSelect
 *   @regions={{regions}}
 *   @selectedRegion={{selectedRegion}}
 *   @onChange={{onChange}}
 *   @disabled={{disabled}}
 * />
 * ```
 *
 * @class FormInputsRegionSelect
 *
 */
export default class FormInputsRegionSelectComponent extends Component<RegionSelectSignature> {
  /**
   * @argument regions - list of all AWS regions (Example: ["us-east-1", "eu-west-1"])
   * @type {Array.<String>}
   */
  /**
   * @argument selectedRegion
   * @type {String}
   */
  /**
   * @argument disabled
   * @type {Boolean}
   */
  /**
   * @argument onChange - handler function that accepts a browser Event as 0th argument
   * @type {Function}
   */
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormInputs::RegionSelect': typeof FormInputsRegionSelectComponent;
    'form-inputs/region-select': typeof FormInputsRegionSelectComponent;
  }
}
