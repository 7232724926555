/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125GetUtilizationResponseValueUnitFromJSON, HashicorpCloudVault20201125GetUtilizationResponseValueUnitToJSON, } from './';
export function HashicorpCloudVault20201125GetUtilizationResponseValueFromJSON(json) {
    return HashicorpCloudVault20201125GetUtilizationResponseValueFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125GetUtilizationResponseValueFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'value': !exists(json, 'value') ? undefined : json['value'],
        'maxValue': !exists(json, 'max_value') ? undefined : json['max_value'],
        'unit': !exists(json, 'unit') ? undefined : HashicorpCloudVault20201125GetUtilizationResponseValueUnitFromJSON(json['unit']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}
export function HashicorpCloudVault20201125GetUtilizationResponseValueToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'value': value.value,
        'max_value': value.maxValue,
        'unit': HashicorpCloudVault20201125GetUtilizationResponseValueUnitToJSON(value.unit),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
