/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * State is the state of the key.
 *
 *  - UNSET: UNSET is the default value.
 * Should never be used at runtime for valid messages.
 *  - CREATING: CREATING is a key that's just been inserted in the DB, and its
 * authN credentials are still being prepared. Will transition to
 * ACTIVE on success.
 *  - ACTIVE: ACTIVE is a key that's been properly created and is ready to use.
 *  - DELETING: DELETING is a key that it's being deleted. It will be fully deleted
 * once its authN credentials will have been deleted from Auth0.
 * @export
 * @enum {string}
 */
export var HashicorpCloudIamServicePrincipalKeyState;
(function (HashicorpCloudIamServicePrincipalKeyState) {
    HashicorpCloudIamServicePrincipalKeyState["UNSET"] = "UNSET";
    HashicorpCloudIamServicePrincipalKeyState["CREATING"] = "CREATING";
    HashicorpCloudIamServicePrincipalKeyState["ACTIVE"] = "ACTIVE";
    HashicorpCloudIamServicePrincipalKeyState["DELETING"] = "DELETING";
})(HashicorpCloudIamServicePrincipalKeyState || (HashicorpCloudIamServicePrincipalKeyState = {}));
export function HashicorpCloudIamServicePrincipalKeyStateFromJSON(json) {
    return HashicorpCloudIamServicePrincipalKeyStateFromJSONTyped(json, false);
}
export function HashicorpCloudIamServicePrincipalKeyStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudIamServicePrincipalKeyStateToJSON(value) {
    return value;
}
