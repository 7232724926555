/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CloudlocationLocationFromJSON, CloudlocationLocationToJSON, Secrets20230613AwsSmConnectionDetailsResponseFromJSON, Secrets20230613AwsSmConnectionDetailsResponseToJSON, Secrets20230613AzureKvConnectionDetailsResponseFromJSON, Secrets20230613AzureKvConnectionDetailsResponseToJSON, Secrets20230613GcpSmConnectionDetailsResponseFromJSON, Secrets20230613GcpSmConnectionDetailsResponseToJSON, Secrets20230613GhOrgConnectionDetailsResponseFromJSON, Secrets20230613GhOrgConnectionDetailsResponseToJSON, Secrets20230613GhRepoConnectionDetailsResponseFromJSON, Secrets20230613GhRepoConnectionDetailsResponseToJSON, Secrets20230613HcpTerraformConnectionDetailsResponseFromJSON, Secrets20230613HcpTerraformConnectionDetailsResponseToJSON, Secrets20230613PrincipalFromJSON, Secrets20230613PrincipalToJSON, Secrets20230613VercelProjectConnectionDetailsResponseFromJSON, Secrets20230613VercelProjectConnectionDetailsResponseToJSON, } from './';
export function Secrets20230613SyncIntegrationFromJSON(json) {
    return Secrets20230613SyncIntegrationFromJSONTyped(json, false);
}
export function Secrets20230613SyncIntegrationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : CloudlocationLocationFromJSON(json['location']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : Secrets20230613PrincipalFromJSON(json['created_by']),
        'updatedBy': !exists(json, 'updated_by') ? undefined : Secrets20230613PrincipalFromJSON(json['updated_by']),
        'awsSmConnectionDetails': !exists(json, 'aws_sm_connection_details') ? undefined : Secrets20230613AwsSmConnectionDetailsResponseFromJSON(json['aws_sm_connection_details']),
        'ghRepoConnectionDetails': !exists(json, 'gh_repo_connection_details') ? undefined : Secrets20230613GhRepoConnectionDetailsResponseFromJSON(json['gh_repo_connection_details']),
        'vercelProjectConnectionDetails': !exists(json, 'vercel_project_connection_details') ? undefined : Secrets20230613VercelProjectConnectionDetailsResponseFromJSON(json['vercel_project_connection_details']),
        'azureKvConnectionDetails': !exists(json, 'azure_kv_connection_details') ? undefined : Secrets20230613AzureKvConnectionDetailsResponseFromJSON(json['azure_kv_connection_details']),
        'gcpSmConnectionDetails': !exists(json, 'gcp_sm_connection_details') ? undefined : Secrets20230613GcpSmConnectionDetailsResponseFromJSON(json['gcp_sm_connection_details']),
        'ghOrgConnectionDetails': !exists(json, 'gh_org_connection_details') ? undefined : Secrets20230613GhOrgConnectionDetailsResponseFromJSON(json['gh_org_connection_details']),
        'hcpTerraformConnectionDetails': !exists(json, 'hcp_terraform_connection_details') ? undefined : Secrets20230613HcpTerraformConnectionDetailsResponseFromJSON(json['hcp_terraform_connection_details']),
    };
}
export function Secrets20230613SyncIntegrationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': CloudlocationLocationToJSON(value.location),
        'name': value.name,
        'type': value.type,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'created_by': Secrets20230613PrincipalToJSON(value.createdBy),
        'updated_by': Secrets20230613PrincipalToJSON(value.updatedBy),
        'aws_sm_connection_details': Secrets20230613AwsSmConnectionDetailsResponseToJSON(value.awsSmConnectionDetails),
        'gh_repo_connection_details': Secrets20230613GhRepoConnectionDetailsResponseToJSON(value.ghRepoConnectionDetails),
        'vercel_project_connection_details': Secrets20230613VercelProjectConnectionDetailsResponseToJSON(value.vercelProjectConnectionDetails),
        'azure_kv_connection_details': Secrets20230613AzureKvConnectionDetailsResponseToJSON(value.azureKvConnectionDetails),
        'gcp_sm_connection_details': Secrets20230613GcpSmConnectionDetailsResponseToJSON(value.gcpSmConnectionDetails),
        'gh_org_connection_details': Secrets20230613GhOrgConnectionDetailsResponseToJSON(value.ghOrgConnectionDetails),
        'hcp_terraform_connection_details': Secrets20230613HcpTerraformConnectionDetailsResponseToJSON(value.hcpTerraformConnectionDetails),
    };
}
