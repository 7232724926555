/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - CLUSTER_PERFORMANCE_REPLICATION_PATHS_FILTER_MODE_INVALID: PATHS_FILTER_MODE_INVALID is a sentinel zero value so that an uninitialized value can be
 * detected.
 *  - ALLOW: ALLOW allows only the specified paths for replication
 *  - DENY: DENY denies the specified paths for replication
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterMode;
(function (HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterMode) {
    HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterMode["CLUSTERPERFORMANCEREPLICATIONPATHSFILTERMODEINVALID"] = "CLUSTER_PERFORMANCE_REPLICATION_PATHS_FILTER_MODE_INVALID";
    HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterMode["ALLOW"] = "ALLOW";
    HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterMode["DENY"] = "DENY";
})(HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterMode || (HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterMode = {}));
export function HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterModeFromJSON(json) {
    return HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterModeFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterModeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125ClusterPerformanceReplicationPathsFilterModeToJSON(value) {
    return value;
}
