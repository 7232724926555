import type PrincipalsService from '../services/principals';
import type { HashicorpCloudIamPrincipal } from '@clients/cloud-iam';

type MapType<T> = Map<string, T>;

export default function iamGetPrincipalFromService<T>(
  map: PrincipalsService | MapType<T> | undefined,
  { key }: { key?: string },
): HashicorpCloudIamPrincipal | undefined {
  if (!map || !key) {
    return undefined;
  }

  if ('get' in map && typeof map.get === 'function') {
    return map.get(key) as HashicorpCloudIamPrincipal;
  }

  return undefined;
}
