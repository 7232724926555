import BaseAbility from './base-ability';
import { inject as service } from '@ember/service';
import {
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_GET,
  ACTION_LIST_MEMBERS,
  ACTION_UPDATE,
  ACTION_UPDATE_MEMBERS,
  PREFIX_IAM_GROUPS,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  @service quota;

  prefix = PREFIX_IAM_GROUPS;

  /** @return {boolean} */
  get canCreate() {
    return this.permissions.has(this.generate(ACTION_CREATE));
  }

  /** @return {boolean} */
  get canGet() {
    return this.permissions.has(this.generate(ACTION_GET));
  }

  /** @return {boolean} */
  get canDelete() {
    return this.permissions.has(this.generate(ACTION_DELETE));
  }

  /** @return {boolean} */
  get canListMembers() {
    // This only relies on the resource-level permissions call.
    return this.groupLevelPermissions?.includes(
      `${PREFIX_IAM_GROUPS}.${ACTION_LIST_MEMBERS}`,
    );
  }

  /** @return {boolean} */
  get canUpdate() {
    return this.groupLevelPermissions?.includes(
      `${PREFIX_IAM_GROUPS}.${ACTION_UPDATE}`,
    );
  }

  /** @return {boolean} */
  get canUpdateMembership() {
    return this.groupLevelPermissions?.includes(
      `${PREFIX_IAM_GROUPS}.${ACTION_UPDATE_MEMBERS}`,
    );
  }
}
