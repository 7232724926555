export const DEFAULT_VARIANT = 'secondary';
export const DEFAULT_VARIANT_MAPPING: { [key: string]: string } = {
  ghost: 'button--ghost',
  'ghost-background': 'button--ghost-background',
  link: 'button--link',
  primary: 'button--primary',
  secondary: 'button--secondary',
  warning: 'button--warning',
};
export const VARIANT_SCALE = Object.keys(DEFAULT_VARIANT_MAPPING);
export const DEFAULT_COMPACT = false;
export const DEFAULT_COMPACT_MAPPING = {
  true: 'button--compact',
  false: '',
};
