/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudIamUpdateWebConsolePreferencesResponseFromJSON(json) {
    return HashicorpCloudIamUpdateWebConsolePreferencesResponseFromJSONTyped(json, false);
}
export function HashicorpCloudIamUpdateWebConsolePreferencesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'webPortalPreferences': !exists(json, 'web_portal_preferences') ? undefined : json['web_portal_preferences'],
    };
}
export function HashicorpCloudIamUpdateWebConsolePreferencesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'web_portal_preferences': value.webPortalPreferences,
    };
}
