/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215ServiceGatewayConfigFromJSON, HashicorpCloudGlobalNetworkManager20220215ServiceGatewayConfigToJSON, HashicorpCloudGlobalNetworkManager20220215ServiceSamenessGroupFromJSON, HashicorpCloudGlobalNetworkManager20220215ServiceSamenessGroupToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ServiceFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ServiceFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ServiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'clusterResourceName': !exists(json, 'cluster_resource_name') ? undefined : json['cluster_resource_name'],
        'clusterResourceId': !exists(json, 'cluster_resource_id') ? undefined : json['cluster_resource_id'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'partition': !exists(json, 'partition') ? undefined : json['partition'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'externalSources': !exists(json, 'external_sources') ? undefined : json['external_sources'],
        'connectNative': !exists(json, 'connect_native') ? undefined : json['connect_native'],
        'connectedWithProxy': !exists(json, 'connected_with_proxy') ? undefined : json['connected_with_proxy'],
        'transparentProxy': !exists(json, 'transparent_proxy') ? undefined : json['transparent_proxy'],
        'connectedWithGateway': !exists(json, 'connected_with_gateway') ? undefined : json['connected_with_gateway'],
        'gatewayConfig': !exists(json, 'gateway_config') ? undefined : HashicorpCloudGlobalNetworkManager20220215ServiceGatewayConfigFromJSON(json['gateway_config']),
        'instanceCount': !exists(json, 'instance_count') ? undefined : json['instance_count'],
        'checksCritical': !exists(json, 'checks_critical') ? undefined : json['checks_critical'],
        'checksPassing': !exists(json, 'checks_passing') ? undefined : json['checks_passing'],
        'checksWarning': !exists(json, 'checks_warning') ? undefined : json['checks_warning'],
        'kind': !exists(json, 'kind') ? undefined : json['kind'],
        'mtlsMode': !exists(json, 'mtls_mode') ? undefined : json['mtls_mode'],
        'samenessGroup': !exists(json, 'sameness_group') ? undefined : HashicorpCloudGlobalNetworkManager20220215ServiceSamenessGroupFromJSON(json['sameness_group']),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ServiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'cluster_id': value.clusterId,
        'cluster_resource_name': value.clusterResourceName,
        'cluster_resource_id': value.clusterResourceId,
        'namespace': value.namespace,
        'partition': value.partition,
        'tags': value.tags,
        'external_sources': value.externalSources,
        'connect_native': value.connectNative,
        'connected_with_proxy': value.connectedWithProxy,
        'transparent_proxy': value.transparentProxy,
        'connected_with_gateway': value.connectedWithGateway,
        'gateway_config': HashicorpCloudGlobalNetworkManager20220215ServiceGatewayConfigToJSON(value.gatewayConfig),
        'instance_count': value.instanceCount,
        'checks_critical': value.checksCritical,
        'checks_passing': value.checksPassing,
        'checks_warning': value.checksWarning,
        'kind': value.kind,
        'mtls_mode': value.mtlsMode,
        'sameness_group': HashicorpCloudGlobalNetworkManager20220215ServiceSamenessGroupToJSON(value.samenessGroup),
    };
}
