import Component from '@glimmer/component';

export interface CoreCardSummaryKeySignature {
  Args: {
    contextualClass?: string;
    icon?: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `CoreCardSummaryKeyComponent` provides a container to display a key in a key/value pair.
 *
 *
 * ```
 * <Core::Card::Summary::Key>
 *   Some Key
 * </Core::Card::Summary::Key>
 * ```
 *
 * @class CoreCardSummaryKeyComponent
 *
 */

export default class CoreCardSummaryKeyComponent extends Component<CoreCardSummaryKeySignature> {
  /**
   * Get the class names to apply to the component.
   * @method CoreCardSummaryKeyComponent#classNames
   * @return {string} The "class" attribute to apply to the component.
   */
  get classNames() {
    const { contextualClass } = this.args;

    const classes = [
      'core-card-summary-key',
      'hds-typography-body-100',
      'hds-font-weight-semibold',
      'hds-foreground-strong',
    ];

    if (contextualClass) {
      classes.push(contextualClass);
    }

    return classes.join(' ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::Card::Summary::Key': typeof CoreCardSummaryKeyComponent;
    'core/card/summary/key': typeof CoreCardSummaryKeyComponent;
  }
}
