/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudResourcemanagerResourceIDFromJSON, HashicorpCloudResourcemanagerResourceIDToJSON, } from './';
export function HashicorpCloudResourcemanagerProjectCreateRequestFromJSON(json) {
    return HashicorpCloudResourcemanagerProjectCreateRequestFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerProjectCreateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parent': !exists(json, 'parent') ? undefined : HashicorpCloudResourcemanagerResourceIDFromJSON(json['parent']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}
export function HashicorpCloudResourcemanagerProjectCreateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'parent': HashicorpCloudResourcemanagerResourceIDToJSON(value.parent),
        'description': value.description,
    };
}
