import BaseAbility from './base-ability';
import { inject as service } from '@ember/service';
import { TYPE_SERVICE_PRINCIPAL_KEY } from 'common/utils/cloud-resource-types';
import {
  PREFIX_IAM_SERVICE_PRINCIPALS,
  ACTION_CREATE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  @service quota;

  // This is intentionally iam.service-principals as there is no service-principal-key permission.
  prefix = PREFIX_IAM_SERVICE_PRINCIPALS;

  get canCreate() {
    let keys = this.model || [];
    return (
      this.permissions.has(this.generate(ACTION_CREATE)) &&
      keys.length < this.quota.for(TYPE_SERVICE_PRINCIPAL_KEY)
    );
  }
}
