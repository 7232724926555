import { waitForPromise } from '@ember/test-waiters';

import type { TaskForAsyncTaskFunction } from 'ember-concurrency';

import {
  Configuration as BillingConfiguration,
  BASE_PATH as BILLING_BASE_PATH,
  BillingAccountServiceApi,
  FCPManagementServiceApi,
  ContractServiceApi,
  InvoiceServiceApi,
  ProductServiceApi,
  StatementServiceApi,
  ActivationServiceApi,
} from '@clients/cloud-billing';

import type {
  RequestContext,
  ResponseContext,
  Middleware,
} from '@clients/cloud-billing';

import {
  Configuration as ConsulConfiguration,
  BASE_PATH as CONSUL_BASE_PATH,
  ConsulServiceApi,
} from '@clients/cloud-consul-service';
import {
  Configuration as ConsulGnmConfiguration,
  BASE_PATH as CONSUL_GNM_BASE_PATH,
  GlobalNetworkManagerServiceApi,
} from '@clients/cloud-global-network-manager-service';
import {
  Configuration as ConsulTelemetryConfiguration,
  BASE_PATH as CONSUL_TELEMETRY_BASE_PATH,
  ConsulTelemetryServiceApi,
} from '@clients/cloud-consul-telemetry-gateway';
import {
  Configuration as IamConfiguration,
  BASE_PATH as IAM_BASE_PATH,
  GroupsServiceApi,
  IamServiceApi,
  InvitationsServiceApi,
  ProfileServiceApi,
  SSOManagementServiceApi,
  ServicePrincipalsServiceApi,
  AuthConfigServiceApi,
} from '@clients/cloud-iam';
import {
  Configuration as IDPConfiguration,
  BASE_PATH as IDP_BASE_PATH,
  ScimServiceApi,
  SessionServiceApi,
  FlagsServiceApi,
} from '@clients/cloud-idp';
import {
  Configuration as EmailConfiguration,
  ContactServiceApi,
  BASE_PATH as EMAIL_BASE_PATH,
} from '@clients/cloud-email';
import {
  Configuration as LogConfiguration,
  BASE_PATH as LOG_BASE_PATH,
  LogServiceApi,
  StreamingServiceApi,
} from '@clients/cloud-log-service';
import {
  Configuration as NetworkConfiguration,
  BASE_PATH as NETWORK_BASE_PATH,
  NetworkServiceApi,
} from '@clients/cloud-network';
import {
  Configuration as OperationConfiguration,
  BASE_PATH as OPERATION_BASE_PATH,
  OperationServiceApi,
} from '@clients/cloud-operation';
import {
  Configuration as ResourceManagerConfiguration,
  OrganizationServiceApi,
  ProjectServiceApi,
  BASE_PATH as RESOURCE_MANAGER_BASE_PATH,
  ResourceServiceApi,
  AuthorizationServiceApi,
} from '@clients/cloud-resource-manager';
import {
  Configuration as PackerConfiguration,
  BASE_PATH as PACKER_BASE_PATH,
  PackerServiceApi,
} from '@clients/cloud-packer-service';
import Service, { inject as service } from '@ember/service';
import {
  Configuration as VaultSecretsConfiguration,
  BASE_PATH as VAULT_SECRETS_BASE_PATH,
  SecretServiceApi,
} from '@clients/cloud-vault-secrets';
import {
  Configuration as VaultSecretsNextConfiguration,
  BASE_PATH as VAULT_SECRETS_NEXT_BASE_PATH,
  SecretServiceApi as SecretNextServiceApi,
} from '@clients/cloud-vault-secrets-next';
import {
  Configuration as VaultConfiguration,
  BASE_PATH as VAULT_BASE_PATH,
  VaultServiceApi,
} from '@clients/cloud-vault-service';
import {
  Configuration as WaypointConfiguration,
  BASE_PATH as WAYPOINT_NEXT_BASE_PATH,
  WaypointServiceApi,
} from '@clients/cloud-waypoint-service';
import {
  Configuration as BoundaryConfiguration,
  BASE_PATH as BOUNDARY_BASE_PATH,
  BoundaryServiceApi,
} from '@clients/cloud-boundary-service';
import {
  Configuration as VagrantConfiguration,
  BASE_PATH as VAGRANT_BASE_PATH,
  RegistryServiceApi,
} from '@clients/cloud-vagrant-box-registry';
import {
  Configuration as WebhookConfiguration,
  BASE_PATH as WEBHOOK_BASE_PATH,
  WebhookServiceApi,
} from '@clients/cloud-webhook';
import {
  Configuration as TFCSynchronizationConfiguration,
  BASE_PATH as TFC_SYNCHRONIZATION_BASE_PATH,
  NotificationsServiceApi as TFCSyncNotificationsServiceApi,
} from '@clients/cloud-tfc-synchronization';
import {
  Configuration as VaultRadarConfiguration,
  BASE_PATH as VAULT_RADAR_BASE_PATH,
  TenantServiceApi as VaultRadarTenantServiceApi,
} from '@clients/cloud-vault-radar';

import type ConfigService from './config.ts';
import type SessionService from 'ember-simple-auth/services/session';

interface Signal {
  signal: AbortSignal;
  matchLength: number;
  urlRe: RegExp;
}

// Solve challenges with component-based data-loading.
// This allows for API methods passed to be passed to components
// and bind `this` internally.
// Ref: https://github.com/hashicorp/cloud-ui/pull/4657#discussion_r1084306026
const autobind = (klass: object) => {
  const methodCache = new WeakMap();
  const handler = {
    get(target: object, prop: string) {
      const val = Reflect.get(target, prop);
      // if it's not a function, just return it
      if (typeof val !== 'function') {
        return val;
      }
      // if it is a function, check the cache first
      if (!methodCache.has(val)) {
        // add the bound function to the cache
        methodCache.set(val, val.bind(target));
      }
      // return the bound function from the cache
      return methodCache.get(val);
    },
  };
  return new Proxy(klass, handler);
};

export default class ApiService extends Service {
  @service declare readonly session: SessionService;
  @service('config') declare readonly appConfig: ConfigService;

  static headers() {
    return {};
  }

  signal?: Signal;

  buildBasePath(
    clientBase: string,
    serviceHost = this.appConfig?.app?.baseServiceHost ||
      'http://localhost:28081'
  ) {
    const apiPath = new URL(clientBase);
    // versioned APIs have the version as part of the endpoint, so pathname is '/'
    // here and if we use that, we end up with a '//' resulting in an error when
    // making network calls
    if (apiPath.pathname === '/') {
      return serviceHost;
    }
    return serviceHost + apiPath.pathname;
  }

  config(
    basePath: string,
    baseHost?: string,
    addFetchTestWaiter = true,
    middleware: Middleware[] = []
  ) {
    // Configuration is the same for all of these
    // because they all extend BaseAPI
    return {
      basePath: this.buildBasePath(basePath, baseHost),

      fetchApi: (...args: [Request]) => {
        if (!addFetchTestWaiter) {
          return window.fetch(...args);
        }

        return waitForPromise(window.fetch(...args));
      },
      apiKey: () => {
        const accessToken = this.session.data?.authenticated?.accessToken;
        if (accessToken) {
          return `Bearer ${accessToken}`;
        }
        return '';
      },
      headers: ApiService.headers(),
      middleware: [
        {
          pre: async (args: RequestContext) => this.checkForSignal(args),
        },
        {
          post: (context: ResponseContext) => {
            // if the response is not authenticated then log the user out
            if (context.response.status === 401) {
              // save the current page for them to come back to
              // Ideally would import setRedirectTarget from hcp redirect util
              sessionStorage.setItem(
                'hcp.redirectTarget',
                `${location.pathname}${location.search}`
              );
              return this.session.invalidate({ skipLogout: true });
            }
            return Promise.resolve();
          },
        },
        ...middleware,
      ],
    };
  }

  // `checkForSignal` is used as fetch middleware for all of the API clients
  // if there is a signal on the API service, and the signal's `urlRe` matches
  // the url of the request, the AbortSignal is attached to the fetch parameters
  // in `init` so that the request can be canceled.

  async checkForSignal({ fetch, init, url }: RequestContext) {
    if (
      this.signal &&
      url?.match(this.signal.urlRe)?.length === this.signal.matchLength
    ) {
      init.signal = this.signal.signal;
      delete this.signal;
    }
    return Promise.resolve({ fetch, init, url });
  }

  // `cancelable` takes arguments that are later used to match the URL in fetch middelware
  // to see if an AbortSignal should be attached to the fetch request. It returns an AbortController
  //
  // You should pass enough arguments to uniquiely identify the URL the request will make.
  //
  // Currently this is only suitable for a single request at a time.
  //
  //@return AbortController

  cancelable(...args: unknown[]) {
    const parts = [...args];
    const controller = new AbortController();
    const signal = {
      signal: controller.signal,
      matchLength: parts.length,
      urlRe: new RegExp(`${parts.join('|')}`, 'g'),
    };

    this.signal = signal;
    return controller;
  }

  consul = new ConsulServiceApi(
    new ConsulConfiguration(this.config(CONSUL_BASE_PATH))
  );
  consulGnm = new GlobalNetworkManagerServiceApi(
    new ConsulGnmConfiguration(this.config(CONSUL_GNM_BASE_PATH))
  );
  consulTelemetry = new ConsulTelemetryServiceApi(
    new ConsulTelemetryConfiguration(this.config(CONSUL_TELEMETRY_BASE_PATH))
  );
  vault = new VaultServiceApi(
    new VaultConfiguration(this.config(VAULT_BASE_PATH))
  );
  packer = new PackerServiceApi(
    new PackerConfiguration(this.config(PACKER_BASE_PATH))
  );

  waypoint = new WaypointServiceApi(
    new WaypointConfiguration(
      this.config(WAYPOINT_NEXT_BASE_PATH, undefined, undefined, [
        {
          post: async function (
            this: ApiService & {
              onWaypointActivationFailure?: TaskForAsyncTaskFunction<
                void,
                () => Promise<void>
              >;
            },
            context: ResponseContext
          ) {
            // https://github.com/hashicorp/cloud-waypoint-service/pull/1366/files#diff-85ee04e67bda04e6159b92426c87fe161c58d4b5550ef2b0fa7a97214309c125R386
            if (
              context.response.status === 403 ||
              context.response.status === 400
            ) {
              const payload = await context.response.json();
              if (
                payload?.message &&
                (payload.message === 'namespace not activated' ||
                  payload.message ===
                    'failed to check TFC organization activation')
              ) {
                await this.onWaypointActivationFailure?.perform();
              }
            }
          }.bind(this),
        },
      ])
    )
  );

  boundary = new BoundaryServiceApi(
    new BoundaryConfiguration(this.config(BOUNDARY_BASE_PATH))
  );
  vagrant = {
    registryServiceApi: new RegistryServiceApi(
      new VagrantConfiguration(this.config(VAGRANT_BASE_PATH))
    ),
  };

  groups = new GroupsServiceApi(
    new IamConfiguration(this.config(IAM_BASE_PATH))
  );
  iam = new IamServiceApi(
    new IamConfiguration(this.config(IAM_BASE_PATH, undefined, false))
  );
  invitation = new InvitationsServiceApi(
    new IamConfiguration(this.config(IAM_BASE_PATH))
  );
  profile = new ProfileServiceApi(
    new IamConfiguration(this.config(IAM_BASE_PATH))
  );
  servicePrincipal = new ServicePrincipalsServiceApi(
    new IamConfiguration(this.config(IAM_BASE_PATH))
  );
  // This IIFE was used to prevent a TS error with this.appConfig
  sessionManager = (() =>
    new SessionServiceApi(
      new IDPConfiguration(
        this.config(IDP_BASE_PATH, this.appConfig?.app?.baseIDPHost)
      )
    ))();
  scim = (() =>
    new ScimServiceApi(
      new IDPConfiguration(
        this.config(IDP_BASE_PATH, this.appConfig?.app?.baseIDPHost)
      )
    ))();
  flag = (() =>
    new FlagsServiceApi(
      new IDPConfiguration(
        this.config(IDP_BASE_PATH, this.appConfig?.app?.baseIDPHost)
      )
    ))();

  sso = new SSOManagementServiceApi(
    new IamConfiguration(this.config(IAM_BASE_PATH))
  );
  authConfig = new AuthConfigServiceApi(
    new IamConfiguration(this.config(IAM_BASE_PATH))
  );
  network = new NetworkServiceApi(
    new NetworkConfiguration(this.config(NETWORK_BASE_PATH))
  );

  operation = autobind(
    new OperationServiceApi(
      new OperationConfiguration(
        this.config(OPERATION_BASE_PATH, undefined, false)
      )
    )
  );

  resourceManager = {
    org: new OrganizationServiceApi(
      new ResourceManagerConfiguration(this.config(RESOURCE_MANAGER_BASE_PATH))
    ),
    project: new ProjectServiceApi(
      new ResourceManagerConfiguration(this.config(RESOURCE_MANAGER_BASE_PATH))
    ),
    resources: new ResourceServiceApi(
      new ResourceManagerConfiguration(this.config(RESOURCE_MANAGER_BASE_PATH))
    ),
    authorization: new AuthorizationServiceApi(
      new ResourceManagerConfiguration(this.config(RESOURCE_MANAGER_BASE_PATH))
    ),
  };

  email = new ContactServiceApi(
    new EmailConfiguration(this.config(EMAIL_BASE_PATH))
  );
  billing = {
    account: new BillingAccountServiceApi(
      new BillingConfiguration(this.config(BILLING_BASE_PATH))
    ),
    activation: new ActivationServiceApi(
      new BillingConfiguration(this.config(BILLING_BASE_PATH))
    ),
    fcp: new FCPManagementServiceApi(
      new BillingConfiguration(this.config(BILLING_BASE_PATH))
    ),
    contract: new ContractServiceApi(
      new BillingConfiguration(this.config(BILLING_BASE_PATH))
    ),
    invoice: new InvoiceServiceApi(
      new BillingConfiguration(this.config(BILLING_BASE_PATH))
    ),
    product: new ProductServiceApi(
      new BillingConfiguration(this.config(BILLING_BASE_PATH))
    ),
    statement: new StatementServiceApi(
      new BillingConfiguration(this.config(BILLING_BASE_PATH))
    ),
  };

  log = new LogServiceApi(new LogConfiguration(this.config(LOG_BASE_PATH)));

  streaming = new StreamingServiceApi(
    new LogConfiguration(this.config(LOG_BASE_PATH))
  );

  secrets = new SecretServiceApi(
    new VaultSecretsConfiguration(this.config(VAULT_SECRETS_BASE_PATH))
  );

  secretsNext = new SecretNextServiceApi(
    new VaultSecretsNextConfiguration(this.config(VAULT_SECRETS_NEXT_BASE_PATH))
  );

  webhook = new WebhookServiceApi(
    new WebhookConfiguration(this.config(WEBHOOK_BASE_PATH))
  );

  tfcSynchronization = new TFCSyncNotificationsServiceApi(
    new TFCSynchronizationConfiguration(
      this.config(TFC_SYNCHRONIZATION_BASE_PATH)
    )
  );

  vaultRadar = {
    tenant: new VaultRadarTenantServiceApi(
      new VaultRadarConfiguration(this.config(VAULT_RADAR_BASE_PATH))
    ),
  };
}
