import type { BillingAvailablePackage } from '../types/api.ts';

import compareTierByKeyAsc from '../utils/compare-tier-by-key-asc.ts';

export default function packagesDataTiers(
  packages: BillingAvailablePackage[] = [],
  { resourceType }: { resourceType?: string } = {},
): BillingAvailablePackage[] {
  if (!resourceType) return [];

  const tiers = packages.filter((p) => p.resourceType === resourceType);

  const sortedTiers = tiers.sort(compareTierByKeyAsc);

  return sortedTiers;
}
