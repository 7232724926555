/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVault20201125GetCORSConfigResponseFromJSON(json) {
    return HashicorpCloudVault20201125GetCORSConfigResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125GetCORSConfigResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'allowedOrigins': !exists(json, 'allowed_origins') ? undefined : json['allowed_origins'],
        'allowedHeaders': !exists(json, 'allowed_headers') ? undefined : json['allowed_headers'],
    };
}
export function HashicorpCloudVault20201125GetCORSConfigResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'enabled': value.enabled,
        'allowed_origins': value.allowedOrigins,
        'allowed_headers': value.allowedHeaders,
    };
}
