/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudIamOIDCWorkloadIdentityProviderConfigFromJSON(json) {
    return HashicorpCloudIamOIDCWorkloadIdentityProviderConfigFromJSONTyped(json, false);
}
export function HashicorpCloudIamOIDCWorkloadIdentityProviderConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'issuerUri': !exists(json, 'issuer_uri') ? undefined : json['issuer_uri'],
        'allowedAudiences': !exists(json, 'allowed_audiences') ? undefined : json['allowed_audiences'],
    };
}
export function HashicorpCloudIamOIDCWorkloadIdentityProviderConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'issuer_uri': value.issuerUri,
        'allowed_audiences': value.allowedAudiences,
    };
}
