/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudResourcemanagerProjectProjectStateFromJSON, HashicorpCloudResourcemanagerProjectProjectStateToJSON, HashicorpCloudResourcemanagerResourceIDFromJSON, HashicorpCloudResourcemanagerResourceIDToJSON, } from './';
export function HashicorpCloudResourcemanagerProjectFromJSON(json) {
    return HashicorpCloudResourcemanagerProjectFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerProjectFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parent': !exists(json, 'parent') ? undefined : HashicorpCloudResourcemanagerResourceIDFromJSON(json['parent']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'state': !exists(json, 'state') ? undefined : HashicorpCloudResourcemanagerProjectProjectStateFromJSON(json['state']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}
export function HashicorpCloudResourcemanagerProjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'parent': HashicorpCloudResourcemanagerResourceIDToJSON(value.parent),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'state': HashicorpCloudResourcemanagerProjectProjectStateToJSON(value.state),
        'description': value.description,
    };
}
