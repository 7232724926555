/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20230613VercelProjectConnectionDetailsResponseFromJSON(json) {
    return Secrets20230613VercelProjectConnectionDetailsResponseFromJSONTyped(json, false);
}
export function Secrets20230613VercelProjectConnectionDetailsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'configurationId': !exists(json, 'configuration_id') ? undefined : json['configuration_id'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'projectName': !exists(json, 'project_name') ? undefined : json['project_name'],
        'teamId': !exists(json, 'team_id') ? undefined : json['team_id'],
        'deploymentEnvironments': !exists(json, 'deployment_environments') ? undefined : json['deployment_environments'],
        'installationName': !exists(json, 'installation_name') ? undefined : json['installation_name'],
    };
}
export function Secrets20230613VercelProjectConnectionDetailsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'configuration_id': value.configurationId,
        'project_id': value.projectId,
        'project_name': value.projectName,
        'team_id': value.teamId,
        'deployment_environments': value.deploymentEnvironments,
        'installation_name': value.installationName,
    };
}
