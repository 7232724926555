/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLinkFromJSON, HashicorpCloudLocationLinkToJSON, HashicorpCloudNetwork20200907PeeringStateFromJSON, HashicorpCloudNetwork20200907PeeringStateToJSON, HashicorpCloudNetwork20200907PeeringTargetFromJSON, HashicorpCloudNetwork20200907PeeringTargetToJSON, } from './';
export function HashicorpCloudNetwork20200907PeeringFromJSON(json) {
    return HashicorpCloudNetwork20200907PeeringFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907PeeringFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'hvn': !exists(json, 'hvn') ? undefined : HashicorpCloudLocationLinkFromJSON(json['hvn']),
        'state': !exists(json, 'state') ? undefined : HashicorpCloudNetwork20200907PeeringStateFromJSON(json['state']),
        'target': !exists(json, 'target') ? undefined : HashicorpCloudNetwork20200907PeeringTargetFromJSON(json['target']),
        'providerPeeringId': !exists(json, 'provider_peering_id') ? undefined : json['provider_peering_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'sourceChannel': !exists(json, 'source_channel') ? undefined : json['source_channel'],
    };
}
export function HashicorpCloudNetwork20200907PeeringToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'hvn': HashicorpCloudLocationLinkToJSON(value.hvn),
        'state': HashicorpCloudNetwork20200907PeeringStateToJSON(value.state),
        'target': HashicorpCloudNetwork20200907PeeringTargetToJSON(value.target),
    };
}
