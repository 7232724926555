/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * ReasonDomainOwnershipUnverified is the reason a request to verify an
 * organizations's ownership/control of a domain failed.
 *
 *  - UNSET: NOT_PROVIDED is the default value.
 * It should never be used when a verification failed.
 *  - NO_TXT_RECORD: NO_TXT_RECORD is the reason a verification failed
 * when a domain does not contain proof of ownership.
 *  - NO_SUCH_HOST: NO_SUCH_HOST is the reason a verification failed when the
 * given domain does not exist.
 *  - ALREADY_IN_USE: ALREADY_IN_USE is the reason a verfication failed when the
 * given domain is already in use by another organization.
 * @export
 * @enum {string}
 */
export var HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverified;
(function (HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverified) {
    HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverified["UNSET"] = "UNSET";
    HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverified["NOTXTRECORD"] = "NO_TXT_RECORD";
    HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverified["NOSUCHHOST"] = "NO_SUCH_HOST";
    HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverified["ALREADYINUSE"] = "ALREADY_IN_USE";
})(HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverified || (HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverified = {}));
export function HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverifiedFromJSON(json) {
    return HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverifiedFromJSONTyped(json, false);
}
export function HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverifiedFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverifiedToJSON(value) {
    return value;
}
