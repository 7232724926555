/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215CVEFromJSON, HashicorpCloudGlobalNetworkManager20220215CVEToJSON, HashicorpCloudGlobalNetworkManager20220215ConsulMajorVersionFromJSON, HashicorpCloudGlobalNetworkManager20220215ConsulMajorVersionToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ListConsulVersionsResponseFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ListConsulVersionsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ListConsulVersionsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'majorVersions': !exists(json, 'major_versions') ? undefined : (mapValues(json['major_versions'], HashicorpCloudGlobalNetworkManager20220215ConsulMajorVersionFromJSON)),
        'cves': !exists(json, 'cves') ? undefined : (mapValues(json['cves'], HashicorpCloudGlobalNetworkManager20220215CVEFromJSON)),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ListConsulVersionsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'major_versions': value.majorVersions === undefined ? undefined : (mapValues(value.majorVersions, HashicorpCloudGlobalNetworkManager20220215ConsulMajorVersionToJSON)),
        'cves': value.cves === undefined ? undefined : (mapValues(value.cves, HashicorpCloudGlobalNetworkManager20220215CVEToJSON)),
    };
}
