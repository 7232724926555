/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudInternalLocationLocationFromJSON, HashicorpCloudInternalLocationLocationToJSON, HashicorpCloudVault20201125SnapshotStateFromJSON, HashicorpCloudVault20201125SnapshotStateToJSON, HashicorpCloudVault20201125SnapshotTypeFromJSON, HashicorpCloudVault20201125SnapshotTypeToJSON, } from './';
export function HashicorpCloudVault20201125SnapshotFromJSON(json) {
    return HashicorpCloudVault20201125SnapshotFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125SnapshotFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : HashicorpCloudInternalLocationLocationFromJSON(json['location']),
        'snapshotId': !exists(json, 'snapshot_id') ? undefined : json['snapshot_id'],
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'state': !exists(json, 'state') ? undefined : HashicorpCloudVault20201125SnapshotStateFromJSON(json['state']),
        'requestedAt': !exists(json, 'requested_at') ? undefined : (new Date(json['requested_at'])),
        'finishedAt': !exists(json, 'finished_at') ? undefined : (new Date(json['finished_at'])),
        'vaultVersion': !exists(json, 'vault_version') ? undefined : json['vault_version'],
        'type': !exists(json, 'type') ? undefined : HashicorpCloudVault20201125SnapshotTypeFromJSON(json['type']),
        'isLocked': !exists(json, 'is_locked') ? undefined : json['is_locked'],
    };
}
export function HashicorpCloudVault20201125SnapshotToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': HashicorpCloudInternalLocationLocationToJSON(value.location),
        'snapshot_id': value.snapshotId,
        'cluster_id': value.clusterId,
        'name': value.name,
        'state': HashicorpCloudVault20201125SnapshotStateToJSON(value.state),
        'requested_at': value.requestedAt === undefined ? undefined : (value.requestedAt.toISOString()),
        'finished_at': value.finishedAt === undefined ? undefined : (value.finishedAt.toISOString()),
        'vault_version': value.vaultVersion,
        'type': HashicorpCloudVault20201125SnapshotTypeToJSON(value.type),
        'is_locked': value.isLocked,
    };
}
