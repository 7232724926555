/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128SyncConfigGitlabFromJSON, Secrets20231128SyncConfigGitlabToJSON, } from './';
export function SecretServiceCreateSyncBodyFromJSON(json) {
    return SecretServiceCreateSyncBodyFromJSONTyped(json, false);
}
export function SecretServiceCreateSyncBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'integrationName': !exists(json, 'integration_name') ? undefined : json['integration_name'],
        'syncConfigGitlab': !exists(json, 'sync_config_gitlab') ? undefined : Secrets20231128SyncConfigGitlabFromJSON(json['sync_config_gitlab']),
    };
}
export function SecretServiceCreateSyncBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'type': value.type,
        'integration_name': value.integrationName,
        'sync_config_gitlab': Secrets20231128SyncConfigGitlabToJSON(value.syncConfigGitlab),
    };
}
