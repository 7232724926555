/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202ModelsArtifactCreateBodyFromJSON, HashicorpCloudPacker20221202ModelsArtifactCreateBodyToJSON, HashicorpCloudPacker20221202ModelsBuildStatusFromJSON, HashicorpCloudPacker20221202ModelsBuildStatusToJSON, } from './';
export function HashicorpCloudPacker20221202ModelsUpdateBuildBodyFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsUpdateBuildBodyFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsUpdateBuildBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': !exists(json, 'status') ? undefined : HashicorpCloudPacker20221202ModelsBuildStatusFromJSON(json['status']),
        'platform': !exists(json, 'platform') ? undefined : json['platform'],
        'packerRunUuid': !exists(json, 'packer_run_uuid') ? undefined : json['packer_run_uuid'],
        'artifacts': !exists(json, 'artifacts') ? undefined : (json['artifacts'].map(HashicorpCloudPacker20221202ModelsArtifactCreateBodyFromJSON)),
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'sourceExternalIdentifier': !exists(json, 'source_external_identifier') ? undefined : json['source_external_identifier'],
        'parentVersionId': !exists(json, 'parent_version_id') ? undefined : json['parent_version_id'],
        'parentChannelId': !exists(json, 'parent_channel_id') ? undefined : json['parent_channel_id'],
    };
}
export function HashicorpCloudPacker20221202ModelsUpdateBuildBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': HashicorpCloudPacker20221202ModelsBuildStatusToJSON(value.status),
        'platform': value.platform,
        'packer_run_uuid': value.packerRunUuid,
        'artifacts': value.artifacts === undefined ? undefined : (value.artifacts.map(HashicorpCloudPacker20221202ModelsArtifactCreateBodyToJSON)),
        'labels': value.labels,
        'source_external_identifier': value.sourceExternalIdentifier,
        'parent_version_id': value.parentVersionId,
        'parent_channel_id': value.parentChannelId,
    };
}
