/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function LogService20210330TestStreamingDestinationResponseFromJSON(json) {
    return LogService20210330TestStreamingDestinationResponseFromJSONTyped(json, false);
}
export function LogService20210330TestStreamingDestinationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'requestId': !exists(json, 'request_id') ? undefined : json['request_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'errorType': !exists(json, 'error_type') ? undefined : json['error_type'],
        'errorMessage': !exists(json, 'error_message') ? undefined : json['error_message'],
        'testPayload': !exists(json, 'test_payload') ? undefined : json['test_payload'],
    };
}
export function LogService20210330TestStreamingDestinationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'request_id': value.requestId,
        'status': value.status,
        'error_type': value.errorType,
        'error_message': value.errorMessage,
        'test_payload': value.testPayload,
    };
}
