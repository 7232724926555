import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

/**
 *
 * `StickyFooter` displays a footer that is 'sticky' and which is intended to be placed inside page
 * content (in the space to the right of the sidebar and under the header).
 *
 * StickyFooter will soon be replaced by HDS's StickyFooter component (seen https://www.figma.com/file/93e8HALzIvTBdZdXErCjAW)
 * However, that work is not estimated to be complete until late Q3 2023. To allow for footers to match the newly updated
 * designs, and allow for an easier upgrade path to the new StickyFooter component, if you use the named blocks of
 * `:primary-button:`, `:secondary-button:` and `:tertiary-button:`, StickyFooter will refrain from yielding any more content, and instead
 * will just yield the buttons in the three button combination, with:
 * - Tertiary on the far left
 * - Primary on the far right
 * - Secondary second from the right
 *
 * StickyFooter only supports the three button mode currently, but could be easily updated to support more modes in the future.
 *
 * ```
 * <StickyFooter>
 *   <div>My footer content</div>
 * </StickyFooter>
 * ```
 *
 * @class StickyFooter
 *
 */

const HDS_SIDE_NAV_MINIMIZED_CLASS = 'hds-side-nav--is-minimized';

interface StickyFooterSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
  Blocks: {
    default: [];
    'primary-button': [];
    'secondary-button': [];
    'tertiary-button': [];
  };
  Element: HTMLDivElement;
}

export default class StickyFooterComponent extends Component<StickyFooterSignature> {
  @tracked isSideNavMinimized = false;
  observer: MutationObserver | null = null;

  constructor(owner: unknown, args: StickyFooterSignature['Args']) {
    super(owner, args);
    this.setupObserver();
  }

  setupObserver(): void {
    const callback = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          const targetElement = mutation.target as Element;
          this.isSideNavMinimized = targetElement.classList.contains(
            HDS_SIDE_NAV_MINIMIZED_CLASS
          );
        }
      }
    };

    this.observer = new MutationObserver(callback);

    const targetNode = document.querySelector('.hds-side-nav');
    if (targetNode) {
      this.isSideNavMinimized = targetNode.classList.contains(
        HDS_SIDE_NAV_MINIMIZED_CLASS
      );

      this.observer.observe(targetNode, { attributes: true });
    }
  }

  willDestroy(): void {
    super.willDestroy();
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  get classNames(): string {
    const classes = ['sticky', 'sticky--with-sidebar'];

    if (this.isSideNavMinimized) {
      classes.push('sticky--is-minimized');
    } else {
      classes.push('sticky--is-maximized');
    }

    return classes.join(' ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    StickyFooter: typeof StickyFooterComponent;
    'sticky-footer': typeof StickyFooterComponent;
  }
}
