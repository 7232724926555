export const NETWORK_CREATE_UP_NEXT_CONTENT = [
  {
    subtitle: 'components.networks.cards.up-next.hvn-create.subtitle',
    instructions: 'components.networks.cards.up-next.hvn-create.instructions',
  },
];
export const TARGET_CREATE_UP_NEXT_CONTENT = [
  {
    subtitle: 'components.networks.cards.up-next.target-create.subtitle',
    instructions:
      'components.networks.cards.up-next.target-create.instructions',
  },
  {
    subtitle: 'components.networks.cards.up-next.target-add-a-route.subtitle',
    instructions:
      'components.networks.cards.up-next.target-add-a-route.instructions',
  },
];

export const NETWORK_ROUTE_CREATE_UP_NEXT_CONTENT = [
  {
    subtitle: 'components.networks.cards.up-next.route-create.subtitle',
    instructions: 'components.networks.cards.up-next.route-create.instructions',
  },
];
