/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVault20201125CloudWatchFromJSON(json) {
    return HashicorpCloudVault20201125CloudWatchFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125CloudWatchFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accessKeyId': !exists(json, 'access_key_id') ? undefined : json['access_key_id'],
        'secretAccessKey': !exists(json, 'secret_access_key') ? undefined : json['secret_access_key'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'groupName': !exists(json, 'group_name') ? undefined : json['group_name'],
        'streamName': !exists(json, 'stream_name') ? undefined : json['stream_name'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
    };
}
export function HashicorpCloudVault20201125CloudWatchToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'access_key_id': value.accessKeyId,
        'secret_access_key': value.secretAccessKey,
        'region': value.region,
        'group_name': value.groupName,
        'stream_name': value.streamName,
        'namespace': value.namespace,
    };
}
