import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type UserContextService from '../../services/user-context.ts';

interface CoreProjectLinksSignature {
  // Args: {};
}

export default class CoreProjectLinksComponent extends Component<CoreProjectLinksSignature> {
  /**
   * This is a component to use to render the links. The component *must* take
   * the same arguments that align with Hds components like the `dd.Interactive`
   * or `Hds::Link::*` components. This argument is not a string. Additionally,
   * this argument cannot be retrieved and returned via a getter so validation
   * is not possible with this approach.
   * @argument component
   * @type {Component}
   * @required
   */

  @service declare readonly userContext: UserContextService;

  get organization() {
    return this.userContext.organization;
  }

  get project() {
    return this.userContext.project;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::ProjectLinks': typeof CoreProjectLinksComponent;
    'core/project-links': typeof CoreProjectLinksComponent;
  }
}
