/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudPacker20221202ModelsBucketFromJSON, HashicorpCloudPacker20221202ModelsBucketToJSON, } from './';
export function HashicorpCloudPacker20221202ModelsListBucketsResponseFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsListBucketsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsListBucketsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'buckets': !exists(json, 'buckets') ? undefined : (json['buckets'].map(HashicorpCloudPacker20221202ModelsBucketFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudPacker20221202ModelsListBucketsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'buckets': value.buckets === undefined ? undefined : (value.buckets.map(HashicorpCloudPacker20221202ModelsBucketToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
