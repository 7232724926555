import Component from '@glimmer/component';

interface ZeroStateMessageSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `ZeroStateMessage` displays the message element of the ZeroState screen.
 *
 *
 * ```
 * <ZeroState::Message>Message</ZeroState::Message>
 * ```
 *
 * @class ZeroStateMessage
 *
 */

export default class ZeroStateMessageComponent extends Component<ZeroStateMessageSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ZeroState::Message': typeof ZeroStateMessageComponent;
    'zero-state/message': typeof ZeroStateMessageComponent;
  }
}
