/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20231128MongoDBRoleFromJSON(json) {
    return Secrets20231128MongoDBRoleFromJSONTyped(json, false);
}
export function Secrets20231128MongoDBRoleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'roleName': !exists(json, 'role_name') ? undefined : json['role_name'],
        'databaseName': !exists(json, 'database_name') ? undefined : json['database_name'],
        'collectionName': !exists(json, 'collection_name') ? undefined : json['collection_name'],
    };
}
export function Secrets20231128MongoDBRoleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'role_name': value.roleName,
        'database_name': value.databaseName,
        'collection_name': value.collectionName,
    };
}
