/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CloudlocationLocationFromJSON, CloudlocationLocationToJSON, Secrets20230613AwsAssumeRoleMetadataFromJSON, Secrets20230613AwsAssumeRoleMetadataToJSON, Secrets20230613GhAppMetadataFromJSON, Secrets20230613GhAppMetadataToJSON, Secrets20230613HcpTerraformMetadataFromJSON, Secrets20230613HcpTerraformMetadataToJSON, Secrets20230613VercelOAuthMetadataFromJSON, Secrets20230613VercelOAuthMetadataToJSON, } from './';
export function Secrets20230613SyncInstallationFromJSON(json) {
    return Secrets20230613SyncInstallationFromJSONTyped(json, false);
}
export function Secrets20230613SyncInstallationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : CloudlocationLocationFromJSON(json['location']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'awsAssumeRole': !exists(json, 'aws_assume_role') ? undefined : Secrets20230613AwsAssumeRoleMetadataFromJSON(json['aws_assume_role']),
        'ghApp': !exists(json, 'gh_app') ? undefined : Secrets20230613GhAppMetadataFromJSON(json['gh_app']),
        'vercelOauth': !exists(json, 'vercel_oauth') ? undefined : Secrets20230613VercelOAuthMetadataFromJSON(json['vercel_oauth']),
        'hcpTerraform': !exists(json, 'hcp_terraform') ? undefined : Secrets20230613HcpTerraformMetadataFromJSON(json['hcp_terraform']),
    };
}
export function Secrets20230613SyncInstallationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': CloudlocationLocationToJSON(value.location),
        'name': value.name,
        'aws_assume_role': Secrets20230613AwsAssumeRoleMetadataToJSON(value.awsAssumeRole),
        'gh_app': Secrets20230613GhAppMetadataToJSON(value.ghApp),
        'vercel_oauth': Secrets20230613VercelOAuthMetadataToJSON(value.vercelOauth),
        'hcp_terraform': Secrets20230613HcpTerraformMetadataToJSON(value.hcpTerraform),
    };
}
