/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330MatrixResponseFromJSON, LogService20210330MatrixResponseToJSON, LogService20210330StreamsResponseFromJSON, LogService20210330StreamsResponseToJSON, } from './';
export function LogService20210330SearchResponseFromJSON(json) {
    return LogService20210330SearchResponseFromJSONTyped(json, false);
}
export function LogService20210330SearchResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'streams': !exists(json, 'streams') ? undefined : LogService20210330StreamsResponseFromJSON(json['streams']),
        'matrix': !exists(json, 'matrix') ? undefined : LogService20210330MatrixResponseFromJSON(json['matrix']),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
    };
}
export function LogService20210330SearchResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'streams': LogService20210330StreamsResponseToJSON(value.streams),
        'matrix': LogService20210330MatrixResponseToJSON(value.matrix),
        'next_page_token': value.nextPageToken,
    };
}
