/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105PackageItemFromJSON, Billing20201105PackageItemToJSON, } from './';
export function Billing20201105AvailablePackageFromJSON(json) {
    return Billing20201105AvailablePackageFromJSONTyped(json, false);
}
export function Billing20201105AvailablePackageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !exists(json, 'key') ? undefined : json['key'],
        'resourceType': !exists(json, 'resource_type') ? undefined : json['resource_type'],
        'items': !exists(json, 'items') ? undefined : (json['items'].map(Billing20201105PackageItemFromJSON)),
    };
}
export function Billing20201105AvailablePackageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
        'resource_type': value.resourceType,
        'items': value.items === undefined ? undefined : (value.items.map(Billing20201105PackageItemToJSON)),
    };
}
