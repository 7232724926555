/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * State is the state of the Vault cluster. Note that this state
 * represents the abstract Vault cluster itself, not necessarily whether
 * Vault cluster is currently available or not.
 *
 *  - CLUSTER_STATE_INVALID: CLUSTER_STATE_INVALID is a sentinel zero value so that an uninitialized value can be
 * detected.
 *  - PENDING: PENDING is the state the cluster is in while it is waiting to be created.
 *  - CREATING: CREATING is the state the cluster is in while it is being provisioned for
 * the first time.
 *  - RUNNING: RUNNING is the steady state while the cluster is running.
 *  - FAILED: FAILED is a failure state in which the cluster is unavailable and may
 * required an operator restore action to recover.
 *  - UPDATING: UPDATING is the state the cluster is in while undergoing a version
 * update.
 *  - RESTORING: RESTORING is the state the cluster is in while restoring from a snapshot.
 *  - DELETING: DELETING is the state the cluster is in while it is being de-provisioned.
 *  - SEALING: SEALING is the state the cluster is in when it is about to get sealed.
 *  - SEALED: SEALED is the state the cluster is in while a cluster is sealed.
 *  - UNSEALING: UNSEALING is the state the cluster is in when it is about to get unsealed.
 *  - CLUSTER_SCALING: CLUSTER_SCALING is the state the cluster is in when it is under an up or down scaling operation to a new tier_size state.
 *  - LOCKING: LOCKING is the state the cluster is in when it is about to get locked.
 *  - LOCKED: LOCKED is the state the cluster is in while a cluster is locked.
 *  - UNLOCKING: UNLOCKING is the state the cluster is in when it is about to get unlocked.
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125ClusterState;
(function (HashicorpCloudVault20201125ClusterState) {
    HashicorpCloudVault20201125ClusterState["CLUSTERSTATEINVALID"] = "CLUSTER_STATE_INVALID";
    HashicorpCloudVault20201125ClusterState["PENDING"] = "PENDING";
    HashicorpCloudVault20201125ClusterState["CREATING"] = "CREATING";
    HashicorpCloudVault20201125ClusterState["RUNNING"] = "RUNNING";
    HashicorpCloudVault20201125ClusterState["FAILED"] = "FAILED";
    HashicorpCloudVault20201125ClusterState["UPDATING"] = "UPDATING";
    HashicorpCloudVault20201125ClusterState["RESTORING"] = "RESTORING";
    HashicorpCloudVault20201125ClusterState["DELETING"] = "DELETING";
    HashicorpCloudVault20201125ClusterState["SEALING"] = "SEALING";
    HashicorpCloudVault20201125ClusterState["SEALED"] = "SEALED";
    HashicorpCloudVault20201125ClusterState["UNSEALING"] = "UNSEALING";
    HashicorpCloudVault20201125ClusterState["CLUSTERSCALING"] = "CLUSTER_SCALING";
    HashicorpCloudVault20201125ClusterState["LOCKING"] = "LOCKING";
    HashicorpCloudVault20201125ClusterState["LOCKED"] = "LOCKED";
    HashicorpCloudVault20201125ClusterState["UNLOCKING"] = "UNLOCKING";
})(HashicorpCloudVault20201125ClusterState || (HashicorpCloudVault20201125ClusterState = {}));
export function HashicorpCloudVault20201125ClusterStateFromJSON(json) {
    return HashicorpCloudVault20201125ClusterStateFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125ClusterStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125ClusterStateToJSON(value) {
    return value;
}
