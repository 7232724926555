/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - DAY_OF_WEEK_UNSPECIFIED: DAY_OF_WEEK_UNSPECIFIED is an unspecified DayOfWeek
 *  - DAY_OF_WEEK_MONDAY: DAY_OF_WEEK_MONDAY is Monday
 *  - DAY_OF_WEEK_TUESDAY: DAY_OF_WEEK_TUESDAY is Tuesday
 *  - DAY_OF_WEEK_WEDNESDAY: DAY_OF_WEEK_WEDNESDAY is Wednesday
 *  - DAY_OF_WEEK_THURSDAY: DAY_OF_WEEK_THURSDAY is Thursday
 *  - DAY_OF_WEEK_FRIDAY: DAY_OF_WEEK_FRIDAY is Friday
 *  - DAY_OF_WEEK_SATURDAY: DAY_OF_WEEK_SATURDAY is Saturday
 *  - DAY_OF_WEEK_SUNDAY: DAY_OF_WEEK_SUNDAY is Sunday
 * @export
 * @enum {string}
 */
export var HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeek;
(function (HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeek) {
    HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeek["UNSPECIFIED"] = "DAY_OF_WEEK_UNSPECIFIED";
    HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeek["MONDAY"] = "DAY_OF_WEEK_MONDAY";
    HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeek["TUESDAY"] = "DAY_OF_WEEK_TUESDAY";
    HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeek["WEDNESDAY"] = "DAY_OF_WEEK_WEDNESDAY";
    HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeek["THURSDAY"] = "DAY_OF_WEEK_THURSDAY";
    HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeek["FRIDAY"] = "DAY_OF_WEEK_FRIDAY";
    HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeek["SATURDAY"] = "DAY_OF_WEEK_SATURDAY";
    HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeek["SUNDAY"] = "DAY_OF_WEEK_SUNDAY";
})(HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeek || (HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeek = {}));
export function HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeekFromJSON(json) {
    return HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeekFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeekFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudBoundary20211221MaintenanceWindowDayOfWeekToJSON(value) {
    return value;
}
