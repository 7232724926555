/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudBoundary20211221ClusterMarketingSKUFromJSON, HashicorpCloudBoundary20211221ClusterMarketingSKUToJSON, HashicorpCloudBoundary20211221ClusterStateFromJSON, HashicorpCloudBoundary20211221ClusterStateToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudBoundary20211221ClusterFromJSON(json) {
    return HashicorpCloudBoundary20211221ClusterFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221ClusterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'state': !exists(json, 'state') ? undefined : HashicorpCloudBoundary20211221ClusterStateFromJSON(json['state']),
        'clusterUrl': !exists(json, 'cluster_url') ? undefined : json['cluster_url'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'boundaryVersion': !exists(json, 'boundary_version') ? undefined : json['boundary_version'],
        'marketingSku': !exists(json, 'marketing_sku') ? undefined : HashicorpCloudBoundary20211221ClusterMarketingSKUFromJSON(json['marketing_sku']),
    };
}
export function HashicorpCloudBoundary20211221ClusterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cluster_id': value.clusterId,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'state': HashicorpCloudBoundary20211221ClusterStateToJSON(value.state),
        'cluster_url': value.clusterUrl,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'boundary_version': value.boundaryVersion,
        'marketing_sku': HashicorpCloudBoundary20211221ClusterMarketingSKUToJSON(value.marketingSku),
    };
}
