import { helper as buildHelper } from '@ember/component/helper';

/*
 * A helper that parses a number string into an integer.
 *
 * @example
 * {{number-parse-int '10'}}
 *
 *
 */

export function numberParseInt([numberString]) {
  return Number.parseInt(numberString, 10);
}

export default buildHelper(numberParseInt);
