/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudResourcemanagerOrganizationCreateRequestToJSON, HashicorpCloudResourcemanagerOrganizationCreateResponseFromJSON, HashicorpCloudResourcemanagerOrganizationGetIamPolicyResponseFromJSON, HashicorpCloudResourcemanagerOrganizationGetResponseFromJSON, HashicorpCloudResourcemanagerOrganizationGetRoleResponseFromJSON, HashicorpCloudResourcemanagerOrganizationListResponseFromJSON, HashicorpCloudResourcemanagerOrganizationListRolesResponseFromJSON, HashicorpCloudResourcemanagerOrganizationSetIamPolicyResponseFromJSON, HashicorpCloudResourcemanagerOrganizationSetNameResponseFromJSON, HashicorpCloudResourcemanagerOrganizationTestIamPermissionsResponseFromJSON, SeeOrganizationServiceSetIamPolicyToJSON, SeeOrganizationServiceSetNameToJSON, SeeOrganizationServiceTestIamPermissionsToJSON, } from '../models';
/**
 *
 */
export class OrganizationServiceApi extends runtime.BaseAPI {
    /**
     * Create creates a new organization. The owner is set to the identity of the caller. An organization can not be owned by a machine account.
     */
    async organizationServiceCreateRaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling organizationServiceCreate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudResourcemanagerOrganizationCreateRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerOrganizationCreateResponseFromJSON(jsonValue));
    }
    /**
     * Create creates a new organization. The owner is set to the identity of the caller. An organization can not be owned by a machine account.
     */
    async organizationServiceCreate(body) {
        const response = await this.organizationServiceCreateRaw({ body: body });
        return await response.value();
    }
    /**
     * Get retrieves the requested organization by ID.
     */
    async organizationServiceGetRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling organizationServiceGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerOrganizationGetResponseFromJSON(jsonValue));
    }
    /**
     * Get retrieves the requested organization by ID.
     */
    async organizationServiceGet(id) {
        const response = await this.organizationServiceGetRaw({ id: id });
        return await response.value();
    }
    /**
     * GetIamPolicy retrieves the requested organization\'s IAM policy.
     */
    async organizationServiceGetIamPolicyRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling organizationServiceGetIamPolicy.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/organizations/{id}/iam-policy`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerOrganizationGetIamPolicyResponseFromJSON(jsonValue));
    }
    /**
     * GetIamPolicy retrieves the requested organization\'s IAM policy.
     */
    async organizationServiceGetIamPolicy(id) {
        const response = await this.organizationServiceGetIamPolicyRaw({ id: id });
        return await response.value();
    }
    /**
     * GetRole returns a Role and its mapped permissions.
     */
    async organizationServiceGetRoleRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling organizationServiceGetRole.');
        }
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId', 'Required parameter requestParameters.roleId was null or undefined when calling organizationServiceGetRole.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/organizations/{id}/{role_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"role_id"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerOrganizationGetRoleResponseFromJSON(jsonValue));
    }
    /**
     * GetRole returns a Role and its mapped permissions.
     */
    async organizationServiceGetRole(id, roleId) {
        const response = await this.organizationServiceGetRoleRaw({ id: id, roleId: roleId });
        return await response.value();
    }
    /**
     * List lists the organizations the caller has access to.
     */
    async organizationServiceListRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerOrganizationListResponseFromJSON(jsonValue));
    }
    /**
     * List lists the organizations the caller has access to.
     */
    async organizationServiceList(paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.organizationServiceListRaw({ paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListRoles returns a list of all user facing Roles for an organization.
     */
    async organizationServiceListRolesRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling organizationServiceListRoles.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/organizations/{id}/roles`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerOrganizationListRolesResponseFromJSON(jsonValue));
    }
    /**
     * ListRoles returns a list of all user facing Roles for an organization.
     */
    async organizationServiceListRoles(id, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.organizationServiceListRolesRaw({ id: id, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * SetIamPolicy sets the IAM policy for an organization. Since this updates the full policy, the policy takes an \"etag\" argument that should match with the current policy stored in the system. This is to avoid concurrent writes erasing each other\'s data. Hence, the client should always get the current policy that includes the etag, modify the policy and then set the policy with the same etag. If there is no existing policy the etag must be empty.
     */
    async organizationServiceSetIamPolicyRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling organizationServiceSetIamPolicy.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling organizationServiceSetIamPolicy.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/organizations/{id}/iam-policy`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SeeOrganizationServiceSetIamPolicyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerOrganizationSetIamPolicyResponseFromJSON(jsonValue));
    }
    /**
     * SetIamPolicy sets the IAM policy for an organization. Since this updates the full policy, the policy takes an \"etag\" argument that should match with the current policy stored in the system. This is to avoid concurrent writes erasing each other\'s data. Hence, the client should always get the current policy that includes the etag, modify the policy and then set the policy with the same etag. If there is no existing policy the etag must be empty.
     */
    async organizationServiceSetIamPolicy(id, body) {
        const response = await this.organizationServiceSetIamPolicyRaw({ id: id, body: body });
        return await response.value();
    }
    /**
     * SetName updates the organization\'s name.
     */
    async organizationServiceSetNameRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling organizationServiceSetName.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling organizationServiceSetName.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/organizations/{id}/name`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SeeOrganizationServiceSetNameToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerOrganizationSetNameResponseFromJSON(jsonValue));
    }
    /**
     * SetName updates the organization\'s name.
     */
    async organizationServiceSetName(id, body) {
        const response = await this.organizationServiceSetNameRaw({ id: id, body: body });
        return await response.value();
    }
    /**
     * TestIamPermissions returns a subset of the request permissions the calling principal has for the organization.
     */
    async organizationServiceTestIamPermissionsRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling organizationServiceTestIamPermissions.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling organizationServiceTestIamPermissions.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/organizations/{id}/test-iam-permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SeeOrganizationServiceTestIamPermissionsToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerOrganizationTestIamPermissionsResponseFromJSON(jsonValue));
    }
    /**
     * TestIamPermissions returns a subset of the request permissions the calling principal has for the organization.
     */
    async organizationServiceTestIamPermissions(id, body) {
        const response = await this.organizationServiceTestIamPermissionsRaw({ id: id, body: body });
        return await response.value();
    }
}
