/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LocationLinkFromJSON, LocationLinkToJSON, LogService20210330ControlPlaneEventFromJSON, LogService20210330ControlPlaneEventToJSON, LogService20210330LogStreamFromJSON, LogService20210330LogStreamToJSON, LogService20210330ServiceLogEventFromJSON, LogService20210330ServiceLogEventToJSON, LogService20210330SubjectFromJSON, LogService20210330SubjectToJSON, } from './';
export function LogService20210330LogEntryFromJSON(json) {
    return LogService20210330LogEntryFromJSONTyped(json, false);
}
export function LogService20210330LogEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'stream': !exists(json, 'stream') ? undefined : LogService20210330LogStreamFromJSON(json['stream']),
        'resource': !exists(json, 'resource') ? undefined : LocationLinkFromJSON(json['resource']),
        'controlPlaneEvent': !exists(json, 'control_plane_event') ? undefined : LogService20210330ControlPlaneEventFromJSON(json['control_plane_event']),
        'serviceLogEvent': !exists(json, 'service_log_event') ? undefined : LogService20210330ServiceLogEventFromJSON(json['service_log_event']),
        'subject': !exists(json, 'subject') ? undefined : LogService20210330SubjectFromJSON(json['subject']),
    };
}
export function LogService20210330LogEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'stream': LogService20210330LogStreamToJSON(value.stream),
        'resource': LocationLinkToJSON(value.resource),
        'control_plane_event': LogService20210330ControlPlaneEventToJSON(value.controlPlaneEvent),
        'service_log_event': LogService20210330ServiceLogEventToJSON(value.serviceLogEvent),
        'subject': LogService20210330SubjectToJSON(value.subject),
    };
}
