/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - ITERATION_UNSET: UNSET is a sentinel zero value so that an uninitialized value can be
 * detected.
 *  - ITERATION_RUNNING: Running means the `packer build` is currently running in the iteration.
 *  - ITERATION_CANCELLED: Cancelled means the `packer build` was cancelled by a user.
 *  - ITERATION_FAILED: Failed means the `packer build` failed and therefore image creation failed.
 *  - ITERATION_REVOKED: Revoked means the iteration has been revoked.
 *  - ITERATION_REVOCATION_SCHEDULED: Revocation scheduled means the iteration has been scheduled to be revoked.
 *  - ITERATION_ACTIVE: Active means the iteration is valid and ready to be used.
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202IterationExternalStatus;
(function (HashicorpCloudPacker20221202IterationExternalStatus) {
    HashicorpCloudPacker20221202IterationExternalStatus["UNSET"] = "ITERATION_UNSET";
    HashicorpCloudPacker20221202IterationExternalStatus["RUNNING"] = "ITERATION_RUNNING";
    HashicorpCloudPacker20221202IterationExternalStatus["CANCELLED"] = "ITERATION_CANCELLED";
    HashicorpCloudPacker20221202IterationExternalStatus["FAILED"] = "ITERATION_FAILED";
    HashicorpCloudPacker20221202IterationExternalStatus["REVOKED"] = "ITERATION_REVOKED";
    HashicorpCloudPacker20221202IterationExternalStatus["REVOCATIONSCHEDULED"] = "ITERATION_REVOCATION_SCHEDULED";
    HashicorpCloudPacker20221202IterationExternalStatus["ACTIVE"] = "ITERATION_ACTIVE";
})(HashicorpCloudPacker20221202IterationExternalStatus || (HashicorpCloudPacker20221202IterationExternalStatus = {}));
export function HashicorpCloudPacker20221202IterationExternalStatusFromJSON(json) {
    return HashicorpCloudPacker20221202IterationExternalStatusFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202IterationExternalStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202IterationExternalStatusToJSON(value) {
    return value;
}
