/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudIamCountGroupsForPrincipalsResponseFromJSON, HashicorpCloudIamCountMembersForGroupsResponseFromJSON, HashicorpCloudIamCountOrganizationGroupsResponseFromJSON, HashicorpCloudIamCreateGroupResponseFromJSON, HashicorpCloudIamGetGroupResponseFromJSON, HashicorpCloudIamGroupToJSON, HashicorpCloudIamListGroupMembersResponseFromJSON, HashicorpCloudIamListGroupsResponseFromJSON, InlineObject16ToJSON, InlineObject17ToJSON, InlineObject3ToJSON, } from '../models';
/**
 *
 */
export class GroupsServiceApi extends runtime.BaseAPI {
    /**
     * CountGroupsForPrincipals will count the number of groups a principal is in for a list of principals
     */
    async groupsServiceCountGroupsForPrincipalsRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling groupsServiceCountGroupsForPrincipals.');
        }
        const queryParameters = {};
        if (requestParameters.principalIds) {
            queryParameters['principal_ids'] = requestParameters.principalIds;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/iam/{parent_resource_name}/groups/count-for-principals`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCountGroupsForPrincipalsResponseFromJSON(jsonValue));
    }
    /**
     * CountGroupsForPrincipals will count the number of groups a principal is in for a list of principals
     */
    async groupsServiceCountGroupsForPrincipals(parentResourceName, principalIds) {
        const response = await this.groupsServiceCountGroupsForPrincipalsRaw({ parentResourceName: parentResourceName, principalIds: principalIds });
        return await response.value();
    }
    /**
     * CountMembersForGroups returns the count of members per group
     */
    async groupsServiceCountMembersForGroupsRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling groupsServiceCountMembersForGroups.');
        }
        const queryParameters = {};
        if (requestParameters.groupResourceNames) {
            queryParameters['group_resource_names'] = requestParameters.groupResourceNames;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/iam/{parent_resource_name}/groups/count-members-for-groups`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCountMembersForGroupsResponseFromJSON(jsonValue));
    }
    /**
     * CountMembersForGroups returns the count of members per group
     */
    async groupsServiceCountMembersForGroups(parentResourceName, groupResourceNames) {
        const response = await this.groupsServiceCountMembersForGroupsRaw({ parentResourceName: parentResourceName, groupResourceNames: groupResourceNames });
        return await response.value();
    }
    /**
     * CountOrganizationGroups returns the count of groups for an organization.
     */
    async groupsServiceCountOrganizationGroupsRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling groupsServiceCountOrganizationGroups.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/iam/{parent_resource_name}/groups/count`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCountOrganizationGroupsResponseFromJSON(jsonValue));
    }
    /**
     * CountOrganizationGroups returns the count of groups for an organization.
     */
    async groupsServiceCountOrganizationGroups(parentResourceName) {
        const response = await this.groupsServiceCountOrganizationGroupsRaw({ parentResourceName: parentResourceName });
        return await response.value();
    }
    /**
     * CreateGroup creates a group.
     */
    async groupsServiceCreateGroupRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling groupsServiceCreateGroup.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling groupsServiceCreateGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/iam/{parent_resource_name}/groups`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject3ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCreateGroupResponseFromJSON(jsonValue));
    }
    /**
     * CreateGroup creates a group.
     */
    async groupsServiceCreateGroup(parentResourceName, body) {
        const response = await this.groupsServiceCreateGroupRaw({ parentResourceName: parentResourceName, body: body });
        return await response.value();
    }
    /**
     * DeleteGroup deletes a group.
     */
    async groupsServiceDeleteGroupRaw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling groupsServiceDeleteGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/{resource_name}`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteGroup deletes a group.
     */
    async groupsServiceDeleteGroup(resourceName) {
        const response = await this.groupsServiceDeleteGroupRaw({ resourceName: resourceName });
        return await response.value();
    }
    /**
     * GetGroup gets a group using the organization ID and group name.
     */
    async groupsServiceGetGroupRaw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling groupsServiceGetGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/{resource_name}`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetGroupResponseFromJSON(jsonValue));
    }
    /**
     * GetGroup gets a group using the organization ID and group name.
     */
    async groupsServiceGetGroup(resourceName) {
        const response = await this.groupsServiceGetGroupRaw({ resourceName: resourceName });
        return await response.value();
    }
    /**
     * ListGroupMembers retrieves a list of member principals.
     */
    async groupsServiceListGroupMembersRaw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling groupsServiceListGroupMembers.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/{resource_name}/members`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamListGroupMembersResponseFromJSON(jsonValue));
    }
    /**
     * ListGroupMembers retrieves a list of member principals.
     */
    async groupsServiceListGroupMembers(resourceName, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.groupsServiceListGroupMembersRaw({ resourceName: resourceName, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListGroups lists the groups in an organization that match the optional filters.
     */
    async groupsServiceListGroupsRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling groupsServiceListGroups.');
        }
        const queryParameters = {};
        if (requestParameters.filterMemberPrincipalId !== undefined) {
            queryParameters['filter.member_principal_id'] = requestParameters.filterMemberPrincipalId;
        }
        if (requestParameters.filterGroupResourceIds) {
            queryParameters['filter.group_resource_ids'] = requestParameters.filterGroupResourceIds;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/iam/{parent_resource_name}/groups`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamListGroupsResponseFromJSON(jsonValue));
    }
    /**
     * ListGroups lists the groups in an organization that match the optional filters.
     */
    async groupsServiceListGroups(parentResourceName, filterMemberPrincipalId, filterGroupResourceIds, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.groupsServiceListGroupsRaw({ parentResourceName: parentResourceName, filterMemberPrincipalId: filterMemberPrincipalId, filterGroupResourceIds: filterGroupResourceIds, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * UpdateGroup updates a group\'s modifiable fields.
     */
    async groupsServiceUpdateGroupRaw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling groupsServiceUpdateGroup.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling groupsServiceUpdateGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/{resource_name}`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject16ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * UpdateGroup updates a group\'s modifiable fields.
     */
    async groupsServiceUpdateGroup(resourceName, body) {
        const response = await this.groupsServiceUpdateGroupRaw({ resourceName: resourceName, body: body });
        return await response.value();
    }
    /**
     * UpdateGroup updates a group\'s modifiable fields.
     */
    async groupsServiceUpdateGroup2Raw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling groupsServiceUpdateGroup2.');
        }
        if (requestParameters.group === null || requestParameters.group === undefined) {
            throw new runtime.RequiredError('group', 'Required parameter requestParameters.group was null or undefined when calling groupsServiceUpdateGroup2.');
        }
        const queryParameters = {};
        if (requestParameters.updateMask !== undefined) {
            queryParameters['update_mask'] = requestParameters.updateMask;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/{resource_name}`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudIamGroupToJSON(requestParameters.group),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * UpdateGroup updates a group\'s modifiable fields.
     */
    async groupsServiceUpdateGroup2(resourceName, group, updateMask) {
        const response = await this.groupsServiceUpdateGroup2Raw({ resourceName: resourceName, group: group, updateMask: updateMask });
        return await response.value();
    }
    /**
     * UpdateGroupMembers updates the principal memberships of a group.
     */
    async groupsServiceUpdateGroupMembersRaw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling groupsServiceUpdateGroupMembers.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling groupsServiceUpdateGroupMembers.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/{resource_name}/members`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject17ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * UpdateGroupMembers updates the principal memberships of a group.
     */
    async groupsServiceUpdateGroupMembers(resourceName, body) {
        const response = await this.groupsServiceUpdateGroupMembersRaw({ resourceName: resourceName, body: body });
        return await response.value();
    }
}
