/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * OnDemandStatus represents the status of the on-demand payment method for a
 * given BillingAccount.
 * @export
 * @enum {string}
 */
export var BillingAccountOnDemandStatus;
(function (BillingAccountOnDemandStatus) {
    BillingAccountOnDemandStatus["INVALID"] = "ON_DEMAND_INVALID";
    BillingAccountOnDemandStatus["UNSET"] = "ON_DEMAND_UNSET";
    BillingAccountOnDemandStatus["ACTIVE"] = "ON_DEMAND_ACTIVE";
    BillingAccountOnDemandStatus["DELETING"] = "ON_DEMAND_DELETING";
})(BillingAccountOnDemandStatus || (BillingAccountOnDemandStatus = {}));
export function BillingAccountOnDemandStatusFromJSON(json) {
    return BillingAccountOnDemandStatusFromJSONTyped(json, false);
}
export function BillingAccountOnDemandStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function BillingAccountOnDemandStatusToJSON(value) {
    return value;
}
