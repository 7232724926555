import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

/**
 *
 * `NetworksFormsCreate` description here.
 *
 *
 * ```
 *  <Networks::Forms::Create
 *    @formState={{formState}}
 *    @regions{{regionsMap}}
 *    @providers={{providers}}
 *    @onFormStateChange={{onFormStateChange}}
 *    @onSubmit={{onSubmit}}
 *    @formDisabled={{formDisabled}}
 *    as |Form FormErrors|
 *  >
 *    <!-- BYOC: Bring your own content (allows for differences in Consul VS Vault VS Networking contexts.) -->
 *
 *    <Form.NetworkId />
 *    <Form.RegionSelect />
 *    <Form.CidrBlock />
 *
 *    <Form.Submit @text='Create Network' />
 *  </Networks::Forms::Create>
 * ```
 *
 * @class NetworksFormsCreate
 *
 */

export default class NetworksFormsCreateComponent extends Component {
  @tracked regions;

  constructor(owner, args) {
    super(owner, args);
    this.regions = args.regions[args.formState.provider] || [];
  }

  @task *onSubmitHandlerChain(event) {
    event.preventDefault();
    yield this.args.onSubmit.perform();
  }

  @action onNetworkIdChange({ target }) {
    this.args.onFormStateChange({ networkId: target.value });
  }

  @action onProviderChange({ target }) {
    this.regions = this.args.regions[target.value] || [];
    this.args.onFormStateChange({
      provider: target.value,
      region: this.regions[0],
    });
  }

  @action onRegionChange({ target }) {
    this.args.onFormStateChange({ region: target.value });
  }

  @action onCidrBlockChange({ target }) {
    this.args.onFormStateChange({ cidrBlock: target.value });
  }
}
