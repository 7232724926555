/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215PeerState;
(function (HashicorpCloudGlobalNetworkManager20220215PeerState) {
    HashicorpCloudGlobalNetworkManager20220215PeerState["UNDEFINED"] = "UNDEFINED";
    HashicorpCloudGlobalNetworkManager20220215PeerState["PENDING"] = "PENDING";
    HashicorpCloudGlobalNetworkManager20220215PeerState["ESTABLISHING"] = "ESTABLISHING";
    HashicorpCloudGlobalNetworkManager20220215PeerState["ACTIVE"] = "ACTIVE";
    HashicorpCloudGlobalNetworkManager20220215PeerState["FAILING"] = "FAILING";
    HashicorpCloudGlobalNetworkManager20220215PeerState["DELETING"] = "DELETING";
    HashicorpCloudGlobalNetworkManager20220215PeerState["TERMINATED"] = "TERMINATED";
})(HashicorpCloudGlobalNetworkManager20220215PeerState || (HashicorpCloudGlobalNetworkManager20220215PeerState = {}));
export function HashicorpCloudGlobalNetworkManager20220215PeerStateFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215PeerStateFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215PeerStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215PeerStateToJSON(value) {
    return value;
}
