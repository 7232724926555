import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class CostEstimatorQueryComponent extends Component {
  @service api;

  /** @type {Object} */
  @tracked error;

  /** @type {Object} */
  @tracked estimate;

  get req() {
    let {
      customizedInput = [],
      network,
      packageItemSelection = [],
      packageKey,
    } = this.args;
    return {
      organizationId: network?.location?.organizationId,
      body: {
        projectId: network?.location?.projectId,
        region: network?.location?.region,
        packageItemSelection: Array.isArray(packageItemSelection)
          ? packageItemSelection
          : [packageItemSelection],
        packageKey,
        customizedInput: Array.isArray(customizedInput)
          ? customizedInput
          : [customizedInput],
      },
    };
  }

  @task
  *query(req = {}) {
    try {
      this.estimate = yield this.api.billing.product.productServiceEstimateCost(
        req.organizationId,
        req.body,
      );
      this.error = null;
    } catch (e) {
      this.estimate = null;
      this.error = e;
    }
  }
}
