/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * PlatformType refers to the type of platform that the cluster was created with ex. HCP or HCS
 * It determines which version list is returned. NOTSET will default to the HCP list.
 * @export
 * @enum {string}
 */
export var HashicorpCloudConsul20210204PlatformType;
(function (HashicorpCloudConsul20210204PlatformType) {
    HashicorpCloudConsul20210204PlatformType["NOTSET"] = "NOTSET";
    HashicorpCloudConsul20210204PlatformType["HCP"] = "HCP";
    HashicorpCloudConsul20210204PlatformType["HCS"] = "HCS";
})(HashicorpCloudConsul20210204PlatformType || (HashicorpCloudConsul20210204PlatformType = {}));
export function HashicorpCloudConsul20210204PlatformTypeFromJSON(json) {
    return HashicorpCloudConsul20210204PlatformTypeFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204PlatformTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudConsul20210204PlatformTypeToJSON(value) {
    return value;
}
