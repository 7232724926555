/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Billing20201105ContractDetailsFromJSON(json) {
    return Billing20201105ContractDetailsFromJSONTyped(json, false);
}
export function Billing20201105ContractDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contractType': !exists(json, 'contract_type') ? undefined : json['contract_type'],
        'startDate': !exists(json, 'start_date') ? undefined : (new Date(json['start_date'])),
        'endDate': !exists(json, 'end_date') ? undefined : (new Date(json['end_date'])),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}
export function Billing20201105ContractDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contract_type': value.contractType,
        'start_date': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'end_date': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'amount': value.amount,
    };
}
