/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330DestinationStatusFromJSON, LogService20210330DestinationStatusToJSON, } from './';
export function LogService20210330TestDestinationResponseFromJSON(json) {
    return LogService20210330TestDestinationResponseFromJSONTyped(json, false);
}
export function LogService20210330TestDestinationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'requestId': !exists(json, 'request_id') ? undefined : json['request_id'],
        'status': !exists(json, 'status') ? undefined : LogService20210330DestinationStatusFromJSON(json['status']),
        'errorMessage': !exists(json, 'error_message') ? undefined : json['error_message'],
        'testPayload': !exists(json, 'test_payload') ? undefined : json['test_payload'],
    };
}
export function LogService20210330TestDestinationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'request_id': value.requestId,
        'status': LogService20210330DestinationStatusToJSON(value.status),
        'error_message': value.errorMessage,
        'test_payload': value.testPayload,
    };
}
