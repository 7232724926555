/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Vault Radar
 * API for managing Vault Radar.
 *
 * The version of the OpenAPI document: 2023-05-01
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function VaultRadar20230501TenantCreateResponseFromJSON(json) {
    return VaultRadar20230501TenantCreateResponseFromJSONTyped(json, false);
}
export function VaultRadar20230501TenantCreateResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !exists(json, 'type') ? undefined : json['type'],
        'state': !exists(json, 'state') ? undefined : json['state'],
    };
}
export function VaultRadar20230501TenantCreateResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'state': value.state,
    };
}
