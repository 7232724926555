/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudVagrant20220930RegistryFromJSON, HashicorpCloudVagrant20220930RegistryToJSON, } from './';
export function HashicorpCloudVagrant20220930ListRegistriesResponseFromJSON(json) {
    return HashicorpCloudVagrant20220930ListRegistriesResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930ListRegistriesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'registries': !exists(json, 'registries') ? undefined : (json['registries'].map(HashicorpCloudVagrant20220930RegistryFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudVagrant20220930ListRegistriesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'registries': value.registries === undefined ? undefined : (value.registries.map(HashicorpCloudVagrant20220930RegistryToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
