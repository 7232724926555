import { HashicorpCloudIamPrincipalType } from '@clients/cloud-iam';
import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';

export const principalTypesToMemberTypes: Record<
  HashicorpCloudIamPrincipalType,
  HashicorpCloudResourcemanagerPolicyBindingMemberType
> = {
  [HashicorpCloudIamPrincipalType.GROUP]:
    HashicorpCloudResourcemanagerPolicyBindingMemberType.GROUP,
  [HashicorpCloudIamPrincipalType.SERVICE]:
    HashicorpCloudResourcemanagerPolicyBindingMemberType.SERVICEPRINCIPAL,
  [HashicorpCloudIamPrincipalType.USER]:
    HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
  [HashicorpCloudIamPrincipalType.UNSPECIFIED]:
    HashicorpCloudResourcemanagerPolicyBindingMemberType.UNSET,
  [HashicorpCloudIamPrincipalType.INTERNALOPERATOR]:
    HashicorpCloudResourcemanagerPolicyBindingMemberType.UNSET,
};
