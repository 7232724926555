import Component from '@glimmer/component';

const ValidBreakpoints = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};

const ValidVariants = {
  image: 'image',
  video: 'video',
};

interface MediaCardSignature {
  Args: {
    alwaysStacked?: boolean;
    mediaTitle: string;
    onVideoPlay?: () => void;
    padding?: string;
    src: string;
    stackedUntilBreakpoint?: keyof typeof ValidBreakpoints;
    title: string;
    variant: keyof typeof ValidVariants;
    videoVariant?: string;
  };
  Blocks: {
    badges: [];
    content: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `ConsulMediaCard` displays media content (image or video) with descriptive text content.
 *
 *
 * ```
 * <MediaCard
 *   @src={{youtubeEmbedSrc}}
 *   @variant='video'
 *   @mediaTitle='Video description'
 *   @title='My media card title'
 *   @videoVariant='youtube'
 *   @onVideoPlay={{doThisWhenVideoPlays}}
 * />
 * ```
 *
 * @class ConsulMediaCard
 *
 */

export default class MediaCardComponent extends Component<MediaCardSignature> {
  /**
   *
   * `src` is either the embed url of a public YouTube video, or the source of an image
   * @argument src
   * @type {string}
   *
   */
  /**
   *
   * `mediaTitle` describes the media content
   * @argument mediaTitle
   * @type {string}
   *
   */
  /**
   *
   * `variant` describes the type of media shown. Valid options are: image, video
   * @argument variant
   * @type {string}
   *
   */
  /**
   *
   * `title` is the visible title of the media card
   * @argument title
   * @type {string}
   *
   */
  /**
   *
   * `padding` optionally specifies the padding between the card content and card border
   * @argument padding
   * @type {?string}
   *
   */
  /**
   *
   * `stackedUntilBreakpoint` optionally specifies the breakpoint at which the layout switches from being stacked to side-by-side.
   * Accepted values are: xs, sm, md, lg. If not provided or if an unknown value is provided, 'lg' is the default.
   * @argument stackedUntilBreakpoint
   * @type {?string}
   *
   */
  /**
   *
   * `alwaysStacked` optionally indicates that the card layout should be stacked at all breakpoints.
   * Use this argument OR `stackedUntilBreakpoint` - not both. This argument will take precedence if both are provided.
   * @argument alwaysStacked
   * @type {?boolean}
   *
   */
  /**
   *
   * `videoVariant` describes the video provider type. Supported variants: youtube. Default variant: youtube.
   * @argument videoVariant
   * @type {?string}
   *
   */
  /**
   *
   * `onVideoPlay` is an optional callback that gets called anytime the video is played.
   * @argument onVideoPlay
   * @type {?Function}
   *
   */

  /**
   * Returns the media variant with a default if not provided or if unknown value.
   * @method ConsulMediaCard#variant
   * @return {string}
   */
  get variant() {
    const defaultVariant = ValidVariants.video;
    let { variant = defaultVariant } = this.args;
    if (!(variant in ValidVariants)) {
      variant = defaultVariant;
    }
    return variant;
  }

  /**
   * Returns the breakpoint at which the layout switches from stacked to side by side.
   * Returns `null` if layout is always stacked.
   * @method ConsulMediaCard#stackedUntilBreakpoint
   * @return {string | null}
   */
  get stackedUntilBreakpoint() {
    if (this.args.alwaysStacked) {
      return null;
    }
    const defaultBreakpoint = 'lg';
    let { stackedUntilBreakpoint = defaultBreakpoint } = this.args;
    if (!ValidBreakpoints[stackedUntilBreakpoint]) {
      stackedUntilBreakpoint = defaultBreakpoint;
    }
    return stackedUntilBreakpoint;
  }

  /**
   * Returns the FlexGrid breakpoint values based on `stackedUntilBreakpoint`.
   * @method ConsulMediaCard#breakpoints
   * @return {Object}
   */
  get breakpoints() {
    return {
      xs: '12',
      ...(this.stackedUntilBreakpoint
        ? {
            [this.stackedUntilBreakpoint]: '6',
          }
        : {}),
    };
  }

  /**
   * Returns the class name necessary to add spacing below the media only when it is in a stacked layout.
   * @method ConsulMediaCard#stackedClass
   * @return {string}
   */
  get stackedClass() {
    return `stacked-${this.stackedUntilBreakpoint || 'always'}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MediaCard: typeof MediaCardComponent;
    'media-card': typeof MediaCardComponent;
  }
}
