import Component from '@glimmer/component';
import { DEBUG } from '@glimmer/env';

interface HvnSelectZeroStateDefaultContentSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
}

export default class FormInputsHvnSelectZeroStateDefaultContentComponent extends Component<HvnSelectZeroStateDefaultContentSignature> {
  constructor(
    owner: unknown,
    args: HvnSelectZeroStateDefaultContentSignature['Args']
  ) {
    super(owner, args);

    if (DEBUG) {
      console.error(
        `WHOOPS !!! You should ONLY see \`<HvnSelect.ZeroStateDefaultContent>\` as a fallback in testing environments.
Please provide a custom zero state for \`<HvnSelect>\` to render using by passing a \`<:zero-state>\` named block content instead.`
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormInputs::HvnSelect::ZeroStateDefaultContent': typeof FormInputsHvnSelectZeroStateDefaultContentComponent;
    'form-inputs/hvn-select/zero-state-default-content': typeof FormInputsHvnSelectZeroStateDefaultContentComponent;
  }
}
