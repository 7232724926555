/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudResourcemanagerProjectFromJSON, HashicorpCloudResourcemanagerProjectToJSON, } from './';
export function HashicorpCloudResourcemanagerProjectListResponseFromJSON(json) {
    return HashicorpCloudResourcemanagerProjectListResponseFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerProjectListResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'projects': !exists(json, 'projects') ? undefined : (json['projects'].map(HashicorpCloudResourcemanagerProjectFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudResourcemanagerProjectListResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'projects': value.projects === undefined ? undefined : (value.projects.map(HashicorpCloudResourcemanagerProjectToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
