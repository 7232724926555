/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudGlobalNetworkManager20220215ClusterFromJSON, HashicorpCloudGlobalNetworkManager20220215ClusterToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ListClustersResponseFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ListClustersResponseFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ListClustersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'clusters': !exists(json, 'clusters') ? undefined : (json['clusters'].map(HashicorpCloudGlobalNetworkManager20220215ClusterFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ListClustersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'clusters': value.clusters === undefined ? undefined : (value.clusters.map(HashicorpCloudGlobalNetworkManager20220215ClusterToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
