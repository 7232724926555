/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudInternalLocationLocationFromJSON, HashicorpCloudInternalLocationLocationToJSON, } from './';
export function HashicorpCloudVault20201125DeleteSentinelPolicyRequestFromJSON(json) {
    return HashicorpCloudVault20201125DeleteSentinelPolicyRequestFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125DeleteSentinelPolicyRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : HashicorpCloudInternalLocationLocationFromJSON(json['location']),
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'egpPolicy': !exists(json, 'egp_policy') ? undefined : json['egp_policy'],
        'rgpPolicy': !exists(json, 'rgp_policy') ? undefined : json['rgp_policy'],
    };
}
export function HashicorpCloudVault20201125DeleteSentinelPolicyRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': HashicorpCloudInternalLocationLocationToJSON(value.location),
        'cluster_id': value.clusterId,
        'namespace': value.namespace,
        'egp_policy': value.egpPolicy,
        'rgp_policy': value.rgpPolicy,
    };
}
