/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudGlobalNetworkManager20220215GetClusterAPIInfoResponseFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215GetClusterAPIInfoResponseFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215GetClusterAPIInfoResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'apiUrl': !exists(json, 'api_url') ? undefined : json['api_url'],
        'uiUrl': !exists(json, 'ui_url') ? undefined : json['ui_url'],
        'aclToken': !exists(json, 'acl_token') ? undefined : json['acl_token'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}
export function HashicorpCloudGlobalNetworkManager20220215GetClusterAPIInfoResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'api_url': value.apiUrl,
        'ui_url': value.uiUrl,
        'acl_token': value.aclToken,
        'version': value.version,
    };
}
