/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var Secrets20230613Tier;
(function (Secrets20230613Tier) {
    Secrets20230613Tier["UNKNOWN"] = "UNKNOWN";
    Secrets20230613Tier["FREE"] = "FREE";
    Secrets20230613Tier["STANDARD"] = "STANDARD";
    Secrets20230613Tier["PLUS"] = "PLUS";
})(Secrets20230613Tier || (Secrets20230613Tier = {}));
export function Secrets20230613TierFromJSON(json) {
    return Secrets20230613TierFromJSONTyped(json, false);
}
export function Secrets20230613TierFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Secrets20230613TierToJSON(value) {
    return value;
}
