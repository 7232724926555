/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointTerraformNocodeModuleFromJSON(json) {
    return HashicorpCloudWaypointTerraformNocodeModuleFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointTerraformNocodeModuleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'source': !exists(json, 'source') ? undefined : json['source'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'moduleId': !exists(json, 'module_id') ? undefined : json['module_id'],
    };
}
export function HashicorpCloudWaypointTerraformNocodeModuleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'source': value.source,
        'version': value.version,
        'module_id': value.moduleId,
    };
}
