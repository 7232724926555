import BaseAbility from './base-ability';

import {
  PREFIX_VAULT_PATHS_FILTER,
  VAULT_PATHS_FILTER_CREATE,
  VAULT_PATHS_FILTER_DELETE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = PREFIX_VAULT_PATHS_FILTER;

  get canCreate() {
    return this.permissions.has(VAULT_PATHS_FILTER_CREATE);
  }

  get canDelete() {
    return this.permissions.has(VAULT_PATHS_FILTER_DELETE);
  }
}
