/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import { PROJECT_PICKER_MAX_PROJECT_LENGTH } from '../utils/consts/project-picker.js';

interface ProjectPickerSignature {
  Args: {
    currentOrg: unknown;
    currentProject: unknown;
    organizations: unknown;
    projects: [];
    projectsNextToken: unknown;
  };
  Element: HTMLDivElement;
}

/**
 * `ProjectPicker` renders a dropdown that allows users to switch Project
 *  context in the HCP navigation app. It should only be rendered if an org
 *  is already in context.
 *
 * ```
 * <ProjectPicker
 *   @currentOrg={{this.currentOrg}}
 *   @currentProject={{this.currentProject}}
 *   @organizations={{this.model.organizations}}
 *   @projects={{this.model.projects}}
 *   @projectsNextToken={{this.userContext.projectsNextToken}}
 * />
 * ```
 *
 * @class ProjectPicker
 *
 */

export default class ProjectPickerComponent extends Component<ProjectPickerSignature> {
  @tracked maxProjectLength = PROJECT_PICKER_MAX_PROJECT_LENGTH;

  get currentOrg() {
    const { currentOrg } = this.args;
    return currentOrg;
  }

  get currentProject() {
    const { currentProject } = this.args;
    return currentProject;
  }

  get organizations() {
    const { organizations = [] } = this.args;
    return organizations;
  }

  get projects() {
    const { projects = [] } = this.args;
    return projects.slice(0, this.maxProjectLength);
  }

  get hasProjectListOverflowing() {
    const { projects = [] } = this.args;
    return projects.length > this.maxProjectLength;
  }

  get hasMoreProjectsPaginated() {
    const { projectsNextToken } = this.args;
    return projectsNextToken ? true : false;
  }

  @action
  closeDropdown(dropdown: any, event: any) {
    // only close if we're not focused on something inside the dropdown and if
    // the component is not destroyed.
    if (
      !event.currentTarget.contains(
        event.relatedTarget || document.activeElement
      ) &&
      (this.isDestroying || this.isDestroyed)
    ) {
      dropdown.actions.close();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ProjectPicker: typeof ProjectPickerComponent;
    'project-picker': typeof ProjectPickerComponent;
  }
}
