import { tracked, TrackedObject } from 'tracked-built-ins';
import { DateTime } from 'luxon';

import type { DateTimeUnit } from 'luxon';

export const MIN_ISO_DATE = '1970-01-01';
export const MAX_ISO_DATE = '2038-01-19';

const DATE_FORMAT = 'LLL d, yyyy, h:mm a';

/**
 * Accepts a unix timestamp and returns formatted datestring
 * @param {string} timestamp Unix timestamp value
 * @returns {string} Formatted dateString
 */
export function formatTimestamp(timestamp: number) {
  const dateTime = DateTime.fromSeconds(timestamp);
  const formattedDateTime = dateTime.toFormat(DATE_FORMAT);
  return formattedDateTime;
}

/**
 * Accepts an ISO DateTime string and returns the formatted date string
 * @param {string} isoDateTime ISO DateTime string
 * @returns {string} Formatted dateString
 */
export function formatDateTime(isoDateTime: string) {
  const dateTime = DateTime.fromISO(isoDateTime);
  const formattedDateTime = dateTime.toFormat(DATE_FORMAT);
  return formattedDateTime;
}

/**
 * Accepts a js Date object and returns the formatted date string
 * @param {string} dateObject js Date object
 * @returns {string} Formatted dateString
 */
export function formatDateObject(dateObject: Date) {
  const dateTime = DateTime.fromJSDate(dateObject);
  const formattedDateTime = dateTime.toFormat(DATE_FORMAT);
  return formattedDateTime;
}

export enum DateInterval {
  SECOND = 'Second',
  MINUTE = 'Minute',
  HOUR = 'Hour',
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  YEAR = 'Year',
}

interface EditableDateRangeProperties extends Record<PropertyKey, unknown> {
  dateRangeKey: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  isCustom: boolean;
}

export interface DateRangeConfig {
  maxLookbackInDays?: number;
  maxLookaheadInDays?: number;
  lookbackOnly?: boolean;
}

const customDateKeys = ['startDate', 'startTime', 'endDate', 'endTime'];

const pendingChangesInitializer: EditableDateRangeProperties = {
  dateRangeKey: '',
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  isCustom: false,
};

/**
 * Tracks and mutates the date/time state of a date-range-dropdown instance, as well as
 * providing methods to format elements of the dropdown label, as well as the output of the filter.
 */
export class DateRange {
  [key: string]: unknown;
  @tracked pendingChanges = new TrackedObject(pendingChangesInitializer);
  @tracked hasPendingChanges = false;
  @tracked dateRangeKey = '';
  @tracked startDate = '';
  @tracked startTime = '';
  @tracked endDate = '';
  @tracked endTime = '';
  @tracked isCustom = false;
  @tracked customDateRangeString = '';
  maxLookbackInDays?: number;
  maxLookaheadInDays?: number;
  lookbackOnly: boolean;

  constructor(config: DateRangeConfig = {}) {
    const { maxLookbackInDays, maxLookaheadInDays, lookbackOnly } = config;
    this.maxLookbackInDays = maxLookbackInDays;
    this.maxLookaheadInDays = maxLookaheadInDays;
    this.lookbackOnly = lookbackOnly ?? false;
  }

  stagePendingChange<K extends keyof EditableDateRangeProperties>(
    key: K,
    value: EditableDateRangeProperties[K],
  ) {
    this.pendingChanges[key] = value;
    this.hasPendingChanges = true;
  }

  setCustomDateOrTime<K extends keyof EditableDateRangeProperties>(
    customType: K,
    value: EditableDateRangeProperties[K],
  ) {
    this.stagePendingChange('isCustom', true);
    this.stagePendingChange('dateRangeKey', 'custom');
    this.stagePendingChange(customType, value);

    // ensures that any other pending and existing values are not lost
    customDateKeys.forEach((dateKey) => {
      if (dateKey === customType) return; // current customType is already updated
      const oldValue = this[dateKey];
      const newValue = this.pendingChanges[dateKey];
      const updateValue = newValue || oldValue;
      this.stagePendingChange(dateKey, updateValue);
    });
  }

  get dateRangeString() {
    return this.isCustom
      ? this.convertCustomDateRange()
      : this.convertPresetDateRange(this.dateRangeKey);
  }

  // We don't want to change the filter state until the user actually submits the values.
  // Therefore, we stash the changes until the filter is applied, and only then
  // do we actually update the filter values and reset the stash.
  applyPendingChanges() {
    Object.entries(this.pendingChanges).forEach(([key, value]) => {
      this[key] = value;
    });

    this.resetPendingChanges();
  }

  convertCustomDateRange() {
    const rangeStartString = this.startTime
      ? `${this.startDate}T${this.startTime}`
      : this.startDate;

    /**
     * If endTime is undefined it defaults to the start of
     * the selected day in the local timezone unless we specify
     * the time to be the end of the day.
     * */
    const rangeEndString = this.startTime
      ? `${this.endDate}T${this.endTime}`
      : `${this.endDate}T23:59:59.000`;

    const rangeStartUTC = DateTime.fromISO(rangeStartString).toUnixInteger();
    const rangeEndUTC = this.lookbackOnly
      ? ''
      : DateTime.fromISO(rangeEndString).toUnixInteger();
    return `${rangeStartUTC || ''}:${rangeEndUTC || ''}`;
  }

  convertPresetDateRange(dateRangeKey: string) {
    if (!dateRangeKey) return '';
    const [intervalValue, intervalType] = dateRangeKey.split('-') as [
      intervalValue: string,
      intervalType: string,
    ];
    /**
     * Snap preset ranges to the whole interval including the current one
     * For example 7 days would be 7 full days including today and 24 hours
     * would be 24 whole hours including the current hour.
     * To accomplish this we can take the current time and round to the end of
     * the current interval. Then subtract the exact amount of intervals and add 1 ms
     * to make it the start of that range.
     */
    const end = DateTime.now().endOf(intervalType as DateTimeUnit);
    const start = end
      .minus({ [intervalType]: intervalValue })
      .plus({ milliseconds: 1 });
    const endString = this.lookbackOnly ? '' : end.toUnixInteger();
    return `${start.toUnixInteger()}:${endString}`;
  }

  setCustomDateRangeDisplayString() {
    if (!this.isCustom) {
      return;
    }
    const startDateDisplay = DateTime.fromISO(this.startDate).toFormat(
      'M/d/yyyy',
    );

    if (!this.endDate) {
      this.customDateRangeString = startDateDisplay;
      return;
    }

    const endDateDisplay = DateTime.fromISO(this.endDate).toFormat('M/d/yyyy');
    this.customDateRangeString = `${startDateDisplay} to ${endDateDisplay}`;
  }

  resetPendingChanges() {
    this.pendingChanges = new TrackedObject(pendingChangesInitializer);
    this.hasPendingChanges = false;
  }

  resetState() {
    this.dateRangeKey = '';
    this.startDate = '';
    this.startTime = '';
    this.endDate = '';
    this.endTime = '';
    this.isCustom = false;
    this.customDateRangeString = '';
  }
}
