/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus;
(function (HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus) {
    HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus["UNDEFINED"] = "PEERING_UNDEFINED";
    HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus["CONNECTING"] = "PEERING_CONNECTING";
    HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus["ACTIVE"] = "PEERING_ACTIVE";
    HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus["DELETING"] = "PEERING_DELETING";
    HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus["FAILING"] = "PEERING_FAILING";
    HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus["UNSYNCED"] = "PEERING_UNSYNCED";
})(HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus || (HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatus = {}));
export function HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatusFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatusFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatusToJSON(value) {
    return value;
}
