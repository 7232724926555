import packagesDataTiers from './packages-data-tiers.ts';

import type { BillingAvailablePackage } from '../types/api.ts';

export default function packagesDataTier(
  packages: BillingAvailablePackage[] | undefined,
  { resourceType, tierKey }: { resourceType?: string; tierKey?: string } = {},
): BillingAvailablePackage | undefined {
  if (!resourceType || !tierKey) return;

  const tiers = packagesDataTiers(packages, { resourceType });

  const tier = tiers.find((t) => t.key === tierKey);

  return tier;
}
