/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * SnapshotState represents the different states a snapshot can be in.
 *
 *  - QUEUED: QUEUED is used for snapshots that haven’t started and are waiting for a cluster operation to finish before starting.
 *  - CREATING: CREATING is used for snapshots that are creating.
 *  - CREATING_FAILED: CREATING_FAILED is used for snapshots that failed creating.
 *  - READY: READY is used for snapshots that are ready to be restored.
 *  - DELETING: DELETING is used for snapshots that are deleting.
 *  - DELETING_FAILED: DELETING_FAILED is used for snapshots that failed deleting.
 *  - RESTORING: RESTORING is used for snapshots that are being restored.
 *
 * Progress can be tracked using cluster operations.
 * @export
 * @enum {string}
 */
export var HashicorpCloudConsul20210204SnapshotSnapshotState;
(function (HashicorpCloudConsul20210204SnapshotSnapshotState) {
    HashicorpCloudConsul20210204SnapshotSnapshotState["STATEUNSET"] = "STATE_UNSET";
    HashicorpCloudConsul20210204SnapshotSnapshotState["QUEUED"] = "QUEUED";
    HashicorpCloudConsul20210204SnapshotSnapshotState["CREATING"] = "CREATING";
    HashicorpCloudConsul20210204SnapshotSnapshotState["CREATINGFAILED"] = "CREATING_FAILED";
    HashicorpCloudConsul20210204SnapshotSnapshotState["READY"] = "READY";
    HashicorpCloudConsul20210204SnapshotSnapshotState["DELETING"] = "DELETING";
    HashicorpCloudConsul20210204SnapshotSnapshotState["DELETINGFAILED"] = "DELETING_FAILED";
    HashicorpCloudConsul20210204SnapshotSnapshotState["RESTORING"] = "RESTORING";
})(HashicorpCloudConsul20210204SnapshotSnapshotState || (HashicorpCloudConsul20210204SnapshotSnapshotState = {}));
export function HashicorpCloudConsul20210204SnapshotSnapshotStateFromJSON(json) {
    return HashicorpCloudConsul20210204SnapshotSnapshotStateFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204SnapshotSnapshotStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudConsul20210204SnapshotSnapshotStateToJSON(value) {
    return value;
}
