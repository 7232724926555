/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128GatewayFromJSON, Secrets20231128GatewayToJSON, } from './';
export function Secrets20231128ListGatewayPoolGatewaysResponseFromJSON(json) {
    return Secrets20231128ListGatewayPoolGatewaysResponseFromJSONTyped(json, false);
}
export function Secrets20231128ListGatewayPoolGatewaysResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gateways': !exists(json, 'gateways') ? undefined : (json['gateways'].map(Secrets20231128GatewayFromJSON)),
    };
}
export function Secrets20231128ListGatewayPoolGatewaysResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'gateways': value.gateways === undefined ? undefined : (value.gateways.map(Secrets20231128GatewayToJSON)),
    };
}
