/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202BucketAncestryParentChannelIterationFromJSON, HashicorpCloudPacker20221202BucketAncestryParentChannelIterationToJSON, } from './';
export function HashicorpCloudPacker20221202BucketAncestryParentFromJSON(json) {
    return HashicorpCloudPacker20221202BucketAncestryParentFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202BucketAncestryParentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bucketSlug': !exists(json, 'bucket_slug') ? undefined : json['bucket_slug'],
        'iterationId': !exists(json, 'iteration_id') ? undefined : json['iteration_id'],
        'iterationIncrementalVersion': !exists(json, 'iteration_incremental_version') ? undefined : json['iteration_incremental_version'],
        'iterationFingerprint': !exists(json, 'iteration_fingerprint') ? undefined : json['iteration_fingerprint'],
        'channel': !exists(json, 'channel') ? undefined : json['channel'],
        'channelIteration': !exists(json, 'channel_iteration') ? undefined : HashicorpCloudPacker20221202BucketAncestryParentChannelIterationFromJSON(json['channel_iteration']),
    };
}
export function HashicorpCloudPacker20221202BucketAncestryParentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bucket_slug': value.bucketSlug,
        'iteration_id': value.iterationId,
        'iteration_incremental_version': value.iterationIncrementalVersion,
        'iteration_fingerprint': value.iterationFingerprint,
        'channel': value.channel,
        'channel_iteration': HashicorpCloudPacker20221202BucketAncestryParentChannelIterationToJSON(value.channelIteration),
    };
}
