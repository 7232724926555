import { PREFIX, ACTIONS } from './index.ts';

// resources
const RESOURCE_INVITATION = 'hashicorp_invitation';
const RESOURCE_NETWORK_HVN = 'hashicorp_network_hvn';
export const RESOURCE_NETWORK_PEERING = 'hashicorp_network_peering';
const RESOURCE_NETWORK_ROUTE = 'hashicorp_network_route';
export const RESOURCE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT =
  'hashicorp_network_transit_gateway_attachment';
export const RESOURCE_ORGANIZATION = 'hashicorp_organization';
const RESOURCE_PROJECT = 'hashicorp_project';
const RESOURCE_SERVICE_PRINCIPAL = 'hashicorp_service_principal';
const RESOURCE_SERVICE_PRINCIPAL_KEY = 'hashicorp_service_principal_key';
const RESOURCE_USER = 'hashicorp_user';

// UI elements
const UI_AZURE_PEERING_TERRAFORM_TAB_DOWNLOAD_TF =
  'azure_peering_terraform_tab_download_tf';
const UI_NETWORK_EMBEDDED_MEDIA = 'network_embedded_media';

// analytics event strings
export const AZURE_PEERING_TERRAFORM_TAB_DOWNLOAD_TF_CLICKED = `${PREFIX}_${UI_AZURE_PEERING_TERRAFORM_TAB_DOWNLOAD_TF}_${ACTIONS.CLICKED}`;
export const INVITATION_RESENT = `${PREFIX}_${RESOURCE_INVITATION}_resend_${ACTIONS.CLICKED}`;
export const NETWORK_EMBEDDED_MEDIA_PLAYED = `${PREFIX}_${UI_NETWORK_EMBEDDED_MEDIA}_${ACTIONS.PLAYED}`;
export const NETWORK_SUBMITTED = `${PREFIX}_${RESOURCE_NETWORK_HVN}_${ACTIONS.SUBMITTED}`;
export const ORGANIZATION_CREATED = `${PREFIX}_${RESOURCE_ORGANIZATION}_${ACTIONS.CREATED}`;
export const ORGANIZATION_UPDATED = `${PREFIX}_${RESOURCE_ORGANIZATION}_${ACTIONS.UPDATE_SUBMITTED}`;
export const PEERING_SUBMITTED = `${PREFIX}_${RESOURCE_NETWORK_PEERING}_${ACTIONS.SUBMITTED}`;
export const PROJECT_CREATED = `${PREFIX}_${RESOURCE_PROJECT}_${ACTIONS.CREATED}`;
export const ROUTE_SUBMITTED = `${PREFIX}_${RESOURCE_NETWORK_ROUTE}_${ACTIONS.SUBMITTED}`;
export const SERVICE_PRINCIPAL_KEY_SUBMITTED = `${PREFIX}_${RESOURCE_SERVICE_PRINCIPAL_KEY}_${ACTIONS.SUBMITTED}`;
export const SERVICE_PRINCIPAL_SUBMITTED = `${PREFIX}_${RESOURCE_SERVICE_PRINCIPAL}_${ACTIONS.SUBMITTED}`;
export const TRANSIT_GATEWAY_ATTACHMENT_SUBMITTED = `${PREFIX}_${RESOURCE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT}_${ACTIONS.SUBMITTED}`;
export const USER_LOGGED_IN = `${PREFIX}_${RESOURCE_USER}_logged_in`;
export const USER_SIGNED_UP = `${PREFIX}_${RESOURCE_USER}_signed_up`;

/**
 * User clicks the submit button on the create and edit user, service principal, and groups form on project level
 * @param {string} metadata.resourceMemberType - resource member type string
 * @param {string} metadata.pageType - name of page type string
 * @param {array} metadata.rolesAssigned - array of roles assigned to the current principal
 * @param {array} metadata.rolesAdded - array of roles added to the current principal
 * @param {array} metadata.roleRemoved - array of roles removed to the current principal
 * @param {string} metadata.projectId - project id string
 * @param {string} metadata.principalId - principal id string
 */
export const PROJECT_IAM_ASSIGNMENT = `${PREFIX}_${RESOURCE_PROJECT}_iam_policy_updated`;

/**
 * User clicks the submit button in the add-assignment form of the role assignments engine
 * @param {string} metadata.principalType - resource member type string
 * @param {string} metadata.pageType - name of page type string
 * @param {array} metadata.rolesAssigned - array of roles assigned to the current principal
 * @param {array} metadata.rolesAdded - array of roles added to the current principal
 * @param {array} metadata.roleRemoved - array of roles removed to the current principal
 * @param {string} metadata.scopeId - scope id string
 * @param {string} metadata.scopeName - scope id string
 * @param {string} metadata.scopeType - scope id string
 * @param {string} metadata.principalId - principal id string
 */
export const ROLE_ASSIGNMENTS_ON_SUBMIT = 'role_assignments_on_submit';
