/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105GeoFromJSON, Billing20201105GeoToJSON, LocationLinkFromJSON, LocationLinkToJSON, StatementResourceFlexibleConsumptionMetadataFromJSON, StatementResourceFlexibleConsumptionMetadataToJSON, StatementResourceLineItemFromJSON, StatementResourceLineItemToJSON, } from './';
export function Billing20201105StatementResourceFromJSON(json) {
    return Billing20201105StatementResourceFromJSONTyped(json, false);
}
export function Billing20201105StatementResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'resourceLink': !exists(json, 'resource_link') ? undefined : LocationLinkFromJSON(json['resource_link']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'flexibleConsumptionMetadata': !exists(json, 'flexible_consumption_metadata') ? undefined : StatementResourceFlexibleConsumptionMetadataFromJSON(json['flexible_consumption_metadata']),
        'lineItems': !exists(json, 'line_items') ? undefined : (json['line_items'].map(StatementResourceLineItemFromJSON)),
        'activeFrom': !exists(json, 'active_from') ? undefined : (new Date(json['active_from'])),
        'activeUntil': !exists(json, 'active_until') ? undefined : (new Date(json['active_until'])),
        'geo': !exists(json, 'geo') ? undefined : Billing20201105GeoFromJSON(json['geo']),
        'commonResourceAttributes': !exists(json, 'common_resource_attributes') ? undefined : json['common_resource_attributes'],
    };
}
export function Billing20201105StatementResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'resource_link': LocationLinkToJSON(value.resourceLink),
        'amount': value.amount,
        'flexible_consumption_metadata': StatementResourceFlexibleConsumptionMetadataToJSON(value.flexibleConsumptionMetadata),
        'line_items': value.lineItems === undefined ? undefined : (value.lineItems.map(StatementResourceLineItemToJSON)),
        'active_from': value.activeFrom === undefined ? undefined : (value.activeFrom.toISOString()),
        'active_until': value.activeUntil === undefined ? undefined : (value.activeUntil.toISOString()),
        'geo': Billing20201105GeoToJSON(value.geo),
        'common_resource_attributes': value.commonResourceAttributes,
    };
}
