import Component from '@glimmer/component';

/**
 *
 * A banner like alert used to display static Learn page content
 * on the Peering Create page providing the user context
 *
 * ```
 * <Networks::Cards::Learn/>
 * ```
 *
 * @class NetworksCardsLearn
 *
 */

export default class NetworksCardsLearnComponent extends Component {}
