/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointAgentGroupFromJSON, HashicorpCloudWaypointAgentGroupToJSON, } from './';
export function HashicorpCloudWaypointListAgentGroupsResponseFromJSON(json) {
    return HashicorpCloudWaypointListAgentGroupsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointListAgentGroupsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'groups': !exists(json, 'groups') ? undefined : (json['groups'].map(HashicorpCloudWaypointAgentGroupFromJSON)),
    };
}
export function HashicorpCloudWaypointListAgentGroupsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'groups': value.groups === undefined ? undefined : (value.groups.map(HashicorpCloudWaypointAgentGroupToJSON)),
    };
}
