import type {
  HashicorpCloudResourcemanagerPolicyBinding,
  HashicorpCloudResourcemanagerPolicy,
  HashicorpCloudResourcemanagerPolicyBindingMemberType,
} from '@clients/cloud-resource-manager';
import {
  ROLE_TYPE_ADMIN,
  ROLE_TYPE_BROWSER,
  ROLE_TYPE_CONTRIBUTOR,
  ROLE_TYPE_OWNER,
  ROLE_TYPE_VIEWER,
} from './cloud-iam-rbac-roles.ts';

interface DeconstructedPolicyMember {
  /** memberId the uuid of the member in a policy */
  memberId: string;
  /** roleId the role id of a policy binding */
  roleId: string | undefined;
  /** memberType the type of member in a policy */
  memberType: HashicorpCloudResourcemanagerPolicyBindingMemberType | undefined;
}

export default class IamPolicy {
  /**
   * "Edit" Policy permissions list based on resource-manager.organizations.set-iam-policy.
   *
   * @link https://github.com/hashicorp/cloud-resource-manager/blob/21619d02df922b5a13580e254e26c674844b7f9b/models/migrations/20200904155948_resource_manager_permissions.up.sql#L11
   */
  setIamPolicyPermissions = [ROLE_TYPE_ADMIN.value, ROLE_TYPE_OWNER.value];

  /**
   * "View" Policy permissions list based on resource-manager.organizations.get-iam-policy.
   *
   * @link https://github.com/hashicorp/cloud-resource-manager/blob/21619d02df922b5a13580e254e26c674844b7f9b/models/migrations/20200904155948_resource_manager_permissions.up.sql#L10

   */
  getIamPolicyPermissions = [
    ROLE_TYPE_ADMIN.value,
    ROLE_TYPE_CONTRIBUTOR.value,
    ROLE_TYPE_OWNER.value,
  ];

  basicRoles = [
    ROLE_TYPE_ADMIN.value,
    ROLE_TYPE_BROWSER.value,
    ROLE_TYPE_CONTRIBUTOR.value,
    ROLE_TYPE_OWNER.value,
    ROLE_TYPE_VIEWER.value,
  ];

  _policy: HashicorpCloudResourcemanagerPolicy;
  members: Record<string, DeconstructedPolicyMember> = {};

  /** Instantiates a new IamPolicy class. */
  constructor(policy: HashicorpCloudResourcemanagerPolicy) {
    // Save this policy for later. We'll need it when reconstructing.
    this._policy = policy;

    // Make the policy into a map of members.
    this.deconstruct(policy);
  }

  /** Returns flag for if a member is the owner of the organization */
  isMemberOwner(memberId: string): boolean {
    // @ts-expect-error: todo fix types @tcie
    const { roleId } = this.getMemberById(memberId);
    return roleId === ROLE_TYPE_OWNER.value;
  }

  /** Returns flag for if a member can edit the IAM policy. */
  canMemberEditPolicy(memberId: string): boolean {
    // @ts-expect-error: todo fix types @tcie
    const { roleId } = this.getMemberById(memberId);
    return Boolean(roleId && this.setIamPolicyPermissions.includes(roleId));
  }

  /** Adjusts the member role to a new binding roleId in the member map. */
  changeMemberRole(
    memberId: string,
    roleId: string
  ): DeconstructedPolicyMember | null {
    // Let's utilize the generic update function and set only the roleId. This
    // helps make this class' API a bit more user-friendly.
    // @ts-expect-error: todo fix types @tcie
    return this.updateMemberById(memberId, {
      roleId,
    });
  }

  /** Adds a member to the member map. */
  addMember(
    memberId: string,
    memberType: HashicorpCloudResourcemanagerPolicyBindingMemberType,
    roleId: string
  ): DeconstructedPolicyMember {
    this.members[memberId] = {
      memberId,
      memberType,
      roleId,
    };

    return this.members[memberId] as DeconstructedPolicyMember;
  }

  /**
   * Transforms the original policy document into a member map by reducing the
   * bindings and then further reducing the members.
   */
  deconstruct(policy: HashicorpCloudResourcemanagerPolicy) {
    // If there aren't any bindings then we're done here.
    if (!policy?.bindings) {
      this.members = {};
      return;
    }

    // If there *are* bindings then let's start by passing in an empty object
    // to this reducer which then gets passed another level deeper into another
    // reducer which operates on the members in that binding.
    this.members = policy.bindings.reduce<
      Record<string, DeconstructedPolicyMember>
    >((map, { roleId, members = [] }) => {
      // This IamPolicy manager only supports basic roles.
      // @ts-expect-error: todo fix types @tcie
      if (this.basicRoles.includes(roleId)) {
        // The original empty object makes it way down into each member and now
        // has access to the roleId, the memberId, and the rest of the member object.
        map = members.reduce((_map, { memberId, memberType }) => {
          // These are all set onto that object with the id being the memberId and
          // the value being the entire member object, including the memberId.
          // This makes it very cheap to make any changes to a member by their id.
          if (memberId) {
            _map[memberId] = {
              memberId,
              memberType,
              roleId,
            };
          }

          // This is still the same map but one level deeper. Double reducer!
          return _map;
        }, map);
      }

      return map;
    }, {});
  }

  /** This is simply a proxy call to `reconstruct`. */
  get() {
    return this.reconstruct();
  }

  /** Gets the member by id from the member map. */
  getMemberById(memberId: string) {
    return this.members[memberId] || {};
  }

  /** Returns the reconstructed policy in the original form. */
  reconstruct(): HashicorpCloudResourcemanagerPolicy {
    return {
      // Spread the original policy so we have all of the original properties.
      ...this._policy,

      // Let's re-build the bindings starting by getting the values of the
      // member map. This will give us an array of members so that we can start.
      // The original bindings property is an Array {Array.<PolicyBinding>}
      // so we'll start by giving this reducer an empty array and then go
      // member-by-member to add them to the correct group.
      bindings: Object.values(this.members).reduce<
        HashicorpCloudResourcemanagerPolicyBinding[]
      >((bindings, { roleId, ...member }) => {
        // Let's see if we have a binding object with the role of this member.
        let role = bindings.find((binding) => {
          return binding.roleId === roleId;
        });

        // If we don't, let's push it into the bindings for the first time.
        if (!role) {
          role = {
            roleId,
            members: [],
          };
          bindings.push(role);
        }

        // Push this member into the binding they belong to.
        role.members = role.members || [];
        role.members.push({
          ...member,
        });

        // Return the bindings array for another iteration.
        return bindings;
      }, []),
    };
  }

  /** Removes the member by id from the member map. */
  removeMemberById(memberId: string) {
    // Get outta here!
    delete this.members[memberId];
  }

  /** Updates the member by id from the member map. */
  updateMemberById(
    memberId: string,
    updates: Partial<DeconstructedPolicyMember> = {}
  ) {
    const member = this.members[memberId];

    // No member? No problem.
    if (!member) {
      return null;
    }

    // Spread the updates onto the member, updating any previous values.
    // @ts-expect-error: todo fix types @tcie
    this.members[memberId] = {
      ...this.members[memberId],
      ...updates,
    };

    // Return the member we just updated.
    return this.members[memberId];
  }
}
