export const TerraformQuickstartLaunchPoint = {
  Overview: 'overview',
  ConsulZeroState: 'consul-zero-state',
  ConsulList: 'consul-list',
};

export const getTerraformQuickstartClickedPayload = (origin: string) => {
  return {
    origin,
  };
};
