/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudInternalLocationLocationFromJSON, HashicorpCloudInternalLocationLocationToJSON, HashicorpCloudVault20201125ClusterConfigFromJSON, HashicorpCloudVault20201125ClusterConfigToJSON, HashicorpCloudVault20201125ClusterCreationMetadataFromJSON, HashicorpCloudVault20201125ClusterCreationMetadataToJSON, HashicorpCloudVault20201125ClusterDNSNamesFromJSON, HashicorpCloudVault20201125ClusterDNSNamesToJSON, HashicorpCloudVault20201125ClusterNotificationFromJSON, HashicorpCloudVault20201125ClusterNotificationToJSON, HashicorpCloudVault20201125ClusterPerformanceReplicationInfoFromJSON, HashicorpCloudVault20201125ClusterPerformanceReplicationInfoToJSON, HashicorpCloudVault20201125ClusterStateFromJSON, HashicorpCloudVault20201125ClusterStateToJSON, } from './';
export function HashicorpCloudVault20201125ClusterFromJSON(json) {
    return HashicorpCloudVault20201125ClusterFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125ClusterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudInternalLocationLocationFromJSON(json['location']),
        'state': !exists(json, 'state') ? undefined : HashicorpCloudVault20201125ClusterStateFromJSON(json['state']),
        'config': !exists(json, 'config') ? undefined : HashicorpCloudVault20201125ClusterConfigFromJSON(json['config']),
        'dnsNames': !exists(json, 'dns_names') ? undefined : HashicorpCloudVault20201125ClusterDNSNamesFromJSON(json['dns_names']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'currentVersion': !exists(json, 'current_version') ? undefined : json['current_version'],
        'performanceReplicationInfo': !exists(json, 'performance_replication_info') ? undefined : HashicorpCloudVault20201125ClusterPerformanceReplicationInfoFromJSON(json['performance_replication_info']),
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'notifications': !exists(json, 'notifications') ? undefined : (json['notifications'].map(HashicorpCloudVault20201125ClusterNotificationFromJSON)),
        'creationMetadata': !exists(json, 'creation_metadata') ? undefined : HashicorpCloudVault20201125ClusterCreationMetadataFromJSON(json['creation_metadata']),
    };
}
export function HashicorpCloudVault20201125ClusterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'location': HashicorpCloudInternalLocationLocationToJSON(value.location),
        'state': HashicorpCloudVault20201125ClusterStateToJSON(value.state),
        'config': HashicorpCloudVault20201125ClusterConfigToJSON(value.config),
        'dns_names': HashicorpCloudVault20201125ClusterDNSNamesToJSON(value.dnsNames),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'current_version': value.currentVersion,
        'performance_replication_info': HashicorpCloudVault20201125ClusterPerformanceReplicationInfoToJSON(value.performanceReplicationInfo),
        'resource_id': value.resourceId,
        'notifications': value.notifications === undefined ? undefined : (value.notifications.map(HashicorpCloudVault20201125ClusterNotificationToJSON)),
        'creation_metadata': HashicorpCloudVault20201125ClusterCreationMetadataToJSON(value.creationMetadata),
    };
}
