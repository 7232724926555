// NOTE: This block list of countries is derived from the RFC SEC-018 for HCP's geoblocking.
// SEE:  https://docs.google.com/document/d/1PJptwq1ZWNqg_Q5FBjFZfbv47YHwGS3uMNZRO6rDAvk
export const COUNTRY_CODES_BLOCK_LIST = [
  'BY', // Belarus
  'BA', // Bosnia & Herzegovina
  'CF', // Central African Republic
  'CN', // China
  'CD', // Democratic Republic of the Congo
  'CU', // Cuba
  'GH', // Ghana
  'IR', // Iran
  'IQ', // Iraq
  'LB', // Lebanon
  'LR', // Liberia
  'LY', // Libya
  'KP', // North Korea
  'QA', // Qatar
  'RU', // Russia
  'SA', // Saudi Arabia
  'SO', // Somalia
  'SS', // South Sudan
  'SD', // Sudan
  'SY', // Syria
  'VE', // Venezuela
  'YE', // Yemen
  'ZW', // Zimbabwe
  // NOTE: 'XK' Kosovo is under US embargo and listed in ISO 3166-1 Alpha-2,
  //       however XK is NOT in ember-i18n-iso-countries NOR available in AWS Cloudfront.
];
