/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudResourcemanagerProjectCreateRequestToJSON, HashicorpCloudResourcemanagerProjectCreateResponseFromJSON, HashicorpCloudResourcemanagerProjectDeleteResponseFromJSON, HashicorpCloudResourcemanagerProjectGetIamPolicyResponseFromJSON, HashicorpCloudResourcemanagerProjectGetProjectsCountResponseFromJSON, HashicorpCloudResourcemanagerProjectGetResponseFromJSON, HashicorpCloudResourcemanagerProjectListResponseFromJSON, HashicorpCloudResourcemanagerProjectSetIamPolicyResponseFromJSON, HashicorpCloudResourcemanagerProjectTestIamPermissionsResponseFromJSON, ProjectSetDescriptionRequestSeeProjectServiceSetDescriptionToJSON, ProjectSetIamPolicyRequestSeeProjectServiceSetIamPolicyToJSON, ProjectSetNameRequestSeeProjectServiceSetNameToJSON, SeeProjectServiceTestIamPermissionsToJSON, } from '../models';
/**
 *
 */
export class ProjectServiceApi extends runtime.BaseAPI {
    /**
     * Create creates a new project.
     */
    async projectServiceCreateRaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling projectServiceCreate.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudResourcemanagerProjectCreateRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerProjectCreateResponseFromJSON(jsonValue));
    }
    /**
     * Create creates a new project.
     */
    async projectServiceCreate(body) {
        const response = await this.projectServiceCreateRaw({ body: body });
        return await response.value();
    }
    /**
     * Delete updates the state of a project to `DELETED`.
     */
    async projectServiceDeleteRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling projectServiceDelete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerProjectDeleteResponseFromJSON(jsonValue));
    }
    /**
     * Delete updates the state of a project to `DELETED`.
     */
    async projectServiceDelete(id) {
        const response = await this.projectServiceDeleteRaw({ id: id });
        return await response.value();
    }
    /**
     * Get retrieves the requested project by ID. A not found status will be returned for projects in the DELETED state.
     */
    async projectServiceGetRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling projectServiceGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerProjectGetResponseFromJSON(jsonValue));
    }
    /**
     * Get retrieves the requested project by ID. A not found status will be returned for projects in the DELETED state.
     */
    async projectServiceGet(id) {
        const response = await this.projectServiceGetRaw({ id: id });
        return await response.value();
    }
    /**
     * GetIamPolicy retrieves the user managed IAM policy for a project.
     */
    async projectServiceGetIamPolicyRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling projectServiceGetIamPolicy.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/projects/{id}/iam-policy`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerProjectGetIamPolicyResponseFromJSON(jsonValue));
    }
    /**
     * GetIamPolicy retrieves the user managed IAM policy for a project.
     */
    async projectServiceGetIamPolicy(id) {
        const response = await this.projectServiceGetIamPolicyRaw({ id: id });
        return await response.value();
    }
    /**
     * GetProjectsCount returns the count of projects within a parent scope.
     */
    async projectServiceGetProjectsCountRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.scopeType !== undefined) {
            queryParameters['scope.type'] = requestParameters.scopeType;
        }
        if (requestParameters.scopeId !== undefined) {
            queryParameters['scope.id'] = requestParameters.scopeId;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/projects/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerProjectGetProjectsCountResponseFromJSON(jsonValue));
    }
    /**
     * GetProjectsCount returns the count of projects within a parent scope.
     */
    async projectServiceGetProjectsCount(scopeType, scopeId) {
        const response = await this.projectServiceGetProjectsCountRaw({ scopeType: scopeType, scopeId: scopeId });
        return await response.value();
    }
    /**
     * List list the projects the caller has access to.
     */
    async projectServiceListRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.scopeType !== undefined) {
            queryParameters['scope.type'] = requestParameters.scopeType;
        }
        if (requestParameters.scopeId !== undefined) {
            queryParameters['scope.id'] = requestParameters.scopeId;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerProjectListResponseFromJSON(jsonValue));
    }
    /**
     * List list the projects the caller has access to.
     */
    async projectServiceList(scopeType, scopeId, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.projectServiceListRaw({ scopeType: scopeType, scopeId: scopeId, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * SetDescription sets the requested project\'s description.
     */
    async projectServiceSetDescriptionRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling projectServiceSetDescription.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling projectServiceSetDescription.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/projects/{id}/description`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectSetDescriptionRequestSeeProjectServiceSetDescriptionToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * SetDescription sets the requested project\'s description.
     */
    async projectServiceSetDescription(id, body) {
        const response = await this.projectServiceSetDescriptionRaw({ id: id, body: body });
        return await response.value();
    }
    /**
     * SetIamPolicy sets the IAM policy for a project. Since this updates the full policy, the policy takes an \"etag\" argument that should match with the current policy stored in the system. This is to avoid concurrent writes erasing each other\'s data. Hence, the client should always get the current policy that includes the etag, modify the policy and then set the policy with the same etag. If there is no existing policy the etag must be empty.
     */
    async projectServiceSetIamPolicyRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling projectServiceSetIamPolicy.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling projectServiceSetIamPolicy.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/projects/{id}/iam-policy`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectSetIamPolicyRequestSeeProjectServiceSetIamPolicyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerProjectSetIamPolicyResponseFromJSON(jsonValue));
    }
    /**
     * SetIamPolicy sets the IAM policy for a project. Since this updates the full policy, the policy takes an \"etag\" argument that should match with the current policy stored in the system. This is to avoid concurrent writes erasing each other\'s data. Hence, the client should always get the current policy that includes the etag, modify the policy and then set the policy with the same etag. If there is no existing policy the etag must be empty.
     */
    async projectServiceSetIamPolicy(id, body) {
        const response = await this.projectServiceSetIamPolicyRaw({ id: id, body: body });
        return await response.value();
    }
    /**
     * SetName sets the requested project\'s name.
     */
    async projectServiceSetNameRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling projectServiceSetName.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling projectServiceSetName.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/projects/{id}/name`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectSetNameRequestSeeProjectServiceSetNameToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * SetName sets the requested project\'s name.
     */
    async projectServiceSetName(id, body) {
        const response = await this.projectServiceSetNameRaw({ id: id, body: body });
        return await response.value();
    }
    /**
     * TestIamPermissions returns a subset of the request permissions the calling principal has for the project.
     */
    async projectServiceTestIamPermissionsRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling projectServiceTestIamPermissions.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling projectServiceTestIamPermissions.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/projects/{id}/test-iam-permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SeeProjectServiceTestIamPermissionsToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerProjectTestIamPermissionsResponseFromJSON(jsonValue));
    }
    /**
     * TestIamPermissions returns a subset of the request permissions the calling principal has for the project.
     */
    async projectServiceTestIamPermissions(id, body) {
        const response = await this.projectServiceTestIamPermissionsRaw({ id: id, body: body });
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var ProjectServiceGetProjectsCountScopeTypeEnum;
(function (ProjectServiceGetProjectsCountScopeTypeEnum) {
    ProjectServiceGetProjectsCountScopeTypeEnum["UNKNOWN"] = "UNKNOWN";
    ProjectServiceGetProjectsCountScopeTypeEnum["ORGANIZATION"] = "ORGANIZATION";
    ProjectServiceGetProjectsCountScopeTypeEnum["PROJECT"] = "PROJECT";
})(ProjectServiceGetProjectsCountScopeTypeEnum || (ProjectServiceGetProjectsCountScopeTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ProjectServiceListScopeTypeEnum;
(function (ProjectServiceListScopeTypeEnum) {
    ProjectServiceListScopeTypeEnum["UNKNOWN"] = "UNKNOWN";
    ProjectServiceListScopeTypeEnum["ORGANIZATION"] = "ORGANIZATION";
    ProjectServiceListScopeTypeEnum["PROJECT"] = "PROJECT";
})(ProjectServiceListScopeTypeEnum || (ProjectServiceListScopeTypeEnum = {}));
