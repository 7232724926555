/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudResourcemanagerProjectFromJSON, HashicorpCloudResourcemanagerProjectToJSON, } from './';
export function HashicorpCloudResourcemanagerProjectGetResponseFromJSON(json) {
    return HashicorpCloudResourcemanagerProjectGetResponseFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerProjectGetResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'project': !exists(json, 'project') ? undefined : HashicorpCloudResourcemanagerProjectFromJSON(json['project']),
    };
}
export function HashicorpCloudResourcemanagerProjectGetResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'project': HashicorpCloudResourcemanagerProjectToJSON(value.project),
    };
}
