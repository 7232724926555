/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Size represents the different VM sizes a Consul cluster can be provisioned
 * with. These are bucketed as "t-shirt" sizes which each correspond to a
 * specific instance type depending on the cloud provider.
 * @export
 * @enum {string}
 */
export var HashicorpCloudConsul20210204CapacityConfigSize;
(function (HashicorpCloudConsul20210204CapacityConfigSize) {
    HashicorpCloudConsul20210204CapacityConfigSize["UNSET"] = "UNSET";
    HashicorpCloudConsul20210204CapacityConfigSize["XSMALL"] = "X_SMALL";
    HashicorpCloudConsul20210204CapacityConfigSize["SMALL"] = "SMALL";
    HashicorpCloudConsul20210204CapacityConfigSize["MEDIUM"] = "MEDIUM";
    HashicorpCloudConsul20210204CapacityConfigSize["LARGE"] = "LARGE";
})(HashicorpCloudConsul20210204CapacityConfigSize || (HashicorpCloudConsul20210204CapacityConfigSize = {}));
export function HashicorpCloudConsul20210204CapacityConfigSizeFromJSON(json) {
    return HashicorpCloudConsul20210204CapacityConfigSizeFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204CapacityConfigSizeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudConsul20210204CapacityConfigSizeToJSON(value) {
    return value;
}
