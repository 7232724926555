/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudInternalLocationLocationFromJSON, HashicorpCloudInternalLocationLocationToJSON, } from './';
export function HashicorpCloudVault20201125IsVaultPluginRegisteredRequestFromJSON(json) {
    return HashicorpCloudVault20201125IsVaultPluginRegisteredRequestFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125IsVaultPluginRegisteredRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : HashicorpCloudInternalLocationLocationFromJSON(json['location']),
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'pluginName': !exists(json, 'plugin_name') ? undefined : json['plugin_name'],
        'pluginType': !exists(json, 'plugin_type') ? undefined : json['plugin_type'],
        'pluginVersion': !exists(json, 'plugin_version') ? undefined : json['plugin_version'],
    };
}
export function HashicorpCloudVault20201125IsVaultPluginRegisteredRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': HashicorpCloudInternalLocationLocationToJSON(value.location),
        'cluster_id': value.clusterId,
        'plugin_name': value.pluginName,
        'plugin_type': value.pluginType,
        'plugin_version': value.pluginVersion,
    };
}
