import Component from '@glimmer/component';

interface TableSortBySignature {
  Args: {
    currentField: unknown;
    field: unknown;
    sortDescending: unknown;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLTableCellElement;
}

export default class TableSortByComponent extends Component<TableSortBySignature> {
  // Is the table sorted by the property this SortBy controls?
  get isActive() {
    return this.args.currentField === this.args.field;
  }

  get sortDescending() {
    if (this.args.sortDescending === 'false') return false;
    return !!this.args.sortDescending;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Table::SortBy': typeof TableSortByComponent;
    'table/sort-by': typeof TableSortByComponent;
  }
}
