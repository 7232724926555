/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Contacts Service
 * API for managing Contact information.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudEmailStartNewContactVerificationRequestFromJSON(json) {
  return HashicorpCloudEmailStartNewContactVerificationRequestFromJSONTyped(json, false);
}
export function HashicorpCloudEmailStartNewContactVerificationRequestFromJSONTyped(
  json,
  ignoreDiscriminator
) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    contactId: !exists(json, 'contact_id') ? undefined : json['contact_id'],
  };
}
export function HashicorpCloudEmailStartNewContactVerificationRequestToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    contact_id: value.contactId,
  };
}
