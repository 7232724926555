/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * ProjectState is the states the project can be in.
 * @export
 * @enum {string}
 */
export var HashicorpCloudResourcemanagerProjectProjectState;
(function (HashicorpCloudResourcemanagerProjectProjectState) {
    HashicorpCloudResourcemanagerProjectProjectState["UNKNOWN"] = "UNKNOWN";
    HashicorpCloudResourcemanagerProjectProjectState["ACTIVE"] = "ACTIVE";
    HashicorpCloudResourcemanagerProjectProjectState["DELETING"] = "DELETING";
    HashicorpCloudResourcemanagerProjectProjectState["DELETED"] = "DELETED";
})(HashicorpCloudResourcemanagerProjectProjectState || (HashicorpCloudResourcemanagerProjectProjectState = {}));
export function HashicorpCloudResourcemanagerProjectProjectStateFromJSON(json) {
    return HashicorpCloudResourcemanagerProjectProjectStateFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerProjectProjectStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudResourcemanagerProjectProjectStateToJSON(value) {
    return value;
}
