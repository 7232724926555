import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import type { ValueOrPromise } from '../utils/consts/split-list';

interface SplitListSignature<ListItemType, DetailType> {
  Args: {
    listData: ListItemType[];
    onSelectItem: (listItem: ListItemType) => ValueOrPromise<DetailType>;
    identifyListItem: (listItem: ListItemType) => string;
    initialSelectedItem?: ListItemType;
    updateSelectedItemOnChange: (
      selectedItemIdentifier: string,
      updatedListData: ListItemType[]
    ) => ListItemType;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `SplitList` displays a split view where the left side shows a list of items, and the right side shows details
 * for the currently selected item.
 *
 * `SplitList` provides an optional pagination control if the list data is paginated. Developers can provide content
 * inside the block component that will yield itself to the pagination area.
 *
 * The list on the left hand side is keyboard navigable using up/down arrow keys, and items are selectable via enter/space.
 *
 * `SplitList` yields four components directly
 * - `LeftHeaderContent` is an optional slot for content on the left side of the list above the list items and pagination controls
 * - `Pagination` provides optional pagination controls and information
 * - `LeftPane` provides a container for the left side list items
 * - `RightPane` provides a container for the right side detail content
 *
 * The left and right pane components yield further components and data
 * - `LeftPane` yields the array of `ListData`, as well as a `ListItem` component
 * - `RightPane` yields three pieces of data: `SelectItemError`, `SelectItemPending`, and `SelectedItem`
 *
 * `SplitList` takes arguments that extend two types, as defined by the consumer:
 * - `ListItemType` is the type of the data used to represent the left hand side list
 * - `DetailType` is the type of the data used to represent the right hand side details for a list item
 *
 * The `SplitList` top-level arguments are described below:
 * - `listData`: the array of data shown in the left side list
 * - `onSelectItem`: a callback that is executed when a list item is selected. This must return the associated DetailType for the selected item.
 * - `identifyListItem`: a function which produces a unique string identifier for a ListItemType
 * - `initialSelectedItem`: the consumer can optionally control which item is initially selected in the list
 * = `updateSelectedItemOnChange`: the consumer can optionally control which item is to be selected when the list updates
 *
 * ```
 * <SplitList
 *   @listData={{this.currentData}}
 *   @onSelectItem={{this.onSelectItem}}
 *   @identifyListItem={{this.identifyListItem}}
 *   @initialSelectedItem={{this.currentData.[1]}}
 *   @updateSelectedItemOnChange={{this.updateSelectedItemOnChange}} as |SL|
 * >
 *   <SL.LeftHeaderContent>
 *     Left header content
 *   </SL.LeftHeaderContent>
 *   <SL.Pagination
 *     @onNextPage={{this.onNext}}
 *   >
 *     <UserDefinedPaginationDOMToYield />
 *   </SL.Pagination>
 *   <SL.LeftPane as |LP|>
 *     {{#each LP.ListData as |listItem|}}
 *       <LP.ListItem @item={{listItem}}>
 *         <div>
 *           List item content
 *           {{listItem.id}}
 *         </div>
 *       </LP.ListItem>
 *     {{/each}}
 *   </SL.LeftPane>
 *   <SL.RightPane as |RP|>
 *     <div class='split-list-example-detail'>
 *       {{#if RP.SelectItemError}}
 *         Error
 *       {{else if RP.SelectItemPending}}
 *         Loading
 *       {{else if RP.SelectedItem}}
 *         Item detail
 *         <p>
 *           ID: {{RP.SelectedItem.id}}
 *         </p>
 *         <p>
 *           {{RP.SelectedItem.details}}
 *         </p>
 *       {{else}}
 *         Nothing selected
 *       {{/if}}
 *     </div>
 *   </SL.RightPane>
 * </SplitList>
 * ```
 *
 * @class SplitList
 *
 */

export default class SplitListComponent<
  ListItemType,
  DetailType,
> extends Component<SplitListSignature<ListItemType, DetailType>> {
  detailElementId = 'split-list-detail';
  focusInitialized = false;

  @tracked selectedItem: null | DetailType = null;
  @tracked selectedItemIdentifier: null | string = null;

  constructor(
    owner: unknown,
    args: SplitListSignature<ListItemType, DetailType>['Args']
  ) {
    super(owner, args);
    const { initialSelectedItem, identifyListItem } = args;
    if (initialSelectedItem) {
      this.selectedItemIdentifier = identifyListItem(initialSelectedItem);
      this.onSelectItem.perform(initialSelectedItem);
    }
  }

  onSelectItem = dropTask(
    waitFor(async (listItem: ListItemType) => {
      if (
        typeof this.args.identifyListItem !== 'function' ||
        typeof this.args.onSelectItem !== 'function'
      ) {
        return;
      }
      this.selectedItemIdentifier = this.args.identifyListItem?.(listItem);
      this.selectedItem = null;
      const selectedItem: DetailType = await this.args.onSelectItem?.(listItem);
      this.selectedItem = selectedItem;
    })
  );

  @action
  setupKeyboardControls(tablistElement: HTMLElement) {
    this.setupListItems(tablistElement);
    tablistElement.addEventListener(
      'focusin',
      this.initializeListFocus(tablistElement).bind(this),
      false
    );
  }

  @action
  listUpdated(tablistElement: HTMLElement) {
    // Update keyboard controls for list
    this.updateKeyboardControls(tablistElement);
    const { listData, identifyListItem, updateSelectedItemOnChange } =
      this.args;

    if (this.selectedItem && this.selectedItemIdentifier) {
      if (updateSelectedItemOnChange) {
        // select a specific item if custom update behaviour is specified
        const itemToSelect = updateSelectedItemOnChange(
          this.selectedItemIdentifier,
          listData
        );
        this.selectedItemIdentifier = identifyListItem(itemToSelect);
        this.onSelectItem.perform(itemToSelect);
      } else {
        // default behaviour: update selected item detail if an item is selected
        const selectedListItem = listData.find(
          (l) => identifyListItem(l) === this.selectedItemIdentifier
        );
        if (selectedListItem) {
          this.onSelectItem.perform(selectedListItem);
        }
      }
    }
  }

  @action
  updateKeyboardControls(tablistElement: HTMLElement) {
    this.setupListItems(tablistElement);
    this.setListFocus(tablistElement);
  }

  initializeListFocus(tablistElement: HTMLElement) {
    return () => {
      if (!this.focusInitialized) {
        // We only need to do this once because after the first focusin,
        // the items will be initialized with the correct focus attributes
        // and one single item will be focusable.
        // If the user blurs and then refocuses the list, the last list item
        // that had been focused will automatically receive first focus.
        this.focusInitialized = true;
        const focusItem = this.setListFocus(tablistElement);
        this.moveFocusToItem(focusItem);
      }
    };
  }

  setListFocus(tablistElement: HTMLElement) {
    const listItems = Array.from(this.getListItems(tablistElement));
    if (!listItems.length) return;
    const selectedItem = listItems.find(this.itemIsSelected);
    const firstItem = listItems[0];
    const focusItem = selectedItem || firstItem;
    // Only one item in the list should be focusable at any given time
    listItems.forEach((item) => {
      if (item !== focusItem && item.tabIndex >= 0) {
        item.tabIndex = -1;
      }
      if (item === focusItem) {
        item.tabIndex = 0;
      }
    });
    return focusItem;
  }

  setupListItems(tablistElement: HTMLElement) {
    const listItems = this.getListItems(tablistElement);

    for (const item of listItems) {
      item.tabIndex = -1;
      item.addEventListener('keyup', this.onKeyUp(tablistElement).bind(this));
    }
  }

  getListItems(tablistElement: HTMLElement) {
    return tablistElement.querySelectorAll<HTMLElement>('[role=tab]');
  }

  onKeyUp(tablistElement: HTMLElement) {
    return (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowDown': {
          const listItems = Array.from(this.getListItems(tablistElement));
          if (!listItems.length) break;
          const lastIndex = listItems.length - 1;
          const firstIndex = 0;
          const focusedItemIndex = listItems.findIndex((item) => {
            return this.itemIsFocused(item);
          });
          const nextIndex = focusedItemIndex + 1;
          const nextFocusedIndex =
            nextIndex <= lastIndex ? nextIndex : firstIndex;
          this.moveFocusToIndex(nextFocusedIndex, listItems);
          break;
        }
        case 'ArrowUp': {
          const listItems = Array.from(this.getListItems(tablistElement));
          if (!listItems.length) break;
          const lastIndex = listItems.length - 1;
          const firstIndex = 0;
          const focusedItemIndex = listItems.findIndex((item) => {
            return this.itemIsFocused(item);
          });
          const nextIndex = focusedItemIndex - 1;
          const nextFocusedIndex =
            nextIndex >= firstIndex ? nextIndex : lastIndex;
          this.moveFocusToIndex(nextFocusedIndex, listItems);
          break;
        }
      }
    };
  }

  itemIsFocused(item: HTMLElement) {
    return document.activeElement === item;
  }

  itemIsSelected(item: HTMLElement) {
    return item.ariaSelected === 'true';
  }

  moveFocusToIndex(nextSelectedIndex: number, listItems: HTMLElement[]) {
    // Remove focusable attribute from previously focused item and add
    // to next focused item.
    listItems.forEach((item, index) => {
      item.tabIndex = index === nextSelectedIndex ? 0 : -1;
    });
    this.moveFocusToItem(listItems[nextSelectedIndex]);
  }

  moveFocusToItem(itemElement?: HTMLElement) {
    itemElement?.focus();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SplitList: typeof SplitListComponent;
    'split-list': typeof SplitListComponent;
  }
}
