/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { Secrets20231128OpenSecretDynamicInstanceFromJSON, Secrets20231128OpenSecretDynamicInstanceToJSON, Secrets20231128OpenSecretRotatingVersionFromJSON, Secrets20231128OpenSecretRotatingVersionToJSON, Secrets20231128OpenSecretStaticVersionFromJSON, Secrets20231128OpenSecretStaticVersionToJSON, Secrets20231128SyncStatusFromJSON, Secrets20231128SyncStatusToJSON, } from './';
export function Secrets20231128OpenSecretFromJSON(json) {
    return Secrets20231128OpenSecretFromJSONTyped(json, false);
}
export function Secrets20231128OpenSecretFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'latestVersion': !exists(json, 'latest_version') ? undefined : json['latest_version'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'syncStatus': !exists(json, 'sync_status') ? undefined : (mapValues(json['sync_status'], Secrets20231128SyncStatusFromJSON)),
        'staticVersion': !exists(json, 'static_version') ? undefined : Secrets20231128OpenSecretStaticVersionFromJSON(json['static_version']),
        'rotatingVersion': !exists(json, 'rotating_version') ? undefined : Secrets20231128OpenSecretRotatingVersionFromJSON(json['rotating_version']),
        'dynamicInstance': !exists(json, 'dynamic_instance') ? undefined : Secrets20231128OpenSecretDynamicInstanceFromJSON(json['dynamic_instance']),
    };
}
export function Secrets20231128OpenSecretToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'type': value.type,
        'provider': value.provider,
        'latest_version': value.latestVersion,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'created_by_id': value.createdById,
        'sync_status': value.syncStatus === undefined ? undefined : (mapValues(value.syncStatus, Secrets20231128SyncStatusToJSON)),
        'static_version': Secrets20231128OpenSecretStaticVersionToJSON(value.staticVersion),
        'rotating_version': Secrets20231128OpenSecretRotatingVersionToJSON(value.rotatingVersion),
        'dynamic_instance': Secrets20231128OpenSecretDynamicInstanceToJSON(value.dynamicInstance),
    };
}
