/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var Secrets20231128RotatingSecretStatus;
(function (Secrets20231128RotatingSecretStatus) {
    Secrets20231128RotatingSecretStatus["NONE"] = "NONE";
    Secrets20231128RotatingSecretStatus["ERRORED"] = "ERRORED";
    Secrets20231128RotatingSecretStatus["WAITINGFORNEXTROTATION"] = "WAITING_FOR_NEXT_ROTATION";
    Secrets20231128RotatingSecretStatus["ROTATIONSTARTING"] = "ROTATION_STARTING";
    Secrets20231128RotatingSecretStatus["ROTATIONSTAGINGCREDENTIAL"] = "ROTATION_STAGING_CREDENTIAL";
    Secrets20231128RotatingSecretStatus["ROTATIONCREATINGCREDENTIAL"] = "ROTATION_CREATING_CREDENTIAL";
    Secrets20231128RotatingSecretStatus["ROTATIONTESTINGCREDENTIAL"] = "ROTATION_TESTING_CREDENTIAL";
    Secrets20231128RotatingSecretStatus["ROTATIONSTORINGCREDENTIAL"] = "ROTATION_STORING_CREDENTIAL";
    Secrets20231128RotatingSecretStatus["ROTATIONCHECKINGREVOCABLE"] = "ROTATION_CHECKING_REVOCABLE";
    Secrets20231128RotatingSecretStatus["ROTATIONREVOKINGCREDENTIAL"] = "ROTATION_REVOKING_CREDENTIAL";
    Secrets20231128RotatingSecretStatus["ROTATIONMARKINGREVOKED"] = "ROTATION_MARKING_REVOKED";
})(Secrets20231128RotatingSecretStatus || (Secrets20231128RotatingSecretStatus = {}));
export function Secrets20231128RotatingSecretStatusFromJSON(json) {
    return Secrets20231128RotatingSecretStatusFromJSONTyped(json, false);
}
export function Secrets20231128RotatingSecretStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Secrets20231128RotatingSecretStatusToJSON(value) {
    return value;
}
