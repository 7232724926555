/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudPacker20221202ModelsChannelAssignmentFromJSON, HashicorpCloudPacker20221202ModelsChannelAssignmentToJSON, } from './';
export function HashicorpCloudPacker20221202ModelsListChannelAssignmentHistoryResponseFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsListChannelAssignmentHistoryResponseFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsListChannelAssignmentHistoryResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'history': !exists(json, 'history') ? undefined : (json['history'].map(HashicorpCloudPacker20221202ModelsChannelAssignmentFromJSON)),
        'count': !exists(json, 'count') ? undefined : json['count'],
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudPacker20221202ModelsListChannelAssignmentHistoryResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'history': value.history === undefined ? undefined : (value.history.map(HashicorpCloudPacker20221202ModelsChannelAssignmentToJSON)),
        'count': value.count,
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
