import { TYPE_CONSUL } from 'common/utils/cloud-resource-types';
import { HashicorpCloudConsul20210204ClusterConfigTier as HashicorpCloudConsulClusterConfigTier } from '@clients/cloud-consul-service';

import {
  RESOURCE_TIER_I18N_PREFIX,
  RESOURCE_TIER_SIZE_I18N_PREFIX,
  RESOURCE_TIER_SIZE_XS,
  RESOURCE_TIER_SIZE_SM,
  RESOURCE_TIER_SIZE_MD,
  RESOURCE_TIER_SIZE_LG,
} from '../../constants/index.ts';

import type {
  ResourceCatalog,
  ResourceTier,
} from '../../types/resource-catalog.ts';

// Development tier
export const CONSUL_TIER_DEV = 'hashicorp.consul.development';
export const CONSUL_TIER_DEV_VALUE =
  HashicorpCloudConsulClusterConfigTier.DEVELOPMENT;
export const CONSUL_DEV_FEATURES_PREFIX = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_DEV}.features`;
export const CONSUL_DEV_FEATURES = [
  `${CONSUL_DEV_FEATURES_PREFIX}.consul-enterprise`,
  `${CONSUL_DEV_FEATURES_PREFIX}.single-node-cluster`,
  `${CONSUL_DEV_FEATURES_PREFIX}.service-instances`,
  `${CONSUL_DEV_FEATURES_PREFIX}.multi-connectivity`,
];

export const CONSUL_DEV_FEATURES_AZURE_PREFIX = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_DEV}.azure-features`;
export const CONSUL_DEV_FEATURES_AZURE = [
  `${CONSUL_DEV_FEATURES_PREFIX}.consul-enterprise`,
  `${CONSUL_DEV_FEATURES_PREFIX}.single-node-cluster`,
  `${CONSUL_DEV_FEATURES_PREFIX}.service-instances`,
  `${CONSUL_DEV_FEATURES_AZURE_PREFIX}.multi-connectivity`,
];
// Development sizes
export const CONSUL_DEV_XS_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_DEV}.extra-small`;
export const CONSUL_DEV_XS_FEATURES = [
  `${CONSUL_DEV_XS_PREFIX}.features.service-instances`,
  `${CONSUL_DEV_XS_PREFIX}.features.service-instance-price`,
];

// Standard tier
export const CONSUL_TIER_STANDARD = 'hashicorp.consul.standard';
export const CONSUL_TIER_STANDARD_VALUE =
  HashicorpCloudConsulClusterConfigTier.STANDARD;
export const CONSUL_STANDARD_FEATURES_PREFIX = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_STANDARD}.features`;
export const CONSUL_STANDARD_FEATURES = [
  `${CONSUL_STANDARD_FEATURES_PREFIX}.enterprise-sla`,
  `${CONSUL_STANDARD_FEATURES_PREFIX}.three-node-cluster`,
  `${CONSUL_STANDARD_FEATURES_PREFIX}.service-instances`,
  `${CONSUL_STANDARD_FEATURES_PREFIX}.multi-connectivity`,
  `${CONSUL_STANDARD_FEATURES_PREFIX}.audit-logs`,
];
export const CONSUL_STANDARD_FEATURES_AZURE_PREFIX = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_STANDARD}.azure-features`;
export const CONSUL_STANDARD_FEATURES_AZURE = [
  `${CONSUL_STANDARD_FEATURES_PREFIX}.enterprise-sla`,
  `${CONSUL_STANDARD_FEATURES_PREFIX}.three-node-cluster`,
  `${CONSUL_STANDARD_FEATURES_PREFIX}.service-instances`,
  `${CONSUL_STANDARD_FEATURES_AZURE_PREFIX}.multi-connectivity`,
];
// Standard sizes
export const CONSUL_STANDARD_SM_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_STANDARD}.small`;
export const CONSUL_STANDARD_SM_FEATURES = [
  `${CONSUL_STANDARD_SM_PREFIX}.features.service-instances`,
  `${CONSUL_STANDARD_SM_PREFIX}.features.service-instance-price`,
];
export const CONSUL_STANDARD_MD_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_STANDARD}.medium`;
export const CONSUL_STANDARD_MD_FEATURES = [
  `${CONSUL_STANDARD_MD_PREFIX}.features.service-instances`,
  `${CONSUL_STANDARD_MD_PREFIX}.features.service-instance-price`,
];
export const CONSUL_STANDARD_LG_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_STANDARD}.large`;
export const CONSUL_STANDARD_LG_FEATURES = [
  `${CONSUL_STANDARD_LG_PREFIX}.features.service-instances`,
  `${CONSUL_STANDARD_LG_PREFIX}.features.service-instance-price`,
];

// Plus tier
export const CONSUL_TIER_PLUS = 'hashicorp.consul.plus';
export const CONSUL_TIER_PLUS_VALUE =
  HashicorpCloudConsulClusterConfigTier.PLUS;
export const CONSUL_PLUS_FEATURES_PREFIX = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_PLUS}.features`;
export const CONSUL_PLUS_FEATURES = [
  `${CONSUL_PLUS_FEATURES_PREFIX}.standard-features`,
  `${CONSUL_PLUS_FEATURES_PREFIX}.multi-connectivity`,
];
// Plus sizes
export const CONSUL_PLUS_SM_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_PLUS}.small`;
export const CONSUL_PLUS_SM_FEATURES = [
  `${CONSUL_PLUS_SM_PREFIX}.features.service-instances`,
  `${CONSUL_PLUS_SM_PREFIX}.features.service-instance-price`,
];
export const CONSUL_PLUS_MD_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_PLUS}.medium`;
export const CONSUL_PLUS_MD_FEATURES = [
  `${CONSUL_PLUS_MD_PREFIX}.features.service-instances`,
  `${CONSUL_PLUS_MD_PREFIX}.features.service-instance-price`,
];
export const CONSUL_PLUS_LG_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_PLUS}.large`;
export const CONSUL_PLUS_LG_FEATURES = [
  `${CONSUL_PLUS_LG_PREFIX}.features.service-instances`,
  `${CONSUL_PLUS_LG_PREFIX}.features.service-instance-price`,
];

// Premium tier
export const CONSUL_TIER_PREMIUM = 'hashicorp.consul.premium';
export const CONSUL_TIER_PREMIUM_VALUE =
  HashicorpCloudConsulClusterConfigTier.PREMIUM;
export const CONSUL_PREMIUM_FEATURES_PREFIX = `${RESOURCE_TIER_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_PREMIUM}.features`;
export const CONSUL_PREMIUM_FEATURES = [
  `${CONSUL_PREMIUM_FEATURES_PREFIX}.plus-features`,
  `${CONSUL_PREMIUM_FEATURES_PREFIX}.multi-region-federation`,
];
// Premium sizes
export const CONSUL_PREMIUM_SM_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_PREMIUM}.small`;
export const CONSUL_PREMIUM_SM_FEATURES = [
  `${CONSUL_PREMIUM_SM_PREFIX}.features.service-instances`,
  `${CONSUL_PREMIUM_SM_PREFIX}.features.service-instance-price`,
];
export const CONSUL_PREMIUM_MD_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_PREMIUM}.medium`;
export const CONSUL_PREMIUM_MD_FEATURES = [
  `${CONSUL_PREMIUM_MD_PREFIX}.features.service-instances`,
  `${CONSUL_PREMIUM_MD_PREFIX}.features.service-instance-price`,
];
export const CONSUL_PREMIUM_LG_PREFIX = `${RESOURCE_TIER_SIZE_I18N_PREFIX}.${TYPE_CONSUL}.${CONSUL_TIER_PREMIUM}.large`;
export const CONSUL_PREMIUM_LG_FEATURES = [
  `${CONSUL_PREMIUM_LG_PREFIX}.features.service-instances`,
  `${CONSUL_PREMIUM_LG_PREFIX}.features.service-instance-price`,
];

export const CONSUL_RESOURCE_TIER_SIZE_XS_VALUE = 'X_SMALL';
export const CONSUL_RESOURCE_TIER_SIZE_SM_VALUE = 'SMALL';
export const CONSUL_RESOURCE_TIER_SIZE_MD_VALUE = 'MEDIUM';
export const CONSUL_RESOURCE_TIER_SIZE_LG_VALUE = 'LARGE';

/**
 * This defines the mapping for available billing tiers for this resource. The
 *     data here enriches an API response with i18n strings which can be used
 *     for basic labels to dynamic pricing and with additional values used for
 *     rendering components in the front-end. More documentation can be found
 *     in `resource-catalog/services/resource-catalog`;
 */
export const RESOURCE_CONSUL_TIER_MAPPING: Record<
  string,
  Record<string, ResourceTier>
> = {
  aws: {
    [CONSUL_TIER_DEV]: {
      value: CONSUL_TIER_DEV_VALUE,
      features: CONSUL_DEV_FEATURES,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_XS,
          value: CONSUL_RESOURCE_TIER_SIZE_XS_VALUE,
          features: CONSUL_DEV_XS_FEATURES,
        },
      ],
    },
    [CONSUL_TIER_STANDARD]: {
      value: CONSUL_TIER_STANDARD_VALUE,
      features: CONSUL_STANDARD_FEATURES,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_SM,
          value: CONSUL_RESOURCE_TIER_SIZE_SM_VALUE,
          features: CONSUL_STANDARD_SM_FEATURES,
        },
        {
          key: RESOURCE_TIER_SIZE_MD,
          value: CONSUL_RESOURCE_TIER_SIZE_MD_VALUE,
          features: CONSUL_STANDARD_MD_FEATURES,
        },
        {
          key: RESOURCE_TIER_SIZE_LG,
          value: CONSUL_RESOURCE_TIER_SIZE_LG_VALUE,
          features: CONSUL_STANDARD_LG_FEATURES,
        },
      ],
    },
    [CONSUL_TIER_PLUS]: {
      value: CONSUL_TIER_PLUS_VALUE,
      features: CONSUL_PLUS_FEATURES,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_SM,
          value: CONSUL_RESOURCE_TIER_SIZE_SM_VALUE,
          features: CONSUL_PLUS_SM_FEATURES,
        },
        {
          key: RESOURCE_TIER_SIZE_MD,
          value: CONSUL_RESOURCE_TIER_SIZE_MD_VALUE,
          features: CONSUL_PLUS_MD_FEATURES,
        },
        {
          key: RESOURCE_TIER_SIZE_LG,
          value: CONSUL_RESOURCE_TIER_SIZE_LG_VALUE,
          features: CONSUL_PLUS_LG_FEATURES,
        },
      ],
    },
    [CONSUL_TIER_PREMIUM]: {
      value: CONSUL_TIER_PREMIUM_VALUE,
      features: CONSUL_PREMIUM_FEATURES,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_SM,
          value: CONSUL_RESOURCE_TIER_SIZE_SM_VALUE,
          features: CONSUL_PREMIUM_SM_FEATURES,
        },
        {
          key: RESOURCE_TIER_SIZE_MD,
          value: CONSUL_RESOURCE_TIER_SIZE_MD_VALUE,
          features: CONSUL_PREMIUM_MD_FEATURES,
        },
        {
          key: RESOURCE_TIER_SIZE_LG,
          value: CONSUL_RESOURCE_TIER_SIZE_LG_VALUE,
          features: CONSUL_PREMIUM_LG_FEATURES,
        },
      ],
    },
  },
  azure: {
    [CONSUL_TIER_DEV]: {
      value: CONSUL_TIER_DEV_VALUE,
      features: CONSUL_DEV_FEATURES_AZURE,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_XS,
          value: CONSUL_RESOURCE_TIER_SIZE_XS_VALUE,
          features: CONSUL_DEV_XS_FEATURES,
        },
      ],
    },
    [CONSUL_TIER_STANDARD]: {
      value: CONSUL_TIER_STANDARD_VALUE,
      features: CONSUL_STANDARD_FEATURES_AZURE,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_SM,
          value: CONSUL_RESOURCE_TIER_SIZE_SM_VALUE,
          features: CONSUL_STANDARD_SM_FEATURES,
        },
        {
          key: RESOURCE_TIER_SIZE_MD,
          value: CONSUL_RESOURCE_TIER_SIZE_MD_VALUE,
          features: CONSUL_STANDARD_MD_FEATURES,
        },
        {
          key: RESOURCE_TIER_SIZE_LG,
          value: CONSUL_RESOURCE_TIER_SIZE_LG_VALUE,
          features: CONSUL_STANDARD_LG_FEATURES,
        },
      ],
    },
    [CONSUL_TIER_PLUS]: {
      value: CONSUL_TIER_PLUS_VALUE,
      features: CONSUL_PLUS_FEATURES,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_SM,
          value: CONSUL_RESOURCE_TIER_SIZE_SM_VALUE,
          features: CONSUL_PLUS_SM_FEATURES,
        },
        {
          key: RESOURCE_TIER_SIZE_MD,
          value: CONSUL_RESOURCE_TIER_SIZE_MD_VALUE,
          features: CONSUL_PLUS_MD_FEATURES,
        },
        {
          key: RESOURCE_TIER_SIZE_LG,
          value: CONSUL_RESOURCE_TIER_SIZE_LG_VALUE,
          features: CONSUL_PLUS_LG_FEATURES,
        },
      ],
    },
    [CONSUL_TIER_PREMIUM]: {
      value: CONSUL_TIER_PREMIUM_VALUE,
      features: CONSUL_PREMIUM_FEATURES,
      sizes: [
        {
          key: RESOURCE_TIER_SIZE_SM,
          value: CONSUL_RESOURCE_TIER_SIZE_SM_VALUE,
          features: CONSUL_PREMIUM_SM_FEATURES,
        },
        {
          key: RESOURCE_TIER_SIZE_MD,
          value: CONSUL_RESOURCE_TIER_SIZE_MD_VALUE,
          features: CONSUL_PREMIUM_MD_FEATURES,
        },
        {
          key: RESOURCE_TIER_SIZE_LG,
          value: CONSUL_RESOURCE_TIER_SIZE_LG_VALUE,
          features: CONSUL_PREMIUM_LG_FEATURES,
        },
      ],
    },
  },
};

export const CONSUL_RESOURCE: ResourceCatalog = {
  icon: 'consul',
  id: TYPE_CONSUL,
  tiers: RESOURCE_CONSUL_TIER_MAPPING,
};
