import Component from '@glimmer/component';

interface BadgeListSignature {
  Args: {
    model: unknown;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLUListElement;
}

export default class BadgeListComponent extends Component<BadgeListSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    BadgeList: typeof BadgeListComponent;
    'badge-list': typeof BadgeListComponent;
  }
}
