import templateOnlyComponent from '@ember/component/template-only';

interface DetailSectionZeroStateSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const DetailSectionZeroStateComponent =
  templateOnlyComponent<DetailSectionZeroStateSignature>();

export default DetailSectionZeroStateComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Detail::Section::ZeroState': typeof DetailSectionZeroStateComponent;
    'detail/section/zero-state': typeof DetailSectionZeroStateComponent;
  }
}
