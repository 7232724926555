import type {
  HashicorpCloudResourcemanagerPolicy,
  HashicorpCloudResourcemanagerPolicyBinding,
  HashicorpCloudResourcemanagerPolicyBindingMember,
  HashicorpCloudResourcemanagerRole,
} from '@clients/cloud-resource-manager';

type RoleId = HashicorpCloudResourcemanagerRole['id'];
type RoleIds = Array<RoleId>;

interface Member {
  memberId: HashicorpCloudResourcemanagerPolicyBindingMember['memberId'];
  memberType: HashicorpCloudResourcemanagerPolicyBindingMember['memberType'];
  roleIds: RoleIds;
}

interface MemberMap {
  [key: string]: HashicorpCloudResourcemanagerPolicyBindingMember; // Composite key: `memberType-memberId`
}

interface RoleBindings {
  [roleId: string]: Set<string>;
}

interface OptimizedPolicy {
  memberMap: MemberMap;
  roleBindings: RoleBindings;
}

interface AddMemberAction {
  type: typeof ADD_MEMBER_TO_ROLE;
  memberId: HashicorpCloudResourcemanagerPolicyBindingMember['memberId'];
  memberType: HashicorpCloudResourcemanagerPolicyBindingMember['memberType'];
  roleIds: Array<RoleId>;
}

interface RemoveMemberAction {
  type: typeof REMOVE_MEMBER_FROM_ROLE;
  memberId: HashicorpCloudResourcemanagerPolicyBindingMember['memberId'];
  memberType: HashicorpCloudResourcemanagerPolicyBindingMember['memberType'];
  roleIds: Array<RoleId>;
}

type Action = AddMemberAction | RemoveMemberAction;

const ADD_MEMBER_TO_ROLE = 'ADD_MEMBER_TO_ROLE';
const REMOVE_MEMBER_FROM_ROLE = 'REMOVE_MEMBER_FROM_ROLE';

/**
 * Reduces actions into an optimized policy state.
 * @param {OptimizedPolicy} state - The current state.
 * @param {Action} action - The action to reduce.
 * @returns {OptimizedPolicy} - The new state.
 */
function policyReducer(
  state: OptimizedPolicy,
  action: Action,
): OptimizedPolicy {
  const newState = { ...state };
  const compositeKey = `${action.memberType}-${action.memberId}`;

  switch (action.type) {
    case ADD_MEMBER_TO_ROLE: {
      if (!action?.roleIds?.length) {
        return newState;
      }
      newState.memberMap[compositeKey] = {
        memberType: action.memberType,
        memberId: action.memberId,
      };
      action.roleIds.forEach((roleId) => {
        if (roleId) {
          const roleSet = newState.roleBindings[roleId] ?? new Set<string>();
          roleSet.add(compositeKey);
          newState.roleBindings[roleId] = roleSet;
        }
      });
      return newState;
    }
    case REMOVE_MEMBER_FROM_ROLE: {
      if (!action?.roleIds?.length) {
        return newState;
      }
      action.roleIds.forEach((roleId) => {
        if (roleId) {
          const roleBindingSet = newState.roleBindings[roleId];
          if (roleBindingSet) {
            roleBindingSet.delete(compositeKey);
          }
        }
      });
      return newState;
    }
    default:
      return state;
  }
}

export interface IPolicyManager {
  dispatch(action: Action): void;
  undo(): void;
  findMembers(
    filterRoleIds?: RoleIds,
    sortKey?: keyof HashicorpCloudResourcemanagerPolicyBindingMember,
  ): HashicorpCloudResourcemanagerPolicyBindingMember[];
  getRoleBindings(): RoleBindings;
  getRolesByMember(
    member: HashicorpCloudResourcemanagerPolicyBindingMember,
  ): Member | undefined;
  getRolesByMemberId(
    memberId: HashicorpCloudResourcemanagerPolicyBindingMember['memberId'],
  ): Member | undefined;
  addMemberToRole(
    member: HashicorpCloudResourcemanagerPolicyBindingMember,
    roleIds: Array<RoleId>,
  ): void;
  removeMemberFromRole(
    member: HashicorpCloudResourcemanagerPolicyBindingMember,
    roleIds: Array<RoleId>,
  ): void;
  getRawPolicy(): HashicorpCloudResourcemanagerPolicy;
}

/**
 * Class to manage policy, actions, and optimizations.
 * @class
 */
class PolicyManager implements IPolicyManager {
  policy: HashicorpCloudResourcemanagerPolicy;
  private optimizedPolicy: OptimizedPolicy;
  private actions: Action[] = [];

  /**
   * Creates a PolicyManager instance.
   * @param {HashicorpCloudResourcemanagerPolicy} policy - The policy to manage.
   */
  constructor({ policy }: { policy: HashicorpCloudResourcemanagerPolicy }) {
    this.policy = policy;
    this.optimizedPolicy = this.optimizePolicy(this.policy);
  }

  /**
   * Optimizes the given policy into an optimized structure for quicker lookups.
   * @private
   * @param {HashicorpCloudResourcemanagerPolicy} policy - The policy to optimize.
   * @returns {OptimizedPolicy} - The optimized policy.
   */
  private optimizePolicy(
    policy: HashicorpCloudResourcemanagerPolicy,
  ): OptimizedPolicy {
    const initialState = {
      memberMap: {} as MemberMap,
      roleBindings: {} as { [roleId: string]: Set<string> },
    };

    if (!policy?.bindings) {
      return initialState;
    }

    const { memberMap, roleBindings } = policy.bindings.reduce(
      (acc, binding) => {
        if (!binding.members || !binding.roleId) {
          return acc;
        }

        const updatedState = binding.members.reduce((innerAcc, member) => {
          if (!binding.roleId) {
            return innerAcc;
          }
          const compositeKey = `${member.memberType}-${member.memberId}`;

          innerAcc.memberMap[compositeKey] = member;

          if (!innerAcc.roleBindings[binding.roleId]) {
            innerAcc.roleBindings[binding.roleId] = new Set();
          }

          innerAcc.roleBindings[binding.roleId]!.add(compositeKey);

          return innerAcc;
        }, acc);

        return updatedState;
      },
      initialState,
    );

    return { memberMap, roleBindings };
  }

  /**
   * Converts an optimized policy back to its original structure.
   * @private
   * @param {OptimizedPolicy} optimized - The optimized policy.
   * @returns {HashicorpCloudResourcemanagerPolicy} - The de-optimized policy.
   */
  private deOptimizePolicy(
    optimized: OptimizedPolicy,
  ): HashicorpCloudResourcemanagerPolicy {
    const bindings: HashicorpCloudResourcemanagerPolicyBinding[] = [];
    const { etag } = this.policy;

    for (const roleId in optimized.roleBindings) {
      const members: HashicorpCloudResourcemanagerPolicyBindingMember[] = [];
      const currentBindings = optimized.roleBindings[roleId];

      if (currentBindings) {
        currentBindings.forEach((compositeKey) => {
          const member = optimized.memberMap[compositeKey];
          if (member) {
            members.push(member);
          }
        });
        bindings.push({ roleId, members });
      }
    }

    return { bindings, etag };
  }

  /**
   * Dispatches an action to modify the optimized policy.
   * @param {Action} action - The action to dispatch.
   */
  dispatch(action: Action): void {
    this.actions.push(action);
    this.optimizedPolicy = policyReducer(this.optimizedPolicy, action);
  }

  /**
   * Undoes the last dispatched action.
   */
  undo(): void {
    if (this.actions.length > 0) {
      this.actions.pop();
      let replayedState = this.optimizePolicy(this.policy);
      this.actions.forEach((action) => {
        replayedState = policyReducer(replayedState, action);
      });
      this.optimizedPolicy = replayedState;
    }
  }

  /**
   * Finds members based on provided role IDs and sort key.
   * @param {string[]} [filterRoleIds=[]] - Role IDs to filter by.
   * @param {keyof Member} [sortKey='memberType'] - Key to sort the members by.
   * @returns {Member[]} - List of members.
   */
  findMembers(
    filterRoleIds: RoleIds = [],
    sortKey: keyof HashicorpCloudResourcemanagerPolicyBindingMember = 'memberType',
  ): HashicorpCloudResourcemanagerPolicyBindingMember[] {
    const allMembers = Object.values(this.optimizedPolicy.memberMap);
    const filteredMembers =
      filterRoleIds.length === 0
        ? allMembers
        : allMembers.filter((member) => {
            const compositeKey = `${member.memberType}-${member.memberId}`;
            return Array.from(filterRoleIds).some((roleId) => {
              if (!roleId) {
                return false;
              }
              return this.optimizedPolicy.roleBindings[roleId]?.has(
                compositeKey,
              );
            });
          });

    return filteredMembers.sort((a, b) => {
      const aValue = a[sortKey];
      const bValue = b[sortKey];

      if (aValue !== undefined && bValue !== undefined) {
        return aValue > bValue ? 1 : -1;
      } else if (aValue !== undefined) {
        return 1; // if a has a value and b doesn't, a should come first
      } else if (bValue !== undefined) {
        return -1; // if b has a value and a doesn't, b should come first
      } else {
        return 0; // if both are undefined, they are equal
      }
    });
  }

  getRoleBindings(): RoleBindings {
    const { roleBindings } = this.optimizedPolicy;
    return roleBindings;
  }

  getRolesByMember(
    member: HashicorpCloudResourcemanagerPolicyBindingMember,
  ): Member {
    const { memberType, memberId } = member;
    const compositeKey = `${memberType}-${memberId}`;
    const { roleBindings } = this.optimizedPolicy;
    let roleIds: RoleIds = [];

    if (memberType && memberId) {
      roleIds = Object.entries(roleBindings).reduce(
        (roleIds, [roleId, bindingSet]) => {
          if (bindingSet && bindingSet.has(compositeKey)) {
            roleIds.push(roleId);
          }

          return roleIds;
        },
        roleIds,
      );
    }

    return {
      memberType,
      memberId,
      roleIds,
    };
  }

  getRolesByMemberId(
    memberId: HashicorpCloudResourcemanagerPolicyBindingMember['memberId'],
  ): Member | undefined {
    const allMembers = Object.values(this.optimizedPolicy.memberMap);
    const member = allMembers.find((member) => member.memberId === memberId);
    if (!member) {
      return;
    }
    return this.getRolesByMember(member);
  }

  /**
   * Adds a member to a specified role.
   * @param {HashicorpCloudResourcemanagerPolicyBindingMember} member - The member to add.
   * @param {string} roleId - The role ID.
   */
  addMemberToRole(
    member: HashicorpCloudResourcemanagerPolicyBindingMember,
    roleIds: Array<RoleId>,
  ): void {
    const { memberId, memberType } = member;
    this.dispatch({
      type: ADD_MEMBER_TO_ROLE,
      memberId,
      memberType,
      roleIds,
    });
  }

  /**
   * Removes a member from specified role.
   * @param {HashicorpCloudResourcemanagerPolicyBindingMember} member - The member to add.
   * @param {string} roleId - The role ID.
   */
  removeMemberFromRole(
    member: HashicorpCloudResourcemanagerPolicyBindingMember,
    roleIds: Array<RoleId>,
  ): void {
    const { memberId, memberType } = member;
    this.dispatch({
      type: REMOVE_MEMBER_FROM_ROLE,
      memberId,
      memberType,
      roleIds,
    });
  }

  /**
   * Retrieves the current policy in its original structure.
   * @returns {HashicorpCloudResourcemanagerPolicy} - The current policy.
   */
  getRawPolicy(): HashicorpCloudResourcemanagerPolicy {
    return this.deOptimizePolicy(this.optimizedPolicy);
  }
}

export default PolicyManager;
