/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudConsul20210204SnapshotSnapshotMetaFromJSON, HashicorpCloudConsul20210204SnapshotSnapshotMetaToJSON, HashicorpCloudConsul20210204SnapshotSnapshotStateFromJSON, HashicorpCloudConsul20210204SnapshotSnapshotStateToJSON, HashicorpCloudConsul20210204SnapshotSnapshotTypeFromJSON, HashicorpCloudConsul20210204SnapshotSnapshotTypeToJSON, HashicorpCloudLocationLinkFromJSON, HashicorpCloudLocationLinkToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudConsul20210204SnapshotFromJSON(json) {
    return HashicorpCloudConsul20210204SnapshotFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204SnapshotFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'selfLink': !exists(json, 'self_link') ? undefined : HashicorpCloudLocationLinkFromJSON(json['self_link']),
        'resource': !exists(json, 'resource') ? undefined : HashicorpCloudLocationLinkFromJSON(json['resource']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'meta': !exists(json, 'meta') ? undefined : HashicorpCloudConsul20210204SnapshotSnapshotMetaFromJSON(json['meta']),
        'type': !exists(json, 'type') ? undefined : HashicorpCloudConsul20210204SnapshotSnapshotTypeFromJSON(json['type']),
        'state': !exists(json, 'state') ? undefined : HashicorpCloudConsul20210204SnapshotSnapshotStateFromJSON(json['state']),
        'runningOperationId': !exists(json, 'running_operation_id') ? undefined : json['running_operation_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'finishedAt': !exists(json, 'finished_at') ? undefined : (new Date(json['finished_at'])),
    };
}
export function HashicorpCloudConsul20210204SnapshotToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'self_link': HashicorpCloudLocationLinkToJSON(value.selfLink),
        'resource': HashicorpCloudLocationLinkToJSON(value.resource),
        'name': value.name,
        'meta': HashicorpCloudConsul20210204SnapshotSnapshotMetaToJSON(value.meta),
        'type': HashicorpCloudConsul20210204SnapshotSnapshotTypeToJSON(value.type),
        'state': HashicorpCloudConsul20210204SnapshotSnapshotStateToJSON(value.state),
        'running_operation_id': value.runningOperationId,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'finished_at': value.finishedAt === undefined ? undefined : (value.finishedAt.toISOString()),
    };
}
