/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVagrant20220930ProviderFromJSON, HashicorpCloudVagrant20220930VersionStateFromJSON, HashicorpCloudVagrant20220930VersionStateToJSON, HashicorpCloudVagrant20220930VersionSummaryFromJSON, HashicorpCloudVagrant20220930VersionSummaryToJSON, } from './';
export function HashicorpCloudVagrant20220930VersionFromJSON(json) {
    return HashicorpCloudVagrant20220930VersionFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930VersionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'state': !exists(json, 'state') ? undefined : HashicorpCloudVagrant20220930VersionStateFromJSON(json['state']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'descriptionHtml': !exists(json, 'description_html') ? undefined : json['description_html'],
        'summary': !exists(json, 'summary') ? undefined : HashicorpCloudVagrant20220930VersionSummaryFromJSON(json['summary']),
        'providers': !exists(json, 'providers') ? undefined : (json['providers'].map(HashicorpCloudVagrant20220930ProviderFromJSON)),
    };
}
export function HashicorpCloudVagrant20220930VersionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'state': HashicorpCloudVagrant20220930VersionStateToJSON(value.state),
        'description': value.description,
        'summary': HashicorpCloudVagrant20220930VersionSummaryToJSON(value.summary),
    };
}
