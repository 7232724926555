import type {
  ParsedResourceName,
  Options,
  PartRecord,
} from '../types/resource-name.ts';

export default function iamFindResourceNamePart(
  parsedResourceName: ParsedResourceName,
  { part }: Options,
): PartRecord | undefined {
  const { parts } = parsedResourceName ?? {};

  if (!parts || !parts.length) {
    return;
  }

  const foundPart = parts?.find(([partType]) => {
    return partType == part;
  });

  if (!foundPart) {
    return;
  }

  return { part, id: foundPart[1] };
}
