import templateOnlyComponent from '@ember/component/template-only';

interface DefinitionListDividerSignature {
  // Args: {};
}

const DefinitionListDividerComponent =
  templateOnlyComponent<DefinitionListDividerSignature>();

export default DefinitionListDividerComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DefinitionList::Divider': typeof DefinitionListDividerComponent;
    'definition-list/divider': typeof DefinitionListDividerComponent;
  }
}
