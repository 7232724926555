import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import { assert } from '@ember/debug';
import { Type, TYPES } from '../../../utils/consts/card-summary.ts';
import type CoreCardSummaryPair from './summary/pair.ts';
/**
 *
 * `CoreCardSummaryComponent` provides a set of components to construct key/value pairs.
 *
 *
 * ```
 * <Core::Card::Summary as |Card|>
 *   <Card.Pair as |Pair|>
 *     <Pair.Key>Term1</Pair.Key>
 *     <Pair.Value>Value1</Pair.Value>
 *   </Card.Pair>
 *   <Card.Pair @keyIcon="zap" as |Pair|>
 *     <Pair.Key>Term2</Pair.Key>
 *     <Pair.Value>Value2</Pair.Value>
 *   </Card.Pair>
 *   <Card.LinkStandalone @text="hello" @href="#" @icon="arrow-right" />
 * </Core::Card::Summary>
 * ```
 *
 * @class CoreCardSummaryComponent
 *
 */

const DEFAULT_BACKGROUND = 'neutral-secondary';
const DEFAULT_HAS_BORDER = true;
// const DEFAULT_TYPE = 'horizontal';

interface CoreCardSummarySignature {
  Args: {
    background?: string;
    hasBorder?: boolean;
    type: Type;
  };
  Blocks: {
    default: [
      {
        Pair: WithBoundArgs<typeof CoreCardSummaryPair, 'keyIcon'>;
      },
    ];
  };
  Element: HTMLElement;
}

export default class CoreCardSummaryComponent extends Component<CoreCardSummarySignature> {
  constructor(owner: unknown, args: CoreCardSummarySignature['Args']) {
    super(owner, args);

    assert(
      `@type for "Core::Card::Summary" must be one of the following: ${TYPES.join(
        ', '
      )}; received: ${this.args.type}`,
      TYPES.includes(this.args.type)
    );
  }

  /**
   * @param background
   * @type {enum}
   * @default neutral-secondary
   * @description The "background" string to apply to the hds card component.
   */
  get background() {
    const { background = DEFAULT_BACKGROUND } = this.args;
    return background;
  }

  /**
   * Get the class names to apply to the component.
   * @method CoreCardSummaryComponent#classNames
   * @return {string} The "class" attribute to apply to the component.
   */
  get classNames() {
    const classes = ['core-card-summary'];

    // Add a class based on the @type argument
    classes.push(`core-card-summary--type-${this.args.type}`);

    return classes.join(' ');
  }

  /**
   * Get the class names to apply to the component.
   * @method CoreCardSummaryComponent#hasBorder
   * @return {string} The "hasBorder" boolean to apply to the hds card component.
   */
  get hasBorder() {
    const { hasBorder = DEFAULT_HAS_BORDER } = this.args;
    return hasBorder;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::Card::Summary': typeof CoreCardSummaryComponent;
    'core/card/summary': typeof CoreCardSummaryComponent;
  }
}
