import templateOnlyComponent from '@ember/component/template-only';

interface DetailCardHeaderSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const DetailCardHeaderComponent =
  templateOnlyComponent<DetailCardHeaderSignature>();

export default DetailCardHeaderComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Detail::Card::Header': typeof DetailCardHeaderComponent;
    'detail/card/header': typeof DetailCardHeaderComponent;
  }
}
