import type { IamParsedRole } from '../types/iam-parsed-role.ts';

type ServiceIconMapping = {
  [service: string]: string;
};

export const serviceIconMapping: ServiceIconMapping = {
  boundary: 'boundary-color',
  consul: 'consul-color',
  nomad: 'nomad-color',
  packer: 'packer-color',
  terraform: 'terraform-color',
  vagrant: 'vagrant-color',
  vault: 'vault-color',
  'vault-radar': 'vault-radar-color',
  secrets: 'vault-secrets-color',
  waypoint: 'waypoint-color',
};

export default function iamGetIconForService(
  service: IamParsedRole['service'],
): string | undefined {
  if (!service) {
    return;
  }

  return serviceIconMapping[service];
}
