/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var LogService20210330DestinationFilterType;
(function (LogService20210330DestinationFilterType) {
    LogService20210330DestinationFilterType["UNKNOWN"] = "DESTINATION_FILTER_TYPE_UNKNOWN";
    LogService20210330DestinationFilterType["ORGFILTER"] = "DESTINATION_FILTER_TYPE_ORG_FILTER";
})(LogService20210330DestinationFilterType || (LogService20210330DestinationFilterType = {}));
export function LogService20210330DestinationFilterTypeFromJSON(json) {
    return LogService20210330DestinationFilterTypeFromJSONTyped(json, false);
}
export function LogService20210330DestinationFilterTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LogService20210330DestinationFilterTypeToJSON(value) {
    return value;
}
