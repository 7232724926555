/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125OptInStateFromJSON, HashicorpCloudVault20201125OptInStateToJSON, HashicorpCloudVault20201125PluginTypeFromJSON, HashicorpCloudVault20201125PluginTypeToJSON, } from './';
export function HashicorpCloudVault20201125PluginRegistrationStatusFromJSON(json) {
    return HashicorpCloudVault20201125PluginRegistrationStatusFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125PluginRegistrationStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'optIn': !exists(json, 'opt_in') ? undefined : HashicorpCloudVault20201125OptInStateFromJSON(json['opt_in']),
        'pluginName': !exists(json, 'plugin_name') ? undefined : json['plugin_name'],
        'pluginType': !exists(json, 'plugin_type') ? undefined : HashicorpCloudVault20201125PluginTypeFromJSON(json['plugin_type']),
        'pluginVersion': !exists(json, 'plugin_version') ? undefined : json['plugin_version'],
        'isRegistered': !exists(json, 'is_registered') ? undefined : json['is_registered'],
    };
}
export function HashicorpCloudVault20201125PluginRegistrationStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'opt_in': HashicorpCloudVault20201125OptInStateToJSON(value.optIn),
        'plugin_name': value.pluginName,
        'plugin_type': HashicorpCloudVault20201125PluginTypeToJSON(value.pluginType),
        'plugin_version': value.pluginVersion,
        'is_registered': value.isRegistered,
    };
}
