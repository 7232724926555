/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128PostgresParamsFromJSON, Secrets20231128PostgresParamsToJSON, } from './';
export function Secrets20231128PostgresRotatingSecretConfigFromJSON(json) {
    return Secrets20231128PostgresRotatingSecretConfigFromJSONTyped(json, false);
}
export function Secrets20231128PostgresRotatingSecretConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'appName': !exists(json, 'app_name') ? undefined : json['app_name'],
        'integrationName': !exists(json, 'integration_name') ? undefined : json['integration_name'],
        'rotationPolicyName': !exists(json, 'rotation_policy_name') ? undefined : json['rotation_policy_name'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'updatedById': !exists(json, 'updated_by_id') ? undefined : json['updated_by_id'],
        'postgresParams': !exists(json, 'postgres_params') ? undefined : Secrets20231128PostgresParamsFromJSON(json['postgres_params']),
    };
}
export function Secrets20231128PostgresRotatingSecretConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'app_name': value.appName,
        'integration_name': value.integrationName,
        'rotation_policy_name': value.rotationPolicyName,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'created_by_id': value.createdById,
        'updated_by_id': value.updatedById,
        'postgres_params': Secrets20231128PostgresParamsToJSON(value.postgresParams),
    };
}
