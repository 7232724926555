/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Webhook Service
 * API for delivering and managing webhooks.
 *
 * The version of the OpenAPI document: 2023-05-31
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWebhookWebhookFromJSON, HashicorpCloudWebhookWebhookToJSON, } from './';
export function HashicorpCloudWebhookCreateWebhookResponseFromJSON(json) {
    return HashicorpCloudWebhookCreateWebhookResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWebhookCreateWebhookResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'webhook': !exists(json, 'webhook') ? undefined : HashicorpCloudWebhookWebhookFromJSON(json['webhook']),
    };
}
export function HashicorpCloudWebhookCreateWebhookResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'webhook': HashicorpCloudWebhookWebhookToJSON(value.webhook),
    };
}
