import Component from '@glimmer/component';
import { typeOf } from '@ember/utils';
import { assert } from '@ember/debug';
import {
  GUIDED_STEPPER_STATUS_PROGRESS,
  GUIDED_STEPPER_STATUS_INCOMPLETE,
  GUIDED_STEPPER_STATUS_COMPLETE,
} from '../../utils/consts/guided-stepper-tab.js';

interface GuidedStepperTabSignature {
  Args: {
    complete: unknown;
    content: unknown;
    currentStep: string | number;
    label: unknown;
    step: number | string;
  };
  Element: HTMLDivElement;
}

/**
 * `GuidedStepper::Tab is meant to be used as a contextual component yielded by
 * `GuidedStepper`. It is the state indicator element of the stepper nav and the
 * parent `GuidedStepper`.
 *
 * @class GuidedStepper::Tab
 */

/**
 * `@step` The numeric placement of this step in the guided stepper.
 *
 * @argument @step
 * @type {Number}
 */

/** `@currentStep` The current step the guided stepper is on. This is
 * typically set in GuidedStepper on the contextual component.
 *
 * @argument @currentStep
 * @type {Number}
 */
export default class GuidedStepperTabComponent extends Component<GuidedStepperTabSignature> {
  get currentStatus() {
    return this.isActive
      ? GUIDED_STEPPER_STATUS_PROGRESS
      : this.step > this.currentStep
        ? GUIDED_STEPPER_STATUS_INCOMPLETE
        : GUIDED_STEPPER_STATUS_COMPLETE;
  }

  get currentStep() {
    const { currentStep } = this.args;

    assert(
      `@currentStep must be a string or a number.`,
      typeOf(currentStep) === 'string' || typeOf(currentStep) === 'number'
    );

    return parseInt(currentStep as string, 10);
  }

  get isActive() {
    return this.step === this.currentStep;
  }

  get step() {
    const { step } = this.args;

    assert(
      `@step must be a string or a number.`,
      typeOf(step) === 'string' || typeOf(step) === 'number'
    );

    return parseInt(step as string, 10);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GuidedStepper::Tab': typeof GuidedStepperTabComponent;
    'guided-stepper/tab': typeof GuidedStepperTabComponent;
  }
}
