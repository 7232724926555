/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudConsulTelemetry20230414TcpMetricsFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414TcpMetricsFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414TcpMetricsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cxRate': !exists(json, 'cx_rate') ? undefined : json['cx_rate'],
        'rxBitsRate': !exists(json, 'rx_bits_rate') ? undefined : json['rx_bits_rate'],
        'txBitsRate': !exists(json, 'tx_bits_rate') ? undefined : json['tx_bits_rate'],
        'cxConnectFailRate': !exists(json, 'cx_connect_fail_rate') ? undefined : json['cx_connect_fail_rate'],
        'cxNoneHealthyRate': !exists(json, 'cx_none_healthy_rate') ? undefined : json['cx_none_healthy_rate'],
    };
}
export function HashicorpCloudConsulTelemetry20230414TcpMetricsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cx_rate': value.cxRate,
        'rx_bits_rate': value.rxBitsRate,
        'tx_bits_rate': value.txBitsRate,
        'cx_connect_fail_rate': value.cxConnectFailRate,
        'cx_none_healthy_rate': value.cxNoneHealthyRate,
    };
}
