/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - RESTRICTED: Make the channel only visible to users with permission to create and manage.
 *  - UNRESTRICTED: Make the channel visible to every member of this organization.
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202CreateChannelBodyRestriction;
(function (HashicorpCloudPacker20221202CreateChannelBodyRestriction) {
    HashicorpCloudPacker20221202CreateChannelBodyRestriction["RESTRICTIONUNSET"] = "RESTRICTION_UNSET";
    HashicorpCloudPacker20221202CreateChannelBodyRestriction["RESTRICTED"] = "RESTRICTED";
    HashicorpCloudPacker20221202CreateChannelBodyRestriction["UNRESTRICTED"] = "UNRESTRICTED";
})(HashicorpCloudPacker20221202CreateChannelBodyRestriction || (HashicorpCloudPacker20221202CreateChannelBodyRestriction = {}));
export function HashicorpCloudPacker20221202CreateChannelBodyRestrictionFromJSON(json) {
    return HashicorpCloudPacker20221202CreateChannelBodyRestrictionFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202CreateChannelBodyRestrictionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202CreateChannelBodyRestrictionToJSON(value) {
    return value;
}
