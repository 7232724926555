/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudBoundary20211221ClusterMarketingSKUFromJSON, HashicorpCloudBoundary20211221ClusterMarketingSKUToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudBoundary20211221CreateRequestFromJSON(json) {
    return HashicorpCloudBoundary20211221CreateRequestFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221CreateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'boundaryRelease': !exists(json, 'boundary_release') ? undefined : json['boundary_release'],
        'marketingSku': !exists(json, 'marketing_sku') ? undefined : HashicorpCloudBoundary20211221ClusterMarketingSKUFromJSON(json['marketing_sku']),
        'enableHcpAuth': !exists(json, 'enable_hcp_auth') ? undefined : json['enable_hcp_auth'],
    };
}
export function HashicorpCloudBoundary20211221CreateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cluster_id': value.clusterId,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'username': value.username,
        'password': value.password,
        'boundary_release': value.boundaryRelease,
        'marketing_sku': HashicorpCloudBoundary20211221ClusterMarketingSKUToJSON(value.marketingSku),
        'enable_hcp_auth': value.enableHcpAuth,
    };
}
