/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20230613SyncInstallationFromJSON, Secrets20230613SyncInstallationToJSON, } from './';
export function Secrets20230613GetSyncInstallationResponseFromJSON(json) {
    return Secrets20230613GetSyncInstallationResponseFromJSONTyped(json, false);
}
export function Secrets20230613GetSyncInstallationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'syncInstallation': !exists(json, 'sync_installation') ? undefined : Secrets20230613SyncInstallationFromJSON(json['sync_installation']),
    };
}
export function Secrets20230613GetSyncInstallationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sync_installation': Secrets20230613SyncInstallationToJSON(value.syncInstallation),
    };
}
