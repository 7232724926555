/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var GhAppMetadataAccountType;
(function (GhAppMetadataAccountType) {
    GhAppMetadataAccountType["UNKNOWN"] = "UNKNOWN";
    GhAppMetadataAccountType["USER"] = "USER";
    GhAppMetadataAccountType["ORGANIZATION"] = "ORGANIZATION";
})(GhAppMetadataAccountType || (GhAppMetadataAccountType = {}));
export function GhAppMetadataAccountTypeFromJSON(json) {
    return GhAppMetadataAccountTypeFromJSONTyped(json, false);
}
export function GhAppMetadataAccountTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function GhAppMetadataAccountTypeToJSON(value) {
    return value;
}
