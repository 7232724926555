import { helper as buildHelper } from '@ember/component/helper';
import { groupRegionsByContinent, RegionProviders } from '../utils/regions.ts';

export const I18N_PREFIX = 'components.region-select';

const PROVIDER_TYPES = {
  azure: {
    group: groupRegionsByContinent(RegionProviders.azure),
  },
  aws: {
    group: groupRegionsByContinent(RegionProviders.aws),
  },
};

export function optionsForRegions([regions], { provider }) {
  let config = PROVIDER_TYPES[provider];
  return config.group(regions);
}

export default buildHelper(optionsForRegions);
