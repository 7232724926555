import templateOnlyComponent from '@ember/component/template-only';

interface LayoutPageHeaderTabsSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

const LayoutPageHeaderTabsComponent =
  templateOnlyComponent<LayoutPageHeaderTabsSignature>();

export default LayoutPageHeaderTabsComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::PageHeader::Tabs': typeof LayoutPageHeaderTabsComponent;
    'layout/page-header/tabs': typeof LayoutPageHeaderTabsComponent;
  }
}
