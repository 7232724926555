/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudNetwork20200907PeeringFromJSON, HashicorpCloudNetwork20200907PeeringToJSON, } from './';
export function HashicorpCloudNetwork20200907ListPeeringsResponseFromJSON(json) {
    return HashicorpCloudNetwork20200907ListPeeringsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907ListPeeringsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'peerings': !exists(json, 'peerings') ? undefined : (json['peerings'].map(HashicorpCloudNetwork20200907PeeringFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudNetwork20200907ListPeeringsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'peerings': value.peerings === undefined ? undefined : (value.peerings.map(HashicorpCloudNetwork20200907PeeringToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
