import {
  ROLE_KEY_ADMIN,
  ROLE_KEY_CONTRIBUTOR,
  ROLE_KEY_VIEWER,
  ROLE_KEY_BROWSER,
  ROLE_KEY_SECRETS_MANAGER,
  ROLE_KEY_SECRETS_READER,
  ROLE_KEY_INTEGRATION_MANAGER,
  ROLE_KEY_INTEGRATION_READER,
} from 'core/utils/cloud-iam-rbac-roles';

import type { RoleDefinitionService } from '../../../types/role-definitions-flyout';

const roleDefinitionFlyoutTranslationPath =
  'manage-access.components.flyout.role-definitions-flyout.project';

export const PROJECT_SERVICES: Array<RoleDefinitionService> = [
  {
    id: 'basic',
    title: `${roleDefinitionFlyoutTranslationPath}.all-services`,
    categories: [
      {
        title: `${roleDefinitionFlyoutTranslationPath}.basic.basic-roles`,
        actions: [
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.view-project`,
            permissions: ['resource-manager.projects.list'],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.edit-project-permissions`,
            permissions: ['resource-manager.projects.update'],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.delete-project`,
            permissions: ['resource-manager.projects.delete'],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.view-project-resources`,
            permissions: ['resource-manager.projects.get-iam-policy'],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.create-and-delete-project-resources`,
            permissions: ['resource-manager.projects.create'],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.manage-project-service-principals`,
            permissions: ['resource-manager.projects.update'],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.basic.actions.manage-group-role-for-project`,
            permissions: ['resource-manager.projects.create'],
          },
        ],
        columnRoleIds: [
          ROLE_KEY_ADMIN,
          ROLE_KEY_CONTRIBUTOR,
          ROLE_KEY_VIEWER,
          ROLE_KEY_BROWSER,
        ],
      },
    ],
  },
  // TODO HCPF-2018: fill in terraform columnRoleIds and actions permissions for comparison table
  // https://hashicorp.atlassian.net/browse/HCPF-2018
  // {
  //   id: 'terraform',
  //   categories: [
  //     {
  //       title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.project-roles.project-roles`,
  //       actions: [
  //         {
  //           title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.project-roles.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //     },
  //     {
  //       title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.workspaces.workspaces`,
  //       actions: [
  //         {
  //           title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.workspaces.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //       type: 'list',
  //     },
  //     {
  //       title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.group-management.group-management`,
  //       actions: [
  //         {
  //           title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.group-management.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //       type: 'list',
  //     },
  //     {
  //       title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.run-access.run-access`,
  //       actions: [
  //         {
  //           title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.run-access.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //       type: 'list',
  //     },
  //     {
  //       title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.variable-access.variable-access`,
  //       actions: [
  //         {
  //           title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.variable-access.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //       type: 'list',
  //     },
  //     {
  //       title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.state-access.state-access`,
  //       actions: [
  //         {
  //           title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.state-access.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //       type: 'list',
  //     },
  //     {
  //       title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.other-controls.other-controls`,
  //       actions: [
  //         {
  //           title: `${roleDefinitionFlyoutTranslationPath}.terraform.categories.other-controls.actions.some-example-action`,
  //           permissions: ['fill-in-permission-strings'],
  //         },
  //       ],
  //       columnRoleIds: [],
  //       type: 'list',
  //     },
  //   ],
  // },
  {
    id: 'secrets',
    title: `${roleDefinitionFlyoutTranslationPath}.secrets.vault-secrets`,
    categories: [
      {
        title: `${roleDefinitionFlyoutTranslationPath}.secrets.app`,
        actions: [
          {
            title: `${roleDefinitionFlyoutTranslationPath}.secrets.project-roles.actions.read-secrets`,
            permissions: ['secrets.app-secrets.list', 'secrets.apps.list'],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.secrets.project-roles.actions.create-edit-and-delete-secrets`,
            permissions: [
              'secrets.app-secrets.create',
              'secrets.app-secrets.update',
              'secrets.app-secrets.delete',
            ],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.secrets.project-roles.actions.create-edit-and-delete-applications`,
            permissions: [
              'secrets.apps.create',
              'secrets.apps.update',
              'secrets.apps.delete',
            ],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.secrets.project-roles.actions.create-edit-and-delete-hvs-role-assignments`,
            permissions: [
              'secrets.apps.set-iam-policy',
              'secrets.apps.get-iam-policy',
            ],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.secrets.project-roles.actions.manage-project-service-principals`,
            permissions: ['iam.service-principals.update'],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.secrets.project-roles.actions.create-and-manage-sync-integrations`,
            permissions: [
              'secrets.integrations.create',
              'secrets.integrations.update',
            ],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.secrets.project-roles.actions.connect-sync-integrations`,
            permissions: ['secrets.sync-integrations.force'],
          },
          {
            title: `${roleDefinitionFlyoutTranslationPath}.secrets.project-roles.actions.disconnect-sync-integrations`,
            permissions: ['secrets.sync-integrations.force'],
          },
        ],
        columnRoleIds: [ROLE_KEY_SECRETS_MANAGER, ROLE_KEY_SECRETS_READER],
      },
      {
        title: `${roleDefinitionFlyoutTranslationPath}.secrets.integration`,
        actions: [],
        type: 'list',
        columnRoleIds: [
          ROLE_KEY_INTEGRATION_MANAGER,
          ROLE_KEY_INTEGRATION_READER,
        ],
      },
    ],
  },
];
