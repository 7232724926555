/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202BuildStatusFromJSON, HashicorpCloudPacker20221202BuildStatusToJSON, HashicorpCloudPacker20221202ImageCreateBodyFromJSON, HashicorpCloudPacker20221202ImageCreateBodyToJSON, } from './';
export function HashicorpCloudPacker20221202UpdateBuildBodyFromJSON(json) {
    return HashicorpCloudPacker20221202UpdateBuildBodyFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202UpdateBuildBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': !exists(json, 'status') ? undefined : HashicorpCloudPacker20221202BuildStatusFromJSON(json['status']),
        'cloudProvider': !exists(json, 'cloud_provider') ? undefined : json['cloud_provider'],
        'packerRunUuid': !exists(json, 'packer_run_uuid') ? undefined : json['packer_run_uuid'],
        'images': !exists(json, 'images') ? undefined : (json['images'].map(HashicorpCloudPacker20221202ImageCreateBodyFromJSON)),
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'sourceImageId': !exists(json, 'source_image_id') ? undefined : json['source_image_id'],
        'sourceIterationId': !exists(json, 'source_iteration_id') ? undefined : json['source_iteration_id'],
        'sourceChannelId': !exists(json, 'source_channel_id') ? undefined : json['source_channel_id'],
    };
}
export function HashicorpCloudPacker20221202UpdateBuildBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': HashicorpCloudPacker20221202BuildStatusToJSON(value.status),
        'cloud_provider': value.cloudProvider,
        'packer_run_uuid': value.packerRunUuid,
        'images': value.images === undefined ? undefined : (value.images.map(HashicorpCloudPacker20221202ImageCreateBodyToJSON)),
        'labels': value.labels,
        'source_image_id': value.sourceImageId,
        'source_iteration_id': value.sourceIterationId,
        'source_channel_id': value.sourceChannelId,
    };
}
