/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202IterationTemplateType;
(function (HashicorpCloudPacker20221202IterationTemplateType) {
    HashicorpCloudPacker20221202IterationTemplateType["TEMPLATETYPEUNSET"] = "TEMPLATE_TYPE_UNSET";
    HashicorpCloudPacker20221202IterationTemplateType["HCL2"] = "HCL2";
    HashicorpCloudPacker20221202IterationTemplateType["JSON"] = "JSON";
})(HashicorpCloudPacker20221202IterationTemplateType || (HashicorpCloudPacker20221202IterationTemplateType = {}));
export function HashicorpCloudPacker20221202IterationTemplateTypeFromJSON(json) {
    return HashicorpCloudPacker20221202IterationTemplateTypeFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202IterationTemplateTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202IterationTemplateTypeToJSON(value) {
    return value;
}
