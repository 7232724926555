/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudConsul20210204VersionStatusFromJSON, HashicorpCloudConsul20210204VersionStatusToJSON, } from './';
export function HashicorpCloudConsul20210204VersionFromJSON(json) {
    return HashicorpCloudConsul20210204VersionFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204VersionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'version': !exists(json, 'version') ? undefined : json['version'],
        'status': !exists(json, 'status') ? undefined : HashicorpCloudConsul20210204VersionStatusFromJSON(json['status']),
    };
}
export function HashicorpCloudConsul20210204VersionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'version': value.version,
        'status': HashicorpCloudConsul20210204VersionStatusToJSON(value.status),
    };
}
