import type { IamEnrichedRole } from '../types/iam-enriched-role.ts';
import type { IamParsedRole } from '../types/iam-parsed-role.ts';

export default function iamFilterRolesByService(
  roles: Array<IamEnrichedRole>,
  { service }: { service: IamParsedRole['service'] },
): Array<IamEnrichedRole> {
  return roles.filter((role) => {
    return role.service === service;
  });
}
