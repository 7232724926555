import * as Sentry from '@sentry/ember';

export default function setSentryUser(owner, userId) {
  const analytics = owner.lookup('service:analytics');

  // https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/ according to the docs we need at least one of their main keys defined to capture user, so we set username to the segment anonymousId
  //If a user is logged in, we also set our system ID
  //
  //This is necessary so we can tell how many users are seeing a given set of errors. Usually Sentry will use IP Address for that but we have disabled that because IP Address is PII
  Sentry.setUser({
    ...(userId && { id: userId }),
    ...(analytics?.anonymousId && { username: analytics?.anonymousId }),
  });
}
