import NetworkOption from './network-option.js';

export default class NetworkOptionGroup {
  /** @type {String} */
  groupName = '';

  /** @type {Array.<NetworkOption>} */
  options = [];

  /** @type {Boolean} */
  disabled = false;

  constructor(groupName, options, disabled = false) {
    if (groupName !== undefined) this.groupName = groupName;

    if (Array.isArray(options)) {
      this.options = options.map(
        ({ network, disabled } = {}) => new NetworkOption(network, disabled)
      );
    }

    if (disabled !== undefined) this.disabled = disabled;
  }
}
