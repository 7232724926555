/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudPacker20221202ModelsCreateBucketBodyToJSON, HashicorpCloudPacker20221202ModelsCreateBucketResponseFromJSON, HashicorpCloudPacker20221202ModelsGetBucketResponseFromJSON, HashicorpCloudPacker20221202ModelsListBucketAncestryResponseFromJSON, HashicorpCloudPacker20221202ModelsListBucketsResponseFromJSON, HashicorpCloudPacker20221202ModelsUpdateBucketBodyToJSON, HashicorpCloudPacker20221202ModelsUpdateBucketResponseFromJSON, } from '../models';
/**
 *
 */
export class PackerBucketServiceApi extends runtime.BaseAPI {
    /**
     * Creates a bucket in the HCP Packer registry.
     */
    async packerBucketServiceCreateBucketRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerBucketServiceCreateBucket.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerBucketServiceCreateBucket.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling packerBucketServiceCreateBucket.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudPacker20221202ModelsCreateBucketBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsCreateBucketResponseFromJSON(jsonValue));
    }
    /**
     * Creates a bucket in the HCP Packer registry.
     */
    async packerBucketServiceCreateBucket(requestParameters) {
        const response = await this.packerBucketServiceCreateBucketRaw(requestParameters);
        return await response.value();
    }
    /**
     * Deletes the bucket and all its information, such as versions, builds and artifacts.
     */
    async packerBucketServiceDeleteBucketRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerBucketServiceDeleteBucket.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerBucketServiceDeleteBucket.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerBucketServiceDeleteBucket.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Deletes the bucket and all its information, such as versions, builds and artifacts.
     */
    async packerBucketServiceDeleteBucket(requestParameters) {
        const response = await this.packerBucketServiceDeleteBucketRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieves an existing bucket.
     */
    async packerBucketServiceGetBucketRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerBucketServiceGetBucket.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerBucketServiceGetBucket.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerBucketServiceGetBucket.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsGetBucketResponseFromJSON(jsonValue));
    }
    /**
     * Retrieves an existing bucket.
     */
    async packerBucketServiceGetBucket(requestParameters) {
        const response = await this.packerBucketServiceGetBucketRaw(requestParameters);
        return await response.value();
    }
    /**
     * Lists the ancestry relationships for the bucket. This includes direct source Bucket (hashicorp.cloud.packer_20221202_models.parents) and downstream artifacts built directly from the Bucket (hashicorp.cloud.packer_20221202_models.children).
     */
    async packerBucketServiceListBucketAncestryRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerBucketServiceListBucketAncestry.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerBucketServiceListBucketAncestry.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerBucketServiceListBucketAncestry.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }
        if (requestParameters.channelName !== undefined) {
            queryParameters['channel_name'] = requestParameters.channelName;
        }
        if (requestParameters.versionFingerprint !== undefined) {
            queryParameters['version_fingerprint'] = requestParameters.versionFingerprint;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/ancestry`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsListBucketAncestryResponseFromJSON(jsonValue));
    }
    /**
     * Lists the ancestry relationships for the bucket. This includes direct source Bucket (hashicorp.cloud.packer_20221202_models.parents) and downstream artifacts built directly from the Bucket (hashicorp.cloud.packer_20221202_models.children).
     */
    async packerBucketServiceListBucketAncestry(requestParameters) {
        const response = await this.packerBucketServiceListBucketAncestryRaw(requestParameters);
        return await response.value();
    }
    /**
     * Lists all existing buckets in the HCP Packer registry.
     */
    async packerBucketServiceListBucketsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerBucketServiceListBuckets.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerBucketServiceListBuckets.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        if (requestParameters.sortingOrderBy) {
            queryParameters['sorting.order_by'] = requestParameters.sortingOrderBy;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsListBucketsResponseFromJSON(jsonValue));
    }
    /**
     * Lists all existing buckets in the HCP Packer registry.
     */
    async packerBucketServiceListBuckets(requestParameters) {
        const response = await this.packerBucketServiceListBucketsRaw(requestParameters);
        return await response.value();
    }
    /**
     * Updates the bucket\'s information.
     */
    async packerBucketServiceUpdateBucketRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerBucketServiceUpdateBucket.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerBucketServiceUpdateBucket.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerBucketServiceUpdateBucket.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling packerBucketServiceUpdateBucket.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudPacker20221202ModelsUpdateBucketBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsUpdateBucketResponseFromJSON(jsonValue));
    }
    /**
     * Updates the bucket\'s information.
     */
    async packerBucketServiceUpdateBucket(requestParameters) {
        const response = await this.packerBucketServiceUpdateBucketRaw(requestParameters);
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var PackerBucketServiceListBucketAncestryTypeEnum;
(function (PackerBucketServiceListBucketAncestryTypeEnum) {
    PackerBucketServiceListBucketAncestryTypeEnum["UNSET"] = "ANCESTRY_TYPE_UNSET";
    PackerBucketServiceListBucketAncestryTypeEnum["PARENTS"] = "ANCESTRY_TYPE_PARENTS";
    PackerBucketServiceListBucketAncestryTypeEnum["CHILDREN"] = "ANCESTRY_TYPE_CHILDREN";
})(PackerBucketServiceListBucketAncestryTypeEnum || (PackerBucketServiceListBucketAncestryTypeEnum = {}));
