import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import { registerLocale, getNames } from 'i18n-iso-countries';
import enCountries from 'i18n-iso-countries/langs/en.json';
import { COUNTRY_CODES_BLOCK_LIST } from '../constants/country-codes-block-list';

registerLocale(enCountries);

export default class CountriesListHelper extends Helper {
  @service intl;

  compute() {
    let [lang] = this.intl.primaryLocale.split('-');
    let countries = getNames(lang);

    for (let blockedCountryCode of COUNTRY_CODES_BLOCK_LIST) {
      delete countries[blockedCountryCode];
    }

    return countries;
  }
}
