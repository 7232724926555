/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - BUILD_UNSET: UNSET is a sentinel zero value so that an uninitialized value can be
 * detected.
 *  - BUILD_RUNNING: Running means the `packer build` is currently running.
 *  - BUILD_DONE: Done means the `packer build` has finished successfully.
 *  - BUILD_CANCELLED: Cancelled means the `packer build` was cancelled by a user.
 *  - BUILD_FAILED: Failed means the `packer build` failed and therefore artifact creation failed.
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202ModelsBuildStatus;
(function (HashicorpCloudPacker20221202ModelsBuildStatus) {
    HashicorpCloudPacker20221202ModelsBuildStatus["UNSET"] = "BUILD_UNSET";
    HashicorpCloudPacker20221202ModelsBuildStatus["RUNNING"] = "BUILD_RUNNING";
    HashicorpCloudPacker20221202ModelsBuildStatus["DONE"] = "BUILD_DONE";
    HashicorpCloudPacker20221202ModelsBuildStatus["CANCELLED"] = "BUILD_CANCELLED";
    HashicorpCloudPacker20221202ModelsBuildStatus["FAILED"] = "BUILD_FAILED";
})(HashicorpCloudPacker20221202ModelsBuildStatus || (HashicorpCloudPacker20221202ModelsBuildStatus = {}));
export function HashicorpCloudPacker20221202ModelsBuildStatusFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsBuildStatusFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsBuildStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202ModelsBuildStatusToJSON(value) {
    return value;
}
