/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215TrialStatusFromJSON, HashicorpCloudGlobalNetworkManager20220215TrialStatusToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215TrialFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215TrialFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215TrialFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'trialStatus': !exists(json, 'trial_status') ? undefined : HashicorpCloudGlobalNetworkManager20220215TrialStatusFromJSON(json['trial_status']),
        'startDate': !exists(json, 'start_date') ? undefined : (new Date(json['start_date'])),
        'endDate': !exists(json, 'end_date') ? undefined : (new Date(json['end_date'])),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215TrialToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'trial_status': HashicorpCloudGlobalNetworkManager20220215TrialStatusToJSON(value.trialStatus),
        'start_date': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'end_date': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
    };
}
