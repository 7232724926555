import templateOnlyComponent from '@ember/component/template-only';

interface MenuTriggerSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const MenuTriggerComponent = templateOnlyComponent<MenuTriggerSignature>();

export default MenuTriggerComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Menu::Trigger': typeof MenuTriggerComponent;
    'menu/trigger': typeof MenuTriggerComponent;
  }
}
