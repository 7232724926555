/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { Billing20201105GetConsumptionPoolResponseFromJSON, Billing20201105ListConsumptionPoolsResponseFromJSON, } from '../models';
/**
 *
 */
export class FCPManagementServiceApi extends runtime.BaseAPI {
    /**
     * GetConsumptionPool returns a consumption pool with a specific id
     */
    async fCPManagementServiceGetConsumptionPoolRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling fCPManagementServiceGetConsumptionPool.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling fCPManagementServiceGetConsumptionPool.');
        }
        if (requestParameters.consumptionPoolId === null || requestParameters.consumptionPoolId === undefined) {
            throw new runtime.RequiredError('consumptionPoolId', 'Required parameter requestParameters.consumptionPoolId was null or undefined when calling fCPManagementServiceGetConsumptionPool.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/fcp-consumption-pools/{consumption_pool_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))).replace(`{${"consumption_pool_id"}}`, encodeURIComponent(String(requestParameters.consumptionPoolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105GetConsumptionPoolResponseFromJSON(jsonValue));
    }
    /**
     * GetConsumptionPool returns a consumption pool with a specific id
     */
    async fCPManagementServiceGetConsumptionPool(organizationId, billingAccountId, consumptionPoolId) {
        const response = await this.fCPManagementServiceGetConsumptionPoolRaw({ organizationId: organizationId, billingAccountId: billingAccountId, consumptionPoolId: consumptionPoolId });
        return await response.value();
    }
    /**
     * ListConsumptionPools returns all consumption pools for a billing account
     */
    async fCPManagementServiceListConsumptionPoolsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling fCPManagementServiceListConsumptionPools.');
        }
        if (requestParameters.billingAccountId === null || requestParameters.billingAccountId === undefined) {
            throw new runtime.RequiredError('billingAccountId', 'Required parameter requestParameters.billingAccountId was null or undefined when calling fCPManagementServiceListConsumptionPools.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/billing/2020-11-05/organizations/{organization_id}/accounts/{billing_account_id}/fcp-consumption-pools`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"billing_account_id"}}`, encodeURIComponent(String(requestParameters.billingAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => Billing20201105ListConsumptionPoolsResponseFromJSON(jsonValue));
    }
    /**
     * ListConsumptionPools returns all consumption pools for a billing account
     */
    async fCPManagementServiceListConsumptionPools(organizationId, billingAccountId) {
        const response = await this.fCPManagementServiceListConsumptionPoolsRaw({ organizationId: organizationId, billingAccountId: billingAccountId });
        return await response.value();
    }
}
