import BaseAbility from './base-ability';
import { inject as service } from '@ember/service';
import {
  PREFIX_NETWORK_DEPENDENCIES,
  ACTION_CREATE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  @service abilities;

  prefix = PREFIX_NETWORK_DEPENDENCIES;

  /**
   * Determine if create is available based on permissions and billing status.
   *
   * @return {boolean}
   */
  get canCreate() {
    return (
      this.abilities.can('bill billing-account') &&
      this.permissions.has(this.generate(ACTION_CREATE))
    );
  }
}
