import { action } from '@ember/object';
import Component from '@glimmer/component';

interface SplitListPaginationSignature {
  Args: {
    onNextPage?: () => void;
    onPreviousPage?: () => void;
  };
  Blocks: {
    default: [];
  };
}

/**
 *
 * `SplitListPagination` provides pagination controls for the `SplitList`.
 *
 * These controls are optional, the consumer does not have to render them if pagination
 * is not applicable to their use case.
 *
 * The pagination component takes two arguments:
 * - `onNextPage`: callback that is invoked when the next page button is clicked
 * - `onPreviousPage`: callback that is invoked when the previous page button is clicked
 *
 * These callbacks should only be provided *if* the associated action is available. For example:
 * - the `onNextPage` argument should not be provided if the current page is the last available page
 * - the `onPreviousPage` argument should not be provided if the current page is the first available page
 *
 * ```
 * <SplitList
 *   @identifyListItem={{this.identifyListItem}}
 *   @initialSelectedItem={{this.currentData.[1]}}
 *   @listData={{this.currentData}}
 *   @onSelectItem={{this.onSelectItem}} as |SL|
 * >
 *   <SL.Pagination
 *     @onNextPage={{if this.nextPageToken this.onNext}}
 *     @onPreviousPage={{if this.prevPageToken this.onPrev}}
 *   />
 * </SplitList>
 * ```
 *
 * @class SplitListPagination
 *
 */

export default class SplitListPaginationComponent extends Component<SplitListPaginationSignature> {
  @action
  onNextPage() {
    const { onNextPage } = this.args;
    onNextPage?.();
  }

  @action
  onPreviousPage() {
    const { onPreviousPage } = this.args;
    onPreviousPage?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SplitList::Pagination': typeof SplitListPaginationComponent;
    'split-list/pagination': typeof SplitListPaginationComponent;
  }
}
