import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import type ConfigService from 'api/services/config';

interface HttpErrorSignature {
  Args: {
    code: string;
    message?: string;
    title?: string;
    previousRoute?: string;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

/**
 *
 * `HttpError` wraps an Error component to help with rendering HTTP Errors.
 *
 *
 * ```
 * <HttpError
 *   @code="404"
 * />
 * ```
 *
 * @class HttpError
 *
 */

export default class HttpErrorComponent extends Component<HttpErrorSignature> {
  @service declare readonly config: ConfigService;

  /**
   * A route path for the Go back link.
   * @argument previousRoute
   * @type {?string}
   */

  /**
   * A string to override the default title for the error code.
   * @argument title
   * @type {?string}
   */

  /**
   * A string to override the default message for the error code.
   * @argument message
   * @type {?string}
   */

  /**
   * The HTTP Error code to use when mapping error messaging.
   * @argument code
   * @type {string}
   */

  get previousRoute() {
    return this.args.previousRoute || this.config?.app?.defaultRoute;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HttpError: typeof HttpErrorComponent;
    'http-error': typeof HttpErrorComponent;
  }
}
