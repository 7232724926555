/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Contacts Service
 * API for managing Contact information.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - UNSPECIFIED: This is never used.
 *  - HCP_USER_PRINCIPAL: Contact was sourced from a HCP user principal
 *  - AZURE_SUBSCRIPTION: Contact was sourced from HCS
 * @export
 * @enum {string}
 */
export var HashicorpCloudEmailSourceType;
(function(HashicorpCloudEmailSourceType) {
  HashicorpCloudEmailSourceType['UNSPECIFIED'] = 'UNSPECIFIED';
  HashicorpCloudEmailSourceType['HCPUSERPRINCIPAL'] = 'HCP_USER_PRINCIPAL';
  HashicorpCloudEmailSourceType['AZURESUBSCRIPTION'] = 'AZURE_SUBSCRIPTION';
})(HashicorpCloudEmailSourceType || (HashicorpCloudEmailSourceType = {}));
export function HashicorpCloudEmailSourceTypeFromJSON(json) {
  return HashicorpCloudEmailSourceTypeFromJSONTyped(json, false);
}
export function HashicorpCloudEmailSourceTypeFromJSONTyped(json, ignoreDiscriminator) {
  return json;
}
export function HashicorpCloudEmailSourceTypeToJSON(value) {
  return value;
}
