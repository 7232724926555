import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';

import disabledRoles from '../utils/disabled-roles.ts';

export default function iamFilterDisabledRoles(
  roles: Array<HashicorpCloudResourcemanagerRole>,
): Array<HashicorpCloudResourcemanagerRole> {
  if (!roles) {
    return [];
  }

  return roles.filter((role) => {
    if (!role || !role.id) {
      return false;
    }
    return !disabledRoles.includes(role.id);
  });
}
