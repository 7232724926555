/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20230613AzureKvConnectionDetailsRequestFromJSON, Secrets20230613AzureKvConnectionDetailsRequestToJSON, Secrets20230613OrganizationsLocationOrganizationIdProjectsLocationProjectIdAppsLocationFromJSON, Secrets20230613OrganizationsLocationOrganizationIdProjectsLocationProjectIdAppsLocationToJSON, } from './';
export function InlineObject4FromJSON(json) {
    return InlineObject4FromJSONTyped(json, false);
}
export function InlineObject4FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : Secrets20230613OrganizationsLocationOrganizationIdProjectsLocationProjectIdAppsLocationFromJSON(json['location']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'azureKvConnectionDetails': !exists(json, 'azure_kv_connection_details') ? undefined : Secrets20230613AzureKvConnectionDetailsRequestFromJSON(json['azure_kv_connection_details']),
    };
}
export function InlineObject4ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': Secrets20230613OrganizationsLocationOrganizationIdProjectsLocationProjectIdAppsLocationToJSON(value.location),
        'name': value.name,
        'azure_kv_connection_details': Secrets20230613AzureKvConnectionDetailsRequestToJSON(value.azureKvConnectionDetails),
    };
}
