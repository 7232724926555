import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';
import type IntlService from 'ember-intl/services/intl';
import type { AuthenticatedUser } from 'ember-simple-auth/services/session';

import type { HashicorpCloudResourcemanagerOrganization } from '@clients/cloud-resource-manager';
import type { HashicorpCloudIamUserPrincipal } from '@clients/cloud-iam';
import { HashicorpCloudIamUserPrincipalIdentityType } from '@clients/cloud-iam';

import CoreFlashMessages from '../services/flash-messages.ts';

type CurrentUser = HashicorpCloudIamUserPrincipal & AuthenticatedUser;

const SSO_IDENTITY_TYPES = [
  HashicorpCloudIamUserPrincipalIdentityType.HASHICORPSSO,
  HashicorpCloudIamUserPrincipalIdentityType.SAMLP,
];

interface PermissionsService {
  readonly organizationPermissions: string[];
}

export default class CurrentUserService extends Service {
  @service declare readonly intl: IntlService;
  @service declare readonly flashMessages: CoreFlashMessages;
  @service declare readonly permissions: PermissionsService;
  @tracked user: CurrentUser | null = null;
  @tracked organizations: HashicorpCloudResourcemanagerOrganization[] = [];

  get defaultName() {
    // always use the GH username because otherwise we will run afoul of
    // the db contraints to have ASCII-only characters
    return this.user?.nickname;
  }

  get defaultOrgName() {
    return this.slugify(
      this.intl.t('organization.default-name', { user: this.defaultName })
    );
  }

  get defaultProjectName() {
    return this.slugify(this.intl.t('project.default-name'));
  }

  get ownedOrganizations() {
    return this.organizations.filter(
      (organization) => organization?.owner?.user === this.user?.id
    );
  }

  get isSsoEnabled() {
    return this.user?.identityTypes?.some((type) => {
      return SSO_IDENTITY_TYPES.includes(type);
    });
  }

  slugify(str: string) {
    return dasherize(str)
      .replace(/[^a-z0-9-]+/gi, '-')
      .replace(/^[-]*/, '')
      .slice(0, 40)
      .replace(/[-]*$/, '')
      .toLowerCase();
  }

  get hasNoOrgRole() {
    return !this.permissions.organizationPermissions?.length;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    currentUser: CurrentUserService;
  }
}
