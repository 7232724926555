/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105StatementStateFromJSON, Billing20201105StatementStateToJSON, } from './';
export function Billing20201105StatementSummaryFromJSON(json) {
    return Billing20201105StatementSummaryFromJSONTyped(json, false);
}
export function Billing20201105StatementSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'billingPeriodStart': !exists(json, 'billing_period_start') ? undefined : (new Date(json['billing_period_start'])),
        'billingPeriodEnd': !exists(json, 'billing_period_end') ? undefined : (new Date(json['billing_period_end'])),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'state': !exists(json, 'state') ? undefined : Billing20201105StatementStateFromJSON(json['state']),
    };
}
export function Billing20201105StatementSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'billing_period_start': value.billingPeriodStart === undefined ? undefined : (value.billingPeriodStart.toISOString()),
        'billing_period_end': value.billingPeriodEnd === undefined ? undefined : (value.billingPeriodEnd.toISOString()),
        'amount': value.amount,
        'state': Billing20201105StatementStateToJSON(value.state),
    };
}
