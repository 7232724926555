import { action } from '@ember/object';
import Component from '@glimmer/component';

import { DropdownListItem } from '../../../utils/filter-bar/dropdown-manager.ts';

import type { HdsDropdownElement } from '../../../utils/filter-bar/index.ts';

interface Args {
  onSubmit: (value: string[], dropdown: HTMLElement) => void;
  listItems: DropdownListItem[];
  dd: HdsDropdownElement;
  trackInteraction: () => void;
}

/**
 * The singleSelectDropdown component is a custom dropdown body
 * component which is preconfigured to render a dropdown with radio buttons
 * to select a single item from a list and return it to the caller in
 * an array. The component can be used by itself inside an Hds::Dropdown component or
 * within a FilterBar component instance.
 *
 * Usage:
 *  <FilterBar::SingleSelectDropdown
 *     @dd={{dd}}
 *     @listItems={{@listItems}}
 *     @onSubmit={{@onSubmit}}
 *     @selectedValues={{@selectedValues}}
 *     @trackInteraction={{@trackInteraction}}
 *  />
 *
 * An optional tracking callback can be provided to instrument the dropdown.
 *
 * The @selectedValues parameter allows items to be pre-selected on render.
 *
 * @class SingleSelectDropdownComponent
 */

export default class SingleSelectDropdownComponent extends Component<Args> {
  selectedItem = '';

  get noneSelected() {
    return !this.selectedItem.length;
  }

  @action
  executeOnSelect() {
    this.args.onSubmit([this.selectedItem], this.args.dd);
    this.args.trackInteraction();
  }

  @action
  instantiateDropdown() {
    const selectedOption = this.args.listItems.find(
      (listItem) => listItem.optionValue,
    );
    this.selectedItem = selectedOption?.optionValue ?? '';
  }

  @action
  onSelect(evt: Event) {
    const { value } = evt.target as HTMLInputElement;
    this.selectedItem = value;
  }

  @action
  onKeyUp(e: KeyboardEvent) {
    if (e.key === 'Enter' && this.selectedItem) {
      this.executeOnSelect();
    }
  }
}
