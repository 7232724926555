import Component from '@glimmer/component';

interface DetailCardSignature {
  // Args: {};
  Blocks: {
    default: [unknown];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `DetailCard` wraps content for display on detail pages.
 *
 *
 * ```
 * <Detail::Card as |DC|>
 *   <DC.Header>Header Content</DC.Header>
 *   <DC.Content>Body Content</DC.Content>
 * </Detail::Card>
 * ```
 *
 * @class DetailCard
 *
 */

export default class DetailCardComponent extends Component<DetailCardSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Detail::Card': typeof DetailCardComponent;
    'detail/card': typeof DetailCardComponent;
  }
}
