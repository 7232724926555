/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { LogService20210330CreateDestinationFilterRequestToJSON, LogService20210330CreateDestinationRequestToJSON, LogService20210330CreateDestinationResponseFromJSON, LogService20210330GetDestinationResponseFromJSON, LogService20210330ListDestinationsResponseFromJSON, LogService20210330TestDestinationRequestToJSON, LogService20210330TestDestinationResponseFromJSON, LogService20210330UpdateDestinationRequestToJSON, } from '../models';
/**
 *
 */
export class StreamingServiceApi extends runtime.BaseAPI {
    /**
     * Create a Streaming Destination
     */
    async streamingServiceCreateDestinationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling streamingServiceCreateDestination.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling streamingServiceCreateDestination.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{organization_id}/destinations`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330CreateDestinationRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330CreateDestinationResponseFromJSON(jsonValue));
    }
    /**
     * Create a Streaming Destination
     */
    async streamingServiceCreateDestination(organizationId, body) {
        const response = await this.streamingServiceCreateDestinationRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * Attach a Filter to a Streaming Destination
     */
    async streamingServiceCreateDestinationFilterRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling streamingServiceCreateDestinationFilter.');
        }
        if (requestParameters.destinationId === null || requestParameters.destinationId === undefined) {
            throw new runtime.RequiredError('destinationId', 'Required parameter requestParameters.destinationId was null or undefined when calling streamingServiceCreateDestinationFilter.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling streamingServiceCreateDestinationFilter.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{organization_id}/destinations/{destination_id}/filters`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"destination_id"}}`, encodeURIComponent(String(requestParameters.destinationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330CreateDestinationFilterRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Attach a Filter to a Streaming Destination
     */
    async streamingServiceCreateDestinationFilter(organizationId, destinationId, body) {
        const response = await this.streamingServiceCreateDestinationFilterRaw({ organizationId: organizationId, destinationId: destinationId, body: body });
        return await response.value();
    }
    /**
     * Delete a Streaming Destination
     */
    async streamingServiceDeleteDestinationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling streamingServiceDeleteDestination.');
        }
        if (requestParameters.destinationId === null || requestParameters.destinationId === undefined) {
            throw new runtime.RequiredError('destinationId', 'Required parameter requestParameters.destinationId was null or undefined when calling streamingServiceDeleteDestination.');
        }
        const queryParameters = {};
        if (requestParameters.sourceChannel !== undefined) {
            queryParameters['source_channel'] = requestParameters.sourceChannel;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{organization_id}/destinations/{destination_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"destination_id"}}`, encodeURIComponent(String(requestParameters.destinationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Delete a Streaming Destination
     */
    async streamingServiceDeleteDestination(organizationId, destinationId, sourceChannel) {
        const response = await this.streamingServiceDeleteDestinationRaw({ organizationId: organizationId, destinationId: destinationId, sourceChannel: sourceChannel });
        return await response.value();
    }
    /**
     * Get a Streaming Destination
     */
    async streamingServiceGetDestinationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling streamingServiceGetDestination.');
        }
        if (requestParameters.destinationId === null || requestParameters.destinationId === undefined) {
            throw new runtime.RequiredError('destinationId', 'Required parameter requestParameters.destinationId was null or undefined when calling streamingServiceGetDestination.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{organization_id}/destinations/{destination_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"destination_id"}}`, encodeURIComponent(String(requestParameters.destinationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330GetDestinationResponseFromJSON(jsonValue));
    }
    /**
     * Get a Streaming Destination
     */
    async streamingServiceGetDestination(organizationId, destinationId) {
        const response = await this.streamingServiceGetDestinationRaw({ organizationId: organizationId, destinationId: destinationId });
        return await response.value();
    }
    /**
     * List the Streaming Destinations in an Organization
     */
    async streamingServiceListDestinationsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling streamingServiceListDestinations.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{organization_id}/destinations`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330ListDestinationsResponseFromJSON(jsonValue));
    }
    /**
     * List the Streaming Destinations in an Organization
     */
    async streamingServiceListDestinations(organizationId) {
        const response = await this.streamingServiceListDestinationsRaw({ organizationId: organizationId });
        return await response.value();
    }
    /**
     * Test a Streaming Destination
     */
    async streamingServiceTestDestinationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling streamingServiceTestDestination.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling streamingServiceTestDestination.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{organization_id}/destinations/test`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330TestDestinationRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => LogService20210330TestDestinationResponseFromJSON(jsonValue));
    }
    /**
     * Test a Streaming Destination
     */
    async streamingServiceTestDestination(organizationId, body) {
        const response = await this.streamingServiceTestDestinationRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * Update a Streaming Destination
     */
    async streamingServiceUpdateDestinationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling streamingServiceUpdateDestination.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling streamingServiceUpdateDestination.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling streamingServiceUpdateDestination.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/logs/2021-03-30/organizations/{organization_id}/destinations/{id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: LogService20210330UpdateDestinationRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Update a Streaming Destination
     */
    async streamingServiceUpdateDestination(organizationId, id, body) {
        const response = await this.streamingServiceUpdateDestinationRaw({ organizationId: organizationId, id: id, body: body });
        return await response.value();
    }
}
