/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * PrincipalType represents the possible types of principals.
 *
 *  - PRINCIPAL_TYPE_UNSPECIFIED: PRINCIPAL_TYPE_UNSPECIFIED is the default value.
 *  - PRINCIPAL_TYPE_USER: PRINCIPAL_TYPE_USER is the user principal type.
 *  - PRINCIPAL_TYPE_SERVICE: PRINCIPAL_TYPE_SERVICE is the service principal type.
 *  - PRINCIPAL_TYPE_GROUP: PRINCIPAL_TYPE_GROUP is the group principal type.
 *  - PRINCIPAL_TYPE_INTERNAL_OPERATOR: PRINCIPAL_TYPE_INTERNAL_OPERATOR is the internal operator principal type.
 * @export
 * @enum {string}
 */
export var HashicorpCloudIamPrincipalType;
(function (HashicorpCloudIamPrincipalType) {
    HashicorpCloudIamPrincipalType["UNSPECIFIED"] = "PRINCIPAL_TYPE_UNSPECIFIED";
    HashicorpCloudIamPrincipalType["USER"] = "PRINCIPAL_TYPE_USER";
    HashicorpCloudIamPrincipalType["SERVICE"] = "PRINCIPAL_TYPE_SERVICE";
    HashicorpCloudIamPrincipalType["GROUP"] = "PRINCIPAL_TYPE_GROUP";
    HashicorpCloudIamPrincipalType["INTERNALOPERATOR"] = "PRINCIPAL_TYPE_INTERNAL_OPERATOR";
})(HashicorpCloudIamPrincipalType || (HashicorpCloudIamPrincipalType = {}));
export function HashicorpCloudIamPrincipalTypeFromJSON(json) {
    return HashicorpCloudIamPrincipalTypeFromJSONTyped(json, false);
}
export function HashicorpCloudIamPrincipalTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudIamPrincipalTypeToJSON(value) {
    return value;
}
