/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Billing20201105CustomizedInputFromJSON(json) {
    return Billing20201105CustomizedInputFromJSONTyped(json, false);
}
export function Billing20201105CustomizedInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'itemKey': !exists(json, 'item_key') ? undefined : json['item_key'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
    };
}
export function Billing20201105CustomizedInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'item_key': value.itemKey,
        'quantity': value.quantity,
    };
}
