/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudLocationLinkFromJSON(json) {
    return HashicorpCloudLocationLinkFromJSONTyped(json, false);
}
export function HashicorpCloudLocationLinkFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !exists(json, 'type') ? undefined : json['type'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}
export function HashicorpCloudLocationLinkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'id': value.id,
        'uuid': value.uuid,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'description': value.description,
    };
}
