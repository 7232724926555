/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudConsulTelemetry20230414HttpMetricsFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414HttpMetricsFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414HttpMetricsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'reqRate': !exists(json, 'req_rate') ? undefined : json['req_rate'],
        'errorRatio': !exists(json, 'error_ratio') ? undefined : json['error_ratio'],
        'durationMsP50': !exists(json, 'duration_ms_p50') ? undefined : json['duration_ms_p50'],
        'durationMsP90': !exists(json, 'duration_ms_p90') ? undefined : json['duration_ms_p90'],
        'durationMsAvg': !exists(json, 'duration_ms_avg') ? undefined : json['duration_ms_avg'],
    };
}
export function HashicorpCloudConsulTelemetry20230414HttpMetricsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'req_rate': value.reqRate,
        'error_ratio': value.errorRatio,
        'duration_ms_p50': value.durationMsP50,
        'duration_ms_p90': value.durationMsP90,
        'duration_ms_avg': value.durationMsAvg,
    };
}
