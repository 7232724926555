/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVagrant20220930BoxSummaryFromJSON(json) {
    return HashicorpCloudVagrant20220930BoxSummaryFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930BoxSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'versionsCount': !exists(json, 'versions_count') ? undefined : json['versions_count'],
        'providerNames': !exists(json, 'provider_names') ? undefined : json['provider_names'],
        'latestReleasedAt': !exists(json, 'latest_released_at') ? undefined : (new Date(json['latest_released_at'])),
    };
}
export function HashicorpCloudVagrant20220930BoxSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'versions_count': value.versionsCount,
        'provider_names': value.providerNames,
        'latest_released_at': value.latestReleasedAt === undefined ? undefined : (value.latestReleasedAt.toISOString()),
    };
}
