/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215ServerStateFromJSON, HashicorpCloudGlobalNetworkManager20220215ServerStateToJSON, LocationIsTheLocationOfTheClusterWithAnOptionalProviderAndRegionFromJSON, LocationIsTheLocationOfTheClusterWithAnOptionalProviderAndRegionToJSON, } from './';
export function InlineObject3FromJSON(json) {
    return InlineObject3FromJSONTyped(json, false);
}
export function InlineObject3FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : LocationIsTheLocationOfTheClusterWithAnOptionalProviderAndRegionFromJSON(json['location']),
        'serverState': !exists(json, 'server_state') ? undefined : HashicorpCloudGlobalNetworkManager20220215ServerStateFromJSON(json['server_state']),
    };
}
export function InlineObject3ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': LocationIsTheLocationOfTheClusterWithAnOptionalProviderAndRegionToJSON(value.location),
        'server_state': HashicorpCloudGlobalNetworkManager20220215ServerStateToJSON(value.serverState),
    };
}
