import templateOnlyComponent from '@ember/component/template-only';

interface StepperStepIconSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

const StepperStepIconComponent =
  templateOnlyComponent<StepperStepIconSignature>();

export default StepperStepIconComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Stepper::StepIcon': typeof StepperStepIconComponent;
    'stepper/step-icon': typeof StepperStepIconComponent;
  }
}
