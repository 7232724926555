/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLinkFromJSON, HashicorpCloudLocationLinkToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, HashicorpCloudNetwork20200907TGWAttachmentProviderDataFromJSON, HashicorpCloudNetwork20200907TGWAttachmentProviderDataToJSON, HashicorpCloudNetwork20200907TGWAttachmentStateFromJSON, HashicorpCloudNetwork20200907TGWAttachmentStateToJSON, } from './';
export function HashicorpCloudNetwork20200907TGWAttachmentFromJSON(json) {
    return HashicorpCloudNetwork20200907TGWAttachmentFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907TGWAttachmentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'hvn': !exists(json, 'hvn') ? undefined : HashicorpCloudLocationLinkFromJSON(json['hvn']),
        'providerTgwAttachmentId': !exists(json, 'provider_tgw_attachment_id') ? undefined : json['provider_tgw_attachment_id'],
        'providerData': !exists(json, 'provider_data') ? undefined : HashicorpCloudNetwork20200907TGWAttachmentProviderDataFromJSON(json['provider_data']),
        'cidrs': !exists(json, 'cidrs') ? undefined : json['cidrs'],
        'state': !exists(json, 'state') ? undefined : HashicorpCloudNetwork20200907TGWAttachmentStateFromJSON(json['state']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}
export function HashicorpCloudNetwork20200907TGWAttachmentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'hvn': HashicorpCloudLocationLinkToJSON(value.hvn),
        'provider_tgw_attachment_id': value.providerTgwAttachmentId,
        'provider_data': HashicorpCloudNetwork20200907TGWAttachmentProviderDataToJSON(value.providerData),
        'cidrs': value.cidrs,
        'state': HashicorpCloudNetwork20200907TGWAttachmentStateToJSON(value.state),
    };
}
