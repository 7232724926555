/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVagrant20220930IndexFromJSON(json) {
    return HashicorpCloudVagrant20220930IndexFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930IndexFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'boxName': !exists(json, 'box_name') ? undefined : json['box_name'],
        'registryName': !exists(json, 'registry_name') ? undefined : json['registry_name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'shortDescription': !exists(json, 'short_description') ? undefined : json['short_description'],
        'isPrivate': !exists(json, 'is_private') ? undefined : json['is_private'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'downloads': !exists(json, 'downloads') ? undefined : json['downloads'],
        'currentVersionName': !exists(json, 'current_version_name') ? undefined : json['current_version_name'],
        'versionUpdatedAt': !exists(json, 'version_updated_at') ? undefined : (new Date(json['version_updated_at'])),
        'versionDescription': !exists(json, 'version_description') ? undefined : json['version_description'],
        'providerNames': !exists(json, 'provider_names') ? undefined : json['provider_names'],
        'architectureTypes': !exists(json, 'architecture_types') ? undefined : json['architecture_types'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
        'vcUrl': !exists(json, 'vc_url') ? undefined : json['vc_url'],
    };
}
export function HashicorpCloudVagrant20220930IndexToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'box_name': value.boxName,
        'registry_name': value.registryName,
        'description': value.description,
        'short_description': value.shortDescription,
        'is_private': value.isPrivate,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'downloads': value.downloads,
        'current_version_name': value.currentVersionName,
        'version_updated_at': value.versionUpdatedAt === undefined ? undefined : (value.versionUpdatedAt.toISOString()),
        'version_description': value.versionDescription,
        'provider_names': value.providerNames,
        'architecture_types': value.architectureTypes,
        'avatar': value.avatar,
        'vc_url': value.vcUrl,
    };
}
