/**
 * ZenDesk Parameter defintions
 * https://hashicorp.atlassian.net/wiki/spaces/GSS/pages/1607696498/Zendesk+URL+Parameter+Definitions
 **/

const SUPPORT_TICKET_PRODUCT_CATEGORIES = {
  consul: 'hcp_consul_cloud',
  vault: 'hcp_vault_cloud',
  packer: 'hcp_packer',
};

const supportTicketCloudProviderCategory = (currentRoute, provider) => {
  let product = currentRoute.split('.'); //consul or vault
  let providerCategory;
  if (provider) {
    providerCategory = `${provider}_hcp_${product[0]}`; // 'aws_hcp_consul', 'azure_hcp_consul', 'aws_hcp_vault', 'azure_hcp_vault'
  } else {
    providerCategory = `na_hcp_${product[0]}`; //'na_hcp_consul' or 'na_hcp_vault'
  }
  return providerCategory;
};

const HCP_SUPPORT_TICKET_CATEGORY = {
  'consul.clusters.overview': {
    category: SUPPORT_TICKET_PRODUCT_CATEGORIES['consul'],
  },
  'consul.clusters.self-managed.detail.index': {
    category: SUPPORT_TICKET_PRODUCT_CATEGORIES['consul'],
  },
  'consul.clusters.hcp.detail.index': {
    category: SUPPORT_TICKET_PRODUCT_CATEGORIES['consul'],
  },
  'vault.clusters.list': {
    category: SUPPORT_TICKET_PRODUCT_CATEGORIES['vault'],
  },
  'vault.detail.index': {
    category: SUPPORT_TICKET_PRODUCT_CATEGORIES['vault'],
  },
  'packer.buckets.bucket.index': {
    category: SUPPORT_TICKET_PRODUCT_CATEGORIES['packer'],
  },
};

const CLUSTER_DETAIL_ROUTES = {
  'consul.clusters.self-managed.detail.index': true,
  'consul.clusters.hcp.detail.index': true,
  'vault.detail.index': true,
};

const SUPPORT_TICKET_BASE_HREF =
  'https://support.hashicorp.com/hc/en-us/requests/new';

const HCP_SUPPORT_TICKET_PRODUCT = 'hashicorp_cloud_platform';

function supportTicketUserEmail(userContext) {
  if (userContext?.currentUser?.user?.email) {
    let { email } = userContext.currentUser.user;
    return encodeURIComponent(email);
  }
  return;
}

function supportTicketCategory(currentRouteName) {
  if (HCP_SUPPORT_TICKET_CATEGORY[currentRouteName]?.category) {
    return HCP_SUPPORT_TICKET_CATEGORY[currentRouteName].category;
  } else {
    return '';
  }
}

function supportTicketDescription(userContext, currentRouteName) {
  let orgDescription = '';

  if (userContext?.organization) {
    let { organization } = userContext;
    orgDescription = `HCP Org name(ID): ${organization.name}(${organization.id})`;

    // Include the CLUSTER ID in the description if at a Consul or Vault detail route
    if (CLUSTER_DETAIL_ROUTES[currentRouteName]) {
      let clusterDescription = `CLUSTER ID: ${userContext.resource.id}`;
      return `${encodeURIComponent(orgDescription)}

${encodeURIComponent(clusterDescription)}`;
    } else {
      return encodeURIComponent(orgDescription);
    }
  }
}

const SUPPORT_TICKET_PRODUCT_TIER_PREFIX = 'hcp_cluster_tier_';

export function supportTicketPrefillHref(userContext) {
  let currentRouteName = userContext?.router?.currentRouteName;
  let basePrefillContent = `${SUPPORT_TICKET_BASE_HREF}
?email=${supportTicketUserEmail(userContext)}
&m=${HCP_SUPPORT_TICKET_PRODUCT}
&description=${supportTicketDescription(userContext, currentRouteName)}`;
  /**
   * The query param for support ticket category ('h') needs to be specified first or the cloud provider and
   * product tier query params will be ignored
   */
  basePrefillContent = `${basePrefillContent}&h=${supportTicketCategory(
    currentRouteName
  )}`;
  if (HCP_SUPPORT_TICKET_CATEGORY[currentRouteName]?.category) {
    // resource only exists if it is a consul or vault cluster
    if (userContext?.resource) {
      let provider = userContext.resource.location.region?.provider;
      let providerCategory = supportTicketCloudProviderCategory(
        currentRouteName,
        provider
      );
      let tier = userContext.resource.config?.tier;

      /**
       * We need to check if the tier contains an underscore and if so, extract the tier
       * Vault tiers contain both the tier name and the size (i.e. 'PLUS_SMALL', and the tier is PLUS)
       * Tier values should be DEV, STARTER, STANDARD, and PLUS
       */
      if (userContext?.resource.config?.tier) {
        if (tier.includes('_')) {
          tier = tier.split('_');
          tier = tier[0];
          // Consul Development tier is DEVELOPMENT
          // Vault Development tier is DEV
          if (tier === 'DEVELOPMENT') {
            tier = 'DEV';
          }
        }
      } else {
        tier = 'NA';
      }

      let productTier = `${SUPPORT_TICKET_PRODUCT_TIER_PREFIX}${tier.toLowerCase()}`;
      basePrefillContent = `${basePrefillContent}&cp=${providerCategory}&pt=${productTier}`;
    }
  }
  return basePrefillContent;
}
