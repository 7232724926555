import Component from '@glimmer/component';

interface FlexGridItemSignature {
  Args: {
    xl?: unknown;
    xlOffset?: unknown;
    lg?: unknown;
    lgOffset?: unknown;
    md?: unknown;
    mdOffset?: unknown;
    sm?: unknown;
    smOffset?: unknown;
    xs?: unknown;
    xsOffset?: unknown;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `FlexGridItem` applies a class on a container to control the width of an item
 * within a `Grid`.
 *
 *
 * ```
 * <FlexGrid::Item
 *   @xs="6"
 *   @sm="6"
 *   @md="6"
 *   @lg="6"
 *   @xl="6"
 * >
 *   content
 * </FlexGrid::Item>
 * ```
 *
 * @class FlexGridItem
 *
 */

export default class FlexGridItemComponent extends Component<FlexGridItemSignature> {
  /**
   * `xs` applies the extra small class for this item.
   * @argument xs
   * @type {string}
   */
  /**
   * `xsOffset` applies the extra small offset class for this item.
   * @argument xsOffset
   * @type {string}
   */
  /**
   * `sm` applies the small class for this item.
   * @argument sm
   * @type {string}
   */
  /**
   * `smOffset` applies the small offset class for this item.
   * @argument smOffset
   * @type {string}
   */
  /**
   * `md` applies the medium class for this item.
   * @argument md
   * @type {string}
   */
  /**
   * `mdOffset` applies the medium offset class for this item.
   * @argument mdOffset
   * @type {string}
   */
  /**
   * `lg` applies the large class for this item.
   * @argument lg
   * @type {string}
   */
  /**
   * `lgOffset` applies the large offset class for this item.
   * @argument lgOffset
   * @type {string}
   */
  /**
   * `xl` applies the x-large class for this item.
   * @argument xl
   * @type {string}
   */
  /**
   * `xlOffset` applies the x-large offset class for this item.
   * @argument xlOffset
   * @type {string}
   */
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FlexGrid::Item': typeof FlexGridItemComponent;
    'flex-grid/item': typeof FlexGridItemComponent;
  }
}
