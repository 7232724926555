/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVagrant20220930ChecksumTypeFromJSON, HashicorpCloudVagrant20220930ChecksumTypeToJSON, } from './';
export function HashicorpCloudVagrant20220930BoxDataFromJSON(json) {
    return HashicorpCloudVagrant20220930BoxDataFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930BoxDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'downloadUrl': !exists(json, 'download_url') ? undefined : json['download_url'],
        'checksum': !exists(json, 'checksum') ? undefined : json['checksum'],
        'checksumType': !exists(json, 'checksum_type') ? undefined : HashicorpCloudVagrant20220930ChecksumTypeFromJSON(json['checksum_type']),
        'size': !exists(json, 'size') ? undefined : json['size'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}
export function HashicorpCloudVagrant20220930BoxDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'download_url': value.downloadUrl,
        'checksum': value.checksum,
        'checksum_type': HashicorpCloudVagrant20220930ChecksumTypeToJSON(value.checksumType),
    };
}
