/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GoogleRpcStatusFromJSON, GoogleRpcStatusToJSON, HashicorpCloudGlobalNetworkManager20220215LicensingFromJSON, HashicorpCloudGlobalNetworkManager20220215LicensingToJSON, HashicorpCloudGlobalNetworkManager20220215PeerStateFromJSON, HashicorpCloudGlobalNetworkManager20220215PeerStateToJSON, HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatusFromJSON, HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatusToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ClusterPartitionPeerFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ClusterPartitionPeerFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterPartitionPeerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'peeringConnectionLocation': !exists(json, 'peering_connection_location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['peering_connection_location']),
        'peeringConnectionId': !exists(json, 'peering_connection_id') ? undefined : json['peering_connection_id'],
        'peerName': !exists(json, 'peer_name') ? undefined : json['peer_name'],
        'peerClusterLocation': !exists(json, 'peer_cluster_location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['peer_cluster_location']),
        'peerClusterId': !exists(json, 'peer_cluster_id') ? undefined : json['peer_cluster_id'],
        'peerPartitionName': !exists(json, 'peer_partition_name') ? undefined : json['peer_partition_name'],
        'peeringStatus': !exists(json, 'peering_status') ? undefined : HashicorpCloudGlobalNetworkManager20220215PeerStateFromJSON(json['peering_status']),
        'lastHeartbeatTimestamp': !exists(json, 'last_heartbeat_timestamp') ? undefined : (new Date(json['last_heartbeat_timestamp'])),
        'importedServicesCount': !exists(json, 'imported_services_count') ? undefined : json['imported_services_count'],
        'exportedServicesCount': !exists(json, 'exported_services_count') ? undefined : json['exported_services_count'],
        'displayError': !exists(json, 'display_error') ? undefined : GoogleRpcStatusFromJSON(json['display_error']),
        'peeringConnectionStatus': !exists(json, 'peering_connection_status') ? undefined : HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatusFromJSON(json['peering_connection_status']),
        'peerLicensing': !exists(json, 'peer_licensing') ? undefined : HashicorpCloudGlobalNetworkManager20220215LicensingFromJSON(json['peer_licensing']),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterPartitionPeerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'peering_connection_location': HashicorpCloudLocationLocationToJSON(value.peeringConnectionLocation),
        'peering_connection_id': value.peeringConnectionId,
        'peer_name': value.peerName,
        'peer_cluster_location': HashicorpCloudLocationLocationToJSON(value.peerClusterLocation),
        'peer_cluster_id': value.peerClusterId,
        'peer_partition_name': value.peerPartitionName,
        'peering_status': HashicorpCloudGlobalNetworkManager20220215PeerStateToJSON(value.peeringStatus),
        'last_heartbeat_timestamp': value.lastHeartbeatTimestamp === undefined ? undefined : (value.lastHeartbeatTimestamp.toISOString()),
        'imported_services_count': value.importedServicesCount,
        'exported_services_count': value.exportedServicesCount,
        'display_error': GoogleRpcStatusToJSON(value.displayError),
        'peering_connection_status': HashicorpCloudGlobalNetworkManager20220215PeeringConnectionStatusToJSON(value.peeringConnectionStatus),
        'peer_licensing': HashicorpCloudGlobalNetworkManager20220215LicensingToJSON(value.peerLicensing),
    };
}
