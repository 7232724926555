export const PREFIX_VAULT_RADAR = 'vault-radar';
export const VAULT_RADAR_USERS = 'users';
export const VAULT_RADAR_DEVELOPER = 'developer';

export const VAULT_RADAR_VIEW = 'can-view';
export const VAULT_RADAR_CONTRIBUTE = 'can-contribute';
export const VAULT_RADAR_ADMIN = 'can-admin';

export const VAULT_RADAR_ALL_PERMISSIONS = [
  `${PREFIX_VAULT_RADAR}.${VAULT_RADAR_USERS}.${VAULT_RADAR_VIEW}`,
  `${PREFIX_VAULT_RADAR}.${VAULT_RADAR_USERS}.${VAULT_RADAR_CONTRIBUTE}`,
  `${PREFIX_VAULT_RADAR}.${VAULT_RADAR_USERS}.${VAULT_RADAR_ADMIN}`,
];

export const VAULT_RADAR_ADMIN_PERMISSIONS = [...VAULT_RADAR_ALL_PERMISSIONS];

export const VAULT_RADAR_CONTRIBUTOR_PERMISSIONS = [
  `${PREFIX_VAULT_RADAR}.${VAULT_RADAR_USERS}.${VAULT_RADAR_VIEW}`,
  `${PREFIX_VAULT_RADAR}.${VAULT_RADAR_USERS}.${VAULT_RADAR_CONTRIBUTE}`,
];

export const VAULT_RADAR_VIEWER_PERMISSIONS = [
  `${PREFIX_VAULT_RADAR}.${VAULT_RADAR_USERS}.${VAULT_RADAR_VIEW}`,
];
