/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevel;
(function (HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevel) {
    HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevel["UNSPECIFIED"] = "CONSUL_ACCESS_LEVEL_UNSPECIFIED";
    HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevel["GLOBALREADONLY"] = "CONSUL_ACCESS_LEVEL_GLOBAL_READ_ONLY";
    HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevel["GLOBALREADWRITE"] = "CONSUL_ACCESS_LEVEL_GLOBAL_READ_WRITE";
})(HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevel || (HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevel = {}));
export function HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevelFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevelFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevelFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevelToJSON(value) {
    return value;
}
