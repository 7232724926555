/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Contacts Service
 * API for managing Contact information.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudEmailCreateContactResponseFromJSON(json) {
  return HashicorpCloudEmailCreateContactResponseFromJSONTyped(json, false);
}
export function HashicorpCloudEmailCreateContactResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
  };
}
export function HashicorpCloudEmailCreateContactResponseToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
  };
}
