/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - UPGRADE_TYPE_UNSPECIFIED: UPGRADE_TYPE_UNSPECIFIED is an unspecified upgrade type
 *  - UPGRADE_TYPE_AUTOMATIC: UPGRADE_TYPE_AUTOMATIC is the upgrade type where we pick the schedule
 *  - UPGRADE_TYPE_SCHEDULED: UPGRADE_TYPE_SCHEDULED is the upgrade type where the user picks the schedule
 *  - UPGRADE_TYPE_MANUAL: UPGRADE_TYPE_MANUAL is the upgrade type where the user can manually trigger the upgrade before a set window,
 * after which the cluster will be automatically upgraded.
 * @export
 * @enum {string}
 */
export var HashicorpCloudBoundary20211221UpgradeType;
(function (HashicorpCloudBoundary20211221UpgradeType) {
    HashicorpCloudBoundary20211221UpgradeType["UNSPECIFIED"] = "UPGRADE_TYPE_UNSPECIFIED";
    HashicorpCloudBoundary20211221UpgradeType["AUTOMATIC"] = "UPGRADE_TYPE_AUTOMATIC";
    HashicorpCloudBoundary20211221UpgradeType["SCHEDULED"] = "UPGRADE_TYPE_SCHEDULED";
    HashicorpCloudBoundary20211221UpgradeType["MANUAL"] = "UPGRADE_TYPE_MANUAL";
})(HashicorpCloudBoundary20211221UpgradeType || (HashicorpCloudBoundary20211221UpgradeType = {}));
export function HashicorpCloudBoundary20211221UpgradeTypeFromJSON(json) {
    return HashicorpCloudBoundary20211221UpgradeTypeFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221UpgradeTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudBoundary20211221UpgradeTypeToJSON(value) {
    return value;
}
