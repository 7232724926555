/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - STATE_UPDATING: UPDATING should remain the default and generically used state.
 * If no custom status required, people should stick with UPDATING
 * rather than proliferating UPDATING_* without an explicit need
 *  - STATE_UPDATING_TOKEN: UPDATING_TOKEN is an edge-case of UPDATING intended to be used so
 * that frontend can display a custom status for it.
 *  - STATE_UPDATING_ACCESS_LEVEL: STATE_UPDATING_ACCESS_LEVEL is an edge-case of UPDATING intended to be
 * used so that frontend can display a custom status for it.
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215ClusterState;
(function (HashicorpCloudGlobalNetworkManager20220215ClusterState) {
    HashicorpCloudGlobalNetworkManager20220215ClusterState["UNSPECIFIED"] = "STATE_UNSPECIFIED";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["PENDING"] = "STATE_PENDING";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["CREATING"] = "STATE_CREATING";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["RUNNING"] = "STATE_RUNNING";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["FAILED"] = "STATE_FAILED";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["UPDATING"] = "STATE_UPDATING";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["RESTORING"] = "STATE_RESTORING";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["DELETING"] = "STATE_DELETING";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["DELETED"] = "STATE_DELETED";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["DISCONNECTED"] = "STATE_DISCONNECTED";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["UPDATINGTOKEN"] = "STATE_UPDATING_TOKEN";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["PENDINGINVALIDTOKEN"] = "STATE_PENDING_INVALID_TOKEN";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["DISCONNECTEDINVALIDTOKEN"] = "STATE_DISCONNECTED_INVALID_TOKEN";
    HashicorpCloudGlobalNetworkManager20220215ClusterState["UPDATINGACCESSLEVEL"] = "STATE_UPDATING_ACCESS_LEVEL";
})(HashicorpCloudGlobalNetworkManager20220215ClusterState || (HashicorpCloudGlobalNetworkManager20220215ClusterState = {}));
export function HashicorpCloudGlobalNetworkManager20220215ClusterStateFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ClusterStateFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterStateToJSON(value) {
    return value;
}
