/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20230613GitHubRepositoryFromJSON, Secrets20230613GitHubRepositoryToJSON, } from './';
export function Secrets20230613GetGitHubRepositoriesResponseFromJSON(json) {
    return Secrets20230613GetGitHubRepositoriesResponseFromJSONTyped(json, false);
}
export function Secrets20230613GetGitHubRepositoriesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'installationId': !exists(json, 'installation_id') ? undefined : json['installation_id'],
        'repositories': !exists(json, 'repositories') ? undefined : (json['repositories'].map(Secrets20230613GitHubRepositoryFromJSON)),
    };
}
export function Secrets20230613GetGitHubRepositoriesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'owner': value.owner,
        'installation_id': value.installationId,
        'repositories': value.repositories === undefined ? undefined : (value.repositories.map(Secrets20230613GitHubRepositoryToJSON)),
    };
}
