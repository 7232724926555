/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215AggregateServiceSummaryFromJSON, HashicorpCloudGlobalNetworkManager20220215AggregateServiceSummaryToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215GetAggregateServiceSummaryResponseFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215GetAggregateServiceSummaryResponseFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215GetAggregateServiceSummaryResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'totalServices': !exists(json, 'total_services') ? undefined : json['total_services'],
        'totalServiceInstances': !exists(json, 'total_service_instances') ? undefined : json['total_service_instances'],
        'passingServiceInstances': !exists(json, 'passing_service_instances') ? undefined : json['passing_service_instances'],
        'warningServiceInstances': !exists(json, 'warning_service_instances') ? undefined : json['warning_service_instances'],
        'criticalServiceInstances': !exists(json, 'critical_service_instances') ? undefined : json['critical_service_instances'],
        'aggregateServiceSummaryByKind': !exists(json, 'aggregate_service_summary_by_kind') ? undefined : (mapValues(json['aggregate_service_summary_by_kind'], HashicorpCloudGlobalNetworkManager20220215AggregateServiceSummaryFromJSON)),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215GetAggregateServiceSummaryResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'total_services': value.totalServices,
        'total_service_instances': value.totalServiceInstances,
        'passing_service_instances': value.passingServiceInstances,
        'warning_service_instances': value.warningServiceInstances,
        'critical_service_instances': value.criticalServiceInstances,
        'aggregate_service_summary_by_kind': value.aggregateServiceSummaryByKind === undefined ? undefined : (mapValues(value.aggregateServiceSummaryByKind, HashicorpCloudGlobalNetworkManager20220215AggregateServiceSummaryToJSON)),
    };
}
