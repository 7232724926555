/**
 * This file sets up the RBAC permission strings and roles defined by
 * the cloud-vault-service and sent to the cloud-resource-manager.
 */

/** PREFIXES
 * This prefix is used in /abilities/vault*.js to extend the
 * BaseAbility class. BaseAbility uses the prefix in its built-in
 * getters, such as canList, canUpdate, etc.
 *
 * The prefixes here should always match the permission strings below,
 * however we aren't using them there to keep the VAULT_*
 * consts more legible.
 */
export const PREFIX_VAULT_CLUSTERS = 'vault.clusters';
export const PREFIX_VAULT_AUDIT_LOGS = 'vault.audit-logs';
export const PREFIX_VAULT_PATHS_FILTER = 'vault.pathsfilter';
export const PREFIX_VAULT_SNAPSHOTS = 'vault.snapshots';

/** PERMISSIONS
 *  The RBAC permission strings here are used by cloud-ui/addons/authz/addons/services/permissions.js, which fetches the full list of
 * permissions from the cloud-resource-manager API and checks whether
 * the passed in permission string is included. Locally these strings
 * are mapped to the roles below for testing purposes.
 *
 * These strings should exactly match https://github.com/hashicorp/cloud-vault-service/blob/master/permissions/permissions.go
 */

// cluster-related permissions
export const VAULT_CLUSTERS_CREATE = 'vault.clusters.create';
export const VAULT_CLUSTERS_CREATE_ADMIN_TOKEN =
  'vault.clusters.create-admin-token';
export const VAULT_CLUSTERS_DELETE = 'vault.clusters.delete';
export const VAULT_CLUSTERS_GET = 'vault.clusters.get';
export const VAULT_CLUSTERS_LIST = 'vault.clusters.list';
export const VAULT_CLUSTERS_LOCK = 'vault.clusters.lock';
export const VAULT_CLUSTERS_REVOKE_ADMIN_TOKENS =
  'vault.clusters.revoke-admin-tokens';
export const VAULT_CLUSTERS_SCALE = 'vault.clusters.scale';
export const VAULT_CLUSTERS_UNLOCK = 'vault.clusters.unlock';
export const VAULT_CLUSTERS_UPDATE = 'vault.clusters.update';

// snapshots
export const VAULT_SNAPSHOTS_CREATE = 'vault.snapshots.create';
export const VAULT_SNAPSHOTS_DELETE = 'vault.snapshots.delete';
export const VAULT_SNAPSHOTS_GET = 'vault.snapshots.get';
export const VAULT_SNAPSHOTS_LIST = 'vault.snapshots.list';
export const VAULT_SNAPSHOTS_UPDATE = 'vault.snapshots.update';

// replication
export const VAULT_PATHS_FILTER_CREATE = 'vault.pathsfilter.create';
export const VAULT_PATHS_FILTER_DELETE = 'vault.pathsfilter.delete';
export const VAULT_PERFORMANCE_REPLICATION_DISABLE =
  'vault.performancereplication.disable';
export const VAULT_PERFORMANCE_REPLICATION_ENABLE =
  'vault.performancereplication.enable';
export const VAULT_PERFORMANCE_REPLICATION_LIST_SECONDARIES =
  'vault.performancereplication.secondaries-list';

// major version upgrades
export const VAULT_VERSION_NOTIFICATIONS_CONTRIBUTOR =
  'vault.notifications.contributor';
export const VAULT_VERSION_NOTIFICATIONS_VIEWER = 'vault.notifications.viewer';
export const VAULT_VERSION_UPGRADE_CONFIG_UPDATE =
  'vault.majorversionupgradeconfig.update';
export const VAULT_VERSION_UPGRADE_MANUAL_UPGRADE =
  'vault.majorversionupgrade.manual-upgrade';

// audit logs
export const VAULT_AUDIT_LOGS_GET = 'vault.audit-logs.get';

/** ROLES
 * The roles defined here are used for local development and testing
 * purposes only. We use them within mirage scenarios and tests to
 * conveniently simulate a user with common set of permissions.
 *
 * Roles are really just a bundle of permissions that are generally
 * grouped together for the same kind of user. While we currently have
 * 4 roles set up, eventually users will be able to customize their own.
 * see: https://admin.hashicorp.cloud/roles
 *
 * For now, roles have cascading permissions, so admins have
 * contributor perms + more, contributors have viewer perms + more,
 * etc.
 *
 * Roles and their permissions mappings should match https://github.com/hashicorp/cloud-vault-service/blob/master/internal/initializer/roles.go
 */
export const VAULT_VIEWER_ROLE_PERMISSIONS = [
  // cluster permissions
  VAULT_CLUSTERS_GET,
  VAULT_CLUSTERS_LIST,

  // snapshot permissions
  VAULT_SNAPSHOTS_GET,
  VAULT_SNAPSHOTS_LIST,

  // replication
  VAULT_PERFORMANCE_REPLICATION_LIST_SECONDARIES,

  // major version upgrades
  VAULT_VERSION_NOTIFICATIONS_VIEWER,
];

export const VAULT_CONTRIBUTOR_ROLE_PERMISSIONS = [
  // cluster permissions
  VAULT_CLUSTERS_CREATE,
  VAULT_CLUSTERS_UPDATE,
  VAULT_CLUSTERS_DELETE,
  VAULT_CLUSTERS_LOCK,
  VAULT_CLUSTERS_UNLOCK,
  VAULT_CLUSTERS_CREATE_ADMIN_TOKEN,

  // snapshot permissions
  VAULT_SNAPSHOTS_CREATE,
  VAULT_SNAPSHOTS_UPDATE,
  VAULT_SNAPSHOTS_DELETE,

  // replication
  VAULT_PERFORMANCE_REPLICATION_DISABLE,
  VAULT_PERFORMANCE_REPLICATION_ENABLE,
  VAULT_PATHS_FILTER_CREATE,
  VAULT_PATHS_FILTER_DELETE,

  // major version upgrades
  VAULT_VERSION_NOTIFICATIONS_VIEWER,

  // and all the viewer perms
  ...VAULT_VIEWER_ROLE_PERMISSIONS,
];

export const VAULT_ADMIN_ROLE_PERMISSIONS = [
  // clusters
  VAULT_CLUSTERS_SCALE,
  VAULT_CLUSTERS_REVOKE_ADMIN_TOKENS,

  // audit logs
  VAULT_AUDIT_LOGS_GET,

  // replication
  VAULT_PERFORMANCE_REPLICATION_LIST_SECONDARIES,

  // major version upgrades
  VAULT_VERSION_UPGRADE_MANUAL_UPGRADE,
  VAULT_VERSION_UPGRADE_CONFIG_UPDATE,
  VAULT_VERSION_NOTIFICATIONS_CONTRIBUTOR,

  // and all the contributor + viewer perms
  ...VAULT_CONTRIBUTOR_ROLE_PERMISSIONS,
];

// owner is kind of a special role. It has all admin permissions + some bonus ones, but we don't care about the bonus ones yet
export const VAULT_OWNER_ROLE_PERMISSIONS = [...VAULT_ADMIN_ROLE_PERMISSIONS];

// owners have all permissions, but this is set up so that when we
// import it it's more obvious that it represents the full list of
// permissions
export const VAULT_ALL_PERMISSIONS = [...VAULT_OWNER_ROLE_PERMISSIONS];
