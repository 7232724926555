import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';

/**
 * Lists the CSS classes used to impose a level of privacy on the data in an HTML / Glimmer element.
 * These classes are used to indicate to Fullstory (and future tooling choices) how to treat elements
 * containing private data when recording user sessions.
 * @see https://help.fullstory.com/hc/en-us/articles/360020623574-How-do-I-protect-my-users-privacy-in-FullStory-
 *
 * Example:
 * ```
 * <input {{data-privacy}} name="credit-card-number" />
 * <input {{data-privacy 'mask'}} name="expiry-year" />
 * ```
 *
 * @property {String[]} REDACT - highest level of privacy (redacted, not reversible)
 * @property {String[]} MASK - low level of privacy (potentially reversible)
 * @property {String[]} UNMASK - absolutely NO privacy (use ONLY to unmask targeted children of a masked parent)
 */
export const PRIVACY_LEVEL_CLASS_NAME_MAPPINGS = {
  REDACT: ['fs-exclude'],
  MASK: ['fs-mask'],
  UNMASK: ['fs-unmask'],
};

export const PRIVACY_LEVEL_SCALE = Object.keys(
  PRIVACY_LEVEL_CLASS_NAME_MAPPINGS
);

function cleanup(instance) {
  let { observer } = instance;

  if (observer) {
    observer.disconnect();
  }
}

export default class DataPrivacyModifier extends Modifier {
  observer = null;

  modify(element, params) {
    this.addMutationObserver(element, params);
    registerDestructor(this, cleanup);
  }

  addMutationObserver = (element, params) => {
    let [privacyLevel] = params;
    let privacyLevelKey = `${privacyLevel}`.toUpperCase();
    let privacyLevelClassNames = PRIVACY_LEVEL_SCALE.includes(privacyLevelKey)
      ? PRIVACY_LEVEL_CLASS_NAME_MAPPINGS[privacyLevelKey]
      : PRIVACY_LEVEL_CLASS_NAME_MAPPINGS.REDACT;

    // add the class name initially
    element.classList.add(...privacyLevelClassNames);

    let observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        // only add the classes if they're missing
        if (
          !Array.from(mutation.target.classList).includes(
            ...privacyLevelClassNames
          )
        ) {
          mutation.target.classList.add(...privacyLevelClassNames);
        }
      });
    });

    observer.observe(element, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class'],
    });

    this.observer = observer;
  };
}
