/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, HashicorpCloudNetwork20200907NetworkProviderNetworkDataFromJSON, HashicorpCloudNetwork20200907NetworkProviderNetworkDataToJSON, HashicorpCloudNetwork20200907NetworkStateFromJSON, HashicorpCloudNetwork20200907NetworkStateToJSON, } from './';
export function HashicorpCloudNetwork20200907NetworkFromJSON(json) {
    return HashicorpCloudNetwork20200907NetworkFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907NetworkFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'state': !exists(json, 'state') ? undefined : HashicorpCloudNetwork20200907NetworkStateFromJSON(json['state']),
        'cidrBlock': !exists(json, 'cidr_block') ? undefined : json['cidr_block'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'providerNetworkData': !exists(json, 'provider_network_data') ? undefined : HashicorpCloudNetwork20200907NetworkProviderNetworkDataFromJSON(json['provider_network_data']),
    };
}
export function HashicorpCloudNetwork20200907NetworkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'state': HashicorpCloudNetwork20200907NetworkStateToJSON(value.state),
        'cidr_block': value.cidrBlock,
        'provider_network_data': HashicorpCloudNetwork20200907NetworkProviderNetworkDataToJSON(value.providerNetworkData),
    };
}
