/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointRefApplicationFromJSON, HashicorpCloudWaypointRefApplicationToJSON, } from './';
export function HashicorpCloudWaypointActionRunScopeFromJSON(json) {
    return HashicorpCloudWaypointActionRunScopeFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointActionRunScopeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'global': !exists(json, 'global') ? undefined : json['global'],
        'application': !exists(json, 'application') ? undefined : HashicorpCloudWaypointRefApplicationFromJSON(json['application']),
    };
}
export function HashicorpCloudWaypointActionRunScopeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'global': value.global,
        'application': HashicorpCloudWaypointRefApplicationToJSON(value.application),
    };
}
