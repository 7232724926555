import Helper from '@ember/component/helper';

import { AWS_QUICK_CREATE_LINK } from '../utils/peerings';

import {
  SOURCE_HVN_PEERING_KEY,
  SOURCE_HVN_PEERING_QUICK_CREATE_KEY,
} from 'core/helpers/option-for-source-state';

/**
 * This returns the type of source channel state mapping string needed to return
 * the proper icon and label for a peering connection.
 *
 * @param {array} args - 0th element of args must be a peering.
 * @param {HashicorpCloudNetwork20200907Peering | Object} args[0] - A peering connection.
 * @returns {string}
 */

export default class networksConnectionSourceType extends Helper {
  compute([connection = {}]) {
    let { sourceChannel = '' } = connection;

    switch (sourceChannel) {
      case AWS_QUICK_CREATE_LINK:
        return SOURCE_HVN_PEERING_QUICK_CREATE_KEY;
      default:
        return SOURCE_HVN_PEERING_KEY;
    }
  }
}
