import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { task, timeout } from 'ember-concurrency';

import {
  COPY_ICON_NAME,
  COPY_ICON_CLASS,
  COPIED_ICON_NAME,
  COPIED_ICON_CLASS,
} from '../utils/consts/code-block.js';

interface CodeBlockSignature {
  Args: {
    disabled: unknown;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `CodeBlock` wraps code or terminal examples with the proper styling.
 *
 *
 * ```
 * <CodeBlock @disabled={{true}}>rm -rf .</CodeBlock>
 * ```
 *
 * @class CodeBlock
 *
 */

/**
 *
 * `disabled` is optional and if true, it does not render the copy button
 * @argument disabled;
 * @type {Boolean}
 *
 */
export default class CodeBlockComponent extends Component<CodeBlockSignature> {
  @tracked codeBlockId = guidFor(this);
  @tracked copyIconName = COPY_ICON_NAME;
  @tracked copyIconClass = COPY_ICON_CLASS;

  @task
  *copied() {
    const originalIconName = this.copyIconName;
    const originalIconClass = this.copyIconClass;

    this.copyIconName = COPIED_ICON_NAME;
    this.copyIconClass = COPIED_ICON_CLASS;

    yield timeout(1000);

    this.copyIconName = originalIconName;
    this.copyIconClass = originalIconClass;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CodeBlock: typeof CodeBlockComponent;
    'code-block': typeof CodeBlockComponent;
  }
}
