/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215TokenInvalidReasonFromJSON, HashicorpCloudGlobalNetworkManager20220215TokenInvalidReasonToJSON, HashicorpCloudGlobalNetworkManager20220215TokenValidityStatusFromJSON, HashicorpCloudGlobalNetworkManager20220215TokenValidityStatusToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215GetClusterManagementTokenDetailsResponseFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215GetClusterManagementTokenDetailsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215GetClusterManagementTokenDetailsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'validityStatus': !exists(json, 'validity_status') ? undefined : HashicorpCloudGlobalNetworkManager20220215TokenValidityStatusFromJSON(json['validity_status']),
        'invalidReasons': !exists(json, 'invalid_reasons') ? undefined : (json['invalid_reasons'].map(HashicorpCloudGlobalNetworkManager20220215TokenInvalidReasonFromJSON)),
        'timeUntilExpiration': !exists(json, 'time_until_expiration') ? undefined : json['time_until_expiration'],
        'accessorId': !exists(json, 'accessor_id') ? undefined : json['accessor_id'],
    };
}
export function HashicorpCloudGlobalNetworkManager20220215GetClusterManagementTokenDetailsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'validity_status': HashicorpCloudGlobalNetworkManager20220215TokenValidityStatusToJSON(value.validityStatus),
        'invalid_reasons': value.invalidReasons === undefined ? undefined : (value.invalidReasons.map(HashicorpCloudGlobalNetworkManager20220215TokenInvalidReasonToJSON)),
        'time_until_expiration': value.timeUntilExpiration,
        'accessor_id': value.accessorId,
    };
}
