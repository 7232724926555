import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { supportTicketPrefillHref } from '../utils/support-ticket.js';

import type UserContextService from '../services/user-context.ts';
import type QuotaService from '../services/quota.ts';

interface LimitBannerSignature {
  Args: {
    count: number;
    padding: string;
    variant: string;
  };
  Blocks: {
    default: [unknown];
  };
}

/**
 *
 * `LimitBanner` renders an alert with the given text for the passed
 *  variant (resource). This will be rendered when a limit from the quota
 * service has been exceeded.
 *
 *
 * ```
 * <LimitBanner
 *  @count={{numClusters}}
 *  @padding={{"0 0 0 0"}}
 *  @variant={{resource-type-for "consul"}}
 * />
 * ```
 *
 * @class LimitBanner
 *
 *
 */

/**
 * The number of resources that currently exist for the given variant. If the
 * variant is 'hashicorp.consul.cluster', this would be the number of clusters.
 *
 * @argument count
 * @type {number}
 */

/**
 * Forwards this string to the box-padding modifier.
 *
 * @argument padding
 * @type {string}
 */

/**
 * Determines what translation strings to show when rendering the LimitBanner.
 *
 * @argument variant
 * @type {string}
 */

export default class LimitBannerComponent extends Component<LimitBannerSignature> {
  @service declare readonly quota: QuotaService;
  @service declare readonly userContext: UserContextService;

  get limit() {
    const { variant } = this.args;
    return this.quota.for(variant);
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LimitBanner: typeof LimitBannerComponent;
    'limit-banner': typeof LimitBannerComponent;
  }
}
