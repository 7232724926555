/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetLabelValuesRequestToJSON, HashicorpCloudConsulTelemetry20230414AgentTelemetryConfigResponseFromJSON, HashicorpCloudConsulTelemetry20230414GetLabelValuesResponseFromJSON, HashicorpCloudConsulTelemetry20230414GetServiceTopologyResponseFromJSON, HashicorpCloudConsulTelemetry20230414QueryRangeBatchResponseFromJSON, InlineObjectToJSON, QueryRangeBatchRequestToJSON, } from '../models';
/**
 *
 */
export class ConsulTelemetryServiceApi extends runtime.BaseAPI {
    /**
     */
    async agentTelemetryConfigRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling agentTelemetryConfig.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling agentTelemetryConfig.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling agentTelemetryConfig.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling agentTelemetryConfig.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/ctgw/2023-04-14/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/agent/telemetry_config`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsulTelemetry20230414AgentTelemetryConfigResponseFromJSON(jsonValue));
    }
    /**
     */
    async agentTelemetryConfig(requestParameters) {
        const response = await this.agentTelemetryConfigRaw(requestParameters);
        return await response.value();
    }
    /**
     * Returns the label values for a given cluster
     */
    async getLabelValuesRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getLabelValues.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getLabelValues.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling getLabelValues.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling getLabelValues.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/ctgw/2023-04-14/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/label/values`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetLabelValuesRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsulTelemetry20230414GetLabelValuesResponseFromJSON(jsonValue));
    }
    /**
     * Returns the label values for a given cluster
     */
    async getLabelValues(requestParameters) {
        const response = await this.getLabelValuesRaw(requestParameters);
        return await response.value();
    }
    /**
     * Creates a service topology graph based on recent metrics from the mesh.
     */
    async getServiceTopologyRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getServiceTopology.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getServiceTopology.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start.toISOString();
        }
        if (requestParameters.duration !== undefined) {
            queryParameters['duration'] = requestParameters.duration;
        }
        if (requestParameters.rootClusterInternalId !== undefined) {
            queryParameters['root.cluster_internal_id'] = requestParameters.rootClusterInternalId;
        }
        if (requestParameters.rootClusterId !== undefined) {
            queryParameters['root.cluster_id'] = requestParameters.rootClusterId;
        }
        if (requestParameters.rootPartition !== undefined) {
            queryParameters['root.partition'] = requestParameters.rootPartition;
        }
        if (requestParameters.rootNamespace !== undefined) {
            queryParameters['root.namespace'] = requestParameters.rootNamespace;
        }
        if (requestParameters.rootName !== undefined) {
            queryParameters['root.name'] = requestParameters.rootName;
        }
        if (requestParameters.rootTrustDomain !== undefined) {
            queryParameters['root.trust_domain'] = requestParameters.rootTrustDomain;
        }
        if (requestParameters.includesClusterInternalId !== undefined) {
            queryParameters['includes.cluster_internal_id'] = requestParameters.includesClusterInternalId;
        }
        if (requestParameters.includesClusterId !== undefined) {
            queryParameters['includes.cluster_id'] = requestParameters.includesClusterId;
        }
        if (requestParameters.includesPartition !== undefined) {
            queryParameters['includes.partition'] = requestParameters.includesPartition;
        }
        if (requestParameters.includesNamespace !== undefined) {
            queryParameters['includes.namespace'] = requestParameters.includesNamespace;
        }
        if (requestParameters.includesName !== undefined) {
            queryParameters['includes.name'] = requestParameters.includesName;
        }
        if (requestParameters.includesTrustDomain !== undefined) {
            queryParameters['includes.trust_domain'] = requestParameters.includesTrustDomain;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/ctgw/2023-04-14/organizations/{location.organization_id}/projects/{location.project_id}/topologies/service`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsulTelemetry20230414GetServiceTopologyResponseFromJSON(jsonValue));
    }
    /**
     * Creates a service topology graph based on recent metrics from the mesh.
     */
    async getServiceTopology(requestParameters) {
        const response = await this.getServiceTopologyRaw(requestParameters);
        return await response.value();
    }
    /**
     * Custom endpoints for observability that batches QueryRange requests
     */
    async queryRangeBatchRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling queryRangeBatch.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling queryRangeBatch.');
        }
        if (requestParameters.clusterId === null || requestParameters.clusterId === undefined) {
            throw new runtime.RequiredError('clusterId', 'Required parameter requestParameters.clusterId was null or undefined when calling queryRangeBatch.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling queryRangeBatch.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/ctgw/2023-04-14/organizations/{location.organization_id}/projects/{location.project_id}/clusters/{cluster_id}/query_range_batch`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"cluster_id"}}`, encodeURIComponent(String(requestParameters.clusterId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryRangeBatchRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudConsulTelemetry20230414QueryRangeBatchResponseFromJSON(jsonValue));
    }
    /**
     * Custom endpoints for observability that batches QueryRange requests
     */
    async queryRangeBatch(requestParameters) {
        const response = await this.queryRangeBatchRaw(requestParameters);
        return await response.value();
    }
}
