/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTC;
(function (HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTC) {
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTC["TIMEWINDOWUTCINVALID"] = "TIME_WINDOW_UTC_INVALID";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTC["WINDOW12AM4AM"] = "WINDOW_12AM_4AM";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTC["WINDOW6AM10AM"] = "WINDOW_6AM_10AM";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTC["WINDOW12PM4PM"] = "WINDOW_12PM_4PM";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTC["WINDOW6PM10PM"] = "WINDOW_6PM_10PM";
})(HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTC || (HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTC = {}));
export function HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTCFromJSON(json) {
    return HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTCFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTCFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTCToJSON(value) {
    return value;
}
