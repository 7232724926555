/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125HTTPEncodingCodec;
(function (HashicorpCloudVault20201125HTTPEncodingCodec) {
    HashicorpCloudVault20201125HTTPEncodingCodec["JSON"] = "JSON";
    HashicorpCloudVault20201125HTTPEncodingCodec["NDJSON"] = "NDJSON";
})(HashicorpCloudVault20201125HTTPEncodingCodec || (HashicorpCloudVault20201125HTTPEncodingCodec = {}));
export function HashicorpCloudVault20201125HTTPEncodingCodecFromJSON(json) {
    return HashicorpCloudVault20201125HTTPEncodingCodecFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125HTTPEncodingCodecFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125HTTPEncodingCodecToJSON(value) {
    return value;
}
