/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330AuditFilterScopeFromJSON, LogService20210330AuditFilterScopeToJSON, } from './';
export function LogService20210330DataPlaneAuditFilterFromJSON(json) {
    return LogService20210330DataPlaneAuditFilterFromJSONTyped(json, false);
}
export function LogService20210330DataPlaneAuditFilterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'includeResources': !exists(json, 'include_resources') ? undefined : (json['include_resources'].map(LogService20210330AuditFilterScopeFromJSON)),
    };
}
export function LogService20210330DataPlaneAuditFilterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'include_resources': value.includeResources === undefined ? undefined : (value.includeResources.map(LogService20210330AuditFilterScopeToJSON)),
    };
}
