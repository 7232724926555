/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202ModelsTemplateTypeFromJSON, HashicorpCloudPacker20221202ModelsTemplateTypeToJSON, } from './';
export function HashicorpCloudPacker20221202ModelsCreateVersionBodyFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsCreateVersionBodyFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsCreateVersionBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'fingerprint': !exists(json, 'fingerprint') ? undefined : json['fingerprint'],
        'templateType': !exists(json, 'template_type') ? undefined : HashicorpCloudPacker20221202ModelsTemplateTypeFromJSON(json['template_type']),
    };
}
export function HashicorpCloudPacker20221202ModelsCreateVersionBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'fingerprint': value.fingerprint,
        'template_type': HashicorpCloudPacker20221202ModelsTemplateTypeToJSON(value.templateType),
    };
}
