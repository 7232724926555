import templateOnlyComponent from '@ember/component/template-only';

interface CoreLayoutMainHorizontalViewportSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

const CoreLayoutMainHorizontalViewportComponent =
  templateOnlyComponent<CoreLayoutMainHorizontalViewportSignature>();

export default CoreLayoutMainHorizontalViewportComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::Layout::Main::HorizontalViewport': typeof CoreLayoutMainHorizontalViewportComponent;
    'core/layout/main/horizontal-viewport': typeof CoreLayoutMainHorizontalViewportComponent;
  }
}
