import Component from '@glimmer/component';

interface CoreCardSummaryTitleSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

/**
 *
 * `CoreCardSummaryTitleComponent` provides a container to display a key in a key/value pair.
 *
 *
 * ```
 * <Core::Card::Summary::Key>
 *   Some Key
 * </Core::Card::Summary::Key>
 * ```
 *
 * @class CoreCardSummaryTitleComponent
 *
 */

export default class CoreCardSummaryTitleComponent extends Component<CoreCardSummaryTitleSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Core::Card::Summary::Title': typeof CoreCardSummaryTitleComponent;
    'core/card/summary/title': typeof CoreCardSummaryTitleComponent;
  }
}
