/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * BillingAccountStatus is the status of the billing account (e.g. active,
 * canceled).
 * @export
 * @enum {string}
 */
export var Billing20201105BillingAccountStatus;
(function (Billing20201105BillingAccountStatus) {
    Billing20201105BillingAccountStatus["ACTIVE"] = "ACTIVE";
    Billing20201105BillingAccountStatus["DELETING"] = "DELETING";
    Billing20201105BillingAccountStatus["DELINQUENT"] = "DELINQUENT";
    Billing20201105BillingAccountStatus["INCOMPLETE"] = "INCOMPLETE";
    Billing20201105BillingAccountStatus["TRIAL"] = "TRIAL";
})(Billing20201105BillingAccountStatus || (Billing20201105BillingAccountStatus = {}));
export function Billing20201105BillingAccountStatusFromJSON(json) {
    return Billing20201105BillingAccountStatusFromJSONTyped(json, false);
}
export function Billing20201105BillingAccountStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Billing20201105BillingAccountStatusToJSON(value) {
    return value;
}
