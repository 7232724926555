/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function LogService20210330StreamingCloudwatchLogsProviderFromJSON(json) {
    return LogService20210330StreamingCloudwatchLogsProviderFromJSONTyped(json, false);
}
export function LogService20210330StreamingCloudwatchLogsProviderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'region': !exists(json, 'region') ? undefined : json['region'],
        'roleArn': !exists(json, 'role_arn') ? undefined : json['role_arn'],
        'externalId': !exists(json, 'external_id') ? undefined : json['external_id'],
        'logGroupName': !exists(json, 'log_group_name') ? undefined : json['log_group_name'],
        'logStreamName': !exists(json, 'log_stream_name') ? undefined : json['log_stream_name'],
    };
}
export function LogService20210330StreamingCloudwatchLogsProviderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'region': value.region,
        'role_arn': value.roleArn,
        'external_id': value.externalId,
        'log_group_name': value.logGroupName,
        'log_stream_name': value.logStreamName,
    };
}
