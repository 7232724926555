/**
 * This directory contains analytics event strings meant for consumption by the core/
 * analytics service. The strings here should follow a precise format to ensure our analytics
 * events are sent consistently. For more information, check out /docs/analytics/index.md.
 *
 * The root file here contains the global prefix and list of actions which should be combined to
 * create an analytics event string.
 *
 * Each engine or resource should have its own file to store their individual analytics events.
 * That file should contain those engine-specific "resources" and "UI elements" as well as the
 * combined analytics events.
 *
 * Resource event naming schema should be as follows:
 *  ui_<resource type>_<past tense action verb>
 *  ^ use this when the event is tracking an action being taken on a resource (such as a cluster
 * or network)
 *
 * UI element event naming schema should be as follows:
 *  ui_<ui element>_<past tense action verb>
 *  ^ use this when the event is tracking an action being taken on a UI element (such as a button
 * or dropdown)
 *
 * Why do we need this when Heap can track these automatically? If we want to look at UI and
 * backend events together, we can only do that through Looker. Only custom events sent through
 * Segment are discoverable in Looker (the automatic UI events Heap tracks are not).
 */

// global prefix (for for all UI events)
export const PREFIX = 'external_ui';

// global actions
export const ACTIONS = {
  CLICKED: 'clicked',
  CREATED: 'created',
  DELETE_SUBMITTED: 'deleted',
  DETECTED: 'detected',
  DISABLE_SUBMITTED: 'disabled',
  ENABLE_SUBMITTED: 'enabled',
  GENERATED: 'generated',
  INSTALLED: 'installed',
  LOCKED: 'locked',
  PLAYED: 'played',
  RENDERED: 'rendered',
  RESTORE_SUBMITTED: 'restored',
  SEALED: 'sealed',
  SUBMITTED: 'submitted',
  UNLINKED: 'unlinked',
  UNLOCKED: 'unlocked',
  UNSEALED: 'unsealed',
  UPDATE_SUBMITTED: 'updated',
};
