import templateOnlyComponent from '@ember/component/template-only';

interface LayoutFullScreenMainSignature {
  // Args: {};
  Blocks: {
    content: [];
    footer: [];
    logo: [];
  };
  Element: HTMLElement;
}

const LayoutFullScreenMainComponent =
  templateOnlyComponent<LayoutFullScreenMainSignature>();

export default LayoutFullScreenMainComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::FullScreen::Main': typeof LayoutFullScreenMainComponent;
    'layout/full-screen/main': typeof LayoutFullScreenMainComponent;
  }
}
