/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Webhook Service
 * API for delivering and managing webhooks.
 *
 * The version of the OpenAPI document: 2023-05-31
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWebhookWebhookSubscriptionEventFromJSON(json) {
    return HashicorpCloudWebhookWebhookSubscriptionEventFromJSONTyped(json, false);
}
export function HashicorpCloudWebhookWebhookSubscriptionEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'action': !exists(json, 'action') ? undefined : json['action'],
        'source': !exists(json, 'source') ? undefined : json['source'],
    };
}
export function HashicorpCloudWebhookWebhookSubscriptionEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'action': value.action,
        'source': value.source,
    };
}
