/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudPacker20221202UpdateBucketBodyFromJSON(json) {
    return HashicorpCloudPacker20221202UpdateBucketBodyFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202UpdateBucketBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'platforms': !exists(json, 'platforms') ? undefined : json['platforms'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
    };
}
export function HashicorpCloudPacker20221202UpdateBucketBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'platforms': value.platforms,
        'description': value.description,
        'labels': value.labels,
    };
}
