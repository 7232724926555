import Component from '@glimmer/component';

import { ALL_CONNECTION_METHODS } from '../../../../utils/connection-methods';
/**
 *
 * `NetworksFormInputsConnectionCards` renders the provider radio cards for AWS and Azure
 *
 *
 * ```
 * <Networks::FormInputs::ConnectionCards
 *   @onChange={{onChange}}
 *   @selectedConnectionMethod='quick-links'
 * />
 * ```
 *
 * @class NetworksFormInputsConnectionCards
 *
 */

export default class NetworksFormInputsConnectionCardsComponent extends Component {
  connectionContent =
    'components.networks.form-inputs.connection-method-cards.';

  get connectionMethods() {
    let { connectionMethods } = this.args;
    return connectionMethods || ALL_CONNECTION_METHODS;
  }
}
