/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105CardDetailsFromJSON, Billing20201105CardDetailsToJSON, Billing20201105OnDemandBillingMethodFromJSON, Billing20201105OnDemandBillingMethodToJSON, } from './';
export function Billing20201105OnDemandBillingMethodDetailsFromJSON(json) {
    return Billing20201105OnDemandBillingMethodDetailsFromJSONTyped(json, false);
}
export function Billing20201105OnDemandBillingMethodDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'billingMethod': !exists(json, 'billing_method') ? undefined : Billing20201105OnDemandBillingMethodFromJSON(json['billing_method']),
        'cardDetails': !exists(json, 'card_details') ? undefined : Billing20201105CardDetailsFromJSON(json['card_details']),
    };
}
export function Billing20201105OnDemandBillingMethodDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'billing_method': Billing20201105OnDemandBillingMethodToJSON(value.billingMethod),
        'card_details': Billing20201105CardDetailsToJSON(value.cardDetails),
    };
}
