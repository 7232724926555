/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202BuildFromJSON, HashicorpCloudPacker20221202BuildToJSON, HashicorpCloudPacker20221202IterationExternalStatusFromJSON, HashicorpCloudPacker20221202IterationExternalStatusToJSON, HashicorpCloudPacker20221202IterationRevocationTypeFromJSON, HashicorpCloudPacker20221202IterationRevocationTypeToJSON, HashicorpCloudPacker20221202IterationTemplateTypeFromJSON, HashicorpCloudPacker20221202IterationTemplateTypeToJSON, HashicorpCloudPacker20221202RevokedAncestorFromJSON, HashicorpCloudPacker20221202RevokedAncestorToJSON, } from './';
export function HashicorpCloudPacker20221202IterationFromJSON(json) {
    return HashicorpCloudPacker20221202IterationFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202IterationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'bucketSlug': !exists(json, 'bucket_slug') ? undefined : json['bucket_slug'],
        'iterationAncestorId': !exists(json, 'iteration_ancestor_id') ? undefined : json['iteration_ancestor_id'],
        'incrementalVersion': !exists(json, 'incremental_version') ? undefined : json['incremental_version'],
        'complete': !exists(json, 'complete') ? undefined : json['complete'],
        'authorId': !exists(json, 'author_id') ? undefined : json['author_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'fingerprint': !exists(json, 'fingerprint') ? undefined : json['fingerprint'],
        'builds': !exists(json, 'builds') ? undefined : (json['builds'].map(HashicorpCloudPacker20221202BuildFromJSON)),
        'revokeAt': !exists(json, 'revoke_at') ? undefined : (new Date(json['revoke_at'])),
        'revocationMessage': !exists(json, 'revocation_message') ? undefined : json['revocation_message'],
        'revocationAuthor': !exists(json, 'revocation_author') ? undefined : json['revocation_author'],
        'revocationType': !exists(json, 'revocation_type') ? undefined : HashicorpCloudPacker20221202IterationRevocationTypeFromJSON(json['revocation_type']),
        'revocationInheritedFrom': !exists(json, 'revocation_inherited_from') ? undefined : HashicorpCloudPacker20221202RevokedAncestorFromJSON(json['revocation_inherited_from']),
        'hasDescendants': !exists(json, 'has_descendants') ? undefined : json['has_descendants'],
        'templateType': !exists(json, 'template_type') ? undefined : HashicorpCloudPacker20221202IterationTemplateTypeFromJSON(json['template_type']),
        'status': !exists(json, 'status') ? undefined : HashicorpCloudPacker20221202IterationExternalStatusFromJSON(json['status']),
    };
}
export function HashicorpCloudPacker20221202IterationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'bucket_slug': value.bucketSlug,
        'iteration_ancestor_id': value.iterationAncestorId,
        'incremental_version': value.incrementalVersion,
        'complete': value.complete,
        'author_id': value.authorId,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'fingerprint': value.fingerprint,
        'builds': value.builds === undefined ? undefined : (value.builds.map(HashicorpCloudPacker20221202BuildToJSON)),
        'revoke_at': value.revokeAt === undefined ? undefined : (value.revokeAt.toISOString()),
        'revocation_message': value.revocationMessage,
        'revocation_author': value.revocationAuthor,
        'revocation_type': HashicorpCloudPacker20221202IterationRevocationTypeToJSON(value.revocationType),
        'revocation_inherited_from': HashicorpCloudPacker20221202RevokedAncestorToJSON(value.revocationInheritedFrom),
        'has_descendants': value.hasDescendants,
        'template_type': HashicorpCloudPacker20221202IterationTemplateTypeToJSON(value.templateType),
        'status': HashicorpCloudPacker20221202IterationExternalStatusToJSON(value.status),
    };
}
