/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215ClusterPartitionPeerFromJSON, HashicorpCloudGlobalNetworkManager20220215ClusterPartitionPeerToJSON, HashicorpCloudGlobalNetworkManager20220215LicensingFromJSON, HashicorpCloudGlobalNetworkManager20220215LicensingToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ClusterPartitionPeersFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ClusterPartitionPeersFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterPartitionPeersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'partitionName': !exists(json, 'partition_name') ? undefined : json['partition_name'],
        'peers': !exists(json, 'peers') ? undefined : (json['peers'].map(HashicorpCloudGlobalNetworkManager20220215ClusterPartitionPeerFromJSON)),
        'licensing': !exists(json, 'licensing') ? undefined : HashicorpCloudGlobalNetworkManager20220215LicensingFromJSON(json['licensing']),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterPartitionPeersToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cluster_id': value.clusterId,
        'partition_name': value.partitionName,
        'peers': value.peers === undefined ? undefined : (value.peers.map(HashicorpCloudGlobalNetworkManager20220215ClusterPartitionPeerToJSON)),
        'licensing': HashicorpCloudGlobalNetworkManager20220215LicensingToJSON(value.licensing),
    };
}
