/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Contacts Service
 * API for managing Contact information.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudEmailSourceTypeFromJSON, HashicorpCloudEmailSourceTypeToJSON } from './';
export function HashicorpCloudEmailCreateContactRequestFromJSON(json) {
  return HashicorpCloudEmailCreateContactRequestFromJSONTyped(json, false);
}
export function HashicorpCloudEmailCreateContactRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    emailAddress: !exists(json, 'email_address') ? undefined : json['email_address'],
    sourceIdentifier: !exists(json, 'source_identifier') ? undefined : json['source_identifier'],
    sourceType: !exists(json, 'source_type')
      ? undefined
      : HashicorpCloudEmailSourceTypeFromJSON(json['source_type']),
  };
}
export function HashicorpCloudEmailCreateContactRequestToJSON(value) {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email_address: value.emailAddress,
    source_identifier: value.sourceIdentifier,
    source_type: HashicorpCloudEmailSourceTypeToJSON(value.sourceType),
  };
}
