/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20230613SyncStatusFromJSON(json) {
    return Secrets20230613SyncStatusFromJSONTyped(json, false);
}
export function Secrets20230613SyncStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'lastErrorCode': !exists(json, 'last_error_code') ? undefined : json['last_error_code'],
    };
}
export function Secrets20230613SyncStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': value.status,
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'last_error_code': value.lastErrorCode,
    };
}
