import { helper } from '@ember/component/helper';

type ContextType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLiteral([object, key]: [ContextType, string]): any {
  return object?.[key] ?? null;
}

export default helper(getLiteral);
