export const HVN_ROUTE_NAMES = {
  create: 'cloud.orgs.detail.projects.detail.hvns.detail.hvn-routes.create',
  list: 'cloud.orgs.detail.projects.detail.hvns.detail.hvn-routes.index',
};
export const TGW_ROUTE_NAMES = {
  configurationInstructions:
    'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.detail.configuration-instructions',
  create:
    'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.create',
  list: 'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.list',
  detail:
    'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.detail.index',
  createRoute:
    'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.detail.hvn-routes.create',
};

export const PEERING_ROUTE_NAMES = {
  configurationInstructions:
    'cloud.orgs.detail.projects.detail.hvns.detail.peerings.detail.configuration-instructions',
  create: 'cloud.orgs.detail.projects.detail.hvns.detail.peerings.create',
  list: 'cloud.orgs.detail.projects.detail.hvns.detail.peerings.list',
  detail: 'cloud.orgs.detail.projects.detail.hvns.detail.peerings.detail.index',
  createRoute:
    'cloud.orgs.detail.projects.detail.hvns.detail.peerings.detail.hvn-routes.create',
};
