/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudIamServicePrincipalFromJSON, HashicorpCloudIamServicePrincipalToJSON, } from './';
export function HashicorpCloudIamListServicePrincipalsResponseFromJSON(json) {
    return HashicorpCloudIamListServicePrincipalsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudIamListServicePrincipalsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'servicePrincipals': !exists(json, 'service_principals') ? undefined : (json['service_principals'].map(HashicorpCloudIamServicePrincipalFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudIamListServicePrincipalsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'service_principals': value.servicePrincipals === undefined ? undefined : (value.servicePrincipals.map(HashicorpCloudIamServicePrincipalToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
