import Component from '@glimmer/component';

import DefinitionListKeyComponent from './definition-list/key.ts';
import DefinitionListValueComponent from './definition-list/value.ts';

interface DefinitionListSignature {
  Args: {
    compressed?: unknown;
    dense?: unknown;
    narrow?: unknown;
    vertical?: unknown;
  };
  Blocks: {
    default: [
      {
        Key: typeof DefinitionListKeyComponent;
        Value: typeof DefinitionListValueComponent;
      },
    ];
  };
  Element: HTMLElement;
}

/**
 *
 * `DefinitionList` provides a set of components to construct key/value pairs.
 *
 *
 * ```
 * <DefinitionList as |DL|>
 *   <DL.Key>Term1</DL.Key>
 *   <DL.Value>Value1</DL.Value>
 * </DefinitionList>
 * ```
 *
 * @class DefinitionList
 *
 */

/**
 * `compressed` reduces margin to zero and grid-gap is reduced. This is intended
 *  for use in a modal.
 * @argument compressed
 * @type {boolean}
 */

/**
 * `dense` reduces the row gap.
 * @argument dense
 * @type {boolean}
 */

/**
 * `narrow` reduces the key column to be min-content width.
 * @argument narrow
 * @type {boolean}
 */

/**
 * `vertical` flag stacks the key/values.
 * @argument vertical
 * @type {boolean}
 */
export default class DefinitionListComponent extends Component<DefinitionListSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DefinitionList: typeof DefinitionListComponent;
    'definition-list': typeof DefinitionListComponent;
  }
}
