/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var TaxSettingsIdType;
(function (TaxSettingsIdType) {
    TaxSettingsIdType["UNSET"] = "UNSET";
    TaxSettingsIdType["AETRN"] = "AE_TRN";
    TaxSettingsIdType["AUABN"] = "AU_ABN";
    TaxSettingsIdType["BRCNPJ"] = "BR_CNPJ";
    TaxSettingsIdType["BRCPF"] = "BR_CPF";
    TaxSettingsIdType["CABN"] = "CA_BN";
    TaxSettingsIdType["CAQST"] = "CA_QST";
    TaxSettingsIdType["CHVAT"] = "CH_VAT";
    TaxSettingsIdType["CLTIN"] = "CL_TIN";
    TaxSettingsIdType["ESCIF"] = "ES_CIF";
    TaxSettingsIdType["EUVAT"] = "EU_VAT";
    TaxSettingsIdType["HKBR"] = "HK_BR";
    TaxSettingsIdType["IDNPWP"] = "ID_NPWP";
    TaxSettingsIdType["INGST"] = "IN_GST";
    TaxSettingsIdType["JPCN"] = "JP_CN";
    TaxSettingsIdType["KRBRN"] = "KR_BRN";
    TaxSettingsIdType["LIUID"] = "LI_UID";
    TaxSettingsIdType["MXRFC"] = "MX_RFC";
    TaxSettingsIdType["MYFRP"] = "MY_FRP";
    TaxSettingsIdType["MYITN"] = "MY_ITN";
    TaxSettingsIdType["MYSST"] = "MY_SST";
    TaxSettingsIdType["NOVAT"] = "NO_VAT";
    TaxSettingsIdType["NZGST"] = "NZ_GST";
    TaxSettingsIdType["RUINN"] = "RU_INN";
    TaxSettingsIdType["SAVAT"] = "SA_VAT";
    TaxSettingsIdType["SGGST"] = "SG_GST";
    TaxSettingsIdType["SGUEN"] = "SG_UEN";
    TaxSettingsIdType["THVAT"] = "TH_VAT";
    TaxSettingsIdType["TWVAT"] = "TW_VAT";
    TaxSettingsIdType["USEIN"] = "US_EIN";
    TaxSettingsIdType["ZAVAT"] = "ZA_VAT";
    TaxSettingsIdType["GBVAT"] = "GB_VAT";
})(TaxSettingsIdType || (TaxSettingsIdType = {}));
export function TaxSettingsIdTypeFromJSON(json) {
    return TaxSettingsIdTypeFromJSONTyped(json, false);
}
export function TaxSettingsIdTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TaxSettingsIdTypeToJSON(value) {
    return value;
}
