import Component from '@glimmer/component';
import type { Error } from '../../utils/consts/with-errors';

interface WithErrorsFormErrorSignature {
  Args: {
    error: Error;
    unclaimedFieldErrors: Array<Error>;
  };
  Element: HTMLElement;
}

export default class WithErrorsFormErrorComponent extends Component<WithErrorsFormErrorSignature> {
  /**
   * The error object from `WithErrors` parent component.
   * @argument error
   * @type {string}
   *
   */

  /**
   * An array of errors that other instances of WithErrors::FieldError have not used as their `@field` value
   * @argument unclaimedFieldErrors
   * @type {array}
   *
   */

  get shouldRenderPageError() {
    return (
      this.args.unclaimedFieldErrors?.length ||
      this.violations?.length ||
      (this.args.error && !this.args.error.details) ||
      this.args.error?.details?.length === 0
    );
  }

  // an array extracted from rolling up all error.details[].violations[]
  get violations() {
    return this.args.error?.details
      ?.flatMap((d) => d.violations)
      .filter(Boolean);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WithErrors::FormError': typeof WithErrorsFormErrorComponent;
    'with-errors/form-error': typeof WithErrorsFormErrorComponent;
  }
}
