/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * ChecksumType describe supported checksum types.
 * @export
 * @enum {string}
 */
export var HashicorpCloudVagrant20220930ChecksumType;
(function (HashicorpCloudVagrant20220930ChecksumType) {
    HashicorpCloudVagrant20220930ChecksumType["UNSET"] = "UNSET";
    HashicorpCloudVagrant20220930ChecksumType["NONE"] = "NONE";
    HashicorpCloudVagrant20220930ChecksumType["MD5"] = "MD5";
    HashicorpCloudVagrant20220930ChecksumType["SHA1"] = "SHA1";
    HashicorpCloudVagrant20220930ChecksumType["SHA256"] = "SHA256";
    HashicorpCloudVagrant20220930ChecksumType["SHA384"] = "SHA384";
    HashicorpCloudVagrant20220930ChecksumType["SHA512"] = "SHA512";
})(HashicorpCloudVagrant20220930ChecksumType || (HashicorpCloudVagrant20220930ChecksumType = {}));
export function HashicorpCloudVagrant20220930ChecksumTypeFromJSON(json) {
    return HashicorpCloudVagrant20220930ChecksumTypeFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930ChecksumTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVagrant20220930ChecksumTypeToJSON(value) {
    return value;
}
