/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125ClusterNotificationTopicFromJSON, HashicorpCloudVault20201125ClusterNotificationTopicToJSON, } from './';
export function HashicorpCloudVault20201125ClusterNotificationFromJSON(json) {
    return HashicorpCloudVault20201125ClusterNotificationFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125ClusterNotificationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'topic': !exists(json, 'topic') ? undefined : HashicorpCloudVault20201125ClusterNotificationTopicFromJSON(json['topic']),
        'metaData': !exists(json, 'meta_data') ? undefined : json['meta_data'],
    };
}
export function HashicorpCloudVault20201125ClusterNotificationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'topic': HashicorpCloudVault20201125ClusterNotificationTopicToJSON(value.topic),
        'meta_data': value.metaData,
    };
}
