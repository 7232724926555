/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudIamWorkloadIdentityProviderFromJSON, HashicorpCloudIamWorkloadIdentityProviderToJSON, } from './';
export function HashicorpCloudIamListWorkloadIdentityProviderResponseFromJSON(json) {
    return HashicorpCloudIamListWorkloadIdentityProviderResponseFromJSONTyped(json, false);
}
export function HashicorpCloudIamListWorkloadIdentityProviderResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'providers': !exists(json, 'providers') ? undefined : (json['providers'].map(HashicorpCloudIamWorkloadIdentityProviderFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudIamListWorkloadIdentityProviderResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'providers': value.providers === undefined ? undefined : (value.providers.map(HashicorpCloudIamWorkloadIdentityProviderToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
