/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudWaypointVariableType;
(function (HashicorpCloudWaypointVariableType) {
    HashicorpCloudWaypointVariableType["STRING"] = "STRING";
    HashicorpCloudWaypointVariableType["BOOL"] = "BOOL";
    HashicorpCloudWaypointVariableType["INT"] = "INT";
})(HashicorpCloudWaypointVariableType || (HashicorpCloudWaypointVariableType = {}));
export function HashicorpCloudWaypointVariableTypeFromJSON(json) {
    return HashicorpCloudWaypointVariableTypeFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointVariableTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudWaypointVariableTypeToJSON(value) {
    return value;
}
