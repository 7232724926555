/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IDP Service
 * API for managing authentication sessions and SCIM settings.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudIdpListMySessionsResponseFromJSON, } from '../models';
/**
 *
 */
export class SessionServiceApi extends runtime.BaseAPI {
    /**
     * ListMySessions returns a list of sessions for the currently logged in user.
     */
    async sessionServiceListMySessionsRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/me/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIdpListMySessionsResponseFromJSON(jsonValue));
    }
    /**
     * ListMySessions returns a list of sessions for the currently logged in user.
     */
    async sessionServiceListMySessions() {
        const response = await this.sessionServiceListMySessionsRaw();
        return await response.value();
    }
    /**
     * RevokeMySessions revokes all existing sessions for the currently logged in user.
     */
    async sessionServiceRevokeMySessionsRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/me/sessions`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * RevokeMySessions revokes all existing sessions for the currently logged in user.
     */
    async sessionServiceRevokeMySessions() {
        const response = await this.sessionServiceRevokeMySessionsRaw();
        return await response.value();
    }
    /**
     */
    async sessionServiceRevokeSessionByIDRaw(requestParameters) {
        if (requestParameters.sid === null || requestParameters.sid === undefined) {
            throw new runtime.RequiredError('sid', 'Required parameter requestParameters.sid was null or undefined when calling sessionServiceRevokeSessionByID.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/me/sessions/{sid}`.replace(`{${"sid"}}`, encodeURIComponent(String(requestParameters.sid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async sessionServiceRevokeSessionByID(sid) {
        const response = await this.sessionServiceRevokeSessionByIDRaw({ sid: sid });
        return await response.value();
    }
}
