/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202ChannelAssignmentIterationFromJSON, HashicorpCloudPacker20221202ChannelAssignmentIterationToJSON, } from './';
export function HashicorpCloudPacker20221202ChannelAssignmentFromJSON(json) {
    return HashicorpCloudPacker20221202ChannelAssignmentFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ChannelAssignmentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'iteration': !exists(json, 'iteration') ? undefined : HashicorpCloudPacker20221202ChannelAssignmentIterationFromJSON(json['iteration']),
        'authorId': !exists(json, 'author_id') ? undefined : json['author_id'],
        'promotedAt': !exists(json, 'promoted_at') ? undefined : (new Date(json['promoted_at'])),
    };
}
export function HashicorpCloudPacker20221202ChannelAssignmentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'iteration': HashicorpCloudPacker20221202ChannelAssignmentIterationToJSON(value.iteration),
        'author_id': value.authorId,
        'promoted_at': value.promotedAt === undefined ? undefined : (value.promotedAt.toISOString()),
    };
}
