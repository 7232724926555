/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - KIND_UNSPECIFIED: The default, unspecified service kind. Kind unknown.
 *  - KIND_TYPICAL: A typical, classic Consul service.
 *  - KIND_CONNECT_PROXY: A Connect proxy instance.
 *  - KIND_MESH_GATEWAY: A mesh gateway service instance.
 *  - KIND_TERMINATING_GATEWAY: A terminating gateway service instance.
 *  - KIND_INGRESS_GATEWAY: An ingress gateway service instance.
 *  - KIND_DESTINATION: A Destination  for the Connect feature.
 *  - KIND_API_GATEWAY: An API gateway service instance
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKind;
(function (HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKind) {
    HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKind["UNSPECIFIED"] = "KIND_UNSPECIFIED";
    HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKind["TYPICAL"] = "KIND_TYPICAL";
    HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKind["CONNECTPROXY"] = "KIND_CONNECT_PROXY";
    HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKind["MESHGATEWAY"] = "KIND_MESH_GATEWAY";
    HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKind["TERMINATINGGATEWAY"] = "KIND_TERMINATING_GATEWAY";
    HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKind["INGRESSGATEWAY"] = "KIND_INGRESS_GATEWAY";
    HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKind["DESTINATION"] = "KIND_DESTINATION";
    HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKind["APIGATEWAY"] = "KIND_API_GATEWAY";
})(HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKind || (HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKind = {}));
export function HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKindFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKindFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKindFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215ServiceSummaryKindToJSON(value) {
    return value;
}
