import BaseAbility from './base-ability';
import { inject as service } from '@ember/service';
import * as Sentry from '@sentry/ember';
import { TYPE_CONSUL } from 'common/utils/cloud-resource-types';
import {
  PREFIX_CONSUL_CLUSTERS,
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_UPDATE,
  ACTION_CREATE_ROOT_TOKEN,
  ACTION_GET_CLIENT_CONFIG,
  ACTION_LIST_VERSIONS,
} from '../utils/permission-types/index';
import { variation } from 'ember-launch-darkly';

export default class extends BaseAbility {
  @service abilities;

  @service quota;
  @service userContext;

  prefix = PREFIX_CONSUL_CLUSTERS;

  /** @return {number} */
  calculateTrialQuota() {
    let fallback = this.quota.constructor.DEFAULT_LIMITS[TYPE_CONSUL];
    if (!variation('hcp-ui-consul-federation')) {
      return fallback;
    }

    let trialQuota = variation('hcp-billing-trial-cluster-quota');

    if (!trialQuota) {
      let missingTrialQuotaError = new Error(
        `Could not retrieve the Consul trial cluster limit from expected 'hcp-billing-trial-cluster-quota' feature flag.`,
      );
      Sentry.captureException(missingTrialQuotaError);

      return fallback;
    }

    return trialQuota;
  }

  /** @return {number} */
  calculateQuota() {
    let useTrialQuota =
      this.userContext.billing?.status?.isTrial &&
      variation('hcp-billing-trial-quota-opt-out') !== 'OPT_OUT';

    return useTrialQuota
      ? this.calculateTrialQuota()
      : this.quota.for(TYPE_CONSUL);
  }

  /**
   * Determine if create is available based on permissions, an array of
   * clusters, an array of networks, a quota, and billing status.
   *
   * Requires a list of clusters to be passed as the model.
   * @return {boolean}
   */
  get canCreate() {
    return (
      this.abilities.can('bill billing-account') &&
      this.permissions.has(this.generate(ACTION_CREATE)) &&
      this.model.length < this.calculateQuota() &&
      this.quota.for(TYPE_CONSUL) !== 0
    );
  }

  /**
   * Determine if delete is available based on permissions and the cluster's state.
   *
   * Requires a single cluster to be passed as the model.
   * @return {boolean}
   */
  get canDelete() {
    return (
      this.permissions.has(this.generate(ACTION_DELETE)) &&
      this.model.state !== 'RESTORING'
    );
  }

  /**
   * Determine if update is available based on permissions and the cluster's state.
   *
   * Requires a single cluster to be passed as the model.
   * @return {boolean}
   */
  get canUpdate() {
    return (
      this.permissions.has(this.generate(ACTION_UPDATE)) &&
      this.model.state === 'RUNNING'
    );
  }

  /**
   * Determine if user has sufficient permissions to create a cluster
   *
   * Does not require a model.
   * @return {boolean}
   */
  get canCreatePermissions() {
    return this.permissions.has(this.generate(ACTION_CREATE));
  }

  /**
   * Determine if cluster quota has been met
   *
   * Requires a list of clusters to be passed as the model.
   * @return {boolean}
   */
  get canCreateQuotaReached() {
    return (
      this.model &&
      this.model.length === this.calculateQuota() &&
      this.calculateQuota() !== 0
    );
  }

  /**
   * Determine if create root token is available based on permissions and
   * cluster state.
   *
   * Requires a single cluster to be passed as the model.
   * @return {boolean}
   */
  get canCreateRootToken() {
    return (
      this.permissions.has(this.generate(ACTION_CREATE_ROOT_TOKEN)) &&
      (this.model.state == 'RUNNING' || this.model.state == 'UPDATING')
    );
  }

  /**
   * Determine if user has sufficient permissions to create a root token
   *
   * Does not require a model.
   * @return {boolean}
   */
  get canCreateRootTokenPermissions() {
    return this.permissions.has(this.generate(ACTION_CREATE_ROOT_TOKEN));
  }

  /**
   * Determine if get client config is available based on permissions and
   * cluster state.
   *
   * Requires a single cluster to be passed as the model.
   * @return {boolean}
   */
  get canGetClientConfig() {
    return (
      this.permissions.has(this.generate(ACTION_GET_CLIENT_CONFIG)) &&
      (this.model.state == 'RUNNING' || this.model.state == 'UPDATING')
    );
  }

  /**
   * Determine if user has sufficient permissions to get client config
   *
   * Does not require a model.
   * @return {boolean}
   */
  get canGetClientConfigPermissions() {
    return this.permissions.has(this.generate(ACTION_GET_CLIENT_CONFIG));
  }

  /** @return {boolean} */
  get canListVersions() {
    return this.permissions.has(this.generate(ACTION_LIST_VERSIONS));
  }
}
