export const DEFAULT_VARIANT = 'body1';
export const DEFAULT_VARIANT_MAPPING = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p',
};
export const DEFAULT_CLASS_MAPPING = {
  subtitle1: ['subtitle1'],
  subtitle2: ['subtitle2'],
  body1: ['body1'],
  body2: ['body2'],
};
export const COMPONENT_SCALE = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
export const VARIANT_SCALE = Object.keys(DEFAULT_VARIANT_MAPPING);
