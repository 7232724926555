/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125OptInState;
(function (HashicorpCloudVault20201125OptInState) {
    HashicorpCloudVault20201125OptInState["OPTINSTATEINVALID"] = "OPT_IN_STATE_INVALID";
    HashicorpCloudVault20201125OptInState["HCPVENABLED"] = "HCPV_ENABLED";
    HashicorpCloudVault20201125OptInState["HCPVDISABLED"] = "HCPV_DISABLED";
    HashicorpCloudVault20201125OptInState["CUSTOMERENABLED"] = "CUSTOMER_ENABLED";
    HashicorpCloudVault20201125OptInState["CUSTOMERDISABLED"] = "CUSTOMER_DISABLED";
})(HashicorpCloudVault20201125OptInState || (HashicorpCloudVault20201125OptInState = {}));
export function HashicorpCloudVault20201125OptInStateFromJSON(json) {
    return HashicorpCloudVault20201125OptInStateFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125OptInStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125OptInStateToJSON(value) {
    return value;
}
