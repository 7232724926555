/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudConsul20210204ClusterDNSNamesFromJSON(json) {
    return HashicorpCloudConsul20210204ClusterDNSNamesFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204ClusterDNSNamesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_public': !exists(json, 'public') ? undefined : json['public'],
        '_private': !exists(json, 'private') ? undefined : json['private'],
    };
}
export function HashicorpCloudConsul20210204ClusterDNSNamesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'public': value._public,
        'private': value._private,
    };
}
