/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVagrant20220930DirectUploadBoxResponseFromJSON(json) {
    return HashicorpCloudVagrant20220930DirectUploadBoxResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930DirectUploadBoxResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'url': !exists(json, 'url') ? undefined : json['url'],
        'callback': !exists(json, 'callback') ? undefined : json['callback'],
        'object': !exists(json, 'object') ? undefined : json['object'],
    };
}
export function HashicorpCloudVagrant20220930DirectUploadBoxResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'url': value.url,
        'callback': value.callback,
        'object': value.object,
    };
}
