import BaseAbility from './base-ability';
import { inject as service } from '@ember/service';
import { TYPE_SERVICE_PRINCIPAL } from 'common/utils/cloud-resource-types';
import {
  ACTION_CREATE,
  ACTION_GET,
  ACTION_LIST,
  PREFIX_IAM_SERVICE_PRINCIPALS,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  @service quota;

  prefix = PREFIX_IAM_SERVICE_PRINCIPALS;

  /** @return {boolean} */
  get canCreate() {
    let principals = this.model || [];
    return (
      this.permissions.has(this.generate(ACTION_CREATE)) &&
      principals.length < this.quota.for(TYPE_SERVICE_PRINCIPAL)
    );
  }

  /** @return {boolean} */
  get canCreateQuotaReached() {
    let principals = this.model || [];
    return principals.length >= this.quota.for(TYPE_SERVICE_PRINCIPAL);
  }

  /** @return {boolean} */
  get canGet() {
    return this.permissions.has(this.generate(ACTION_GET));
  }

  /** @return {boolean} */
  get canList() {
    return this.permissions.has(this.generate(ACTION_LIST));
  }
}
