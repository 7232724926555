/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatusFromJSON, HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatusToJSON, HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevelFromJSON, HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevelToJSON, HashicorpCloudVaultLink20221107LinkedClusterNodeStateFromJSON, HashicorpCloudVaultLink20221107LinkedClusterNodeStateToJSON, HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatusFromJSON, HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatusToJSON, } from './';
export function HashicorpCloudVaultLink20221107LinkedClusterNodeFromJSON(json) {
    return HashicorpCloudVaultLink20221107LinkedClusterNodeFromJSONTyped(json, false);
}
export function HashicorpCloudVaultLink20221107LinkedClusterNodeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nodeId': !exists(json, 'node_id') ? undefined : json['node_id'],
        'nodeOs': !exists(json, 'node_os') ? undefined : json['node_os'],
        'nodeOsVersion': !exists(json, 'node_os_version') ? undefined : json['node_os_version'],
        'nodeBinaryArchitecture': !exists(json, 'node_binary_architecture') ? undefined : json['node_binary_architecture'],
        'statusVersion': !exists(json, 'status_version') ? undefined : json['status_version'],
        'nodeInitialized': !exists(json, 'node_initialized') ? undefined : json['node_initialized'],
        'nodeSealed': !exists(json, 'node_sealed') ? undefined : json['node_sealed'],
        'nodeType': !exists(json, 'node_type') ? undefined : json['node_type'],
        'hasSecurityFlaws': !exists(json, 'has_security_flaws') ? undefined : json['has_security_flaws'],
        'currentVersion': !exists(json, 'current_version') ? undefined : json['current_version'],
        'recommendedVersion': !exists(json, 'recommended_version') ? undefined : json['recommended_version'],
        'status': !exists(json, 'status') ? undefined : HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatusFromJSON(json['status']),
        'alternativeVersions': !exists(json, 'alternative_versions') ? undefined : json['alternative_versions'],
        'hostname': !exists(json, 'hostname') ? undefined : json['hostname'],
        'leaderStatus': !exists(json, 'leader_status') ? undefined : HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatusFromJSON(json['leader_status']),
        'logLevel': !exists(json, 'log_level') ? undefined : HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevelFromJSON(json['log_level']),
        'storageType': !exists(json, 'storage_type') ? undefined : json['storage_type'],
        'nodeState': !exists(json, 'node_state') ? undefined : HashicorpCloudVaultLink20221107LinkedClusterNodeStateFromJSON(json['node_state']),
        'internalId': !exists(json, 'internal_id') ? undefined : json['internal_id'],
        'linkedClusterInternalId': !exists(json, 'linked_cluster_internal_id') ? undefined : json['linked_cluster_internal_id'],
        'lastReportedAt': !exists(json, 'last_reported_at') ? undefined : (new Date(json['last_reported_at'])),
        'currentReport': !exists(json, 'current_report') ? undefined : (new Date(json['current_report'])),
        'listenerAddresses': !exists(json, 'listener_addresses') ? undefined : json['listener_addresses'],
    };
}
export function HashicorpCloudVaultLink20221107LinkedClusterNodeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'node_id': value.nodeId,
        'node_os': value.nodeOs,
        'node_os_version': value.nodeOsVersion,
        'node_binary_architecture': value.nodeBinaryArchitecture,
        'status_version': value.statusVersion,
        'node_initialized': value.nodeInitialized,
        'node_sealed': value.nodeSealed,
        'node_type': value.nodeType,
        'has_security_flaws': value.hasSecurityFlaws,
        'current_version': value.currentVersion,
        'recommended_version': value.recommendedVersion,
        'status': HashicorpCloudVaultLink20221107LinkedClusterNodeVersionStatusToJSON(value.status),
        'alternative_versions': value.alternativeVersions,
        'hostname': value.hostname,
        'leader_status': HashicorpCloudVaultLink20221107LinkedClusterNodeLeaderStatusToJSON(value.leaderStatus),
        'log_level': HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevelToJSON(value.logLevel),
        'storage_type': value.storageType,
        'node_state': HashicorpCloudVaultLink20221107LinkedClusterNodeStateToJSON(value.nodeState),
        'internal_id': value.internalId,
        'linked_cluster_internal_id': value.linkedClusterInternalId,
        'last_reported_at': value.lastReportedAt === undefined ? undefined : (value.lastReportedAt.toISOString()),
        'current_report': value.currentReport === undefined ? undefined : (value.currentReport.toISOString()),
        'listener_addresses': value.listenerAddresses,
    };
}
