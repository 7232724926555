/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202ModelsBuildFromJSON, HashicorpCloudPacker20221202ModelsBuildToJSON, HashicorpCloudPacker20221202ModelsRevocationTypeFromJSON, HashicorpCloudPacker20221202ModelsRevocationTypeToJSON, HashicorpCloudPacker20221202ModelsRevokedAncestorFromJSON, HashicorpCloudPacker20221202ModelsRevokedAncestorToJSON, HashicorpCloudPacker20221202ModelsTemplateTypeFromJSON, HashicorpCloudPacker20221202ModelsTemplateTypeToJSON, HashicorpCloudPacker20221202ModelsVersionParentsFromJSON, HashicorpCloudPacker20221202ModelsVersionParentsToJSON, HashicorpCloudPacker20221202ModelsVersionStatusFromJSON, HashicorpCloudPacker20221202ModelsVersionStatusToJSON, } from './';
export function HashicorpCloudPacker20221202ModelsVersionFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsVersionFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsVersionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'bucketName': !exists(json, 'bucket_name') ? undefined : json['bucket_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'status': !exists(json, 'status') ? undefined : HashicorpCloudPacker20221202ModelsVersionStatusFromJSON(json['status']),
        'authorId': !exists(json, 'author_id') ? undefined : json['author_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'fingerprint': !exists(json, 'fingerprint') ? undefined : json['fingerprint'],
        'builds': !exists(json, 'builds') ? undefined : (json['builds'].map(HashicorpCloudPacker20221202ModelsBuildFromJSON)),
        'revokeAt': !exists(json, 'revoke_at') ? undefined : (new Date(json['revoke_at'])),
        'revocationMessage': !exists(json, 'revocation_message') ? undefined : json['revocation_message'],
        'revocationAuthor': !exists(json, 'revocation_author') ? undefined : json['revocation_author'],
        'revocationType': !exists(json, 'revocation_type') ? undefined : HashicorpCloudPacker20221202ModelsRevocationTypeFromJSON(json['revocation_type']),
        'revocationInheritedFrom': !exists(json, 'revocation_inherited_from') ? undefined : HashicorpCloudPacker20221202ModelsRevokedAncestorFromJSON(json['revocation_inherited_from']),
        'hasDescendants': !exists(json, 'has_descendants') ? undefined : json['has_descendants'],
        'templateType': !exists(json, 'template_type') ? undefined : HashicorpCloudPacker20221202ModelsTemplateTypeFromJSON(json['template_type']),
        'parents': !exists(json, 'parents') ? undefined : HashicorpCloudPacker20221202ModelsVersionParentsFromJSON(json['parents']),
    };
}
export function HashicorpCloudPacker20221202ModelsVersionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'bucket_name': value.bucketName,
        'name': value.name,
        'status': HashicorpCloudPacker20221202ModelsVersionStatusToJSON(value.status),
        'author_id': value.authorId,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'fingerprint': value.fingerprint,
        'builds': value.builds === undefined ? undefined : (value.builds.map(HashicorpCloudPacker20221202ModelsBuildToJSON)),
        'revoke_at': value.revokeAt === undefined ? undefined : (value.revokeAt.toISOString()),
        'revocation_message': value.revocationMessage,
        'revocation_author': value.revocationAuthor,
        'revocation_type': HashicorpCloudPacker20221202ModelsRevocationTypeToJSON(value.revocationType),
        'revocation_inherited_from': HashicorpCloudPacker20221202ModelsRevokedAncestorToJSON(value.revocationInheritedFrom),
        'has_descendants': value.hasDescendants,
        'template_type': HashicorpCloudPacker20221202ModelsTemplateTypeToJSON(value.templateType),
        'parents': HashicorpCloudPacker20221202ModelsVersionParentsToJSON(value.parents),
    };
}
