/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202ChannelFromJSON, HashicorpCloudPacker20221202ChannelToJSON, } from './';
export function HashicorpCloudPacker20221202GetChannelResponseFromJSON(json) {
    return HashicorpCloudPacker20221202GetChannelResponseFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202GetChannelResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'channel': !exists(json, 'channel') ? undefined : HashicorpCloudPacker20221202ChannelFromJSON(json['channel']),
    };
}
export function HashicorpCloudPacker20221202GetChannelResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'channel': HashicorpCloudPacker20221202ChannelToJSON(value.channel),
    };
}
