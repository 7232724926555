import Component from '@glimmer/component';

interface VerticalRuleSignature {
  // Args: {};
}

/**
 *
 * `VerticalRule` renders a vertical rule visual element.
 *
 *
 * ```
 * <VerticalRule />
 * ```
 *
 * @class VerticalRule
 *
 */

export default class VerticalRuleComponent extends Component<VerticalRuleSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    VerticalRule: typeof VerticalRuleComponent;
    'vertical-rule': typeof VerticalRuleComponent;
  }
}
