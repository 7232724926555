/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var CardDetailsBrand;
(function (CardDetailsBrand) {
    CardDetailsBrand["UNKNOWN"] = "UNKNOWN";
    CardDetailsBrand["AMERICANEXPRESS"] = "AMERICAN_EXPRESS";
    CardDetailsBrand["DINERSCLUB"] = "DINERS_CLUB";
    CardDetailsBrand["DISCOVER"] = "DISCOVER";
    CardDetailsBrand["JCB"] = "JCB";
    CardDetailsBrand["MASTERCARD"] = "MASTERCARD";
    CardDetailsBrand["UNIONPAY"] = "UNION_PAY";
    CardDetailsBrand["VISA"] = "VISA";
})(CardDetailsBrand || (CardDetailsBrand = {}));
export function CardDetailsBrandFromJSON(json) {
    return CardDetailsBrandFromJSONTyped(json, false);
}
export function CardDetailsBrandFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CardDetailsBrandToJSON(value) {
    return value;
}
