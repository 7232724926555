import Component from '@glimmer/component';

interface DetailSectionSignature {
  Args: {
    title: unknown;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

/**
 *
 * `DetailSection` wraps content for display on detail pages.
 *
 *
 * ```
 * <Detail::Section @title="Title">
 *   Content
 * </Detail::Section>
 * ```
 *
 * @class DetailSection
 *
 */

export default class DetailSectionComponent extends Component<DetailSectionSignature> {
  /**
   *
   * The title of the section to render as a Typography h3.
   * @argument title
   * @type string
   *
   */
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Detail::Section': typeof DetailSectionComponent;
    'detail/section': typeof DetailSectionComponent;
  }
}
