/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudGlobalNetworkManager20220215CertificateMetadataFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215CertificateMetadataFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215CertificateMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'certName': !exists(json, 'cert_name') ? undefined : json['cert_name'],
        'certSerial': !exists(json, 'cert_serial') ? undefined : json['cert_serial'],
        'certExpiry': !exists(json, 'cert_expiry') ? undefined : (new Date(json['cert_expiry'])),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215CertificateMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cert_name': value.certName,
        'cert_serial': value.certSerial,
        'cert_expiry': value.certExpiry === undefined ? undefined : (value.certExpiry.toISOString()),
    };
}
