/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudIamSAMLDetailsFromJSON(json) {
    return HashicorpCloudIamSAMLDetailsFromJSONTyped(json, false);
}
export function HashicorpCloudIamSAMLDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'signinUrl': !exists(json, 'signin_url') ? undefined : json['signin_url'],
        'certificate': !exists(json, 'certificate') ? undefined : json['certificate'],
        'signoutUrl': !exists(json, 'signout_url') ? undefined : json['signout_url'],
        'emailDomains': !exists(json, 'email_domains') ? undefined : json['email_domains'],
        'assertionConsumerUrl': !exists(json, 'assertion_consumer_url') ? undefined : json['assertion_consumer_url'],
        'entityId': !exists(json, 'entity_id') ? undefined : json['entity_id'],
    };
}
export function HashicorpCloudIamSAMLDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'signin_url': value.signinUrl,
        'certificate': value.certificate,
        'signout_url': value.signoutUrl,
        'email_domains': value.emailDomains,
        'assertion_consumer_url': value.assertionConsumerUrl,
        'entity_id': value.entityId,
    };
}
