import Component from '@glimmer/component';

interface DownloadCardSignature {
  Args: {
    title: unknown;
    url: unknown;
  };
  Blocks: {
    description: [];
  };
}

/**
 *
 * `DownloadCard` is a card with a description, a label, and a link that opens the download url in a new tab.
 *
 * ```
 * <DownloadCard
 *   @title={{'Audit Logs'}}
 *   @url={{'download-url'}}
 * />
 *   <:description>{{'December 12, 2020 to December 13, 2020'}}</:description>
 * </DownloadCard>
 * ```
 *
 * @class DownloadCard
 *
 */

export default class DownloadCardComponent extends Component<DownloadCardSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DownloadCard: typeof DownloadCardComponent;
    'download-card': typeof DownloadCardComponent;
  }
}
