/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - DEV: NOTE: enum 0 values should be invalid. Use this field with care in GORM queries.
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125Tier;
(function (HashicorpCloudVault20201125Tier) {
    HashicorpCloudVault20201125Tier["DEV"] = "DEV";
    HashicorpCloudVault20201125Tier["STANDARDSMALL"] = "STANDARD_SMALL";
    HashicorpCloudVault20201125Tier["STANDARDMEDIUM"] = "STANDARD_MEDIUM";
    HashicorpCloudVault20201125Tier["STANDARDLARGE"] = "STANDARD_LARGE";
    HashicorpCloudVault20201125Tier["STARTERSMALL"] = "STARTER_SMALL";
    HashicorpCloudVault20201125Tier["PLUSSMALL"] = "PLUS_SMALL";
    HashicorpCloudVault20201125Tier["PLUSMEDIUM"] = "PLUS_MEDIUM";
    HashicorpCloudVault20201125Tier["PLUSLARGE"] = "PLUS_LARGE";
})(HashicorpCloudVault20201125Tier || (HashicorpCloudVault20201125Tier = {}));
export function HashicorpCloudVault20201125TierFromJSON(json) {
    return HashicorpCloudVault20201125TierFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125TierFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125TierToJSON(value) {
    return value;
}
