/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ProjectOverviewResourceTypeFromJSON, ProjectOverviewResourceTypeToJSON, } from './';
export function Billing20201105ProjectOverviewFromJSON(json) {
    return Billing20201105ProjectOverviewFromJSONTyped(json, false);
}
export function Billing20201105ProjectOverviewFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'resourceTypes': !exists(json, 'resource_types') ? undefined : (json['resource_types'].map(ProjectOverviewResourceTypeFromJSON)),
        'geos': !exists(json, 'geos') ? undefined : json['geos'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}
export function Billing20201105ProjectOverviewToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'resource_types': value.resourceTypes === undefined ? undefined : (value.resourceTypes.map(ProjectOverviewResourceTypeToJSON)),
        'geos': value.geos,
        'total': value.total,
    };
}
