/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatusFromJSON, HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatusToJSON, HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgressFromJSON, HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgressToJSON, } from './';
export function HashicorpCloudVault20201125GetReplicationStatusResponseFromJSON(json) {
    return HashicorpCloudVault20201125GetReplicationStatusResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125GetReplicationStatusResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'connectionStatus': !exists(json, 'connection_status') ? undefined : HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatusFromJSON(json['connection_status']),
        'syncProgress': !exists(json, 'sync_progress') ? undefined : HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgressFromJSON(json['sync_progress']),
    };
}
export function HashicorpCloudVault20201125GetReplicationStatusResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'connection_status': HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatusToJSON(value.connectionStatus),
        'sync_progress': HashicorpCloudVault20201125GetReplicationStatusResponseSyncProgressToJSON(value.syncProgress),
    };
}
