import { BASIC_ROLE_KEY_UI_ONLY } from '../utils/parse-role-id.ts';

import type { IamParsedRole } from '../types/iam-parsed-role.ts';

const basicServices = new Set<string>([BASIC_ROLE_KEY_UI_ONLY]);

export default function iamSortServicesForSelect(
  services: Array<IamParsedRole['service']> | undefined = [],
): {
  basic: Array<IamParsedRole['service']>;
  fineGrained: Array<IamParsedRole['service']>;
} {
  const initialSort = { basic: [], fineGrained: [] };

  if (!services || !Array.isArray(services)) {
    return initialSort;
  }

  return services.reduce<{
    basic: Array<IamParsedRole['service']>;
    fineGrained: Array<IamParsedRole['service']>;
  }>((acc, service) => {
    if (service && basicServices.has(service)) {
      acc.basic.push(service);
    } else {
      acc.fineGrained.push(service);
    }
    return acc;
  }, initialSort);
}
