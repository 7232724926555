/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105UnitFromJSON, Billing20201105UnitToJSON, } from './';
export function Billing20201105InvoiceLineItemFromJSON(json) {
    return Billing20201105InvoiceLineItemFromJSONTyped(json, false);
}
export function Billing20201105InvoiceLineItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'productName': !exists(json, 'product_name') ? undefined : json['product_name'],
        'unit': !exists(json, 'unit') ? undefined : Billing20201105UnitFromJSON(json['unit']),
        'unitPrice': !exists(json, 'unit_price') ? undefined : json['unit_price'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'productKey': !exists(json, 'product_key') ? undefined : json['product_key'],
        'lastUsageRecordedAt': !exists(json, 'last_usage_recorded_at') ? undefined : (new Date(json['last_usage_recorded_at'])),
        'standaloneItem': !exists(json, 'standalone_item') ? undefined : json['standalone_item'],
        'unitDisplayKey': !exists(json, 'unit_display_key') ? undefined : json['unit_display_key'],
    };
}
export function Billing20201105InvoiceLineItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'amount': value.amount,
        'product_name': value.productName,
        'unit': Billing20201105UnitToJSON(value.unit),
        'unit_price': value.unitPrice,
        'quantity': value.quantity,
        'product_key': value.productKey,
        'last_usage_recorded_at': value.lastUsageRecordedAt === undefined ? undefined : (value.lastUsageRecordedAt.toISOString()),
        'standalone_item': value.standaloneItem,
        'unit_display_key': value.unitDisplayKey,
    };
}
