import BaseAbility from './base-ability';
import { inject as service } from '@ember/service';
import { TYPE_NETWORK_PEERING } from 'common/utils/cloud-resource-types';
import {
  PREFIX_NETWORK_PEERINGS,
  ACTION_CREATE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  @service abilities;
  @service quota;

  prefix = PREFIX_NETWORK_PEERINGS;

  /**
   * Determine if create is available based on permissions, an array of peerings,
   * and billing status.
   *
   * Requires a list of peerings to be passed as the model.
   *
   * @return {boolean}
   */
  get canCreate() {
    return (
      this.abilities.can('bill billing-account') &&
      this.permissions.has(this.generate(ACTION_CREATE)) &&
      this.model.length < this.quota.for(TYPE_NETWORK_PEERING)
    );
  }

  /**
   * Determine if user has sufficient permissions to create a peering
   *
   * Does not require a model.
   * @return {boolean}
   */
  get canCreatePermissions() {
    return this.permissions.has(this.generate(ACTION_CREATE));
  }

  /**
   * Determine if peering quota has been met
   *
   * Requires a list of peerings to be passed as the model.
   * @return {boolean}
   */
  get canCreateQuotaReached() {
    return this.model.length < this.quota.for(TYPE_NETWORK_PEERING);
  }
}
