import templateOnlyComponent from '@ember/component/template-only';

interface LayoutPageHeaderBreadcrumbsSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

const LayoutPageHeaderBreadcrumbsComponent =
  templateOnlyComponent<LayoutPageHeaderBreadcrumbsSignature>();

export default LayoutPageHeaderBreadcrumbsComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::PageHeader::Breadcrumbs': typeof LayoutPageHeaderBreadcrumbsComponent;
    'layout/page-header/breadcrumbs': typeof LayoutPageHeaderBreadcrumbsComponent;
  }
}
