/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption;
(function (HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption) {
    HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption["UNDEFINED"] = "PEERING_CONNECTIVITY_UNDEFINED";
    HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption["HVNTOHVNPEERING"] = "PEERING_CONNECTIVITY_HVN_TO_HVN_PEERING";
    HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption["MESHGATEWAY"] = "PEERING_CONNECTIVITY_MESH_GATEWAY";
    HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption["HCPPUBLIC"] = "PEERING_CONNECTIVITY_HCP_PUBLIC";
    HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption["INTRAHVN"] = "PEERING_CONNECTIVITY_INTRA_HVN";
})(HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption || (HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOption = {}));
export function HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOptionFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOptionFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOptionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215PeeringConnectivityOptionToJSON(value) {
    return value;
}
