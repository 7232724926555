/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128GcpDynamicSecretFromJSON, Secrets20231128GcpDynamicSecretToJSON, } from './';
export function Secrets20231128GetGcpDynamicSecretResponseFromJSON(json) {
    return Secrets20231128GetGcpDynamicSecretResponseFromJSONTyped(json, false);
}
export function Secrets20231128GetGcpDynamicSecretResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'secret': !exists(json, 'secret') ? undefined : Secrets20231128GcpDynamicSecretFromJSON(json['secret']),
    };
}
export function Secrets20231128GetGcpDynamicSecretResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'secret': Secrets20231128GcpDynamicSecretToJSON(value.secret),
    };
}
