/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointTFCOrganizationFromJSON(json) {
    return HashicorpCloudWaypointTFCOrganizationFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointTFCOrganizationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'externalId': !exists(json, 'external_id') ? undefined : json['external_id'],
    };
}
export function HashicorpCloudWaypointTFCOrganizationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'external_id': value.externalId,
    };
}
