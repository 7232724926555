/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudGlobalNetworkManager20220215ConsulMinorVersionFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ConsulMinorVersionFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ConsulMinorVersionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cve': !exists(json, 'cve') ? undefined : json['cve'],
        'cveEnterpriseOnly': !exists(json, 'cve_enterprise_only') ? undefined : json['cve_enterprise_only'],
        'documentation': !exists(json, 'documentation') ? undefined : json['documentation'],
        'hcpSupported': !exists(json, 'hcp_supported') ? undefined : json['hcp_supported'],
        'hcpRecommended': !exists(json, 'hcp_recommended') ? undefined : json['hcp_recommended'],
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ConsulMinorVersionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cve': value.cve,
        'cve_enterprise_only': value.cveEnterpriseOnly,
        'documentation': value.documentation,
        'hcp_supported': value.hcpSupported,
        'hcp_recommended': value.hcpRecommended,
    };
}
