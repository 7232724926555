import BaseAbility from './base-ability';
import { inject as service } from '@ember/service';
import { TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT } from 'common/utils/cloud-resource-types';
import {
  PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS,
  ACTION_CREATE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  @service abilities;
  @service quota;

  prefix = PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS;

  /**
   * Determine if create is available based on permissions, an array of transit
   * gateway attachments, and billing status.
   *
   * @return {boolean}
   */
  get canCreate() {
    return (
      this.abilities.can('bill billing-account') &&
      this.permissions.has(this.generate(ACTION_CREATE)) &&
      this.model.length <
        this.quota.for(TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT)
    );
  }
}
