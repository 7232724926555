import templateOnlyComponent from '@ember/component/template-only';

interface LocationLinkIconSignature {
  Args: {
    location: unknown;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLSpanElement;
}

const LocationLinkIconComponent =
  templateOnlyComponent<LocationLinkIconSignature>();

export default LocationLinkIconComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LocationLinkIcon: typeof LocationLinkIconComponent;
    'location-link-icon': typeof LocationLinkIconComponent;
  }
}
