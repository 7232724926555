/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIamPrincipalTypeFromJSON, HashicorpCloudIamPrincipalTypeToJSON, } from './';
export function HashicorpCloudIamSearchPrincipalsFilterFromJSON(json) {
    return HashicorpCloudIamSearchPrincipalsFilterFromJSONTyped(json, false);
}
export function HashicorpCloudIamSearchPrincipalsFilterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'searchText': !exists(json, 'search_text') ? undefined : json['search_text'],
        'principalTypes': !exists(json, 'principal_types') ? undefined : (json['principal_types'].map(HashicorpCloudIamPrincipalTypeFromJSON)),
        'excludeMembersOfGroupNames': !exists(json, 'exclude_members_of_group_names') ? undefined : json['exclude_members_of_group_names'],
    };
}
export function HashicorpCloudIamSearchPrincipalsFilterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'search_text': value.searchText,
        'principal_types': value.principalTypes === undefined ? undefined : (value.principalTypes.map(HashicorpCloudIamPrincipalTypeToJSON)),
        'exclude_members_of_group_names': value.excludeMembersOfGroupNames,
    };
}
