import type { IRoleAssignmentsConfig } from '../types/role-assignments-config';

class RoleAssignmentsConfig implements IRoleAssignmentsConfig {
  canEditPolicy: IRoleAssignmentsConfig['canEditPolicy'];
  filterRolesFunction: IRoleAssignmentsConfig['filterRolesFunction'];
  isEnabled: IRoleAssignmentsConfig['isEnabled'];
  resourceContext: IRoleAssignmentsConfig['resourceContext'];

  constructor({
    canEditPolicy,
    filterRolesFunction,
    isEnabled,
    resourceContext,
  }: IRoleAssignmentsConfig = {}) {
    this.filterRolesFunction = filterRolesFunction;
    this.canEditPolicy = canEditPolicy;
    this.isEnabled = isEnabled;
    this.resourceContext = resourceContext;
  }
}

export default RoleAssignmentsConfig;
