/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudIamAuthConnectionFromJSON(json) {
    return HashicorpCloudIamAuthConnectionFromJSONTyped(json, false);
}
export function HashicorpCloudIamAuthConnectionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'issuer': !exists(json, 'issuer') ? undefined : json['issuer'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'emailDomain': !exists(json, 'email_domain') ? undefined : json['email_domain'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'defaultRole': !exists(json, 'default_role') ? undefined : json['default_role'],
    };
}
export function HashicorpCloudIamAuthConnectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'issuer': value.issuer,
        'client_id': value.clientId,
        'email_domain': value.emailDomain,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'default_role': value.defaultRole,
    };
}
