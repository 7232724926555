/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudVagrant20220930ArchitectureFromJSON, HashicorpCloudVagrant20220930ArchitectureToJSON, } from './';
export function HashicorpCloudVagrant20220930ListArchitecturesResponseFromJSON(json) {
    return HashicorpCloudVagrant20220930ListArchitecturesResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930ListArchitecturesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'architectures': !exists(json, 'architectures') ? undefined : (json['architectures'].map(HashicorpCloudVagrant20220930ArchitectureFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudVagrant20220930ListArchitecturesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'architectures': value.architectures === undefined ? undefined : (value.architectures.map(HashicorpCloudVagrant20220930ArchitectureToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
