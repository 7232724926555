export const CHANGE_SUMMARY_TYPE_ADDED = 'ADDED';
export const CHANGE_SUMMARY_TYPE_NO_CHANGE = 'NO_CHANGE';
export const CHANGE_SUMMARY_TYPE_REMOVED = 'REMOVED';
export const CHANGE_SUMMARY_TYPE_UNKNOWN = 'UNKNOWN';

export const CHANGE_SUMMARY_MAP_COLOR = {
  [CHANGE_SUMMARY_TYPE_ADDED]: 'success',
  [CHANGE_SUMMARY_TYPE_NO_CHANGE]: 'neutral',
  [CHANGE_SUMMARY_TYPE_REMOVED]: 'critical',
  [CHANGE_SUMMARY_TYPE_UNKNOWN]: 'neutral',
};

export const CHANGE_SUMMARY_MAP_ICON = {
  [CHANGE_SUMMARY_TYPE_ADDED]: 'plus',
  [CHANGE_SUMMARY_TYPE_REMOVED]: 'minus',
};

export const CHANGE_SUMMARY_MAP_LABEL = {
  [CHANGE_SUMMARY_TYPE_ADDED]:
    'manage-access.components.table.change-summary.added',
  [CHANGE_SUMMARY_TYPE_NO_CHANGE]:
    'manage-access.components.table.change-summary.no-change',
  [CHANGE_SUMMARY_TYPE_REMOVED]:
    'manage-access.components.table.change-summary.removed',
  [CHANGE_SUMMARY_TYPE_UNKNOWN]:
    'manage-access.components.table.change-summary.unknown',
};

export default {
  CHANGE_SUMMARY_MAP_COLOR,
  CHANGE_SUMMARY_MAP_ICON,
  CHANGE_SUMMARY_MAP_LABEL,
  CHANGE_SUMMARY_TYPE_ADDED,
  CHANGE_SUMMARY_TYPE_REMOVED,
};
