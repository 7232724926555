/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevelFromJSON, HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevelToJSON, HashicorpCloudGlobalNetworkManager20220215ClusterSourceFromJSON, HashicorpCloudGlobalNetworkManager20220215ClusterSourceToJSON, LocationIsTheLocationOfTheClusterWithAnOptionalProviderAndRegionFromJSON, LocationIsTheLocationOfTheClusterWithAnOptionalProviderAndRegionToJSON, } from './';
export function InlineObjectFromJSON(json) {
    return InlineObjectFromJSONTyped(json, false);
}
export function InlineObjectFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : LocationIsTheLocationOfTheClusterWithAnOptionalProviderAndRegionFromJSON(json['location']),
        'existingCluster': !exists(json, 'existing_cluster') ? undefined : json['existing_cluster'],
        'source': !exists(json, 'source') ? undefined : HashicorpCloudGlobalNetworkManager20220215ClusterSourceFromJSON(json['source']),
        'consulAccessLevel': !exists(json, 'consul_access_level') ? undefined : HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevelFromJSON(json['consul_access_level']),
        'managementToken': !exists(json, 'management_token') ? undefined : json['management_token'],
    };
}
export function InlineObjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'location': LocationIsTheLocationOfTheClusterWithAnOptionalProviderAndRegionToJSON(value.location),
        'existing_cluster': value.existingCluster,
        'source': HashicorpCloudGlobalNetworkManager20220215ClusterSourceToJSON(value.source),
        'consul_access_level': HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevelToJSON(value.consulAccessLevel),
        'management_token': value.managementToken,
    };
}
