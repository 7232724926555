import { HashicorpCloudIamPrincipalType } from '@clients/cloud-iam';

import type { HashicorpCloudResourcemanagerPolicyBindingMember } from '@clients/cloud-resource-manager';
import type { HashicorpCloudIamPrincipal } from '@clients/cloud-iam';

import { principalTypesToMemberTypes } from '../utils/principal-types-to-member-types.ts';

export default function iamGetPrincipalMemberData(
  principal: HashicorpCloudIamPrincipal,
): HashicorpCloudResourcemanagerPolicyBindingMember | undefined {
  if (!principal) return;

  switch (principal.type) {
    case HashicorpCloudIamPrincipalType.SERVICE:
      return {
        memberId: principal.service?.id,
        memberType: principalTypesToMemberTypes[principal.type],
      };
    case HashicorpCloudIamPrincipalType.USER:
      return {
        memberId: principal.user?.id,
        memberType: principalTypesToMemberTypes[principal.type],
      };
    case HashicorpCloudIamPrincipalType.GROUP:
      return {
        memberId: principal.group?.resourceId,
        memberType: principalTypesToMemberTypes[principal.type],
      };
  }
}
