import { PREFIX, ACTIONS } from './index.ts';
import {
  RESOURCE_NETWORK_PEERING,
  RESOURCE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
} from './platform.ts';

// guided flow UI elements
const CREATE_CONNECTION_PEERING_LINK = `CREATE_CONNECTION_${RESOURCE_NETWORK_PEERING}_LINK`;
const CREATE_CONNECTION_TGW_LINK = `CREATE_CONNECTION_${RESOURCE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT}_LINK`;

// guided flow events strings
export const CREATE_CONNECTION_PEERING_LINK_CLICKED = `${PREFIX}_${CREATE_CONNECTION_PEERING_LINK}_${ACTIONS.CLICKED}`;
export const CREATE_CONNECTION_TGW_LINK_CLICKED = `${PREFIX}_${CREATE_CONNECTION_TGW_LINK}_${ACTIONS.CLICKED}`;
