/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudInternalLocationLocationFromJSON, HashicorpCloudInternalLocationLocationToJSON, } from './';
export function HashicorpCloudVault20201125FetchAuditLogRequestFromJSON(json) {
    return HashicorpCloudVault20201125FetchAuditLogRequestFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125FetchAuditLogRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : HashicorpCloudInternalLocationLocationFromJSON(json['location']),
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'intervalStart': !exists(json, 'interval_start') ? undefined : (new Date(json['interval_start'])),
        'intervalEnd': !exists(json, 'interval_end') ? undefined : (new Date(json['interval_end'])),
    };
}
export function HashicorpCloudVault20201125FetchAuditLogRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': HashicorpCloudInternalLocationLocationToJSON(value.location),
        'cluster_id': value.clusterId,
        'interval_start': value.intervalStart === undefined ? undefined : (value.intervalStart.toISOString()),
        'interval_end': value.intervalEnd === undefined ? undefined : (value.intervalEnd.toISOString()),
    };
}
