/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330ActionFromJSON, LogService20210330ActionToJSON, LogService20210330AuthenticationInfoFromJSON, LogService20210330AuthenticationInfoToJSON, LogService20210330AuthorizationInfoFromJSON, LogService20210330AuthorizationInfoToJSON, LogService20210330OperationInfoFromJSON, LogService20210330OperationInfoToJSON, LogService20210330RequestInfoFromJSON, LogService20210330RequestInfoToJSON, } from './';
export function LogService20210330ControlPlaneEventFromJSON(json) {
    return LogService20210330ControlPlaneEventFromJSONTyped(json, false);
}
export function LogService20210330ControlPlaneEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'requestInfo': !exists(json, 'request_info') ? undefined : LogService20210330RequestInfoFromJSON(json['request_info']),
        'authenticationInfo': !exists(json, 'authentication_info') ? undefined : LogService20210330AuthenticationInfoFromJSON(json['authentication_info']),
        'authorizationInfo': !exists(json, 'authorization_info') ? undefined : (json['authorization_info'].map(LogService20210330AuthorizationInfoFromJSON)),
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'operationInfo': !exists(json, 'operation_info') ? undefined : LogService20210330OperationInfoFromJSON(json['operation_info']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'action': !exists(json, 'action') ? undefined : LogService20210330ActionFromJSON(json['action']),
        'statusCode': !exists(json, 'status_code') ? undefined : json['status_code'],
    };
}
export function LogService20210330ControlPlaneEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'request_info': LogService20210330RequestInfoToJSON(value.requestInfo),
        'authentication_info': LogService20210330AuthenticationInfoToJSON(value.authenticationInfo),
        'authorization_info': value.authorizationInfo === undefined ? undefined : (value.authorizationInfo.map(LogService20210330AuthorizationInfoToJSON)),
        'metadata': value.metadata,
        'operation_info': LogService20210330OperationInfoToJSON(value.operationInfo),
        'description': value.description,
        'action': LogService20210330ActionToJSON(value.action),
        'status_code': value.statusCode,
    };
}
