import Component from '@glimmer/component';

interface DocumentSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

export default class DocumentComponent extends Component<DocumentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Document: typeof DocumentComponent;
    document: typeof DocumentComponent;
  }
}
