/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Billing20201105StripeInvoiceFromJSON(json) {
    return Billing20201105StripeInvoiceFromJSONTyped(json, false);
}
export function Billing20201105StripeInvoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hostedInvoiceUrl': !exists(json, 'hosted_invoice_url') ? undefined : json['hosted_invoice_url'],
        'invoicePdf': !exists(json, 'invoice_pdf') ? undefined : json['invoice_pdf'],
        'periodStart': !exists(json, 'period_start') ? undefined : (new Date(json['period_start'])),
        'periodEnd': !exists(json, 'period_end') ? undefined : (new Date(json['period_end'])),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}
export function Billing20201105StripeInvoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hosted_invoice_url': value.hostedInvoiceUrl,
        'invoice_pdf': value.invoicePdf,
        'period_start': value.periodStart === undefined ? undefined : (value.periodStart.toISOString()),
        'period_end': value.periodEnd === undefined ? undefined : (value.periodEnd.toISOString()),
        'total': value.total,
    };
}
