/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIamServicePrincipalFromJSON, HashicorpCloudIamServicePrincipalToJSON, HashicorpCloudIamServicePrincipalKeyFromJSON, HashicorpCloudIamServicePrincipalKeyToJSON, } from './';
export function HashicorpCloudIamGetOrganizationServicePrincipalResponseFromJSON(json) {
    return HashicorpCloudIamGetOrganizationServicePrincipalResponseFromJSONTyped(json, false);
}
export function HashicorpCloudIamGetOrganizationServicePrincipalResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'servicePrincipal': !exists(json, 'service_principal') ? undefined : HashicorpCloudIamServicePrincipalFromJSON(json['service_principal']),
        'keys': !exists(json, 'keys') ? undefined : (json['keys'].map(HashicorpCloudIamServicePrincipalKeyFromJSON)),
    };
}
export function HashicorpCloudIamGetOrganizationServicePrincipalResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'service_principal': HashicorpCloudIamServicePrincipalToJSON(value.servicePrincipal),
        'keys': value.keys === undefined ? undefined : (value.keys.map(HashicorpCloudIamServicePrincipalKeyToJSON)),
    };
}
