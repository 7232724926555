/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330DestinationFromJSON, LogService20210330DestinationToJSON, } from './';
export function LogService20210330CreateStreamingDestinationResponseFromJSON(json) {
    return LogService20210330CreateStreamingDestinationResponseFromJSONTyped(json, false);
}
export function LogService20210330CreateStreamingDestinationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'destination': !exists(json, 'destination') ? undefined : LogService20210330DestinationFromJSON(json['destination']),
    };
}
export function LogService20210330CreateStreamingDestinationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'destination': LogService20210330DestinationToJSON(value.destination),
    };
}
