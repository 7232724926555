/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointStatusLogFromJSON(json) {
    return HashicorpCloudWaypointStatusLogFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointStatusLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'emittedAt': !exists(json, 'emitted_at') ? undefined : (new Date(json['emitted_at'])),
        'log': !exists(json, 'log') ? undefined : json['log'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
    };
}
export function HashicorpCloudWaypointStatusLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'emitted_at': value.emittedAt === undefined ? undefined : (value.emittedAt.toISOString()),
        'log': value.log,
        'metadata': value.metadata,
    };
}
