import templateOnlyComponent from '@ember/component/template-only';

interface LayoutPageHeaderTitleSignature {
  Args: {
    lg: unknown;
    md: unknown;
    sm: unknown;
    xs: unknown;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const LayoutPageHeaderTitleComponent =
  templateOnlyComponent<LayoutPageHeaderTitleSignature>();

export default LayoutPageHeaderTitleComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::PageHeader::Title': typeof LayoutPageHeaderTitleComponent;
    'layout/page-header/title': typeof LayoutPageHeaderTitleComponent;
  }
}
