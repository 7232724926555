import BaseAbility from './base-ability';
import {
  ACTION_GET_CREDENTIALS,
  PREFIX_CONSUL_GNM_OBSERVABILITY,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = PREFIX_CONSUL_GNM_OBSERVABILITY;

  /**
   * Determine if get credentials is available based on permissions.
   *
   * Does not require a model.
   * @return {boolean}
   */
  get canGetCredentials() {
    return this.permissions.has(this.generate(ACTION_GET_CREDENTIALS));
  }
}
