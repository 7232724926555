import compareSizeByKeyAsc from '../utils/compare-size-by-key-asc.ts';

/**
 * Returns `true` if the provided `size` key is lower than the `compareToSize` key.
 * Example: packageSizeKeyIsLowerThan(['small'], { compareToSize: 'medium' }) === true
 */

export default function packageSizeKeyIsLowerThan(
  size = '',
  { compareToSize }: { compareToSize?: string } = {},
) {
  if (!size || !compareToSize) return false;

  const result = compareSizeByKeyAsc({ key: size }, { key: compareToSize });

  return result < 0;
}
