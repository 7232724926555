import { HashicorpCloudIamPrincipalType } from '@clients/cloud-iam';
import type { HashicorpCloudIamPrincipal } from '@clients/cloud-iam';

export default function iamGetPrincipalResourceName(
  principal: HashicorpCloudIamPrincipal,
): string | undefined {
  if (!principal) return;

  switch (principal.type) {
    case HashicorpCloudIamPrincipalType.SERVICE:
      return principal.service?.resourceName;
    case HashicorpCloudIamPrincipalType.USER:
      return;
    case HashicorpCloudIamPrincipalType.GROUP:
      return principal.group?.resourceName;
  }
}
