import BaseAbility from './base-ability';
import {
  ACTION_CREATE_TOKEN,
  ACTION_DELETE_TOKEN,
  ACTION_LIST_TOKENS,
  PREFIX_IDP_SCIM,
} from '../utils/permission-types/index';
import { variation } from 'ember-launch-darkly';

export default class extends BaseAbility {
  prefix = PREFIX_IDP_SCIM;

  /** @return {boolean} */
  get canCreate() {
    return (
      variation('hcp-identity-scim-enabled') &&
      this.permissions.has(this.generate(ACTION_CREATE_TOKEN))
    );
  }

  /** @return {boolean} */
  get canDelete() {
    return (
      variation('hcp-identity-scim-enabled') &&
      this.permissions.has(this.generate(ACTION_DELETE_TOKEN))
    );
  }

  /** @return {boolean} */
  get canList() {
    return (
      variation('hcp-identity-scim-enabled') &&
      this.permissions.has(this.generate(ACTION_LIST_TOKENS))
    );
  }
}
