import {
  HashicorpCloudNetwork20200907PeeringState,
  HashicorpCloudNetwork20200907TGWAttachmentState,
} from '@clients/cloud-network';
import SystemNotificationColors from 'common/utils/system-notification-colors';
import { DateTime } from 'luxon';
// import { RegionProviders } from 'core/utils/regions'; Will use this for TCE-1789
/**
 * Returns true if a peering is an HVN to HVN peering.
 * @param {Object} peering - A peering object.
 * @return {boolean}
 */
export const isHvnToHvnPeering = (peering) => {
  return Boolean(peering?.target?.hvnTarget?.hvn);
};

export const PEERING_ACTIVE_STATUS =
  HashicorpCloudNetwork20200907PeeringState.ACTIVE;
export const PEERING_PENDING_ACCEPTANCE_STATUS =
  HashicorpCloudNetwork20200907PeeringState.PENDINGACCEPTANCE;
export const PEERING_ACCEPTED_STATUS =
  HashicorpCloudNetwork20200907PeeringState.ACCEPTED;
export const PEERING_FAILED_STATUS =
  HashicorpCloudNetwork20200907PeeringState.FAILED;
export const PEERING_EXPIRED_STATUS =
  HashicorpCloudNetwork20200907PeeringState.EXPIRED;
export const PEERING_REJECTED_STATUS =
  HashicorpCloudNetwork20200907PeeringState.REJECTED;
export const PEERING_DELETING_STATUS =
  HashicorpCloudNetwork20200907PeeringState.DELETING;
export const PEERING_CREATING_STATUS =
  HashicorpCloudNetwork20200907PeeringState.CREATING;
export const TGW_ATTACHMENT_ACTIVE_STATUS =
  HashicorpCloudNetwork20200907TGWAttachmentState.ACTIVE;

// Source Channel string
export const AWS_QUICK_CREATE_LINK = 'aws-quick-create-link';

/**
 * Returns true if a peering is active.
 * @param {Object} peering - A peering object.
 * @return {boolean}
 */
export const isActivePeering = (peering) => {
  return peering?.state === PEERING_ACTIVE_STATUS;
};

export const isPendingAcceptance = (peering) => {
  return peering?.state === PEERING_PENDING_ACCEPTANCE_STATUS;
};

/**
 * Returns a Luxon DateTime object with a 7 day expiration date
 * for a peering's expiration date.
 */
export const getPeeringExpiration = (peering) => {
  let { createdAt } = peering;
  return DateTime.fromJSDate(new Date(createdAt)).plus({ days: 7 });
};

/**
 * Returns true if a transit gateway attachment is active.
 * @param {Object} tga - A transit gateway attachment object.
 * @return {boolean}
 */
export const isActiveTransitGatewayAttachment = (tga) => {
  return tga?.state === TGW_ATTACHMENT_ACTIVE_STATUS;
};

export const NETWORK_PEERING_STATES = Object.values(
  HashicorpCloudNetwork20200907PeeringState,
);

export const hidePeeringInstructions = (peering) => {
  let hideStates = [
    HashicorpCloudNetwork20200907PeeringState.EXPIRED,
    HashicorpCloudNetwork20200907PeeringState.FAILED,
    HashicorpCloudNetwork20200907PeeringState.REJECTED,
  ];
  return hideStates.find((hideState) =>
    peering?.state === hideState ? true : false,
  );
};

export const getUpNextTranslations = (state) => {
  return state === HashicorpCloudNetwork20200907PeeringState.PENDINGACCEPTANCE
    ? {
        header:
          'components.page.hvn-peerings.detail.peering-details.up-next.pending-acceptance.header',
        message:
          'components.page.hvn-peerings.detail.peering-details.up-next.pending-acceptance.message',
      }
    : {
        header:
          'components.page.hvn-peerings.detail.peering-details.up-next.active.header',
        message:
          'components.page.hvn-peerings.detail.peering-details.up-next.active.message',
      };
};

export const getBusyStateTranslations = () => {
  let operationStates = [
    HashicorpCloudNetwork20200907PeeringState.DELETING,
    HashicorpCloudNetwork20200907PeeringState.CREATING,
  ];

  let operationTranslations = {};

  operationStates.map((operationState) => {
    let operation = operationState.toLowerCase();

    operationTranslations[operationState] = {
      header: `components.page.hvn-peerings.detail.${operation}.header`,
      message: `components.page.hvn-peerings.detail.${operation}.message`,
    };
  });
  return operationTranslations;
};

export const getTargetType = (peering) => {
  if (peering?.target.awsTarget?.accountId) {
    return 'aws';
  }
  if (peering?.target.azureTarget?.tenantId) {
    return 'azure';
  }
  if (peering?.target.hvnTarget?.hvn) {
    return 'hvn';
  }

  return '';
};

/** ALERTS is used for Peering banners and accordions
 *  The translations pattern is:
 *  components
 *    --> networks
 *       --> peerings
 *          --> alerts
 *            --> <PROVIDER>
 *              --> <PEERING STATE>
 *                  --> banner or accordion
 *                      --> message or title
 * **/

// Putting these here for now - will be used as part of TODO: TCE-1788
// let AWS_PEERINGS_BANNER_STATES = [
//   HashicorpCloudNetwork20200907PeeringState.EXPIRED,
//   HashicorpCloudNetwork20200907PeeringState.FAILED,
//   HashicorpCloudNetwork20200907PeeringState.REJECTED,
//   HashicorpCloudNetwork20200907PeeringState.PENDINGACCEPTANCE,
//   HashicorpCloudNetwork20200907PeeringState.ACCEPTED,
//   HashicorpCloudNetwork20200907PeeringState.ACTIVE,
// ];

// let AZURE_PEERINGS_BANNER_STATES = [
//   HashicorpCloudNetwork20200907PeeringState.EXPIRED,
//   HashicorpCloudNetwork20200907PeeringState.FAILED,
//   HashicorpCloudNetwork20200907PeeringState.REJECTED,
//   HashicorpCloudNetwork20200907PeeringState.PENDINGACCEPTANCE,
//   HashicorpCloudNetwork20200907PeeringState.ACTIVE,
// ];

// let ERROR_VARIANT_STATES = [
//   HashicorpCloudNetwork20200907PeeringState.EXPIRED,
//   HashicorpCloudNetwork20200907PeeringState.FAILED,
//   HashicorpCloudNetwork20200907PeeringState.REJECTED,
// ];

// TODO:  TCE-1788 Create mapping to autogenerate PEERINGS_ALERTS
// AWS has accordions for PENDING_ACCEPTANCE, ACCEPTED, ACTIVE
// Azure has accordions for PENDING_ACCEPTANCE, ACTIVE

export const PEERINGS_ALERTS = {
  aws: {
    EXPIRED: {
      banner: {
        message: 'components.networks.peerings.alerts.expired.banner.message',
        title: 'components.networks.peerings.alerts.expired.banner.title',
        color: SystemNotificationColors.CRITICAL,
      },
    },
    FAILED: {
      banner: {
        message: 'components.networks.peerings.alerts.failed.banner.message',
        title: 'components.networks.peerings.alerts.failed.banner.title',
        color: SystemNotificationColors.CRITICAL,
      },
    },
    REJECTED: {
      banner: {
        message:
          'components.networks.peerings.alerts.aws.rejected.banner.message',
        title: 'components.networks.peerings.alerts.aws.rejected.banner.title',
        color: SystemNotificationColors.CRITICAL,
      },
    },
    PENDING_ACCEPTANCE: {
      banner: {
        message:
          'components.networks.peerings.alerts.aws.pending-acceptance.banner.message',
        title:
          'components.networks.peerings.alerts.aws.pending-acceptance.banner.title',
        color: SystemNotificationColors.WARNING,
        link: 'components.networks.peerings.alerts.aws.pending-acceptance.banner.link',
      },
      accordion: {
        title:
          'components.networks.peerings.alerts.aws.pending-acceptance.accordion.title',
        message:
          'components.networks.peerings.alerts.aws.pending-acceptance.accordion.message',
      },
    },
    ACCEPTED: {
      banner: {
        message:
          'components.networks.peerings.alerts.aws.default.banner.message',
        title: 'components.networks.peerings.alerts.aws.default.banner.title',
        color: SystemNotificationColors.WARNING,
        link: 'components.networks.peerings.alerts.aws.default.banner.link',
      },
      accordion: {
        title:
          'components.networks.peerings.alerts.aws.default.accordion.title',
        message:
          'components.networks.peerings.alerts.aws.default.accordion.message',
      },
    },
    ACTIVE: {
      banner: {
        message:
          'components.networks.peerings.alerts.aws.default.banner.message',
        title: 'components.networks.peerings.alerts.aws.default.banner.title',
        color: SystemNotificationColors.WARNING,
        link: 'components.networks.peerings.alerts.aws.default.banner.link',
      },
      accordion: {
        title:
          'components.networks.peerings.alerts.aws.default.accordion.title',
        message:
          'components.networks.peerings.alerts.aws.default.accordion.message',
      },
    },
  },
  azure: {
    PENDING_ACCEPTANCE: {
      banner: {
        message:
          'components.networks.peerings.alerts.azure.pending-acceptance.banner.message',
        title:
          'components.networks.peerings.alerts.azure.pending-acceptance.banner.title',
        color: SystemNotificationColors.WARNING,
        link: 'components.networks.peerings.alerts.azure.pending-acceptance.banner.link',
      },
      accordion: {
        title:
          'components.networks.peerings.alerts.azure.pending-acceptance.accordion.title',
        message:
          'components.networks.peerings.alerts.azure.pending-acceptance.accordion.message',
      },
    },
    ACTIVE: {
      banner: {
        message:
          'components.networks.peerings.alerts.azure.default.banner.message',
        title: 'components.networks.peerings.alerts.azure.default.banner.title',
        color: SystemNotificationColors.WARNING,
        link: 'components.networks.peerings.alerts.azure.default.banner.link',
      },
      accordion: {
        title:
          'components.networks.peerings.alerts.azure.default.accordion.title',
        message:
          'components.networks.peerings.alerts.azure.default.accordion.message',
      },
    },
    EXPIRED: {
      banner: {
        message: 'components.networks.peerings.alerts.expired.banner.message',
        title: 'components.networks.peerings.alerts.expired.banner.title',
        color: SystemNotificationColors.CRITICAL,
      },
    },
    FAILED: {
      banner: {
        message: 'components.networks.peerings.alerts.failed.banner.message',
        title: 'components.networks.peerings.alerts.failed.banner.title',
        color: SystemNotificationColors.CRITICAL,
      },
    },
  },
};

// Peering Configuration Steps (Mark Complete)
// Constants to match the enum in HVN scoped Metadata Service
export const AWS_SECURITY_GROUP = 'AWS_SECURITY_GROUP';
export const AWS_ROUTE_TABLE = 'AWS_ROUTE_TABLE';
export const COMPLETED_CONFIGURATION_STEPS = [
  AWS_SECURITY_GROUP,
  AWS_ROUTE_TABLE,
];

/**
 * Returns true if a peering's metadata has configuration steps marked as complete
 * @param {Object} peering - A transit gateway attachment object.
 * @return {boolean}
 */
export const isPeeringConfigurationComplete = ({ completion }) => {
  let remainingSteps = COMPLETED_CONFIGURATION_STEPS.filter(
    (step) => !completion.includes(step),
  );
  return remainingSteps.length === 0;
};
