/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVault20201125VaultAccessFromJSON(json) {
    return HashicorpCloudVault20201125VaultAccessFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125VaultAccessFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'url': !exists(json, 'url') ? undefined : json['url'],
        'rootToken': !exists(json, 'root_token') ? undefined : json['root_token'],
        'recoveryKey': !exists(json, 'recovery_key') ? undefined : json['recovery_key'],
    };
}
export function HashicorpCloudVault20201125VaultAccessToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'url': value.url,
        'root_token': value.rootToken,
        'recovery_key': value.recoveryKey,
    };
}
