/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudPacker20221202ModelsCreateVersionBodyToJSON, HashicorpCloudPacker20221202ModelsCreateVersionResponseFromJSON, HashicorpCloudPacker20221202ModelsGetVersionResponseFromJSON, HashicorpCloudPacker20221202ModelsListVersionsResponseFromJSON, HashicorpCloudPacker20221202ModelsUpdateVersionBodyToJSON, HashicorpCloudPacker20221202ModelsUpdateVersionResponseFromJSON, } from '../models';
/**
 *
 */
export class PackerVersionServiceApi extends runtime.BaseAPI {
    /**
     * Creates an empty version to be written to. This API is called at the beginning of a new Packer build and does not create individual builds for the version.
     */
    async packerVersionServiceCreateVersionRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerVersionServiceCreateVersion.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerVersionServiceCreateVersion.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerVersionServiceCreateVersion.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling packerVersionServiceCreateVersion.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/versions`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudPacker20221202ModelsCreateVersionBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsCreateVersionResponseFromJSON(jsonValue));
    }
    /**
     * Creates an empty version to be written to. This API is called at the beginning of a new Packer build and does not create individual builds for the version.
     */
    async packerVersionServiceCreateVersion(requestParameters) {
        const response = await this.packerVersionServiceCreateVersionRaw(requestParameters);
        return await response.value();
    }
    /**
     * Deletes the version and all its information, such as its builds and artifacts.
     */
    async packerVersionServiceDeleteVersionRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerVersionServiceDeleteVersion.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerVersionServiceDeleteVersion.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerVersionServiceDeleteVersion.');
        }
        if (requestParameters.fingerprint === null || requestParameters.fingerprint === undefined) {
            throw new runtime.RequiredError('fingerprint', 'Required parameter requestParameters.fingerprint was null or undefined when calling packerVersionServiceDeleteVersion.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.rollbackChannels !== undefined) {
            queryParameters['rollback_channels'] = requestParameters.rollbackChannels;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/versions/{fingerprint}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))).replace(`{${"fingerprint"}}`, encodeURIComponent(String(requestParameters.fingerprint))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Deletes the version and all its information, such as its builds and artifacts.
     */
    async packerVersionServiceDeleteVersion(requestParameters) {
        const response = await this.packerVersionServiceDeleteVersionRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieves the version using the version fingerprint.
     */
    async packerVersionServiceGetVersionRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerVersionServiceGetVersion.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerVersionServiceGetVersion.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerVersionServiceGetVersion.');
        }
        if (requestParameters.fingerprint === null || requestParameters.fingerprint === undefined) {
            throw new runtime.RequiredError('fingerprint', 'Required parameter requestParameters.fingerprint was null or undefined when calling packerVersionServiceGetVersion.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/versions/{fingerprint}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))).replace(`{${"fingerprint"}}`, encodeURIComponent(String(requestParameters.fingerprint))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsGetVersionResponseFromJSON(jsonValue));
    }
    /**
     * Retrieves the version using the version fingerprint.
     */
    async packerVersionServiceGetVersion(requestParameters) {
        const response = await this.packerVersionServiceGetVersionRaw(requestParameters);
        return await response.value();
    }
    /**
     * Lists every existing version of a bucket.
     */
    async packerVersionServiceListVersionsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerVersionServiceListVersions.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerVersionServiceListVersions.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerVersionServiceListVersions.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        if (requestParameters.sortingOrderBy) {
            queryParameters['sorting.order_by'] = requestParameters.sortingOrderBy;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/versions`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsListVersionsResponseFromJSON(jsonValue));
    }
    /**
     * Lists every existing version of a bucket.
     */
    async packerVersionServiceListVersions(requestParameters) {
        const response = await this.packerVersionServiceListVersionsRaw(requestParameters);
        return await response.value();
    }
    /**
     * This API can be used to revoke, restore, or complete a version. When a version is revoked, all its descendants will automatically get revoked unless \'skip_descendants_revocation\' is set. Descendants will get revoked asynchronously to the request. Revoked versions cannot be updated unless restored.
     */
    async packerVersionServiceUpdateVersionRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerVersionServiceUpdateVersion.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerVersionServiceUpdateVersion.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerVersionServiceUpdateVersion.');
        }
        if (requestParameters.fingerprint === null || requestParameters.fingerprint === undefined) {
            throw new runtime.RequiredError('fingerprint', 'Required parameter requestParameters.fingerprint was null or undefined when calling packerVersionServiceUpdateVersion.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling packerVersionServiceUpdateVersion.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/versions/{fingerprint}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))).replace(`{${"fingerprint"}}`, encodeURIComponent(String(requestParameters.fingerprint))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudPacker20221202ModelsUpdateVersionBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsUpdateVersionResponseFromJSON(jsonValue));
    }
    /**
     * This API can be used to revoke, restore, or complete a version. When a version is revoked, all its descendants will automatically get revoked unless \'skip_descendants_revocation\' is set. Descendants will get revoked asynchronously to the request. Revoked versions cannot be updated unless restored.
     */
    async packerVersionServiceUpdateVersion(requestParameters) {
        const response = await this.packerVersionServiceUpdateVersionRaw(requestParameters);
        return await response.value();
    }
}
