/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudConsulTelemetry20230414LVT;
(function (HashicorpCloudConsulTelemetry20230414LVT) {
    HashicorpCloudConsulTelemetry20230414LVT["UNSPECIFIED"] = "LVT_UNSPECIFIED";
    HashicorpCloudConsulTelemetry20230414LVT["SERVERIDS"] = "LVT_SERVER_IDS";
    HashicorpCloudConsulTelemetry20230414LVT["SERVERNODENAMES"] = "LVT_SERVER_NODE_NAMES";
})(HashicorpCloudConsulTelemetry20230414LVT || (HashicorpCloudConsulTelemetry20230414LVT = {}));
export function HashicorpCloudConsulTelemetry20230414LVTFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414LVTFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414LVTFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudConsulTelemetry20230414LVTToJSON(value) {
    return value;
}
