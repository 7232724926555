/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * `NullValue` is a singleton enumeration to represent the null value for the
 * `Value` type union.
 *
 * The JSON representation for `NullValue` is JSON `null`.
 *
 *  - NULL_VALUE: Null value.
 * @export
 * @enum {string}
 */
export var ProtobufNullValue;
(function (ProtobufNullValue) {
    ProtobufNullValue["NULLVALUE"] = "NULL_VALUE";
})(ProtobufNullValue || (ProtobufNullValue = {}));
export function ProtobufNullValueFromJSON(json) {
    return ProtobufNullValueFromJSONTyped(json, false);
}
export function ProtobufNullValueFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ProtobufNullValueToJSON(value) {
    return value;
}
