/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudResourcemanagerAuthorizationTestIamPermissionsRequestToJSON, HashicorpCloudResourcemanagerAuthorizationTestIamPermissionsResponseFromJSON, HashicorpCloudResourcemanagerBatchAuthorizationTestIamPermissionsRequestToJSON, HashicorpCloudResourcemanagerBatchAuthorizationTestIamPermissionsResponseFromJSON, } from '../models';
/**
 *
 */
export class AuthorizationServiceApi extends runtime.BaseAPI {
    /**
     * BatchTestIamPermissions allows for a batch of TestIamPermissions requests to be made in a single call.
     */
    async authorizationServiceBatchTestIamPermissionsRaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling authorizationServiceBatchTestIamPermissions.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/authorization/batch-test-iam-permissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudResourcemanagerBatchAuthorizationTestIamPermissionsRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerBatchAuthorizationTestIamPermissionsResponseFromJSON(jsonValue));
    }
    /**
     * BatchTestIamPermissions allows for a batch of TestIamPermissions requests to be made in a single call.
     */
    async authorizationServiceBatchTestIamPermissions(body) {
        const response = await this.authorizationServiceBatchTestIamPermissionsRaw({ body: body });
        return await response.value();
    }
    /**
     * TestIamPermissions returns a subset of the request permissions the calling principal has for the authorization context.
     */
    async authorizationServiceTestIamPermissionsRaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling authorizationServiceTestIamPermissions.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/authorization/test-iam-permissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudResourcemanagerAuthorizationTestIamPermissionsRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerAuthorizationTestIamPermissionsResponseFromJSON(jsonValue));
    }
    /**
     * TestIamPermissions returns a subset of the request permissions the calling principal has for the authorization context.
     */
    async authorizationServiceTestIamPermissions(body) {
        const response = await this.authorizationServiceTestIamPermissionsRaw({ body: body });
        return await response.value();
    }
}
