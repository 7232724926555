/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128PrincipalFromJSON, Secrets20231128PrincipalToJSON, } from './';
export function Secrets20231128SecretRotatingVersionFromJSON(json) {
    return Secrets20231128SecretRotatingVersionFromJSONTyped(json, false);
}
export function Secrets20231128SecretRotatingVersionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'version': !exists(json, 'version') ? undefined : json['version'],
        'keys': !exists(json, 'keys') ? undefined : json['keys'],
        'createdBy': !exists(json, 'created_by') ? undefined : Secrets20231128PrincipalFromJSON(json['created_by']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'revokedAt': !exists(json, 'revoked_at') ? undefined : (new Date(json['revoked_at'])),
    };
}
export function Secrets20231128SecretRotatingVersionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'version': value.version,
        'keys': value.keys,
        'created_by': Secrets20231128PrincipalToJSON(value.createdBy),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'revoked_at': value.revokedAt === undefined ? undefined : (value.revokedAt.toISOString()),
    };
}
