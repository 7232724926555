import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import Component from '@glimmer/component';

import { DropdownListItem } from '../../../utils/filter-bar/dropdown-manager.ts';

import type { HdsDropdownElement } from '../../../utils/filter-bar/index.ts';

interface Args {
  dd: HdsDropdownElement;
  listItems: DropdownListItem[];
  selectedValues: string[];
  onSubmit: (value: string[], dropdown?: HdsDropdownElement) => void;
  trackInteraction: () => void;
}
type Section = {
  title?: string;
  options: DropdownListItem[];
};

/**
 * The multiSelectDropdown component is a dropdown body for use inside an
 * Hds dropdown component, which is preconfigured to render a dropdown with checkboxes
 * to select multiple items from a list and return them to the caller in
 * an array. The component can be used within a standalone Hds dropdown component
 * or within a FilterBar component instance.
 *
 * Usage:
 *  <FilterBar::MultiSelectDropdown
 *     @name={{@dropdownName}}
 *     @dd={{dd}}
 *     @listItems={{@listItems}}
 *     @onSubmit={{@onSubmit}}
 *     @selectedValues={{@selectedValues}}
 *     @trackInteraction={{@trackInteraction}}
 *  />
 *
 * An optional tracking callback can be provided to instrument the dropdown.
 *
 * The @selectedValues parameter allows items to be pre-selected on render.
 *
 * @class MultiSelectDropdownComponent
 */

export default class MultiSelectDropdownComponent extends Component<Args> {
  @tracked selectedItems: string[] = [];
  @tracked hasChanges = false;

  get showClearButton() {
    return this.args.selectedValues.length;
  }

  @action
  instantiateSelections() {
    this.selectedItems = this.args.selectedValues;
  }

  @action
  onSelect(evt: Event) {
    const { value, checked } = evt.target as HTMLInputElement;
    checked
      ? (this.selectedItems = [...this.selectedItems, value])
      : (this.selectedItems = this.selectedItems.filter(
          (selectedItem) => selectedItem !== value,
        ));
    this.hasChanges = true;
  }

  @action
  handleSubmit() {
    const { onSubmit } = this.args;

    if (onSubmit && typeof onSubmit === 'function') {
      onSubmit(this.selectedItems, this.args.dd);
      this.args.trackInteraction?.();
      this.hasChanges = false;
    }
  }

  @action
  resetDropdown() {
    this.selectedItems = [];
    this.handleSubmit();
  }

  @action
  onKeyup(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  get formattedSections() {
    // If no titles, just return the listItems as a single section
    if (!this.args.listItems.some((item) => item.optionType === 'TITLE')) {
      return [
        {
          options: this.args.listItems,
        },
      ];
    }

    // Else create sections based on the titles
    const sections: Section[] = [];
    let section: Section = { title: '', options: [] };

    for (let i = 0; i < this.args.listItems.length; i++) {
      if (this.args.listItems[i]?.optionType === 'CHECKBOX') {
        section.options.push(this.args.listItems[i] as DropdownListItem);
      }

      // If the next item is a title or we're at the last item in the list, start a new section
      if (
        this.args.listItems[i + 1]?.optionType === 'TITLE' ||
        i === this.args.listItems.length - 1
      ) {
        sections.push({ ...section });
        section = { title: '', options: [] };
      }
      if (this.args.listItems[i]?.optionType === 'TITLE') {
        section.title = this.args.listItems[i]?.optionLabel as string;
      }
    }

    return sections;
  }
}
