/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IDP Service
 * API for managing authentication sessions and SCIM settings.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudIdpScimTokenFromJSON(json) {
    return HashicorpCloudIdpScimTokenFromJSONTyped(json, false);
}
export function HashicorpCloudIdpScimTokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tokenId': !exists(json, 'token_id') ? undefined : json['token_id'],
        'displayName': !exists(json, 'display_name') ? undefined : json['display_name'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'lastUsedAt': !exists(json, 'last_used_at') ? undefined : (new Date(json['last_used_at'])),
    };
}
export function HashicorpCloudIdpScimTokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token_id': value.tokenId,
        'display_name': value.displayName,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'last_used_at': value.lastUsedAt === undefined ? undefined : (value.lastUsedAt.toISOString()),
    };
}
