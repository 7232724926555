/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverifiedFromJSON, HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverifiedToJSON, } from './';
export function HashicorpCloudIamVerifyDomainOwnershipResponseFromJSON(json) {
    return HashicorpCloudIamVerifyDomainOwnershipResponseFromJSONTyped(json, false);
}
export function HashicorpCloudIamVerifyDomainOwnershipResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
        'reason': !exists(json, 'reason') ? undefined : HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverifiedFromJSON(json['reason']),
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
    };
}
export function HashicorpCloudIamVerifyDomainOwnershipResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'verified': value.verified,
        'reason': HashicorpCloudIamVerifyDomainOwnershipResponseReasonDomainOwnershipUnverifiedToJSON(value.reason),
        'domain': value.domain,
    };
}
