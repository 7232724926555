/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128SecretRotatingVersionFromJSON, Secrets20231128SecretRotatingVersionToJSON, Secrets20231128SecretStaticVersionFromJSON, Secrets20231128SecretStaticVersionToJSON, } from './';
export function Secrets20231128GetAppSecretVersionResponseFromJSON(json) {
    return Secrets20231128GetAppSecretVersionResponseFromJSONTyped(json, false);
}
export function Secrets20231128GetAppSecretVersionResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !exists(json, 'type') ? undefined : json['type'],
        'staticVersion': !exists(json, 'static_version') ? undefined : Secrets20231128SecretStaticVersionFromJSON(json['static_version']),
        'rotatingVersion': !exists(json, 'rotating_version') ? undefined : Secrets20231128SecretRotatingVersionFromJSON(json['rotating_version']),
    };
}
export function Secrets20231128GetAppSecretVersionResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'static_version': Secrets20231128SecretStaticVersionToJSON(value.staticVersion),
        'rotating_version': Secrets20231128SecretRotatingVersionToJSON(value.rotatingVersion),
    };
}
