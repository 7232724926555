/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudOperationOperationFromJSON, HashicorpCloudOperationOperationToJSON, } from './';
export function HashicorpCloudBoundary20211221CreateResponseFromJSON(json) {
    return HashicorpCloudBoundary20211221CreateResponseFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221CreateResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'operation': !exists(json, 'operation') ? undefined : HashicorpCloudOperationOperationFromJSON(json['operation']),
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'clusterUrl': !exists(json, 'cluster_url') ? undefined : json['cluster_url'],
    };
}
export function HashicorpCloudBoundary20211221CreateResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'operation': HashicorpCloudOperationOperationToJSON(value.operation),
        'cluster_id': value.clusterId,
        'cluster_url': value.clusterUrl,
    };
}
