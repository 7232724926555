/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20231128OpenSecretRotatingVersionFromJSON(json) {
    return Secrets20231128OpenSecretRotatingVersionFromJSONTyped(json, false);
}
export function Secrets20231128OpenSecretRotatingVersionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'version': !exists(json, 'version') ? undefined : json['version'],
        'values': !exists(json, 'values') ? undefined : json['values'],
        'createdById': !exists(json, 'created_by_id') ? undefined : json['created_by_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'revokedAt': !exists(json, 'revoked_at') ? undefined : (new Date(json['revoked_at'])),
        'keys': !exists(json, 'keys') ? undefined : json['keys'],
    };
}
export function Secrets20231128OpenSecretRotatingVersionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'version': value.version,
        'values': value.values,
        'created_by_id': value.createdById,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'revoked_at': value.revokedAt === undefined ? undefined : (value.revokedAt.toISOString()),
        'keys': value.keys,
    };
}
