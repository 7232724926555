/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CloudiamServicePrincipalFromJSON, CloudiamServicePrincipalToJSON, CloudiamUserPrincipalFromJSON, CloudiamUserPrincipalToJSON, IamGroupFromJSON, IamGroupToJSON, IamPrincipalTypeFromJSON, IamPrincipalTypeToJSON, } from './';
export function CloudiamPrincipalFromJSON(json) {
    return CloudiamPrincipalFromJSONTyped(json, false);
}
export function CloudiamPrincipalFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : IamPrincipalTypeFromJSON(json['type']),
        'user': !exists(json, 'user') ? undefined : CloudiamUserPrincipalFromJSON(json['user']),
        'service': !exists(json, 'service') ? undefined : CloudiamServicePrincipalFromJSON(json['service']),
        'group': !exists(json, 'group') ? undefined : IamGroupFromJSON(json['group']),
        'groupIds': !exists(json, 'group_ids') ? undefined : json['group_ids'],
    };
}
export function CloudiamPrincipalToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'type': IamPrincipalTypeToJSON(value.type),
        'user': CloudiamUserPrincipalToJSON(value.user),
        'service': CloudiamServicePrincipalToJSON(value.service),
        'group': IamGroupToJSON(value.group),
        'group_ids': value.groupIds,
    };
}
