/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointTerraformCloudProjectFromJSON, HashicorpCloudWaypointTerraformCloudProjectToJSON, } from './';
export function HashicorpCloudWaypointListTerraformCloudProjectsResponseFromJSON(json) {
    return HashicorpCloudWaypointListTerraformCloudProjectsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointListTerraformCloudProjectsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tfcProjects': !exists(json, 'tfc_projects') ? undefined : (json['tfc_projects'].map(HashicorpCloudWaypointTerraformCloudProjectFromJSON)),
    };
}
export function HashicorpCloudWaypointListTerraformCloudProjectsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tfc_projects': value.tfcProjects === undefined ? undefined : (value.tfcProjects.map(HashicorpCloudWaypointTerraformCloudProjectToJSON)),
    };
}
