/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVaultLink20221107LinkedClusterState;
(function (HashicorpCloudVaultLink20221107LinkedClusterState) {
    HashicorpCloudVaultLink20221107LinkedClusterState["LINKEDCLUSTERSTATEINVALID"] = "LINKED_CLUSTER_STATE_INVALID";
    HashicorpCloudVaultLink20221107LinkedClusterState["LINKING"] = "LINKING";
    HashicorpCloudVaultLink20221107LinkedClusterState["LINKED"] = "LINKED";
    HashicorpCloudVaultLink20221107LinkedClusterState["UNLINKING"] = "UNLINKING";
    HashicorpCloudVaultLink20221107LinkedClusterState["UNLINKED"] = "UNLINKED";
})(HashicorpCloudVaultLink20221107LinkedClusterState || (HashicorpCloudVaultLink20221107LinkedClusterState = {}));
export function HashicorpCloudVaultLink20221107LinkedClusterStateFromJSON(json) {
    return HashicorpCloudVaultLink20221107LinkedClusterStateFromJSONTyped(json, false);
}
export function HashicorpCloudVaultLink20221107LinkedClusterStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVaultLink20221107LinkedClusterStateToJSON(value) {
    return value;
}
