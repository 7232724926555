import BaseAbility from './base-ability';
import {
  PREFIX_LOG_STREAMING,
  ACTION_GET,
  ACTION_LIST,
  ACTION_CREATE,
  ACTION_UPDATE,
  ACTION_DELETE,
  ACTION_TEST,
} from '../utils/permission-types/index';
import { variation } from 'ember-launch-darkly';

export default class extends BaseAbility {
  prefix = PREFIX_LOG_STREAMING;

  get canList() {
    return (
      this.permissions.has(this.generate(ACTION_LIST)) &&
      variation('hcp-ui-organization-level-audit-logs-streaming')
    );
  }

  get canGet() {
    return this.permissions.has(this.generate(ACTION_GET));
  }

  get canCreate() {
    return this.permissions.has(this.generate(ACTION_CREATE));
  }

  get canEdit() {
    return this.permissions.has(this.generate(ACTION_UPDATE));
  }

  get canDelete() {
    return this.permissions.has(this.generate(ACTION_DELETE));
  }

  get canTest() {
    return this.permissions.has(this.generate(ACTION_TEST));
  }
}
