/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudNetwork20200907AzureHopTypeFromJSON, HashicorpCloudNetwork20200907AzureHopTypeToJSON, } from './';
export function HashicorpCloudNetwork20200907AzureRouteFromJSON(json) {
    return HashicorpCloudNetwork20200907AzureRouteFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907AzureRouteFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nextHopType': !exists(json, 'next_hop_type') ? undefined : HashicorpCloudNetwork20200907AzureHopTypeFromJSON(json['next_hop_type']),
        'nextHopIpAddress': !exists(json, 'next_hop_ip_address') ? undefined : json['next_hop_ip_address'],
    };
}
export function HashicorpCloudNetwork20200907AzureRouteToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'next_hop_type': HashicorpCloudNetwork20200907AzureHopTypeToJSON(value.nextHopType),
        'next_hop_ip_address': value.nextHopIpAddress,
    };
}
