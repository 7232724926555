import Component from '@glimmer/component';

interface CodeEditorSignature {
  Args: {
    codeValue: unknown;
  };
  Blocks: {
    default: [unknown];
  };
}

/**
 *
 * `CodeEditor` is a component that yields two presentational components which together provide an IDE-like UX.
 * CodeEditor::FieldEditor is where the provided code is displayed with syntax highlighting.
 * CodeEditor::Toolbar is intended to go on top of the FieldEditor and provides a 'Copy code' button as well as other optional user-provided actions.
 *
 *
 * ```
 * <CodeEditor
 *   @codeValue={{this.codeIWantToSee}}
 *   as |C|
 * >
 *  <C.Toolbar />
 *  <C.FieldEditor />
 * </CodeEditor>
 * ```
 *
 * @class CodeEditor
 *
 */
/**
 *
 * A string containing the code that should appear in the code editor.
 * @argument codeValue
 * @type {String}
 *
 */

export default class CodeEditorComponent extends Component<CodeEditorSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CodeEditor: typeof CodeEditorComponent;
    'code-editor': typeof CodeEditorComponent;
  }
}
