/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointUILoadProductBannerResponseFromJSON(json) {
    return HashicorpCloudWaypointUILoadProductBannerResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointUILoadProductBannerResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tokenExpired': !exists(json, 'token_expired') ? undefined : json['token_expired'],
        'productValid': !exists(json, 'product_valid') ? undefined : json['product_valid'],
        'tfcOrgPermissions': !exists(json, 'tfc_org_permissions') ? undefined : json['tfc_org_permissions'],
        'productHasActivated': !exists(json, 'product_has_activated') ? undefined : json['product_has_activated'],
        'hasActions': !exists(json, 'has_actions') ? undefined : json['has_actions'],
        'hasTemplatesAndAddons': !exists(json, 'has_templates_and_addons') ? undefined : json['has_templates_and_addons'],
    };
}
export function HashicorpCloudWaypointUILoadProductBannerResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token_expired': value.tokenExpired,
        'product_valid': value.productValid,
        'tfc_org_permissions': value.tfcOrgPermissions,
        'product_has_activated': value.productHasActivated,
        'has_actions': value.hasActions,
        'has_templates_and_addons': value.hasTemplatesAndAddons,
    };
}
