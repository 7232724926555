/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105BillingAccountUsageFromJSON, Billing20201105BillingAccountUsageToJSON, } from './';
export function Billing20201105GetUsageResponseFromJSON(json) {
    return Billing20201105GetUsageResponseFromJSONTyped(json, false);
}
export function Billing20201105GetUsageResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'usage': !exists(json, 'usage') ? undefined : Billing20201105BillingAccountUsageFromJSON(json['usage']),
    };
}
export function Billing20201105GetUsageResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'usage': Billing20201105BillingAccountUsageToJSON(value.usage),
    };
}
