import Component from '@glimmer/component';
import { getYoutubeEmbedLink, getYoutubeWebLink } from '../utils/video.ts';

interface VideoCardSignature {
  Args: {
    docsHref?: string;
    hideDescription?: string;
    iconType?: string;
    linkTitle: string;
    onVideoPlay?: () => void;
    videoDescription: string;
    youtubeId: string;
  };
  Element: HTMLElement;
}

/**
 *
 * `VideoCard` displays a card with full-width video content.
 *
 *
 * ```
 * <VideoCard
 *   @youtubeId={{youtubeId}}
 *   @videoDescription='Video about xyz'
 *   @linkTitle='Watch: My youtube video'
 *   @onVideoPlay={{doThisWhenVideoPlays}}
 * />
 * ```
 *
 * @class VideoCard
 *
 */

export default class VideoCardComponent extends Component<VideoCardSignature> {
  /**
   *
   * `youtubeId` is the the ID of a YouTube video
   * @argument youtubeId
   * @type {string}
   *
   */
  /**
   *
   * `videoDescription` describes the content and/or purpose of the video for accessibility.
   * @argument videoDescription
   * @type {string}
   *
   */
  /**
   *
   * `linkTitle` is the text that is shown in a hyperlink to the video.
   * @argument linkTitle
   * @type {string}
   *
   */
  /**
   *
   * `docsHref` can be specified if you want to override the default video link with a documentation link below the player.
   * @argument docsHref
   * @type {?string}
   *
   */
  /**
   *
   * `onVideoPlay` is an optional callback that gets called anytime the video is played.
   * @argument onVideoPlay
   * @type {?Function}
   *
   */
  /**
   *
   * `iconType` can be provided to override the default icon beside the video link.
   * @argument iconType
   * @type {?String}
   *
   */
  /**
   *
   * `hideDescription` can be set to `true` to hide the description area with link below the video.
   * @argument hideDescription
   * @type {?boolean}
   *
   */

  /**
   * Returns the YouTube embed link for an iframe.
   * @method VideoCard#embedLink
   * @return {string}
   */
  get embedLink() {
    const { youtubeId } = this.args;
    return getYoutubeEmbedLink(youtubeId);
  }

  /**
   * Returns the link to watch the video on YouTube directly.
   * @method VideoCard#webLink
   * @return {string}
   */
  get webLink() {
    const { youtubeId } = this.args;
    return getYoutubeWebLink(youtubeId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    VideoCard: typeof VideoCardComponent;
    'video-card': typeof VideoCardComponent;
  }
}
