import BaseAbility from './base-ability';
import {
  PREFIX_CONSUL_GNM_PEERING_CONNECTIONS,
  ACTION_CREATE,
  ACTION_DELETE,
} from '../utils/permission-types/index';

export default class extends BaseAbility {
  prefix = PREFIX_CONSUL_GNM_PEERING_CONNECTIONS;

  /**
   * Determine if cluster peering delete is available based on permissions.
   *
   * Does not require a model.
   * @return {boolean}
   */
  get canDelete() {
    return this.permissions.has(this.generate(ACTION_DELETE));
  }

  /**
   * Determine if cluster peering create is available based on permissions.
   *
   * Does not require a model.
   * @return {boolean}
   */
  get canCreate() {
    return this.permissions.has(this.generate(ACTION_CREATE));
  }
}
