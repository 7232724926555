/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVagrant20220930ProviderFromJSON, HashicorpCloudVagrant20220930ProviderToJSON, } from './';
export function HashicorpCloudVagrant20220930CreateProviderResponseFromJSON(json) {
    return HashicorpCloudVagrant20220930CreateProviderResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930CreateProviderResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'provider': !exists(json, 'provider') ? undefined : HashicorpCloudVagrant20220930ProviderFromJSON(json['provider']),
    };
}
export function HashicorpCloudVagrant20220930CreateProviderResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'provider': HashicorpCloudVagrant20220930ProviderToJSON(value.provider),
    };
}
