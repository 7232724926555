/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudConsul20210204CidrRangeFromJSON, HashicorpCloudConsul20210204CidrRangeToJSON, HashicorpCloudLocationLinkFromJSON, HashicorpCloudLocationLinkToJSON, } from './';
export function HashicorpCloudConsul20210204NetworkConfigFromJSON(json) {
    return HashicorpCloudConsul20210204NetworkConfigFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204NetworkConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'network': !exists(json, 'network') ? undefined : HashicorpCloudLocationLinkFromJSON(json['network']),
        '_private': !exists(json, 'private') ? undefined : json['private'],
        'ipAllowlist': !exists(json, 'ip_allowlist') ? undefined : (json['ip_allowlist'].map(HashicorpCloudConsul20210204CidrRangeFromJSON)),
    };
}
export function HashicorpCloudConsul20210204NetworkConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'network': HashicorpCloudLocationLinkToJSON(value.network),
        'private': value._private,
        'ip_allowlist': value.ipAllowlist === undefined ? undefined : (value.ipAllowlist.map(HashicorpCloudConsul20210204CidrRangeToJSON)),
    };
}
