/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * State is the state of the network resource. Note that this state
 * represents the abstract network itself, not necessarilly whether
 * network connectivity is currently available or not.
 * @export
 * @enum {string}
 */
export var HashicorpCloudNetwork20200907NetworkState;
(function (HashicorpCloudNetwork20200907NetworkState) {
    HashicorpCloudNetwork20200907NetworkState["UNSET"] = "UNSET";
    HashicorpCloudNetwork20200907NetworkState["CREATING"] = "CREATING";
    HashicorpCloudNetwork20200907NetworkState["STABLE"] = "STABLE";
    HashicorpCloudNetwork20200907NetworkState["FAILED"] = "FAILED";
    HashicorpCloudNetwork20200907NetworkState["DELETING"] = "DELETING";
    HashicorpCloudNetwork20200907NetworkState["DELETED"] = "DELETED";
})(HashicorpCloudNetwork20200907NetworkState || (HashicorpCloudNetwork20200907NetworkState = {}));
export function HashicorpCloudNetwork20200907NetworkStateFromJSON(json) {
    return HashicorpCloudNetwork20200907NetworkStateFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907NetworkStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudNetwork20200907NetworkStateToJSON(value) {
    return value;
}
