/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudIamConfirmMFAEnrollmentRequestToJSON, HashicorpCloudIamDisableMFARequestToJSON, HashicorpCloudIamEnableMFARequestToJSON, HashicorpCloudIamEnableMFAResponseFromJSON, HashicorpCloudIamGetMFAStatusResponseFromJSON, } from '../models';
/**
 *
 */
export class ProfileServiceApi extends runtime.BaseAPI {
    /**
     * ConfirmMFAEnrollment is used to perform the first authentication with a newly setup MFA (TOTP), which then confirms the user\'s setup and activates the new MFA enrollment.
     */
    async profileServiceConfirmMFAEnrollmentRaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling profileServiceConfirmMFAEnrollment.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/me/confirm-mfa-enrollment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudIamConfirmMFAEnrollmentRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * ConfirmMFAEnrollment is used to perform the first authentication with a newly setup MFA (TOTP), which then confirms the user\'s setup and activates the new MFA enrollment.
     */
    async profileServiceConfirmMFAEnrollment(body) {
        const response = await this.profileServiceConfirmMFAEnrollmentRaw({ body: body });
        return await response.value();
    }
    /**
     */
    async profileServiceDisableMFARaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling profileServiceDisableMFA.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/me/disable-mfa`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudIamDisableMFARequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async profileServiceDisableMFA(body) {
        const response = await this.profileServiceDisableMFARaw({ body: body });
        return await response.value();
    }
    /**
     * EnableMFA triggers the process of enabling MFA for a given user.
     */
    async profileServiceEnableMFARaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling profileServiceEnableMFA.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/me/enable-mfa`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudIamEnableMFARequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamEnableMFAResponseFromJSON(jsonValue));
    }
    /**
     * EnableMFA triggers the process of enabling MFA for a given user.
     */
    async profileServiceEnableMFA(body) {
        const response = await this.profileServiceEnableMFARaw({ body: body });
        return await response.value();
    }
    /**
     * GetMFAStatus returns the MFA status of the current user.
     */
    async profileServiceGetMFAStatusRaw() {
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/me/mfa-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetMFAStatusResponseFromJSON(jsonValue));
    }
    /**
     * GetMFAStatus returns the MFA status of the current user.
     */
    async profileServiceGetMFAStatus() {
        const response = await this.profileServiceGetMFAStatusRaw();
        return await response.value();
    }
    /**
     * ResetPassword resets a password for an email+password user.
     */
    async profileServiceResetPasswordRaw() {
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/me/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * ResetPassword resets a password for an email+password user.
     */
    async profileServiceResetPassword() {
        const response = await this.profileServiceResetPasswordRaw();
        return await response.value();
    }
    /**
     * SendVerificationEmail triggers a new verification email to be sent to calling user\'s email address
     */
    async profileServiceSendVerificationEmailRaw() {
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/me/send-verification-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * SendVerificationEmail triggers a new verification email to be sent to calling user\'s email address
     */
    async profileServiceSendVerificationEmail() {
        const response = await this.profileServiceSendVerificationEmailRaw();
        return await response.value();
    }
}
