/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudConsulTelemetry20230414RefreshConfigFromJSON, HashicorpCloudConsulTelemetry20230414RefreshConfigToJSON, HashicorpCloudConsulTelemetry20230414TelemetryConfigFromJSON, HashicorpCloudConsulTelemetry20230414TelemetryConfigToJSON, } from './';
export function HashicorpCloudConsulTelemetry20230414AgentTelemetryConfigResponseFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414AgentTelemetryConfigResponseFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414AgentTelemetryConfigResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'telemetryConfig': !exists(json, 'telemetry_config') ? undefined : HashicorpCloudConsulTelemetry20230414TelemetryConfigFromJSON(json['telemetry_config']),
        'refreshConfig': !exists(json, 'refresh_config') ? undefined : HashicorpCloudConsulTelemetry20230414RefreshConfigFromJSON(json['refresh_config']),
    };
}
export function HashicorpCloudConsulTelemetry20230414AgentTelemetryConfigResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'telemetry_config': HashicorpCloudConsulTelemetry20230414TelemetryConfigToJSON(value.telemetryConfig),
        'refresh_config': HashicorpCloudConsulTelemetry20230414RefreshConfigToJSON(value.refreshConfig),
    };
}
