/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Webhook Service
 * API for delivering and managing webhooks.
 *
 * The version of the OpenAPI document: 2023-05-31
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWebhookWebhookPayloadFromJSON(json) {
    return HashicorpCloudWebhookWebhookPayloadFromJSONTyped(json, false);
}
export function HashicorpCloudWebhookWebhookPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'resourceName': !exists(json, 'resource_name') ? undefined : json['resource_name'],
        'eventId': !exists(json, 'event_id') ? undefined : json['event_id'],
        'eventAction': !exists(json, 'event_action') ? undefined : json['event_action'],
        'eventDescription': !exists(json, 'event_description') ? undefined : json['event_description'],
        'eventSource': !exists(json, 'event_source') ? undefined : json['event_source'],
        'eventVersion': !exists(json, 'event_version') ? undefined : json['event_version'],
        'eventPayload': !exists(json, 'event_payload') ? undefined : json['event_payload'],
    };
}
export function HashicorpCloudWebhookWebhookPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'resource_id': value.resourceId,
        'resource_name': value.resourceName,
        'event_id': value.eventId,
        'event_action': value.eventAction,
        'event_description': value.eventDescription,
        'event_source': value.eventSource,
        'event_version': value.eventVersion,
        'event_payload': value.eventPayload,
    };
}
