import Controller, { type ControllerQueryParam } from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class PaginatedController extends Controller {
  queryParams: ControllerQueryParam[] = [
    'pageSize',
    'nextPageToken',
    'previousPageToken',
    'sortProperty',
    { sortDescending: { type: 'boolean' } },
    'orderBy',
    'leafNameFilter',
  ];

  @tracked pageSize?: number;
  @tracked nextPageToken?: string;
  @tracked previousPageToken?: string;
  @tracked sortProperty?: string;
  @tracked sortDescending?: boolean;
  @tracked orderBy?: string[];
  // leafNameFilter is used in the 'terraform.workspaces.list' route
  // leafNameFilter is supported by ResourceV2
  @tracked leafNameFilter?: string;

  resetPagination() {
    this.pageSize = undefined;
    this.nextPageToken = undefined;
    this.previousPageToken = undefined;
    this.leafNameFilter = undefined;
  }
}
