import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import type FlashMessageService from 'ember-cli-flash/services/flash-messages';

interface SystemNotificationsContractExpirationSignature {
  Args: {
    flash: unknown;
  };
}

export default class SystemNotificationsContractExpirationComponent extends Component<SystemNotificationsContractExpirationSignature> {
  @service declare readonly flashMessages: FlashMessageService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SystemNotifications::ContractExpiration': typeof SystemNotificationsContractExpirationComponent;
    'system-notifications/contract-expiration': typeof SystemNotificationsContractExpirationComponent;
  }
}
