/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudIamGetMFAStatusResponseMFAStatus;
(function (HashicorpCloudIamGetMFAStatusResponseMFAStatus) {
    HashicorpCloudIamGetMFAStatusResponseMFAStatus["UNSET"] = "UNSET";
    HashicorpCloudIamGetMFAStatusResponseMFAStatus["ENABLED"] = "ENABLED";
    HashicorpCloudIamGetMFAStatusResponseMFAStatus["DISABLED"] = "DISABLED";
    HashicorpCloudIamGetMFAStatusResponseMFAStatus["UNKNOWN"] = "UNKNOWN";
})(HashicorpCloudIamGetMFAStatusResponseMFAStatus || (HashicorpCloudIamGetMFAStatusResponseMFAStatus = {}));
export function HashicorpCloudIamGetMFAStatusResponseMFAStatusFromJSON(json) {
    return HashicorpCloudIamGetMFAStatusResponseMFAStatusFromJSONTyped(json, false);
}
export function HashicorpCloudIamGetMFAStatusResponseMFAStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudIamGetMFAStatusResponseMFAStatusToJSON(value) {
    return value;
}
