import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { assert } from '@ember/debug';
import templateString from 'core/utils/template-string';
import * as Sentry from '@sentry/ember';

const awsQuickCreateStackLink = templateString`https://${'awsTargetRegion'}.console.aws.amazon.com/cloudformation/home?region=${'awsTargetRegion'}#/stacks/create/review
?templateURL=${'templateURL'}
&stackName=${'stackName'}
&param_hcpApiAddr=${'hcpApiAddr'}
&param_hvnAccount=${'hvnAccount'}
&param_hvnCidrRange=${'hvnCidrRange'}
&param_hvnId=${'hvnId'}
&param_hvnOrganization=${'hvnOrganization'}
&param_hvnProject=${'hvnProject'}
&param_hvnRegion=${'hvnRegion'}
&param_hvnToken=${'hvnToken'}
`;

export default class NetworksPeeringsAwsCreateAutomateComponent extends Component {
  @service('config') appConfig;
  @service analytics;
  @service api;

  @action
  async launchQuickCreateStack() {
    try {
      this.analytics.trackEvent(
        'external_ui_hashicorp_network_peering_launch_quick_create_button_clicked',
        {
          ...this.args.network,
        },
      );

      let { network } = this.args;

      // Create a single-use token for creating peering.
      const tokenResp = this.api.network.createToken(
        network.location.organizationId,
        network.location.projectId,
        network.id,
        {},
      );

      const { token } = await tokenResp;
      if (!token) {
        throw Error(`failed to create a single-use peering token: ${token}`);
      }
      if (
        !network ||
        !network.cidrBlock ||
        !network.providerNetworkData?.awsNetworkData?.accountId ||
        !network.location?.region?.region
      ) {
        throw new Error(`missing fields in network response: ${network}`);
      }

      // Populate a link to initiate the peering. Open in a new tab.
      const quickCreateStackLink = awsQuickCreateStackLink({
        // TODO: support for cross-region peering. Requires more space than the dropdown.
        awsTargetRegion: network.location.region.region,
        // When bumping this, check that it matches the latest (or previously supported) version of hcp-templates.
        // You can check that here: https://github.com/hashicorp/hcp-templates/blob/f0187080d9febc37a47482428a2000f1ec1a7b4a/.bumpversion.cfg#L2
        // Or here: https://github.com/hashicorp/hcp-templates/tags
        templateURL:
          'https://s3.us-west-2.amazonaws.com/hcp-peering/auto-vpc-peering.0.2.11.template',
        // We're taking the end of the token is here to add a bit of noise to avoid duplicate stack names.
        // A user might peer one HVN to multiple VPCs in a single account, and we don't have other unique info
        // here to make each stack name unique.
        stackName: `hcp-peering-${network.id}-${token
          .substring(token.length - 5)
          .replace('_', '')
          .replace('-', '')
          .toLowerCase()}`,
        hcpApiAddr:
          this.appConfig?.app?.baseServiceHost || 'http://localhost:28081',
        hvnAccount: network.providerNetworkData.awsNetworkData.accountId,
        hvnCidrRange: network.cidrBlock,
        hvnId: network.id,
        hvnOrganization: network.location.organizationId,
        hvnProject: network.location.projectId,
        hvnRegion: network.location.region.region,
        hvnToken: token,
      });

      window.open(quickCreateStackLink);
    } catch (e) {
      assert(e);
      Sentry.eventFromException(e);
    }
  }

  @action goToFeedbackForm() {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_feedback_form_link_clicked',
      {
        ...this.args.network,
      },
    );
  }

  @action goToExternalAwsQuickCreateLink() {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_aws_quick_create_link_clicked',
      {
        ...this.args.network,
      },
    );
  }

  @action goToExternalAwsWorkingWithStacksLink() {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_aws_working_with_stacks_link_clicked',
      {
        ...this.args.network,
      },
    );
  }
}
