/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IDP Service
 * API for managing authentication sessions and SCIM settings.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudIdpEvalUnificationFlagResponseFromJSON, } from '../models';
/**
 *
 */
export class FlagsServiceApi extends runtime.BaseAPI {
    /**
     * Returns the status of flags used in TFC-HCP unificiation
     */
    async flagsServiceEvalUnificationFlagRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.flagName !== undefined) {
            queryParameters['flag_name'] = requestParameters.flagName;
        }
        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }
        if (requestParameters.organizationId !== undefined) {
            queryParameters['organization_id'] = requestParameters.organizationId;
        }
        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/eval-unification-flags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIdpEvalUnificationFlagResponseFromJSON(jsonValue));
    }
    /**
     * Returns the status of flags used in TFC-HCP unificiation
     */
    async flagsServiceEvalUnificationFlag(flagName, userId, organizationId, email) {
        const response = await this.flagsServiceEvalUnificationFlagRaw({ flagName: flagName, userId: userId, organizationId: organizationId, email: email });
        return await response.value();
    }
    /**
     * Returns the status of flags used in TFC-HCP unificiation
     */
    async flagsServiceEvalUnificationFlag2Raw(requestParameters) {
        if (requestParameters.flagName === null || requestParameters.flagName === undefined) {
            throw new runtime.RequiredError('flagName', 'Required parameter requestParameters.flagName was null or undefined when calling flagsServiceEvalUnificationFlag2.');
        }
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling flagsServiceEvalUnificationFlag2.');
        }
        const queryParameters = {};
        if (requestParameters.organizationId !== undefined) {
            queryParameters['organization_id'] = requestParameters.organizationId;
        }
        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/eval-unification-flags/{flag_name}/users/{user_id}`.replace(`{${"flag_name"}}`, encodeURIComponent(String(requestParameters.flagName))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIdpEvalUnificationFlagResponseFromJSON(jsonValue));
    }
    /**
     * Returns the status of flags used in TFC-HCP unificiation
     */
    async flagsServiceEvalUnificationFlag2(flagName, userId, organizationId, email) {
        const response = await this.flagsServiceEvalUnificationFlag2Raw({ flagName: flagName, userId: userId, organizationId: organizationId, email: email });
        return await response.value();
    }
    /**
     * Returns the status of flags used in TFC-HCP unificiation
     */
    async flagsServiceEvalUnificationFlag3Raw(requestParameters) {
        if (requestParameters.flagName === null || requestParameters.flagName === undefined) {
            throw new runtime.RequiredError('flagName', 'Required parameter requestParameters.flagName was null or undefined when calling flagsServiceEvalUnificationFlag3.');
        }
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling flagsServiceEvalUnificationFlag3.');
        }
        const queryParameters = {};
        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }
        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/eval-unification-flags/{flag_name}/organizations/{organization_id}`.replace(`{${"flag_name"}}`, encodeURIComponent(String(requestParameters.flagName))).replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIdpEvalUnificationFlagResponseFromJSON(jsonValue));
    }
    /**
     * Returns the status of flags used in TFC-HCP unificiation
     */
    async flagsServiceEvalUnificationFlag3(flagName, organizationId, userId, email) {
        const response = await this.flagsServiceEvalUnificationFlag3Raw({ flagName: flagName, organizationId: organizationId, userId: userId, email: email });
        return await response.value();
    }
    /**
     * Returns the status of flags used in TFC-HCP unificiation
     */
    async flagsServiceEvalUnificationFlag4Raw(requestParameters) {
        if (requestParameters.flagName === null || requestParameters.flagName === undefined) {
            throw new runtime.RequiredError('flagName', 'Required parameter requestParameters.flagName was null or undefined when calling flagsServiceEvalUnificationFlag4.');
        }
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling flagsServiceEvalUnificationFlag4.');
        }
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling flagsServiceEvalUnificationFlag4.');
        }
        const queryParameters = {};
        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/eval-unification-flags/{flag_name}/organizations/{organization_id}/users/{user_id}`.replace(`{${"flag_name"}}`, encodeURIComponent(String(requestParameters.flagName))).replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIdpEvalUnificationFlagResponseFromJSON(jsonValue));
    }
    /**
     * Returns the status of flags used in TFC-HCP unificiation
     */
    async flagsServiceEvalUnificationFlag4(flagName, organizationId, userId, email) {
        const response = await this.flagsServiceEvalUnificationFlag4Raw({ flagName: flagName, organizationId: organizationId, userId: userId, email: email });
        return await response.value();
    }
    /**
     * Returns the status of flags used in TFC-HCP unificiation
     */
    async flagsServiceEvalUnificationFlag5Raw(requestParameters) {
        if (requestParameters.flagName === null || requestParameters.flagName === undefined) {
            throw new runtime.RequiredError('flagName', 'Required parameter requestParameters.flagName was null or undefined when calling flagsServiceEvalUnificationFlag5.');
        }
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email', 'Required parameter requestParameters.email was null or undefined when calling flagsServiceEvalUnificationFlag5.');
        }
        const queryParameters = {};
        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }
        if (requestParameters.organizationId !== undefined) {
            queryParameters['organization_id'] = requestParameters.organizationId;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/idp/2022-04-06/eval-unification-flags/{flag_name}/emails/{email}`.replace(`{${"flag_name"}}`, encodeURIComponent(String(requestParameters.flagName))).replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIdpEvalUnificationFlagResponseFromJSON(jsonValue));
    }
    /**
     * Returns the status of flags used in TFC-HCP unificiation
     */
    async flagsServiceEvalUnificationFlag5(flagName, email, userId, organizationId) {
        const response = await this.flagsServiceEvalUnificationFlag5Raw({ flagName: flagName, email: email, userId: userId, organizationId: organizationId });
        return await response.value();
    }
}
