/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationRegionFromJSON, HashicorpCloudLocationRegionToJSON, } from './';
export function LocationIsTheLocationOfTheClusterWithAnOptionalProviderAndRegionFromJSON(json) {
    return LocationIsTheLocationOfTheClusterWithAnOptionalProviderAndRegionFromJSONTyped(json, false);
}
export function LocationIsTheLocationOfTheClusterWithAnOptionalProviderAndRegionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'region': !exists(json, 'region') ? undefined : HashicorpCloudLocationRegionFromJSON(json['region']),
    };
}
export function LocationIsTheLocationOfTheClusterWithAnOptionalProviderAndRegionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'region': HashicorpCloudLocationRegionToJSON(value.region),
    };
}
