import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { supportTicketPrefillHref } from '../utils/support-ticket.js';
import type UserContextService from '../services/user-context.ts';

interface HelpMenuSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
}

/**
 *
 * `HelpMenu` renders the HCP help menu using HDS drop-down.
 *  It includes a getter that can be used to generate a support ticket URL with query params that are specific to the user.
 *
 *
 * ```
 * <HelpMenu />
 * ```
 *
 * @class HelpMenu
 *
 */

export default class HelpMenuComponent extends Component<HelpMenuSignature> {
  @service declare readonly userContext: UserContextService;

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HelpMenu: typeof HelpMenuComponent;
    'help-menu': typeof HelpMenuComponent;
  }
}
