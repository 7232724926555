/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Billing20201105ContractDiscountFromJSON(json) {
    return Billing20201105ContractDiscountFromJSONTyped(json, false);
}
export function Billing20201105ContractDiscountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'percentage': !exists(json, 'percentage') ? undefined : json['percentage'],
        'productType': !exists(json, 'product_type') ? undefined : json['product_type'],
    };
}
export function Billing20201105ContractDiscountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'percentage': value.percentage,
        'product_type': value.productType,
    };
}
