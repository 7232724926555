/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudWaypointActionRunFromJSON, HashicorpCloudWaypointActionRunToJSON, } from './';
export function HashicorpCloudWaypointListActionRunsByNamespaceResponseFromJSON(json) {
    return HashicorpCloudWaypointListActionRunsByNamespaceResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointListActionRunsByNamespaceResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'actionRuns': !exists(json, 'action_runs') ? undefined : (json['action_runs'].map(HashicorpCloudWaypointActionRunFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudWaypointListActionRunsByNamespaceResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'action_runs': value.actionRuns === undefined ? undefined : (value.actionRuns.map(HashicorpCloudWaypointActionRunToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
