/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105InvoiceLineItemFromJSON, Billing20201105InvoiceLineItemToJSON, LocationLinkFromJSON, LocationLinkToJSON, } from './';
export function Billing20201105ResourceUsageFromJSON(json) {
    return Billing20201105ResourceUsageFromJSONTyped(json, false);
}
export function Billing20201105ResourceUsageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'resource': !exists(json, 'resource') ? undefined : LocationLinkFromJSON(json['resource']),
        'usageFrom': !exists(json, 'usage_from') ? undefined : (new Date(json['usage_from'])),
        'usageUntil': !exists(json, 'usage_until') ? undefined : (new Date(json['usage_until'])),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'totalHours': !exists(json, 'total_hours') ? undefined : json['total_hours'],
        'lineItems': !exists(json, 'line_items') ? undefined : (json['line_items'].map(Billing20201105InvoiceLineItemFromJSON)),
    };
}
export function Billing20201105ResourceUsageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'resource': LocationLinkToJSON(value.resource),
        'usage_from': value.usageFrom === undefined ? undefined : (value.usageFrom.toISOString()),
        'usage_until': value.usageUntil === undefined ? undefined : (value.usageUntil.toISOString()),
        'amount': value.amount,
        'total_hours': value.totalHours,
        'line_items': value.lineItems === undefined ? undefined : (value.lineItems.map(Billing20201105InvoiceLineItemToJSON)),
    };
}
