/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Webhook Service
 * API for delivering and managing webhooks.
 *
 * The version of the OpenAPI document: 2023-05-31
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudWebhookCreateWebhookRequestBodyToJSON, HashicorpCloudWebhookCreateWebhookResponseFromJSON, HashicorpCloudWebhookGetWebhookResponseFromJSON, HashicorpCloudWebhookListWebhookDeliveriesResponseFromJSON, HashicorpCloudWebhookListWebhooksResponseFromJSON, HashicorpCloudWebhookUpdateWebhookNameRequestBodyToJSON, HashicorpCloudWebhookUpdateWebhookNameResponseFromJSON, HashicorpCloudWebhookUpdateWebhookRequestBodyToJSON, HashicorpCloudWebhookUpdateWebhookResponseFromJSON, } from '../models';
/**
 *
 */
export class WebhookServiceApi extends runtime.BaseAPI {
    /**
     * Creates an enabled webhook that is optionally subscribed to the resources\' lifecycle events.
     */
    async webhookServiceCreateWebhookRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling webhookServiceCreateWebhook.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling webhookServiceCreateWebhook.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2023-05-31/webhook/{parent_resource_name}/webhooks`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudWebhookCreateWebhookRequestBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudWebhookCreateWebhookResponseFromJSON(jsonValue));
    }
    /**
     * Creates an enabled webhook that is optionally subscribed to the resources\' lifecycle events.
     */
    async webhookServiceCreateWebhook(requestParameters) {
        const response = await this.webhookServiceCreateWebhookRaw(requestParameters);
        return await response.value();
    }
    /**
     * Deletes a webhook and all of its delivery records.
     */
    async webhookServiceDeleteWebhookRaw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling webhookServiceDeleteWebhook.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2023-05-31/{resource_name}`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Deletes a webhook and all of its delivery records.
     */
    async webhookServiceDeleteWebhook(requestParameters) {
        const response = await this.webhookServiceDeleteWebhookRaw(requestParameters);
        return await response.value();
    }
    /**
     * Gets an existing webhook.
     */
    async webhookServiceGetWebhookRaw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling webhookServiceGetWebhook.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2023-05-31/{resource_name}`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudWebhookGetWebhookResponseFromJSON(jsonValue));
    }
    /**
     * Gets an existing webhook.
     */
    async webhookServiceGetWebhook(requestParameters) {
        const response = await this.webhookServiceGetWebhookRaw(requestParameters);
        return await response.value();
    }
    /**
     * Lists all the deliveries from the last 90 days for a webhook.
     */
    async webhookServiceListWebhookDeliveriesRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling webhookServiceListWebhookDeliveries.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        if (requestParameters.sortingOrderBy) {
            queryParameters['sorting.order_by'] = requestParameters.sortingOrderBy;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2023-05-31/{parent_resource_name}/deliveries`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudWebhookListWebhookDeliveriesResponseFromJSON(jsonValue));
    }
    /**
     * Lists all the deliveries from the last 90 days for a webhook.
     */
    async webhookServiceListWebhookDeliveries(requestParameters) {
        const response = await this.webhookServiceListWebhookDeliveriesRaw(requestParameters);
        return await response.value();
    }
    /**
     * Lists all existing webhooks in an HCP project.
     */
    async webhookServiceListWebhooksRaw(requestParameters) {
        if (requestParameters.parentResourceName === null || requestParameters.parentResourceName === undefined) {
            throw new runtime.RequiredError('parentResourceName', 'Required parameter requestParameters.parentResourceName was null or undefined when calling webhookServiceListWebhooks.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        if (requestParameters.sortingOrderBy) {
            queryParameters['sorting.order_by'] = requestParameters.sortingOrderBy;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2023-05-31/webhook/{parent_resource_name}/webhooks`.replace(`{${"parent_resource_name"}}`, encodeURIComponent(String(requestParameters.parentResourceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudWebhookListWebhooksResponseFromJSON(jsonValue));
    }
    /**
     * Lists all existing webhooks in an HCP project.
     */
    async webhookServiceListWebhooks(requestParameters) {
        const response = await this.webhookServiceListWebhooksRaw(requestParameters);
        return await response.value();
    }
    /**
     * Updates a webhook configuration and subscriptions and sets the status to `enabled` or `disabled`.
     */
    async webhookServiceUpdateWebhookRaw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling webhookServiceUpdateWebhook.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling webhookServiceUpdateWebhook.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2023-05-31/{resource_name}`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudWebhookUpdateWebhookRequestBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudWebhookUpdateWebhookResponseFromJSON(jsonValue));
    }
    /**
     * Updates a webhook configuration and subscriptions and sets the status to `enabled` or `disabled`.
     */
    async webhookServiceUpdateWebhook(requestParameters) {
        const response = await this.webhookServiceUpdateWebhookRaw(requestParameters);
        return await response.value();
    }
    /**
     * Updates a webhook name. Updating a webhook name changes its resource name.
     */
    async webhookServiceUpdateWebhookNameRaw(requestParameters) {
        if (requestParameters.resourceName === null || requestParameters.resourceName === undefined) {
            throw new runtime.RequiredError('resourceName', 'Required parameter requestParameters.resourceName was null or undefined when calling webhookServiceUpdateWebhookName.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling webhookServiceUpdateWebhookName.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2023-05-31/{resource_name}/name`.replace(`{${"resource_name"}}`, encodeURIComponent(String(requestParameters.resourceName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudWebhookUpdateWebhookNameRequestBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudWebhookUpdateWebhookNameResponseFromJSON(jsonValue));
    }
    /**
     * Updates a webhook name. Updating a webhook name changes its resource name.
     */
    async webhookServiceUpdateWebhookName(requestParameters) {
        const response = await this.webhookServiceUpdateWebhookNameRaw(requestParameters);
        return await response.value();
    }
}
