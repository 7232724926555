/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetUserPrincipalsByIDsInOrganizationRequestIsARequestForUsersByIDInAGivenOrganizationToJSON, HashicorpCloudIamBatchGetPrincipalsResponseFromJSON, HashicorpCloudIamCreateUserPrincipalRequestToJSON, HashicorpCloudIamGetCallerIdentityResponseFromJSON, HashicorpCloudIamGetCurrentUserPrincipalResponseFromJSON, HashicorpCloudIamGetOrganizationAuthMetadataResponseFromJSON, HashicorpCloudIamGetUserPrincipalsByIDsInOrganizationResponseFromJSON, HashicorpCloudIamListUserPrincipalsByOrganizationResponseFromJSON, HashicorpCloudIamSearchPrincipalsResponseFromJSON, HashicorpCloudIamUpdateWebConsolePreferencesRequestToJSON, HashicorpCloudIamUpdateWebConsolePreferencesResponseFromJSON, HashicorpCloudIamUserPrincipalResponseFromJSON, InlineObject7ToJSON, } from '../models';
/**
 *
 */
export class IamServiceApi extends runtime.BaseAPI {
    /**
     * BatchGetPrincipals retrieves principals in a batch.
     */
    async iamServiceBatchGetPrincipalsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling iamServiceBatchGetPrincipals.');
        }
        const queryParameters = {};
        if (requestParameters.principalIds) {
            queryParameters['principal_ids'] = requestParameters.principalIds;
        }
        if (requestParameters.view !== undefined) {
            queryParameters['view'] = requestParameters.view;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/principals`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamBatchGetPrincipalsResponseFromJSON(jsonValue));
    }
    /**
     * BatchGetPrincipals retrieves principals in a batch.
     */
    async iamServiceBatchGetPrincipals(organizationId, principalIds, view) {
        const response = await this.iamServiceBatchGetPrincipalsRaw({ organizationId: organizationId, principalIds: principalIds, view: view });
        return await response.value();
    }
    /**
     * CreateUserPrincipal creates a new UserPrincipal.
     */
    async iamServiceCreateUserPrincipalRaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling iamServiceCreateUserPrincipal.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/user-principals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudIamCreateUserPrincipalRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamUserPrincipalResponseFromJSON(jsonValue));
    }
    /**
     * CreateUserPrincipal creates a new UserPrincipal.
     */
    async iamServiceCreateUserPrincipal(body) {
        const response = await this.iamServiceCreateUserPrincipalRaw({ body: body });
        return await response.value();
    }
    /**
     * DeleteOrganizationMembership deletes a user principal\'s organization membership
     */
    async iamServiceDeleteOrganizationMembershipRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling iamServiceDeleteOrganizationMembership.');
        }
        if (requestParameters.userPrincipalId === null || requestParameters.userPrincipalId === undefined) {
            throw new runtime.RequiredError('userPrincipalId', 'Required parameter requestParameters.userPrincipalId was null or undefined when calling iamServiceDeleteOrganizationMembership.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/user-principals/{user_principal_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"user_principal_id"}}`, encodeURIComponent(String(requestParameters.userPrincipalId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteOrganizationMembership deletes a user principal\'s organization membership
     */
    async iamServiceDeleteOrganizationMembership(organizationId, userPrincipalId) {
        const response = await this.iamServiceDeleteOrganizationMembershipRaw({ organizationId: organizationId, userPrincipalId: userPrincipalId });
        return await response.value();
    }
    /**
     * GetCallerIdentity returns the identity of the current caller.
     */
    async iamServiceGetCallerIdentityRaw() {
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/caller-identity`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetCallerIdentityResponseFromJSON(jsonValue));
    }
    /**
     * GetCallerIdentity returns the identity of the current caller.
     */
    async iamServiceGetCallerIdentity() {
        const response = await this.iamServiceGetCallerIdentityRaw();
        return await response.value();
    }
    /**
     * GetCurrentUserPrincipal retrieves information about the current user principal. This endpoint it meant to be used by external clients over an HTTP API. It supports retrieving the basic user principal data (useful for any client) and, optionally, the user preferences for the HCP Web Portal JS application.
     */
    async iamServiceGetCurrentUserPrincipalRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.webPortalPreferences !== undefined) {
            queryParameters['web_portal_preferences'] = requestParameters.webPortalPreferences;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetCurrentUserPrincipalResponseFromJSON(jsonValue));
    }
    /**
     * GetCurrentUserPrincipal retrieves information about the current user principal. This endpoint it meant to be used by external clients over an HTTP API. It supports retrieving the basic user principal data (useful for any client) and, optionally, the user preferences for the HCP Web Portal JS application.
     */
    async iamServiceGetCurrentUserPrincipal(webPortalPreferences) {
        const response = await this.iamServiceGetCurrentUserPrincipalRaw({ webPortalPreferences: webPortalPreferences });
        return await response.value();
    }
    /**
     * GetOrganizationAuthMetadata returns metadata about the organization\'s configured authentication methods.
     */
    async iamServiceGetOrganizationAuthMetadataRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling iamServiceGetOrganizationAuthMetadata.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/auth-metadata`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetOrganizationAuthMetadataResponseFromJSON(jsonValue));
    }
    /**
     * GetOrganizationAuthMetadata returns metadata about the organization\'s configured authentication methods.
     */
    async iamServiceGetOrganizationAuthMetadata(organizationId) {
        const response = await this.iamServiceGetOrganizationAuthMetadataRaw({ organizationId: organizationId });
        return await response.value();
    }
    /**
     * GetUserPrincipal retrieves a user principal
     */
    async iamServiceGetUserPrincipalByIdInOrganizationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling iamServiceGetUserPrincipalByIdInOrganization.');
        }
        if (requestParameters.userPrincipalId === null || requestParameters.userPrincipalId === undefined) {
            throw new runtime.RequiredError('userPrincipalId', 'Required parameter requestParameters.userPrincipalId was null or undefined when calling iamServiceGetUserPrincipalByIdInOrganization.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/user-principals/{user_principal_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"user_principal_id"}}`, encodeURIComponent(String(requestParameters.userPrincipalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamUserPrincipalResponseFromJSON(jsonValue));
    }
    /**
     * GetUserPrincipal retrieves a user principal
     */
    async iamServiceGetUserPrincipalByIdInOrganization(organizationId, userPrincipalId) {
        const response = await this.iamServiceGetUserPrincipalByIdInOrganizationRaw({ organizationId: organizationId, userPrincipalId: userPrincipalId });
        return await response.value();
    }
    /**
     * GetUserPrincipalsByIDsInOrganization is a batch method to fetch users by ID for a given organization If some of the requested users don\'t exist or aren\'t members of the given organization then they will be omitted in response. We\'re using POST because GET has a length limitation for URL which is given that user-id is UUID would limit us to fetching up to ~48 users at a time which is less than ideal.
     */
    async iamServiceGetUserPrincipalsByIDsInOrganizationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling iamServiceGetUserPrincipalsByIDsInOrganization.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling iamServiceGetUserPrincipalsByIDsInOrganization.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/user-principals/batch-fetch`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetUserPrincipalsByIDsInOrganizationRequestIsARequestForUsersByIDInAGivenOrganizationToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetUserPrincipalsByIDsInOrganizationResponseFromJSON(jsonValue));
    }
    /**
     * GetUserPrincipalsByIDsInOrganization is a batch method to fetch users by ID for a given organization If some of the requested users don\'t exist or aren\'t members of the given organization then they will be omitted in response. We\'re using POST because GET has a length limitation for URL which is given that user-id is UUID would limit us to fetching up to ~48 users at a time which is less than ideal.
     */
    async iamServiceGetUserPrincipalsByIDsInOrganization(organizationId, body) {
        const response = await this.iamServiceGetUserPrincipalsByIDsInOrganizationRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * ListUserPrincipalsByOrganization returns a list of principals that are members of an organization.
     */
    async iamServiceListUserPrincipalsByOrganizationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling iamServiceListUserPrincipalsByOrganization.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/user-principals`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamListUserPrincipalsByOrganizationResponseFromJSON(jsonValue));
    }
    /**
     * ListUserPrincipalsByOrganization returns a list of principals that are members of an organization.
     */
    async iamServiceListUserPrincipalsByOrganization(organizationId, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.iamServiceListUserPrincipalsByOrganizationRaw({ organizationId: organizationId, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * Ping pings the healthcheck endpoint exposed for HTTP healthchecks via Datadog Synthetic Monitoring
     */
    async iamServicePingRaw() {
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/ping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Ping pings the healthcheck endpoint exposed for HTTP healthchecks via Datadog Synthetic Monitoring
     */
    async iamServicePing() {
        const response = await this.iamServicePingRaw();
        return await response.value();
    }
    /**
     * SearchPrincipals returns principal details for principals within the supplied organization. Optional filters may be specified to filter the result set.
     */
    async iamServiceSearchPrincipalsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling iamServiceSearchPrincipals.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling iamServiceSearchPrincipals.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/principals/search`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject7ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamSearchPrincipalsResponseFromJSON(jsonValue));
    }
    /**
     * SearchPrincipals returns principal details for principals within the supplied organization. Optional filters may be specified to filter the result set.
     */
    async iamServiceSearchPrincipals(organizationId, body) {
        const response = await this.iamServiceSearchPrincipalsRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * UnifyUserPrincipal triggers the unification of a user principal to TFC
     */
    async iamServiceUnifyUserPrincipalRaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling iamServiceUnifyUserPrincipal.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/user-principals/unify`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * UnifyUserPrincipal triggers the unification of a user principal to TFC
     */
    async iamServiceUnifyUserPrincipal(body) {
        const response = await this.iamServiceUnifyUserPrincipalRaw({ body: body });
        return await response.value();
    }
    /**
     * UpdateWebConsolePreferences updates a user principal\'s web portal (fka web console) preferences
     */
    async iamServiceUpdateWebConsolePreferencesRaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling iamServiceUpdateWebConsolePreferences.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/me/web-portal-preferences`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudIamUpdateWebConsolePreferencesRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamUpdateWebConsolePreferencesResponseFromJSON(jsonValue));
    }
    /**
     * UpdateWebConsolePreferences updates a user principal\'s web portal (fka web console) preferences
     */
    async iamServiceUpdateWebConsolePreferences(body) {
        const response = await this.iamServiceUpdateWebConsolePreferencesRaw({ body: body });
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var IamServiceBatchGetPrincipalsViewEnum;
(function (IamServiceBatchGetPrincipalsViewEnum) {
    IamServiceBatchGetPrincipalsViewEnum["UNSPECIFIED"] = "PRINCIPAL_VIEW_UNSPECIFIED";
    IamServiceBatchGetPrincipalsViewEnum["BASIC"] = "PRINCIPAL_VIEW_BASIC";
    IamServiceBatchGetPrincipalsViewEnum["FULL"] = "PRINCIPAL_VIEW_FULL";
})(IamServiceBatchGetPrincipalsViewEnum || (IamServiceBatchGetPrincipalsViewEnum = {}));
