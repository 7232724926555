import BaseAbility from './base-ability';
import {
  ACTION_GET_IAM_POLICY,
  ACTION_SET_IAM_POLICY,
  PREFIX_VAULT_SECRETS,
  SECRETS_APP,
} from '../utils/permission-types/index';
import { PermissionScope } from '../services/permissions';

export default class VaultSecretsAppAbility extends BaseAbility {
  prefix = `${PREFIX_VAULT_SECRETS}.${SECRETS_APP}`;

  get canList() {
    // This is intentionally set to true because the list permissions might
    // not be reflecting the actual permissions on the secrets app. We might
    // need to query the endpoint itself to see if the user has access to
    // secrets apps.
    return true;
  }

  get canGetIamPolicy() {
    const permission = this.generate(ACTION_GET_IAM_POLICY);
    const hasResourcePermissions = (this.allowedPermissions ?? []).includes(
      permission,
    );
    const hasOrganizationPermissions = this.permissions.has(
      permission,
      PermissionScope.ORGANIZATION,
    );
    return hasResourcePermissions || hasOrganizationPermissions;
  }

  get canSetIamPolicy() {
    const permission = this.generate(ACTION_SET_IAM_POLICY);
    const hasResourcePermissions = (this.allowedPermissions ?? []).includes(
      permission,
    );
    const hasOrganizationPermissions = this.permissions.has(
      permission,
      PermissionScope.ORGANIZATION,
    );
    return hasResourcePermissions || hasOrganizationPermissions;
  }
}
