/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeekFromJSON, HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeekToJSON, HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTCFromJSON, HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTCToJSON, } from './';
export function HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowFromJSON(json) {
    return HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dayOfWeek': !exists(json, 'day_of_week') ? undefined : HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeekFromJSON(json['day_of_week']),
        'timeWindowUtc': !exists(json, 'time_window_utc') ? undefined : HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTCFromJSON(json['time_window_utc']),
    };
}
export function HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'day_of_week': HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowDayOfWeekToJSON(value.dayOfWeek),
        'time_window_utc': HashicorpCloudVault20201125MajorVersionUpgradeConfigMaintenanceWindowTimeWindowUTCToJSON(value.timeWindowUtc),
    };
}
