import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';
import {
  TYPE_CONSUL,
  TYPE_VAULT,
  TYPE_VAULT_SECRETS_PROJECT,
  TYPE_VAULT_SECRETS_APP,
  TYPE_VAULT_SECRETS_SECRET,
  TYPE_VAULT_SECRETS_INTEGRATION,
  TYPE_TERRAFORM,
  TYPE_TERRAFORM_ORGANIZATION,
  TYPE_PACKER,
  TYPE_PACKER_VERSION,
  TYPE_BOUNDARY,
  TYPE_WAYPOINT,
  TYPE_WEBHOOK,
} from 'common/utils/cloud-resource-types';

const I18N_PREFIX = 'helpers.product-display';

export const CLOUD_RESOURCE_TYPE_ACCEPT_LIST = [
  TYPE_CONSUL,
  TYPE_VAULT,
  TYPE_VAULT_SECRETS_PROJECT,
  TYPE_VAULT_SECRETS_APP,
  TYPE_VAULT_SECRETS_SECRET,
  TYPE_VAULT_SECRETS_INTEGRATION,
  TYPE_TERRAFORM,
  TYPE_TERRAFORM_ORGANIZATION,
  TYPE_PACKER,
  TYPE_PACKER_VERSION,
  TYPE_BOUNDARY,
  TYPE_WAYPOINT,
  TYPE_WEBHOOK,
];

export const CLOUD_RESOURCE_TYPE_NAME_I18N_MAPPING =
  CLOUD_RESOURCE_TYPE_ACCEPT_LIST.reduce(
    (mappings, type) => ({
      ...mappings,
      [type]: `${I18N_PREFIX}.${type}.name`,
    }),
    {}
  );

export const CLOUD_RESOURCE_TYPE_ICON_I18N_MAPPING =
  CLOUD_RESOURCE_TYPE_ACCEPT_LIST.reduce(
    (mappings, type) => ({
      ...mappings,
      [type]: `${I18N_PREFIX}.${type}.icon`,
    }),
    {}
  );

export default class ProductDisplay extends Helper {
  @service intl;

  /**
   * @param {array} [args] - 0th element of args must be a non-empty string
   * @returns {string} - i18n translation of resource type friendly name
   */
  compute([cloudResourceType = ''], { displayAsIcon }) {
    if (CLOUD_RESOURCE_TYPE_ACCEPT_LIST.includes(cloudResourceType)) {
      if (displayAsIcon) {
        return this.intl.t(
          CLOUD_RESOURCE_TYPE_ICON_I18N_MAPPING[cloudResourceType]
        );
      } else {
        let i18nTranslation =
          CLOUD_RESOURCE_TYPE_NAME_I18N_MAPPING[cloudResourceType];

        return this.intl.t(i18nTranslation);
      }
    }

    return cloudResourceType;
  }
}
