/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudIamGroupFromJSON, HashicorpCloudIamGroupToJSON, HashicorpCloudIamGroupMemberFromJSON, HashicorpCloudIamGroupMemberToJSON, } from './';
export function HashicorpCloudIamListGroupMembersResponseFromJSON(json) {
    return HashicorpCloudIamListGroupMembersResponseFromJSONTyped(json, false);
}
export function HashicorpCloudIamListGroupMembersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'members': !exists(json, 'members') ? undefined : (json['members'].map(HashicorpCloudIamGroupMemberFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
        'group': !exists(json, 'group') ? undefined : HashicorpCloudIamGroupFromJSON(json['group']),
    };
}
export function HashicorpCloudIamListGroupMembersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'members': value.members === undefined ? undefined : (value.members.map(HashicorpCloudIamGroupMemberToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
        'group': HashicorpCloudIamGroupToJSON(value.group),
    };
}
