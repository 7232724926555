/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - UNDETERMINED: HCP Packer cannot determine whether the child artifact is out of date.
 * This status occurs when the parent version was not assigned to a channel
 * when Packer built the artifact, the channel no longer exists in the bucket,
 * or HCP Packer is not tracking the parent.
 *  - UP_TO_DATE: The child artifact is built from the version currently assigned to the channel.
 *  - OUT_OF_DATE: The child artifact is built from a different version than the one currently
 * assigned to the channel.
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202ModelsAncestryStatus;
(function (HashicorpCloudPacker20221202ModelsAncestryStatus) {
    HashicorpCloudPacker20221202ModelsAncestryStatus["UNDETERMINED"] = "UNDETERMINED";
    HashicorpCloudPacker20221202ModelsAncestryStatus["UPTODATE"] = "UP_TO_DATE";
    HashicorpCloudPacker20221202ModelsAncestryStatus["OUTOFDATE"] = "OUT_OF_DATE";
})(HashicorpCloudPacker20221202ModelsAncestryStatus || (HashicorpCloudPacker20221202ModelsAncestryStatus = {}));
export function HashicorpCloudPacker20221202ModelsAncestryStatusFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsAncestryStatusFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsAncestryStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202ModelsAncestryStatusToJSON(value) {
    return value;
}
