/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudResourcemanagerRoleFromJSON, HashicorpCloudResourcemanagerRoleToJSON, } from './';
export function HashicorpCloudResourcemanagerListRolesResponseFromJSON(json) {
    return HashicorpCloudResourcemanagerListRolesResponseFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerListRolesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'roles': !exists(json, 'roles') ? undefined : (json['roles'].map(HashicorpCloudResourcemanagerRoleFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudResourcemanagerListRolesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'roles': value.roles === undefined ? undefined : (value.roles.map(HashicorpCloudResourcemanagerRoleToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
