/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudResourcemanagerGetResourceResponseFromJSON, HashicorpCloudResourcemanagerListRolesResponseFromJSON, HashicorpCloudResourcemanagerResourceGetIamPolicyResponseFromJSON, HashicorpCloudResourcemanagerResourceListAccessibleResourcesResponseFromJSON, HashicorpCloudResourcemanagerResourceListResponseFromJSON, HashicorpCloudResourcemanagerResourceSetIamPolicyRequestToJSON, HashicorpCloudResourcemanagerResourceSetIamPolicyResponseFromJSON, HashicorpCloudResourcemanagerResourceTestIamPermissionsRequestToJSON, HashicorpCloudResourcemanagerResourceTestIamPermissionsResponseFromJSON, } from '../models';
/**
 *
 */
export class ResourceServiceApi extends runtime.BaseAPI {
    /**
     * GetIamPolicy gets the IAM policy for a resource.
     */
    async resourceServiceGetIamPolicyRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.resourceName !== undefined) {
            queryParameters['resource_name'] = requestParameters.resourceName;
        }
        if (requestParameters.resourceId !== undefined) {
            queryParameters['resource_id'] = requestParameters.resourceId;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/resource-manager/resources/iam-policy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerResourceGetIamPolicyResponseFromJSON(jsonValue));
    }
    /**
     * GetIamPolicy gets the IAM policy for a resource.
     */
    async resourceServiceGetIamPolicy(resourceName, resourceId) {
        const response = await this.resourceServiceGetIamPolicyRaw({ resourceName: resourceName, resourceId: resourceId });
        return await response.value();
    }
    /**
     * GetResource retrieves a resource.
     */
    async resourceServiceGetResourceRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.resourceId !== undefined) {
            queryParameters['resource_id'] = requestParameters.resourceId;
        }
        if (requestParameters.resourceName !== undefined) {
            queryParameters['resource_name'] = requestParameters.resourceName;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/resource`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerGetResourceResponseFromJSON(jsonValue));
    }
    /**
     * GetResource retrieves a resource.
     */
    async resourceServiceGetResource(resourceId, resourceName) {
        const response = await this.resourceServiceGetResourceRaw({ resourceId: resourceId, resourceName: resourceName });
        return await response.value();
    }
    /**
     * List lists the resources the caller has access to.
     */
    async resourceServiceListRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.scopeType !== undefined) {
            queryParameters['scope.type'] = requestParameters.scopeType;
        }
        if (requestParameters.scopeId !== undefined) {
            queryParameters['scope.id'] = requestParameters.scopeId;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerResourceListResponseFromJSON(jsonValue));
    }
    /**
     * List lists the resources the caller has access to.
     */
    async resourceServiceList(scopeType, scopeId, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.resourceServiceListRaw({ scopeType: scopeType, scopeId: scopeId, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListAccessibleResources lists the resources the caller has access to at and below the given scope.
     */
    async resourceServiceListAccessibleResourcesRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.scopeResourceName !== undefined) {
            queryParameters['scope_resource_name'] = requestParameters.scopeResourceName;
        }
        if (requestParameters.scopeResourceId !== undefined) {
            queryParameters['scope_resource_id'] = requestParameters.scopeResourceId;
        }
        if (requestParameters.resourceType !== undefined) {
            queryParameters['resource_type'] = requestParameters.resourceType;
        }
        if (requestParameters.permission !== undefined) {
            queryParameters['permission'] = requestParameters.permission;
        }
        if (requestParameters.leafNameFilter !== undefined) {
            queryParameters['leaf_name_filter'] = requestParameters.leafNameFilter;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/resources/accessible`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerResourceListAccessibleResourcesResponseFromJSON(jsonValue));
    }
    /**
     * ListAccessibleResources lists the resources the caller has access to at and below the given scope.
     */
    async resourceServiceListAccessibleResources(scopeResourceName, scopeResourceId, resourceType, permission, leafNameFilter, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.resourceServiceListAccessibleResourcesRaw({ scopeResourceName: scopeResourceName, scopeResourceId: scopeResourceId, resourceType: resourceType, permission: permission, leafNameFilter: leafNameFilter, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListRoles lists roles applicable on the given resource. Requires caller to have \"resource-manager.resource.list-roles\" permission on the given resource.
     */
    async resourceServiceListRolesRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.resourceName !== undefined) {
            queryParameters['resource.name'] = requestParameters.resourceName;
        }
        if (requestParameters.resourceId !== undefined) {
            queryParameters['resource.id'] = requestParameters.resourceId;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/resource/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerListRolesResponseFromJSON(jsonValue));
    }
    /**
     * ListRoles lists roles applicable on the given resource. Requires caller to have \"resource-manager.resource.list-roles\" permission on the given resource.
     */
    async resourceServiceListRoles(resourceName, resourceId, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.resourceServiceListRolesRaw({ resourceName: resourceName, resourceId: resourceId, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * SetIamPolicy sets the IAM policy for a resource. Since this updates the full policy, the policy takes an \"etag\" argument that should match with the current policy stored in the system. This is to avoid concurrent writes erasing each other\'s data. Hence, the client should always get the current policy that includes the etag, modify the policy and then set the policy with the same etag. If there is no existing policy the etag must be empty.
     */
    async resourceServiceSetIamPolicyRaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling resourceServiceSetIamPolicy.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/2019-12-10/resource-manager/resources/iam-policy`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudResourcemanagerResourceSetIamPolicyRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerResourceSetIamPolicyResponseFromJSON(jsonValue));
    }
    /**
     * SetIamPolicy sets the IAM policy for a resource. Since this updates the full policy, the policy takes an \"etag\" argument that should match with the current policy stored in the system. This is to avoid concurrent writes erasing each other\'s data. Hence, the client should always get the current policy that includes the etag, modify the policy and then set the policy with the same etag. If there is no existing policy the etag must be empty.
     */
    async resourceServiceSetIamPolicy(body) {
        const response = await this.resourceServiceSetIamPolicyRaw({ body: body });
        return await response.value();
    }
    /**
     * TestIamPermissions returns a subset of the request permissions the calling principal has on the resource.
     */
    async resourceServiceTestIamPermissionsRaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling resourceServiceTestIamPermissions.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/resource-manager/2019-12-10/resources/test-iam-permissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudResourcemanagerResourceTestIamPermissionsRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudResourcemanagerResourceTestIamPermissionsResponseFromJSON(jsonValue));
    }
    /**
     * TestIamPermissions returns a subset of the request permissions the calling principal has on the resource.
     */
    async resourceServiceTestIamPermissions(body) {
        const response = await this.resourceServiceTestIamPermissionsRaw({ body: body });
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var ResourceServiceListScopeTypeEnum;
(function (ResourceServiceListScopeTypeEnum) {
    ResourceServiceListScopeTypeEnum["UNKNOWN"] = "UNKNOWN";
    ResourceServiceListScopeTypeEnum["ORGANIZATION"] = "ORGANIZATION";
    ResourceServiceListScopeTypeEnum["PROJECT"] = "PROJECT";
})(ResourceServiceListScopeTypeEnum || (ResourceServiceListScopeTypeEnum = {}));
