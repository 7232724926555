/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudBoundary20211221ClusterFromJSON, HashicorpCloudBoundary20211221ClusterToJSON, } from './';
export function HashicorpCloudBoundary20211221GetResponseFromJSON(json) {
    return HashicorpCloudBoundary20211221GetResponseFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221GetResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cluster': !exists(json, 'cluster') ? undefined : HashicorpCloudBoundary20211221ClusterFromJSON(json['cluster']),
    };
}
export function HashicorpCloudBoundary20211221GetResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cluster': HashicorpCloudBoundary20211221ClusterToJSON(value.cluster),
    };
}
