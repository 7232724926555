/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125GetAvailableProvidersResponseProviderFromJSON, HashicorpCloudVault20201125GetAvailableProvidersResponseProviderToJSON, } from './';
export function HashicorpCloudVault20201125GetAvailableProvidersResponseFromJSON(json) {
    return HashicorpCloudVault20201125GetAvailableProvidersResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125GetAvailableProvidersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'providers': !exists(json, 'providers') ? undefined : (json['providers'].map(HashicorpCloudVault20201125GetAvailableProvidersResponseProviderFromJSON)),
    };
}
export function HashicorpCloudVault20201125GetAvailableProvidersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'providers': value.providers === undefined ? undefined : (value.providers.map(HashicorpCloudVault20201125GetAvailableProvidersResponseProviderToJSON)),
    };
}
