/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, HashicorpCloudVaultLink20221107LinkedClusterNodeFromJSON, HashicorpCloudVaultLink20221107LinkedClusterNodeToJSON, HashicorpCloudVaultLink20221107LinkedClusterStateFromJSON, HashicorpCloudVaultLink20221107LinkedClusterStateToJSON, HashicorpCloudVaultLink20221107RaftQuorumStatusFromJSON, HashicorpCloudVaultLink20221107RaftQuorumStatusToJSON, } from './';
export function HashicorpCloudVaultLink20221107LinkedClusterFromJSON(json) {
    return HashicorpCloudVaultLink20221107LinkedClusterFromJSONTyped(json, false);
}
export function HashicorpCloudVaultLink20221107LinkedClusterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'internalId': !exists(json, 'internal_id') ? undefined : json['internal_id'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'state': !exists(json, 'state') ? undefined : HashicorpCloudVaultLink20221107LinkedClusterStateFromJSON(json['state']),
        'linkedAt': !exists(json, 'linked_at') ? undefined : (new Date(json['linked_at'])),
        'currentVersion': !exists(json, 'current_version') ? undefined : json['current_version'],
        'nodeStatuses': !exists(json, 'node_statuses') ? undefined : (json['node_statuses'].map(HashicorpCloudVaultLink20221107LinkedClusterNodeFromJSON)),
        'storageType': !exists(json, 'storage_type') ? undefined : json['storage_type'],
        'autopilotEnabled': !exists(json, 'autopilot_enabled') ? undefined : json['autopilot_enabled'],
        'haEnabled': !exists(json, 'ha_enabled') ? undefined : json['ha_enabled'],
        'isSealed': !exists(json, 'is_sealed') ? undefined : json['is_sealed'],
        'raftQuorumStatus': !exists(json, 'raft_quorum_status') ? undefined : HashicorpCloudVaultLink20221107RaftQuorumStatusFromJSON(json['raft_quorum_status']),
        'clusterName': !exists(json, 'cluster_name') ? undefined : json['cluster_name'],
        'credentialsRevoked': !exists(json, 'credentials_revoked') ? undefined : json['credentials_revoked'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}
export function HashicorpCloudVaultLink20221107LinkedClusterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'internal_id': value.internalId,
        'id': value.id,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'state': HashicorpCloudVaultLink20221107LinkedClusterStateToJSON(value.state),
        'linked_at': value.linkedAt === undefined ? undefined : (value.linkedAt.toISOString()),
        'current_version': value.currentVersion,
        'node_statuses': value.nodeStatuses === undefined ? undefined : (value.nodeStatuses.map(HashicorpCloudVaultLink20221107LinkedClusterNodeToJSON)),
        'storage_type': value.storageType,
        'autopilot_enabled': value.autopilotEnabled,
        'ha_enabled': value.haEnabled,
        'is_sealed': value.isSealed,
        'raft_quorum_status': HashicorpCloudVaultLink20221107RaftQuorumStatusToJSON(value.raftQuorumStatus),
        'cluster_name': value.clusterName,
        'credentials_revoked': value.credentialsRevoked,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}
