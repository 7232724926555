import Component from '@glimmer/component';

interface ZeroStateHeaderSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `ZeroStateHeader` displays the header element of the ZeroState screen.
 *
 *
 * ```
 * <ZeroState::Header>Heading</ZeroState::Header>
 * ```
 *
 * @class ZeroStateHeader
 *
 */

export default class ZeroStateHeaderComponent extends Component<ZeroStateHeaderSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ZeroState::Header': typeof ZeroStateHeaderComponent;
    'zero-state/header': typeof ZeroStateHeaderComponent;
  }
}
