/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128OpenSecretDynamicInstanceFromJSON, Secrets20231128OpenSecretDynamicInstanceToJSON, Secrets20231128OpenSecretRotatingVersionFromJSON, Secrets20231128OpenSecretRotatingVersionToJSON, Secrets20231128OpenSecretStaticVersionFromJSON, Secrets20231128OpenSecretStaticVersionToJSON, } from './';
export function Secrets20231128OpenAppSecretVersionResponseFromJSON(json) {
    return Secrets20231128OpenAppSecretVersionResponseFromJSONTyped(json, false);
}
export function Secrets20231128OpenAppSecretVersionResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !exists(json, 'type') ? undefined : json['type'],
        'staticVersion': !exists(json, 'static_version') ? undefined : Secrets20231128OpenSecretStaticVersionFromJSON(json['static_version']),
        'rotatingVersion': !exists(json, 'rotating_version') ? undefined : Secrets20231128OpenSecretRotatingVersionFromJSON(json['rotating_version']),
        'dynamicInstance': !exists(json, 'dynamic_instance') ? undefined : Secrets20231128OpenSecretDynamicInstanceFromJSON(json['dynamic_instance']),
    };
}
export function Secrets20231128OpenAppSecretVersionResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'static_version': Secrets20231128OpenSecretStaticVersionToJSON(value.staticVersion),
        'rotating_version': Secrets20231128OpenSecretRotatingVersionToJSON(value.rotatingVersion),
        'dynamic_instance': Secrets20231128OpenSecretDynamicInstanceToJSON(value.dynamicInstance),
    };
}
