/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVagrant20220930VersionFromJSON, HashicorpCloudVagrant20220930VersionToJSON, } from './';
export function HashicorpCloudVagrant20220930ReadVersionResponseFromJSON(json) {
    return HashicorpCloudVagrant20220930ReadVersionResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930ReadVersionResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'version': !exists(json, 'version') ? undefined : HashicorpCloudVagrant20220930VersionFromJSON(json['version']),
    };
}
export function HashicorpCloudVagrant20220930ReadVersionResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'version': HashicorpCloudVagrant20220930VersionToJSON(value.version),
    };
}
