/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudPacker20221202ImageFromJSON(json) {
    return HashicorpCloudPacker20221202ImageFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ImageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'imageId': !exists(json, 'image_id') ? undefined : json['image_id'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}
export function HashicorpCloudPacker20221202ImageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'image_id': value.imageId,
        'region': value.region,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}
