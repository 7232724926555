/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointCheckNamespaceActivationResponseFromJSON(json) {
    return HashicorpCloudWaypointCheckNamespaceActivationResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointCheckNamespaceActivationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'active': !exists(json, 'active') ? undefined : json['active'],
        'hasActivated': !exists(json, 'has_activated') ? undefined : json['has_activated'],
    };
}
export function HashicorpCloudWaypointCheckNamespaceActivationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'active': value.active,
        'has_activated': value.hasActivated,
    };
}
