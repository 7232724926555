/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - RESTRICTED: Make the channel only visible to users with permission to create and manage.
 *  - UNRESTRICTED: Make the channel visible to every member of this organization.
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202UpdateChannelBodyRestriction;
(function (HashicorpCloudPacker20221202UpdateChannelBodyRestriction) {
    HashicorpCloudPacker20221202UpdateChannelBodyRestriction["RESTRICTIONUNSET"] = "RESTRICTION_UNSET";
    HashicorpCloudPacker20221202UpdateChannelBodyRestriction["RESTRICTED"] = "RESTRICTED";
    HashicorpCloudPacker20221202UpdateChannelBodyRestriction["UNRESTRICTED"] = "UNRESTRICTED";
})(HashicorpCloudPacker20221202UpdateChannelBodyRestriction || (HashicorpCloudPacker20221202UpdateChannelBodyRestriction = {}));
export function HashicorpCloudPacker20221202UpdateChannelBodyRestrictionFromJSON(json) {
    return HashicorpCloudPacker20221202UpdateChannelBodyRestrictionFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202UpdateChannelBodyRestrictionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202UpdateChannelBodyRestrictionToJSON(value) {
    return value;
}
