/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125MajorVersionUpgradeConfigUpgradeType;
(function (HashicorpCloudVault20201125MajorVersionUpgradeConfigUpgradeType) {
    HashicorpCloudVault20201125MajorVersionUpgradeConfigUpgradeType["MAJORVERSIONUPGRADECONFIGUPGRADETYPEINVALID"] = "MAJOR_VERSION_UPGRADE_CONFIG_UPGRADE_TYPE_INVALID";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigUpgradeType["AUTOMATIC"] = "AUTOMATIC";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigUpgradeType["SCHEDULED"] = "SCHEDULED";
    HashicorpCloudVault20201125MajorVersionUpgradeConfigUpgradeType["MANUAL"] = "MANUAL";
})(HashicorpCloudVault20201125MajorVersionUpgradeConfigUpgradeType || (HashicorpCloudVault20201125MajorVersionUpgradeConfigUpgradeType = {}));
export function HashicorpCloudVault20201125MajorVersionUpgradeConfigUpgradeTypeFromJSON(json) {
    return HashicorpCloudVault20201125MajorVersionUpgradeConfigUpgradeTypeFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125MajorVersionUpgradeConfigUpgradeTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125MajorVersionUpgradeConfigUpgradeTypeToJSON(value) {
    return value;
}
