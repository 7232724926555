import Component from '@glimmer/component';

interface DefinitionListKeySignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

/**
 *
 * `DefinitionValue` provides a container to display a key in a key/value pair.
 *
 *
 * ```
 * <DefinitionList::Key>
 *   Some Key
 * </DefinitionList::Key>
 * ```
 *
 * @class DefinitionListKey
 *
 */

export default class DefinitionListKeyComponent extends Component<DefinitionListKeySignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DefinitionList::Key': typeof DefinitionListKeyComponent;
    'definition-list/key': typeof DefinitionListKeyComponent;
  }
}
