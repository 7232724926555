/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudVagrant20220930ProviderFromJSON, HashicorpCloudVagrant20220930ProviderToJSON, } from './';
export function HashicorpCloudVagrant20220930ListProvidersResponseFromJSON(json) {
    return HashicorpCloudVagrant20220930ListProvidersResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930ListProvidersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
        'providers': !exists(json, 'providers') ? undefined : (json['providers'].map(HashicorpCloudVagrant20220930ProviderFromJSON)),
    };
}
export function HashicorpCloudVagrant20220930ListProvidersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
        'providers': value.providers === undefined ? undefined : (value.providers.map(HashicorpCloudVagrant20220930ProviderToJSON)),
    };
}
