import templateOnlyComponent from '@ember/component/template-only';

interface ModalDialogFooterActionsSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
  Blocks: {
    default: [];
  };
}

const ModalDialogFooterActionsComponent =
  templateOnlyComponent<ModalDialogFooterActionsSignature>();

export default ModalDialogFooterActionsComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ModalDialog::Footer::Actions': typeof ModalDialogFooterActionsComponent;
    'modal-dialog/footer/actions': typeof ModalDialogFooterActionsComponent;
  }
}
