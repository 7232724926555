/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointApplicationFromJSON, HashicorpCloudWaypointApplicationToJSON, } from './';
export function HashicorpCloudWaypointUIBulkUpdateActionAssignmentsForAppResponseFromJSON(json) {
    return HashicorpCloudWaypointUIBulkUpdateActionAssignmentsForAppResponseFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointUIBulkUpdateActionAssignmentsForAppResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'applications': !exists(json, 'applications') ? undefined : (json['applications'].map(HashicorpCloudWaypointApplicationFromJSON)),
    };
}
export function HashicorpCloudWaypointUIBulkUpdateActionAssignmentsForAppResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'applications': value.applications === undefined ? undefined : (value.applications.map(HashicorpCloudWaypointApplicationToJSON)),
    };
}
