/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105ResourceUsageFromJSON, Billing20201105ResourceUsageToJSON, } from './';
export function Billing20201105BillingAccountUsageFromJSON(json) {
    return Billing20201105BillingAccountUsageFromJSONTyped(json, false);
}
export function Billing20201105BillingAccountUsageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'billingPeriodStart': !exists(json, 'billing_period_start') ? undefined : (new Date(json['billing_period_start'])),
        'billingPeriodEnd': !exists(json, 'billing_period_end') ? undefined : (new Date(json['billing_period_end'])),
        'totalCost': !exists(json, 'total_cost') ? undefined : json['total_cost'],
        'remainingBalance': !exists(json, 'remaining_balance') ? undefined : json['remaining_balance'],
        'subtotalCost': !exists(json, 'subtotal_cost') ? undefined : json['subtotal_cost'],
        'resourceUsages': !exists(json, 'resource_usages') ? undefined : (json['resource_usages'].map(Billing20201105ResourceUsageFromJSON)),
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
    };
}
export function Billing20201105BillingAccountUsageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'billing_period_start': value.billingPeriodStart === undefined ? undefined : (value.billingPeriodStart.toISOString()),
        'billing_period_end': value.billingPeriodEnd === undefined ? undefined : (value.billingPeriodEnd.toISOString()),
        'total_cost': value.totalCost,
        'remaining_balance': value.remainingBalance,
        'subtotal_cost': value.subtotalCost,
        'resource_usages': value.resourceUsages === undefined ? undefined : (value.resourceUsages.map(Billing20201105ResourceUsageToJSON)),
        'discount': value.discount,
    };
}
