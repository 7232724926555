/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CloudlogService20210330FilterFromJSON, CloudlogService20210330FilterToJSON, LocationLinkFromJSON, LocationLinkToJSON, LogService20210330CloudwatchLogsProviderFromJSON, LogService20210330CloudwatchLogsProviderToJSON, LogService20210330DatadogProviderFromJSON, LogService20210330DatadogProviderToJSON, LogService20210330SplunkCloudProviderFromJSON, LogService20210330SplunkCloudProviderToJSON, } from './';
export function LogService20210330DestinationFromJSON(json) {
    return LogService20210330DestinationFromJSONTyped(json, false);
}
export function LogService20210330DestinationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'resource': !exists(json, 'resource') ? undefined : LocationLinkFromJSON(json['resource']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'filter': !exists(json, 'filter') ? undefined : CloudlogService20210330FilterFromJSON(json['filter']),
        'inError': !exists(json, 'in_error') ? undefined : json['in_error'],
        'lastErrorMessage': !exists(json, 'last_error_message') ? undefined : json['last_error_message'],
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
        'datadogProvider': !exists(json, 'datadog_provider') ? undefined : LogService20210330DatadogProviderFromJSON(json['datadog_provider']),
        'cloudwatchlogsProvider': !exists(json, 'cloudwatchlogs_provider') ? undefined : LogService20210330CloudwatchLogsProviderFromJSON(json['cloudwatchlogs_provider']),
        'splunkCloudProvider': !exists(json, 'splunk_cloud_provider') ? undefined : LogService20210330SplunkCloudProviderFromJSON(json['splunk_cloud_provider']),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}
export function LogService20210330DestinationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'resource': LocationLinkToJSON(value.resource),
        'name': value.name,
        'filter': CloudlogService20210330FilterToJSON(value.filter),
        'in_error': value.inError,
        'last_error_message': value.lastErrorMessage,
        'endpoint': value.endpoint,
        'datadog_provider': LogService20210330DatadogProviderToJSON(value.datadogProvider),
        'cloudwatchlogs_provider': LogService20210330CloudwatchLogsProviderToJSON(value.cloudwatchlogsProvider),
        'splunk_cloud_provider': LogService20210330SplunkCloudProviderToJSON(value.splunkCloudProvider),
        'status': value.status,
    };
}
