/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudConsulTelemetry20230414ServiceFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414ServiceFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414ServiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'partition': !exists(json, 'partition') ? undefined : json['partition'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'kind': !exists(json, 'kind') ? undefined : json['kind'],
        'samenessGroup': !exists(json, 'sameness_group') ? undefined : json['sameness_group'],
        'instanceCount': !exists(json, 'instance_count') ? undefined : json['instance_count'],
        'checksPassing': !exists(json, 'checks_passing') ? undefined : json['checks_passing'],
        'checksWarning': !exists(json, 'checks_warning') ? undefined : json['checks_warning'],
        'checksCritical': !exists(json, 'checks_critical') ? undefined : json['checks_critical'],
        'instancesPassing': !exists(json, 'instances_passing') ? undefined : json['instances_passing'],
        'instancesWarning': !exists(json, 'instances_warning') ? undefined : json['instances_warning'],
        'instancesCritical': !exists(json, 'instances_critical') ? undefined : json['instances_critical'],
    };
}
export function HashicorpCloudConsulTelemetry20230414ServiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'partition': value.partition,
        'namespace': value.namespace,
        'name': value.name,
        'kind': value.kind,
        'sameness_group': value.samenessGroup,
        'instance_count': value.instanceCount,
        'checks_passing': value.checksPassing,
        'checks_warning': value.checksWarning,
        'checks_critical': value.checksCritical,
        'instances_passing': value.instancesPassing,
        'instances_warning': value.instancesWarning,
        'instances_critical': value.instancesCritical,
    };
}
