import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { DEBUG } from '@glimmer/env';
import * as Sentry from '@sentry/ember';

import type ResourceCatalogService from '../services/resource-catalog.ts';
import type { ResourceTierSizeData } from '../types/resource-catalog.ts';

export default class OptionForResourceTierSize extends Helper<ResourceTierSizeData | null> {
  @service declare readonly resourceCatalog: ResourceCatalogService;

  compute(
    [sizeKey = '']: [string | undefined],
    {
      tierKey,
      resourceType,
      provider,
      showCostData = true,
    }: {
      tierKey?: string;
      resourceType?: string;
      showCostData?: boolean;
      provider?: string;
    },
  ) {
    if (!tierKey || !resourceType || !sizeKey || !provider) {
      return null;
    }

    // This checks if a size exists, if not, it won't try to display it on the page.
    try {
      return this.resourceCatalog.getSize(
        resourceType,
        tierKey,
        sizeKey,
        provider,
        showCostData,
      );
    } catch (e) {
      if (DEBUG) {
        console.log(e); // eslint-disable-line
      }
      Sentry.captureException(e);
      return null;
    }
  }
}
