/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudNetwork20200907AssociateHVNWithAWSRoute53PrivateHostedZoneRequestToJSON, HashicorpCloudNetwork20200907CreateHVNRouteRequestToJSON, HashicorpCloudNetwork20200907CreateHVNRouteResponseFromJSON, HashicorpCloudNetwork20200907CreatePeeringRequestToJSON, HashicorpCloudNetwork20200907CreatePeeringResponseFromJSON, HashicorpCloudNetwork20200907CreateRequestToJSON, HashicorpCloudNetwork20200907CreateResponseFromJSON, HashicorpCloudNetwork20200907CreateTGWAttachmentRequestToJSON, HashicorpCloudNetwork20200907CreateTGWAttachmentResponseFromJSON, HashicorpCloudNetwork20200907CreateTokenRequestToJSON, HashicorpCloudNetwork20200907CreateTokenResponseFromJSON, HashicorpCloudNetwork20200907DeleteHVNRouteResponseFromJSON, HashicorpCloudNetwork20200907DeletePeeringResponseFromJSON, HashicorpCloudNetwork20200907DeleteResponseFromJSON, HashicorpCloudNetwork20200907DeleteTGWAttachmentResponseFromJSON, HashicorpCloudNetwork20200907GetHVNRouteResponseFromJSON, HashicorpCloudNetwork20200907GetPeeringResponseFromJSON, HashicorpCloudNetwork20200907GetResponseFromJSON, HashicorpCloudNetwork20200907GetTGWAttachmentResponseFromJSON, HashicorpCloudNetwork20200907ListDependenciesResponseFromJSON, HashicorpCloudNetwork20200907ListHVNRoutesResponseFromJSON, HashicorpCloudNetwork20200907ListPeeringsResponseFromJSON, HashicorpCloudNetwork20200907ListResponseFromJSON, HashicorpCloudNetwork20200907ListTGWAttachmentsResponseFromJSON, } from '../models';
/**
 *
 */
export class NetworkServiceApi extends runtime.BaseAPI {
    /**
     * Delete deletes a new HVN.
     */
    async _deleteRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling _delete.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling _delete.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling _delete.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{location.organization_id}/projects/{location.project_id}/networks/{id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907DeleteResponseFromJSON(jsonValue));
    }
    /**
     * Delete deletes a new HVN.
     */
    async _delete(locationOrganizationId, locationProjectId, id, locationRegionProvider, locationRegionRegion) {
        const response = await this._deleteRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, id: id, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * AssociateHVNWithAWSRoute53PrivateHostedZone will associate the HVN\'s VPC with a user\'s private hosted zone in AWS Route53. This will enable the resolution of records of the private hosted zone from within the VPC.
     */
    async associateHVNWithAWSRoute53PrivateHostedZoneRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling associateHVNWithAWSRoute53PrivateHostedZone.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling associateHVNWithAWSRoute53PrivateHostedZone.');
        }
        if (requestParameters.hvnId === null || requestParameters.hvnId === undefined) {
            throw new runtime.RequiredError('hvnId', 'Required parameter requestParameters.hvnId was null or undefined when calling associateHVNWithAWSRoute53PrivateHostedZone.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling associateHVNWithAWSRoute53PrivateHostedZone.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{location.organization_id}/projects/{location.project_id}/networks/{hvn_id}/aws-route53-private-hosted-zone-associations`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"hvn_id"}}`, encodeURIComponent(String(requestParameters.hvnId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudNetwork20200907AssociateHVNWithAWSRoute53PrivateHostedZoneRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * AssociateHVNWithAWSRoute53PrivateHostedZone will associate the HVN\'s VPC with a user\'s private hosted zone in AWS Route53. This will enable the resolution of records of the private hosted zone from within the VPC.
     */
    async associateHVNWithAWSRoute53PrivateHostedZone(locationOrganizationId, locationProjectId, hvnId, body) {
        const response = await this.associateHVNWithAWSRoute53PrivateHostedZoneRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, hvnId: hvnId, body: body });
        return await response.value();
    }
    /**
     * Create creates a new HVN.
     */
    async createRaw(requestParameters) {
        if (requestParameters.networkLocationOrganizationId === null || requestParameters.networkLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('networkLocationOrganizationId', 'Required parameter requestParameters.networkLocationOrganizationId was null or undefined when calling create.');
        }
        if (requestParameters.networkLocationProjectId === null || requestParameters.networkLocationProjectId === undefined) {
            throw new runtime.RequiredError('networkLocationProjectId', 'Required parameter requestParameters.networkLocationProjectId was null or undefined when calling create.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling create.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{network.location.organization_id}/projects/{network.location.project_id}/networks`.replace(`{${"network.location.organization_id"}}`, encodeURIComponent(String(requestParameters.networkLocationOrganizationId))).replace(`{${"network.location.project_id"}}`, encodeURIComponent(String(requestParameters.networkLocationProjectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudNetwork20200907CreateRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907CreateResponseFromJSON(jsonValue));
    }
    /**
     * Create creates a new HVN.
     */
    async create(networkLocationOrganizationId, networkLocationProjectId, body) {
        const response = await this.createRaw({ networkLocationOrganizationId: networkLocationOrganizationId, networkLocationProjectId: networkLocationProjectId, body: body });
        return await response.value();
    }
    /**
     * CreateHVNRoute creates a new HVN Route
     * HVN Routes
     */
    async createHVNRouteRaw(requestParameters) {
        if (requestParameters.hvnLocationOrganizationId === null || requestParameters.hvnLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('hvnLocationOrganizationId', 'Required parameter requestParameters.hvnLocationOrganizationId was null or undefined when calling createHVNRoute.');
        }
        if (requestParameters.hvnLocationProjectId === null || requestParameters.hvnLocationProjectId === undefined) {
            throw new runtime.RequiredError('hvnLocationProjectId', 'Required parameter requestParameters.hvnLocationProjectId was null or undefined when calling createHVNRoute.');
        }
        if (requestParameters.hvnId === null || requestParameters.hvnId === undefined) {
            throw new runtime.RequiredError('hvnId', 'Required parameter requestParameters.hvnId was null or undefined when calling createHVNRoute.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createHVNRoute.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{hvn.location.organization_id}/projects/{hvn.location.project_id}/networks/{hvn.id}/routes`.replace(`{${"hvn.location.organization_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationOrganizationId))).replace(`{${"hvn.location.project_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationProjectId))).replace(`{${"hvn.id"}}`, encodeURIComponent(String(requestParameters.hvnId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudNetwork20200907CreateHVNRouteRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907CreateHVNRouteResponseFromJSON(jsonValue));
    }
    /**
     * CreateHVNRoute creates a new HVN Route
     * HVN Routes
     */
    async createHVNRoute(hvnLocationOrganizationId, hvnLocationProjectId, hvnId, body) {
        const response = await this.createHVNRouteRaw({ hvnLocationOrganizationId: hvnLocationOrganizationId, hvnLocationProjectId: hvnLocationProjectId, hvnId: hvnId, body: body });
        return await response.value();
    }
    /**
     * CreatePeering creates a new network peering between HVN VPC and target VPC
     */
    async createPeeringRaw(requestParameters) {
        if (requestParameters.peeringHvnLocationOrganizationId === null || requestParameters.peeringHvnLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('peeringHvnLocationOrganizationId', 'Required parameter requestParameters.peeringHvnLocationOrganizationId was null or undefined when calling createPeering.');
        }
        if (requestParameters.peeringHvnLocationProjectId === null || requestParameters.peeringHvnLocationProjectId === undefined) {
            throw new runtime.RequiredError('peeringHvnLocationProjectId', 'Required parameter requestParameters.peeringHvnLocationProjectId was null or undefined when calling createPeering.');
        }
        if (requestParameters.peeringHvnId === null || requestParameters.peeringHvnId === undefined) {
            throw new runtime.RequiredError('peeringHvnId', 'Required parameter requestParameters.peeringHvnId was null or undefined when calling createPeering.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createPeering.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{peering.hvn.location.organization_id}/projects/{peering.hvn.location.project_id}/networks/{peering.hvn.id}/peerings`.replace(`{${"peering.hvn.location.organization_id"}}`, encodeURIComponent(String(requestParameters.peeringHvnLocationOrganizationId))).replace(`{${"peering.hvn.location.project_id"}}`, encodeURIComponent(String(requestParameters.peeringHvnLocationProjectId))).replace(`{${"peering.hvn.id"}}`, encodeURIComponent(String(requestParameters.peeringHvnId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudNetwork20200907CreatePeeringRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907CreatePeeringResponseFromJSON(jsonValue));
    }
    /**
     * CreatePeering creates a new network peering between HVN VPC and target VPC
     */
    async createPeering(peeringHvnLocationOrganizationId, peeringHvnLocationProjectId, peeringHvnId, body) {
        const response = await this.createPeeringRaw({ peeringHvnLocationOrganizationId: peeringHvnLocationOrganizationId, peeringHvnLocationProjectId: peeringHvnLocationProjectId, peeringHvnId: peeringHvnId, body: body });
        return await response.value();
    }
    /**
     * CreateTGWAttachment creates a new Transit Gateway attachment for an HVN.
     */
    async createTGWAttachmentRaw(requestParameters) {
        if (requestParameters.hvnLocationOrganizationId === null || requestParameters.hvnLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('hvnLocationOrganizationId', 'Required parameter requestParameters.hvnLocationOrganizationId was null or undefined when calling createTGWAttachment.');
        }
        if (requestParameters.hvnLocationProjectId === null || requestParameters.hvnLocationProjectId === undefined) {
            throw new runtime.RequiredError('hvnLocationProjectId', 'Required parameter requestParameters.hvnLocationProjectId was null or undefined when calling createTGWAttachment.');
        }
        if (requestParameters.hvnId === null || requestParameters.hvnId === undefined) {
            throw new runtime.RequiredError('hvnId', 'Required parameter requestParameters.hvnId was null or undefined when calling createTGWAttachment.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createTGWAttachment.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{hvn.location.organization_id}/projects/{hvn.location.project_id}/networks/{hvn.id}/transit-gateway-attachments`.replace(`{${"hvn.location.organization_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationOrganizationId))).replace(`{${"hvn.location.project_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationProjectId))).replace(`{${"hvn.id"}}`, encodeURIComponent(String(requestParameters.hvnId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudNetwork20200907CreateTGWAttachmentRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907CreateTGWAttachmentResponseFromJSON(jsonValue));
    }
    /**
     * CreateTGWAttachment creates a new Transit Gateway attachment for an HVN.
     */
    async createTGWAttachment(hvnLocationOrganizationId, hvnLocationProjectId, hvnId, body) {
        const response = await this.createTGWAttachmentRaw({ hvnLocationOrganizationId: hvnLocationOrganizationId, hvnLocationProjectId: hvnLocationProjectId, hvnId: hvnId, body: body });
        return await response.value();
    }
    /**
     * CreateToken creates a short-lived token with auth to issue a CreatePeering for the given HVN. WARNING: This is an experimental feature that may change or be removed without notice.
     */
    async createTokenRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling createToken.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling createToken.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling createToken.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling createToken.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{location.organization_id}/projects/{location.project_id}/networks/{id}/tokens`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudNetwork20200907CreateTokenRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907CreateTokenResponseFromJSON(jsonValue));
    }
    /**
     * CreateToken creates a short-lived token with auth to issue a CreatePeering for the given HVN. WARNING: This is an experimental feature that may change or be removed without notice.
     */
    async createToken(locationOrganizationId, locationProjectId, id, body) {
        const response = await this.createTokenRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, id: id, body: body });
        return await response.value();
    }
    /**
     * DeleteHVNRoute triggers HVN Route deletion workflow
     */
    async deleteHVNRouteRaw(requestParameters) {
        if (requestParameters.hvnLocationOrganizationId === null || requestParameters.hvnLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('hvnLocationOrganizationId', 'Required parameter requestParameters.hvnLocationOrganizationId was null or undefined when calling deleteHVNRoute.');
        }
        if (requestParameters.hvnLocationProjectId === null || requestParameters.hvnLocationProjectId === undefined) {
            throw new runtime.RequiredError('hvnLocationProjectId', 'Required parameter requestParameters.hvnLocationProjectId was null or undefined when calling deleteHVNRoute.');
        }
        if (requestParameters.hvnId === null || requestParameters.hvnId === undefined) {
            throw new runtime.RequiredError('hvnId', 'Required parameter requestParameters.hvnId was null or undefined when calling deleteHVNRoute.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteHVNRoute.');
        }
        const queryParameters = {};
        if (requestParameters.hvnType !== undefined) {
            queryParameters['hvn.type'] = requestParameters.hvnType;
        }
        if (requestParameters.hvnUuid !== undefined) {
            queryParameters['hvn.uuid'] = requestParameters.hvnUuid;
        }
        if (requestParameters.hvnLocationRegionProvider !== undefined) {
            queryParameters['hvn.location.region.provider'] = requestParameters.hvnLocationRegionProvider;
        }
        if (requestParameters.hvnLocationRegionRegion !== undefined) {
            queryParameters['hvn.location.region.region'] = requestParameters.hvnLocationRegionRegion;
        }
        if (requestParameters.hvnDescription !== undefined) {
            queryParameters['hvn.description'] = requestParameters.hvnDescription;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{hvn.location.organization_id}/projects/{hvn.location.project_id}/networks/{hvn.id}/routes/{id}`.replace(`{${"hvn.location.organization_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationOrganizationId))).replace(`{${"hvn.location.project_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationProjectId))).replace(`{${"hvn.id"}}`, encodeURIComponent(String(requestParameters.hvnId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907DeleteHVNRouteResponseFromJSON(jsonValue));
    }
    /**
     * DeleteHVNRoute triggers HVN Route deletion workflow
     */
    async deleteHVNRoute(hvnLocationOrganizationId, hvnLocationProjectId, hvnId, id, hvnType, hvnUuid, hvnLocationRegionProvider, hvnLocationRegionRegion, hvnDescription) {
        const response = await this.deleteHVNRouteRaw({ hvnLocationOrganizationId: hvnLocationOrganizationId, hvnLocationProjectId: hvnLocationProjectId, hvnId: hvnId, id: id, hvnType: hvnType, hvnUuid: hvnUuid, hvnLocationRegionProvider: hvnLocationRegionProvider, hvnLocationRegionRegion: hvnLocationRegionRegion, hvnDescription: hvnDescription });
        return await response.value();
    }
    /**
     * DeletePeering deletes specified peering and triggers updating network configuration
     */
    async deletePeeringRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling deletePeering.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling deletePeering.');
        }
        if (requestParameters.hvnId === null || requestParameters.hvnId === undefined) {
            throw new runtime.RequiredError('hvnId', 'Required parameter requestParameters.hvnId was null or undefined when calling deletePeering.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deletePeering.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{location.organization_id}/projects/{location.project_id}/networks/{hvn_id}/peerings/{id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"hvn_id"}}`, encodeURIComponent(String(requestParameters.hvnId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907DeletePeeringResponseFromJSON(jsonValue));
    }
    /**
     * DeletePeering deletes specified peering and triggers updating network configuration
     */
    async deletePeering(locationOrganizationId, locationProjectId, hvnId, id, locationRegionProvider, locationRegionRegion) {
        const response = await this.deletePeeringRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, hvnId: hvnId, id: id, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * DeleteTGWAttachment deletes specified TransitGatewayAttachment and triggers updating network configuration
     */
    async deleteTGWAttachmentRaw(requestParameters) {
        if (requestParameters.hvnLocationOrganizationId === null || requestParameters.hvnLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('hvnLocationOrganizationId', 'Required parameter requestParameters.hvnLocationOrganizationId was null or undefined when calling deleteTGWAttachment.');
        }
        if (requestParameters.hvnLocationProjectId === null || requestParameters.hvnLocationProjectId === undefined) {
            throw new runtime.RequiredError('hvnLocationProjectId', 'Required parameter requestParameters.hvnLocationProjectId was null or undefined when calling deleteTGWAttachment.');
        }
        if (requestParameters.hvnId === null || requestParameters.hvnId === undefined) {
            throw new runtime.RequiredError('hvnId', 'Required parameter requestParameters.hvnId was null or undefined when calling deleteTGWAttachment.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteTGWAttachment.');
        }
        const queryParameters = {};
        if (requestParameters.hvnType !== undefined) {
            queryParameters['hvn.type'] = requestParameters.hvnType;
        }
        if (requestParameters.hvnUuid !== undefined) {
            queryParameters['hvn.uuid'] = requestParameters.hvnUuid;
        }
        if (requestParameters.hvnLocationRegionProvider !== undefined) {
            queryParameters['hvn.location.region.provider'] = requestParameters.hvnLocationRegionProvider;
        }
        if (requestParameters.hvnLocationRegionRegion !== undefined) {
            queryParameters['hvn.location.region.region'] = requestParameters.hvnLocationRegionRegion;
        }
        if (requestParameters.hvnDescription !== undefined) {
            queryParameters['hvn.description'] = requestParameters.hvnDescription;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{hvn.location.organization_id}/projects/{hvn.location.project_id}/networks/{hvn.id}/transit-gateway-attachments/{id}`.replace(`{${"hvn.location.organization_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationOrganizationId))).replace(`{${"hvn.location.project_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationProjectId))).replace(`{${"hvn.id"}}`, encodeURIComponent(String(requestParameters.hvnId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907DeleteTGWAttachmentResponseFromJSON(jsonValue));
    }
    /**
     * DeleteTGWAttachment deletes specified TransitGatewayAttachment and triggers updating network configuration
     */
    async deleteTGWAttachment(hvnLocationOrganizationId, hvnLocationProjectId, hvnId, id, hvnType, hvnUuid, hvnLocationRegionProvider, hvnLocationRegionRegion, hvnDescription) {
        const response = await this.deleteTGWAttachmentRaw({ hvnLocationOrganizationId: hvnLocationOrganizationId, hvnLocationProjectId: hvnLocationProjectId, hvnId: hvnId, id: id, hvnType: hvnType, hvnUuid: hvnUuid, hvnLocationRegionProvider: hvnLocationRegionProvider, hvnLocationRegionRegion: hvnLocationRegionRegion, hvnDescription: hvnDescription });
        return await response.value();
    }
    /**
     * Get reads a single HVN.
     */
    async getRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling get.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling get.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling get.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{location.organization_id}/projects/{location.project_id}/networks/{id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907GetResponseFromJSON(jsonValue));
    }
    /**
     * Get reads a single HVN.
     */
    async get(locationOrganizationId, locationProjectId, id, locationRegionProvider, locationRegionRegion) {
        const response = await this.getRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, id: id, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * GetHVNRoute returns a specific HVN Route
     */
    async getHVNRouteRaw(requestParameters) {
        if (requestParameters.hvnLocationOrganizationId === null || requestParameters.hvnLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('hvnLocationOrganizationId', 'Required parameter requestParameters.hvnLocationOrganizationId was null or undefined when calling getHVNRoute.');
        }
        if (requestParameters.hvnLocationProjectId === null || requestParameters.hvnLocationProjectId === undefined) {
            throw new runtime.RequiredError('hvnLocationProjectId', 'Required parameter requestParameters.hvnLocationProjectId was null or undefined when calling getHVNRoute.');
        }
        if (requestParameters.hvnId === null || requestParameters.hvnId === undefined) {
            throw new runtime.RequiredError('hvnId', 'Required parameter requestParameters.hvnId was null or undefined when calling getHVNRoute.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getHVNRoute.');
        }
        const queryParameters = {};
        if (requestParameters.hvnType !== undefined) {
            queryParameters['hvn.type'] = requestParameters.hvnType;
        }
        if (requestParameters.hvnUuid !== undefined) {
            queryParameters['hvn.uuid'] = requestParameters.hvnUuid;
        }
        if (requestParameters.hvnLocationRegionProvider !== undefined) {
            queryParameters['hvn.location.region.provider'] = requestParameters.hvnLocationRegionProvider;
        }
        if (requestParameters.hvnLocationRegionRegion !== undefined) {
            queryParameters['hvn.location.region.region'] = requestParameters.hvnLocationRegionRegion;
        }
        if (requestParameters.hvnDescription !== undefined) {
            queryParameters['hvn.description'] = requestParameters.hvnDescription;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{hvn.location.organization_id}/projects/{hvn.location.project_id}/networks/{hvn.id}/routes/{id}`.replace(`{${"hvn.location.organization_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationOrganizationId))).replace(`{${"hvn.location.project_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationProjectId))).replace(`{${"hvn.id"}}`, encodeURIComponent(String(requestParameters.hvnId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907GetHVNRouteResponseFromJSON(jsonValue));
    }
    /**
     * GetHVNRoute returns a specific HVN Route
     */
    async getHVNRoute(hvnLocationOrganizationId, hvnLocationProjectId, hvnId, id, hvnType, hvnUuid, hvnLocationRegionProvider, hvnLocationRegionRegion, hvnDescription) {
        const response = await this.getHVNRouteRaw({ hvnLocationOrganizationId: hvnLocationOrganizationId, hvnLocationProjectId: hvnLocationProjectId, hvnId: hvnId, id: id, hvnType: hvnType, hvnUuid: hvnUuid, hvnLocationRegionProvider: hvnLocationRegionProvider, hvnLocationRegionRegion: hvnLocationRegionRegion, hvnDescription: hvnDescription });
        return await response.value();
    }
    /**
     * GetPeering returns information about peering
     */
    async getPeeringRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling getPeering.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling getPeering.');
        }
        if (requestParameters.hvnId === null || requestParameters.hvnId === undefined) {
            throw new runtime.RequiredError('hvnId', 'Required parameter requestParameters.hvnId was null or undefined when calling getPeering.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getPeering.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{location.organization_id}/projects/{location.project_id}/networks/{hvn_id}/peerings/{id}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"hvn_id"}}`, encodeURIComponent(String(requestParameters.hvnId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907GetPeeringResponseFromJSON(jsonValue));
    }
    /**
     * GetPeering returns information about peering
     */
    async getPeering(locationOrganizationId, locationProjectId, hvnId, id, locationRegionProvider, locationRegionRegion) {
        const response = await this.getPeeringRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, hvnId: hvnId, id: id, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion });
        return await response.value();
    }
    /**
     * GetTGWAttachment returns existing TGW Attachment
     */
    async getTGWAttachmentRaw(requestParameters) {
        if (requestParameters.hvnLocationOrganizationId === null || requestParameters.hvnLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('hvnLocationOrganizationId', 'Required parameter requestParameters.hvnLocationOrganizationId was null or undefined when calling getTGWAttachment.');
        }
        if (requestParameters.hvnLocationProjectId === null || requestParameters.hvnLocationProjectId === undefined) {
            throw new runtime.RequiredError('hvnLocationProjectId', 'Required parameter requestParameters.hvnLocationProjectId was null or undefined when calling getTGWAttachment.');
        }
        if (requestParameters.hvnId === null || requestParameters.hvnId === undefined) {
            throw new runtime.RequiredError('hvnId', 'Required parameter requestParameters.hvnId was null or undefined when calling getTGWAttachment.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getTGWAttachment.');
        }
        const queryParameters = {};
        if (requestParameters.hvnType !== undefined) {
            queryParameters['hvn.type'] = requestParameters.hvnType;
        }
        if (requestParameters.hvnUuid !== undefined) {
            queryParameters['hvn.uuid'] = requestParameters.hvnUuid;
        }
        if (requestParameters.hvnLocationRegionProvider !== undefined) {
            queryParameters['hvn.location.region.provider'] = requestParameters.hvnLocationRegionProvider;
        }
        if (requestParameters.hvnLocationRegionRegion !== undefined) {
            queryParameters['hvn.location.region.region'] = requestParameters.hvnLocationRegionRegion;
        }
        if (requestParameters.hvnDescription !== undefined) {
            queryParameters['hvn.description'] = requestParameters.hvnDescription;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{hvn.location.organization_id}/projects/{hvn.location.project_id}/networks/{hvn.id}/transit-gateway-attachments/{id}`.replace(`{${"hvn.location.organization_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationOrganizationId))).replace(`{${"hvn.location.project_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationProjectId))).replace(`{${"hvn.id"}}`, encodeURIComponent(String(requestParameters.hvnId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907GetTGWAttachmentResponseFromJSON(jsonValue));
    }
    /**
     * GetTGWAttachment returns existing TGW Attachment
     */
    async getTGWAttachment(hvnLocationOrganizationId, hvnLocationProjectId, hvnId, id, hvnType, hvnUuid, hvnLocationRegionProvider, hvnLocationRegionRegion, hvnDescription) {
        const response = await this.getTGWAttachmentRaw({ hvnLocationOrganizationId: hvnLocationOrganizationId, hvnLocationProjectId: hvnLocationProjectId, hvnId: hvnId, id: id, hvnType: hvnType, hvnUuid: hvnUuid, hvnLocationRegionProvider: hvnLocationRegionProvider, hvnLocationRegionRegion: hvnLocationRegionRegion, hvnDescription: hvnDescription });
        return await response.value();
    }
    /**
     * List lists all HVNs.
     */
    async listRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling list.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling list.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{location.organization_id}/projects/{location.project_id}/networks`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907ListResponseFromJSON(jsonValue));
    }
    /**
     * List lists all HVNs.
     */
    async list(locationOrganizationId, locationProjectId, locationRegionProvider, locationRegionRegion, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.listRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListDependencies lists the dependent services on the given HVN.
     */
    async listDependenciesRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling listDependencies.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling listDependencies.');
        }
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling listDependencies.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{location.organization_id}/projects/{location.project_id}/networks/{id}/dependencies`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907ListDependenciesResponseFromJSON(jsonValue));
    }
    /**
     * ListDependencies lists the dependent services on the given HVN.
     */
    async listDependencies(locationOrganizationId, locationProjectId, id, locationRegionProvider, locationRegionRegion, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.listDependenciesRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, id: id, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListHVNRoutes returns a list of Routes for an HVN
     */
    async listHVNRoutesRaw(requestParameters) {
        if (requestParameters.hvnLocationOrganizationId === null || requestParameters.hvnLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('hvnLocationOrganizationId', 'Required parameter requestParameters.hvnLocationOrganizationId was null or undefined when calling listHVNRoutes.');
        }
        if (requestParameters.hvnLocationProjectId === null || requestParameters.hvnLocationProjectId === undefined) {
            throw new runtime.RequiredError('hvnLocationProjectId', 'Required parameter requestParameters.hvnLocationProjectId was null or undefined when calling listHVNRoutes.');
        }
        if (requestParameters.hvnId === null || requestParameters.hvnId === undefined) {
            throw new runtime.RequiredError('hvnId', 'Required parameter requestParameters.hvnId was null or undefined when calling listHVNRoutes.');
        }
        const queryParameters = {};
        if (requestParameters.hvnType !== undefined) {
            queryParameters['hvn.type'] = requestParameters.hvnType;
        }
        if (requestParameters.hvnUuid !== undefined) {
            queryParameters['hvn.uuid'] = requestParameters.hvnUuid;
        }
        if (requestParameters.hvnLocationRegionProvider !== undefined) {
            queryParameters['hvn.location.region.provider'] = requestParameters.hvnLocationRegionProvider;
        }
        if (requestParameters.hvnLocationRegionRegion !== undefined) {
            queryParameters['hvn.location.region.region'] = requestParameters.hvnLocationRegionRegion;
        }
        if (requestParameters.hvnDescription !== undefined) {
            queryParameters['hvn.description'] = requestParameters.hvnDescription;
        }
        if (requestParameters.targetId !== undefined) {
            queryParameters['target_id'] = requestParameters.targetId;
        }
        if (requestParameters.targetType !== undefined) {
            queryParameters['target_type'] = requestParameters.targetType;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        if (requestParameters.destination !== undefined) {
            queryParameters['destination'] = requestParameters.destination;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{hvn.location.organization_id}/projects/{hvn.location.project_id}/networks/{hvn.id}/routes`.replace(`{${"hvn.location.organization_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationOrganizationId))).replace(`{${"hvn.location.project_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationProjectId))).replace(`{${"hvn.id"}}`, encodeURIComponent(String(requestParameters.hvnId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907ListHVNRoutesResponseFromJSON(jsonValue));
    }
    /**
     * ListHVNRoutes returns a list of Routes for an HVN
     */
    async listHVNRoutes(hvnLocationOrganizationId, hvnLocationProjectId, hvnId, hvnType, hvnUuid, hvnLocationRegionProvider, hvnLocationRegionRegion, hvnDescription, targetId, targetType, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken, destination) {
        const response = await this.listHVNRoutesRaw({ hvnLocationOrganizationId: hvnLocationOrganizationId, hvnLocationProjectId: hvnLocationProjectId, hvnId: hvnId, hvnType: hvnType, hvnUuid: hvnUuid, hvnLocationRegionProvider: hvnLocationRegionProvider, hvnLocationRegionRegion: hvnLocationRegionRegion, hvnDescription: hvnDescription, targetId: targetId, targetType: targetType, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken, destination: destination });
        return await response.value();
    }
    /**
     * ListPeerings returns a list of peerings matching the request
     */
    async listPeeringsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling listPeerings.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling listPeerings.');
        }
        if (requestParameters.hvnId === null || requestParameters.hvnId === undefined) {
            throw new runtime.RequiredError('hvnId', 'Required parameter requestParameters.hvnId was null or undefined when calling listPeerings.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{location.organization_id}/projects/{location.project_id}/networks/{hvn_id}/peerings`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"hvn_id"}}`, encodeURIComponent(String(requestParameters.hvnId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907ListPeeringsResponseFromJSON(jsonValue));
    }
    /**
     * ListPeerings returns a list of peerings matching the request
     */
    async listPeerings(locationOrganizationId, locationProjectId, hvnId, locationRegionProvider, locationRegionRegion, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.listPeeringsRaw({ locationOrganizationId: locationOrganizationId, locationProjectId: locationProjectId, hvnId: hvnId, locationRegionProvider: locationRegionProvider, locationRegionRegion: locationRegionRegion, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
    /**
     * ListTGWAttachments returns a list of TGW Attachments matching the request
     */
    async listTGWAttachmentsRaw(requestParameters) {
        if (requestParameters.hvnLocationOrganizationId === null || requestParameters.hvnLocationOrganizationId === undefined) {
            throw new runtime.RequiredError('hvnLocationOrganizationId', 'Required parameter requestParameters.hvnLocationOrganizationId was null or undefined when calling listTGWAttachments.');
        }
        if (requestParameters.hvnLocationProjectId === null || requestParameters.hvnLocationProjectId === undefined) {
            throw new runtime.RequiredError('hvnLocationProjectId', 'Required parameter requestParameters.hvnLocationProjectId was null or undefined when calling listTGWAttachments.');
        }
        if (requestParameters.hvnId === null || requestParameters.hvnId === undefined) {
            throw new runtime.RequiredError('hvnId', 'Required parameter requestParameters.hvnId was null or undefined when calling listTGWAttachments.');
        }
        const queryParameters = {};
        if (requestParameters.hvnType !== undefined) {
            queryParameters['hvn.type'] = requestParameters.hvnType;
        }
        if (requestParameters.hvnUuid !== undefined) {
            queryParameters['hvn.uuid'] = requestParameters.hvnUuid;
        }
        if (requestParameters.hvnLocationRegionProvider !== undefined) {
            queryParameters['hvn.location.region.provider'] = requestParameters.hvnLocationRegionProvider;
        }
        if (requestParameters.hvnLocationRegionRegion !== undefined) {
            queryParameters['hvn.location.region.region'] = requestParameters.hvnLocationRegionRegion;
        }
        if (requestParameters.hvnDescription !== undefined) {
            queryParameters['hvn.description'] = requestParameters.hvnDescription;
        }
        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/network/2020-09-07/organizations/{hvn.location.organization_id}/projects/{hvn.location.project_id}/networks/{hvn.id}/transit-gateway-attachments`.replace(`{${"hvn.location.organization_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationOrganizationId))).replace(`{${"hvn.location.project_id"}}`, encodeURIComponent(String(requestParameters.hvnLocationProjectId))).replace(`{${"hvn.id"}}`, encodeURIComponent(String(requestParameters.hvnId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudNetwork20200907ListTGWAttachmentsResponseFromJSON(jsonValue));
    }
    /**
     * ListTGWAttachments returns a list of TGW Attachments matching the request
     */
    async listTGWAttachments(hvnLocationOrganizationId, hvnLocationProjectId, hvnId, hvnType, hvnUuid, hvnLocationRegionProvider, hvnLocationRegionRegion, hvnDescription, states, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken) {
        const response = await this.listTGWAttachmentsRaw({ hvnLocationOrganizationId: hvnLocationOrganizationId, hvnLocationProjectId: hvnLocationProjectId, hvnId: hvnId, hvnType: hvnType, hvnUuid: hvnUuid, hvnLocationRegionProvider: hvnLocationRegionProvider, hvnLocationRegionRegion: hvnLocationRegionRegion, hvnDescription: hvnDescription, states: states, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken });
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var ListTGWAttachmentsStatesEnum;
(function (ListTGWAttachmentsStatesEnum) {
    ListTGWAttachmentsStatesEnum["UNSET"] = "UNSET";
    ListTGWAttachmentsStatesEnum["CREATING"] = "CREATING";
    ListTGWAttachmentsStatesEnum["PENDINGACCEPTANCE"] = "PENDING_ACCEPTANCE";
    ListTGWAttachmentsStatesEnum["ACCEPTED"] = "ACCEPTED";
    ListTGWAttachmentsStatesEnum["ACTIVE"] = "ACTIVE";
    ListTGWAttachmentsStatesEnum["FAILED"] = "FAILED";
    ListTGWAttachmentsStatesEnum["REJECTED"] = "REJECTED";
    ListTGWAttachmentsStatesEnum["DELETING"] = "DELETING";
    ListTGWAttachmentsStatesEnum["EXPIRED"] = "EXPIRED";
})(ListTGWAttachmentsStatesEnum || (ListTGWAttachmentsStatesEnum = {}));
