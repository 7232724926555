import { BASIC_ROLE_KEY_UI_ONLY } from '../utils/parse-role-id.ts';

// Types
import type { IamEnrichedRole } from '../types/iam-enriched-role.ts';
import type { PermissionsMap } from '../types/permissions.ts';

export const uniqueServices = new Set<IamEnrichedRole['service']>([
  BASIC_ROLE_KEY_UI_ONLY,
]);

export default function iamFilterAvailableRoles(
  enrichedRoles: IamEnrichedRole[],
  permissionsMap: PermissionsMap,
): IamEnrichedRole[] {
  if (!enrichedRoles) {
    return [];
  }

  const permissions = Array.from(permissionsMap.values());

  const seenServices = permissions.reduce((acc, permission) => {
    if (permission.service) {
      const service = acc.get(permission.service);
      acc.set(permission.service, {
        count: (service ? service.count : 0) + 1,
      });
    }
    return acc;
  }, new Map<string, { count: number }>());

  const serviceThresholds = enrichedRoles.reduce((acc, role) => {
    if (role.service) {
      acc.set(role.service, (acc.get(role.service) || 0) + 1);
    }
    return acc;
  }, new Map<string, number>());

  const registeredRoleIds = permissions.reduce((acc, permission) => {
    if (permission.roleIds) {
      permission.roleIds.forEach((roleId: string) => acc.add(roleId));
    }
    return acc;
  }, new Set<string>());

  return enrichedRoles.filter((role) => {
    if (!role.service || (role.id && registeredRoleIds.has(role.id))) {
      return false;
    }

    const shouldOnlyDisplayOneRow = uniqueServices.has(role.service);

    const seenService = seenServices.get(role.service);

    if (role.service && seenService && shouldOnlyDisplayOneRow) {
      return false;
    }

    const threshold = serviceThresholds.get(role.service) || 0;

    return !seenService || seenService.count < threshold;
  });
}
