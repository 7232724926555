/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IDP Service
 * API for managing authentication sessions and SCIM settings.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudIdpListMySessionsResponseSessionFromJSON(json) {
    return HashicorpCloudIdpListMySessionsResponseSessionFromJSONTyped(json, false);
}
export function HashicorpCloudIdpListMySessionsResponseSessionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userAgent': !exists(json, 'user_agent') ? undefined : json['user_agent'],
        'ipAddress': !exists(json, 'ip_address') ? undefined : json['ip_address'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'lastSeen': !exists(json, 'last_seen') ? undefined : (new Date(json['last_seen'])),
        'current': !exists(json, 'current') ? undefined : json['current'],
    };
}
export function HashicorpCloudIdpListMySessionsResponseSessionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'user_agent': value.userAgent,
        'ip_address': value.ipAddress,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'last_seen': value.lastSeen === undefined ? undefined : (value.lastSeen.toISOString()),
        'current': value.current,
    };
}
