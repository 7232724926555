export class FormValidationError extends Error {
  details: FieldViolation[];

  constructor(message: string) {
    super(message);
    this.details = [];
  }
}

interface FieldViolationType {
  description: string;
  field: string;
}

export class FieldViolation {
  field_violations: FieldViolationType[];

  constructor(description = 'N/A', field = '') {
    this.field_violations = [{ description, field }];
  }
}
