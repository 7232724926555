/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * State is one of the states that an organization invitation can be in. The public and private
 * values for State do not map onto each other in a 1-to-1 way.
 *
 *  - PENDING: PENDING is the state of an organization invitation that has been created successfully but
 * has not been accepted yet.
 *  - ACCEPTED: ACCEPTED is the state of an organization invitation that has been accepted.
 * @export
 * @enum {string}
 */
export var HashicorpCloudIamOrganizationInvitationState;
(function (HashicorpCloudIamOrganizationInvitationState) {
    HashicorpCloudIamOrganizationInvitationState["PENDING"] = "PENDING";
    HashicorpCloudIamOrganizationInvitationState["ACCEPTED"] = "ACCEPTED";
})(HashicorpCloudIamOrganizationInvitationState || (HashicorpCloudIamOrganizationInvitationState = {}));
export function HashicorpCloudIamOrganizationInvitationStateFromJSON(json) {
    return HashicorpCloudIamOrganizationInvitationStateFromJSONTyped(json, false);
}
export function HashicorpCloudIamOrganizationInvitationStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudIamOrganizationInvitationStateToJSON(value) {
    return value;
}
