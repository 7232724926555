/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import { HashicorpCloudConsulTelemetry20230414MetricMatricesFromJSON, HashicorpCloudConsulTelemetry20230414MetricMatricesToJSON, } from './';
export function HashicorpCloudConsulTelemetry20230414QueryRangeBatchResponseFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414QueryRangeBatchResponseFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414QueryRangeBatchResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'results': !exists(json, 'results') ? undefined : (mapValues(json['results'], HashicorpCloudConsulTelemetry20230414MetricMatricesFromJSON)),
    };
}
export function HashicorpCloudConsulTelemetry20230414QueryRangeBatchResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'results': value.results === undefined ? undefined : (mapValues(value.results, HashicorpCloudConsulTelemetry20230414MetricMatricesToJSON)),
    };
}
