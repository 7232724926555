import Component from '@glimmer/component';

interface ZeroStateActionSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

/**
 *
 * `ZeroStateAction` displays the message element of the ZeroState screen.
 *
 *
 * ```
 * <ZeroState::Action>Action</ZeroState::Action>
 * ```
 *
 * @class ZeroStateAction
 *
 */

export default class ZeroStateActionComponent extends Component<ZeroStateActionSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ZeroState::Action': typeof ZeroStateActionComponent;
    'zero-state/action': typeof ZeroStateActionComponent;
  }
}
