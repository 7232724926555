/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Geo is a reference to all available Geos supported by
 * the billing service.
 *
 *  - GEO_UNSPECIFIED: GEO_UNSPECIFIED is an invalid Geo, which
 * is used to flag when the Geo was left blank.
 *  - GEO_US: GEO_US represents a US control-plane Geo.
 *  - GEO_EU: GEO_EU represents a US control-plane Geo.
 * @export
 * @enum {string}
 */
export var Billing20201105Geo;
(function (Billing20201105Geo) {
    Billing20201105Geo["UNSPECIFIED"] = "GEO_UNSPECIFIED";
    Billing20201105Geo["US"] = "GEO_US";
    Billing20201105Geo["EU"] = "GEO_EU";
})(Billing20201105Geo || (Billing20201105Geo = {}));
export function Billing20201105GeoFromJSON(json) {
    return Billing20201105GeoFromJSONTyped(json, false);
}
export function Billing20201105GeoFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Billing20201105GeoToJSON(value) {
    return value;
}
