import { HashicorpCloudIamPrincipalType } from '@clients/cloud-iam';
import type { HashicorpCloudIamPrincipal } from '@clients/cloud-iam';

export default function iamGetPrincipalDisplayName(
  principal: HashicorpCloudIamPrincipal,
): string | undefined {
  if (!principal) return;

  switch (principal.type) {
    case HashicorpCloudIamPrincipalType.SERVICE:
      return principal.service?.name;
    case HashicorpCloudIamPrincipalType.USER:
      return principal.user?.fullName || principal.user?.email;
    case HashicorpCloudIamPrincipalType.GROUP:
      return principal.group?.displayName;
  }
}
