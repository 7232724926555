import Service from '@ember/service';
import type { HashicorpCloudIamPrincipal } from '@clients/cloud-iam';

/**
 * This service acts as a key-value store with an added timestamp feature. It
 * uses a Map internally to manage the key-value pairs and attaches a
 * `_timestamp` to each entry to record when it was set. This can be useful for
 * checking the staleness of the data.
 *
 * @class PrincipalsService
 * @extends Service
 */
export default class PrincipalsService extends Service {
  private _map: Map<string, HashicorpCloudIamPrincipal> = new Map();

  set(key: string, value: HashicorpCloudIamPrincipal): void {
    this._map.set(key, value);
  }

  get(key: string): HashicorpCloudIamPrincipal | undefined {
    const entry = this._map.get(key);
    if (entry) {
      return entry;
    }
    return undefined;
  }

  delete(key: string): boolean {
    return this._map.delete(key);
  }

  has(key: string): boolean {
    return this._map.has(key);
  }
}
