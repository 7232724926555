/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudNetwork20200907AWSPeeringTargetFromJSON, HashicorpCloudNetwork20200907AWSPeeringTargetToJSON, HashicorpCloudNetwork20200907AzurePeeringTargetFromJSON, HashicorpCloudNetwork20200907AzurePeeringTargetToJSON, HashicorpCloudNetwork20200907NetworkTargetFromJSON, HashicorpCloudNetwork20200907NetworkTargetToJSON, } from './';
export function HashicorpCloudNetwork20200907PeeringTargetFromJSON(json) {
    return HashicorpCloudNetwork20200907PeeringTargetFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907PeeringTargetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'awsTarget': !exists(json, 'aws_target') ? undefined : HashicorpCloudNetwork20200907AWSPeeringTargetFromJSON(json['aws_target']),
        'hvnTarget': !exists(json, 'hvn_target') ? undefined : HashicorpCloudNetwork20200907NetworkTargetFromJSON(json['hvn_target']),
        'azureTarget': !exists(json, 'azure_target') ? undefined : HashicorpCloudNetwork20200907AzurePeeringTargetFromJSON(json['azure_target']),
    };
}
export function HashicorpCloudNetwork20200907PeeringTargetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aws_target': HashicorpCloudNetwork20200907AWSPeeringTargetToJSON(value.awsTarget),
        'hvn_target': HashicorpCloudNetwork20200907NetworkTargetToJSON(value.hvnTarget),
        'azure_target': HashicorpCloudNetwork20200907AzurePeeringTargetToJSON(value.azureTarget),
    };
}
