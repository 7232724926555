/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ForceSyncRequestOPERATION;
(function (ForceSyncRequestOPERATION) {
    ForceSyncRequestOPERATION["UPLOAD"] = "UPLOAD";
    ForceSyncRequestOPERATION["PURGE"] = "PURGE";
})(ForceSyncRequestOPERATION || (ForceSyncRequestOPERATION = {}));
export function ForceSyncRequestOPERATIONFromJSON(json) {
    return ForceSyncRequestOPERATIONFromJSONTyped(json, false);
}
export function ForceSyncRequestOPERATIONFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ForceSyncRequestOPERATIONToJSON(value) {
    return value;
}
