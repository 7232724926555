import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import IamPolicy from '../utils/iam-policy.ts';
import { MEMBER_TYPE_SERVICE_PRINCIPAL } from '../helpers/rbac-member-types.ts';
import { ROLE_KEY_CONTRIBUTOR } from '../helpers/rbac-roles.ts';
// import type ApiService from 'api/services/api';
import type AnalyticsService from '../services/analytics.ts';
import type {
  HashicorpCloudIamCreateOrganizationServicePrincipalKeyResponse,
  HashicorpCloudIamCreateOrganizationServicePrincipalResponse,
  HashicorpCloudIamCreateProjectServicePrincipalKeyResponse,
  HashicorpCloudIamCreateProjectServicePrincipalResponse,
} from '@clients/cloud-iam';
import {
  SERVICE_PRINCIPAL_SUBMITTED,
  SERVICE_PRINCIPAL_KEY_SUBMITTED,
} from '../utils/consts/analytics-events/platform.ts';
import type { HashicorpCloudResourcemanagerPolicy } from '@clients/cloud-resource-manager';
import {
  MAX_UPDATE_POLICY_RETRIES,
  type Client,
} from '../utils/consts/generate-service-principal-key-button.ts';

interface GenerateServicePrincipalKeyButtonSignature {
  Args: {
    /** name of the service principal to create */
    servicePrincipalName: string;
    organizationId: string;
    /** optional project id, will create project level service principals if provided  */
    projectId?: string;
    roleKey?: string;
    disabled?: boolean;
    /** optional callback that is called when the operation succeeds */
    onSuccess?: (client: Client) => void;
    /** optional callback that is called when the operation fails */
    onError?: (e?: unknown) => void;
    /** optional callback that is called when the button is clicked */
    onClick?: () => void;
    /** optionally overrides default button options*/
    icon?: string;
    text?: string;
    color?: string;
  };
}

interface IsUpdated {
  updated: boolean;
}

type CreateServicePrincipalResponse =
  | HashicorpCloudIamCreateOrganizationServicePrincipalResponse
  | HashicorpCloudIamCreateProjectServicePrincipalResponse;

type CreateServicePrincipalKeyResponse =
  | HashicorpCloudIamCreateOrganizationServicePrincipalKeyResponse
  | HashicorpCloudIamCreateProjectServicePrincipalKeyResponse;

/**
 *
 * `GenerateServicePrincipalKeyButton` renders a button that allows a user to generate a service principal and key.
 *
 *
 * ```
 * <GenerateServicePrincipalKeyButton
 *   @servicePrincipalName='new-service-principal-name'
 *   @projectId='projId'
 *   @roleKey='roles/viewer'
 *   @organizationId='orgId'
 *   @onClick={{this.onClickButton}}
 *   @onSuccess={{this.onServicePrincipalKeySuccess}}
 *   @onError={{this.onServicePrincipalKeyError}}
 *   @disabled={{false}}
 * />
 * ```
 *
 * @class GenerateServicePrincipalKeyButton
 *
 */

export default class GenerateServicePrincipalKeyButtonComponent extends Component<GenerateServicePrincipalKeyButtonSignature> {
  // @service declare readonly api: ApiService;
  // @ts-expect-error: replace with the above line once api addon is migrated to v2
  @service declare readonly api;
  @service declare readonly analytics: AnalyticsService;

  /** Called if there was an error during generation of service principal and key. */
  onError(e?: unknown) {
    const { onError } = this.args;
    onError && onError(e);
  }

  /** Called on button click. */
  onClick() {
    const { onClick } = this.args;
    onClick && onClick();
  }

  /** Called on successful generation of service principal and key. */
  onSuccess(clientIdAndSecret: Client) {
    const { onSuccess } = this.args;
    onSuccess && onSuccess(clientIdAndSecret);
  }

  /** This getter handles deciding whether or not to send calls to create project level service principals/keys
   * or organization level. It decides based on the presence of a projectId.
   */
  get apiAdapter() {
    const { servicePrincipalName, organizationId, projectId } = this.args;

    return {
      createServicePrincipal: () => {
        const payload = { name: servicePrincipalName };
        if (projectId) {
          return this.api.servicePrincipal.servicePrincipalsServiceCreateProjectServicePrincipal(
            organizationId,
            projectId,
            payload
          );
        }

        return this.api.servicePrincipal.servicePrincipalsServiceCreateOrganizationServicePrincipal(
          organizationId,
          payload
        );
      },

      deleteServicePrincipal: (servicePrincipalId: string) => {
        if (projectId) {
          return this.api.servicePrincipal.servicePrincipalsServiceDeleteProjectServicePrincipal(
            organizationId,
            projectId,
            servicePrincipalId
          );
        }

        return this.api.servicePrincipal.servicePrincipalsServiceDeleteOrganizationServicePrincipal(
          organizationId,
          servicePrincipalId
        );
      },

      createServicePrincipalKey: (servicePrincipalId: string) => {
        if (projectId) {
          return this.api.servicePrincipal.servicePrincipalsServiceCreateProjectServicePrincipalKey(
            organizationId,
            projectId,
            {
              principalId: servicePrincipalId,
            }
          );
        }
        return this.api.servicePrincipal.servicePrincipalsServiceCreateOrganizationServicePrincipalKey(
          organizationId,
          {
            principalId: servicePrincipalId,
          }
        );
      },

      getIamPolicy: () => {
        if (projectId) {
          return this.api.resourceManager.project.projectServiceGetIamPolicy(
            projectId
          );
        }

        return this.api.resourceManager.org.organizationServiceGetIamPolicy(
          organizationId
        );
      },
      setIamPolicy: (policyPayload: {
        policy: HashicorpCloudResourcemanagerPolicy;
      }) => {
        if (projectId) {
          return this.api.resourceManager.project.projectServiceSetIamPolicy(
            projectId,
            policyPayload
          );
        }
        return this.api.resourceManager.org.organizationServiceSetIamPolicy(
          organizationId,
          policyPayload
        );
      },
    };
  }

  /**
   * This task attempts to create a service principal, update the IAM policy, and generate a key.
   * If any of the above fails, previous steps will attempt to be rolled back.
   * `dropTask` is used here to ensure that only one instance of the task runs at a time and any that
   * are attempted while the task is already running will be dropped.
   */
  @dropTask
  *generate() {
    this.onClick();

    let createResponse: CreateServicePrincipalResponse;
    try {
      createResponse = yield this.apiAdapter.createServicePrincipal();
      this.analytics.trackEvent(SERVICE_PRINCIPAL_SUBMITTED, {
        servicePrincipalId: createResponse?.servicePrincipal?.id,
      });
    } catch (e) {
      Sentry.captureException(e);
      return this.onError(e);
    }

    // If an error is thrown it will be handled by the WithError component.
    // If we simply fail to update the IAM policy we will notify the user and retrieve an updated list.
    const { updated } = yield this.addServicePrincipalToPolicy(
      createResponse.servicePrincipal?.id || '',
      this.args.roleKey ?? ROLE_KEY_CONTRIBUTOR,
      MAX_UPDATE_POLICY_RETRIES
    );

    if (updated) {
      // Create service principal key
      let clientIdAndSecret: Client;
      try {
        clientIdAndSecret = yield this.generateKey(
          createResponse.servicePrincipal?.id || ''
        );
      } catch (e) {
        Sentry.captureException(e);
        // Undo update policy
        try {
          yield this.removeServicePrincipalFromPolicy(
            createResponse.servicePrincipal?.id || '',
            MAX_UPDATE_POLICY_RETRIES
          );
          yield this.deleteServicePrincipal(
            createResponse.servicePrincipal?.id || ''
          );
        } catch (e) {
          Sentry.captureException(e);
        }
        return this.onError(e);
      }

      // Success!
      return this.onSuccess(clientIdAndSecret);
    } else {
      // Delete service principal
      try {
        yield this.deleteServicePrincipal(
          createResponse.servicePrincipal?.id || ''
        );
      } catch (e) {
        Sentry.captureException(e);
      }
      return this.onError();
    }
  }

  /**
   * Adds a new member binding to a policy and attempts to update the policy.
   * If the policy has been updated concurrently, it will retry up to maxRetries.
   */
  async addServicePrincipalToPolicy(
    servicePrincipalId: string,
    /** the role the service principal should be added with */
    roleId: string,
    /** number of times the operation will be retried in case of concurrent update */
    maxRetries: number
  ): Promise<IsUpdated> {
    for (let i = 0; i < maxRetries; i++) {
      try {
        // Get the latest policy in order to get the latest etag to protect
        // from concurrent updates.
        const res = await this.apiAdapter.getIamPolicy();
        const { policy } = res;
        if (!policy) {
          throw new Error();
        }

        // Given the latest policy, attempt to add the service principal with the specified role.
        const policyToUpdate = new IamPolicy(policy);
        policyToUpdate.addMember(
          servicePrincipalId,
          MEMBER_TYPE_SERVICE_PRINCIPAL.value,
          roleId
        );

        const updatedPolicy = policyToUpdate.get();
        const policyPayload = {
          policy: {
            ...updatedPolicy,
          },
        };

        await this.apiAdapter.setIamPolicy(policyPayload);

        return {
          updated: true,
        };
      } catch (e) {
        const { status } = e as { status: number };
        // A conflict indicates a concurrent update.
        // We may just need try again.
        if (status === 409) {
          continue;
        }

        // Other errors are not retried
        return {
          updated: false,
        };
      }
    }

    // If we use up all retries without success response
    return {
      updated: false,
    };
  }

  /**
   * Removes a member binding from a policy and attempts to update the policy.
   * If the policy has been updated concurrently, it will retry up to maxRetries.
   */
  async removeServicePrincipalFromPolicy(
    servicePrincipalId: string,
    /** number of times the operation will be retried in case of concurrent update */
    maxRetries: number
  ): Promise<IsUpdated> {
    for (let i = 0; i < maxRetries; i++) {
      try {
        // Get the latest policy in order to get the latest etag to protect
        // from concurrent updates.
        const { policy } = await this.apiAdapter.getIamPolicy();

        if (!policy) {
          throw new Error();
        }

        // Given the latest policy, attempt to remove the service principal
        const policyToUpdate = new IamPolicy(policy);
        policyToUpdate.removeMemberById(servicePrincipalId);

        const updatedPolicy = policyToUpdate.get();
        const policyPayload = {
          policy: {
            ...updatedPolicy,
          },
        };

        await this.apiAdapter.setIamPolicy(policyPayload);

        return {
          updated: true,
        };
      } catch (e) {
        const { status } = e as { status: number };

        // A conflict indicates a concurrent update.
        // We may just need try again.
        if (status === 409) {
          continue;
        }

        return {
          updated: false,
        };
      }
    }

    return {
      updated: false,
    };
  }

  /** Generates a new service principal key. */
  async generateKey(servicePrincipalId: string): Promise<Client> {
    const newKeyResponse: CreateServicePrincipalKeyResponse =
      await this.apiAdapter.createServicePrincipalKey(servicePrincipalId);
    this.analytics.trackEvent(SERVICE_PRINCIPAL_KEY_SUBMITTED, {
      servicePrincipalId,
    });

    return {
      clientId: newKeyResponse.key?.clientId || '',
      clientSecret: newKeyResponse.clientSecret || '',
    };
  }

  /** Deletes a service principal. */
  async deleteServicePrincipal(servicePrincipalId: string) {
    return await this.apiAdapter.deleteServicePrincipal(servicePrincipalId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    GenerateServicePrincipalKeyButton: typeof GenerateServicePrincipalKeyButtonComponent;
    'generate-service-principal-key-button': typeof GenerateServicePrincipalKeyButtonComponent;
  }
}
