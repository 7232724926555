/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * State is the state of the Consul cluster. Note that this state
 * represents the abstract Consul cluster itself, not necessarily whether
 * Consul cluster is currently available or not.
 *
 *  - UNSET: UNSET is a sentinel zero value so that an uninitialized value can be
 * detected.
 *  - PENDING: PENDING is the state the cluster is in while it is waiting to be created.
 *  - CREATING: CREATING is the state the cluster is in while it is being provisioned for
 * the first time.
 *  - RUNNING: RUNNING is the steady state while the cluster is running.
 *  - FAILED: FAILED is a failure state in which the cluster is unavailable and may
 * required an operator restore action to recover.
 *  - UPDATING: UPDATING is the state the cluster is in while undergoing a version
 * update.
 *  - RESTORING: RESTORING is the state the cluster is in while restoring from a snapshot.
 *  - DELETING: DELETING is the state the cluster is in while it is being de-provisioned.
 *  - DELETED: DELETED is the state the cluster is in when it has been de-provisioned. At
 * this point, the cluster is eligible for garbage collection.
 * @export
 * @enum {string}
 */
export var HashicorpCloudConsul20210204ClusterState;
(function (HashicorpCloudConsul20210204ClusterState) {
    HashicorpCloudConsul20210204ClusterState["UNSET"] = "UNSET";
    HashicorpCloudConsul20210204ClusterState["PENDING"] = "PENDING";
    HashicorpCloudConsul20210204ClusterState["CREATING"] = "CREATING";
    HashicorpCloudConsul20210204ClusterState["RUNNING"] = "RUNNING";
    HashicorpCloudConsul20210204ClusterState["FAILED"] = "FAILED";
    HashicorpCloudConsul20210204ClusterState["UPDATING"] = "UPDATING";
    HashicorpCloudConsul20210204ClusterState["RESTORING"] = "RESTORING";
    HashicorpCloudConsul20210204ClusterState["DELETING"] = "DELETING";
    HashicorpCloudConsul20210204ClusterState["DELETED"] = "DELETED";
})(HashicorpCloudConsul20210204ClusterState || (HashicorpCloudConsul20210204ClusterState = {}));
export function HashicorpCloudConsul20210204ClusterStateFromJSON(json) {
    return HashicorpCloudConsul20210204ClusterStateFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204ClusterStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudConsul20210204ClusterStateToJSON(value) {
    return value;
}
