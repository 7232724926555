/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105StatementOverviewFromJSON, Billing20201105StatementOverviewToJSON, } from './';
export function StatementStatementMetadataFromJSON(json) {
    return StatementStatementMetadataFromJSONTyped(json, false);
}
export function StatementStatementMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'previousStatement': !exists(json, 'previous_statement') ? undefined : Billing20201105StatementOverviewFromJSON(json['previous_statement']),
        'nextStatement': !exists(json, 'next_statement') ? undefined : Billing20201105StatementOverviewFromJSON(json['next_statement']),
    };
}
export function StatementStatementMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'previous_statement': Billing20201105StatementOverviewToJSON(value.previousStatement),
        'next_statement': Billing20201105StatementOverviewToJSON(value.nextStatement),
    };
}
