/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudWaypointActionConfigFlavorGitHubMethod;
(function (HashicorpCloudWaypointActionConfigFlavorGitHubMethod) {
    HashicorpCloudWaypointActionConfigFlavorGitHubMethod["CREATE"] = "CREATE";
    HashicorpCloudWaypointActionConfigFlavorGitHubMethod["RERUN"] = "RERUN";
    HashicorpCloudWaypointActionConfigFlavorGitHubMethod["RERUNFAILED"] = "RERUNFAILED";
    HashicorpCloudWaypointActionConfigFlavorGitHubMethod["CANCEL"] = "CANCEL";
    HashicorpCloudWaypointActionConfigFlavorGitHubMethod["APPROVE"] = "APPROVE";
    HashicorpCloudWaypointActionConfigFlavorGitHubMethod["DELETE"] = "DELETE";
})(HashicorpCloudWaypointActionConfigFlavorGitHubMethod || (HashicorpCloudWaypointActionConfigFlavorGitHubMethod = {}));
export function HashicorpCloudWaypointActionConfigFlavorGitHubMethodFromJSON(json) {
    return HashicorpCloudWaypointActionConfigFlavorGitHubMethodFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointActionConfigFlavorGitHubMethodFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudWaypointActionConfigFlavorGitHubMethodToJSON(value) {
    return value;
}
