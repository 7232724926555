/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Telemetry Gateway Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2023-04-14
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * service topology errors are returned with the graph on failures to gather metadata for some part(s) of the graph.
 *
 *  - ERROR_NODE_SERVICE: node service error is returned if we failed to get service metadata from GNM for some/all of the services.
 *  - ERROR_NODE_CLUSTER: node cluster error is returned if we failed to get cluster-level metadata from GNM for some/all of the services' clusters.
 *  - ERROR_NODE_METRICS: node metric error is returned if we failed to get service metrics for connections/requests into services from all other services.
 *  - ERROR_EDGE_METRICS: edge metric error is returned if we failed to get connection/request metrics between services.
 *  - ERROR_EDGE_INTENTIONS: edge intentions error is returned if we failed to get intentions from GNM.
 * @export
 * @enum {string}
 */
export var HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusError;
(function (HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusError) {
    HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusError["UNSPECIFIED"] = "ERROR_UNSPECIFIED";
    HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusError["NODESERVICE"] = "ERROR_NODE_SERVICE";
    HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusError["NODECLUSTER"] = "ERROR_NODE_CLUSTER";
    HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusError["NODEMETRICS"] = "ERROR_NODE_METRICS";
    HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusError["EDGEMETRICS"] = "ERROR_EDGE_METRICS";
    HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusError["EDGEINTENTIONS"] = "ERROR_EDGE_INTENTIONS";
})(HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusError || (HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusError = {}));
export function HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusErrorFromJSON(json) {
    return HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusErrorFromJSONTyped(json, false);
}
export function HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusErrorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudConsulTelemetry20230414GetServiceTopologyStatusErrorToJSON(value) {
    return value;
}
