import Service, { inject as service } from '@ember/service';
import * as Sentry from '@sentry/ember';
import type CurrentUserService from '../current-user.ts';

interface MetadataRecord {
  identifier?: string;
}

export default class MetadataLocalStorageService extends Service {
  @service declare readonly currentUser: CurrentUserService;

  storageKey() {
    const { id: userId } = this.currentUser?.user || {};
    return `hcp.metadata.user:${userId}`;
  }

  // simple get is just a wrapper around localStorage
  async get(key: string) {
    const k = key || this.storageKey();
    const value = localStorage.getItem(k);
    let returnVal;

    if (value) {
      try {
        returnVal = JSON.parse(value);
      } catch (e) {
        console.log(e);
        Sentry.captureException(e);
      }
    }
    return returnVal;
  }

  async set(key: string, value: unknown) {
    const k = key || this.storageKey();

    try {
      localStorage.setItem(k, JSON.stringify(value));
      return value;
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      return null;
    }
  }

  async getMetadata() {
    let metadataString;

    try {
      metadataString = localStorage.getItem(this.storageKey());
    } catch (e) {
      console.log(e);
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage#exceptions
      Sentry.captureException(e);
    }

    let metadata = {};

    try {
      if (metadataString) {
        metadata = JSON.parse(metadataString);
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }

    return metadata;
  }

  async setMetadata(record: MetadataRecord) {
    record.identifier = this.currentUser?.user?.id;
    const metadataString = JSON.stringify(record);
    const key = this.storageKey();

    try {
      localStorage.setItem(key, metadataString);
      return metadataString;
    } catch (e) {
      console.log(e);
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage#exceptions
      Sentry.captureException(e);
      return null;
    }
  }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'metadata.local-storage': MetadataLocalStorageService;
  }
}
