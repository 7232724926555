/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125GetAvailableTemplatesResponseTemplateFromJSON, HashicorpCloudVault20201125GetAvailableTemplatesResponseTemplateToJSON, } from './';
export function HashicorpCloudVault20201125GetAvailableTemplatesResponseFromJSON(json) {
    return HashicorpCloudVault20201125GetAvailableTemplatesResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125GetAvailableTemplatesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'templates': !exists(json, 'templates') ? undefined : (json['templates'].map(HashicorpCloudVault20201125GetAvailableTemplatesResponseTemplateFromJSON)),
    };
}
export function HashicorpCloudVault20201125GetAvailableTemplatesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'templates': value.templates === undefined ? undefined : (value.templates.map(HashicorpCloudVault20201125GetAvailableTemplatesResponseTemplateToJSON)),
    };
}
