/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudOperationOperationFromJSON, HashicorpCloudOperationOperationToJSON, HashicorpCloudVagrant20220930BoxFromJSON, HashicorpCloudVagrant20220930BoxToJSON, } from './';
export function HashicorpCloudVagrant20220930UpdateBoxResponseFromJSON(json) {
    return HashicorpCloudVagrant20220930UpdateBoxResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930UpdateBoxResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'box': !exists(json, 'box') ? undefined : HashicorpCloudVagrant20220930BoxFromJSON(json['box']),
        'operation': !exists(json, 'operation') ? undefined : HashicorpCloudOperationOperationFromJSON(json['operation']),
    };
}
export function HashicorpCloudVagrant20220930UpdateBoxResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'box': HashicorpCloudVagrant20220930BoxToJSON(value.box),
        'operation': HashicorpCloudOperationOperationToJSON(value.operation),
    };
}
