/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudIamCreateAuthConnectionResponseFromJSON, HashicorpCloudIamGetAuthConnectionsResponseFromJSON, InlineObject4ToJSON, InlineObject5ToJSON, } from '../models';
/**
 *
 */
export class AuthConfigServiceApi extends runtime.BaseAPI {
    /**
     * CreateAuthConnection creates an auth connection.
     */
    async authConfigServiceCreateAuthConnectionRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling authConfigServiceCreateAuthConnection.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling authConfigServiceCreateAuthConnection.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/auth_connections`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject4ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamCreateAuthConnectionResponseFromJSON(jsonValue));
    }
    /**
     * CreateAuthConnection creates an auth connection.
     */
    async authConfigServiceCreateAuthConnection(organizationId, body) {
        const response = await this.authConfigServiceCreateAuthConnectionRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * DeleteAuthConnectionFromOrganization permanently deletes a persisted AuthConnection.
     */
    async authConfigServiceDeleteAuthConnectionFromOrganizationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling authConfigServiceDeleteAuthConnectionFromOrganization.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/auth_connections`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteAuthConnectionFromOrganization permanently deletes a persisted AuthConnection.
     */
    async authConfigServiceDeleteAuthConnectionFromOrganization(organizationId) {
        const response = await this.authConfigServiceDeleteAuthConnectionFromOrganizationRaw({ organizationId: organizationId });
        return await response.value();
    }
    /**
     * EditAuthConnection edits an existing AuthConnection.
     */
    async authConfigServiceEditAuthConnectionRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling authConfigServiceEditAuthConnection.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling authConfigServiceEditAuthConnection.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/auth_connections`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject5ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * EditAuthConnection edits an existing AuthConnection.
     */
    async authConfigServiceEditAuthConnection(organizationId, body) {
        const response = await this.authConfigServiceEditAuthConnectionRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     */
    async authConfigServiceGetAuthConnectionsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling authConfigServiceGetAuthConnections.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/auth_connections`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetAuthConnectionsResponseFromJSON(jsonValue));
    }
    /**
     */
    async authConfigServiceGetAuthConnections(organizationId) {
        const response = await this.authConfigServiceGetAuthConnectionsRaw({ organizationId: organizationId });
        return await response.value();
    }
}
