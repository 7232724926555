import templateOnlyComponent from '@ember/component/template-only';

interface MenuContentSignature {
  Args: {
    isOpen: unknown;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

const MenuContentComponent = templateOnlyComponent<MenuContentSignature>();

export default MenuContentComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Menu::Content': typeof MenuContentComponent;
    'menu/content': typeof MenuContentComponent;
  }
}
