import Component from '@glimmer/component';
import { typeOf } from '@ember/utils';
import { assert } from '@ember/debug';

interface GuidedStepperSignature {
  Args: {
    currentStep: number | string;
  };
  Blocks: {
    tabs: [unknown];
  };
  Element: HTMLDivElement;
}

/**
 * `GuidedStepper` component is used to structure a sequential user flow broken
 * down into phases.
 *
 * Each step must be completed sequentially and marked as so.
 *
 * @class GuidedStepper
 */

/**
 * `@currentStep` The step the stepper is currently on.
 *
 * @argument @currentStep
 * @type {Number | String}
 */
export default class GuidedStepperComponent extends Component<GuidedStepperSignature> {
  get currentStep() {
    // Steps are *not* necessarily zero-based.
    const { currentStep } = this.args;

    assert(
      `@currentStep must be a string or a number.`,
      typeOf(currentStep) === 'string' || typeOf(currentStep) === 'number'
    );

    // Ensure this currentStep is parsed to a Number.
    return parseInt(currentStep as string, 10);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    GuidedStepper: typeof GuidedStepperComponent;
    'guided-stepper': typeof GuidedStepperComponent;
  }
}
