import NO_ROLE_ROLE from '../utils/no-role-role.ts';

import type { HashicorpCloudResourcemanagerRole } from '@clients/cloud-resource-manager';

type RoleIdIconMapping = {
  [roleId: string]: string;
};

export const roleIdIconMapping: RoleIdIconMapping = {
  [NO_ROLE_ROLE.id]: 'user-x',
};

export default function iamGetIconForRoleId(
  roleId: HashicorpCloudResourcemanagerRole['id'],
): string | undefined {
  if (!roleId) {
    return;
  }

  return roleIdIconMapping[roleId];
}
