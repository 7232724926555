/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - TRIAL_STATUS_UNSPECIFIED: trial status is not specified
 *  - TRIAL_STATUS_UNSTARTED: trial has not started
 *  - TRIAL_STATUS_IN_PROGRESS: trial is in progress
 *  - TRIAL_STATUS_EXPIRED: trial is expired
 *  - TRIAL_STATUS_INELIGIBLE: organization is ineligible for a trial
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215TrialStatus;
(function (HashicorpCloudGlobalNetworkManager20220215TrialStatus) {
    HashicorpCloudGlobalNetworkManager20220215TrialStatus["UNSPECIFIED"] = "TRIAL_STATUS_UNSPECIFIED";
    HashicorpCloudGlobalNetworkManager20220215TrialStatus["UNSTARTED"] = "TRIAL_STATUS_UNSTARTED";
    HashicorpCloudGlobalNetworkManager20220215TrialStatus["INPROGRESS"] = "TRIAL_STATUS_IN_PROGRESS";
    HashicorpCloudGlobalNetworkManager20220215TrialStatus["EXPIRED"] = "TRIAL_STATUS_EXPIRED";
    HashicorpCloudGlobalNetworkManager20220215TrialStatus["INELIGIBLE"] = "TRIAL_STATUS_INELIGIBLE";
})(HashicorpCloudGlobalNetworkManager20220215TrialStatus || (HashicorpCloudGlobalNetworkManager20220215TrialStatus = {}));
export function HashicorpCloudGlobalNetworkManager20220215TrialStatusFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215TrialStatusFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215TrialStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215TrialStatusToJSON(value) {
    return value;
}
