/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudIamGroupMemberPrincipalType;
(function (HashicorpCloudIamGroupMemberPrincipalType) {
    HashicorpCloudIamGroupMemberPrincipalType["UNSPECIFIED"] = "GROUP_MEMBER_PRINCIPAL_TYPE_UNSPECIFIED";
    HashicorpCloudIamGroupMemberPrincipalType["USER"] = "GROUP_MEMBER_PRINCIPAL_TYPE_USER";
})(HashicorpCloudIamGroupMemberPrincipalType || (HashicorpCloudIamGroupMemberPrincipalType = {}));
export function HashicorpCloudIamGroupMemberPrincipalTypeFromJSON(json) {
    return HashicorpCloudIamGroupMemberPrincipalTypeFromJSONTyped(json, false);
}
export function HashicorpCloudIamGroupMemberPrincipalTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudIamGroupMemberPrincipalTypeToJSON(value) {
    return value;
}
