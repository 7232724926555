import Component from '@glimmer/component';
import type { ComponentLike } from '@glint/template';

interface PresentationalTabsSignature {
  Args: {
    defaultFocus?: unknown;
    defaultIndex?: unknown;
    onSelect?: unknown;
    selectedIndex?: unknown;
    selectedTabClassName?: unknown;
    selectedTabPanelClassName?: unknown;
  };
  Blocks: {
    default: [
      {
        tabList: ComponentLike<{
          Element: HTMLElement;
          Blocks: {
            default: [
              {
                tab: ComponentLike<{
                  Element: HTMLElement;
                  Args: {
                    id?: unknown;
                    index?: unknown;
                    isSelected?: unknown;
                    onSelect?: unknown;
                  };
                  Blocks: {
                    default: [unknown];
                  };
                }>;
              },
            ];
          };
        }>;
        tabPanel: ComponentLike<{
          Element: HTMLElement;
          Blocks: {
            default: [unknown];
          };
        }>;
      },
    ];
  };
  Element: HTMLElement;
}

/**
 *
 * `PresentationalTabs` is a presentational (as opposed to navigational) tabs component.
 * It is a thin wrapper around ember-aria-tabs https://github.com/concordnow/ember-aria-tabs
 * and adds a data-test attribute as well as HCP styling.
 *
 * It provides the exact same usage API as ember-aria-tabs.
 * Further documentation available here https://concordnow.github.io/ember-aria-tabs/#/docs
 *
 *
 * ```
 * <PresentationalTabs @onSelect={{this.onSelect}} as |at|>
 *   <at.tabList as |tl|>
 *     <tl.tab>Title 1</tl.tab>
 *     <tl.tab>Title 2</tl.tab>
 *   </at.tabList>
 *   <at.tabPanel>
 *     <h2>Any content 1</h2>
 *   </at.tabPanel>
 *   <at.tabPanel>
 *     <h2>Any content 2</h2>
 *   </at.tabPanel>
 * </PresentationalTabs>
 * ```
 *
 * @class PresentationalTabs
 *
 */

export default class PresentationalTabsComponent extends Component<PresentationalTabsSignature> {
  /**
   *
   * `onSelect` https://concordnow.github.io/ember-aria-tabs/#/docs/components/aria-tabs
   * @argument onSelect
   * @type {?Function}
   *
   */
  /**
   *
   * `defaultIndex` https://concordnow.github.io/ember-aria-tabs/#/docs/components/aria-tabs
   * @argument defaultIndex
   * @type {?boolean}
   *
   */
  /**
   *
   * `defaultFocus` https://concordnow.github.io/ember-aria-tabs/#/docs/components/aria-tabs
   * @argument defaultFocus
   * @type {?boolean}
   *
   */
  /**
   *
   * `selectedIndex` https://concordnow.github.io/ember-aria-tabs/#/docs/components/aria-tabs
   * @argument selectedIndex
   * @type {?boolean}
   *
   */
  /**
   *
   * `selectedTabClassName` https://concordnow.github.io/ember-aria-tabs/#/docs/components/aria-tabs
   * @argument selectedTabClassName
   * @type {?string}
   *
   */
  /**
   *
   * `selectedTabPanelClassName` https://concordnow.github.io/ember-aria-tabs/#/docs/components/aria-tabs
   * @argument selectedTabPanelClassName
   * @type {?string}
   *
   */
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PresentationalTabs: typeof PresentationalTabsComponent;
    'presentational-tabs': typeof PresentationalTabsComponent;
  }
}
