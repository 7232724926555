import Component from '@glimmer/component';

/**
 *
 * `NetworksCidrRecommendations` contain the list of recommendations on selecting an appropriate CIDR block for an HVN.
 *
 *
 * ```
 * <NetworksCidrRecommendations />
 * ```
 *
 * @class NetworksCidrRecommendations
 *
 */

export default class NetworksCidrRecommendationsComponent extends Component {}
