/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * PrincipalType represents the possible types of principals.
 *
 *  - PRINCIPAL_TYPE_UNSPECIFIED: PRINCIPAL_TYPE_UNSPECIFIED is the default value.
 *  - PRINCIPAL_TYPE_USER: PRINCIPAL_TYPE_USER is the user principal type.
 *  - PRINCIPAL_TYPE_SERVICE: PRINCIPAL_TYPE_SERVICE is the service principal type.
 *  - PRINCIPAL_TYPE_GROUP: PRINCIPAL_TYPE_GROUP is the group principal type.
 *  - PRINCIPAL_TYPE_INTERNAL_OPERATOR: PRINCIPAL_TYPE_INTERNAL_OPERATOR is the internal operator principal type.
 * @export
 * @enum {string}
 */
export var IamPrincipalType;
(function (IamPrincipalType) {
    IamPrincipalType["UNSPECIFIED"] = "PRINCIPAL_TYPE_UNSPECIFIED";
    IamPrincipalType["USER"] = "PRINCIPAL_TYPE_USER";
    IamPrincipalType["SERVICE"] = "PRINCIPAL_TYPE_SERVICE";
    IamPrincipalType["GROUP"] = "PRINCIPAL_TYPE_GROUP";
    IamPrincipalType["INTERNALOPERATOR"] = "PRINCIPAL_TYPE_INTERNAL_OPERATOR";
})(IamPrincipalType || (IamPrincipalType = {}));
export function IamPrincipalTypeFromJSON(json) {
    return IamPrincipalTypeFromJSONTyped(json, false);
}
export function IamPrincipalTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function IamPrincipalTypeToJSON(value) {
    return value;
}
