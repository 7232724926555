/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudPacker20221202UpdateChannelBodyRestrictionFromJSON, HashicorpCloudPacker20221202UpdateChannelBodyRestrictionToJSON, } from './';
export function HashicorpCloudPacker20221202UpdateChannelBodyFromJSON(json) {
    return HashicorpCloudPacker20221202UpdateChannelBodyFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202UpdateChannelBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'incrementalVersion': !exists(json, 'incremental_version') ? undefined : json['incremental_version'],
        'iterationId': !exists(json, 'iteration_id') ? undefined : json['iteration_id'],
        'fingerprint': !exists(json, 'fingerprint') ? undefined : json['fingerprint'],
        'restriction': !exists(json, 'restriction') ? undefined : HashicorpCloudPacker20221202UpdateChannelBodyRestrictionFromJSON(json['restriction']),
        'mask': !exists(json, 'mask') ? undefined : json['mask'],
    };
}
export function HashicorpCloudPacker20221202UpdateChannelBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'incremental_version': value.incrementalVersion,
        'iteration_id': value.iterationId,
        'fingerprint': value.fingerprint,
        'restriction': HashicorpCloudPacker20221202UpdateChannelBodyRestrictionToJSON(value.restriction),
        'mask': value.mask,
    };
}
