import templateString from './template-string.ts';
import SystemNotificationColors from 'common/utils/system-notification-colors';
import type { GeoRegion } from './v2-resource.ts';

export const SYSTEM_TYPE = 'system';

export interface SystemNotification {
  id: string;
  priority: number;
  color: string;
  content: string;
  actionText?: string;
  message?: string;
}

export const SystemNotifications: Readonly<Record<string, SystemNotification>> =
  Object.freeze({
    FCP_LOW_BALANCE: {
      id: 'system-notification-fcp-low-balance',
      priority: 200,
      color: SystemNotificationColors.WARNING,
      message: 'system-notifications.fcp-low-balance.message',
      content: 'system-notifications.fcp-low-balance.content',
    },
    LOW_BALANCE: {
      id: 'system-notification-low-balance',
      priority: 200,
      color: SystemNotificationColors.WARNING,
      message: 'system-notifications.low-balance.message',
      content: 'system-notifications.low-balance.content',
      actionText: 'system-notifications.low-balance.action-text',
    },
    CONTRACT_EXPIRING: {
      id: 'system-notification-contract-expiring',
      priority: 200,
      color: SystemNotificationColors.WARNING,
      message: 'system-notifications.contract-expiring.message',
      content: 'system-notifications.contract-expiring.content',
    },
    CONTRACT_EXPIRED: {
      id: 'system-notification-contract-expired',
      priority: 300,
      color: SystemNotificationColors.CRITICAL,
      message: 'system-notifications.contract-expired.message',
      content: 'system-notifications.contract-expired.content',
    },
    NO_PAYMENT_METHOD: {
      id: 'system-notification-no-payment-method',
      priority: 300,
      color: SystemNotificationColors.CRITICAL,
      message: 'system-notifications.no-payment-method.message',
      content: 'system-notifications.no-payment-method.content',
      actionText: 'system-notifications.no-payment-method.action-text',
    },
    ZERO_BALANCE: {
      id: 'system-notification-zero-balance',
      priority: 300,
      color: SystemNotificationColors.CRITICAL,
      message: 'system-notifications.zero-balance.message',
      content: 'system-notifications.zero-balance.content',
      actionText: 'system-notifications.zero-balance.action-text',
    },
    FCP_ZERO_BALANCE: {
      id: 'system-notification-fcp-zero-balance',
      priority: 400,
      color: SystemNotificationColors.CRITICAL,
      message: 'system-notifications.fcp-zero-balance.message',
      content: 'system-notifications.fcp-zero-balance.content',
      actionText: 'system-notifications.fcp-depleted-balance.action-text',
    },
    CARD_ERROR: {
      id: 'system-notification-card-error',
      priority: 500,
      color: SystemNotificationColors.CRITICAL,
      message: 'system-notifications.card-error.message',
      content: 'system-notifications.card-error.content',
      actionText: 'system-notifications.card-error.action-text',
    },
    ACCESS_RESTRICTED: {
      id: 'system-notification-access-restricted',
      priority: 500,
      color: SystemNotificationColors.CRITICAL,
      icon: 'shield-alert',
      message: 'system-notifications.access-restricted.message',
      content: 'system-notifications.access-restricted.content',
      linkText: 'system-notifications.access-restricted.link-text',
      linkIcon: 'help',
      actionText: 'system-notifications.access-restricted.action-text',
      actionIcon: 'swap-horizontal',
    },
  });

export const TERRAFORM_IMPORTS = {
  awsPeering: templateString`terraform import hcp_aws_network_peering.<RESOURCE_NAME> ${'hvnId'}:${'peeringId'}`,
  awsTransitGateway: templateString`terraform import hcp_aws_transit_gateway_attachment.<RESOURCE_NAME> ${'hvnId'}:${'transitGatewayId'}:<AWS_RESOURCE_SHARE_ARN>`,
  azurePeering: templateString`terraform import hcp_azure_peering_connection.<RESOURCE_NAME> ${'hvnId'}:${'peeringId'}`,
  hvnPeering: templateString`terraform import hcp_hvn_peering_connection.<RESOURCE_NAME> ${'hvnId'}:${'peeringId'}`,
  consul: templateString`terraform import hcp_consul_cluster.<RESOURCE_NAME> ${'id'}`,
  hvn: templateString`terraform import hcp_hvn.<RESOURCE_NAME> ${'id'}`,
  vault: templateString`terraform import hcp_vault_cluster.<RESOURCE_NAME> ${'id'}`,
};

/** @typedef {('operational'|'degraded_performance'|'partial_outage'|'major_outage'|'under_maintenance')} StatuspageIoStatus */

/** @type {StatuspageIoStatus} */
export const STATUSPAGE_IO_STATUS = {
  OPERATIONAL: 'operational',
  DEGRADED_PERFORMANCE: 'degraded_performance',
  PARTIAL_OUTAGE: 'partial_outage',
  MAJOR_OUTAGE: 'major_outage',
  UNDER_MAINTENANCE: 'under_maintenance',
};

export const TFC_GEO_REGIONS = {
  REGION_US: 'us' as GeoRegion,
  REGION_EU: 'eu' as GeoRegion,
};
