/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionConfigFromJSON, HashicorpCloudWaypointActionConfigToJSON, HashicorpCloudWaypointActionRunFromJSON, HashicorpCloudWaypointActionRunToJSON, HashicorpCloudWaypointUIActionConfigBundleApplicationAssignmentFromJSON, HashicorpCloudWaypointUIActionConfigBundleApplicationAssignmentToJSON, HashicorpCloudWaypointUIActionConfigBundleApplicationTemplateAssignmentFromJSON, HashicorpCloudWaypointUIActionConfigBundleApplicationTemplateAssignmentToJSON, HashicorpCloudWaypointUIActionConfigBundleTotalRunsFromJSON, HashicorpCloudWaypointUIActionConfigBundleTotalRunsToJSON, HashicorpCloudWaypointUIActionConfigBundleVariableFromJSON, HashicorpCloudWaypointUIActionConfigBundleVariableToJSON, } from './';
export function HashicorpCloudWaypointUIActionConfigBundleFromJSON(json) {
    return HashicorpCloudWaypointUIActionConfigBundleFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointUIActionConfigBundleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'actionConfig': !exists(json, 'action_config') ? undefined : HashicorpCloudWaypointActionConfigFromJSON(json['action_config']),
        'latestRun': !exists(json, 'latest_run') ? undefined : HashicorpCloudWaypointActionRunFromJSON(json['latest_run']),
        'totalRuns': !exists(json, 'total_runs') ? undefined : HashicorpCloudWaypointUIActionConfigBundleTotalRunsFromJSON(json['total_runs']),
        'assignedApplications': !exists(json, 'assigned_applications') ? undefined : (json['assigned_applications'].map(HashicorpCloudWaypointUIActionConfigBundleApplicationAssignmentFromJSON)),
        'assignedApplicationTemplates': !exists(json, 'assigned_application_templates') ? undefined : (json['assigned_application_templates'].map(HashicorpCloudWaypointUIActionConfigBundleApplicationTemplateAssignmentFromJSON)),
        'variablesUsed': !exists(json, 'variables_used') ? undefined : (json['variables_used'].map(HashicorpCloudWaypointUIActionConfigBundleVariableFromJSON)),
    };
}
export function HashicorpCloudWaypointUIActionConfigBundleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'action_config': HashicorpCloudWaypointActionConfigToJSON(value.actionConfig),
        'latest_run': HashicorpCloudWaypointActionRunToJSON(value.latestRun),
        'total_runs': HashicorpCloudWaypointUIActionConfigBundleTotalRunsToJSON(value.totalRuns),
        'assigned_applications': value.assignedApplications === undefined ? undefined : (value.assignedApplications.map(HashicorpCloudWaypointUIActionConfigBundleApplicationAssignmentToJSON)),
        'assigned_application_templates': value.assignedApplicationTemplates === undefined ? undefined : (value.assignedApplicationTemplates.map(HashicorpCloudWaypointUIActionConfigBundleApplicationTemplateAssignmentToJSON)),
        'variables_used': value.variablesUsed === undefined ? undefined : (value.variablesUsed.map(HashicorpCloudWaypointUIActionConfigBundleVariableToJSON)),
    };
}
