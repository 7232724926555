/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IDP Service
 * API for managing authentication sessions and SCIM settings.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIdpListMySessionsResponseSessionFromJSON, HashicorpCloudIdpListMySessionsResponseSessionToJSON, } from './';
export function HashicorpCloudIdpListMySessionsResponseFromJSON(json) {
    return HashicorpCloudIdpListMySessionsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudIdpListMySessionsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sessions': !exists(json, 'sessions') ? undefined : (json['sessions'].map(HashicorpCloudIdpListMySessionsResponseSessionFromJSON)),
    };
}
export function HashicorpCloudIdpListMySessionsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sessions': value.sessions === undefined ? undefined : (value.sessions.map(HashicorpCloudIdpListMySessionsResponseSessionToJSON)),
    };
}
