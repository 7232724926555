import { helper as buildHelper } from '@ember/component/helper';
import { busyStatesForResource } from './busy-states-for-resource.js';

/*
 * A helper that determines if a busy state should be shown for a resource.
 * Returns `true` if a busy indicator should be shown, or `false` if not.
 *
 * @example
 * {{resource-is-busy @model.network type=(resource-type-for 'network')}}
 *
 *
 */

export function resourceIsBusy([resource], { type }) {
  // is the resource in any of the states where it would be considered "busy" ?
  return (busyStatesForResource([type]) || []).includes(resource.state);
}

export default buildHelper(resourceIsBusy);
