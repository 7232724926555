/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLinkFromJSON, HashicorpCloudLocationLinkToJSON, HashicorpCloudNetwork20200907AzureRouteFromJSON, HashicorpCloudNetwork20200907AzureRouteToJSON, HashicorpCloudNetwork20200907HVNRouteTargetFromJSON, HashicorpCloudNetwork20200907HVNRouteTargetToJSON, } from './';
export function HashicorpCloudNetwork20200907CreateHVNRouteRequestFromJSON(json) {
    return HashicorpCloudNetwork20200907CreateHVNRouteRequestFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907CreateHVNRouteRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hvn': !exists(json, 'hvn') ? undefined : HashicorpCloudLocationLinkFromJSON(json['hvn']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'destination': !exists(json, 'destination') ? undefined : json['destination'],
        'target': !exists(json, 'target') ? undefined : HashicorpCloudNetwork20200907HVNRouteTargetFromJSON(json['target']),
        'azureRoute': !exists(json, 'azure_route') ? undefined : HashicorpCloudNetwork20200907AzureRouteFromJSON(json['azure_route']),
    };
}
export function HashicorpCloudNetwork20200907CreateHVNRouteRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hvn': HashicorpCloudLocationLinkToJSON(value.hvn),
        'id': value.id,
        'destination': value.destination,
        'target': HashicorpCloudNetwork20200907HVNRouteTargetToJSON(value.target),
        'azure_route': HashicorpCloudNetwork20200907AzureRouteToJSON(value.azureRoute),
    };
}
