/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CloudlocationLocationFromJSON, CloudlocationLocationToJSON, LogService20210330CloudwatchLogsProviderFromJSON, LogService20210330CloudwatchLogsProviderToJSON, LogService20210330DatadogProviderFromJSON, LogService20210330DatadogProviderToJSON, ProtobufFieldMaskFromJSON, ProtobufFieldMaskToJSON, } from './';
export function LogService20210330TestStreamingDestinationRequestFromJSON(json) {
    return LogService20210330TestStreamingDestinationRequestFromJSONTyped(json, false);
}
export function LogService20210330TestStreamingDestinationRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : CloudlocationLocationFromJSON(json['location']),
        'existingDestinationId': !exists(json, 'existing_destination_id') ? undefined : json['existing_destination_id'],
        'mask': !exists(json, 'mask') ? undefined : ProtobufFieldMaskFromJSON(json['mask']),
        'datadogProvider': !exists(json, 'datadog_provider') ? undefined : LogService20210330DatadogProviderFromJSON(json['datadog_provider']),
        'cloudwatchlogsProvider': !exists(json, 'cloudwatchlogs_provider') ? undefined : LogService20210330CloudwatchLogsProviderFromJSON(json['cloudwatchlogs_provider']),
    };
}
export function LogService20210330TestStreamingDestinationRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': CloudlocationLocationToJSON(value.location),
        'existing_destination_id': value.existingDestinationId,
        'mask': ProtobufFieldMaskToJSON(value.mask),
        'datadog_provider': LogService20210330DatadogProviderToJSON(value.datadogProvider),
        'cloudwatchlogs_provider': LogService20210330CloudwatchLogsProviderToJSON(value.cloudwatchlogsProvider),
    };
}
