/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReason;
(function (HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReason) {
    HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReason["UNDEFINED"] = "PARTITION_INELIGIBILITY_UNDEFINED";
    HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReason["PEERINGALREADYEXISTS"] = "PARTITION_INELIGIBILITY_PEERING_ALREADY_EXISTS";
})(HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReason || (HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReason = {}));
export function HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReasonFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReasonFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReasonFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215PartitionPeeringIneligibilityReasonToJSON(value) {
    return value;
}
