/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionConfigFlavorCustomHeaderFromJSON, HashicorpCloudWaypointActionConfigFlavorCustomHeaderToJSON, HashicorpCloudWaypointActionConfigFlavorCustomMethodFromJSON, HashicorpCloudWaypointActionConfigFlavorCustomMethodToJSON, } from './';
export function HashicorpCloudWaypointActionConfigFlavorCustomFromJSON(json) {
    return HashicorpCloudWaypointActionConfigFlavorCustomFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointActionConfigFlavorCustomFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'method': !exists(json, 'method') ? undefined : HashicorpCloudWaypointActionConfigFlavorCustomMethodFromJSON(json['method']),
        'headers': !exists(json, 'headers') ? undefined : (json['headers'].map(HashicorpCloudWaypointActionConfigFlavorCustomHeaderFromJSON)),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'body': !exists(json, 'body') ? undefined : json['body'],
    };
}
export function HashicorpCloudWaypointActionConfigFlavorCustomToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'method': HashicorpCloudWaypointActionConfigFlavorCustomMethodToJSON(value.method),
        'headers': value.headers === undefined ? undefined : (value.headers.map(HashicorpCloudWaypointActionConfigFlavorCustomHeaderToJSON)),
        'url': value.url,
        'body': value.body,
    };
}
