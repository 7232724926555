import Component from '@glimmer/component';

interface LayoutCardSignature {
  Args: {
    disabled: unknown;
  };
  Blocks: {
    default: [];
  };
}

/**
 *
 * Layout primitive to wrap content in a card, with a drop-shadow.
 * Disabled state has no drop shadow, lightened border, and tooltip as visual cues.
 *
 * NOTE: Intentionally unopinionated about max-width, padding, margin styles etc. to remain generic.
 *       combine with other layout components (like <Box />)
 *
 *
 * ```
 * <Layout::Card @disabled={{disabled}}>
 *   King of Hearts
 * </Layout::Card>
 * ```
 *
 * @class LayoutCard
 *
 */
export default class LayoutCardComponent extends Component<LayoutCardSignature> {
  /**
   * @argument disabled
   * @type {Boolean}
   */
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Layout::Card': typeof LayoutCardComponent;
    'layout/card': typeof LayoutCardComponent;
  }
}
