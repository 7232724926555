/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIamServicePrincipalFromJSON, HashicorpCloudIamServicePrincipalToJSON, } from './';
export function HashicorpCloudIamCreateServicePrincipalResponseFromJSON(json) {
    return HashicorpCloudIamCreateServicePrincipalResponseFromJSONTyped(json, false);
}
export function HashicorpCloudIamCreateServicePrincipalResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'servicePrincipal': !exists(json, 'service_principal') ? undefined : HashicorpCloudIamServicePrincipalFromJSON(json['service_principal']),
    };
}
export function HashicorpCloudIamCreateServicePrincipalResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'service_principal': HashicorpCloudIamServicePrincipalToJSON(value.servicePrincipal),
    };
}
