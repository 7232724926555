/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudWaypointWaypointServiceEndingActionBodyFromJSON(json) {
    return HashicorpCloudWaypointWaypointServiceEndingActionBodyFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointWaypointServiceEndingActionBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'actionRunId': !exists(json, 'action_run_id') ? undefined : json['action_run_id'],
        'statusCode': !exists(json, 'status_code') ? undefined : json['status_code'],
        'finalStatus': !exists(json, 'final_status') ? undefined : json['final_status'],
    };
}
export function HashicorpCloudWaypointWaypointServiceEndingActionBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': value.namespace,
        'action_run_id': value.actionRunId,
        'status_code': value.statusCode,
        'final_status': value.finalStatus,
    };
}
