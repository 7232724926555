/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215ServerFromJSON, HashicorpCloudGlobalNetworkManager20220215ServerToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ListClusterServersResponseFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ListClusterServersResponseFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ListClusterServersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'server': !exists(json, 'server') ? undefined : (json['server'].map(HashicorpCloudGlobalNetworkManager20220215ServerFromJSON)),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ListClusterServersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'server': value.server === undefined ? undefined : (value.server.map(HashicorpCloudGlobalNetworkManager20220215ServerToJSON)),
    };
}
