/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *  - UNSET: UNSET is a sentinel zero value so that an uninitialized value can be
 * detected.
 *  - RUNNING: Running means the `packer build` is currently running.
 *  - DONE: Done means the `packer build` has finished successfully.
 *  - CANCELLED: Cancelled means the `packer build` was cancelled by a user.
 *  - FAILED: Failed means the `packer build` failed and therefore image creation failed.
 * @export
 * @enum {string}
 */
export var HashicorpCloudPacker20221202BuildStatus;
(function (HashicorpCloudPacker20221202BuildStatus) {
    HashicorpCloudPacker20221202BuildStatus["UNSET"] = "UNSET";
    HashicorpCloudPacker20221202BuildStatus["RUNNING"] = "RUNNING";
    HashicorpCloudPacker20221202BuildStatus["DONE"] = "DONE";
    HashicorpCloudPacker20221202BuildStatus["CANCELLED"] = "CANCELLED";
    HashicorpCloudPacker20221202BuildStatus["FAILED"] = "FAILED";
})(HashicorpCloudPacker20221202BuildStatus || (HashicorpCloudPacker20221202BuildStatus = {}));
export function HashicorpCloudPacker20221202BuildStatusFromJSON(json) {
    return HashicorpCloudPacker20221202BuildStatusFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202BuildStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudPacker20221202BuildStatusToJSON(value) {
    return value;
}
