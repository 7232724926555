/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * SnapshotType represents the type of a snapshot.
 *
 *  - AUTOMATIC: AUTOMATIC is used for snapshots generated automatically, like a snapshot before update.
 *  - MANUAL: MANUAL is used for snapshots that are initiated by a user's actions.
 *  - SCHEDULED: SCHEDULED is used for snapshots that are generated based on a schedule.
 * @export
 * @enum {string}
 */
export var HashicorpCloudConsul20210204SnapshotSnapshotType;
(function (HashicorpCloudConsul20210204SnapshotSnapshotType) {
    HashicorpCloudConsul20210204SnapshotSnapshotType["TYPEUNSET"] = "TYPE_UNSET";
    HashicorpCloudConsul20210204SnapshotSnapshotType["AUTOMATIC"] = "AUTOMATIC";
    HashicorpCloudConsul20210204SnapshotSnapshotType["MANUAL"] = "MANUAL";
    HashicorpCloudConsul20210204SnapshotSnapshotType["SCHEDULED"] = "SCHEDULED";
})(HashicorpCloudConsul20210204SnapshotSnapshotType || (HashicorpCloudConsul20210204SnapshotSnapshotType = {}));
export function HashicorpCloudConsul20210204SnapshotSnapshotTypeFromJSON(json) {
    return HashicorpCloudConsul20210204SnapshotSnapshotTypeFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204SnapshotSnapshotTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudConsul20210204SnapshotSnapshotTypeToJSON(value) {
    return value;
}
