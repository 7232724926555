/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIamSAMLDetailsFromJSON, HashicorpCloudIamSAMLDetailsToJSON, HashicorpCloudIamSSOTypeFromJSON, HashicorpCloudIamSSOTypeToJSON, } from './';
export function HashicorpCloudIamSSOConfigFromJSON(json) {
    return HashicorpCloudIamSSOConfigFromJSONTyped(json, false);
}
export function HashicorpCloudIamSSOConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !exists(json, 'type') ? undefined : HashicorpCloudIamSSOTypeFromJSON(json['type']),
        'saml': !exists(json, 'saml') ? undefined : HashicorpCloudIamSAMLDetailsFromJSON(json['saml']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'defaultRole': !exists(json, 'default_role') ? undefined : json['default_role'],
    };
}
export function HashicorpCloudIamSSOConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': HashicorpCloudIamSSOTypeToJSON(value.type),
        'saml': HashicorpCloudIamSAMLDetailsToJSON(value.saml),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'default_role': value.defaultRole,
    };
}
