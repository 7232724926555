/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Waypoint
 * Service for managing applications.
 *
 * The version of the OpenAPI document: 2023-08-18
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWaypointActionCfgRefFromJSON, HashicorpCloudWaypointActionCfgRefToJSON, HashicorpCloudWaypointRefApplicationFromJSON, HashicorpCloudWaypointRefApplicationToJSON, HashicorpCloudWaypointRefApplicationTemplateFromJSON, HashicorpCloudWaypointRefApplicationTemplateToJSON, } from './';
export function HashicorpCloudWaypointVariableScopeFromJSON(json) {
    return HashicorpCloudWaypointVariableScopeFromJSONTyped(json, false);
}
export function HashicorpCloudWaypointVariableScopeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'global': !exists(json, 'global') ? undefined : json['global'],
        'applicationTemplate': !exists(json, 'application_template') ? undefined : HashicorpCloudWaypointRefApplicationTemplateFromJSON(json['application_template']),
        'application': !exists(json, 'application') ? undefined : HashicorpCloudWaypointRefApplicationFromJSON(json['application']),
        'action': !exists(json, 'action') ? undefined : HashicorpCloudWaypointActionCfgRefFromJSON(json['action']),
    };
}
export function HashicorpCloudWaypointVariableScopeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'global': value.global,
        'application_template': HashicorpCloudWaypointRefApplicationTemplateToJSON(value.applicationTemplate),
        'application': HashicorpCloudWaypointRefApplicationToJSON(value.application),
        'action': HashicorpCloudWaypointActionCfgRefToJSON(value.action),
    };
}
