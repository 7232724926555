/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * PricingModel is a reference to all available pricing models in the billing service.
 *
 *  - PRICING_MODEL_UNSPECIFIED: PRICING_MODEL_UNSPECIFIED is an invalid pricing model, which
 * is used to flag when the pricing model was left blank.
 *  - PRICING_MODEL_PAYG: PRICING_MODEL_PAYG represents the PAYG pricing model.
 *  - PRICING_MODEL_FLEX: PRICING_MODEL_FLEX represents the FLEX (or FCP) pricing model.
 *  - PRICING_MODEL_ENTITLEMENT: PRICING_MODEL_ENTITLEMENT represents the Entitlement (or contracts) pricing model.
 * @export
 * @enum {string}
 */
export var Billing20201105PricingModel;
(function (Billing20201105PricingModel) {
    Billing20201105PricingModel["UNSPECIFIED"] = "PRICING_MODEL_UNSPECIFIED";
    Billing20201105PricingModel["PAYG"] = "PRICING_MODEL_PAYG";
    Billing20201105PricingModel["FLEX"] = "PRICING_MODEL_FLEX";
    Billing20201105PricingModel["ENTITLEMENT"] = "PRICING_MODEL_ENTITLEMENT";
})(Billing20201105PricingModel || (Billing20201105PricingModel = {}));
export function Billing20201105PricingModelFromJSON(json) {
    return Billing20201105PricingModelFromJSONTyped(json, false);
}
export function Billing20201105PricingModelFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function Billing20201105PricingModelToJSON(value) {
    return value;
}
