/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215ServerTLSFromJSON, HashicorpCloudGlobalNetworkManager20220215ServerTLSToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ClusterBootstrapFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ClusterBootstrapFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterBootstrapFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'bootstrapExpect': !exists(json, 'bootstrap_expect') ? undefined : json['bootstrap_expect'],
        'gossipKey': !exists(json, 'gossip_key') ? undefined : json['gossip_key'],
        'serverTls': !exists(json, 'server_tls') ? undefined : HashicorpCloudGlobalNetworkManager20220215ServerTLSFromJSON(json['server_tls']),
        'consulConfig': !exists(json, 'consul_config') ? undefined : json['consul_config'],
        'managementToken': !exists(json, 'management_token') ? undefined : json['management_token'],
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterBootstrapToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'bootstrap_expect': value.bootstrapExpect,
        'gossip_key': value.gossipKey,
        'server_tls': HashicorpCloudGlobalNetworkManager20220215ServerTLSToJSON(value.serverTls),
        'consul_config': value.consulConfig,
        'management_token': value.managementToken,
    };
}
