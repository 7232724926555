/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudIamAcceptOrganizationInvitationRequestToJSON, HashicorpCloudIamAcceptOrganizationInvitationResponseFromJSON, HashicorpCloudIamGetOrganizationNameByInvitationTokenResponseFromJSON, HashicorpCloudIamListOrganizationInvitationsResponseFromJSON, InlineObject6ToJSON, } from '../models';
/**
 *
 */
export class InvitationsServiceApi extends runtime.BaseAPI {
    /**
     * AcceptOrganizationInvitation accepts an organization invitation, expects an invitation token in the body.
     */
    async invitationsServiceAcceptOrganizationInvitationRaw(requestParameters) {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling invitationsServiceAcceptOrganizationInvitation.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/accept-invitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudIamAcceptOrganizationInvitationRequestToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamAcceptOrganizationInvitationResponseFromJSON(jsonValue));
    }
    /**
     * AcceptOrganizationInvitation accepts an organization invitation, expects an invitation token in the body.
     */
    async invitationsServiceAcceptOrganizationInvitation(body) {
        const response = await this.invitationsServiceAcceptOrganizationInvitationRaw({ body: body });
        return await response.value();
    }
    /**
     * CreateOrganizationInvitations creates one or more organization invitations.
     */
    async invitationsServiceCreateOrganizationInvitationsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling invitationsServiceCreateOrganizationInvitations.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling invitationsServiceCreateOrganizationInvitations.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/invitations`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject6ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * CreateOrganizationInvitations creates one or more organization invitations.
     */
    async invitationsServiceCreateOrganizationInvitations(organizationId, body) {
        const response = await this.invitationsServiceCreateOrganizationInvitationsRaw({ organizationId: organizationId, body: body });
        return await response.value();
    }
    /**
     * DeleteOrganizationInvitation creates an organization invitation.
     */
    async invitationsServiceDeleteOrganizationInvitationRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling invitationsServiceDeleteOrganizationInvitation.');
        }
        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId', 'Required parameter requestParameters.invitationId was null or undefined when calling invitationsServiceDeleteOrganizationInvitation.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/invitations/{invitation_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"invitation_id"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * DeleteOrganizationInvitation creates an organization invitation.
     */
    async invitationsServiceDeleteOrganizationInvitation(organizationId, invitationId) {
        const response = await this.invitationsServiceDeleteOrganizationInvitationRaw({ organizationId: organizationId, invitationId: invitationId });
        return await response.value();
    }
    /**
     * GetOrganizationNameByInvitationToken returns the organization name for the invitation associated with the invitation token provided.
     */
    async invitationsServiceGetOrganizationNameByInvitationTokenRaw(requestParameters) {
        if (requestParameters.invitationToken === null || requestParameters.invitationToken === undefined) {
            throw new runtime.RequiredError('invitationToken', 'Required parameter requestParameters.invitationToken was null or undefined when calling invitationsServiceGetOrganizationNameByInvitationToken.');
        }
        const queryParameters = {};
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/invitations/{invitation_token}/organization`.replace(`{${"invitation_token"}}`, encodeURIComponent(String(requestParameters.invitationToken))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamGetOrganizationNameByInvitationTokenResponseFromJSON(jsonValue));
    }
    /**
     * GetOrganizationNameByInvitationToken returns the organization name for the invitation associated with the invitation token provided.
     */
    async invitationsServiceGetOrganizationNameByInvitationToken(invitationToken) {
        const response = await this.invitationsServiceGetOrganizationNameByInvitationTokenRaw({ invitationToken: invitationToken });
        return await response.value();
    }
    /**
     * ListOrganizationInvitations returns a list of organization invitations.
     */
    async invitationsServiceListOrganizationInvitationsRaw(requestParameters) {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId', 'Required parameter requestParameters.organizationId was null or undefined when calling invitationsServiceListOrganizationInvitations.');
        }
        const queryParameters = {};
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        if (requestParameters.states) {
            queryParameters['states'] = requestParameters.states;
        }
        const headerParameters = {};
        // NOTE: Condition checking return value of `apiKey(...)` is HCP specific, and deviates from default OAS template
        if (this.configuration && this.configuration.apiKey && this.configuration.apiKey("Authorization")) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/iam/2019-12-10/organizations/{organization_id}/invitations`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudIamListOrganizationInvitationsResponseFromJSON(jsonValue));
    }
    /**
     * ListOrganizationInvitations returns a list of organization invitations.
     */
    async invitationsServiceListOrganizationInvitations(organizationId, paginationPageSize, paginationNextPageToken, paginationPreviousPageToken, states) {
        const response = await this.invitationsServiceListOrganizationInvitationsRaw({ organizationId: organizationId, paginationPageSize: paginationPageSize, paginationNextPageToken: paginationNextPageToken, paginationPreviousPageToken: paginationPreviousPageToken, states: states });
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var InvitationsServiceListOrganizationInvitationsStatesEnum;
(function (InvitationsServiceListOrganizationInvitationsStatesEnum) {
    InvitationsServiceListOrganizationInvitationsStatesEnum["PENDING"] = "PENDING";
    InvitationsServiceListOrganizationInvitationsStatesEnum["ACCEPTED"] = "ACCEPTED";
})(InvitationsServiceListOrganizationInvitationsStatesEnum || (InvitationsServiceListOrganizationInvitationsStatesEnum = {}));
