/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GoogleRpcStatusFromJSON, GoogleRpcStatusToJSON, HashicorpCloudGlobalNetworkManager20220215NetworkConnectivityFromJSON, HashicorpCloudGlobalNetworkManager20220215NetworkConnectivityToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215PeeringConnectionFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215PeeringConnectionFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215PeeringConnectionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'peeringConnectionId': !exists(json, 'peering_connection_id') ? undefined : json['peering_connection_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'networkConnectivity': !exists(json, 'network_connectivity') ? undefined : HashicorpCloudGlobalNetworkManager20220215NetworkConnectivityFromJSON(json['network_connectivity']),
        'displayError': !exists(json, 'display_error') ? undefined : GoogleRpcStatusFromJSON(json['display_error']),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215PeeringConnectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'peering_connection_id': value.peeringConnectionId,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'network_connectivity': HashicorpCloudGlobalNetworkManager20220215NetworkConnectivityToJSON(value.networkConnectivity),
        'display_error': GoogleRpcStatusToJSON(value.displayError),
    };
}
