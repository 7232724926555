import Component from '@glimmer/component';
import {
  TYPE_NETWORK,
  TYPE_NETWORK_PEERING,
  TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
  TYPE_NETWORK_ROUTE,
} from 'common/utils/cloud-resource-types';
import {
  NETWORK_CREATE_UP_NEXT_CONTENT,
  TARGET_CREATE_UP_NEXT_CONTENT,
  NETWORK_ROUTE_CREATE_UP_NEXT_CONTENT,
} from '../../../../utils/cards-up-next';

/**
 *
 * `NetworksCardsUpNext` renders the Up next card that has a default title "Up next".  It also contains an optional name blocks :title :subtitle and :instructions so consumers have the option to replace these values with custom content.
 *
 *
 * ```
 * <Networks::Cards::UpNext @resourceType='hashicorp.network.hvn' @provider={{this.provider}}/>
 * ```
 *
 * @class NetworksCardsUpNext
 *
 */

/**
 * `resourceType` is a string specifying the type of HashiCorp resource.
 * @argument resourceType
 * @type {string}
 */
/**
 * `provider` is a string specifying the provider type, values can be aws or azure. This value is required when the resourceType is TYPE_NETWORK or TYPE_NETWORK_ROUTE
 * @argument provider
 * @type {string}
 */
export default class NetworksCardsUpNextComponent extends Component {
  get target() {
    switch (this.args.resourceType) {
      case TYPE_NETWORK_PEERING:
        return 'peering';
      case TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT:
        return 'tgw';
      default:
        return null;
    }
  }

  get provider() {
    if (
      this.args.resourceType === TYPE_NETWORK ||
      this.args.resourceType === TYPE_NETWORK_ROUTE
    ) {
      return this.args.provider;
    } else {
      return null;
    }
  }

  get content() {
    switch (this.args.resourceType) {
      case TYPE_NETWORK:
        return NETWORK_CREATE_UP_NEXT_CONTENT;
      case TYPE_NETWORK_PEERING:
        return TARGET_CREATE_UP_NEXT_CONTENT;
      case TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT:
        return TARGET_CREATE_UP_NEXT_CONTENT;
      case TYPE_NETWORK_ROUTE:
        return NETWORK_ROUTE_CREATE_UP_NEXT_CONTENT;
      default:
        return null;
    }
  }
}
