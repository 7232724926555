import Service from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { isTesting } from '@embroider/macros';

export const MILLISECOND_IN_MS = 1;
export const SECOND_IN_MS = 1000 * MILLISECOND_IN_MS;
export const MINUTE_IN_MS = 60 * SECOND_IN_MS;
export const HOUR_IN_MS = 60 * MINUTE_IN_MS;
export const DAY_IN_MS = 24 * HOUR_IN_MS;
export const WEEK_IN_MS = 7 * DAY_IN_MS;

export const THRESHOLD_RELATIVE_TIME_IN_MS = WEEK_IN_MS;

export const RELATIVE_UNIT_SECOND = 'second';
export const RELATIVE_UNIT_HOUR = 'hour';
export const RELATIVE_UNIT_MINUTE = 'minute';
export const RELATIVE_UNIT_DAY = 'day';
export const RELATIVE_UNIT_WEEK = 'week';

export const DEFAULT_RELATIVE_THRESHOLDS = {
  [RELATIVE_UNIT_SECOND]: 1 * MINUTE_IN_MS,
  [RELATIVE_UNIT_MINUTE]: 1 * HOUR_IN_MS,
  [RELATIVE_UNIT_HOUR]: 1 * DAY_IN_MS,
  [RELATIVE_UNIT_DAY]: 100 * WEEK_IN_MS,
};

// returns 'Sep 5, 2018 (30 minutes ago)'
export const DISPLAY_KEY_FRIENDLY_RELATIVE = 'friendly-relative';

// returns 'Sep 5, 2018, 4:07:32 pm'
export const DISPLAY_KEY_FRIENDLY_LOCAL = 'friendly-local';

// returns 'Sep 5, 2018'
export const DISPLAY_KEY_FRIENDLY_ONLY = 'friendly-only';

// returns 'about 2 hours ago'
export const DISPLAY_KEY_RELATIVE = 'relative';

// returns '2018-09-05T23:15:17345Z'
export const DISPLAY_KEY_UTC = 'utc';

export const FORMAT_PRECISION_SHORT_DATE = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};
export const FORMAT_PRECISION_MINUTE = {
  ...FORMAT_PRECISION_SHORT_DATE,
  hour: 'numeric',
  minute: 'numeric',
};
export const FORMAT_PRECISION_SECOND = {
  ...FORMAT_PRECISION_SHORT_DATE,
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

// These are exported and used in the `formats.js` for use directly with the
// ember-intl format-date helper.
export const DATE_DISPLAY_FORMATS = {
  [DISPLAY_KEY_FRIENDLY_LOCAL]: FORMAT_PRECISION_SECOND,
  [DISPLAY_KEY_FRIENDLY_ONLY]: FORMAT_PRECISION_SHORT_DATE,
};

export const DEFAULT_DISPLAY = undefined;
export const DEFAULT_DISPLAY_MAPPING = {
  [DISPLAY_KEY_FRIENDLY_RELATIVE]: {
    displayFormat: FORMAT_PRECISION_SHORT_DATE,
    showFriendly: true,
    showRelative: true,
    tooltipFormat: FORMAT_PRECISION_SECOND,
  },
  [DISPLAY_KEY_FRIENDLY_LOCAL]: {
    displayFormat: DATE_DISPLAY_FORMATS[DISPLAY_KEY_FRIENDLY_LOCAL],
    showFriendly: true,
    showRelative: false,
    tooltipFormat: null,
  },
  [DISPLAY_KEY_FRIENDLY_ONLY]: {
    displayFormat: DATE_DISPLAY_FORMATS[DISPLAY_KEY_FRIENDLY_ONLY],
    showFriendly: true,
    showRelative: false,
    tooltipFormat: null,
  },
  [DISPLAY_KEY_RELATIVE]: {
    displayFormat: null,
    showFriendly: false,
    showRelative: true,
    tooltipFormat: FORMAT_PRECISION_MINUTE,
  },
  [DISPLAY_KEY_UTC]: {
    displayFormat: null,
    showFriendly: true,
    showRelative: false,
    tooltipFormat: null,
  },
};
export const DISPLAY_SCALE = Object.keys(DEFAULT_DISPLAY_MAPPING);

/**
 * The display options for a display type.
 * @typedef {Object} TimeDisplayOption
 * @property {Object} displayFormat - A set of Intl formatting options used to
 *     format the display date.
 * @property {boolean} showFriendly - A flag to display a friendly date.
 * @property {boolean} showRelative - A flag to display a relative date.
 * @property {Object} tooltipFormat - A set of Intl formatting options used to
 *     format the tooltip date.
 */

/**
 * The representation of time difference in milliseconds.
 * @typedef {Object} TimeDifference
 * @property {number} absValueInMs - The absolute value of valueInMs.
 * @property {number} valueInMs - The value, in ms, of the difference between a
 *     date and now. THis value could be negative to indicate the past.
 */

/**
 * The difference in time used in a human readable relative date.
 * @typedef {Object} TimeRelativeUnit
 * @property {('second'|'minute'|'hour'|'day'|'week')} unit - The context for
 *     displaying the length of time for the value.
 * @property {number} value - The amount of units that this relative time is
 *     based on.
 */

/**
 * A formatted group of display values for rendering a human-readable date.
 * @typedef {Object} TimeFormattedDisplay
 * @property {TimeDisplayOption} options - The options based on a selected
 *     display key.
 * @property {TimeDifference} difference - The difference between dates in ms.
 * @property {TimeRelativeUnit} relative - The relative unit based on the
 *     difference.
 */

/**
 *
 * The TimeService provides a set of format methods to help transform a Date and
 * potentially a type into a human-readable string.
 *
 * @see {@link format}
 * @extends Service
 * @see {@link https://docs.google.com/document/d/1I91wdyCa8iO91TlVmKd2n1NI1aK7bRm5YM2BF8FaQfY/edit|Structure date and time}
 */
export default class TimeService extends Service {
  #listeners = new Set();

  @tracked now = Date.now();

  /**
   * This formats a Date into one of many selected formats that can either be
   * set via the @display argument or, if left blank, will be displayed based
   * on an algorithm set forth by the design team for maximum readability
   * based on context.
   *
   * @param {TimeDifference} difference - A number to format.  TODO: add type
   * @param {string} display - A unit to passthrough. TODO: add type
   * @returns {TimeFormattedDisplay} A formatted relative unit.
   */
  format(difference, display = DEFAULT_DISPLAY) {
    let displayKey;
    let options;

    // If the scale display is defined and valid then set that display.
    if (display && DISPLAY_SCALE.includes(display)) {
      displayKey = display;
    } else {
      // If there's no defined display then we will execute the design system's
      // prefered algorithm.

      // By default, we assume we will display just a relative display only.
      displayKey = DISPLAY_KEY_RELATIVE;

      // If the difference in date is greater than the threshold for showing the
      // relative time then switch the display key.
      if (difference.absValueInMs > THRESHOLD_RELATIVE_TIME_IN_MS) {
        displayKey = DISPLAY_KEY_FRIENDLY_LOCAL;
      }
    }

    options = DEFAULT_DISPLAY_MAPPING[displayKey];

    return {
      options,
      difference,
      relative: this.selectTimeRelativeUnit(difference),
    };
  }

  /**
   * Formats the value of a relative unit.
   * @param {number} value - A number to format.
   * @param {string} unit - A unit to passthrough.
   * @returns {TimeRelativeUnit} A formatted relative unit.
   */
  formatTimeRelativeUnit(value, unit) {
    return {
      value: Math.trunc(value),
      unit,
    };
  }

  /**
   * Gets the currently subscribed listeners.
   * @param {Date | number} startDate - A start date to calculate a difference.
   * @param {Date | number} endDate - A end date to calculate a difference.
   * @returns {TimeDifference} The difference, in ms, between two dates.
   */
  getTimeDifference(startDate, endDate) {
    const valueInMs = endDate - startDate;
    return {
      absValueInMs: Math.abs(valueInMs),
      valueInMs,
    };
  }

  /**
   * Gets the currently subscribed listeners.
   * @returns {Set} The listeners that are currently subscribed.
   */
  get listeners() {
    return this.#listeners;
  }

  /**
   * Subscribes a listener to the ticking task for time changes.
   * @param {Date} id - The difference to select a unit.
   * @returns {TimeRelativeUnit} A unit and value for relative difference.
   */
  register(id) {
    this.#listeners.add(id);
    this.start.perform();
    return () => {
      this.unregister(id);
    };
  }

  /**
   * Selects an appropriate display format for the difference.
   * @param {TimeDifference} difference - The difference to select a unit.
   * @returns {TimeRelativeUnit} A unit and value for relative difference.
   */
  selectTimeRelativeUnit(
    { absValueInMs, valueInMs },
    thresholds = DEFAULT_RELATIVE_THRESHOLDS
  ) {
    if (absValueInMs < thresholds[RELATIVE_UNIT_SECOND]) {
      return this.formatTimeRelativeUnit(
        valueInMs / SECOND_IN_MS,
        RELATIVE_UNIT_SECOND
      );
    }

    if (absValueInMs < thresholds[RELATIVE_UNIT_MINUTE]) {
      return this.formatTimeRelativeUnit(
        valueInMs / MINUTE_IN_MS,
        RELATIVE_UNIT_MINUTE
      );
    }

    if (absValueInMs < thresholds[RELATIVE_UNIT_HOUR]) {
      return this.formatTimeRelativeUnit(
        valueInMs / HOUR_IN_MS,
        RELATIVE_UNIT_HOUR
      );
    }

    if (absValueInMs < thresholds[RELATIVE_UNIT_DAY]) {
      return this.formatTimeRelativeUnit(
        valueInMs / DAY_IN_MS,
        RELATIVE_UNIT_DAY
      );
    }

    return this.formatTimeRelativeUnit(
      valueInMs / WEEK_IN_MS,
      RELATIVE_UNIT_WEEK
    );
  }

  @task({ drop: true })
  *start() {
    while (this.listeners.size) {
      this.now = Date.now();
      // When testing and canceling a EC task, a timer will never resolve and
      // cause the test to hang while waiting for a permanently hanging timeout.
      // This condition breaks the test out of that.
      // via: http://ember-concurrency.com/docs/testing-debugging/
      if (isTesting()) return;
      yield timeout(SECOND_IN_MS);
    }
  }

  /**
   * Transforms a JS date to a string representing the UTC ISO date.
   * @param {Date} date - A JS Date.
   * @returns {string} An ISO date representing the UTC time of the JS date.
   */
  toIsoUtcString(date) {
    return DateTime.fromJSDate(date).toUTC().toJSDate().toISOString();
  }

  /**
   * Unregisters listener for the time task.
   * @param {any} id - The id used at time of registration.
   * @returns {boolean} Returns true if value was already in Set; otherwise false.
   */
  unregister(id) {
    if (id) {
      return this.#listeners.delete(id);
    }
  }
}
