import Component from '@glimmer/component';

interface LoadingMessageSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
  Blocks: {
    default: [];
  };
}

/**
 *
 * `LoadingMessage` displays the message element of the Loading screen.
 *
 *
 * ```
 * <Loading::Message>Message</Loading::Message>
 * ```
 *
 * @class LoadingMessage
 *
 */

export default class LoadingMessageComponent extends Component<LoadingMessageSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Loading::Message': typeof LoadingMessageComponent;
    'loading/message': typeof LoadingMessageComponent;
  }
}
