/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudNetwork20200907AzurePeeringTargetFromJSON(json) {
    return HashicorpCloudNetwork20200907AzurePeeringTargetFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907AzurePeeringTargetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'applicationId': !exists(json, 'application_id') ? undefined : json['application_id'],
        'subscriptionId': !exists(json, 'subscription_id') ? undefined : json['subscription_id'],
        'tenantId': !exists(json, 'tenant_id') ? undefined : json['tenant_id'],
        'resourceGroupName': !exists(json, 'resource_group_name') ? undefined : json['resource_group_name'],
        'vnetName': !exists(json, 'vnet_name') ? undefined : json['vnet_name'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'allowForwardedTraffic': !exists(json, 'allow_forwarded_traffic') ? undefined : json['allow_forwarded_traffic'],
        'useRemoteGateways': !exists(json, 'use_remote_gateways') ? undefined : json['use_remote_gateways'],
    };
}
export function HashicorpCloudNetwork20200907AzurePeeringTargetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'application_id': value.applicationId,
        'subscription_id': value.subscriptionId,
        'tenant_id': value.tenantId,
        'resource_group_name': value.resourceGroupName,
        'vnet_name': value.vnetName,
        'region': value.region,
        'allow_forwarded_traffic': value.allowForwardedTraffic,
        'use_remote_gateways': value.useRemoteGateways,
    };
}
