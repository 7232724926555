/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp IAM Service
 * API for managing Identity and Access Management.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudIamAWSWorkloadIdentityProviderConfigFromJSON, HashicorpCloudIamAWSWorkloadIdentityProviderConfigToJSON, HashicorpCloudIamOIDCWorkloadIdentityProviderConfigFromJSON, HashicorpCloudIamOIDCWorkloadIdentityProviderConfigToJSON, } from './';
export function ProviderIsTheWorkloadIdentityProviderToUpdateFromJSON(json) {
    return ProviderIsTheWorkloadIdentityProviderToUpdateFromJSONTyped(json, false);
}
export function ProviderIsTheWorkloadIdentityProviderToUpdateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'conditionalAccess': !exists(json, 'conditional_access') ? undefined : json['conditional_access'],
        'awsConfig': !exists(json, 'aws_config') ? undefined : HashicorpCloudIamAWSWorkloadIdentityProviderConfigFromJSON(json['aws_config']),
        'oidcConfig': !exists(json, 'oidc_config') ? undefined : HashicorpCloudIamOIDCWorkloadIdentityProviderConfigFromJSON(json['oidc_config']),
        'systemManaged': !exists(json, 'system_managed') ? undefined : json['system_managed'],
        'createdAt': !exists(json, 'created_at') ? undefined : (json['created_at'] === null ? null : new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (json['updated_at'] === null ? null : new Date(json['updated_at'])),
    };
}
export function ProviderIsTheWorkloadIdentityProviderToUpdateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'resource_id': value.resourceId,
        'description': value.description,
        'conditional_access': value.conditionalAccess,
        'aws_config': HashicorpCloudIamAWSWorkloadIdentityProviderConfigToJSON(value.awsConfig),
        'oidc_config': HashicorpCloudIamOIDCWorkloadIdentityProviderConfigToJSON(value.oidcConfig),
        'system_managed': value.systemManaged,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
    };
}
