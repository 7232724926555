/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudPacker20221202ModelsChildFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsChildFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsChildFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bucketName': !exists(json, 'bucket_name') ? undefined : json['bucket_name'],
        'versionName': !exists(json, 'version_name') ? undefined : json['version_name'],
        'versionId': !exists(json, 'version_id') ? undefined : json['version_id'],
        'versionFingerprint': !exists(json, 'version_fingerprint') ? undefined : json['version_fingerprint'],
    };
}
export function HashicorpCloudPacker20221202ModelsChildToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bucket_name': value.bucketName,
        'version_name': value.versionName,
        'version_id': value.versionId,
        'version_fingerprint': value.versionFingerprint,
    };
}
