/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function LogService20210330AuthorizationInfoFromJSON(json) {
    return LogService20210330AuthorizationInfoFromJSONTyped(json, false);
}
export function LogService20210330AuthorizationInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
    };
}
export function LogService20210330AuthorizationInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'permissions': value.permissions,
        'organization_id': value.organizationId,
        'project_id': value.projectId,
        'resource_id': value.resourceId,
    };
}
