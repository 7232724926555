/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * State is the state of the HCP Boundary cluster.
 *
 *  - STATE_INVALID_UNSPECIFIED: STATE_INVALID_UNSPECIFIED is used when the cluster state is not valid.
 *  - STATE_PENDING: STATE_PENDING is used when the cluster is waiting for creation.
 *  - STATE_CREATING: STATE_CREATING is used when the cluster is being created.
 *  - STATE_RUNNING: STATE_RUNNING is used when the cluster is up and running.
 *  - STATE_FAILED: STATE_FAILED is used when cluster creation failed.
 *  - STATE_DELETING: STATE_DELETING is used when cluster deletion is in progress.
 *  - STATE_DELETED: STATE_DELETED is used when the cluster has been deleted.
 *  - STATE_UPDATING: STATE_UPDATING is used when an upgrade is in progress for the cluster.
 * @export
 * @enum {string}
 */
export var HashicorpCloudBoundary20211221ClusterState;
(function (HashicorpCloudBoundary20211221ClusterState) {
    HashicorpCloudBoundary20211221ClusterState["INVALIDUNSPECIFIED"] = "STATE_INVALID_UNSPECIFIED";
    HashicorpCloudBoundary20211221ClusterState["PENDING"] = "STATE_PENDING";
    HashicorpCloudBoundary20211221ClusterState["CREATING"] = "STATE_CREATING";
    HashicorpCloudBoundary20211221ClusterState["RUNNING"] = "STATE_RUNNING";
    HashicorpCloudBoundary20211221ClusterState["FAILED"] = "STATE_FAILED";
    HashicorpCloudBoundary20211221ClusterState["DELETING"] = "STATE_DELETING";
    HashicorpCloudBoundary20211221ClusterState["DELETED"] = "STATE_DELETED";
    HashicorpCloudBoundary20211221ClusterState["UPDATING"] = "STATE_UPDATING";
})(HashicorpCloudBoundary20211221ClusterState || (HashicorpCloudBoundary20211221ClusterState = {}));
export function HashicorpCloudBoundary20211221ClusterStateFromJSON(json) {
    return HashicorpCloudBoundary20211221ClusterStateFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221ClusterStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudBoundary20211221ClusterStateToJSON(value) {
    return value;
}
