/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20230613HcpTerraformCategoryFromJSON, Secrets20230613HcpTerraformCategoryToJSON, Secrets20230613HcpTerraformTypeFromJSON, Secrets20230613HcpTerraformTypeToJSON, } from './';
export function Secrets20230613HcpTerraformConnectionDetailsResponseFromJSON(json) {
    return Secrets20230613HcpTerraformConnectionDetailsResponseFromJSONTyped(json, false);
}
export function Secrets20230613HcpTerraformConnectionDetailsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'installationName': !exists(json, 'installation_name') ? undefined : json['installation_name'],
        'workspaceId': !exists(json, 'workspace_id') ? undefined : json['workspace_id'],
        'variableSetId': !exists(json, 'variable_set_id') ? undefined : json['variable_set_id'],
        'type': !exists(json, 'type') ? undefined : Secrets20230613HcpTerraformTypeFromJSON(json['type']),
        'category': !exists(json, 'category') ? undefined : Secrets20230613HcpTerraformCategoryFromJSON(json['category']),
        'hcl': !exists(json, 'hcl') ? undefined : json['hcl'],
        'workspaceName': !exists(json, 'workspace_name') ? undefined : json['workspace_name'],
        'variableSetName': !exists(json, 'variable_set_name') ? undefined : json['variable_set_name'],
    };
}
export function Secrets20230613HcpTerraformConnectionDetailsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'installation_name': value.installationName,
        'workspace_id': value.workspaceId,
        'variable_set_id': value.variableSetId,
        'type': Secrets20230613HcpTerraformTypeToJSON(value.type),
        'category': Secrets20230613HcpTerraformCategoryToJSON(value.category),
        'hcl': value.hcl,
        'workspace_name': value.workspaceName,
        'variable_set_name': value.variableSetName,
    };
}
