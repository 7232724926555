/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CommonPaginationResponseFromJSON, CommonPaginationResponseToJSON, Secrets20231128GcpIntegrationFromJSON, Secrets20231128GcpIntegrationToJSON, } from './';
export function Secrets20231128ListGcpIntegrationsResponseFromJSON(json) {
    return Secrets20231128ListGcpIntegrationsResponseFromJSONTyped(json, false);
}
export function Secrets20231128ListGcpIntegrationsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'integrations': !exists(json, 'integrations') ? undefined : (json['integrations'].map(Secrets20231128GcpIntegrationFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : CommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function Secrets20231128ListGcpIntegrationsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'integrations': value.integrations === undefined ? undefined : (value.integrations.map(Secrets20231128GcpIntegrationToJSON)),
        'pagination': CommonPaginationResponseToJSON(value.pagination),
    };
}
