/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function IamGroupFromJSON(json) {
    return IamGroupFromJSONTyped(json, false);
}
export function IamGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'resourceName': !exists(json, 'resource_name') ? undefined : json['resource_name'],
        'displayName': !exists(json, 'display_name') ? undefined : json['display_name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'memberCount': !exists(json, 'member_count') ? undefined : json['member_count'],
        'scimSynchronized': !exists(json, 'scim_synchronized') ? undefined : json['scim_synchronized'],
    };
}
export function IamGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'resource_id': value.resourceId,
        'resource_name': value.resourceName,
        'display_name': value.displayName,
        'description': value.description,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'member_count': value.memberCount,
        'scim_synchronized': value.scimSynchronized,
    };
}
