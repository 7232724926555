/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - TOKEN_VALIDITY_STATUS_UNSPECIFIED: Token's validity is unspecified. This includes when CCM is unable to
 * successfully request token information from Consul to do the validation
 *  - TOKEN_VALIDITY_STATUS_NOT_APPLICABLE: Token validity is not applicable when requesting a token that we do not
 * support validation
 *  - TOKEN_VALIDITY_STATUS_VALID: Token is valid
 *  - TOKEN_VALIDITY_STATUS_INVALID: Token is invalid
 * @export
 * @enum {string}
 */
export var HashicorpCloudGlobalNetworkManager20220215TokenValidityStatus;
(function (HashicorpCloudGlobalNetworkManager20220215TokenValidityStatus) {
    HashicorpCloudGlobalNetworkManager20220215TokenValidityStatus["UNSPECIFIED"] = "TOKEN_VALIDITY_STATUS_UNSPECIFIED";
    HashicorpCloudGlobalNetworkManager20220215TokenValidityStatus["NOTAPPLICABLE"] = "TOKEN_VALIDITY_STATUS_NOT_APPLICABLE";
    HashicorpCloudGlobalNetworkManager20220215TokenValidityStatus["VALID"] = "TOKEN_VALIDITY_STATUS_VALID";
    HashicorpCloudGlobalNetworkManager20220215TokenValidityStatus["INVALID"] = "TOKEN_VALIDITY_STATUS_INVALID";
})(HashicorpCloudGlobalNetworkManager20220215TokenValidityStatus || (HashicorpCloudGlobalNetworkManager20220215TokenValidityStatus = {}));
export function HashicorpCloudGlobalNetworkManager20220215TokenValidityStatusFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215TokenValidityStatusFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215TokenValidityStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudGlobalNetworkManager20220215TokenValidityStatusToJSON(value) {
    return value;
}
