import {
  CONSUL_TIER_DEV,
  CONSUL_TIER_STANDARD,
  CONSUL_TIER_PLUS,
  CONSUL_TIER_PREMIUM,
} from '../strategies/consul/config.ts';

import {
  VAULT_TIER_DEV,
  VAULT_TIER_STARTER,
  VAULT_TIER_STANDARD,
  VAULT_TIER_PLUS,
} from '../strategies/vault/config.ts';

import type { BillingAvailablePackage } from '../types/api.ts';

const CONSUL_TIER_KEY_ORDER = [
  CONSUL_TIER_DEV,
  CONSUL_TIER_STANDARD,
  CONSUL_TIER_PLUS,
  CONSUL_TIER_PREMIUM,
];

const VAULT_TIER_KEY_ORDER = [
  VAULT_TIER_DEV,
  VAULT_TIER_STARTER,
  VAULT_TIER_STANDARD,
  VAULT_TIER_PLUS,
];

// NOTE: It doesn't really matter which product grouping comes first in this list,
//       because Billing packages are pre-filtered by product type anyway.
const PACKAGE_TIER_KEY_ORDER = [
  ...CONSUL_TIER_KEY_ORDER,
  ...VAULT_TIER_KEY_ORDER,
];

/** Comparator used to order an array of Billing packages by tier key. */
export default function compareTierByKeyAsc(
  left: BillingAvailablePackage,
  right: BillingAvailablePackage,
) {
  const leftKey = left?.key;
  if (!leftKey) return 0;

  const rightKey = right?.key;
  if (!rightKey) return -1;

  return (
    PACKAGE_TIER_KEY_ORDER.indexOf(leftKey) -
    PACKAGE_TIER_KEY_ORDER.indexOf(rightKey)
  );
}
