import Component from '@glimmer/component';

interface ZeroStateSignature {
  // Args: {};
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

/**
 *
 * `ZeroState` is displayed when a page has nothing to display. You may render
 * a header, a message, and an action.
 *
 *
 * ```
 * <ZeroState as |ZS|>
 *   <ZS.Header>
 *     {{t 'components.page.hvns.list.empty.header'}}
 *   </ZS.Header>
 *   <ZS.Message>
 *     {{t 'components.page.hvns.list.empty.message'}}
 *   </ZS.Message>
 *   <ZS.Action>
 *     <button type='submit'>
 *       {{t 'components.page.hvns.create.title'}}
 *     </button>
 *   </ZS.Action>
 * </ZeroState>
 * ```
 *
 * @class ZeroState
 *
 */

export default class ZeroStateComponent extends Component<ZeroStateSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ZeroState: typeof ZeroStateComponent;
    'zero-state': typeof ZeroStateComponent;
  }
}
