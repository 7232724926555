/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatus;
(function (HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatus) {
    HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatus["GETREPLICATIONSTATUSRESPONSECONNECTIONSTATUSINVALID"] = "GET_REPLICATION_STATUS_RESPONSE_CONNECTION_STATUS_INVALID";
    HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatus["DISCONNECTED"] = "DISCONNECTED";
    HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatus["CONNECTED"] = "CONNECTED";
})(HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatus || (HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatus = {}));
export function HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatusFromJSON(json) {
    return HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatusFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125GetReplicationStatusResponseConnectionStatusToJSON(value) {
    return value;
}
