/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Webhook Service
 * API for delivering and managing webhooks.
 *
 * The version of the OpenAPI document: 2023-05-31
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudWebhookWebhookPayloadFromJSON, HashicorpCloudWebhookWebhookPayloadToJSON, } from './';
export function HashicorpCloudWebhookWebhookRequestFromJSON(json) {
    return HashicorpCloudWebhookWebhookRequestFromJSONTyped(json, false);
}
export function HashicorpCloudWebhookWebhookRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'headers': !exists(json, 'headers') ? undefined : json['headers'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'payload': !exists(json, 'payload') ? undefined : HashicorpCloudWebhookWebhookPayloadFromJSON(json['payload']),
        'sentAt': !exists(json, 'sent_at') ? undefined : (new Date(json['sent_at'])),
    };
}
export function HashicorpCloudWebhookWebhookRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'headers': value.headers,
        'url': value.url,
        'payload': HashicorpCloudWebhookWebhookPayloadToJSON(value.payload),
        'sent_at': value.sentAt === undefined ? undefined : (value.sentAt.toISOString()),
    };
}
