/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * IdentityType contains the identity types for users.
 *
 *  - UNSET: UNSET is the default value, should not be used.
 *  - SOCIAL_GITHUB: SOCIAL_GITHUB is the GitHub Social login provider.
 *  - EMAIL_PASSWORD: EMAIL_PASSWORD is the auth0 email/password database provider.
 *  - SAMLP: SAMLP is the auth0 SAML provider.
 *  - HASHICORP_SSO: HASHICORP_SSO is the HashiCorp SSO provider.
 * @export
 * @enum {string}
 */
export var UserPrincipalIdentityType;
(function (UserPrincipalIdentityType) {
    UserPrincipalIdentityType["UNSET"] = "UNSET";
    UserPrincipalIdentityType["SOCIALGITHUB"] = "SOCIAL_GITHUB";
    UserPrincipalIdentityType["EMAILPASSWORD"] = "EMAIL_PASSWORD";
    UserPrincipalIdentityType["SAMLP"] = "SAMLP";
    UserPrincipalIdentityType["HASHICORPSSO"] = "HASHICORP_SSO";
})(UserPrincipalIdentityType || (UserPrincipalIdentityType = {}));
export function UserPrincipalIdentityTypeFromJSON(json) {
    return UserPrincipalIdentityTypeFromJSONTyped(json, false);
}
export function UserPrincipalIdentityTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UserPrincipalIdentityTypeToJSON(value) {
    return value;
}
