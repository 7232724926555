export default class NetworkOption {
  /** @type {Network,null} */
  network = null;

  /** @type {Boolean} */
  disabled = false;

  constructor(network, disabled = false) {
    if (network !== undefined) this.network = network;
    if (disabled !== undefined) this.disabled = disabled;
  }
}
