import Component from '@glimmer/component';

interface DisclosureGuideSignature {
  Args: {
    content: unknown;
  };
  Element: HTMLElement;
}

/**
 *
 * `DisclosureGuide` renders  `<Disclosure />` styled with an icon instead of
 *  the default indicator. It also will change the summary text based on whether
 *  the element is open. Additionally, it renders `<Hds::Alert />` followed by
 *  an `<img />` element. This component was created to provide users with a
 *  guide with imagery while completing various input fields within HCP. This
 *  component can render multiple `<Hds::Alert />` and a corresponding `<img />`.
 *
 *
 * ## Example usage
 *
 * ```
 * <DisclosureGuide
 *   @content={{this.disclosureGuides.accountId}}
 * />
 * ```
 *
 * @class DisclosureGuide
 *
 */

export default class DisclosureGuideComponent extends Component<DisclosureGuideSignature> {
  /**
   * `@content` is an array of objects that contains the helpText, imgSrc, and
   *  imgAlt that is rendered in `<DisclosureGuide />`.
   *
   * @argument content
   * @type {object}
   * @required
   */
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DisclosureGuide: typeof DisclosureGuideComponent;
    'disclosure-guide': typeof DisclosureGuideComponent;
  }
}
