import compareSizeByKeyAsc from '../utils/compare-size-by-key-asc.ts';

import type {
  BillingAvailablePackage,
  BillingPackageOption,
} from '../types/api.ts';

export default function packagesDataSizes(
  tier: BillingAvailablePackage | undefined,
): BillingPackageOption[] {
  if (!tier) return [];

  const { items = [] } = tier;
  const [sizes = {}] = items.filter(
    (i) => i.key === 'hashicorp.billing.dimension.size',
  );
  const { options = [] } = sizes;

  const sortedSizeOptions = options.sort(compareSizeByKeyAsc);

  return sortedSizeOptions;
}
