import { ACTIONS, PREFIX } from './index.ts';

// resources
const RESOURCE_CONSUL_CLUSTER = 'hashicorp_consul_cluster';
const RESOURCE_CONSUL_CLUSTER_FRONT_END_VALIDATIONS = `${RESOURCE_CONSUL_CLUSTER}_front_end_validations`;
const RESOURCE_CONSUL_CLUSTER_SNAPSHOT = `${RESOURCE_CONSUL_CLUSTER}_snapshot`;
const RESOURCE_CONSUL_SELF_MANAGED_CLUSTER =
  'hashicorp_consul_global_network_manager_cluster';
const RESOURCE_CONSUL_SELF_MANAGED_CLUSTER_SERVERS =
  'hashicorp_consul_global_network_manager_cluster_servers';
const RESOURCE_CONSUL_SELF_MANAGED_CLUSTER_TOKEN_VALIDATION_SUCCESS =
  'hashicorp_consul_global_network_manager_cluster_token_validation_success';
const RESOURCE_CONSUL_SELF_MANAGED_CLUSTER_PEERING_CONNECTION =
  'hashicorp_consul_global_network_manager_cluster_peering_connection';

// UI elements
const UI_ONBOARDING_STEPPER_AUTOPEERING_LINK =
  'consul_onboarding_stepper_autopeering_link';
const UI_ONBOARDING_STEPPER_CONNECT_AKS_LINK =
  'consul_onboarding_stepper_connect_aks_link';
const UI_ONBOARDING_STEPPER_CONNECT_EC2_LINK =
  'consul_onboarding_stepper_connect_ec2_link';
const UI_ONBOARDING_STEPPER_CONNECT_ECS_LINK =
  'consul_onboarding_stepper_connect_ecs_link';
const UI_ONBOARDING_STEPPER_CONNECT_EKS_LINK =
  'consul_onboarding_stepper_connect_eks_link';
const UI_ONBOARDING_STEPPER_CONNECT_VM_LINK =
  'consul_onboarding_stepper_connect_vm_link';
const UI_ONBOARDING_STEPPER_PEERING_LINK =
  'consul_onboarding_stepper_peering_link';
const UI_ONBOARDING_STEPPER_STEP_1_ACTIVE =
  'consul_onboarding_stepper_step_1_active';
const UI_ONBOARDING_STEPPER_STEP_2_ACTIVE =
  'consul_onboarding_stepper_step_2_active';
const UI_ONBOARDING_STEPPER_STEP_3_ACTIVE =
  'consul_onboarding_stepper_step_3_active';
const UI_ONBOARDING_STEPPER_STEP_3_COMPLETE =
  'consul_onboarding_stepper_step_3_complete';
const UI_ONBOARDING_STEPPER_TGA_LINK = 'consul_onboarding_stepper_tga_link';
const UI_SELF_MANAGED_GENERATE_CODE_SNIPPET_CREDENTIALS_BUTTON =
  'consul_global_network_manager_generate_credentials_button';
const UI_SELF_MANAGED_SERVER_STARTED_BUTTON =
  'consul_global_network_manager_server_started_button';
const UI_SELF_MANAGED_STEPPER_CODE_SNIPPET_COPY_BUTTON =
  'consul_global_network_manager_self_managed_code_snippet_copy_button';
const UI_SELF_MANAGED_CHANGE_CLUSTER_ACCESS_MODE_TO_READ_WRITE_CONFIRM_BUTTON =
  'consul_global_network_manager_self_managed_change_cluster_access_mode_to_read_write_confirm_button';
const UI_SELF_MANAGED_UPDATE_READ_ONLY_TOKEN_CONFIRM_BUTTON =
  'consul_global_network_manager_self_managed_update_read_only_token_confirm_button';
const UI_SELF_MANAGED_ENTERPRISE_MODAL_OPEN_BUTTON =
  'consul_global_network_manager_self_managed_enterprise_modal_open_button';
const UI_SELF_MANAGED_ENTERPRISE_SALES_LINK =
  'consul_global_network_manager_self_managed_enterprise_sales_link';
const UI_TERRAFORM_QUICKSTART = 'consul_terraform_quickstart';
const UI_TERRAFORM_QUICKSTART_COPY_HCL = 'consul_terraform_quickstart_copy_hcl';
const UI_TERRAFORM_QUICKSTART_DOWNLOAD_TF =
  'consul_terraform_quickstart_download_tf';
const UI_TERRAFORM_QUICKSTART_EXIT = 'consul_terraform_quickstart_exit';
const UI_TERRAFORM_QUICKSTART_SP_PERMISSIONS_ERROR =
  'consul_terraform_quickstart_service_principal_permissions_error';
const UI_TOKEN_VALIDATION_FAILED_ERROR = 'consul_token_validation_error';
const UI_TOKEN_VALIDATION_LOAD_RETRY = 'consul_token_validation_load_retry';
const UI_TOKEN_NOT_PROVIDED_ERROR = 'consul_token_not_provided_error';
const UI_TERRAFORM_QUICKSTART_SP_QUOTA_REACHED =
  'consul_terraform_quickstart_service_principal_quota_reached';

// analytics event strings
export const CONSUL_CLUSTER_SECONDARY_FEDERATION_SUBMITTED = `${PREFIX}_${RESOURCE_CONSUL_CLUSTER}_secondary_federation_${ACTIONS.SUBMITTED}`;
export const CONSUL_CLUSTER_SNAPSHOT_DELETED = `${PREFIX}_${RESOURCE_CONSUL_CLUSTER_SNAPSHOT}_${ACTIONS.DELETE_SUBMITTED}`;
export const CONSUL_CLUSTER_SNAPSHOT_RESTORED = `${PREFIX}_${RESOURCE_CONSUL_CLUSTER_SNAPSHOT}_${ACTIONS.RESTORE_SUBMITTED}`;
export const CONSUL_CLUSTER_SNAPSHOT_SUBMITTED = `${PREFIX}_${RESOURCE_CONSUL_CLUSTER_SNAPSHOT}_${ACTIONS.SUBMITTED}`;
export const CONSUL_CLUSTER_SNAPSHOT_UPDATED = `${PREFIX}_${RESOURCE_CONSUL_CLUSTER_SNAPSHOT}_${ACTIONS.UPDATE_SUBMITTED}`;
export const CONSUL_CLUSTER_SUBMITTED = `${PREFIX}_${RESOURCE_CONSUL_CLUSTER}_${ACTIONS.SUBMITTED}`;
export const CONSUL_CLUSTER_FRONT_END_VALIDATION_TRIGGERED = `${PREFIX}_${RESOURCE_CONSUL_CLUSTER_FRONT_END_VALIDATIONS}_${ACTIONS.RENDERED}`;
export const CONSUL_CLUSTER_UPDATE_SUBMITTED = `${PREFIX}_${RESOURCE_CONSUL_CLUSTER}_${ACTIONS.UPDATE_SUBMITTED}`;
export const CONSUL_ONBOARDING_STEPPER_AUTOPEERING_LINK_CLICKED = `${PREFIX}_${UI_ONBOARDING_STEPPER_AUTOPEERING_LINK}_${ACTIONS.CLICKED}`;
export const CONSUL_ONBOARDING_STEPPER_CONNECT_AKS_LINK_CLICKED = `${PREFIX}_${UI_ONBOARDING_STEPPER_CONNECT_AKS_LINK}_${ACTIONS.CLICKED}`;
export const CONSUL_ONBOARDING_STEPPER_CONNECT_EC2_LINK_CLICKED = `${PREFIX}_${UI_ONBOARDING_STEPPER_CONNECT_EC2_LINK}_${ACTIONS.CLICKED}`;
export const CONSUL_ONBOARDING_STEPPER_CONNECT_ECS_LINK_CLICKED = `${PREFIX}_${UI_ONBOARDING_STEPPER_CONNECT_ECS_LINK}_${ACTIONS.CLICKED}`;
export const CONSUL_ONBOARDING_STEPPER_CONNECT_EKS_LINK_CLICKED = `${PREFIX}_${UI_ONBOARDING_STEPPER_CONNECT_EKS_LINK}_${ACTIONS.CLICKED}`;
export const CONSUL_ONBOARDING_STEPPER_CONNECT_VM_LINK_CLICKED = `${PREFIX}_${UI_ONBOARDING_STEPPER_CONNECT_VM_LINK}_${ACTIONS.CLICKED}`;
export const CONSUL_ONBOARDING_STEPPER_PEERING_LINK_CLICKED = `${PREFIX}_${UI_ONBOARDING_STEPPER_PEERING_LINK}_${ACTIONS.CLICKED}`;
export const CONSUL_ONBOARDING_STEPPER_STEP_1_ACTIVE_RENDERED = `${PREFIX}_${UI_ONBOARDING_STEPPER_STEP_1_ACTIVE}_${ACTIONS.RENDERED}`;
export const CONSUL_ONBOARDING_STEPPER_STEP_2_ACTIVE_RENDERED = `${PREFIX}_${UI_ONBOARDING_STEPPER_STEP_2_ACTIVE}_${ACTIONS.RENDERED}`;
export const CONSUL_ONBOARDING_STEPPER_STEP_3_ACTIVE_RENDERED = `${PREFIX}_${UI_ONBOARDING_STEPPER_STEP_3_ACTIVE}_${ACTIONS.RENDERED}`;
export const CONSUL_ONBOARDING_STEPPER_STEP_3_COMPLETE_RENDERED = `${PREFIX}_${UI_ONBOARDING_STEPPER_STEP_3_COMPLETE}_${ACTIONS.RENDERED}`;
export const CONSUL_ONBOARDING_STEPPER_TGA_LINK_CLICKED = `${PREFIX}_${UI_ONBOARDING_STEPPER_TGA_LINK}_${ACTIONS.CLICKED}`;
export const CONSUL_SELF_MANAGED_CLUSTER_CODE_SNIPPET_COPIED = `${PREFIX}_${UI_SELF_MANAGED_STEPPER_CODE_SNIPPET_COPY_BUTTON}_${ACTIONS.CLICKED}`;
export const CONSUL_SELF_MANAGED_CLUSTER_GENERATE_CREDENTIALS_CLICKED = `${PREFIX}_${UI_SELF_MANAGED_GENERATE_CODE_SNIPPET_CREDENTIALS_BUTTON}_${ACTIONS.CLICKED}`;
export const CONSUL_SELF_MANAGED_CHANGE_CLUSTER_ACCESS_MODE_TO_READ_WRITE_CONFIRMED = `${PREFIX}_${UI_SELF_MANAGED_CHANGE_CLUSTER_ACCESS_MODE_TO_READ_WRITE_CONFIRM_BUTTON}_${ACTIONS.CLICKED}`;
export const CONSUL_SELF_MANAGED_UPDATE_READ_ONLY_TOKEN_CONFIRMED = `${PREFIX}_${UI_SELF_MANAGED_UPDATE_READ_ONLY_TOKEN_CONFIRM_BUTTON}_${ACTIONS.CLICKED}`;
export const CONSUL_SELF_MANAGED_CLUSTER_SERVERS_DETECTED = `${PREFIX}_${RESOURCE_CONSUL_SELF_MANAGED_CLUSTER_SERVERS}_${ACTIONS.DETECTED}`;
export const CONSUL_SELF_MANAGED_CLUSTER_TOKEN_VALIDATION_SUCCESS = `${PREFIX}_${RESOURCE_CONSUL_SELF_MANAGED_CLUSTER_TOKEN_VALIDATION_SUCCESS}_${ACTIONS.DETECTED}`;
export const CONSUL_SELF_MANAGED_CLUSTER_SERVERS_STARTED_CLICKED = `${PREFIX}_${UI_SELF_MANAGED_SERVER_STARTED_BUTTON}_${ACTIONS.CLICKED}`;
export const CONSUL_SELF_MANAGED_CLUSTER_SUBMITTED = `${PREFIX}_${RESOURCE_CONSUL_SELF_MANAGED_CLUSTER}_${ACTIONS.SUBMITTED}`;
export const CONSUL_SELF_MANAGED_CLUSTER_UNLINKED = `${PREFIX}_${RESOURCE_CONSUL_SELF_MANAGED_CLUSTER}_${ACTIONS.UNLINKED}`;
export const CONSUL_SELF_MANAGED_CLUSTER_PEERING_CONNECTION_SUBMITTED = `${PREFIX}_${RESOURCE_CONSUL_SELF_MANAGED_CLUSTER_PEERING_CONNECTION}_${ACTIONS.SUBMITTED}`;
export const CONSUL_SELF_MANAGED_CLUSTER_PEERING_CONNECTION_DELETED = `${PREFIX}_${RESOURCE_CONSUL_SELF_MANAGED_CLUSTER_PEERING_CONNECTION}_${ACTIONS.DELETE_SUBMITTED}`;
export const CONSUL_SELF_MANAGED_CLUSTER_ENTERPRISE_MODAL_OPENED = `${PREFIX}_${UI_SELF_MANAGED_ENTERPRISE_MODAL_OPEN_BUTTON}_${ACTIONS.CLICKED}`;
export const CONSUL_SELF_MANAGED_CLUSTER_ENTERPRISE_SALES_LINK_CLICKED = `${PREFIX}_${UI_SELF_MANAGED_ENTERPRISE_SALES_LINK}_${ACTIONS.CLICKED}`;
export const CONSUL_TERRAFORM_QUICKSTART_CLICKED = `${PREFIX}_${UI_TERRAFORM_QUICKSTART}_${ACTIONS.CLICKED}`;
export const CONSUL_TERRAFORM_QUICKSTART_COPY_HCL_CLICKED_V2 = `${PREFIX}_${UI_TERRAFORM_QUICKSTART_COPY_HCL}_${ACTIONS.CLICKED}_v2`;
export const CONSUL_TERRAFORM_QUICKSTART_DOWNLOAD_TF_CLICKED = `${PREFIX}_${UI_TERRAFORM_QUICKSTART_DOWNLOAD_TF}_${ACTIONS.CLICKED}`;
export const CONSUL_TERRAFORM_QUICKSTART_EXIT_CLICKED_V2 = `${PREFIX}_${UI_TERRAFORM_QUICKSTART_EXIT}_${ACTIONS.CLICKED}_v2`;
export const CONSUL_TERRAFORM_QUICKSTART_SP_PERMISSIONS_ERROR_RENDERED = `${PREFIX}_${UI_TERRAFORM_QUICKSTART_SP_PERMISSIONS_ERROR}_${ACTIONS.RENDERED}`;
export const CONSUL_TOKEN_INVALID_ERROR_RENDERED = `${PREFIX}_${UI_TOKEN_VALIDATION_FAILED_ERROR}_${ACTIONS.RENDERED}`;
export const CONSUL_TOKEN_DETAILS_LOAD_REQUEST_SUBMITTED = `${PREFIX}_${UI_TOKEN_VALIDATION_LOAD_RETRY}_${ACTIONS.SUBMITTED}`;
export const CONSUL_TOKEN_NOT_PROVIDED_ERROR_RENDERED = `${PREFIX}_${UI_TOKEN_NOT_PROVIDED_ERROR}_${ACTIONS.RENDERED}`;
export const CONSUL_TERRAFORM_QUICKSTART_SP_QUOTA_REACHED_RENDERED = `${PREFIX}_${UI_TERRAFORM_QUICKSTART_SP_QUOTA_REACHED}_${ACTIONS.RENDERED}`;
