import Service from '@ember/service';

import type {
  ResourceType,
  IRoleAssignmentsConfig,
} from '../types/role-assignments-config';

import RoleAssignmentConfig from '../utils/role-assignments-config.ts';

const DEFAULT_CONFIG: IRoleAssignmentsConfig = new RoleAssignmentConfig();

/**
 * This service acts as a key-value store for configuration strategies based on
 * resource type.
 *
 * @class RoleAssignmentsConfigService
 * @extends Service
 */
export default class RoleAssignmentsConfigService extends Service {
  private _map: Map<ResourceType, IRoleAssignmentsConfig> = new Map();

  set(key: ResourceType, value: IRoleAssignmentsConfig | undefined): void {
    if (key && value) {
      this._map.set(key, value);
    }
  }

  get(key: ResourceType): IRoleAssignmentsConfig {
    const entry = this._map.get(key);

    if (entry) {
      return entry;
    }

    return DEFAULT_CONFIG;
  }

  delete(key: ResourceType): boolean | undefined {
    if (key) {
      return this._map.delete(key);
    }
    return;
  }

  has(key: ResourceType): boolean | undefined {
    if (key) {
      return this._map.has(key);
    }
    return;
  }
}
