import Component from '@glimmer/component';

import type LoadingElapsedComponent from './loading/elapsed';
import type LoadingHeaderComponent from './loading/header';
import type LoadingMessageComponent from './loading/message';

interface LoadingSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
  Blocks: {
    default: [
      {
        Elapsed: typeof LoadingElapsedComponent;
        Header: typeof LoadingHeaderComponent;
        Message: typeof LoadingMessageComponent;
      },
    ];
  };
  Element: HTMLElement;
}

/**
 *
 * `Loading` displays a loading icon by default but can also display a header
 * and a message for use when a page is "initializing" or loading any async
 * request.
 *
 *
 * ```
 * <Loading as |L|>
 *   <L.Elapsed />
 *   <L.Header>
 *     {{t 'components.page.hvns.detail.initializing.header'}}
 *   </L.Header>
 *   <L.Message>
 *     {{t 'components.page.hvns.detail.initializing.message'}}
 *   </L.Message>
 * </Loading>
 * ```
 *
 * @class Loading
 *
 */

export default class LoadingComponent extends Component<LoadingSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Loading: typeof LoadingComponent;
    loading: typeof LoadingComponent;
  }
}
