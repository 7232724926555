/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-06-13
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20230613VercelProjectFromJSON, Secrets20230613VercelProjectToJSON, } from './';
export function Secrets20230613VercelOAuthMetadataFromJSON(json) {
    return Secrets20230613VercelOAuthMetadataFromJSONTyped(json, false);
}
export function Secrets20230613VercelOAuthMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'configurationId': !exists(json, 'configuration_id') ? undefined : json['configuration_id'],
        'userOrTeamId': !exists(json, 'user_or_team_id') ? undefined : json['user_or_team_id'],
        'userOrTeamName': !exists(json, 'user_or_team_name') ? undefined : json['user_or_team_name'],
        'isTeam': !exists(json, 'is_team') ? undefined : json['is_team'],
        'projects': !exists(json, 'projects') ? undefined : (json['projects'].map(Secrets20230613VercelProjectFromJSON)),
        'userOrTeamDisplayName': !exists(json, 'user_or_team_display_name') ? undefined : json['user_or_team_display_name'],
    };
}
export function Secrets20230613VercelOAuthMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'configuration_id': value.configurationId,
        'user_or_team_id': value.userOrTeamId,
        'user_or_team_name': value.userOrTeamName,
        'is_team': value.isTeam,
        'projects': value.projects === undefined ? undefined : (value.projects.map(Secrets20230613VercelProjectToJSON)),
        'user_or_team_display_name': value.userOrTeamDisplayName,
    };
}
