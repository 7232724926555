import templateOnlyComponent from '@ember/component/template-only';

interface ChartsStackedBarSummarySignature {
  Args: {
    isOverMax: unknown;
    max: unknown;
    total: unknown;
  };
}

const ChartsStackedBarSummaryComponent =
  templateOnlyComponent<ChartsStackedBarSummarySignature>();

export default ChartsStackedBarSummaryComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Charts::StackedBar::Summary': typeof ChartsStackedBarSummaryComponent;
    'charts/stacked-bar/summary': typeof ChartsStackedBarSummaryComponent;
  }
}
