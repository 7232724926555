/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVagrant20220930RegistryFromJSON, HashicorpCloudVagrant20220930RegistryToJSON, } from './';
export function HashicorpCloudVagrant20220930UpdateRegistryResponseFromJSON(json) {
    return HashicorpCloudVagrant20220930UpdateRegistryResponseFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930UpdateRegistryResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'registry': !exists(json, 'registry') ? undefined : HashicorpCloudVagrant20220930RegistryFromJSON(json['registry']),
    };
}
export function HashicorpCloudVagrant20220930UpdateRegistryResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'registry': HashicorpCloudVagrant20220930RegistryToJSON(value.registry),
    };
}
