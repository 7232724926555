import { RegionProviders } from 'core/utils/regions';
import {
  PEERING_CREATING_STATUS,
  PEERING_PENDING_ACCEPTANCE_STATUS,
  PEERING_ACCEPTED_STATUS,
  PEERING_ACTIVE_STATUS,
  PEERING_FAILED_STATUS,
  PEERING_EXPIRED_STATUS,
  PEERING_REJECTED_STATUS,
  PEERING_DELETING_STATUS,
} from './peerings';

export const NETWORK_PROVIDERS = {
  awsNetworkData: {
    provider: 'aws',
    defaultRegion: 'us-west-2',
  },
  azureNetworkData: {
    provider: 'azure',
    defaultRegion: 'eastus',
  },
};

export const ALL_PROVIDERS = Object.values(RegionProviders);

export const createProvidersIconMapping = (providers) => {
  let providersMapping = {};
  Object.entries(providers).forEach(([key]) => {
    providersMapping[key] = { icon: `${key}-color` };
  });
  return providersMapping;
};

export const PROVIDERS_ICONS_MAPPING =
  createProvidersIconMapping(RegionProviders);

export const isAzureProvider = (provider) => provider === RegionProviders.azure;

export const isAwsProviderNetwork = (network) => {
  return network.providerNetworkData?.awsNetworkData;
};

export const isAzureProviderNetwork = (network) => {
  // Related to HCPE-1641 azureNetworkData
  // azureNetworkData is undefined because HCPE-1641 is not completed yet
  // Once HCPE-1641 is completed, replace lines 21-25 with commented line 20 below
  // return network.providerNetworkData?.azureNetworkData;
  if (isAzureProvider(network.location.region.provider)) {
    return true;
  } else {
    return false;
  }
};

export const providerName = (network) => {
  if (isAwsProviderNetwork(network)) {
    return NETWORK_PROVIDERS['awsNetworkData'].provider;
  }
  if (isAzureProviderNetwork(network)) {
    return NETWORK_PROVIDERS['azureNetworkData'].provider;
  }
};

export const defaultRegion = (network) => {
  if (isAwsProviderNetwork(network)) {
    return NETWORK_PROVIDERS['awsNetworkData'].defaultRegion;
  }
  if (isAzureProviderNetwork(network)) {
    return NETWORK_PROVIDERS['azureNetworkData'].defaultRegion;
  }
};

export const connectionPriority = [
  'PENDING',
  'CREATING',
  'ACTIVE',
  'FAILED',
  'REJECTED',
  'EXPIRED',
  'DELETING',
];

export const sortNetworkConnectionsByStatePrecedence = (
  peeringsAndTgws,
  connectionPriority,
) => {
  let uniqueStates = {
    PENDING: ({ state }) =>
      [PEERING_PENDING_ACCEPTANCE_STATUS, PEERING_ACCEPTED_STATUS].includes(
        state,
      ),
    CREATING: ({ state }) => [PEERING_CREATING_STATUS].includes(state),
    ACTIVE: ({ state }) => [PEERING_ACTIVE_STATUS].includes(state),
    FAILED: ({ state }) => [PEERING_FAILED_STATUS].includes(state),
    REJECTED: ({ state }) => [PEERING_REJECTED_STATUS].includes(state),
    EXPIRED: ({ state }) => [PEERING_EXPIRED_STATUS].includes(state),
    DELETING: ({ state }) => [PEERING_DELETING_STATUS].includes(state),
  };

  let groupedConnections = [];

  connectionPriority.forEach((priority) => {
    let matchedConnections = peeringsAndTgws
      .filter((connection) => {
        return uniqueStates[priority](connection);
      })
      .sort((a, b) => +a.createdAt < +b.createdAt);

    if (matchedConnections.length > 0) {
      groupedConnections.push([...matchedConnections]);
    }
  });

  return groupedConnections.flatMap((arr) => arr);
};
