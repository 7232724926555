/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * State is the state of download.
 *
 *  - AUDIT_LOG_STATE_INVALID: AUDIT_LOG_STATE_INVALID is a sentinel zero value so that an uninitialized value can be
 * detected.
 *  - PENDING: PENDING is the initial state before the workflow starts.
 *  - CREATING: CREATING is the state used when the download is being prepared.
 *  - READY: READY is the completed state when the download is ready
 *  - FAILED: FAILED is a failure state in which the download is not created
 *  - EXPIRED: EXPIRED is the state that the download hits when it is no longer
 * accessible for download
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125AuditLogState;
(function (HashicorpCloudVault20201125AuditLogState) {
    HashicorpCloudVault20201125AuditLogState["AUDITLOGSTATEINVALID"] = "AUDIT_LOG_STATE_INVALID";
    HashicorpCloudVault20201125AuditLogState["PENDING"] = "PENDING";
    HashicorpCloudVault20201125AuditLogState["CREATING"] = "CREATING";
    HashicorpCloudVault20201125AuditLogState["READY"] = "READY";
    HashicorpCloudVault20201125AuditLogState["FAILED"] = "FAILED";
    HashicorpCloudVault20201125AuditLogState["EXPIRED"] = "EXPIRED";
})(HashicorpCloudVault20201125AuditLogState || (HashicorpCloudVault20201125AuditLogState = {}));
export function HashicorpCloudVault20201125AuditLogStateFromJSON(json) {
    return HashicorpCloudVault20201125AuditLogStateFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125AuditLogStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125AuditLogStateToJSON(value) {
    return value;
}
