/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Tier represents the different tiers a Consul cluster can be provisioned as.
 * @export
 * @enum {string}
 */
export var HashicorpCloudConsul20210204ClusterConfigTier;
(function (HashicorpCloudConsul20210204ClusterConfigTier) {
    HashicorpCloudConsul20210204ClusterConfigTier["UNSET"] = "UNSET";
    HashicorpCloudConsul20210204ClusterConfigTier["DEVELOPMENT"] = "DEVELOPMENT";
    HashicorpCloudConsul20210204ClusterConfigTier["STANDARD"] = "STANDARD";
    HashicorpCloudConsul20210204ClusterConfigTier["PLUS"] = "PLUS";
    HashicorpCloudConsul20210204ClusterConfigTier["PREMIUM"] = "PREMIUM";
})(HashicorpCloudConsul20210204ClusterConfigTier || (HashicorpCloudConsul20210204ClusterConfigTier = {}));
export function HashicorpCloudConsul20210204ClusterConfigTierFromJSON(json) {
    return HashicorpCloudConsul20210204ClusterConfigTierFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204ClusterConfigTierFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudConsul20210204ClusterConfigTierToJSON(value) {
    return value;
}
