import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import templateString from 'core/utils/template-string';

/**
 *
 * `NetworksPeeringAwsAutomate` description here.
 *
 *
 * ```
 * <Networks::Peering::Aws::Automate
 *   @peering={{@model.peering}}
 * />
 * ```
 *
 * @class NetworksPeeringAwsAutomate
 *
 */
let awsQuickCreateStackLink = templateString`https://${'awsTargetRegion'}.console.aws.amazon.com/cloudformation/home?region=${'awsTargetRegion'}#/stacks/create/review
?templateURL=${'templateURL'}
&stackName=${'stackName'}
&param_HvnCidrRange=${'hvnCidrRange'}
&param_HvnId=${'hvnId'}
&param_VpcId=${'vpcId'}
&param_VpcPeeringConnectionId=${'vpcPeeringConnectionId'}`;

export default class NetworksPeeringsAwsAutomateComponent extends Component {
  /**
   * Peering is an object that contains the properties of a Peering connection created in HCP
   * @argument peering
   * @type {object}
   *
   *
   * Network is an object that contains the properties of the HVN that a Peering is connected to
   * @argument network
   * @type {object}
   */

  @service analytics;

  @action
  launchQuickCreateStack() {
    let { peering, network } = this.args;

    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_launch_quick_create_button_clicked',
      {
        ...this.args.peering,
      },
    );

    let quickCreateStackLink = awsQuickCreateStackLink({
      awsTargetRegion: peering.target.awsTarget.region,
      // When bumping this, check that it matches the latest (or previously supported) version of hcp-templates.
      // You can check that here: https://github.com/hashicorp/hcp-templates/blob/f0187080d9febc37a47482428a2000f1ec1a7b4a/.bumpversion.cfg#L2
      // Or here: https://github.com/hashicorp/hcp-templates/tags
      templateURL:
        'https://s3.us-west-2.amazonaws.com/hcp-peering/vpc-peering.0.2.11.template',
      stackName: `hcp-peering-${peering.providerPeeringId}`,
      hvnCidrRange: network.cidrBlock,
      hvnId: network.id,
      vpcId: peering.target.awsTarget.vpcId,
      vpcPeeringConnectionId: peering.providerPeeringId,
    });
    window.open(quickCreateStackLink);
  }

  @action goToFeedbackForm() {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_feedback_form_link_clicked',
      {
        ...this.args.peering,
      },
    );
  }

  @action goToExternalAwsQuickCreateLink() {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_aws_quick_create_link_clicked',
      {
        ...this.args.peering,
      },
    );
  }

  @action goToExternalAwsWorkingWithStacksLink() {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_aws_working_with_stacks_link_clicked',
      {
        ...this.args.peering,
      },
    );
  }
}
