/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function GoogleProtobufAnyFromJSON(json) {
    return GoogleProtobufAnyFromJSONTyped(json, false);
}
export function GoogleProtobufAnyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'typeUrl': !exists(json, 'type_url') ? undefined : json['type_url'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}
export function GoogleProtobufAnyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type_url': value.typeUrl,
        'value': value.value,
    };
}
