/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudPacker20221202ModelsGetRegistryTFCRunTaskAPIResponseFromJSON, HashicorpCloudPacker20221202ModelsRegenerateTFCRunTaskHmacKeyResponseFromJSON, InlineObject1ToJSON, } from '../models';
/**
 *
 */
export class PackerTFCRunTaskServiceApi extends runtime.BaseAPI {
    /**
     * Gets the HCP Packer registry API URL and HMAC key to integrate with Terraform Cloud as a Run Task.
     */
    async packerTFCRunTaskServiceGetRegistryTFCRunTaskAPIRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerTFCRunTaskServiceGetRegistryTFCRunTaskAPI.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerTFCRunTaskServiceGetRegistryTFCRunTaskAPI.');
        }
        if (requestParameters.taskType === null || requestParameters.taskType === undefined) {
            throw new runtime.RequiredError('taskType', 'Required parameter requestParameters.taskType was null or undefined when calling packerTFCRunTaskServiceGetRegistryTFCRunTaskAPI.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/runtasks/{task_type}/v2`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"task_type"}}`, encodeURIComponent(String(requestParameters.taskType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsGetRegistryTFCRunTaskAPIResponseFromJSON(jsonValue));
    }
    /**
     * Gets the HCP Packer registry API URL and HMAC key to integrate with Terraform Cloud as a Run Task.
     */
    async packerTFCRunTaskServiceGetRegistryTFCRunTaskAPI(requestParameters) {
        const response = await this.packerTFCRunTaskServiceGetRegistryTFCRunTaskAPIRaw(requestParameters);
        return await response.value();
    }
    /**
     * Regenerates the HMAC key used to sign requests from Terraform Cloud to HCP Packer run tasks.
     */
    async packerTFCRunTaskServiceRegenerateTFCRunTaskHmacKeyRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerTFCRunTaskServiceRegenerateTFCRunTaskHmacKey.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerTFCRunTaskServiceRegenerateTFCRunTaskHmacKey.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling packerTFCRunTaskServiceRegenerateTFCRunTaskHmacKey.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/runtasks/hmac/v2`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject1ToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsRegenerateTFCRunTaskHmacKeyResponseFromJSON(jsonValue));
    }
    /**
     * Regenerates the HMAC key used to sign requests from Terraform Cloud to HCP Packer run tasks.
     */
    async packerTFCRunTaskServiceRegenerateTFCRunTaskHmacKey(requestParameters) {
        const response = await this.packerTFCRunTaskServiceRegenerateTFCRunTaskHmacKeyRaw(requestParameters);
        return await response.value();
    }
}
