/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { HashicorpCloudPacker20221202ModelsCreateChannelBodyToJSON, HashicorpCloudPacker20221202ModelsCreateChannelResponseFromJSON, HashicorpCloudPacker20221202ModelsGetChannelResponseFromJSON, HashicorpCloudPacker20221202ModelsListChannelAssignmentHistoryResponseFromJSON, HashicorpCloudPacker20221202ModelsListChannelsResponseFromJSON, HashicorpCloudPacker20221202ModelsUpdateChannelBodyToJSON, HashicorpCloudPacker20221202ModelsUpdateChannelResponseFromJSON, } from '../models';
/**
 *
 */
export class PackerChannelServiceApi extends runtime.BaseAPI {
    /**
     * Creates a channel either empty or assigned to a version.
     */
    async packerChannelServiceCreateChannelRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerChannelServiceCreateChannel.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerChannelServiceCreateChannel.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerChannelServiceCreateChannel.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling packerChannelServiceCreateChannel.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/channels`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudPacker20221202ModelsCreateChannelBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsCreateChannelResponseFromJSON(jsonValue));
    }
    /**
     * Creates a channel either empty or assigned to a version.
     */
    async packerChannelServiceCreateChannel(requestParameters) {
        const response = await this.packerChannelServiceCreateChannelRaw(requestParameters);
        return await response.value();
    }
    /**
     * Deletes a channel.
     */
    async packerChannelServiceDeleteChannelRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerChannelServiceDeleteChannel.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerChannelServiceDeleteChannel.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerChannelServiceDeleteChannel.');
        }
        if (requestParameters.channelName === null || requestParameters.channelName === undefined) {
            throw new runtime.RequiredError('channelName', 'Required parameter requestParameters.channelName was null or undefined when calling packerChannelServiceDeleteChannel.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/channels/{channel_name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))).replace(`{${"channel_name"}}`, encodeURIComponent(String(requestParameters.channelName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Deletes a channel.
     */
    async packerChannelServiceDeleteChannel(requestParameters) {
        const response = await this.packerChannelServiceDeleteChannelRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieves a channel with the version that it is currently assigned if any.
     */
    async packerChannelServiceGetChannelRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerChannelServiceGetChannel.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerChannelServiceGetChannel.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerChannelServiceGetChannel.');
        }
        if (requestParameters.channelName === null || requestParameters.channelName === undefined) {
            throw new runtime.RequiredError('channelName', 'Required parameter requestParameters.channelName was null or undefined when calling packerChannelServiceGetChannel.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/channels/{channel_name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))).replace(`{${"channel_name"}}`, encodeURIComponent(String(requestParameters.channelName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsGetChannelResponseFromJSON(jsonValue));
    }
    /**
     * Retrieves a channel with the version that it is currently assigned if any.
     */
    async packerChannelServiceGetChannel(requestParameters) {
        const response = await this.packerChannelServiceGetChannelRaw(requestParameters);
        return await response.value();
    }
    /**
     * Lists a channel assignment history.
     */
    async packerChannelServiceListChannelAssignmentHistoryRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerChannelServiceListChannelAssignmentHistory.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerChannelServiceListChannelAssignmentHistory.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerChannelServiceListChannelAssignmentHistory.');
        }
        if (requestParameters.channelName === null || requestParameters.channelName === undefined) {
            throw new runtime.RequiredError('channelName', 'Required parameter requestParameters.channelName was null or undefined when calling packerChannelServiceListChannelAssignmentHistory.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        if (requestParameters.paginationPageSize !== undefined) {
            queryParameters['pagination.page_size'] = requestParameters.paginationPageSize;
        }
        if (requestParameters.paginationNextPageToken !== undefined) {
            queryParameters['pagination.next_page_token'] = requestParameters.paginationNextPageToken;
        }
        if (requestParameters.paginationPreviousPageToken !== undefined) {
            queryParameters['pagination.previous_page_token'] = requestParameters.paginationPreviousPageToken;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/channels/{channel_name}/history`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))).replace(`{${"channel_name"}}`, encodeURIComponent(String(requestParameters.channelName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsListChannelAssignmentHistoryResponseFromJSON(jsonValue));
    }
    /**
     * Lists a channel assignment history.
     */
    async packerChannelServiceListChannelAssignmentHistory(requestParameters) {
        const response = await this.packerChannelServiceListChannelAssignmentHistoryRaw(requestParameters);
        return await response.value();
    }
    /**
     * Lists all channels of a given bucket.
     */
    async packerChannelServiceListChannelsRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerChannelServiceListChannels.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerChannelServiceListChannels.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerChannelServiceListChannels.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/channels`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsListChannelsResponseFromJSON(jsonValue));
    }
    /**
     * Lists all channels of a given bucket.
     */
    async packerChannelServiceListChannels(requestParameters) {
        const response = await this.packerChannelServiceListChannelsRaw(requestParameters);
        return await response.value();
    }
    /**
     * Updates the channel assignment and/or restriction.
     */
    async packerChannelServiceUpdateChannelRaw(requestParameters) {
        if (requestParameters.locationOrganizationId === null || requestParameters.locationOrganizationId === undefined) {
            throw new runtime.RequiredError('locationOrganizationId', 'Required parameter requestParameters.locationOrganizationId was null or undefined when calling packerChannelServiceUpdateChannel.');
        }
        if (requestParameters.locationProjectId === null || requestParameters.locationProjectId === undefined) {
            throw new runtime.RequiredError('locationProjectId', 'Required parameter requestParameters.locationProjectId was null or undefined when calling packerChannelServiceUpdateChannel.');
        }
        if (requestParameters.bucketName === null || requestParameters.bucketName === undefined) {
            throw new runtime.RequiredError('bucketName', 'Required parameter requestParameters.bucketName was null or undefined when calling packerChannelServiceUpdateChannel.');
        }
        if (requestParameters.channelName === null || requestParameters.channelName === undefined) {
            throw new runtime.RequiredError('channelName', 'Required parameter requestParameters.channelName was null or undefined when calling packerChannelServiceUpdateChannel.');
        }
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling packerChannelServiceUpdateChannel.');
        }
        const queryParameters = {};
        if (requestParameters.locationRegionProvider !== undefined) {
            queryParameters['location.region.provider'] = requestParameters.locationRegionProvider;
        }
        if (requestParameters.locationRegionRegion !== undefined) {
            queryParameters['location.region.region'] = requestParameters.locationRegionRegion;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }
        const response = await this.request({
            path: `/packer/2022-12-02/organizations/{location.organization_id}/projects/{location.project_id}/buckets/{bucket_name}/channels/{channel_name}`.replace(`{${"location.organization_id"}}`, encodeURIComponent(String(requestParameters.locationOrganizationId))).replace(`{${"location.project_id"}}`, encodeURIComponent(String(requestParameters.locationProjectId))).replace(`{${"bucket_name"}}`, encodeURIComponent(String(requestParameters.bucketName))).replace(`{${"channel_name"}}`, encodeURIComponent(String(requestParameters.channelName))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: HashicorpCloudPacker20221202ModelsUpdateChannelBodyToJSON(requestParameters.body),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => HashicorpCloudPacker20221202ModelsUpdateChannelResponseFromJSON(jsonValue));
    }
    /**
     * Updates the channel assignment and/or restriction.
     */
    async packerChannelServiceUpdateChannel(requestParameters) {
        const response = await this.packerChannelServiceUpdateChannelRaw(requestParameters);
        return await response.value();
    }
}
