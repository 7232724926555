/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - ClusterCreationGenerate: ** CREATE MILESTONES **
 * ClusterCreationGenerate represents the first milestone in cluster creation
 *  - ClusterCreationCreate: ClusterCreationCreate represents the second milestone in cluster creation
 *  - ClusterCreationBootstrap: ClusterCreationBootstrap represents the third milestone in cluster creation
 *  - ClusterCreationValidate: ClusterCreationValidate represents the fourth milestone in cluster creation
 * @export
 * @enum {string}
 */
export var HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestone;
(function (HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestone) {
    HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestone["INVALID"] = "INVALID";
    HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestone["ClusterCreationGenerate"] = "ClusterCreationGenerate";
    HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestone["ClusterCreationCreate"] = "ClusterCreationCreate";
    HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestone["ClusterCreationBootstrap"] = "ClusterCreationBootstrap";
    HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestone["ClusterCreationValidate"] = "ClusterCreationValidate";
})(HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestone || (HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestone = {}));
export function HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestoneFromJSON(json) {
    return HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestoneFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestoneFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVault20201125GetCurrentMilestoneResponseCurrentMilestoneToJSON(value) {
    return value;
}
