import Component from '@glimmer/component';

interface TooltipConditionalSignature {
  Args: {
    tooltipOptions: unknown;
    useTooltip: unknown;
  };
  Blocks: {
    anchor: [];
    tooltip: [];
  };
}

/**
 *
 * The TooltipConditional component yields the tooltip sub-elements to the
 * Tooltip component if the provided property evaluates to true. 
 * This is necessary because the Tooltip component renders an empty div
 * with styling when there is no content in the :anchor.
 *
 *
 * ```
 * <TooltipConditional @useTooltip={{true}}>
 *  <:tooltip>
      Content
    </:tooltip>
    <:anchor>
      Anchor
    </:anchor>
 * </TooltipConditional>
 * ```
 *
 * @class TooltipConditional
 */

/**
 * Whether to display the Tooltip or not
 *
 * @argument useTooltip
 * @type {boolean}
 */

export default class TooltipConditionalComponent extends Component<TooltipConditionalSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TooltipConditional: typeof TooltipConditionalComponent;
    'tooltip-conditional': typeof TooltipConditionalComponent;
  }
}
