/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215AdminPartitionFromJSON, HashicorpCloudGlobalNetworkManager20220215AdminPartitionToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ListClusterPartitionsResponseFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ListClusterPartitionsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ListClusterPartitionsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'partitions': !exists(json, 'partitions') ? undefined : (json['partitions'].map(HashicorpCloudGlobalNetworkManager20220215AdminPartitionFromJSON)),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ListClusterPartitionsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'partitions': value.partitions === undefined ? undefined : (value.partitions.map(HashicorpCloudGlobalNetworkManager20220215AdminPartitionToJSON)),
    };
}
