import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { supportTicketPrefillHref } from '../../utils/support-ticket.js';

import type UserContextService from '../../services/user-context.ts';

interface SystemNotificationsAccessRestrictedSignature {
  Args: {
    flash: unknown;
  };
}

export default class SystemNotificationsAccessRestrictedComponent extends Component<SystemNotificationsAccessRestrictedSignature> {
  @service declare readonly userContext: UserContextService;

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SystemNotifications::AccessRestricted': typeof SystemNotificationsAccessRestrictedComponent;
    'system-notifications/access-restricted': typeof SystemNotificationsAccessRestrictedComponent;
  }
}
