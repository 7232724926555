import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked, TrackedSet } from 'tracked-built-ins';

import type {
  HTMLElementEvent,
  HdsDropdownElement,
} from '../../../utils/filter-bar/index.ts';

interface SearchHistoryItem {
  history: string;
  initiallySelected: boolean;
}
interface Args {
  dd: HdsDropdownElement;
  textValue: string;
  selectedValues: string[];
  onSubmit: (value: string[], dropdown: HTMLElement) => void;
  resetDropdown: () => void;
  helperText: string;
  searchHistory: TrackedSet<string>;
  trackInteraction: () => void;
  validator?: (input: string) => string | undefined;
}

/**
 * The textSearchDropdown component is a custom dropdown body which allows the
 * user to enter freeform text for search and query purposes. Previous selections
 * from the current session can be displayed as well, and the component allows
 * multiple concurrent selections.
 *
 * The component can be used inside an Hds dropdown or within a FilterBar
 * component instance.
 *
 * Usage:
 *  <FilterBar::TextSearchDropdown
 *     @dd={{dd}}
 *     @onSubmit={{@onSubmit}}
 *     @textValue={{@optionValue}}
 *     @helperText={{@helperText}}
 *     @selectedValues={{@selectedValues}}
 *     @resetDropdown={{
 *       fn
 *       @resetDropdownSelection
 *       @id
 *     }}
 *     @searchHistory={{@selectionHistory}}
 *     @trackInteraction={{@trackInteraction}}
 *  />
 *
 * An optional tracking callback can be provided to instrument the dropdown.
 *
 * The @selectedValues parameter allows items to be pre-selected on render.
 *
 * @class TextSearchDropdownComponent
 */

export default class TextSearchDropdownComponent extends Component<Args> {
  @tracked currentSearchText = '';
  @tracked checkedHistoryItems: string[] = [];
  @tracked searchHistoryItems: SearchHistoryItem[] = [];
  @tracked hasSearchChange = false;
  @tracked error?: string;

  get showClearButton() {
    return this.args.selectedValues.length;
  }

  get submitDisabled() {
    return !this.hasSearchChange;
  }

  @action
  initializeSearchHistoryCheckboxes() {
    if (!this.args.searchHistory.size) return;

    const currentlySelectedValues = this.args.selectedValues[0]?.split(',');
    const searchHistoryCheckboxItems = Array.from(this.args.searchHistory); // purely for convenience, so we can use map instead of a for loop

    const searchHistoryItems = searchHistoryCheckboxItems.map((history) => {
      const initiallySelected =
        currentlySelectedValues?.includes(history) ?? false;
      if (initiallySelected) this.checkedHistoryItems.push(history);
      return {
        history,
        initiallySelected,
      };
    });

    this.searchHistoryItems = searchHistoryItems;
  }

  @action
  executeOnSelect() {
    if (this.args.validator && this.currentSearchText !== '') {
      const error = this.args.validator(this.currentSearchText);
      if (error) {
        this.error = error;
        return;
      }
    }
    this.args.onSubmit(
      [
        ...this.checkedHistoryItems,
        ...(this.currentSearchText && [this.currentSearchText]),
      ],
      this.args.dd,
    );
    this.currentSearchText = '';
    this.error = undefined;
    this.hasSearchChange = false;
    this.args.trackInteraction();
  }

  @action
  setSearchText(evt: HTMLElementEvent<HTMLInputElement>) {
    this.currentSearchText = evt.target.value;
    this.hasSearchChange = true;
  }

  @action
  resetText() {
    this.currentSearchText = '';
    this.checkedHistoryItems = [];
    this.args.onSubmit([''], this.args.dd);
    this.args.resetDropdown();
    this.hasSearchChange = false;
    this.args.trackInteraction();
  }

  @action
  onHistoryItemChecked(evt: Event) {
    const { value, checked } = evt.target as HTMLInputElement;
    checked
      ? (this.checkedHistoryItems = [...this.checkedHistoryItems, value])
      : (this.checkedHistoryItems = this.checkedHistoryItems.filter(
          (historyItem) => historyItem !== value,
        ));
    this.hasSearchChange = true;
  }

  @action
  onKeyUp(e: KeyboardEvent) {
    if (e.key === 'Enter' && !this.submitDisabled) {
      this.executeOnSelect();
    }
  }
}
