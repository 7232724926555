import templateOnlyComponent from '@ember/component/template-only';

interface ToolbarFiltersSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

const ToolbarFiltersComponent =
  templateOnlyComponent<ToolbarFiltersSignature>();

export default ToolbarFiltersComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Toolbar::Filters': typeof ToolbarFiltersComponent;
    'toolbar/filters': typeof ToolbarFiltersComponent;
  }
}
