/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function SecretServiceUpdateGatewayPoolBodyFromJSON(json) {
    return SecretServiceUpdateGatewayPoolBodyFromJSONTyped(json, false);
}
export function SecretServiceUpdateGatewayPoolBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}
export function SecretServiceUpdateGatewayPoolBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
    };
}
