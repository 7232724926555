/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GoogleProtobufAnyFromJSON, GoogleProtobufAnyToJSON, GoogleRpcStatusFromJSON, GoogleRpcStatusToJSON, HashicorpCloudLocationLinkFromJSON, HashicorpCloudLocationLinkToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, HashicorpCloudOperationOperationStateFromJSON, HashicorpCloudOperationOperationStateToJSON, } from './';
export function HashicorpCloudOperationOperationFromJSON(json) {
    return HashicorpCloudOperationOperationFromJSONTyped(json, false);
}
export function HashicorpCloudOperationOperationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'state': !exists(json, 'state') ? undefined : HashicorpCloudOperationOperationStateFromJSON(json['state']),
        'error': !exists(json, 'error') ? undefined : GoogleRpcStatusFromJSON(json['error']),
        'response': !exists(json, 'response') ? undefined : GoogleProtobufAnyFromJSON(json['response']),
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'link': !exists(json, 'link') ? undefined : HashicorpCloudLocationLinkFromJSON(json['link']),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}
export function HashicorpCloudOperationOperationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'state': HashicorpCloudOperationOperationStateToJSON(value.state),
        'error': GoogleRpcStatusToJSON(value.error),
        'response': GoogleProtobufAnyToJSON(value.response),
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'link': HashicorpCloudLocationLinkToJSON(value.link),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
