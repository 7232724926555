/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul
 * API for managing Consul clusters.
 *
 * The version of the OpenAPI document: 2021-02-04
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLinkFromJSON, HashicorpCloudLocationLinkToJSON, } from './';
export function HashicorpCloudConsul20210204ConsulConfigFromJSON(json) {
    return HashicorpCloudConsul20210204ConsulConfigFromJSONTyped(json, false);
}
export function HashicorpCloudConsul20210204ConsulConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'datacenter': !exists(json, 'datacenter') ? undefined : json['datacenter'],
        'connectEnabled': !exists(json, 'connect_enabled') ? undefined : json['connect_enabled'],
        'primary': !exists(json, 'primary') ? undefined : HashicorpCloudLocationLinkFromJSON(json['primary']),
    };
}
export function HashicorpCloudConsul20210204ConsulConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'datacenter': value.datacenter,
        'connect_enabled': value.connectEnabled,
        'primary': HashicorpCloudLocationLinkToJSON(value.primary),
    };
}
