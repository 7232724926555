import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';

export default class RouteWithUserContext extends Route {
  @service api;
  @service userContext;
  @service router;

  queryParams = {
    project_id: {
      refreshModel: true,
    },
    org_id: {
      refreshModel: true,
    },
  };

  async model({ org_id, project_id }) {
    if (!org_id && !project_id) {
      return {};
    }
    let isProjectContext = project_id ? true : false;

    let { project } = project_id
      ? await this.api.resourceManager.project.projectServiceGet(project_id)
      : {
          project: (
            await this.api.resourceManager.project.projectServiceList(
              'ORGANIZATION',
              org_id
            )
          ).projects[0],
        };
    let { organization } = project_id
      ? await this.api.resourceManager.org.organizationServiceGet(
          project.parent.id
        )
      : await this.api.resourceManager.org.organizationServiceGet(org_id);

    await this.userContext.setOrganization(organization);

    if (isProjectContext) {
      await this.userContext.setProject(project);
    } else {
      await this.userContext.setProject(null);
    }

    return {
      isProjectContext,
      project,
      organization,
    };
  }

  async afterModel(model) {
    if (!model || (!model.organization && !model.project)) {
      let { organizations } =
        await this.api.resourceManager.org.organizationServiceList();
      this.router.transitionTo(this.routeName, {
        queryParams: {
          org_id: organizations[0].id,
        },
      });
    }
  }
}
