import type { HashicorpCloudResourcemanagerPolicyBindingMember } from '@clients/cloud-resource-manager';
import type { PolicyManagerLocation } from '../types/policy-manager-location.ts';

export default function iamFindMembersFromPolicyManagerLocations(
  policyManagerLocations: Array<PolicyManagerLocation> = [],
): Array<HashicorpCloudResourcemanagerPolicyBindingMember> {
  if (
    !policyManagerLocations?.length ||
    !Array.isArray(policyManagerLocations)
  ) {
    return [];
  }

  type PolicyMemberMap = Map<
    HashicorpCloudResourcemanagerPolicyBindingMember['memberId'],
    HashicorpCloudResourcemanagerPolicyBindingMember
  >;

  const memberMap = policyManagerLocations.reduce(
    (map: PolicyMemberMap, policyManagerLocation) => {
      const policyMembers = policyManagerLocation?.policyManager?.findMembers();
      return policyMembers.reduce(
        (accumulatorMap: PolicyMemberMap, member) =>
          accumulatorMap.set(member.memberId, member),
        map,
      );
    },
    new Map<
      HashicorpCloudResourcemanagerPolicyBindingMember['memberId'],
      HashicorpCloudResourcemanagerPolicyBindingMember
    >(),
  );

  return Array.from(memberMap.values());
}
