/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { TaxSettingsExemptionStatusFromJSON, TaxSettingsExemptionStatusToJSON, TaxSettingsIdTypeFromJSON, TaxSettingsIdTypeToJSON, } from './';
export function Billing20201105TaxSettingsFromJSON(json) {
    return Billing20201105TaxSettingsFromJSONTyped(json, false);
}
export function Billing20201105TaxSettingsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'exemptionStatus': !exists(json, 'exemption_status') ? undefined : TaxSettingsExemptionStatusFromJSON(json['exemption_status']),
        'idType': !exists(json, 'id_type') ? undefined : TaxSettingsIdTypeFromJSON(json['id_type']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}
export function Billing20201105TaxSettingsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'exemption_status': TaxSettingsExemptionStatusToJSON(value.exemptionStatus),
        'id_type': TaxSettingsIdTypeToJSON(value.idType),
        'id': value.id,
    };
}
