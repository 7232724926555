/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevelFromJSON, HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevelToJSON, HashicorpCloudGlobalNetworkManager20220215ClusterSourceFromJSON, HashicorpCloudGlobalNetworkManager20220215ClusterSourceToJSON, HashicorpCloudGlobalNetworkManager20220215ClusterStateFromJSON, HashicorpCloudGlobalNetworkManager20220215ClusterStateToJSON, HashicorpCloudGlobalNetworkManager20220215LicensingFromJSON, HashicorpCloudGlobalNetworkManager20220215LicensingToJSON, HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ClusterFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ClusterFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'bootstrapExpect': !exists(json, 'bootstrap_expect') ? undefined : json['bootstrap_expect'],
        'activeServerCount': !exists(json, 'active_server_count') ? undefined : json['active_server_count'],
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'resourceLinkUrl': !exists(json, 'resource_link_url') ? undefined : json['resource_link_url'],
        'state': !exists(json, 'state') ? undefined : HashicorpCloudGlobalNetworkManager20220215ClusterStateFromJSON(json['state']),
        'source': !exists(json, 'source') ? undefined : HashicorpCloudGlobalNetworkManager20220215ClusterSourceFromJSON(json['source']),
        'consulVersion': !exists(json, 'consul_version') ? undefined : json['consul_version'],
        'hcpManaged': !exists(json, 'hcp_managed') ? undefined : json['hcp_managed'],
        'existingCluster': !exists(json, 'existing_cluster') ? undefined : json['existing_cluster'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'licensing': !exists(json, 'licensing') ? undefined : HashicorpCloudGlobalNetworkManager20220215LicensingFromJSON(json['licensing']),
        'consulAccessLevel': !exists(json, 'consul_access_level') ? undefined : HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevelFromJSON(json['consul_access_level']),
        'hcpPortalUrl': !exists(json, 'hcp_portal_url') ? undefined : json['hcp_portal_url'],
        'trustDomain': !exists(json, 'trust_domain') ? undefined : json['trust_domain'],
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ClusterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'id': value.id,
        'bootstrap_expect': value.bootstrapExpect,
        'active_server_count': value.activeServerCount,
        'resource_id': value.resourceId,
        'resource_link_url': value.resourceLinkUrl,
        'state': HashicorpCloudGlobalNetworkManager20220215ClusterStateToJSON(value.state),
        'source': HashicorpCloudGlobalNetworkManager20220215ClusterSourceToJSON(value.source),
        'consul_version': value.consulVersion,
        'hcp_managed': value.hcpManaged,
        'existing_cluster': value.existingCluster,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'licensing': HashicorpCloudGlobalNetworkManager20220215LicensingToJSON(value.licensing),
        'consul_access_level': HashicorpCloudGlobalNetworkManager20220215ClusterConsulAccessLevelToJSON(value.consulAccessLevel),
        'hcp_portal_url': value.hcpPortalUrl,
        'trust_domain': value.trustDomain,
    };
}
