import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task, dropTask, rawTimeout } from 'ember-concurrency';
import { DEBUG } from '@glimmer/env';
import { warn } from '@ember/debug';
import { getOwner } from '@ember/owner';
import window from 'ember-window-mock';

export default class OperationService extends Service {
  @service api;
  @service userContext;

  @tracked changedOperations = [];
  @tracked _operations = [];
  @tracked firstFetch = false;

  constructor() {
    super(...arguments);
    document.addEventListener('visibilitychange', this.visibilityChange);
  }

  get config() {
    return {
      app: getOwner(this).resolveRegistration('config:environment')['APP'],
    };
  }

  willDestroy() {
    document.removeEventListener('visibilitychange', this.visibilityChange);
    super.willDestroy(...arguments);
  }

  @action
  visibilityChange() {
    if (document.hidden) {
      this.stop();
    } else {
      this.start();
    }
  }

  set operations(val) {
    if (this._operations.length === 0) {
      this._operations = this.changedOperations = val;
      this.firstFetch = true;
      return;
    }
    this.firstFetch = false;
    let changedOperations = val.reduce((changed, op) => {
      let oldOp = this._operations.find((o) => o.id === op.id);
      // if it's new push to changedOps
      if (!oldOp) {
        changed.push(op);
      } else if (
        // if it's old, and the states don't match
        oldOp &&
        op.state !== oldOp.state
      ) {
        changed.push(op);
      }
      return changed;
    }, []);
    this.changedOperations = changedOperations;

    // finally set the new operations
    this._operations = val;
  }

  get operations() {
    return this._operations;
  }

  start() {
    warn('operation service starting', { id: 'service.operation.start' });

    this.pollOperations.perform();
  }

  stop() {
    warn('operation service stopping', { id: 'service.operation.stop' });
    this.pollOperations.cancelAll();
  }

  addOperation(operation) {
    return (this.operations = [operation, ...this.operations]);
  }

  updateOperation(operation) {
    let opIndex = this.operations.findIndex((op) => op.id === operation.id);
    if (opIndex !== -1) {
      this.operations.splice(opIndex, 1, operation);

      this.operations = [...this.operations];
    } else {
      this.addOperation(operation);
    }
  }

  @dropTask
  *pollOperations() {
    // use 1-based counter so we can use the counter for exponential backoff
    let errCounter = 1;
    while (true) {
      let { organization, project } = this.userContext;
      if (organization && project) {
        try {
          let { operations } = yield this.api.operation.list(
            organization.id,
            project.id
          );
          this.operations = operations;
          errCounter = 1;
        } catch (e) {
          if (e && e.status === 401) {
            // if a user has been away from the tab long enough that their token
            // expired, we should reload the page so they can be redirected back
            // to sign in
            window.location.reload();
          }
          errCounter = errCounter + 1;
          if (DEBUG) {
            // eslint-disable-next-line
            console.error(e);
          }
        }
      }
      yield rawTimeout(this.config.app.pollingInterval * errCounter);
    }
  }

  waitFor(operationId) {
    // linked is necessary here because we call `waitFor` in an e-c task
    // calling linked ensures that when one task is canceled, this one will be too
    return this.startWait.linked().perform(operationId);
  }

  cancelWait() {
    if (this.abortController) {
      this.abortController.abort();
      delete this.abortController;
    }
    this.startWait.cancelAll();
  }

  // `startTask` takes and operation id and will continually call the operation
  // service's `wait` endpoint with a 50s timeout.
  //
  // This continues until either:
  //
  // 1. The returned operation reaches a `DONE` state, or
  // 2. Three successive calls error

  @task
  *startWait(operationId) {
    let { organization, project } = this.userContext;
    let errorCount = 0;
    let maxErrorRetries = 3;
    let serviceTimeout = '50s';
    if (!organization || !project) {
      return;
    }
    while (true) {
      try {
        // set up a cancelable fetch call
        this.abortController = this.api.cancelable(
          'wait',
          organization.id,
          project.id,
          operationId
        );
        // this call will return when the operation state is DONE, or the
        // timeout is hit
        let { operation } = yield this.api.operation.wait(
          organization.id,
          project.id,
          operationId,
          serviceTimeout
        );
        this.updateOperation(operation);
        // reset error count
        errorCount = 0;
        // if the operation is done, break the loop, otherwise fall through
        // and wait will get called again because of the infinite loop
        if (operation.state === 'DONE') {
          return operation;
        }
      } catch (e) {
        if (DEBUG) {
          // eslint-disable-next-line
          console.error(e);
        }
        errorCount = errorCount + 1;
        // only break after hitting max retry count
        if (errorCount === maxErrorRetries) {
          break;
        }
      }
    }
  }
}
