import { helper as buildHelper } from '@ember/component/helper';

const I18N_PREFIX = 'helpers.options-for-http-error';

export const DEFAULT_ERROR_CODE = 'default';
export const ERROR_CODE_MAPPING = {
  form: {
    label: `${I18N_PREFIX}.form.label`,
    message: `${I18N_PREFIX}.form.message`,
    icon: 'x-square-fill',
  },
  403: {
    label: `${I18N_PREFIX}.403.label`,
    message: `${I18N_PREFIX}.403.message`,
    icon: 'cloud-off',
  },
  404: {
    label: `${I18N_PREFIX}.404.label`,
    message: `${I18N_PREFIX}.404.message`,
    icon: 'help',
  },
  500: {
    label: `${I18N_PREFIX}.500.label`,
    message: `${I18N_PREFIX}.500.message`,
    icon: 'alert-circle',
  },
  [DEFAULT_ERROR_CODE]: {
    label: `${I18N_PREFIX}.default.label`,
    message: `${I18N_PREFIX}.default.message`,
    icon: 'alert-circle',
  },
};

export const ERROR_CODE_SCALE = Object.keys(ERROR_CODE_MAPPING);

export function optionForHttpError([code = 'default']) {
  let option = ERROR_CODE_MAPPING[code] || ERROR_CODE_MAPPING['default'];

  return {
    ...option,
  };
}

export default buildHelper(optionForHttpError);
