/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function LogService20210330SearchRequestFromJSON(json) {
    return LogService20210330SearchRequestFromJSONTyped(json, false);
}
export function LogService20210330SearchRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationId': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'query': !exists(json, 'query') ? undefined : json['query'],
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'pageSize': !exists(json, 'page_size') ? undefined : json['page_size'],
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'orderBy': !exists(json, 'order_by') ? undefined : json['order_by'],
    };
}
export function LogService20210330SearchRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_id': value.organizationId,
        'query': value.query,
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'page_size': value.pageSize,
        'next_page_token': value.nextPageToken,
        'order_by': value.orderBy,
    };
}
