import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { SystemNotifications, SYSTEM_TYPE } from '../utils/constants.ts';

import type FlashMessageService from 'ember-cli-flash/services/flash-messages';
import type EnginesRouterService from 'ember-engines-router-service/services/router';
import type FlashObject from 'ember-cli-flash/flash/object';

interface SystemNotificationsSignature {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Args: {};
}

/**
 *
 * `SystemNotifications` utilizes the FlashMessage queue to render
 * a choice of Hds::Alert banners based on flash.id. The SystemNotifications
 * will only ever render one banner at a time based on priority.
 *
 * ```
 * <SystemNotifications />
 * ```
 *
 * @class SystemNotifications
 *
 */

/**
 * The flash object being triggered.
 * @typedef {Object} Flash
 * @property {function?} action - Flash message action callback (optional).
 * @property {string?} actionText - Flash message action button text (optional).
 * @property {string} content - Flash message content / description i18n string.
 * @property {string} message - Flash message title i18n string.
 */

export default class SystemNotificationsComponent extends Component<SystemNotificationsSignature> {
  @service declare readonly flashMessages: FlashMessageService;
  @service declare readonly router: EnginesRouterService;

  SYSTEM_TYPE = SYSTEM_TYPE;

  CONTRACT_EXPIRED = SystemNotifications['CONTRACT_EXPIRED'];
  CONTRACT_EXPIRING = SystemNotifications['CONTRACT_EXPIRING'];
  FCP_LOW_BALANCE = SystemNotifications['FCP_LOW_BALANCE'];
  FCP_ZERO_BALANCE = SystemNotifications['FCP_ZERO_BALANCE'];
  ACCESS_RESTRICTED = SystemNotifications['ACCESS_RESTRICTED'];

  ADD_CC_ROUTE = 'billing.accounts.account.payments.credit-card.index';

  get activeRoute() {
    return this.router.currentRouteName;
  }

  /**
   * Executes the `onAction` method if it exists.
   * @param {Flash} flash - The flash message.
   */
  @dropTask
  *action(flash: FlashObject) {
    // @ts-expect-error: we are extending the object
    if (flash.onAction) {
      // @ts-expect-error: we are extending the object
      if (typeof flash.onAction === 'function') {
        // @ts-expect-error: we are extending the object
        yield flash.onAction();
      } else if (
        // TODO: Swap this conditional to check the instance of `onAction`.
        //
        // Ideally this would check if `onAction` is an instanceof an
        // ember-concurrency task. I think this would be a better way to
        // conditionally perform a task instead of checking if `perform` exists.
        // @ts-expect-error: we are extending the object
        flash.onAction.perform &&
        // @ts-expect-error: we are extending the object
        typeof flash.onAction.perform === 'function'
      ) {
        // @ts-expect-error: we are extending the object
        yield flash.onAction.perform();
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SystemNotifications: typeof SystemNotificationsComponent;
    'system-notifications': typeof SystemNotificationsComponent;
  }
}
