/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vagrant Box Registry
 * API for managing Vagrant boxes.
 *
 * The version of the OpenAPI document: 2022-09-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudLocationLocationFromJSON, HashicorpCloudLocationLocationToJSON, HashicorpCloudVagrant20220930BoxFromJSON, } from './';
export function HashicorpCloudVagrant20220930RegistryFromJSON(json) {
    return HashicorpCloudVagrant20220930RegistryFromJSONTyped(json, false);
}
export function HashicorpCloudVagrant20220930RegistryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'location': !exists(json, 'location') ? undefined : HashicorpCloudLocationLocationFromJSON(json['location']),
        'activated': !exists(json, 'activated') ? undefined : json['activated'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'descriptionHtml': !exists(json, 'description_html') ? undefined : json['description_html'],
        'gravatarUrl': !exists(json, 'gravatar_url') ? undefined : json['gravatar_url'],
        'boxes': !exists(json, 'boxes') ? undefined : (json['boxes'].map(HashicorpCloudVagrant20220930BoxFromJSON)),
    };
}
export function HashicorpCloudVagrant20220930RegistryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'location': HashicorpCloudLocationLocationToJSON(value.location),
        'description': value.description,
    };
}
