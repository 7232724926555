import BaseAbility from '../abilities/base-ability';
import { ACTION_CREATE, ACTION_UPDATE } from '../utils/permission-types/index';
import { inject as service } from '@ember/service';

export default class extends BaseAbility {
  @service abilities;
  @service snapshots;

  prefix = this.snapshots.permissionsPrefix;

  /**
   * Determine if create is available based on permissions and billing status.
   *
   * @return {boolean}
   */
  get canCreate() {
    return (
      this.abilities.can('bill billing-account') &&
      this.permissions.has(this.generate(ACTION_CREATE))
    );
  }

  /**
   * Determine if restore is available based on permissions,
   * the snapshot as the model, and the cluster.
   *
   * @return {boolean}
   */
  get canRestore() {
    let clusterUpdate = this.permissions.generate(
      this.snapshots.permissionsClusterPrefix,
      ACTION_UPDATE,
    );
    let handlers = this.snapshots.handlers;
    return (
      this.permissions.has(clusterUpdate) &&
      handlers.isReady(this.model) &&
      (this.cluster.state === 'RUNNING' || this.cluster.state === 'LOCKED')
    );
  }

  /**
   * Determine if a user can take any action on a snapshot based on the snapshot
   * as the model, other abilities, and the cluster.
   *
   * @return {boolean}
   */
  get canTakeAction() {
    let handlers = this.snapshots.handlers;
    return (
      (this.canRestore || this.canUpdate || this.canDelete) &&
      handlers.isReady(this.model)
    );
  }
}
