/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudVault20201125VaultConfigFromJSON(json) {
    return HashicorpCloudVault20201125VaultConfigFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125VaultConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'initialVersion': !exists(json, 'initial_version') ? undefined : json['initial_version'],
        'maxLeaseTtl': !exists(json, 'max_lease_ttl') ? undefined : json['max_lease_ttl'],
    };
}
export function HashicorpCloudVault20201125VaultConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'namespace': value.namespace,
        'initial_version': value.initialVersion,
        'max_lease_ttl': value.maxLeaseTtl,
    };
}
