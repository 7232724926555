/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudVault20201125ClusterFromJSON, HashicorpCloudVault20201125ClusterToJSON, HashicorpCloudVaultLink20221107LinkedClusterFromJSON, HashicorpCloudVaultLink20221107LinkedClusterToJSON, } from './';
export function HashicorpCloudVault20201125ListAllClustersResponseVaultClusterFromJSON(json) {
    return HashicorpCloudVault20201125ListAllClustersResponseVaultClusterFromJSONTyped(json, false);
}
export function HashicorpCloudVault20201125ListAllClustersResponseVaultClusterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'managedCluster': !exists(json, 'managed_cluster') ? undefined : HashicorpCloudVault20201125ClusterFromJSON(json['managed_cluster']),
        'linkedCluster': !exists(json, 'linked_cluster') ? undefined : HashicorpCloudVaultLink20221107LinkedClusterFromJSON(json['linked_cluster']),
    };
}
export function HashicorpCloudVault20201125ListAllClustersResponseVaultClusterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'managed_cluster': HashicorpCloudVault20201125ClusterToJSON(value.managedCluster),
        'linked_cluster': HashicorpCloudVaultLink20221107LinkedClusterToJSON(value.linkedCluster),
    };
}
