/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function Secrets20231128UsageOptLimitRemainingFromJSON(json) {
    return Secrets20231128UsageOptLimitRemainingFromJSONTyped(json, false);
}
export function Secrets20231128UsageOptLimitRemainingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'active': !exists(json, 'active') ? undefined : json['active'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'remaining': !exists(json, 'remaining') ? undefined : json['remaining'],
    };
}
export function Secrets20231128UsageOptLimitRemainingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'active': value.active,
        'limit': value.limit,
        'remaining': value.remaining,
    };
}
