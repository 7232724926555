/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215NetworkConnectivityFromJSON, HashicorpCloudGlobalNetworkManager20220215NetworkConnectivityToJSON, HashicorpCloudLocationLinkFromJSON, HashicorpCloudLocationLinkToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215PeerDialerFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215PeerDialerFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215PeerDialerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cluster': !exists(json, 'cluster') ? undefined : HashicorpCloudLocationLinkFromJSON(json['cluster']),
        'clusterPartition': !exists(json, 'cluster_partition') ? undefined : json['cluster_partition'],
        'peerName': !exists(json, 'peer_name') ? undefined : json['peer_name'],
        'peerMeta': !exists(json, 'peer_meta') ? undefined : json['peer_meta'],
        'networkConnectivity': !exists(json, 'network_connectivity') ? undefined : HashicorpCloudGlobalNetworkManager20220215NetworkConnectivityFromJSON(json['network_connectivity']),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215PeerDialerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cluster': HashicorpCloudLocationLinkToJSON(value.cluster),
        'cluster_partition': value.clusterPartition,
        'peer_name': value.peerName,
        'peer_meta': value.peerMeta,
        'network_connectivity': HashicorpCloudGlobalNetworkManager20220215NetworkConnectivityToJSON(value.networkConnectivity),
    };
}
