/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Action is a classification for Control-Plane actions.
 * @export
 * @enum {string}
 */
export var LogService20210330Action;
(function (LogService20210330Action) {
    LogService20210330Action["UNSET"] = "UNSET";
    LogService20210330Action["READ"] = "READ";
    LogService20210330Action["CREATE"] = "CREATE";
    LogService20210330Action["UPDATE"] = "UPDATE";
    LogService20210330Action["DELETE"] = "DELETE";
})(LogService20210330Action || (LogService20210330Action = {}));
export function LogService20210330ActionFromJSON(json) {
    return LogService20210330ActionFromJSONTyped(json, false);
}
export function LogService20210330ActionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LogService20210330ActionToJSON(value) {
    return value;
}
