/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudCommonPaginationResponseFromJSON, HashicorpCloudCommonPaginationResponseToJSON, HashicorpCloudNetwork20200907TGWAttachmentFromJSON, HashicorpCloudNetwork20200907TGWAttachmentToJSON, } from './';
export function HashicorpCloudNetwork20200907ListTGWAttachmentsResponseFromJSON(json) {
    return HashicorpCloudNetwork20200907ListTGWAttachmentsResponseFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907ListTGWAttachmentsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tgwAttachments': !exists(json, 'tgw_attachments') ? undefined : (json['tgw_attachments'].map(HashicorpCloudNetwork20200907TGWAttachmentFromJSON)),
        'pagination': !exists(json, 'pagination') ? undefined : HashicorpCloudCommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function HashicorpCloudNetwork20200907ListTGWAttachmentsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tgw_attachments': value.tgwAttachments === undefined ? undefined : (value.tgwAttachments.map(HashicorpCloudNetwork20200907TGWAttachmentToJSON)),
        'pagination': HashicorpCloudCommonPaginationResponseToJSON(value.pagination),
    };
}
