/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Packer Artifact Registry
 * API for managing Packer images.
 *
 * The version of the OpenAPI document: 2022-12-02
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudPacker20221202ModelsArtifactCreateBodyFromJSON(json) {
    return HashicorpCloudPacker20221202ModelsArtifactCreateBodyFromJSONTyped(json, false);
}
export function HashicorpCloudPacker20221202ModelsArtifactCreateBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'externalIdentifier': !exists(json, 'external_identifier') ? undefined : json['external_identifier'],
        'region': !exists(json, 'region') ? undefined : json['region'],
    };
}
export function HashicorpCloudPacker20221202ModelsArtifactCreateBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'external_identifier': value.externalIdentifier,
        'region': value.region,
    };
}
