export const CONTENT_TABS_LABEL_TERRAFORM = {
  id: 'terraform',
  label: 'components.networks.content-tabs.terraform.label',
};

export const CONTENT_TABS_LABEL_TERMINAL = {
  id: 'terminal',
  label: 'components.networks.content-tabs.terminal.label',
};

export const CONTENT_TABS_LABEL_WEB_CONSOLE = {
  id: 'web-console',
  label: 'components.networks.content-tabs.web-console.label',
};

export const CONTENT_TABS_LABEL_CLOUD_SHELL = {
  id: 'cloud-shell',
  label: 'components.networks.content-tabs.cloud-shell.label',
};

export const CONTENT_TABS_LABEL_AUTOMATE = {
  id: 'automate',
  label: 'components.networks.content-tabs.automate.label',
};

export const CONTENT_TABS_LABELS = {
  terraform: CONTENT_TABS_LABEL_TERRAFORM,
  terminal: CONTENT_TABS_LABEL_TERMINAL,
  webConsole: CONTENT_TABS_LABEL_WEB_CONSOLE,
  cloudShell: CONTENT_TABS_LABEL_CLOUD_SHELL,
  automate: CONTENT_TABS_LABEL_AUTOMATE,
};

export const PRODUCT_CONSUL = {
  id: 'consul',
  label: 'components.networks.content-tabs.consul.label',
};
export const PRODUCT_VAULT = {
  id: 'vault',
  label: 'components.networks.content-tabs.vault.label',
};

export const PRODUCT_POWERSHELL = {
  id: 'powershell',
  label: 'components.networks.content-tabs.powershell.label',
};

export const PRODUCT_AZURE_CLI = {
  id: 'azurecli',
  label: 'components.networks.content-tabs.azurecli.label',
};

export const PRODUCT_BASH = {
  id: 'bash',
  label: 'components.networks.content-tabs.bash.label',
};
