/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Consul Global Network Manager Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2022-02-15
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudGlobalNetworkManager20220215ServiceSummaryEntryFromJSON, HashicorpCloudGlobalNetworkManager20220215ServiceSummaryEntryToJSON, } from './';
export function HashicorpCloudGlobalNetworkManager20220215ServiceSummaryFromJSON(json) {
    return HashicorpCloudGlobalNetworkManager20220215ServiceSummaryFromJSONTyped(json, false);
}
export function HashicorpCloudGlobalNetworkManager20220215ServiceSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'passing': !exists(json, 'passing') ? undefined : json['passing'],
        'warning': !exists(json, 'warning') ? undefined : json['warning'],
        'critical': !exists(json, 'critical') ? undefined : json['critical'],
        'summaries': !exists(json, 'summaries') ? undefined : (json['summaries'].map(HashicorpCloudGlobalNetworkManager20220215ServiceSummaryEntryFromJSON)),
    };
}
export function HashicorpCloudGlobalNetworkManager20220215ServiceSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'total': value.total,
        'passing': value.passing,
        'warning': value.warning,
        'critical': value.critical,
        'summaries': value.summaries === undefined ? undefined : (value.summaries.map(HashicorpCloudGlobalNetworkManager20220215ServiceSummaryEntryToJSON)),
    };
}
