import BaseAbility from './base-ability';
import { PREFIX_IAM_SSO } from '../utils/permission-types/index';

export default class SsoAbility extends BaseAbility {
  prefix = PREFIX_IAM_SSO;

  // only users that are org owners should be able to do this
  get canCRUD() {
    return (
      this.canCreate &&
      this.canUpdate &&
      this.canDelete &&
      this.canGet &&
      this.canList
    );
  }
}
