/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Boundary
 * API for managing Boundary clusters.
 *
 * The version of the OpenAPI document: 2021-02-21
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudBoundary20211221MaintenanceWindowFromJSON, HashicorpCloudBoundary20211221MaintenanceWindowToJSON, HashicorpCloudBoundary20211221UpgradeTypeFromJSON, HashicorpCloudBoundary20211221UpgradeTypeToJSON, } from './';
export function HashicorpCloudBoundary20211221MaintenanceWindowGetResponseFromJSON(json) {
    return HashicorpCloudBoundary20211221MaintenanceWindowGetResponseFromJSONTyped(json, false);
}
export function HashicorpCloudBoundary20211221MaintenanceWindowGetResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'upgradeType': !exists(json, 'upgrade_type') ? undefined : HashicorpCloudBoundary20211221UpgradeTypeFromJSON(json['upgrade_type']),
        'maintenanceWindow': !exists(json, 'maintenance_window') ? undefined : HashicorpCloudBoundary20211221MaintenanceWindowFromJSON(json['maintenance_window']),
    };
}
export function HashicorpCloudBoundary20211221MaintenanceWindowGetResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'upgrade_type': HashicorpCloudBoundary20211221UpgradeTypeToJSON(value.upgradeType),
        'maintenance_window': HashicorpCloudBoundary20211221MaintenanceWindowToJSON(value.maintenanceWindow),
    };
}
