/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Vault Secrets
 * API for managing Secrets on Cloud Services.
 *
 * The version of the OpenAPI document: 2023-11-28
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Secrets20231128AssumeRoleRequestFromJSON, Secrets20231128AssumeRoleRequestToJSON, } from './';
export function SecretServiceCreateAwsDynamicSecretBodyFromJSON(json) {
    return SecretServiceCreateAwsDynamicSecretBodyFromJSONTyped(json, false);
}
export function SecretServiceCreateAwsDynamicSecretBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'integrationName': !exists(json, 'integration_name') ? undefined : json['integration_name'],
        'defaultTtl': !exists(json, 'default_ttl') ? undefined : json['default_ttl'],
        'assumeRole': !exists(json, 'assume_role') ? undefined : Secrets20231128AssumeRoleRequestFromJSON(json['assume_role']),
    };
}
export function SecretServiceCreateAwsDynamicSecretBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'integration_name': value.integrationName,
        'default_ttl': value.defaultTtl,
        'assume_role': Secrets20231128AssumeRoleRequestToJSON(value.assumeRole),
    };
}
