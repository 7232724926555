import {
  TYPE_NETWORK_PEERING,
  TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
} from 'common/utils/cloud-resource-types';

import { PEERING_ROUTE_NAMES, TGW_ROUTE_NAMES } from './network-routes';

export const AWS_DISCLOSURE_GUIDES_PEERING = {
  accountId: [
    {
      helpText:
        'components.page.hvn-peerings.create.form.aws.label.aws-account-id-example-instructions',
      imgSrc: '/images/vpc-instruction-01.png',
      imgAlt:
        'components.page.hvn-peerings.create.form.aws.label.aws-account-id-example-alt',
    },
  ],
  vpcId: [
    {
      helpText:
        'components.page.hvn-peerings.create.form.aws.label.vpc-id-example-instructions',
      imgSrc: '/images/vpc-instruction-02.png',
      imgAlt:
        'components.page.hvn-peerings.create.form.aws.label.vpc-id-example-alt',
    },
  ],
  cidrBlock: [
    {
      helpText:
        'components.page.hvn-peerings.create.form.aws.label.vpc-cidr-block-example-instructions',
      imgSrc: '/images/vpc-instruction-03.png',
      imgAlt:
        'components.page.hvn-peerings.create.form.aws.label.vpc-cidr-block-example-alt',
    },
  ],
  findSecurityGroupId: [
    {
      helpText:
        'components.page.hvn-peerings.detail.instructions.aws.default.disclosures.terminal.find-security-group-id.example-instructions',
      imgSrc: '/images/security-group-id-instruction-file.png',
      imgAlt:
        'components.page.hvn-peerings.detail.instructions.aws.default.disclosures.terminal.find-security-group-id.example-alt',
    },
  ],
  updatingSecurityGroups: [
    {
      helpText:
        'components.page.hvn-peerings.detail.instructions.aws.default.disclosures.web-console.updating-security-groups.example-instructions',
      imgSrc: '/images/securitygroups-instruction-files.png',
      imgAlt:
        'components.page.hvn-peerings.detail.instructions.aws.default.disclosures.web-console.updating-security-groups.example-alt',
    },
  ],
  findRouteTableId: [
    {
      helpText:
        'components.page.hvn-peerings.detail.instructions.aws.default.disclosures.terminal.find-route-table-id.example-instructions',
      imgSrc: '/images/route-table-id-instruction-file.png',
      imgAlt:
        'components.page.hvn-peerings.detail.instructions.aws.default.disclosures.terminal.find-route-table-id.example-alt',
    },
  ],
  updatingRouteTable: [
    {
      helpText:
        'components.page.hvn-peerings.detail.instructions.aws.default.disclosures.web-console.updating-route-table.example-instructions',
      imgSrc: '/images/routetable-instruction-files.png',
      imgAlt:
        'components.page.hvn-peerings.detail.instructions.aws.default.disclosures.web-console.updating-route-table.example-alt',
    },
  ],
};
export const AWS_CONNECTIONS = ['tgwAttachment', 'peering'];
export const AWS_CONNECTIONS_INFO = {
  tgwAttachment: {
    type: TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
    configPath: 'transit-gateway-attachments',
    info: {
      message:
        'components.page.hvn-routes.create.form.target.info-alert.tgw-attachment.message',
      linkText:
        'components.page.hvn-routes.create.form.target.info-alert.tgw-attachment.link-text',
    },
    ...TGW_ROUTE_NAMES,
  },
  peering: {
    type: TYPE_NETWORK_PEERING,
    configPath: 'peerings',
    info: {
      message:
        'components.page.hvn-routes.create.form.target.info-alert.peering.message',
      linkText:
        'components.page.hvn-routes.create.form.target.info-alert.peering.link-text',
    },
    ...PEERING_ROUTE_NAMES,
  },
};

// TGW
export const AWS_DISCLOSURE_GUIDES_TGW_ATTACHMENTS = {
  accountId: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.create.form.label.aws-account-id-example-instructions',
      imgSrc: '/images/tgw-Instructions-account-ID.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.create.form.label.aws-account-id-example-alt',
    },
  ],
  tgwId: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.create.form.label.aws-transit-gateway-id-example-instructions',
      imgSrc: '/images/tgw-Instructions-tgw-id.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.create.form.label.aws-transit-gateway-id-example-alt',
    },
  ],
  resourceShareArn: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.create.form.label.aws-resource-share-arn-example-instructions',
      imgSrc: '/images/tgw-Instructions-share-arn.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.create.form.label.aws-resource-share-arn-example-alt',
    },
  ],
  cidrBlock: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.create.form.label.route-table-example-instructions',
      imgSrc: '/images/tgw-Instructions-route-table.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.create.form.label.route-table-example-alt',
    },
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.create.form.label.vpc-cidr-block-example-instructions',
      imgSrc: '/images/tgw-Instructions-cidr.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.create.form.label.vpc-cidr-block-example-alt',
    },
  ],
  updatingSecurityGroups: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.detail.instructions.default.updating-groups.web-console.example-instructions',
      imgSrc: '/images/securitygroups-instruction-files.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.detail.instructions.default.updating-groups.web-console.example-alt',
    },
  ],
  updatingRouteTable: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.detail.instructions.default.updating-route-table.web-console.example-instructions',
      imgSrc: '/images/routetable-instruction-files.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.detail.instructions.default.updating-route-table.web-console.example-alt',
    },
  ],
};
