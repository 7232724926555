/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Log Service
 * API for retrieving Log data from HCP
 *
 * The version of the OpenAPI document: 2021-03-30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LogService20210330StreamingOrganizationFilterFromJSON, LogService20210330StreamingOrganizationFilterToJSON, LogService20210330StreamingResourceFilterFromJSON, LogService20210330StreamingResourceFilterToJSON, } from './';
export function LogService20210330StreamingFilterFromJSON(json) {
    return LogService20210330StreamingFilterFromJSONTyped(json, false);
}
export function LogService20210330StreamingFilterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'organizationFilter': !exists(json, 'organization_filter') ? undefined : LogService20210330StreamingOrganizationFilterFromJSON(json['organization_filter']),
        'resourceFilter': !exists(json, 'resource_filter') ? undefined : LogService20210330StreamingResourceFilterFromJSON(json['resource_filter']),
    };
}
export function LogService20210330StreamingFilterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'organization_filter': LogService20210330StreamingOrganizationFilterToJSON(value.organizationFilter),
        'resource_filter': LogService20210330StreamingResourceFilterToJSON(value.resourceFilter),
    };
}
