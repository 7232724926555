/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Vault (HCV)
 * API for managing HashiCorp Cloud Vault clusters.
 *
 * The version of the OpenAPI document: 20201125
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevel;
(function (HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevel) {
    HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevel["LOGLEVELCLUSTERSTATEINVALID"] = "LOG_LEVEL_CLUSTER_STATE_INVALID";
    HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevel["TRACE"] = "TRACE";
    HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevel["DEBUG"] = "DEBUG";
    HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevel["INFO"] = "INFO";
    HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevel["WARN"] = "WARN";
    HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevel["ERROR"] = "ERROR";
})(HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevel || (HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevel = {}));
export function HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevelFromJSON(json) {
    return HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevelFromJSONTyped(json, false);
}
export function HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevelFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HashicorpCloudVaultLink20221107LinkedClusterNodeLogLevelToJSON(value) {
    return value;
}
