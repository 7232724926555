import Component from '@glimmer/component';

interface LoadingHeaderSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
}

/**
 *
 * `LoadingHeader` displays the header element of the Loading screen.
 *
 *
 * ```
 * <Loading::Header>Heading</Loading::Header>
 * ```
 *
 * @class LoadingHeader
 *
 */

export default class LoadingHeaderComponent extends Component<LoadingHeaderSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Loading::Header': typeof LoadingHeaderComponent;
    'loading/header': typeof LoadingHeaderComponent;
  }
}
