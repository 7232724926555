/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Billing
 * API for managing HCP Billing Accounts.
 *
 * The version of the OpenAPI document: 2020-11-05
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { Billing20201105StatementResourceFromJSON, Billing20201105StatementResourceToJSON, CommonPaginationResponseFromJSON, CommonPaginationResponseToJSON, } from './';
export function Billing20201105ListStatementResourcesByProjectResponseFromJSON(json) {
    return Billing20201105ListStatementResourcesByProjectResponseFromJSONTyped(json, false);
}
export function Billing20201105ListStatementResourcesByProjectResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'resources': !exists(json, 'resources') ? undefined : (json['resources'].map(Billing20201105StatementResourceFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'pagination': !exists(json, 'pagination') ? undefined : CommonPaginationResponseFromJSON(json['pagination']),
    };
}
export function Billing20201105ListStatementResourcesByProjectResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'resources': value.resources === undefined ? undefined : (value.resources.map(Billing20201105StatementResourceToJSON)),
        'total': value.total,
        'pagination': CommonPaginationResponseToJSON(value.pagination),
    };
}
