/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Resource Manager Service
 * API for managing organizations, projects, and listing resources within projects.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HashicorpCloudResourcemanagerPolicyBindingMemberTypeFromJSON, HashicorpCloudResourcemanagerPolicyBindingMemberTypeToJSON, } from './';
export function HashicorpCloudResourcemanagerPolicyBindingMemberFromJSON(json) {
    return HashicorpCloudResourcemanagerPolicyBindingMemberFromJSONTyped(json, false);
}
export function HashicorpCloudResourcemanagerPolicyBindingMemberFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'memberType': !exists(json, 'member_type') ? undefined : HashicorpCloudResourcemanagerPolicyBindingMemberTypeFromJSON(json['member_type']),
        'memberId': !exists(json, 'member_id') ? undefined : json['member_id'],
    };
}
export function HashicorpCloudResourcemanagerPolicyBindingMemberToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'member_type': HashicorpCloudResourcemanagerPolicyBindingMemberTypeToJSON(value.memberType),
        'member_id': value.memberId,
    };
}
