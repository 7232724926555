import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import {
  FLIGHT_ICON_HEXAGON,
  FLIGHT_ICON_HEXAGON_FILL,
  FLIGHT_ICON_CHECK_HEXAGON,
  FLIGHT_ICON_CHECK_HEXAGON_FILL,
  FLIGHT_ICON_X_HEXAGON,
  FLIGHT_ICON_X_HEXAGON_FILL,
  FLIGHT_ICON_LOADING,
  FLIGHT_ICON_SIZE_16,
  FLIGHT_ICON_SIZE_24,
} from '../utils/consts/hexagon-step.js';
/**
 *
 * `HexagonStep` renders an empty FlightIcon hexagon with the ability to
 *     position a number inside of the container and centered. If the icon is
 *     an empty hexagon, outlined or filled, then the `@text` argument's value
 *     will be rendered on top via absolute positining.
 *
 *
 * ```
 * <HexagonStep
 *   @icon='hexagon-fill'
 *   @text={{1}}
 * />
 * ```
 *
 * @class HexagonStep
 *
 */

const ALLOWED_ICONS = [
  FLIGHT_ICON_HEXAGON,
  FLIGHT_ICON_HEXAGON_FILL,
  FLIGHT_ICON_CHECK_HEXAGON,
  FLIGHT_ICON_CHECK_HEXAGON_FILL,
  FLIGHT_ICON_X_HEXAGON,
  FLIGHT_ICON_X_HEXAGON_FILL,
  FLIGHT_ICON_LOADING,
] as const;

const ALLOWED_SIZES = [
  FLIGHT_ICON_SIZE_16,
  FLIGHT_ICON_SIZE_24,
  `${FLIGHT_ICON_SIZE_16}`,
  `${FLIGHT_ICON_SIZE_24}`,
];

interface HexagonStepSignature {
  Args: {
    icon: (typeof ALLOWED_ICONS)[number];
    size: (typeof ALLOWED_SIZES)[number];
    text: string;
  };
  Element: HTMLSpanElement;
}

export default class HexagonStepComponent extends Component<HexagonStepSignature> {
  constructor(owner: unknown, args: HexagonStepSignature['Args']) {
    super(owner, args);
    if (this.args.icon && !ALLOWED_ICONS.includes(this.args.icon)) {
      assert(
        `The @icon "${
          this.args.icon
        }" provided should be a hexagon FlightIcon (${ALLOWED_ICONS.join(
          ', '
        )}). Please verify it exists on https://flight-hashicorp.vercel.app/`
      );
    }

    if (this.args.size && !ALLOWED_SIZES.includes(this.args.size)) {
      assert(
        `The @size "${
          this.args.size
        }" provided should be an allowed FlightIcon size (${ALLOWED_SIZES.join(
          ', '
        )}). Please verify it exists on https://flight-hashicorp.vercel.app/`
      );
    }
  }

  FLIGHT_ICON_SIZE_24 = FLIGHT_ICON_SIZE_24;

  /**
   * Gets the icon's name
   *
   * @param icon {string}
   * @returns the value of `icon` if set
   * @default `hexagon`
   */
  get icon() {
    const { icon = FLIGHT_ICON_HEXAGON } = this.args;
    return icon;
  }

  /**
   * Determines if this icon is filled with a black background
   *
   * @param icon {string}
   * @returns if the icon is a filled hexagon
   */
  get isFilled() {
    const icon = this.icon;
    return icon.includes('fill');
  }

  /**
   * Gets if text should be rendered
   *
   * @param icon {string}
   * @returns if the text should be visible based on the icon
   */
  get showText() {
    const icon = this.icon;

    switch (icon) {
      case FLIGHT_ICON_HEXAGON:
      case FLIGHT_ICON_HEXAGON_FILL:
        return true;
      default:
        return false;
    }
  }

  /**
   * Gets the icon's size
   *
   * @param size
   * @returns the value of `size` if set
   * @default `24`
   */
  get size() {
    const { size = FLIGHT_ICON_SIZE_24 } = this.args;
    return size;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HexagonStep: typeof HexagonStepComponent;
    'hexagon-step': typeof HexagonStepComponent;
  }
}
