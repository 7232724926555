import templateOnlyComponent from '@ember/component/template-only';

interface DetailCardContentSignature {
  // Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const DetailCardContentComponent =
  templateOnlyComponent<DetailCardContentSignature>();

export default DetailCardContentComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Detail::Card::Content': typeof DetailCardContentComponent;
    'detail/card/content': typeof DetailCardContentComponent;
  }
}
