/* tslint:disable */
/* eslint-disable */
/**
 * HashiCorp Cloud Platform Network Service
 * API for managing HashiCorp virtual networks.
 *
 * The version of the OpenAPI document: 2020-09-07
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HashicorpCloudNetwork20200907AWSCreateRequestTGWDataFromJSON(json) {
    return HashicorpCloudNetwork20200907AWSCreateRequestTGWDataFromJSONTyped(json, false);
}
export function HashicorpCloudNetwork20200907AWSCreateRequestTGWDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tgwId': !exists(json, 'tgw_id') ? undefined : json['tgw_id'],
        'resourceShareArn': !exists(json, 'resource_share_arn') ? undefined : json['resource_share_arn'],
    };
}
export function HashicorpCloudNetwork20200907AWSCreateRequestTGWDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tgw_id': value.tgwId,
        'resource_share_arn': value.resourceShareArn,
    };
}
